import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import { MAIN_ROUTE } from '../../../../config/constants'

const Email = (props) => {

  const { tenantById } = props;   

  return (
    <div className="InvoiceOtr outrTabBg col-md-3 fileSec widbx3">
      <div className="brdOut">
        <h3> Files</h3>
        {tenantById && tenantById.tenant && tenantById.tenant.tenantFile &&  tenantById.tenant.tenantFile.length ?
          <ul className="emlOtr fileData">
            {tenantById && tenantById.tenant && tenantById.tenant.tenantFile.slice(0, 4).map((data, i) => {
              return <li className="d-flex fileLst" key={i}>
                <div className="imgSecEml"><i class="far fa-file-alt fa-lg"></i></div>
                <div className="dtlSecEml">
                  <div className="col-md-12">
                    {/* <p className="payCont">{data.title}</p> */}
                    <a href={MAIN_ROUTE + data.path} download target={"_blank"}>{data.title}</a>
                    <i class="far fa-trash-alt pl-3" onClick={() => props.deleteFileDetails(data._id)}></i>
                  </div>

                </div>
              </li>
            })
            }
          </ul> :
          <div className="no_value" style={{
            minHeight
              : "100px"
          }}>There is no data to show you right now!</div>}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { tenantReducer, commonReducer } = state;
  return {
    tenantReducer,
    commonReducer
  };
};

export default connect(mapStateToProps, {
  ...action, ...commonAction
})(Email);
