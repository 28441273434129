import React, { useState, useEffect } from "react";
import action from "../action";
import tenantAction from "../../Tenants/action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import AddCreditCard from './addCreditCard';
import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';
import history from '../../../config/history';
import PaymentDetail from "./paymentDetail";
import moment from 'moment';
import { numberWithCommas } from "../../Common/components/utils";

const PaymentHistory = (props) => {

    const [paymentModal, setPaymentModal] = useState('');
    const [detail, setDetail] = useState('');
    const [id, setId] = useState('');

    const [params, setParams] = useState({ limit: 10, offset: 0, id: id })
    const [currentPage, setcurrentPage] = useState(1);

    useEffect(() => {
        async function renderCall() {
            const { profileDetail: { profileDetail } } = props;
            if (props.profileReducer.profileDetail && profileDetail) {
                setId(profileDetail.admin && profileDetail.admin.tenantId._id)
                await props.getPaymentHistory({ limit: 10, offset: 0, tenantId: profileDetail.admin && profileDetail.admin.tenantId._id })
            }
        }
        renderCall();
    }, []);

    useEffect(() => {
        async function renderCall() {
            const { profileDetail: { profileDetail } } = props;
            if (profileDetail) {
                setId(profileDetail.admin && profileDetail.admin.tenantId._id)
                await props.getPaymentHistory({ limit: 10, offset: 0, tenantId: profileDetail.admin && profileDetail.admin.tenantId._id })
            }
        }
        renderCall();
    }, [props.profileDetail]);

    const openModal = (e) => {
        setPaymentModal(true);
        setDetail(e)
    }

    const closeModal = () => {
        setPaymentModal(false);
    }

    const renderModal = () => {
        return <PaymentDetail
            onCancel={closeModal}
            detail={detail}
        />
    }

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        data.tenantId = id;
        setParams(data)
        setcurrentPage(page)
        await props.getPaymentHistory(data);
    };

    const { settingsReducer: { paymentHistory: { payments }, paymentHistory } } = props;

    return (
        <div className="tenants-otr">
            {paymentModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            <div className="innago-table-top main-table-top maint_req maint_req_btm">
                <div className="lft">
                    <h1>Payment History</h1>
                </div>
            </div>

            <div className="innago-table table-main maintenanceTable">
                <div className="table-responsive">
                    <table className="table-gap table-td payment-histroy">
                        <tr className="table-head">
                            <th>Invoice #</th>
                            <th>Transaction Date</th>
                            <th>Due Date</th>
                            <th>Recieved on</th>
                            <th>Amount</th>
                            <th className="transaction-status">Transaction Status</th>
                        </tr>
                        {payments && payments.length ?
                            payments.map((e, i) => {
                                return <tr key={i}>
                                    <td
                                        onClick={() => openModal(e)}
                                        className="cursorPointer"
                                    >
                                        <p>{e.invoiceId.invoiceNumber}</p>
                                    </td>
                                    <td>
                                        {e.createdAt && moment(new Date(e.createdAt)).format('MM/DD/YYYY')}
                                    </td>
                                    <td>
                                        {e.invoiceId.dueDate && moment(new Date(e.invoiceId.dueDate)).format('MM/DD/YYYY')}
                                    </td>
                                    <td>
                                        {e.invoiceId.dueDate && moment(new Date(e.invoiceId.startDate)).format('MM/DD/YYYY')}
                                    </td>
                                    <td>
                                        {e.invoiceId ? <>${numberWithCommas(e.invoiceId.amount) || 0}</> : null}</td>
                                    <td className="text-center">{e.status == 0 ? "Failed" : "Success"}</td>
                                </tr>
                            })
                            :
                            <tr>
                                <td className="text-center no_value_table" colSpan={7}><i className="far fa-thumbs-down"></i>There is no data to show you right now!</td>
                            </tr>
                        }
                    </table>
                </div>
                <div className="paginationSec">
                    {paymentHistory.count ?
                        <Pagination
                            totalRecords={paymentHistory.count}
                            pageLimit={params.limit}
                            pageNeighbours={2}
                            currentPage={currentPage}
                            onPageChanged={onPageChanged}
                        /> : null}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, settingsReducer, profileReducer } = state;
    return {
        tenantReducer,
        profileDetail: profileReducer.profileDetail,
        card: settingsReducer.card,
        deleteCards: settingsReducer.deleteCard,
        settingsReducer,
        profileReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...tenantAction
})(PaymentHistory);


