import * as actionType from "./constant";

const initialState = {
    Expense: [],
    ExpenseList:{},
    invoiceTransactions:{}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_EXPENSE_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_EXPENSE_SUCCESS:
            return {
                ...state,
                Expense: {success:true,...action.data}
            };
        case actionType.ADD_EXPENSE_FAILURE:
            return {
                ...state,
                Expense: {success: false, ...action.error}
            };

        case actionType.GET_EXPENSE_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPENSE_SUCCESS:
            return {
                ...state,
                ExpenseList: {
                    success: true,
                    expenses: action.data.data.expenses,
                    monthExpense: action.data.data.monthAmount,
                    totalAmount: action.data.data.totalAmount,
                    expensesCount:action.data.data.expensesCount
                },
            };
        case actionType.GET_EXPENSE_FAILURE:
            return {
                ...state,
                ExpenseList: {success: false, ...action.error}
            };

            case actionType.GET_INVOICE_TRANSACTION_REQUEST:
                return {
                    ...state
                };
            case actionType.GET_INVOICE_TRANSACTION_SUCCESS:
                return {
                    ...state,
                    invoiceTransactions: {success:true,...action.data}
                };
            case actionType.GET_INVOICE_TRANSACTION_FAILURE:
                return {
                    ...state,
                    invoiceTransactions: {success: false, ...action.error}
                };
        default:
            return state;
    }
};
