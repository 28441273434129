import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropertySideList from "./propertySideList";
import PropertyHeader from "./PropertyHeader";
import UnitDetails from "./unitDetails";
import TenantDetails from "./tenantDetails";
import MaintenanceDetails from "./maintenanceDetails";
import history from '../../../../config/history'
import commonAction from '../../../Common/action';
import action from "../../action";
import tenantAction from '../../../Tenants/action';
import maintenanceAction from '../../../Maintenance/action'
import usePrevious from "../../../Common/components/prevProps";
import LoaderSpinner from "../../../Common/components/Loader";
import Pagination from "../../../Common/components/pagination";
import AddPropertyModal from '../AddPropertyModal';
import Modal from "../../../Common/components/Modal";
import MessageBox from "../../../Common/components/MessageBox";
import Confirm from "../../../Common/components/Confirm";
import toastr from "../../../Common/components/Toaster";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FRONT_END_ROUTE_ROUTE } from "../../../../config/constants";

const PropertyDetails = (props) => {
    const [unit, setUnit] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [message, setMessage] = useState(null);
    const [params, setParams] = useState({ limit: 5, offset: 0, unit: null })
    const [currentPage, setcurrentPage] = useState(1);
    const [renderEditModal, setRenderEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [propertyToDelete, setPropertyToDelete] = useState(null);
    const {
        match: {
            params: { id }
        }
    } = props;

    useEffect(() => {
        async function renderCall() {
            await props.getPropertyById(id);
            setTimeout(() => setIsLoading(false), 1000)

        }

        renderCall();
    }, []);

    useEffect(() => {
        async function renderCall() {
            await props.getPropertyById(id);
            setTimeout(() => setIsLoading(false), 1000)

        }

        renderCall();
    }, [id]);

    const prevProperty = usePrevious(props.propertyDetails);

    useEffect(() => {
        if (props.propertyDetails && props.propertyDetails.length) {
            setUnit(props.propertyDetails[0].Units && props.propertyDetails[0].Units[0])
            let tempParams = params
            // tempParams.unit = props.propertyDetails[0].Units && props.propertyDetails[0].Units[0] && props.propertyDetails[0].Units[0]._id
            let unit = props.propertyDetails[0].Units && props.propertyDetails[0].Units[0] && props.propertyDetails[0].Units[0]._id
            setParams(tempParams)
            props.getTenant({ unit: props.propertyDetails[0].Units && props.propertyDetails[0].Units[0] && props.propertyDetails[0].Units[0]._id, isDeleted: false, isClosed: false })
            props.getMaintenance({ unit })
            if (!unit) history.push('/Properties/All')


        }
    }, [prevProperty, props.propertyDetails]);


    const handleUnit = async (unitId) => {
        setUnit(unitId)
        let tempParams = params
        // tempParams.unit = unit._id
        let unit = unitId._id;
        setParams(tempParams)
        await props.getTenant({ unit, isDeleted: false, isClosed: false })
        await props.getMaintenance({ unit })

    }

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getMaintenance(data);
    }

    const { tenantReducer: { tenants } } = props;
    const showEditModal = (e) => {
        e.preventDefault()
        setRenderEditModal(true)
    }

    useEffect(() => {
        if (isSubmitted && props.propertyReducer.updatePropertyResponse && props.propertyReducer.updatePropertyResponse.success) {
            toastr.success("Property updated successfully");
        } else if (props.propertyReducer.updatePropertyResponse && props.propertyReducer.updatePropertyResponse.success == false) {
            toastr.error('Failed to udpate property!');
        }
    }, [props.propertyReducer.updatePropertyResponse]);

    const updateProperty = async (params) => {
        let propertyDetails = new FormData();
        params.images.map(img => {
            propertyDetails.append("file", img.image[0]);
        })
        propertyDetails.append("data", JSON.stringify(params.data));
        setIsLoading(true)
        setIsSubmitted(true)
        await props.updateProperty(propertyDetails)
        await props.getPropertyById(id);
        setRenderEditModal(false)
        setIsLoading(false)
        setIsSubmitted(false)
    }

    const renderPropertyEditModal = () => {
        return <AddPropertyModal
            onCancel={() => setRenderEditModal(false)}
            getPropertyList={props.getPropertyList}
            propertyDetails={props.propertyDetails[0]}
            getPropertyById={props.getPropertyById}
            addPropertyDetails={updateProperty}
            propertyId={id}
            renderLoader={isLoading}
        />
    }

    const deleteProperty = async () => {
        let property = props.propertyDetails[0]
        if (property) {
            if (property.tenantCount > 0) {
                setMessage('A tenant is associated with this property. Remove tenant to delete this property!!')
            } else {
                setConfirmDelete(true)
                setPropertyToDelete(property)
            }
        }
    }

    const deletePropertyUnit = async () => {
        if (unit.isAvailable) {
            setConfirmDelete(true)
        }
        else {
            setMessage('A tenant is associated with this unit. Remove tenant to delete this unit!!')
        }

        // await props.deletePropertyUnit(unit._id)
    }

    const deletePropertyConfirmed = async () => {
        setIsLoading(true)
        await props.deletePropertyUnit(unit._id)
        await props.getPropertyById(id)
        setIsLoading(false)
        setConfirmDelete(false)


    }

    return (
        <div className="tenants-otr">
            {(isSubmitted || isLoading) && <LoaderSpinner />}
            {renderEditModal ?
                <Modal body={renderPropertyEditModal()} onCancel={() => setRenderEditModal(false)} /> : null}
            {message ? <Modal body={<MessageBox
                onCancel={() => setMessage(null)}
                message={message}
                title={<i style={{ 'color': 'indianred', 'fontSize': '30px' }} className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
            />
            }
                onCancel={() => setMessage({ text: null })}
            /> : null}
            {
                confirmDelete ? <Modal body={
                    <Confirm
                        title='Delete unit'
                        message='Do you want to delete this unit?'
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={() => deletePropertyConfirmed()}
                    />
                } />
                    : null
            }
            <div className="innago-table-top tenantDtlTop">
                <div className="lft">
                    <h1>PROPERTIES</h1>
                </div>
                <div className="rgt">
                    <div className="col-md-12 inputCopySec p-0">
                        <input type="text" className="form-control" value={FRONT_END_ROUTE_ROUTE + `screening-Add?id=${id}`} disabled />
                        <CopyToClipboard text={FRONT_END_ROUTE_ROUTE + `screening-Add?id=${id}`}>
                            <button className="copy" onClick={() => toastr.info("Copied to clipboard")}>Copy</button>
                        </CopyToClipboard>
                    </div>
                    <button className="export tenantViewBut" id="viewAllTenant">
                        <i className="far fa-eye"></i>View All Tenants
                    </button>
                    {/*<button*/}
                    {/*className="add"*/}
                    {/*data-toggle="modal"*/}
                    {/*data-target="#addTenant"*/}
                    {/*>*/}
                    {/*<i className="fas fa-plus"></i> Add New Property*/}
                    {/*</button>*/}
                </div>
            </div>

            <div className="tenantDtlOutr propertyOuter">
                <div className="col-md-12 tenantDtl p-0">
                    {/*<PropertySideList currentBusinessId={id}/>*/}
                    {props.propertyDetails ?
                        <div className="propertyDtlRight">
                            <PropertyHeader propertyDetails={props.propertyDetails} />
                            <div className="rightBg">
                                <div className="tabRightBg">
                                    <ul className="nav nav-tabs" role="tablist">
                                        {props.propertyDetails && props.propertyDetails.length ? props.propertyDetails[0].Units.map((units, key) => {
                                            return <li className="nav-item" key={key} onClick={() => handleUnit(units)}>
                                                <a
                                                    className={unit && unit._id === units._id ? "nav-link active-tab" : "nav-link"}
                                                    data-toggle="tab"
                                                    href="#unit1"
                                                >{units.unitName}
                                                </a>
                                            </li>
                                        }) : null}
                                    </ul>
                                    {/*<button type="button" onClick={() => deleteProperty()} className="editright"> Delete </button>
                                    <button type="button" onClick={e => showEditModal(e)} className="editright"> Edit </button>*/}
                                    <div className="d-flex unitAction">
                                        <i className="fas fa-pencil-alt mr-1" onClick={e => showEditModal(e)}></i>
                                        <i className="fa fa-trash" onClick={() => deletePropertyUnit()}></i>
                                        {/* <i className="fa fa-trash pl-3" onClick={() => setConfirmDelete(true)}></i> */}
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div id="unit1" className="tab-pane active">
                                        <UnitDetails unitDetails={unit} />
                                        <TenantDetails tenantList={tenants.tenantList} propertyDetails={props.propertyDetails[0]} unit={unit} />
                                        <MaintenanceDetails maintenanceList={props.maintenanceReducer.maintenanceList} />
                                        {/* <div className="paginationSec">
                                            <ul className="pagination">
                                                <Pagination
                                                    totalRecords={props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceListCount}
                                                    pageLimit={params.limit}
                                                    currentPage={currentPage}
                                                    onPageChanged={onPageChanged}
                                                />
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : "No details Found"}
                </div>

            </div>
        </div>
    )

}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer, maintenanceReducer, propertyReducer } = state;
    return {
        propertyDetails: commonReducer.propertyDetails,
        tenantReducer,
        maintenanceReducer,
        propertyReducer
    };
}

export default connect(mapStateToProps, { ...commonAction, ...action, ...tenantAction, ...maintenanceAction })(PropertyDetails);
