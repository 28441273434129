import React, { useState, useEffect } from "react";
import action from "./action";
import { connect } from "react-redux";
import AddReport from "./components/AddReport";
import Modal from '../Common/components/Modal';
import { withRouter } from "react-router-dom";
import LoaderSpinner from "../Common/components/Loader";
import ReportListing from './components/ReportListing';
import Pagination from '../Common/components/pagination';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FRONT_END_ROUTE_ROUTE } from "../../config/constants";
import toastr from '../Common/components/Toaster';
import moment from 'moment';
import RentalApplication from "./components/rentalApplication";
import commonAction from '../Common/action'


class Report extends React.Component {
    state = {
        modal: false,
        submitted: true,
        currentPage: 1,
        params: {
            limit: 15,
            offset: 0
        },
        email: '',
        rentalModalApplication: false
    }

    componentDidMount = async () => {
        await this.props.getLimitData()
        const { limitData } = this.props.commonReducer
        let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 4)
        let tempParams = this.state.params
        tempParams.limit = limitCount ? limitCount.count : 15
        this.setState({
            params: tempParams
        })
        await this.props.getReport(tempParams);

        this.setState({ submitted: false })
    }

    openModal = () => {
        this.setState({
            modal: true
        })
    }

    closeModal = () => {
        this.setState({
            modal: false
        })
    }

    renderModal = () => {
        return <AddReport onCancel={this.closeModal} status={'add'} />
    };

    onPageChanged = async page => {
        const { params } = this.state;
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        this.setState({
            params: data,
            currentPage: page
        })
        await this.props.getReport(data);
    };

    openRentalModalApplication = () => {
        this.setState({
            rentalModalApplication: true
        })
    }

    closeRentalModalApplication = () => {
        this.setState({
            rentalModalApplication: false
        })
    }


    renderModalApplication = () => {
        return <RentalApplication onCancel={this.closeRentalModalApplication}

        />
    };

    handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = this.state.params
        tempParams.limit = value
        tempParams.offset = 0
        this.setState({
            params: tempParams
        })
        await this.props.updateLimitData({ type: 4, count: value });
        await this.props.getReport(this.state.params);
    }



    render() {
        const { reportReducer: { getReport } } = this.props;
        return (
            <div className="tenants-otr">
                {this.state.modal ? <Modal body={this.renderModal()} onCancel={this.closeModal} /> : null}
                {this.state.rentalModalApplication ? <Modal body={this.renderModalApplication()} onCancel={this.closeRentalModalApplication} /> : null}
                {this.state.submitted && <LoaderSpinner />}
                <div className="innago-table-top">
                    <div className="lft">
                        <h1>SCREENING</h1>
                    </div>
                    {/*<div className="inputCopySec inviteEmail">*/}
                    {/*<input*/}
                    {/*type="text"*/}
                    {/*className="form-control"*/}
                    {/*onChange={(e)=>this.setState({email:e.target.value})}*/}
                    {/*value={this.state.email}*/}
                    {/*placeholder= "Enter Email Address"*/}

                    {/*/>*/}
                    <div className={"top-bar"}>
                        <div className="formsectionPadding selTenant">
                            <div className="selctOtr">
                                {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                                <select className="form-control" value={this.state.params.limit} name={"limit"}
                                    onChange={this.handleChangeLimit}>
                                    <option value={15}>15</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        <div className="rgt pl-2">
                            <button className="add" onClick={this.openRentalModalApplication}>
                                Invite
                        </button>
                        </div>

                        {/*</div>*/}
                        <div className="rgt ">
                            <button className="add mr-0" onClick={this.openModal}>
                                <svg><use xlinkHref="/images/svg-sprite.svg#ic-add" /></svg>
                            New Report
                        </button>
                        </div>
                    </div>
                </div>
                <div className={`innago-table ${getReport && getReport.report && !getReport.report.length ? "addarw" : ""}`}>
                    {getReport && <ReportListing report={getReport.report} params={this.state.params} />}
                    <div className="paginationSec">
                        {getReport.count ?
                            <Pagination
                                totalRecords={getReport.count}
                                pageLimit={this.state.params.limit}
                                pageNeighbours={2}
                                currentPage={this.state.currentPage}
                                onPageChanged={this.onPageChanged}
                            /> : null}
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    const { reportReducer, commonReducer } = state;
    return {
        reportReducer,
        invite: reportReducer.invite,
        commonReducer
    };
};

export default withRouter(connect(mapStateToProps, { ...action, ...commonAction })(Report));
