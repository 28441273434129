import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import history from "../../../../config/history";
import LoaderSpinner from "../Loader";


class SideMenu extends React.Component {
    state = {
        styleVariables: {
            Dashboard: true,
            Properties: false,
            Tenants: false,
            Invoice: false,
            Report: false,
            Transaction: false,
            Leases: false,
            Messaging: false,
            Maintenance: false,
            Settings: false,
            Planner: false,
            Accounts: false,
            PaymentHistory: false,
            AutoPay: false,
            Cards: false,
            Profile: false,
        },
        role: ''
    }

    componentDidMount = () => {
        const { profileReducer: { profileDetail } } = this.props;
        let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
        this.setState({
            role: role
        })
        this.setState({
            styleVariables: {
                Dashboard: window.location.pathname === '/home',
                Properties: window.location.pathname === '/Properties/All',
                Tenants: window.location.pathname === '/Tenants',
                Invoice: window.location.pathname === '/Invoice/All',
                Report: window.location.pathname === '/Report',
                Transaction: window.location.pathname === '/InvoiceTransaction',
                Leases: window.location.pathname === '/Leases',
                Messaging: window.location.pathname === '/Messaging' || window.location.pathname === '/Messaging/Email/Inbox' || window.location.pathname === '/Messaging/Email/Sent' || window.location.pathname === '/Messaging/Sms',
                Maintenance: window.location.pathname === '/Maintenance' || window.location.pathname === '/Add-Maintenance',
                Settings: window.location.pathname === '/Settings',
                Planner: window.location.pathname === '/Planner',
                Plaid: window.location.pathname === '/DataSource',
                Accounts: window.location.pathname === '/Accounts/Cards',
                PaymentHistory: window.location.pathname === '/PaymentHistory',
                AutoPay: window.location.pathname === '/Auto-Pay',
                Cards: window.location.pathname === '/Accounts/Cards',
                Profile: window.location.pathname === '/Profile',
            }
        });
    }

    componentDidUpdate = async (prevProps) => {
        if ((prevProps.profileReducer.profileDetail !== this.props.profileReducer.profileDetail) || (prevProps.match.path != this.props.match.path)) {
            const { profileReducer: { profileDetail } } = this.props;
            let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
            this.setState({
                role: role
            })

            this.setState({
                styleVariables: {
                    Dashboard: window.location.pathname === '/home',
                    Properties: window.location.pathname === '/Properties/All',
                    Tenants: window.location.pathname === '/Tenants',
                    Invoice: window.location.pathname === '/Invoice/All',
                    Report: window.location.pathname === '/Report',
                    Transaction: window.location.pathname === '/Expense' || window.location.pathname === '/Transaction' || window.location.pathname === '/InvoiceTransaction',
                    Leases: window.location.pathname === '/Leases',
                    Messaging: window.location.pathname === '/Messaging' || window.location.pathname === '/Messaging/Email/Inbox' || window.location.pathname === '/Messaging/Email/Sent' || window.location.pathname === '/Messaging/Sms',
                    Maintenance: window.location.pathname === '/Maintenance' || window.location.pathname === '/Add-Maintenance' || window.location.pathname === '/Tenant-Maintenance',
                    Settings: window.location.pathname === '/Settings',
                    Planner: window.location.pathname === '/Planner',
                    Plaid: window.location.pathname === '/DataSource',
                    Accounts: window.location.pathname === '/Accounts/Cards',
                    PaymentHistory: window.location.pathname === '/PaymentHistory',
                    AutoPay: window.location.pathname === '/Auto-Pay',
                    Cards: window.location.pathname === '/Accounts/Cards',
                    Profile: window.location.pathname === '/Profile',
                }
            });
        }
    }

    handleClick = async (data) => {
        const { history } = this.props;
        const { role } = this.state;
        await this.setState({
            styleVariables: {
                Dashboard: data === 'Dashboard',
                Properties: data === 'Properties',
                Tenants: data === 'Tenants',
                Invoice: data === 'Invoice',
                Report: data === 'Report',
                Transaction: data === 'Transaction',
                Leases: data === 'Leases',
                Messaging: data === 'Messaging',
                Maintenance: data === 'Maintenance',
                Settings: data === 'Settings',
                Planner: data === 'Planner',
                Plaid: data === 'Plaid',
                Accounts: data === 'Accounts',
                PaymentHistory: data === 'PaymentHistory',
                AutoPay: data === '/Auto-Pay',
                Cards: data === '/Accounts/Cards',
                Profile: data === '/Profile',

            }
        });

        const { styleVariables } = this.state;
        if (styleVariables.Dashboard) history.push('/home')
        if (styleVariables.Properties) {
            // if (role === 'tenant') {
            //     history.push('/PropertyDetails/:id')
            // } else {
            if (role !== 'tenant')
                history.push('/Properties/All')
            //}
        }
        if (styleVariables.Tenants) history.push('/Tenants');
        if (styleVariables.Invoice) history.push('/Invoice/All');
        if (styleVariables.Transaction) history.push('/InvoiceTransaction');
        if (styleVariables.Report) history.push('/Report');
        if (styleVariables.Leases) history.push('/Leases');
        if (styleVariables.Messaging) history.push('/Messaging/Sms');
        if (styleVariables.AutoPay) history.push('/Auto-Pay');
        if (styleVariables.Cards) history.push('/Accounts/Cards');
        if (styleVariables.Profile) history.push('/Profile');


        if (role && styleVariables.Maintenance) {
            if (role == 'tenant') {
                history.push('/Tenant-Maintenance')
            } else {
                history.push('/Maintenance');
            }
        }
        if (styleVariables.Settings) history.push('/Settings');
        if (styleVariables.Planner) history.push('/Planner');
        if (styleVariables.Plaid) history.push('/DataSource');
        if (styleVariables.Accounts) history.push('/Accounts/Cards');
        if (styleVariables.PaymentHistory) history.push('/PaymentHistory');
    };

    closeNav = () => {
        var element = document.getElementById('sidebar');
        var element1 = document.getElementById('main');
        element.style.width = '80px';
        element1.style.marginLeft = "80px";
        element.classList.remove('expandnavbar');
        element1.classList.remove('expandnavbarr');
    }

    handleReload = () => {
        window.location.reload()
    }

    render() {
        const { styleVariables } = this.state;
        const { profileReducer: { profileDetail } } = this.props;
        let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
        return (
            <div className="sideNav expandnavbar" id="sidebar">
                <div className="navbar-brand">
                    <a className="logo-small" onClick={() => history.push('/home')}> <img src="/images/logo-small.png" /></a>
                    <a className="logo-large" onClick={() => history.push('/home')}> <img src="/images/logo.png" /></a>
                    <button className="navclose" onClick={this.closeNav}><i className="fas fa-bars"></i></button>
                </div>

                {role ? <ul className="navList">

                    <li className={styleVariables.Dashboard ? "active" : null}
                        onClick={(e) => this.handleClick('Dashboard')}>
                        <svg>
                            <use xlinkHref="/images/svg-sprite.svg#ic-adshboard" />
                        </svg>
                        <span className="open-menu" onClick={this.handleReload}><Link to="/home">Dashboard</Link></span></li>

                    {(role && (role === "admin" || role === "adminuser" || role === "maintenance")) ?
                        <li onClick={(e) => this.handleClick('Properties')}
                            className={styleVariables.Properties ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-properties" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload}><Link to="/Properties/All">Properties</Link></span></li> : null}

                    {(role && (role === "admin" || role === "adminuser" || role === "maintenance")) ?
                        <li onClick={(e) => this.handleClick('Tenants')}
                            className={styleVariables.Tenants ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-Tenants" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload}><Link to="/Tenants">Tenants</Link></span></li> : null}

                    {role && (role === "admin" || role === "tenant" || role === "adminuser") ?
                        <li onClick={(e) => this.handleClick('Invoice')}
                            className={styleVariables.Invoice ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-Invoice" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload}><Link to="/Invoice/All">Invoices</Link></span></li> : null}
                    {role && (role === "admin" || role === "adminuser") ?
                        <li onClick={(e) => this.handleClick('Report')}
                            className={styleVariables.Report ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-report" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload}><Link to="/Report">Screening</Link></span>
                        </li> : null}
                    {role && (role === "admin" || role === "adminuser") ?

                        <li onClick={(e) => this.handleClick('Transaction')}
                            className={styleVariables.Transaction ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-Expenses" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload}><Link to="/InvoiceTransaction">Transactions</Link></span></li> : null}

                    {role && (role === "admin" || role === "adminuser" || role === "tenant") ?
                        <li onClick={(e) => this.handleClick('Leases')}
                            className={styleVariables.Leases ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-Leases-Files" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload}><Link to="/Leases">Leases & Files</Link></span></li>
                        : null}
                    < li onClick={(e) => this.handleClick('Maintenance')}
                        className={styleVariables.Maintenance ? "active" : null}>
                        <svg>
                            <use xlinkHref="/images/svg-sprite.svg#ic-maintenancenew" />
                        </svg>
                        <span className="open-menu" onClick={this.handleReload}>
                            <Link
                                to={role && (role === "admin" || role === "adminuser") ? "/Maintenance" : '/Tenant-Maintenance'}>Maintenance</Link>
                        </span>
                    </li>

                    {role && (role === "tenant") ?
                        <li onClick={(e) => this.handleClick('PaymentHistory')}
                            className={styleVariables.PaymentHistory ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-paymenthistory" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload} ><Link to="/PaymentHistory">Payment History</Link></span>
                        </li> : null}
                    {/*{role && (role === "tenant") ?*/}
                    {/*    <li*/}
                    {/*        className={styleVariables.PaymentHistory ? "active" : "deafult-menu"} >*/}
                    {/*        <svg>*/}
                    {/*            <use xlinkHref="/images/svg-sprite.svg#ic-paymenthistory"/>*/}
                    {/*        </svg>*/}
                    {/*        <span className="open-menu"><Link>Payment History</Link></span>*/}
                    {/*    </li> : null}*/}
                    {role && (role === "admin" || role === "adminuser") ?
                        <li onClick={(e) => this.handleClick('Messaging')}
                            className={styleVariables.Messaging ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-Messaging" />
                            </svg>
                            {/* <span className="open-menu"><Link to="/Messaging/Email/Inbox">Messaging</Link></span> */}
                            <span className="open-menu" onClick={this.handleReload}><Link to="/Messaging/Sms">Messaging</Link></span>

                        </li> : null}
                    {role && (role === "admin" || role === "adminuser") ?
                        <li onClick={(e) => this.handleClick('Planner')}
                            className={styleVariables.Planner ? "active" : null}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-board" />
                            </svg>
                            <span className="open-menu" onClick={this.handleReload}><Link to="/Planner">Planner</Link></span>
                        </li> : null}


                    {role && role === "tenant" ?

                        <li className="dropdown">

                            <a className="nav-drp dropdown-toggle"  id="navbarDropdown" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <svg>
                                    <use xlinkHref="/images/svg-sprite.svg#ic-Maintenance"></use>
                                </svg>
                                <span className="open-menu" 
                                >Settings</span></a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                <span

                                    className={styleVariables.AutoPay ? `sub-menu sub-menu-active open-sub-menu` : "sub-menu open-sub-menu"}> <svg> <use
                                        xlinkHref="/images/svg-sprite.svg#ic-autopay" onClick={this.handleReload}></use>
                                    </svg>
                                    <Link to="/Auto-Pay" className="dropdown-item">Auto Pay</Link></span>

                                <Link to="/Accounts/Cards"> <span

                                    className={styleVariables.Cards ? `sub-menu sub-menu-active open-sub-menu` : "sub-menu open-sub-menu"}><svg><use
                                        xlinkHref="/images/svg-sprite.svg#ic-card" onClick={this.handleReload}></use>
                                    </svg><a className="dropdown-item" >Cards</a></span></Link>

                                {/*<Link to="/Messaging/Email/Inbox"> <span*/}
                                {/*   className={styleVariables.Messaging ? `sub-menu sub-menu-active open-sub-menu` : "sub-menu open-sub-menu"}><svg>*/}
                                {/*       <use xlinkHref="images/svg-sprite.svg#ic-email"></use>*/}
                                {/*   </svg><a className="dropdown-item" href="#">Email</a></span></Link>*/}

                                <Link to="/Profile"> <span

                                    className={styleVariables.Profile ? `sub-menu sub-menu-active open-sub-menu` : "sub-menu open-sub-menu"}><svg>
                                        <use xlinkHref="/images/svg-sprite.svg#ic-profile" onClick={this.handleReload}></use>
                                    </svg><a className="dropdown-item" > Profile</a></span></Link>
                            </div>
                        </li>
                        : null
                    }
                </ul> : <LoaderSpinner />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { profileReducer } = state;
    return {
        profileReducer
    };
};

export default withRouter(connect(mapStateToProps, {})(SideMenu));

