import React, {useState, useEffect} from "react";
import CreditCardInput from 'react-credit-card-input';

const AddCredit = (props) => {

    const onSubmit = () => {
        props.openConfirm()

    }


    const {state, validate, handleChange, onChangeIncomeStartdate, handleCardNumberChange, handleCardExpiryChange, handleCvvChange, onOpenConfirmation} = props;

    return (
        <div className="modal addTenant addcardDtl" id="modalId" style={{display: "block"}} role="dialog"
             aria-hidden="true">

            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Credit Card Information</h5>
                        <button type="button" className="close" onClick={() => props.onCancel()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <div className="formSection">
                                <div className="row outrForm m-0 p-0">
                                    <div className="col-md-12 formInp">
                                        <label>Name on Card</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Name on Card"
                                            value={state.name}
                                            onChange={handleChange}
                                            maxLength="25"
                                        />
                                        {validate(state.name, "Name", "required")}
                                        {state.name && validate(state.name, "Name", "letter")}
                                    </div>
                                    <div className="col-md-12 formInp">
                                        <label>Card Number</label>
                                        <div className="creditcardOtr">
                                            <CreditCardInput
                                             customTextLabels={{
                                                invalidCvc:'  ',
                                                cardNumberPlaceholder: 'Card Number',
                                                expiryPlaceholder: 'MM/YY',
                                                cvcPlaceholder: 'CVV'
                                              }}
                                                cardNumberInputProps={{
                                                    value: state.number,
                                                    onChange: handleCardNumberChange
                                                }}
                                                cardExpiryInputProps={{
                                                    value: state.expiry,
                                                    onChange: handleCardExpiryChange
                                                }}
                                                cardCVCInputProps={{value: state.cvv, onChange: handleCvvChange}}
                                                dangerTextClassName={"errorMsg"}
                                                invalidClassName={null}
                                            />
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit" onClick={() => onSubmit()}>Submit
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddCredit;

