import * as actions from "./constant";

const initialState = {
  screening: {},
  getProperty: { property: {} },
  email: '',
  screeningPayment:{}
};

export default function tenantsReducer(state = initialState, action) {
  switch (action.type) {

    case actions.ADD_EMAIL:
      return {
        ...state,
        email: action.data.email
      };

    case actions.ADD_SCREENING_REQUEST:
      return {
        ...state
      };

    case actions.ADD_SCREENING_SUCCESS:
      return {
        ...state,
        screening: { success: true, ...action.reportAction, message: action.reportAction.message }
      };

    case actions.ADD_SCREENING_FAILURE:
      return {
        ...state,
        screening: { success: false, ...action.error }
      };

    case actions.GET_SCREENING_PROPERTY_REQUEST:
      return {
        ...state
      };

    case actions.GET_SCREENING_PROPERTY_SUCCESS:
      return {
        ...state,
        getProperty: {
          success: true,
          property: action.reportAction.property
        }
      };

    case actions.GET_SCREENING_PROPERTY_FAILURE:
      return {
        ...state,
        getProperty: { success: false, ...action.error }
      };

    case actions.MAKE_PAYMENT_REQUEST:
      return {
        ...state
      };

    case actions.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        screeningPayment:action.reportAction
      };

    case actions.MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        screeningPayment: { success: false, ...action.error }
      };
    case actions.GET_VACANT_PROPERTY_REQUEST:
      return {
        ...state
      };

    case actions.GET_VACANT_PROPERTY_SUCCESS:
      return {
        ...state,
        propertyDetails:action.reportAction
      };

    case actions.GET_VACANT_PROPERTY_FAILURE:
      return {
        ...state,
        propertyDetails: { success: false, ...action.error }
      };

    case actions.GET_VACANT_UNIT_REQUEST:
      return {
        ...state
      };

    case actions.GET_VACANT_UNIT_SUCCESS:
      return {
        ...state,
        unitDetails:action.reportAction
      };

    case actions.GET_VACANT_UNIT_FAILURE:
      return {
        ...state,
        unitDetails: { success: false, ...action.error }
      };



    default:
      return state;
  }
}

