import React from "react";
import action from "../action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"
import LoaderSpinner from "../../Common/components/Loader";
import PageTitle from "./pageTitle";
import CreatableSelect from 'react-select/creatable';
import queryString from 'query-string'
import toastr from '../../Common/components/Toaster'
import EmailContainer from './EmailContainer'
import SmsContainer from "./SmsContainer";
import { animateScroll } from "react-scroll";
import io from 'socket.io-client';
import { MAIN_ROUTE } from "../../../config/constants";
import commonAction from '../../Common/action'
import history from '../../../config/history'
import Confirm from "../../Common/components/Confirm";
import Modal from "../../Common/components/Modal";


class MessageContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            listParams: {
                limit: 10,
                offset: 1,
                type: 'inbox',
                search: '',
                fromDate: '',
                toDate: '',
            },
            filterParams: {
                keyWord: '',
                fromDate: null,
                toDate: null,
            },
            currentPage: 1,
            showSuccess: false,
            isNewChat: false,
            chatReceiverId: 'null',
            chatReceiversArray: [],
            chatReceiverPhoneNo: '',
            chatMessage: '',
            currentRecName: '',
            hasNotRecSmsResp: false,
            deleteArray: [],
            isConfim: false,
            checkedArray: [],
            isConfimMessage: false,
            isCheckAll: false
        };
    }

    componentDidMount = async () => {
        const { listParams } = this.state;
        const { location: { pathname } } = this.props;
        if (pathname === '/Messaging/Email/Sent') {
            await this.setState({
                listParams: { ...listParams, type: 'sent' }
            });
        }
        if (pathname === '/Messaging/Email/Inbox') {
            await this.setState({
                listParams: { ...listParams, type: 'inbox' }
            });
        }
        await this.props.getPhoneNumbers();
        await this.props.getChatMessageList();
        await this.props.getMailList(this.state.listParams);
        const { messageReducer: { chatMessageList: { chats: chatList } } } = this.props;
        if (chatList && chatList.length) {
            await this.setState({
                chatReceiverId: chatList[0]._tid,
                currentRecName: chatList[0].name,
                chatReceiverPhoneNo: chatList[0]._id,
            });
            await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
        }
        const { location: { search } } = this.props;
        const query = queryString.parse(search);
        if (query.newChat) {
            await this.setState({
                chatReceiverId: '',
                currentRecName: '',
            });
        }
        toastr.remove();
        this.setState({
            isLoaded: true
        });
        this.scrollToBottom();
        const socket = io(MAIN_ROUTE);
        // function timer() {
        //      this.props.refreshMail();
        // }
        // this.props.refreshMail();
        // let counter = setInterval(timer, 3000);
        socket.on("message", async (data) => {
            await this.props.getPhoneNumbers();
            // await this.props.refreshMail();
            await this.props.getChatMessageList();
            await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
            this.scrollToBottom()
        });
    };

    componentDidUpdate = async (preProps, preState) => {
        if (this.props.location.pathname !== preProps.location.pathname) {
            this.setState({
                isLoaded: false
            });
            const { listParams } = this.state;
            const { location: { pathname } } = this.props;
            if (pathname === '/Messaging/Email/Sent') {
                await this.setState({
                    listParams: { ...listParams, type: 'sent' }
                });
            }
            if (pathname === '/Messaging/Email/Inbox') {
                await this.setState({
                    listParams: { ...listParams, type: 'inbox' }
                });
            }
            await this.props.getMailList(this.state.listParams);
            this.setState({
                isLoaded: true
            });
            this.scrollToBottom();
        }
        // if(this.state.isLoaded && this.props.messageReducer.refeshData!=preProps.messageReducer.refeshData){
        //     if(this.props.messageReducer.refeshData.success){
        //         await this.props.getMailList(this.state.listParams);
        //     }
        //     else{
        //         toastr.errors(this.props.messageReducer.refeshData.message)
        //     }
        //
        // }
    };

    handlePagination = async (page) => {
        const { listParams, listParams: { limit } } = this.state;
        let nextOffset = page || 1;
        await this.setState({
            listParams: {
                ...listParams,
                offset: nextOffset
            },
            currentPage: page
        });
        await this.props.getMailList(this.state.listParams);
    };

    hideSuccessModal = () => {
        this.setState({
            showSuccess: false,
        });
    };

    handleDeleteMessage = async (messageId) => {
        this.setState({
            isLoaded: false
        });
        await this.props.deleteMessage(messageId);
        await this.props.getMailList(this.state.listParams);
        this.setState({
            isLoaded: true,
            showSuccess: true,
        });
    };

    updateFilterParams = async (filterParams) => {
        const { listParams } = this.state;
        await this.setState({
            filterParams,
            listParams: {
                ...listParams,
                search: filterParams.keyWord,
                fromDate: filterParams.fromDate,
                toDate: filterParams.toDate,
            },
        });
        await this.props.getMailList(this.state.listParams);
        const { messageReducer: { messageList: { success } } } = this.props;
        if (success) return true
    };

    handleToSelect = async (selectedPhoneNumbers) => {
        let error = null;
        let tempPhoneNo = null;
        if (typeof (selectedPhoneNumbers.value) === 'object') {
            tempPhoneNo = selectedPhoneNumbers.value.phoneNumber
        } else {
            tempPhoneNo = selectedPhoneNumbers.value
        }
        if (tempPhoneNo.length !== 10) {
            error = 'Invalid phone number ' + tempPhoneNo;
        }
        this.setState({
            chatReceiverId: selectedPhoneNumbers.value._id || null,
            chatReceiverPhoneNo: tempPhoneNo,
        }, () => {
            error && toastr.error(error)
        });
    };

    renderNewChat = (tenantPhoneNumbers) => {
        toastr.remove();
        if (!this.props.location) return null;
        const { location: { search } } = this.props;
        const query = queryString.parse(search);
        if (query.newChat) {
            return <div className="formInp chatWrapper">
                <span className="messageLabel">To</span>
                <CreatableSelect
                    // isMulti
                    onChange={this.handleToSelect}
                    placeholder={"Select Recipients"}
                    options={tenantPhoneNumbers}
                />
            </div>
        }
        return null;
    };

    scrollToBottom = () => {
        if (document.getElementById("messageHistory")) {
            animateScroll.scrollToBottom({
                containerId: "messageHistory"
            });
        }
    };

    handleSendMessage = async () => {
        toastr.remove();
        const { chatReceiverId, chatReceiverPhoneNo, chatMessage } = this.state;
        if (!chatMessage.length) toastr.error('Please enter a message!');
        if (!chatReceiverPhoneNo.length) toastr.error('Please select recipient!');
        if (chatMessage.length && chatReceiverPhoneNo.length) {
            this.setState({
                hasNotRecSmsResp: true,
                tempMessageContent: chatMessage
            }, () => {
                this.scrollToBottom()
            });
            let chatMessageData = {
                phoneNumber: chatReceiverPhoneNo,
                message: chatMessage
            };
            this.setState({
                chatMessage: '',
            });
            await this.props.sendChatMessage(chatMessageData);
            const { messageReducer: { sendChatMessageRes: { success: status } }, history } = this.props;
            if (!status) {
                this.setState({
                    isLoaded: true
                });
                toastr.error('Failed! SMS not sent');
                return
            }
            this.setState({
                hasNotRecSmsResp: false
            });
            await this.props.getChatMessageList();
            await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
            const { messageReducer: { chatMessageList: { chats: chatList } } } = this.props;
            if (chatList && chatList.length) {
                await this.setState({
                    chatReceiverId: chatList[0]._tid,
                    currentRecName: chatList[0].name,
                    chatReceiverPhoneNo: chatList[0]._id,
                });
                await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
            }
            this.handleChatSelect(this.state.chatReceiverId, this.state.currentRecName, this.state.chatReceiverPhoneNo)
            this.setState({
                chatMessage: '',
                isLoaded: true
            });
            history.push('/Messaging/Sms')
        }
    };

    handleChatSelect = async (tenantId, name, receiverNumber) => {
        toastr.remove();
        const { history } = this.props;
        await this.setState({
            chatReceiverId: tenantId,
            currentRecName: name,
            chatReceiverPhoneNo: receiverNumber,
        });
        await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
        this.scrollToBottom();
        history.push('/Messaging/Sms')
    };

    updateChatReceiver = async () => {
        toastr.remove();
        const { history } = this.props;
        await this.setState({
            chatReceiverId: '',
            currentRecName: '',
        });
        await this.props.getChatDetails(this.state.chatReceiverId);
        history.push(`/Messaging/Sms?newChat=true`)
    };

    handleRemoveNewChat = async () => {
        toastr.remove();
        await this.props.getChatMessageList();
        await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
        const { messageReducer: { chatMessageList: { chats: chatList } }, history } = this.props;
        if (chatList && chatList.length) {
            await this.setState({
                chatReceiverId: chatList[0]._tid,
                currentRecName: chatList[0].name,
                chatReceiverPhoneNo: chatList[0]._id,
            });
            await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
        }
        this.handleChatSelect(this.state.chatReceiverId, this.state.currentRecName, this.state.chatReceiverPhoneNo)
        await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
        history.push(`/Messaging/Sms`)
    };

    handleKeyPressed = (event) => {
        if (event.key === "Enter") {
            this.handleSendMessage();
        }
    };

    manageMessageOnChange = (value) => {
        this.setState({ chatMessage: value })
    };

    viewNotification = async (type) => {
        await this.props.updateNotificationDetails({ type })
        const socket = io(MAIN_ROUTE);
        socket.on("message", async (data) => {
            await this.props.getNotificationDetails()
        });
        socket.on("new_email", async (data) => {
            await this.props.getNotificationDetails()
        });
        await this.props.getNotificationDetails()
        this.setState({
            isLoading: true
        })
        if (type === "email") {
            history.push('/Messaging/Email/Inbox')
        } else {
            history.push('/Messaging/Sms')
        }

    }

    setLoding = async () => {
        this.setState({
            isLoaded: false
        })
        // await this.props.refreshMail()
        const { messageReducer: { refeshData } } = this.props;
        if (refeshData && refeshData.success) {
            await this.props.getMailList(this.state.listParams);
        } else {
            toastr.error(refeshData && refeshData.message)
        }
        this.setState({
            isLoaded: true
        })
    }

    handleCheck = async (e, mailDetails) => {
        let tempArray = this.state.deleteArray
        if (e.target.checked) {
            tempArray.push({ id: mailDetails.messageId })
            this.setState({
                deleteArray: tempArray
            })
        } else {
            tempArray = tempArray.filter(t => t.id !== mailDetails.messageId)
            this.setState({
                deleteArray: tempArray
            })
        }



    }

    deleteMessages = async () => {
        if (this.state.deleteArray.length) {
            this.setState({
                isConfim: true
            })
        }
        else {
            toastr.error('Please Select a mail to delete!!')
        }


    }
    closeConfirm = () => {
        this.setState({
            isConfim: false
        })
        this.setState({
            deleteArray: []
        })
        window.location.reload()
    }

    closeConfirmMessage = () => {
        this.setState({
            isConfimMessage: false
        })
        this.setState({
            checkedArray: []
        })
        window.location.reload()
    }


    renderDeleteModal = () => {
        return <Confirm
            title='Delete Email'
            message='Do you want to delete this email?'
            onCancel={this.closeConfirm}
            onConfirm={this.deleteCheckedEmail}
        />
    }

    renderDeleteMessageModal = () => {
        return <Confirm
            title='Delete Message'
            message='Do you want to delete this Message?'
            onCancel={this.closeConfirmMessage}
            onConfirm={this.deleteCheckedMessage}
        />
    }

    deleteCheckedEmail = async () => {
        await this.props.deleteMailChecked(this.state.deleteArray)
        this.setState({
            isConfim: false,
            deleteArray: []
        })
        window.location.reload()
        await this.props.getMailList(this.state.listParams);
        const socket = io(MAIN_ROUTE);
        // this.props.refreshMail();
        socket.on("message", async (data) => {
            await this.props.getPhoneNumbers();
            // await this.props.refreshMail();
            await this.props.getChatMessageList();
            await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
            this.scrollToBottom()
        });
    }

    handleCheckMessage = async (e, messageDetails) => {

        let tempArray = this.state.checkedArray
        if (e.target.checked) {
            tempArray.push({ id: messageDetails._id })
            this.setState({
                checkedArray: tempArray
            })
        } else {
            tempArray = tempArray.filter(t => t.id !== messageDetails._id)
            this.setState({
                checkedArray: tempArray
            })
        }
    }


    handleCheckAllMessage = async (e, messageDetails) => {
        const { messageReducer: { chatMessageList: {
            chats: chatList
        } } } = this.props;
        let tempArray = this.state.checkedArray
        if (chatList && chatList.length) {
            chatList.map((item, i) => {
                tempArray.push({ id: item._id })

            })

            if (e.target.checked) {
                this.setState({
                    checkedArray: tempArray,
                    isCheckAll: true
                })
            } else {
                this.setState({
                    checkedArray: [],
                    isCheckAll: false

                })
            }
        }
    }




    deleteCheckedMessage = async () => {
        await this.props.deleteMessageChecked(this.state.checkedArray)
        this.setState({
            isConfim: false,
            checkedArray: [],
            isCheckAll: false
        })
        window.location.reload()
        await this.props.getMailList(this.state.listParams);
        const socket = io(MAIN_ROUTE);
        this.props.refreshMail();
        socket.on("message", async (data) => {
            await this.props.getPhoneNumbers();
            // await this.props.refreshMail();
            await this.props.getChatMessageList();
            await this.props.getChatDetails(this.state.chatReceiverPhoneNo);
            this.scrollToBottom()
        });
    }

    openDeleteMessageConfirm = () => {
        if (this.state.checkedArray.length) {
            this.setState({
                isConfimMessage: true
            })
        }
        else {
            toastr.error('Please Select a message to delete!!')
        }
    }

    deleteSelectedMessage=(messageDetails)=>{
        let tempArray = this.state.checkedArray
        tempArray.push({ id: messageDetails._id })
        this.setState({
            checkedArray: tempArray,
            isConfimMessage: true
        })
    }


    render() {
        const { isLoaded } = this.state;
        if (!isLoaded) {
            return <LoaderSpinner />;
        }
        const { listParams, currentPage, showSuccess } = this.state;
        const {
            messageReducer: {
                messageList: {
                    list: messageList
                },
                totalMessages,
                tenantPhoneNumbers,
                chatMessageList: {
                    chats: chatList
                },
                chatMessageDetails
            },
            location: {
                pathname,
                search
            },
            history,
            profileReducer: { profileDetail }
        } = this.props;

        const query = queryString.parse(search);

        let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
        let tempTenantPhoneNumbers = [];
        (tenantPhoneNumbers && tenantPhoneNumbers.length) && tenantPhoneNumbers.map(item => tempTenantPhoneNumbers.push({
            label: item.firstName.trim() + ' ' + item.lastName.trim(),
            value: { phoneNumber: item.phoneNumber.trim(), _id: item._id.trim() }
        }));

        return (
            <>
                <div className="tenants-otr">
                    {this.state.isConfim ? <Modal body={this.renderDeleteModal()} onCancel={this.closeConfirm} /> : null}
                    {this.state.isConfimMessage ? <Modal body={this.renderDeleteMessageModal()} onCancel={this.closeConfirmMessage} /> : null}
                    <PageTitle updateFilterParams={this.updateFilterParams}
                        updateChatReceiver={this.updateChatReceiver}
                        setLoding={this.setLoding}

                    />
                    <div className="chatNav">
                        <div className="nav nav-pills">
                            {/* <a className={`nav-item nav-link ${(pathname === '/Messaging/Email/Inbox' || pathname === '/Messaging/Email/Sent' || pathname === '/Messaging') ? 'active' : ''}`}
                                onClick={() => this.viewNotification('email')}>Email</a> */}
                            {
                                role && role == 'admin'
                                    ? <a
                                        className={`nav-item nav-link ${pathname === '/Messaging/Sms' ? 'active' : ''}`}
                                        onClick={() => this.viewNotification('sms')}


                                    >SMS</a>
                                    : null
                            }
                        </div>
                    </div>

                    {pathname !== '/Messaging/Sms'
                        ? //EMAIL Section
                        <EmailContainer
                            listParams={listParams}
                            messageList={messageList}
                            handleDeleteMessage={this.handleDeleteMessage}
                            totalMessages={totalMessages}
                            currentPage={currentPage}
                            hideSuccessModal={this.handlePagination}
                            handlePagination={this.hideSuccessModal}
                            pathname={pathname}
                            showSuccess={showSuccess}
                            handleCheck={this.handleCheck}
                            deleteMessages={this.deleteMessages}
                            deleteArray={this.state.deleteArray}
                        />
                        : //SMS Section
                        <SmsContainer
                            chatList={chatList}
                            handleChatSelect={this.handleChatSelect}
                            chatReceiverId={this.state.chatReceiverId}
                            currentRecName={this.state.currentRecName}
                            query={query}
                            handleRemoveNewChat={this.handleRemoveNewChat}
                            renderNewChat={this.renderNewChat}
                            tempTenantPhoneNumbers={tempTenantPhoneNumbers}
                            chatMessageDetails={chatMessageDetails}
                            chatMessage={this.state.chatMessage}
                            keyPressed={this.handleKeyPressed}
                            handleSendMessage={this.handleSendMessage}
                            manageMessageOnChange={this.manageMessageOnChange}
                            hasNotRecSmsResp={this.state.hasNotRecSmsResp}
                            tempMessageContent={this.state.tempMessageContent}
                            handleCheck={this.handleCheckMessage}
                            checkedArray={this.state.checkedArray}
                            openDeleteMessageConfirm={this.openDeleteMessageConfirm}
                            isCheckAll={this.state.isCheckAll}
                            handleCheckAllMessage={this.handleCheckAllMessage}
                            deleteSelectedMessage={this.deleteSelectedMessage}
                            chatReceiverPhoneNo={this.state.chatReceiverPhoneNo}

                        />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    const { messageReducer, profileReducer, commonReducer } = state;
    return { messageReducer, profileReducer, commonReducer }
};

export default withRouter(connect(mapStateToProps, { ...action, ...commonAction })(MessageContainer));
