import React from 'react'

const MessageBox = props => {
    return (
        <div className="modal success-modal-container modal-confirm" id="modalId" style={{display:"flex"}}>
            <div className="modal-dialog modal-success">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title}</h4>
                    </div>
                    <div className="modal-body">
                        <p className="text-center">{props.message}</p>
                        <div className="ftr-btn-grp">
                            <button className="btn btn-success" type="button"
                                    onClick={props.onCancel}>Ok
                            </button>
                            {/* <button className="btn btn-grey" type="button"
                                    onClick={props.onCancel}>No
                            </button> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MessageBox
