import * as actions from "./constant";

const initialState = {
    tenant: {},
    tenants: { tenantList: [], success: '' },
    tenantsPropertyById: {},
    tenantsById: {},
    invoicesTypes: {},
    invoiceList: {},
    messages: {},
    lease: {},
    viewLease: {},
    viewLeasePath: {},
    password: {},
    sendLobMailResponse: {},
    closeTenant: {},
    permanentDeleteTenant: {},
    viewNotice: {},
    leaseEdit: {},
    updateRecuredDate: {},
    leaseEdit: {},
    generateInvoiceByTenant: {}
};

export default function tenantsReducer(state = initialState, action) {
    switch (action.type) {

        case actions.ADD_TENANT_REQUEST:
            return {
                ...state
            };

        case actions.ADD_TENANT_SUCCESS:
            return {
                ...state,
                tenant: { success: true, ...action.tenantAction, message: action.tenantAction.message }
            };

        case actions.ADD_TENANT_FAILURE:
            return {
                ...state,
                tenant: { success: false, ...action.error }
            };

        case actions.GET_TENANT_REQUEST:
            return {
                ...state
            };

        case actions.GET_TENANT_SUCCESS:
            return {
                ...state,
                tenants: {
                    success: true,
                    tenantList: action.tenantAction.tenantList,
                    count: action.tenantAction.totalTenantListCount,
                    totalCount: action.tenantAction.totalTenant
                }
            };

        case actions.GET_TENANT_FAILURE:
            return {
                ...state,
                tenants: { success: false, ...action.error }
            };

        case actions.GET_TENANT_ID_REQUEST:
            return {
                ...state
            };

        case actions.GET_TENANT_ID_SUCCESS:
            return {
                ...state,
                tenantsById: {
                    success: true,
                    tenant: action.tenantAction,
                }
            };

        case actions.GET_TENANT_ID_FAILURE:
            return {
                ...state,
                tenantsById: { success: false, ...action.error }
            };

        case actions.GET_TENANT_PROPERTY_REQUEST:
            return {
                ...state
            };

        case actions.GET_TENANT_PROPERTY_SUCCESS:
            return {
                ...state,
                tenantsPropertyById: {
                    success: true,
                    tenant: action.tenantAction.tenant,
                }
            };

        case actions.GET_TENANT_PROPERTY_FAILURE:
            return {
                ...state,
                tenantsPropertyById: { success: false, ...action.error }
            };


        case actions.DELETE_PERMANENT_TENANT_REQUEST:
            return {
                ...state
            };

        case actions.DELETE_PERMANENT_TENANT_SUCCESS:
            return {
                ...state,
                permanentDeleteTenant: {
                    success: true,
                    message: ' Tenant account deleted permanently'
                }
            };

        case actions.DELETE_PERMANENT_TENANT_FAILURE:
            return {
                ...state,
                permanentDeleteTenant: { success: false, ...action.error }
            };
        case actions.DELETE_TENANT_REQUEST:
            return {
                ...state
            };


        case actions.DELETE_TENANT_SUCCESS:
            return {
                ...state,
                closeTenant: {
                    success: true
                }
            };

        case actions.DELETE_TENANT_FAILURE:
            return {
                ...state,
                closeTenant: { success: false, ...action.error }
            };

        case actions.INVOICE_TYPE_REQUEST:
            return {
                ...state
            };

        case actions.INVOICE_TYPE_SUCCESS:
            return {
                ...state,
                invoicesTypes: { success: true, invoices: action.tenantAction.tenant }
            };

        case actions.INVOICE_TYPE_FAILURE:
            return {
                ...state,
                invoicesTypes: { success: false, ...action.error }
            };

        case actions.INVOICE_REQUEST:
            return {
                ...state
            };

        case actions.INVOICE_SUCCESS:
            return {
                ...state,
                invoiceList: {
                    success: true,
                    paid: action.tenantAction.Paid,
                    overdueAmount: action.tenantAction.overdueAmounts,
                    unPaid: action.tenantAction.unPaid,
                    totalDue: action.tenantAction.totalDue,
                    balance: action.tenantAction.balance,
                    invoice: action.tenantAction.Invoices,
                    tenantId: action.tenantAction.tenant,
                    lease: action.tenantAction.lease,
                    invoiceCount: action.tenantAction.invoiceCount
                }
            };

        case actions.INVOICE_FAILURE:
            return {
                ...state,
                invoiceList: { success: false, ...action.error }
            };

        case actions.MESSAGE_TENANT_REQUEST:
            return {
                ...state
            };

        case actions.MESSAGE_TENANT_SUCCESS:
            return {
                ...state,
                messages: {
                    success: true,
                    messages: action.tenantAction.message,
                    totalMessages: action.tenantAction.count
                }
            };

        case actions.MESSAGE_TENANT_FAILURE:
            return {
                ...state,
                messages: { success: false, ...action.error }
            };

        // case actions.DELETE_TENANT_FILE_REQUEST:
        //   return {
        //     ...state
        //   };

        // case actions.DELETE_TENANT_FILE_SUCCESS:
        //   return {
        //     ...state,
        //     tenantsById: {
        //       success: true,
        //      // tenant: { tenant: { file: state.tenantsById.tenant.tenant.file.filter((tnt) => tnt._id != action.id) } },
        //       message: "Successfully deleted the file"
        //     }
        //   };

        // case actions.DELETE_TENANT_FILE_FAILURE:
        //   return {
        //     ...state,
        //     tenants: { success: false, ...action.error }
        //   };

        // case actions.DELETE_INVOICE_REQUEST:
        //   return {
        //     ...state
        //   };

        // case actions.DELETE_INVOICE_SUCCESS:
        //   return {
        //     ...state,
        //     tenantsById: {
        //       success: true,
        //        tenant: { Invoices: state.tenantsById.tenant.Invoices.filter((tnt) => tnt._id != action.id) },
        //       message: "Successfully deleted the file"
        //     }
        //   };

        // case actions.DELETE_INVOICE_FAILURE:
        //   return {
        //     ...state,
        //     tenants: { success: false, ...action.error }
        //   };

        case actions.LEASE_TENANT_REQUEST:
            return {
                ...state
            };

        case actions.LEASE_TENANT_SUCCESS:
            return {
                ...state,
                lease: {
                    success: true,
                    message: action.tenantAction.message,
                    lease: action.tenantAction.data.documentData
                }
            };

        case actions.LEASE_TENANT_FAILURE:
            return {
                ...state,
                lease: { success: false, ...action.error }
            };


        case actions.LEASE_TENANT_EDIT_REQUEST:
            return {
                ...state
            };

        case actions.LEASE_TENANT_EDIT_SUCCESS:
            return {
                ...state,
                leaseEdit: { success: true, ...action.tenantAction.data.template }
            };

        case actions.LEASE_TENANT_EDIT_FAILURE:
            return {
                ...state,
                leaseEdit: { success: false, ...action.error }
            };

        case actions.VIEW_LEASE_TENANT_REQUEST:
            return {
                ...state
            };

        case actions.VIEW_LEASE_TENANT_SUCCESS:
            return {
                ...state,
                viewLease: {
                    success: true,
                    lease: action.tenantAction.data.template
                }
            };

        case actions.VIEW_LEASE_TENANT_FAILURE:
            return {
                ...state,
                viewLease: { success: false, ...action.error }
            };

        case actions.VIEW_LEASE_REQUEST:
            return {
                ...state
            };

        case actions.VIEW_LEASE_SUCCESS:
            return {
                ...state,

                viewLeasePath: {
                    success: true,
                    lease: action.tenantAction.data.leasePath
                }
            };

        case actions.VIEW_LEASE_FAILURE:
            return {
                ...state,
                viewLeasePath: { success: false, ...action.error }
            };

        case actions.CREATE_PASSWORD_REQUEST:
            return {
                ...state
            };

        case actions.CREATE_PASSWORD_SUCCESS:
            return {
                ...state,

                password: {
                    success: true,
                    message: "Password successfully saved"
                }
            };

        case actions.CREATE_PASSWORD_FAILURE:
            return {
                ...state,
                password: { success: false, ...action.error }
            };

        case actions.SEND_LOB_MAIL_REQUEST:
            return {
                ...state,
                invoicePayment: {
                    isLobMailLoading: true
                },
            };

        case actions.SEND_LOB_MAIL_SUCCESS:
            return {
                ...state,
                sendLobMailResponse: { success: true, ...action.data },
            };

        case actions.SEND_LOB_MAIL_FAILURE:
            return {
                ...state,
                sendLobMailResponse: {
                    success: false,
                    ...action.error
                }
            };

        case actions.VIEW_NOTICE_REQUEST:
            return {
                ...state
            };

        case actions.VIEW_NOTICE_SUCCESS:
            return {
                ...state,
                viewNotice: { success: true, ...action.tenantAction }
            };

        case actions.VIEW_NOTICE_FAILURE:
            return {
                ...state,
                viewNotice: {
                    success: false,
                    ...action.error
                }
            };

        case actions.REOPEN_TENANT_REQUEST:
            return {
                ...state
            };

        case actions.REOPEN_TENANT_SUCCESS:
            return {
                ...state,
                reopenTenant: { success: true, ...action.tenantAction }
            };

        case actions.REOPEN_TENANT_FAILURE:
            return {
                ...state,
                reopenTenant: {
                    success: false,
                    ...action.error
                }
            };
        case actions.ADD_FILE_REQUEST:
            return {
                ...state
            };

        case actions.ADD_FILE_SUCCESS:

            return {
                ...state,
                addFileData: { success: true, ...action }
            };

        case actions.ADD_FILE_FAILURE:
            return {
                ...state,
                addFileData: {
                    success: false,
                    ...action.error
                }
            };
        case actions.DELETE_FILE_REQUEST:
            return {
                ...state
            };

        case actions.DELETE_FILE_SUCCESS:

            return {
                ...state,
                deleteFileResponse: { success: true, ...action.tenantAction }
            };

        case actions.DELETE_FILE_FAILURE:
            return {
                ...state,
                deleteFileResponse: {
                    success: false,
                    ...action.error
                }
            };
        case actions.ADD_TENANT_EMAIL_REQUEST:
            return {
                ...state
            };

        case actions.ADD_TENANT_EMAIL_SUCCESS:
            return {
                ...state,
                tenantEmailAdd: action.tenantAction
            };

        case actions.ADD_TENANT_EMAIL_FAILURE:
            return {
                ...state,
                tenantEmailAdd: { success: false, ...action.error }
            };
        case actions.GET_TENANT_EMAIL_REQUEST:
            return {
                ...state
            };

        case actions.GET_TENANT_EMAIL_SUCCESS:
            return {
                ...state,
                tenantEmailDetails: { success: true, ...action.tenantAction.data }

            };

        case actions.GET_TENANT_EMAIL_FAILURE:
            return {
                ...state,
                tenantEmailDetails: { success: false, ...action.error }
            };
        case actions.DELETE_TENANT_EMAIL_REQUEST:
            return {
                ...state
            };

        case actions.DELETE_TENANT_EMAIL_SUCCESS:
            return {
                ...state,
                tenantEmailDelete: {
                    success: true,
                    ...action.tenantAction.data
                }
            };

        case actions.DELETE_TENANT_EMAIL_FAILURE:
            return {
                ...state,
                tenantEmailDelete: { success: false, ...action.error }
            };

        case actions.ADD_RECURED_DATE_REQUEST:
            return {
                ...state
            };

        case actions.ADD_RECURED_DATE_SUCCESS:
            return {
                ...state,
                updateRecuredDate: {
                    success: true,
                    ...action.tenantAction
                }
            };

        case actions.ADD_RECURED_DATE_FAILURE:
            return {
                ...state,
                updateRecuredDate: { success: false, ...action.error }
            };

        case actions.GENERATE_TENANT_INVOICE_REQUEST:
            return {
                ...state
            };

        case actions.GENERATE_TENANT_INVOICE_SUCCESS:
            return {
                ...state,
                generateInvoiceByTenant: {
                    success: true,
                    ...action.tenantAction
                }
            };

        case actions.GENERATE_TENANT_INVOICE_FAILURE:
            return {
                ...state,
                generateInvoiceByTenant: { success: false, ...action.error }
            };

        // generateInvoiceByTenant
        default:
            return state;
    }
}

