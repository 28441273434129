import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import AsyncSelect from 'react-select/async';


import commonAction from "../../Common/action";
import action from "../action";
import tenantAction from '../../Tenants/action';
import CommonDatePicker from '../../Common/components/CommonDatePicker'

const ExpenseFilter = (props) => {

    const [params, setParams] = useState({
        propertyId: null,
        unitId: null,
        amount: null,
        businessId: null,
        merchantId: null,
        categoryId: null,
        expenseFile: null,
        fromDate: null,
        isArchived: false,
        tenantDetails: null,
        propertyDetails: null
    })

    useEffect(() => {
        async function renderCall() {
            await props.getProperty()
            await props.getExpenseCategory()
            await props.getBusiness()
            await props.getMerchant()
            await props.getTenant({ limit: 10, isDeleted: false, isClosed: false })
            await props.getAllUnits()
        }

        renderCall();
    }, []);

    const filterData = async () => {
        await props.filterExpense(params)  
    }

    const handleChange = async (e) => {

        const { name, value } = e.target
        if (name === "propertyId") {
            await props.getUnitsByPropertyId(value)
            await props.getTenant({ [name]: value, isDeleted: false, isClosed: false })
        }
        if (name === "unitId") {
            await props.getTenant({ [name]: value, isDeleted: false, isClosed: false, isClosed: false  })
        } else if (name === "isArchived") {
            if (e.target.checked) {
                setParams({
                    ...params,
                    [name]: true
                })
            } else {
                setParams({
                    ...params,
                    [name]: false
                })
            }
        } else {
            setParams({
                ...params,
                [name]: value
            })
        }

    }

    const handleChangeDate = (name, value) => {
        let date = moment(value).format("YYYY-MM-DD")
        setParams({
            ...params,
            [name]: date
        })
    }



    const clearFilterData = () => {
        setParams({
            propertyId: "",
            unitId: "",
            amount: null,
            // businessId: null,
            // merchantId: null,
            categoryId: "",
            expenseFile: "",
            fromDate: "",
            toDate: "",
            tenantId: "",
            isArchived: false,
            tenantDetails: "",
            propertyDetails: " "
        })
    }

    const promiseOptionsTenant = async val => {
        await props.getTenant({ name: val, isDeleted: false, isClosed: false })
        const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));
        return tenantList.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeTenant = (value) => {
        setParams({
            ...params,
            tenantDetails: value,
            tenantId: value.value
        })
    }

    const { tenantReducer: { tenants } } = props;

    const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
        value: e._id,
        label: e.firstName + " " + e.lastName
    }));

    const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));

    const promiseOptionsProperty = async val => {
        await props.getProperty({ name: val })
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeProperty = async (value) => {
        let tempParams = params
        tempParams.propertyId = value.value
        setParams({
            ...params,
            propertyId: value.value,
            propertyDetails: value
        })
    }




    return (
        <div className="expensefilterSide" style={{ display: 'block' }}>
            <button type="button" className="close" onClick={props.closeFilter}><span aria-hidden="true">×</span></button>
            <div className="formSection outerBg ">

                <div className="col-md-12 row m-0 p-0 fltrOtr">


                    <div className="col-lg-4 col-sm-6 formInp">
                        <label>Property</label>
                        {/*<div className="selOtr">*/}
                        {/*<select className="form-control" name="propertyId"*/}
                        {/*onChange={handleChange} value={params.propertyId}*/}
                        {/*>*/}
                        {/*<option default>Select Property</option>*/}
                        {/*{*/}
                        {/*Properties && Properties.map((e, i) => {*/}
                        {/*return <option value={e.value} key={i}>{e.label}</option>*/}
                        {/*})*/}
                        {/*}*/}
                        {/*</select>*/}
                        {/*</div>*/}
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={Properties}
                            value={params.propertyDetails}
                            loadOptions={promiseOptionsProperty}
                            name="propertyId"
                            onChange={handleChangeProperty}
                        />
                    </div>

                    <div className="col-lg-4 col-sm-6 formInp">
                        <label>Unit</label>

                        <div className="selOtr">
                            <select className="form-control" name={"unitId"}
                                value={params.unitId}
                                onChange={handleChange}>
                                <option>Select Unit</option>
                                {props.commonReducer.unitDetails && props.commonReducer.unitDetails.unitList && props.commonReducer.unitDetails.unitList.length &&
                                    props.commonReducer.unitDetails.unitList.map((unit, key) => {
                                        return <option value={unit._id}>{unit.unitName}</option>
                                    })}
                            </select>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 formInp">
                        <label>Payee</label>
                        <AsyncSelect
                            cacheOptions
                            value={params.tenantDetails}
                            defaultOptions={tenantList}
                            loadOptions={promiseOptionsTenant}
                            name="tenantId"
                            onChange={handleChangeTenant}
                        />
                    </div>

                    <div className="col-lg-4 col-sm-6 formInp">
                        <label>Category</label>
                        <div className="selOtr">
                            <select className="form-control" name={"categoryId"}
                                value={params.categoryId}
                                onChange={handleChange}>
                                <option>Select category</option>
                                {props.commonReducer.categoryList.length ? props.commonReducer.categoryList.map((category, key) => {
                                    return <option value={category._id}>{category.name}</option>
                                }) : null}
                            </select>
                        </div>
                    </div>

                    {/*<div className="col-lg-4 col-sm-6 formInp">*/}
                    {/*<label>Business Entity</label>*/}
                    {/*<div className="selOtr">*/}
                    {/*<select className="form-control" name={"businessId"}*/}
                    {/*value={params.businessId}*/}
                    {/*onChange={handleChange}>*/}
                    {/*<option>Select business entity</option>*/}
                    {/*{props.commonReducer.businessList.length ? props.commonReducer.businessList.map((business, key) => {*/}
                    {/*return <option value={business._id}>{business.name}</option>*/}
                    {/*}) : null}*/}
                    {/*</select>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    {/*<div className="col-lg-4 col-sm-6 formInp">*/}
                    {/*<label>Bank Account</label>*/}
                    {/*<div className="selOtr">*/}
                    {/*<select className="form-control">*/}
                    {/*<option>Select Bank Account</option>*/}
                    {/*</select>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                </div>

                <div className="col-md-12 otrTenantCheck d-flex">
                    <div className="col-md-12 tenantStatus border-0 d-flex date-pik-outer">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 pdr formInp">
                                <label htmlFor="">From Date</label>
                                {/*<div className="clndr">*/}
                                {/*<input data-date-format="mm/dd/yyyy" id="datepicker" placeholder="From Date"*/}
                                {/*className="date1 mb-4"/>*/}
                                <CommonDatePicker
                                    name="fromDate"
                                    placeholder="From Date"
                                    format="YYYY-MM-DD"
                                    className="form-control"
                                    onChange={(value) => handleChangeDate("fromDate", value)}
                                    value={params.fromDate}
                                    min={new Date()}
                                />
                                {/*</div>*/}
                            </div>
                            <div className="col-xl-6 col-sm-6 pdl formInp">
                                <label htmlFor="">To Date</label>
                                {/*<div className="clndr">*/}
                                {/*<input data-date-format="mm/dd/yyyy" id="datepicker1" placeholder="To Date"*/}
                                {/*className="date1 clndr mb-4"/>*/}
                                <CommonDatePicker
                                    name="toDate"
                                    placeholder="To Date"
                                    format="YYYY-MM-DD"
                                    className="form-control"
                                    onChange={(value) => handleChangeDate("toDate", value)}
                                    value={params.toDate}
                                    min={new Date()}
                                />
                                {/*</div>*/}
                            </div>
                        </div>

                        <h3>Tenant Status</h3>
                        <ul className="tenantstOtr">
                            <li className="formInp">
                                <label className="containerCheck">Archived
                                    <input type="checkbox" name={"isArchived"} onChange={handleChange}
                                        checked={params.isArchived} />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className="btnOtr text-right">
                    <button type="button" className="btn btn-primary btnClear" onClick={clearFilterData}>Clear
                    </button>
                    <button type="button" className="btn btn-primary btnApply" onClick={filterData}>Apply
                    </button>
                </div>
            </div>
        </div>


    )
}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer } = state;
    return {
        commonReducer,
        tenantReducer
    };
}

export default connect(mapStateToProps, { ...commonAction, ...action, ...tenantAction })(ExpenseFilter);
