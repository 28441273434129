import React, { useState, useEffect } from "react";
import parser from 'html-react-parser';
import Modal from "../../Common/components/Modal";
import UploadMOdal from "./uploadModal"
import usePrevious from "../../Common/components/prevProps";
import toastr from '../../Common/components/Toaster'
import LoaderSpinner from '../../Common/components/Loader';
import history from "../../../config/history"
import { connect } from "react-redux";
import action from "../action";
import tenantAction from "../../Tenants/action";
import commonAction from '../../Common/action';



const SignLease = (props) => {
    const [modal, setModal] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const { lease, leaseId } = props;

    const prevLeaseAgreementUpdate = usePrevious(props.leaseAgreementUpdate);

    const prevLeaseAgreement = usePrevious(props.leaseAgreement);

    useEffect(() => {
        if (isSubmitted && prevLeaseAgreementUpdate !== props.leaseAgreementUpdate && !props.leaseAgreementUpdate.success) {
            toastr.error(props.leaseAgreementUpdate.message)
            props.onCancel()

        } else if (isSubmitted && prevLeaseAgreementUpdate !== props.leaseAgreementUpdate && props.leaseAgreementUpdate.success) {
            toastr.success("Signature uploaded successfully!!");
            // let btn = document.getElementById("btnSubmit")
            // btn.disabled = true

        }
    }, [prevLeaseAgreementUpdate, props.leaseAgreementUpdate]);

    useEffect(() => {
        if (props.lease) {
            async function renderCall() {
                var inputs = document.getElementsByTagName("p");
                for (var i = 0; i < inputs.length; i++) {
                    inputs[i].innerHTML = inputs[i].innerHTML.trim();
                }
                let replaceText = document.getElementsByClassName("text-center");

                if (replaceText) {
                    Object.values(replaceText).map((text, key) => {
                        if (text.innerHTML == "**signature_1**" && !props.isTenantSign) {
                            let div = document.createElement('div')
                            let button = document.createElement('button')
                            button.setAttribute("class", "btn btn-primary upldBtn")
                            button.innerHTML = "Signature"
                            button.id = `inpOwner${key}`
                            div.append(button)
                            let img = document.createElement('img')
                            img.id = `imgOwner${key}`
                            img.style.height = "55px"
                            div.append(img)
                            text.replaceWith(div);
                        }
                        else if (text.innerHTML == "**signature_2**" && props.isTenantSign) {
                            let div = document.createElement('div')
                            let button = document.createElement('button')
                            button.setAttribute("class", "btn btn-primary upldBtn")
                            button.innerHTML = "Signature"
                            button.id = `inpTenant${key}`
                            div.append(button)
                            let img = document.createElement('img')
                            img.id = `imgTenant${key}`
                            img.style.height = "55px"
                            div.append(img)
                            text.replaceWith(div);
                        }
                    })
                    let inputtype = document.getElementsByClassName("upldBtn")
                    if (inputtype) {
                        Object.values(inputtype).map((ip, key) => {
                            ip.addEventListener("click", handleUpload);
                        }
                        )
                    }

                    let btn = document.getElementById("btnSubmit")
                    btn.disabled = true

                }
            }
            renderCall();
        }


    }, [lease]);

    const handleUpload = (event) => {
        var currentEventTarget = event.currentTarget.parentNode;
        setModal(true)
    }


    const closeModal = () => {
        setModal(false)
    }

    const renderModal = () => {
        return <UploadMOdal onCancel={closeModal}
            handleImage={handleImage}
            handleSign={handleSign} />
    }

    const handleImage = (e) => {
        setModal(false)
        var FR = new FileReader();
        let url_string = window.location.href
        let url = new URL(url_string);
        let type = url.searchParams.get("type");
        let data = null
        let inputtype = document.getElementsByClassName("upldBtn")
        if (inputtype) {
            Object.values(inputtype).map((ip, key) => {
                let isDisabled = ip.getAttribute('disabled')
                if (!isDisabled) {
                    var img = ip.parentNode.getElementsByTagName("img");
                    if (img) {
                        let imgId = []
                        if (img) {
                            Object.values(img).map((data, key) => {
                                imgId = data.getAttribute('id')
                                FR.addEventListener("load", function (e) {
                                    document.getElementById(imgId).src = e.target.result;
                                });


                            })
                        }
                    }
                }

            })
        }
        FR.readAsDataURL(e.target.files[0]);
        let btn = document.getElementById("btnSubmit")
        btn.disabled = false
    }

    const handleSign = (path, signature) => {
        setModal(false)
        var FR = new FileReader();
        let url_string = window.location.href
        let url = new URL(url_string);
        let type = url.searchParams.get("type");
        let data = null
        let inputtype = document.getElementsByClassName("upldBtn")
        if (inputtype) {
            Object.values(inputtype).map((ip, key) => {
                let isDisabled = ip.getAttribute('disabled')

                if (!isDisabled) {
                    var img = ip.parentNode.getElementsByTagName("img");
                    if (img) {
                        let imgId = []
                        if (img) {
                            Object.values(img).map((dataImg, key) => {
                                imgId = dataImg.getAttribute('id')
                                document.getElementById(imgId).src = path;
                                data = document.getElementById(imgId)
                                signature.fromDataURL(path);

                                //   document.getElementById("b64").innerHTML = e.target.result;

                            })
                        }
                    }
                }

            })
        }
        let btn = document.getElementById("btnSubmit")
        btn.disabled = false
    }



    const handleSubmit = async () => {
        setModal(false)
        let url_string = window.location.href
        let url = new URL(url_string);
        let id = url.searchParams.get("id");
        let type = url.searchParams.get("type");
        let inputtype = document.getElementsByClassName("upldBtn")
        let replaceText = document.getElementsByClassName("text-center");
        let isAllImageUploaded = true
        let btn = document.getElementById("btnSubmit")
        btn.disabled = true
        Object.values(replaceText).map((ip, key) => {
            if (ip.innerHTML == "**signature_1**" || ip.innerHTML == "**signature_2**") {
                isAllImageUploaded = false
            }
        })
        if (inputtype) {
            Object.values(inputtype).map((ip, key) => {

                let isDisabled = ip.getAttribute('disabled')
                if (isDisabled) ip.removeAttribute("disabled")
                var img = ip.parentNode.getElementsByTagName("img");
                if (img) {
                    Object.values(img).map((dataImg, key) => {
                        let isImage = dataImg.getAttribute('src')
                    })
                }
                ip.parentNode.removeChild(ip)

            })
            if (isAllImageUploaded) {
                setIsSubmitted(true)
                let data = document.getElementById("ui").innerHTML
                await props.updateLeaseSign({ documentData: data.toString(), status: 2, isOwnerSigned: true, isTenantSigned: true }, { leaseId })
                setIsSubmitted(false)
            }
            else {
                setIsSubmitted(true)
                let isOwnerSigned=false
                let isTenantSigned=false
                let data = document.getElementById("ui").innerHTML

                if(props.isTenantSign){
                    isTenantSigned=true
                    await props.updateLeaseSign({ documentData: data.toString(), status: 1, isTenantSigned: true }, { leaseId })

                }
                else{
                    isOwnerSigned=true
                    await props.updateLeaseSign({ documentData: data.toString(), status: 1, isOwnerSigned: true }, { leaseId })

                }
                setIsSubmitted(false)
            }
        }
        setTimeout(() => props.onCancel(), 500)
    }


    return (

        <div className="modal addTenant leaseAgreement" id="modalId" role="dialog" style={{ display: 'block' }}>
            {modal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="customtest">
                            <h5 className="modal-title" id="exampleModalLabel">Lease Agreement</h5>
                            <div className="leaseDiv">
                                <button className="btn btn-primary sendbutton"
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    id={"btnSubmit"}
                                >
                                    Save
                                 </button>

                            </div>
                        </div>

                        <button type="button" className="close" onClick={() => props.onCancel()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" id={"ui"}>
                        {lease && parser(JSON.parse(lease))}
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer, leaseReducer } = state;
    return {
        commonReducer,
        tenantReducer,
        leaseReducer,
        leaseAgreementUpdate: leaseReducer.leaseAgreementUpdate
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction, ...tenantAction })(SignLease);
