import React, {useState, useEffect} from "react";
import PlaidLinkButton from "react-plaid-link";
import axios from "axios";
import { PLAID_KEY, PLAID_ENV } from "../../../config/constants";


const Confirm = (props) => {

    return (
        <div className="modal success-modal-container modal-confirm " id="modalId" style={{display: "flex"}}>
            <div className="modal-dialog modal-success">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title}</h4>
                    </div>
                    <div className="modal-body">
                        <p className="text-center">{props.message}</p>
                        <div className="ftr-btn-grp">
                            {!props.plaidConfirm ? <>
                                <button className="btn btn-success" type="button"
                                        onClick={props.onConfirm}>Yes
                                </button>
                                <button className="btn btn-grey" type="button"
                                        onClick={props.onCancel}>No
                                </button>
                            </> : <>
                                <PlaidLinkButton
                                    clientName="React Plaid Setup"
                                    env={PLAID_ENV}
                                    product={["auth", "transactions"]}
                                    publicKey={PLAID_KEY}
                                    onExit={props.handleOnExit}
                                    onSuccess={props.handleOnSuccess}
                                    className="btn btn-success plaidbtn"
                                >
                                    <button className="btn btn-success" type="button">Yes
                                    </button>
                                </PlaidLinkButton>
                                <div className={"custom-btn-top"}>
                                    <button className="btn btn-grey" type="button"
                                            onClick={props.onCancel}>No
                                    </button>
                                </div>
                            </>
                            }


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Confirm;
