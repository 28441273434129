import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import { MAIN_ROUTE } from "../../../../config/constants";
import LoaderSpinner from "../../../Common/components/Loader";
import toastr from '../../../Common/components/Toaster';
import TenantDetailLease from '../TenantDetailLease';
import Modal from '../../../Common/components/Modal';
import ConfirmLease from '../ConfirmLease';
import settingsAction from '../../../Settings/action';
import TemplateListing from '../templateListModal';
import LeaseEdit from '../leaseEditModal';
import SignLease from "../../../Leases&Files/components/signModal";
import Confirm from '../../../Common/components/Confirm';


class file extends React.Component {

  state = {
    submitLease: false,
    leaseModal: false,
    sendLeaseLoad: false,
    previewLease: false,
    modal: false,
    isButtonDisabled: false,
    addTenant: false,
    isSubmitted: false,
    templateModal: false,
    isLeaseEdit: false,
    leaseSignModal: false,
    leaseSign: null,
    sendView: false
  }

  componentDidMount = async () => {
    await this.props.getTemplates()
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { tenantReducer: { lease: prevLease, } } = prevProps;
    const { tenantReducer: { lease } } = this.props;
    const { sendLeaseLoad, addTenant, isSubmitted, submitLease } = this.state;
    if (sendLeaseLoad && !lease.success && prevLease != lease) {
      toastr.error(lease.message)
    } else if (sendLeaseLoad && lease.success && prevLease != lease) {
      toastr.success(lease.message);
    }
    if (addTenant && this.props.commonReducer.emailCheck && prevProps.commonReducer.emailCheck != this.props.commonReducer.emailCheck && !this.props.commonReducer.emailCheck.success) {
      toastr.error("Please add a notification email!")
    } else if (addTenant && this.props.commonReducer.emailCheck.success && prevProps.commonReducer.emailCheck != this.props.commonReducer.emailCheck) {
      this.setState({
        modal: false,
        submitLease: false,

      })
      const { settingsReducer: { templateDetails } } = this.props;
      if (templateDetails && templateDetails && templateDetails.length && templateDetails.length === 1) {
        this.setState({
          templateData: templateDetails[0],
          templateId: templateDetails[0]._id,
          isSubmitted: true
        })
        this.props.editLeaseAgreement({ tenantId: this.props.id, templateId: templateDetails[0]._id });
        this.setState({
          isSubmitted: false,
          submitLease: true
        })

      }
      else {
        this.setState({
          isSubmitted: false,
          submitLease: false,
          templateModal: true
        })

      }

    }

    if (isSubmitted && prevProps.tenantReducer.leaseEdit != this.props.tenantReducer.leaseEdit) {
      if (this.props.tenantReducer.leaseEdit.success) {

        this.setState({
          isLeaseEdit: true
        })
      }
      else {
        toastr.error("Error while adding lease")
      }
    }

    if (submitLease && this.props.tenantReducer.lease && !this.props.tenantReducer.lease.success && prevProps.tenantReducer.lease != this.props.tenantReducer.lease) {
      toastr.error(this.props.tenantReducer.lease.message)
    } else if (submitLease && this.props.tenantReducer.lease && this.props.tenantReducer.lease.success && prevProps.tenantReducer.lease != this.props.tenantReducer.lease) {
      toastr.success(this.props.tenantReducer.lease.message);

      this.setState({
        isSign: true,
        leaseSign: this.props.tenantReducer.lease.lease
      })


    }
  }

  viewSignedLease = async () => {
    const { tenantById } = this.props;
    if (tenantById.tenant && tenantById.tenant.agreementSent) {
      this.setState({
        submitLease: true
      });
      await this.props.viewAgreement({ tenantId: this.props.id });
      const { tenantReducer: { viewLeasePath } } = this.props;
      if (viewLeasePath.lease) {
        window.open(MAIN_ROUTE + viewLeasePath.lease, '_blank');
        this.setState({
          submitLease: false
        });
      }
      else {
        this.setState({
          submitLease: false
        });
        toastr.error(viewLeasePath.message)
      }
    }
  }

  viewAgreement = async () => {
    this.setState({ previewLease: true, modal: false })
    const { tenantById } = this.props;
    await this.props.viewLeaseAgreement({ tenantId: this.props.id, templateId: tenantById.tenant.templateId });
    setTimeout(() => {
      this.setState({ leaseModal: true, previewLease: false })
    }, 100);
  }

  closeViewLeaseModal = () => {
    this.setState({ leaseModal: false })
  }

  renderViewLeaseModal = () => {
    return <TenantDetailLease
      onCancel={this.closeViewLeaseModal}
      lease={this.props.tenantReducer.viewLease}
      files={true}
      leaseAgreement={this.state.sendView ? this.sendLeaseAgreement : this.leaseAgreement}
    />
  }
  leaseAgreement = async () => {
    this.setState({ sendLeaseLoad: true, leaseModal: false })
    await this.props.leaseAgreement({ tenantId: this.props.id });
    setTimeout(() => {
      this.setState({ sendLeaseLoad: false })
    }, 500);

  }

  sendLeaseAgreement = async () => {
    this.setState({
      addTenant: true,
      leaseModal: false
    })
    await this.props.emailCheck({ emailType: "notification" })
    this.setState({
      addTenant: false
    })
  }

  onOpenModal = async (id) => {
    // if (this.props.profileDetail.profileDetail && this.props.profileDetail.profileDetail.admin.gmail) {
    if (id) {
      await this.props.getTenantId(id);
      this.setState({
        tenantModal: true,
        viewLeaseAgreement: false
      })
    }
    else {
      await this.props.emailCheck({ emailType: "notification" })
      const { commonReducer: { emailCheck } } = this.props;
      if (emailCheck && emailCheck.success) {
        this.setState({
          tenantModal: true,
          viewLeaseAgreement: false
        })
      }
      else {
        toastr.error("Please add a notification email!")
      }
    }


    // } else {
    //     toastr.error('Please Add a google account to add property!!')
    // }


  }

  onCloseModal = async () => {
    this.setState({
      tenantModal: false,
      tenantId: ''
    })
    await this.props.getTenantId(this.props.id)
  }

  renderModalLease = () => {
    return <ConfirmLease
      title='Lease Agreement'
      message='Do you want to send lease agreement?'
      isButtonDisabled={this.state.isButtonDisabled}
      close={this.closeModal}
      onConfirm={this.sendLeaseAgreement}
      onView={this.viewAgreement}
      edit={true}

    />
  }

  closeModal = async () => {
    this.setState({
      modal: false,
      tenantId: null
    })
    await this.props.getTenantId(this.props.id)
  }

  leaseModal = () => {
    const { settingsReducer: { templateDetails } } = this.props;
    const { tenantById } = this.props;
    if (templateDetails && templateDetails.length) {
      if (tenantById.tenant && tenantById.tenant.templateId) {
        this.setState({
          tenantModal: false,
          modal: true,
          tenantId: this.props.id,
          sendView: true
        })
      }
      else {
        this.setState({
          isButtonDisabled: true,
          sendView: true
        })
        if (templateDetails && templateDetails.length === 1) {
          this.setState({
            tenantModal: false,
            modal: true,
            tenantId: this.props.id,
            templateId: templateDetails[0]._id
          })
        }
        else {
          this.setState({
            modal: true,
          })
        }
      }

    }
    else {
      this.setState({
        message: 'Please add a template to send lease agreement!!'
      })
    }
  }

  renderTemplateList = () => {
    const { settingsReducer: { templateDetails } } = this.props;
    return <TemplateListing
      onCancel={this.closeModalTemplate}
      templateDetails={templateDetails}
      submitTemplate={this.submitTemplate}
      handleChangeTemplate={this.handleChangeTemplate}
      templateId={this.state.templateId}

    />
  };

  handleChangeTemplate = (e, details) => {
    if (e.target.checked) {
      this.setState({
        templateId: details._id,
        templateData: details
      })
    }
    else {
      this.setState({
        templateId: null,
        templateData: null
      })
    }
  }

  submitTemplate = async () => {
    if (this.state.templateId) {
      this.setState({
        modal: false,
        templateModal: false,
        submitLease: true,
        isSubmitted: true
      })

      await this.props.editLeaseAgreement({ tenantId: this.props.id, templateId: this.state.templateId });
      this.setState({
        isSubmitted: false
      })
      await this.props.getTenantId(this.props.id)
      setTimeout(() => {
        this.setState({
          submitLease: false,
          isButtonDisabled: false,
          viewAgreement: false
        })

      }, 100);
    }
    else {
      toastr.error("Please choose a template")
    }
  }

  closeModalTemplate = () => {
    this.setState({
      templateModal: false,
      isButtonDisabled: false,
      isSubmitted: false,

    })
  }

  renderModalLeaseEdit = () => {
    return <LeaseEdit
      templateData={this.props.tenantReducer.leaseEdit}
      onCancel={this.closeModalLeaseEdit}
      addEditedLeaseData={this.addEditedLeaseData}
      editId={this.props.tenantReducer.leaseEdit._id}
    />
  }

  closeModalLeaseEdit = () => {
    this.setState({
      isLeaseEdit: false,
      isButtonDisabled: false
    })
  }

  addEditedLeaseData = async (data) => {
    this.setState({
      isLeaseEdit: false,
      submitLease: true
    })
    await this.props.sendLeaseAgreement(data, { templateId: this.state.templateId, tenantId: this.props.id })
    await this.props.getTenantId(this.props.id)
    this.setState({
      isSubmitted: false,
      submitLease: false
    })

  }

  renderSignConfirm = () => {
    return <Confirm
      title='Sign Lease'
      message='Do you want to sign now?'
      onCancel={this.closeSignConfirm}
      onConfirm={this.signLease}
    />
  }

  closeSignConfirm = () => {
    this.setState({
      isSign: false
    })
  }

  signLease = () => {
    this.setState({
      isSign: false,
      leaseSignModal: true
    })
  }

  renderSignLease = () => {
    return <SignLease
      onCancel={this.closeSignLease}
      lease={this.state.leaseSign.documentData}
      leaseId={this.state.leaseSign._id}
    />
  }

  closeSignLease = async () => {
    this.setState({
      leaseSignModal: false,
    })
    await this.props.getTenantId(this.props.id)

  }

  render() {
    const { tenantById, handleUploadModal } = this.props;
    return (
      <div className="InvoiceOtr outrTabBg col-md-4 fileSec widbx2" >
        {this.state.submitLease && <LoaderSpinner />}
        {this.state.sendLeaseLoad && <LoaderSpinner />
        }
        {this.state.previewLease && <LoaderSpinner />}
        {this.state.leaseModal ? <Modal body={this.renderViewLeaseModal()} onCancel={this.closeViewLeaseModal} /> : null}
        {this.state.modal ? <Modal body={this.renderModalLease()} onCancel={this.closeModal} /> : null}
        {this.state.templateModal ? <Modal body={this.renderTemplateList()} onCancel={this.closeViewLeaseModal} /> : null}
        {this.state.isLeaseEdit ? <Modal body={this.renderModalLeaseEdit()} onCancel={this.closeModalLeaseEdit} /> : null}
        {this.state.leaseSignModal ? <Modal body={this.renderSignLease()} onCancel={this.closeSignLease} /> : null}
        {this.state.isSign ? <Modal body={this.renderSignConfirm()} onCancel={this.closeSignConfirm} /> : null}
        < div className="brdOut" >
          <h3>Files and Leases</h3>
          <div className="btnOtrSec text-center">

            <div className="formSection">
              <button
                className="btnadd viewLease"
                disabled={tenantById.tenant && tenantById.tenant.isClosed}
                onClick={() => this.leaseModal()}
              >
                <i class="fas fa-share-square"></i> Send Lease Agreement
              </button>
              {this.props.id && tenantById.tenant && !tenantById.tenant.templateId ?
                <button
                  className="btnadd viewLease"
                  disabled={true}
                // onClick={() => this.viewAgreement()}
                >
                  <i className="far fa-file-alt"></i> View Lease Agreement
                </button>
                :
                <button
                  className="btnadd viewLease"
                  disabled={(tenantById.tenant && !tenantById.tenant.agreementSent) || tenantById.tenant.isClosed}
                  onClick={() => this.viewSignedLease()}
                >
                  <i className="far fa-file-alt"></i> View Lease Agreement
              </button>
              }
              <button
                className="btnadd viewLease"
                onClick={handleUploadModal}
              >
                <i class="fa fa-upload" aria-hidden="true"></i> Upload File
              </button>
            </div>
          </div>
        </div >
      </div >
    )
  }
}

const mapStateToProps = state => {
  const { tenantReducer, commonReducer, settingsReducer } = state;
  return {
    tenantReducer,
    commonReducer,
    settingsReducer
  };
};

export default connect(mapStateToProps, {
  ...action, ...commonAction, ...settingsAction
})(file);
