import React, {useState, useEffect} from "react";
import action from "../../action";
import {connect} from "react-redux";
import { MAIN_ROUTE } from "../../../../config/constants";
import mailDetails from "../../SupportTickets/Components/mailDetails";

const MaintenanceForm1 = (props) => {

    return (
            <div className="col-md-9 categ-sub-one p-0">
              <div className="boxSec">
                <div className="row">
                  { props.category.length ? props.category.map((e,i)=> {
                    return <div className="col-md-4 categ-box-out" key ={i} onClick={()=>props.onClickCategory(e.categoryId, parseInt(props.level)+1, e.name)}>
                    <div className={e.categoryId == mailDetails.maintenanceCategoryId ? "categoryBox active": "categoryBox"} >
                      {/* <svg>
                      <use xlinkHref={`images/svg-sprite.svg#${e.fileName}`}
                        ></use>
                      </svg> */}
                      <img src= {e.fileName}/>
                      <h4>{e.name.toUpperCase()}</h4>
                      {props.level === 1 && <p>
                        Select this category if the issue relates to
                        drains, faucets, pipes, pumps, sprinkler systems,
                        sewers, septic systems and such.
                      </p>}
                    </div>
                  </div>
                  }):''
                  }

                </div>
              </div>
            </div>

    )
}

const mapStateToProps = state => {
    const {maintenanceReducer, commonReducer, tenantReducer} = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        commonReducer,
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(MaintenanceForm1);

