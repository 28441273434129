import * as Service from './service';
import * as Constant from './constant';
import * as actionTypes from "../Invoice/constant";


const getMaintenance = (params) => {
    const request = () => ({type: Constant.GET_MAINTENANCE_REQUEST});
    const success = response => ({
        type: Constant.GET_MAINTENANCE_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.GET_MAINTENANCE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getMaintenance(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addMaintenance = (value) => {
    let maintenanceDetails = new FormData();
    if (value.maintenanceFile && value.maintenanceFile.length) {
        value.maintenanceFile.map((item, i) => {
            maintenanceDetails.append("file", item)
        })
    }
    // value.maintenanceFile && maintenanceDetails.append('file', value.maintenanceFile);
    maintenanceDetails.append('data', JSON.stringify(value));

    const request = () => ({type: Constant.ADD_MAINTENANCE_REQUEST});
    const success = response => ({
        type: Constant.ADD_MAINTENANCE_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.ADD_MAINTENANCE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addMaintenance(maintenanceDetails);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const updateMaintenance = (value) => {
    let maintenanceDetails = new FormData();
    if (value.maintenanceFile && value.maintenanceFile.length) {
        value.maintenanceFile.map((item, i) => {
            maintenanceDetails.append("file", item)
        })
    }
    maintenanceDetails.append('data', JSON.stringify(value));
    const request = () => ({type: Constant.ADD_MAINTENANCE_REQUEST});
    const success = response => ({
        type: Constant.ADD_MAINTENANCE_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.ADD_MAINTENANCE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateMaintenance(maintenanceDetails);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//make close maintenance payment
const closeMaintenance = (params) => {

    const request = data => ({
        type: Constant.CLOSE_MAINTENANCE_REQUEST,
        data
    });
    const success = data => ({
        type: Constant.CLOSE_MAINTENANCE_SUCCESS,
        data
    });
    const failure = error => ({
        type: Constant.CLOSE_MAINTENANCE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.closeMaintenance(params);
            if (res.status === 200) {
                const maintenanceDetails = res.data;
                await dispatch(success(maintenanceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//delete invoice payment
const deleteMaintenance = (params) => {
    const request = data => ({
        type: Constant.DELETE_MAINTENANCE_REQUEST,
        data
    });
    const success = data => ({
        type: Constant.DELETE_MAINTENANCE_SUCCESS,
        data
    });
    const failure = error => ({
        type: Constant.DELETE_MAINTENANCE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteMaintenance(params);
            if (res.status === 200) {
                const maintenanceDetails = res.data;
                await dispatch(success(maintenanceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const viewMaintenanceById = (params) => {
    const request = data => ({
        type: Constant.VIEW_MAINTENANCE_REQUEST,
        data
    });
    const success = data => ({
        type: Constant.VIEW_MAINTENANCE_SUCCESS,
        data
    });
    const failure = error => ({
        type: Constant.VIEW_MAINTENANCE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.viewMaintenanceById(params);
            if (res.status === 200) {
                const maintenanceDetails = res.data;
                await dispatch(success(maintenanceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const getCategoryList = (params) => {
    const request = data => ({
        type: Constant.GET_MAINTENANCE_CATEGORY_REQUEST,
        data
    });
    const success = data => ({
        type: Constant.GET_MAINTENANCE_CATEGORY_SUCCESS,
        data
    });
    const failure = error => ({
        type: Constant.GET_MAINTENANCE_CATEGORY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getCategoryList(params);
            if (res.status === 200) {
                const maintenanceDetails = res.data;
                await dispatch(success(maintenanceDetails.data));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const maintenanceAdd = (value) => {
    let maintenanceDetails = new FormData();
    // if (value.maintenancePicture && value.maintenancePicture.length) {
    //     value.maintenancePicture.map((item, i)=>{
    //         maintenanceDetails.append("file", item )
    //     })
    // }

    value.maintenancePicture && maintenanceDetails.append('file', value.maintenancePicture);
    value.maintenanceVideo && maintenanceDetails.append('file', value.maintenanceVideo);
    maintenanceDetails.append('data', JSON.stringify(value));

    const request = () => ({type: Constant.MAINTENANCE_ADD_REQUEST});
    const success = response => ({
        type: Constant.MAINTENANCE_ADD_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.MAINTENANCE_ADD_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.maintenanceAdd(maintenanceDetails);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const maintenanceUpdate = (value) => {
    let maintenanceDetails = new FormData();
    // if (value.maintenancePicture && value.maintenancePicture.length) {
    //     value.maintenancePicture.map((item, i)=>{
    //         maintenanceDetails.append("file", item )
    //     })
    // }
    let Data = {...value}
    if(value.isChangedImage){
        Data = {
            ...value,
            maintenancePicture: [],
        }

    }
    if(value.isChangedVideo){
        Data = {
            ...value,
            maintenanceVideo: [],
        }
    }

    value.maintenancePicture && maintenanceDetails.append('file', value.maintenancePicture);
    value.maintenanceVideo && maintenanceDetails.append('file', value.maintenanceVideo);
    maintenanceDetails.append('data', JSON.stringify(Data));

    const request = () => ({type: Constant.MAINTENANCE_ADD_REQUEST});
    const success = response => ({
        type: Constant.MAINTENANCE_ADD_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.MAINTENANCE_ADD_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.maintenanceUpdate(maintenanceDetails);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


const addMaintenanceTicket = (value) => {
    let maintenanceDetails = new FormData();
    if (value.uploadAttachment && value.uploadAttachment.length) {
        value.uploadAttachment.map((item, i) => {
            maintenanceDetails.append("file", item)
        })
    }
    // value.maintenanceFile && maintenanceDetails.append('file', value.maintenanceFile);
    maintenanceDetails.append('data', JSON.stringify(value));

    const request = () => ({type: Constant.EDIT_NOTE_REQUEST});
    const success = response => ({
        type: Constant.EDIT_NOTE_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.EDIT_NOTE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addMaintenanceTicket(maintenanceDetails);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


const getTenantMaintenanceDetails = (tempParams) => {


    const request = () => ({type: Constant.GET_TENANT_MAINTENANCE_REQUEST});
    const success = response => ({
        type: Constant.GET_TENANT_MAINTENANCE_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.GET_TENANT_MAINTENANCE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantMaintenanceDetails(tempParams);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getMaintenanceById = (id) => {


    const request = () => ({type: Constant.GET_MAINTENANCE_DETAILS_REQUEST});
    const success = response => ({
        type: Constant.GET_MAINTENANCE_DETAILS_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.GET_MAINTENANCE_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getMaintenanceById(id);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getAdvancedSearchDetails = (id) => {


    const request = () => ({type: Constant.GET_ADVANCED_CATEGORY_REQUEST});
    const success = response => ({
        type: Constant.GET_ADVANCED_CATEGORY_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.GET_ADVANCED_CATEGORY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getAdvancedSearchDetails(id);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const editNote = (value ,params) => {


    const request = () => ({type: Constant.EDIT_NOTE_REQUEST});
    const success = response => ({
        type: Constant.EDIT_NOTE_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.EDIT_NOTE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.editNote(value ,params);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


export default {
    getMaintenance,
    addMaintenance,
    updateMaintenance,
    closeMaintenance,
    deleteMaintenance,
    viewMaintenanceById,
    getCategoryList,
    maintenanceAdd,
    addMaintenanceTicket,
    getTenantMaintenanceDetails,
    getMaintenanceById,
    getAdvancedSearchDetails,
    editNote,
    maintenanceUpdate
}
