import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

import commonAction from "../../../Common/action";
import action from "../../../Maintenance/action";
import moment from 'moment';


const Messages = props => {

    useEffect(() => {
        props.getTenantMessage()
    }, [])

    return (
        <div
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-3 col-md-6 col-sm-12 occupancy maintenance mb-3 messageOtr">
            <div className="occupancyOtr">
                <div className="headerSection d-flex">
                    <h3>Messages</h3>
                    <div className="viewMore">
                        <img src="/images/more.png"/><span>View All</span>
                    </div>
                </div>
                <ul className="maintul">
                    {props.messageDetailsByTenant && props.messageDetailsByTenant.message && props.messageDetailsByTenant.message.length ?
                        props.messageDetailsByTenant.message.slice(0,3).map((mes) => {
                            return <li className="d-flex active">
                                <div className="imgSec">
                                    <img src="/images/message.png"/>
                                </div>
                                <div className="maintCont mesgCont">
                                    <h4>{mes.from ? mes.from : null}</h4>
                                    <p className="contDtl">
                                        {mes.message ? mes.message : null}
                                    </p>
                                    <p className="textTime">
                                        {moment(mes.createdAt).format('LT')}
                                    </p>
                                </div>
                            </li>

                        }) : <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>}


                </ul>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {commonReducer, profileReducer, maintenanceReducer} = state;
    return {
        messageDetailsByTenant: commonReducer.messageDetailsByTenant,
        profileReducer,
        maintenanceReducer
    };
}

export default withRouter(connect(mapStateToProps, {...commonAction, ...action})(Messages));
