import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import { numberWithCommas } from "../../../Common/components/utils";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonDatePicker from "../../../Common/components/CommonDatePicker";
import usePrevious from "../../../Common/components/prevProps";
import Modal from '../../../Common/components/Modal'
import Confirm from '../../../Common/components/Confirm'
import LoaderSpinner from "../../../Common/components/Loader";
import toastr from '../../../Common/components/Toaster'




export const popperPlacementPositions = [
    'auto',
    'auto-left',
    'auto-right',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start'
]

const InvoiceSplitUp = (props) => {
    const [recurredDate, setDate] = useState(null)
    const [isSubmit, setIsSubmit] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [tenantArray, setArray] = useState([])
    const [confirmModal, setConfirm] = useState(false)
    const [data, setData] = useState({
        date: "",
        id: "",
        name: ""
    })


    const { tenant } = props;
    // const {tenantById: {tenant}} = props;
    const prevTenant = usePrevious(props.tenant);
    const prevReccured = usePrevious(props.tenantReducer.updateRecuredDate)


    useEffect(() => {
        if (prevTenant != props.tenant) {
            // if (tenant && tenant.Invoices && tenant.Invoices.length) {
            //     setArray(tenant.Invoices)
            // }
            // else
            if (tenant && tenant.tenant && tenant.tenant.rent && tenant.tenant.rent.length) {
                let otherInvoice = Object.keys(tenant.tenant).filter(key => key.match(/otherInvoice_/g) ? tenant.tenant[key] : null)
                let invArray = []
                let rentArray = []
                let tempArray = []
                if (otherInvoice && otherInvoice.length) {

                    otherInvoice.map((inv, key) => {
                        if (tenant.tenant[inv][0]) {
                            invArray.push({
                                ...tenant.tenant[inv][0],
                                startDate: (tenant.tenant[inv][0].startDate)
                                    .replace(/-/g, '/')
                                    .replace(/T.+/, ''),
                                dueDate: (tenant.tenant[inv][0].dueDate)
                                    .replace(/-/g, '/')
                                    .replace(/T.+/, '')
                            })
                        }
                    })


                }
                if (tenant && tenant.tenant.rent) {
                    rentArray.push({
                        ...tenant.tenant.rent[0], startDate: (tenant.tenant.rent[0].startDate)
                            .replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        dueDate: (tenant.tenant.rent[0].dueDate)
                            .replace(/-/g, '/')
                            .replace(/T.+/, '')
                    })
                }
                tempArray = [...rentArray, ...invArray]
                console.log("rentArray", tempArray)

                setArray(tempArray)
            }
            else if (tenant && tenant.Invoices && tenant.Invoices.length) {
                setArray(tenant.Invoices)
            }

        }

    }, [prevTenant, tenant])

    useEffect(() => {
        if (isSubmit && prevReccured != props.tenantReducer.updateRecuredDate && !props.tenantReducer.updateRecuredDate.success) {
            toastr.error(props.tenantReducer.updateRecuredDate.message)
        } else if (isSubmit &&
            prevReccured != props.tenantReducer.updateRecuredDate &&
            props.tenantReducer.updateRecuredDate.success) {
            toastr.success(props.tenantReducer.updateRecuredDate.message);
        }
    }, [prevReccured, props.tenantReducer.updateRecuredDate])




    const isCard = tenant && tenant.tenant.cardDetails && tenant.tenant.cardDetails.length ? tenant.tenant.cardDetails.find(tc => tc.defaultCard) : null

    const handleChange = async (e, i, name) => {
        let tempArray = tenantArray
        if (name == "startDate" || name == "dueData") {
            //tempArray[i][name] = moment(e).format('YYYY-MM-DD')
            tempArray[i][name] = e
        }
        else {
            tempArray[i][name] = e
            // moment(e).format('YYYY-MM-DD')

        }
        console.log("tempArray", new Date(e), tempArray)
        setArray(tempArray)
        // await props.editInvoiceTenant([{
        //     id: i,
        //     dueDate: moment(new Date(e)).format('YYYY-MM-DD')
        // }])
        // await props.reloadTenant();
    }

    const handleChangeRecurring = async (e) => {
        setDate(moment(new Date(e)).format('YYYY-MM-DD'))

    }
    let RentData = tenant && tenant.Invoices && tenant.Invoices.length && tenant && tenant.Invoices && tenant.Invoices.find(data => data.defaultInvoice == 2)




    const closeConfirm = () => {
        setConfirm(false)
    }

    const renderConfirm = () => {
        return <Confirm
            title='Change Date'
            message='Do you want to Continue?'
            onCancel={closeConfirm}
            onConfirm={handleApplyConfirm} />
    }

    const handleApply = async (e, date, id, name, defaultInvoice) => {
        e.preventDefault()
        setConfirm(true)
        setData({
            date: date,
            id: id,
            name: name,
            defaultInvoice
        })
    }

    const handleApplyConfirm = async (e, date, id, name) => {
        e.preventDefault()
        setConfirm(false)

        setIsLoader(true)

        setIsSubmit(true)
        if (data.date) {
            let formatCheck = {
                tenantId: props.tenantId,
                invoiceID: data.id,
                // [data.name]: moment(new Date(data.date)).endOf('day').toISOString(),
                [data.name]: moment(data.date).format('MM/DD/YYYY'),
                defaultInvoice: data.defaultInvoice
            }

            await props.addRecuredDate(formatCheck)
            // console.log("Summary format:ttttt", formatCheck ,data.date);
            setDate(null)
            await props.reloadTenant();
            setIsSubmit(false)

        }
        setIsLoader(false)


    }


    return (
        <div className="innago-table table-main expenses-table invSplit">


            {isLoader && <LoaderSpinner />}
            {confirmModal ? <Modal body={renderConfirm()} onCancel={closeConfirm} /> : null}
            <h3>Invoice Spilt Up</h3>
            <div className="ta">
                {(tenant && (tenant.Invoices && tenant.Invoices.length) || (tenant.tenant && tenant.tenant.rent)) ?
                    <form autoComplete="off">
                        <table className="table-gap table-td">
                            <tbody>
                                <tr className="table-head">
                                    <th className="th-wid1">Invoice Type</th>
                                    <th className="th-wid2">Amount</th>
                                    <th className="paymentType">Payment Type</th>
                                    <th className="amtSec">Invoice Date</th>
                                    <th className="amtSec">Next Due Date</th>
                                    <th className="billCycle">Billing Cycle</th>
                                </tr>
                                {tenantArray && tenantArray.length ? tenantArray.map((invoice, i) => {

                                    if (invoice.invoiceTypeId.name != "Security Deposit") {
                                        return <tr key={i}>
                                            <td>
                                                {invoice.invoiceTypeId ? invoice.invoiceTypeId.name : null}
                                            </td>
                                            <td><span>${numberWithCommas(invoice.amount)}</span>
                                            </td>
                                            <td>{invoice.paymentTypeId ? invoice.paymentTypeId.name : null}</td>
                                            <td >
                                                <div className="invoiceEditDate">
                                                    <CommonDatePicker
                                                        name="startDate"
                                                        className={"form-control"}
                                                        value={invoice.startDate && new Date(invoice.startDate)}
                                                        placeholderText={"MM/DD/YYYY"}
                                                        onChange={(e) => handleChange(e, i, "startDate")}
                                                        showYearDropdown='true'
                                                        popperModifiers={{
                                                            offset: {
                                                                enabled: true,
                                                                offset: '5px, 10px'
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                                                boundariesElement: 'viewport'
                                                            }
                                                        }}

                                                    />
                                                    <button onClick={(e) => handleApply(e, invoice.startDate, invoice._id, 'startDate', invoice.defaultInvoice)} className={'btn btn-add'} disabled={!invoice.startDate}>Apply</button>
                                                </div>
                                            </td>
                                            <td >
                                                <div className="invoiceEditDate">
                                                    {/* {invoice.dueDate && moment(new Date(invoice.dueDate)).format('YYYY-MM-DD')} */}
                                                    <CommonDatePicker
                                                        name="dueDate"
                                                        className={"form-control"}
                                                        value={invoice.dueDate && new Date(invoice.dueDate)}
                                                        placeholderText={"MM/DD/YYYY"}
                                                        onChange={(e) => handleChange(e, i, "dueDate")}
                                                        showYearDropdown='tramtSecue'
                                                        minDate={invoice.startDate ? new Date(invoice.startDate) : null}
                                                        popperModifiers={{
                                                            offset: {
                                                                enabled: true,
                                                                offset: '5px, 10px'
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                                                boundariesElement: 'viewport'
                                                            }
                                                        }}

                                                    />
                                                    <button onClick={(e) => handleApply(e, invoice.dueDate, invoice._id, 'dueDate', invoice.defaultInvoice)} className={'btn btn-add'} disabled={!invoice.dueDate}>Apply</button>
                                                </div>
                                            </td>
                                            <td>{invoice.isRecurring ? "Monthly" : "One Time"}</td>
                                        </tr>
                                    }
                                }) : <tr>
                                        <td colSpan={6} className={"text-center no_value_table"}><i className="far fa-thumbs-down"></i>No Data Found!!!</td>
                                    </tr>}
                            </tbody>
                        </table>

                    </form>

                    :
                    <div className="no_value"><i className="far fa-thumbs-down"></i> No Data Found!!!</div>
                }

            </div>

            {/* <div class="calndrsecn d-flex">
                <div class="col-md-6 formInp calenRcr">
                    <label>Current Rent Date</label>
                    <div class="react-datepicker-wrapper">
                        <div class="react-datepicker__input-container">
                            <CommonDatePicker

                                name="recurringDate"
                                className="form-control "
                                value={moment(RentData.dueDate).format('MM/DD/YYYY')}
                                readOnly={true}

                            /> <label>( {(moment(RentData.dueDate).format('Do') )} of every month</label>)
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-6 formInp calenRcr justify-content-end">
                    <label>New Rent Date</label>
                    <div class="react-datepicker-wrapper">
                        <div class="react-datepicker__input-container">
                            <CommonDatePicker

                                name="recurringDate"
                                className="form-control "
                                value={recurredDate ? new Date(recurredDate) : null}
                              
                                onChange={(e) => handleChangeRecurring(e)}
                                showYearDropdown='true'
                                popperPlacement="top-end"
                                minDate={new Date()}
                                disabled={!isCard}
                                popperModifiers={{
                                    offset: {
                                        enabled: true,
                                        offset: '5px, 10px'
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                        boundariesElement: 'viewport'
                                    }
                                }}
                            />
                        </div>
                    </div>
                 
                    {isSubmit && !recurredDate ? <div className={"errorMsg"}>*Required</div> : null}
                    <button onClick={handleApply} className={'btn btn-add'} disabled={!recurredDate ||!isCard}>Apply</button>
                </div>

            </div> */}

        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer } = state;
    return {
        tenantReducer,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(InvoiceSplitUp);
