import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import toastr from '../../Common/components/Toaster'

const PublicRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    toastr.clear()
  }, [rest.location.pathname])
  return (
    <Route
      {...rest}
      render={props =>
        (!localStorage.getItem("token") || (JSON.parse(localStorage.getItem("userDetails")).type === "admin")) ? (
          <>
            <Component {...props} />
          </>
        ) : (
            <Redirect to={{ pathname: "/home" }} />
          )
      }
    />
  );
};

export default PublicRoute;
