import React, { Fragment, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import TopHeader from ".//Navigation/topHeader";
import SideMenu from ".//Navigation/sideMenu";
import Footer from ".//Navigation/footer";
import toastr from '../../Common/components/Toaster';
import ErrorPage from '../components/error'

const PrivateRoute = ({ component: Component, ...rest }) => {
    useEffect(() => {
        toastr.clear()
    }, [rest.location.pathname])
    return (
        <>
            {navigator.onLine ?
                <Route
                    {...rest}
                    render={props =>
                        localStorage.getItem("token") ? (
                            <Fragment>
                                <TopHeader />
                                <section className="dashboardOtr">
                                    <div className="otrDtl d-flex">
                                        <SideMenu />
                                        <div className="dtlSec" id="main">
                                            <Component {...props} />
                                            <Footer />
                                        </div>
                                    </div>
                                </section>
                            </Fragment>
                        ) : (
                                <Redirect to={{ pathname: "/" }} />
                            )
                    }
                /> : <ErrorPage />}
        </>
    );
};
export default PrivateRoute;
