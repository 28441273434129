import React, { useEffect, useState } from "react";
import action from "../../action";
import { connect } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../../Common/components/Loader";
import history from "../../../../config/history";
import moment from 'moment';
import base64url from "base64url";
import messageAction from "../../../Messaging/action";
import Pagination from '../../../Common/components/pagination';
import Confirm from '../../../Common/components/Confirm';
import usePrevious from "../../../Common/components/prevProps";

import Modal from '../../../Common/components/Modal';
import maintenanceAction from '../../../Maintenance/action';
import MaintenanceList from '../../../Maintenance/components/maintenanceList';
import commonAction from '../../../Common/action'

const Email = (props) => {

  const [isSubmitted, setIsSubmitted] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageId, setMessageId] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    requestById: props.id

  })
  const [maintenanceModal, setMaintenanceModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckedAll, setCheckedAll] = useState(false)
  const [isButtonActive, setButtonActive] = useState(false)
  const [closeArray, setCloseArray] = useState([])
  const [addMaintenanceSet, setAddMaintenance] = useState(false);
  const [setFilter, setIsFilter] = useState(false);
  const [editData, setEditData] = useState({
    propertyId: null,
    maintenanceFile: [],
    maintenanceFileEdit: [],
    description: null,
    categoryId: null,
    requestById: null,
    isEdit: false,
    isView: false,
    maintenancePicture: [],
    status: 1

  })

  const prevLimit = usePrevious(props.commonReducer.limitData);



  useEffect(() => {
    props.getLimitData()
  }, []);



  useEffect(() => {
    window.scroll(0, 0)

    if (prevLimit != props.commonReducer.limitData) {
      const { limitData } = props.commonReducer
      if (limitData) {
        let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 6)
        let tempParams = params
        tempParams.limit = limitCount ? limitCount.count : 15
        setParams(tempParams)

        async function renderCall() {
          await props.getMaintenance(tempParams)
        }

        renderCall();
        setTimeout(() => {
          setIsLoading(false)
        }, 100)
      }

    }


  }, [prevLimit, props.commonReducer.limitData]);


  const editMaintenance = async (maintenance) => {
    history.push(`/Add-Maintenance/${maintenance._id}`)
  }

  // const prevUser = usePrevious(props.Invoice);
  // const prevInvoiceList = usePrevious(props.InvoiceList.invoice);

  useEffect(() => {
    async function renderCall() {
      await props.getMaintenance(params);
    }
    renderCall();
    setTimeout(() => {
      setIsSubmitted(false)
    }, 1000)

  }, [props.id]);

  const openConfirmModal = (id) => {
    setConfirmModal(true);
    setMessageId(id)
  }

  const closeModal = () => {
    setConfirmModal(false);
  }

  const renderConfirmModal = () => {
    return <Confirm
      title='Delete Message'
      message='Do you want to delete this message?'
      onCancel={closeModal}
      onConfirm={handleDeleteMessage}
    />
  }

  const handleDeleteMessage = async () => {
    await props.deleteMessage(messageId);
    setConfirmModal(false);
    await props.getMessageByTenantId(props.id, params);

  };

  const onPageChanged = async page => {
    let data = { ...params }
    let offset = ((page - 1) * params.limit) || 0;
    data.offset = offset;
    setParams(data)
    setcurrentPage(page)
    await props.getMessageByTenantId(props.id, { limit: params.limit, offset: offset });
  }

  const viewMaintenance = (maintenance) => {
    let viewData = {
      _id: maintenance._id,
      propertyId: maintenance.propertyId._id,
      maintenanceFile: [],
      maintenancePicture: maintenance.maintenancePicture,
      description: maintenance.description,
      categoryId: maintenance.categoryId._id,
      requestById: maintenance.requestById._id,
      isView: true,
      status: JSON.stringify(maintenance.status),
      amount: maintenance.amount,
      paymentId: maintenance.paymentId && maintenance.paymentId._id,
      propertyOwnerName: maintenance.paymentId && maintenance.paymentId.entityName,
      requestBy: {
        value: maintenance.requestById._id,
        label: maintenance.requestById.firstName + " " + maintenance.requestById.lastName
      }
    }
    setEditData(viewData)
    setMaintenanceModal(true)
  }

  const handleCheck = (e, maintenance) => {
    let tempArray = closeArray
    if (e.target.checked) {
      setButtonActive(true)
      let data = {
        id: maintenance._id,
      }
      tempArray.push(data)
      return setCloseArray(tempArray)
    } else {
      setCheckedAll(false)
      tempArray = tempArray.filter(t => t.id !== maintenance._id)
      if (!tempArray.length) setButtonActive(false)
      return setCloseArray(tempArray)
    }
  }

  const handleCheckAll = (e) => {
    let tempArray = []
    if (e.target.checked) {
      setButtonActive(true)
      setCheckedAll(true)
      return props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList.length ?
        props.maintenanceReducer.maintenanceList.maintenanceList.map((maintenance, key) => {

          if (maintenance.status) {
            let data = {
              id: maintenance._id,
              status: 1,
            }
            tempArray.push(data)
            setCloseArray(tempArray)
          }
        })
        : null
    } else {
      setCloseArray([])
      setButtonActive(false)
      setCheckedAll(false)
    }

  }

  const handleDelete = async () => {
    setIsSubmitted(true)
    setDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setDeleteModal(false)
    setCloseArray([])
    setCheckedAll(false)
    setIsSubmitted(false)
  }

  const renderDeleteModal = () => {
    return <Confirm
      title='Delete Maintenance'
      message='Do you want to delete this maintenance?'
      onCancel={closeDeleteModal}
      onConfirm={deleteMaintenance}
    />
  }

  const deleteMaintenanceById = (maintenance) => {
    let tempArray = []
    let data = {
      id: maintenance._id,
    }
    tempArray.push(data)
    setCloseArray(tempArray)
    setIsSubmitted(true)
    setDeleteModal(true)
  }

  const deleteMaintenance = async () => {
    setIsSubmitted(true)
    await props.deleteMaintenance(closeArray)
    setCloseArray([])
    setCheckedAll(false)
    await props.getMaintenance(params);
    setDeleteModal(false)
    setIsSubmitted(false)
  }

  const closeMaintenanceData = async () => {
    setIsSubmitted(true)
    await props.closeMaintenance(closeArray)
    setCloseArray([])
    setCheckedAll(false)
    await props.getMaintenance(params);
    setIsSubmitted(false)
  }

  const handleChangeLimit = async (e) => {
    const { name, value } = e.target
    let tempParams = params
    tempParams.limit = value
    tempParams.offset = 0
    setParams(tempParams)
    await props.updateLimitData({ type: 6, count: value });
    await props.getMaintenance(tempParams);
  }

  const filterData = async (value) => {
    let tempParams = params
    if (value) {
      tempParams.sort = value
      let orderDetails = !params.orderData
      tempParams.order = orderDetails ? "ASC" : "DESC"
      tempParams.orderData = !params.orderData
    }
    setParams(tempParams)
    setIsLoading(true)
    await props.getMaintenance(tempParams)
    setTimeout(() => {
      setIsLoading(false)

    }, 1000)
  }


  const { tenantReducer: { messages } } = props;

  return (
    <div className="messageOuter outrTabBg">
      {confirmModal ? <Modal body={renderConfirmModal()} onCancel={closeModal} /> : null}
      {(isSubmitted || isLoading) && <LoaderSpinner />}
      {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}

      {/* <ToastContainer /> */}
      {isSubmitted && <LoaderSpinner />}
      <div className="emailOuter">
        <div className="inboxInner">
          <div className="email-img">
            <span>SupportTickets</span>
          </div>
          {/* <div className="email-btn">
            <button className="email" id="send-email">
              Send Emails
            </button>
          </div> */}
          {props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList
            && props.maintenanceReducer.maintenanceList.maintenanceList.length ? <div className="maintenancerightBtn">
              <div className="formsectionPadding selTenant pr-2">
                <div className="selctOtr">
                  {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                  <select className="form-control" value={params.limit} name={"limit"}
                    onChange={handleChangeLimit}>
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <button className="btn btnClose" onClick={closeMaintenanceData} disabled={!isButtonActive}>Close
                </button>
              <button className="btn btnDelete" onClick={handleDelete} disabled={!isButtonActive}>Delete</button>
            </div> : null}
        </div>
        {props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList.length ? <div className={`innago-table table-main invoiceTable  maintenanceTable ${props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList
          && props.maintenanceReducer.maintenanceList.maintenanceList.length ? "remarw" : "addarw"}`}>
          <MaintenanceList
            maintenanceList={props.maintenanceReducer.maintenanceList}
            editMaintenance={editMaintenance}
            viewMaintenance={viewMaintenance}
            handleCheck={handleCheck}
            handleCheckAll={handleCheckAll}
            closeArray={closeArray}
            isButtonActive={isButtonActive}
            isCheckedAll={isCheckedAll}
            handleDelete={handleDelete}
            closeMaintenanceData={closeMaintenanceData}
            deleteMaintenanceById={deleteMaintenanceById}
            filterData={filterData}

          />
          <div className="paginationSec">
            <ul className="pagination">
              {props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.totalCount ?
                <Pagination
                  totalRecords={props.maintenanceReducer.maintenanceList.totalCount}
                  pageLimit={params.limit}
                  pageNeighbours={1}
                  currentPage={currentPage}
                  onPageChanged={onPageChanged}
                /> : null}
            </ul>
          </div>
        </div> : <div className="no_value">
            <i className="far fa-thumbs-down" />No Data Found!!!
                                    </div>}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const { messageReducer, tenantReducer, maintenanceReducer, commonReducer } = state;
  return {
    messageReducer,
    tenantReducer,
    maintenanceReducer,
    commonReducer
  };
}

export default connect(mapStateToProps, { ...action, ...messageAction, ...maintenanceAction, ...commonAction })(Email);


