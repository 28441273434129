import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import action from "../action";
import tenantAction from '../../Tenants/action'
import commonAction from '../../Common/action'
import usePrevious from "../../Common/components/prevProps";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toastr from '../../Common/components/Toaster'
import * as validation from '../../Common/components/validation';
import {MAIN_ROUTE} from "../../../config/constants";
import isImage from "is-image";
import LoaderSpinner from "../../Common/components/Loader";
import {formatUsPhone} from "../../Common/components/utils";

const EditProfile = (props) => {

    const [logoChanged, setLogoChanged] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [params, setParams] = useState({limit: 5, offset: 0});

    const [profile, setProfile] = useState({
        firstName: null,
        lastName: '',
        email: '',
        address: '',
        profilePic: '',
        phoneNumber: '',
        permanentAddress: '',
        propertyAddress:''
    })

    useEffect(() => {
        const {profileDetail, userRole} = props;
        if (userRole === 'tenant') {
            props.getTenantProperty(params)
        }
        if (profileDetail) {
            setProfile({
                firstName: profileDetail.admin.firstName,
                lastName: profileDetail.admin.lastName,
                email: profileDetail.admin.email,
                address: profileDetail.admin.address,
                profilePic: userRole === 'tenant' && profileDetail.admin && profileDetail.admin.length
                    ? profileDetail.admin[0].profilePic
                    : profileDetail.admin.profilePic && profileDetail.admin.profilePic.length ? (MAIN_ROUTE + profileDetail.admin.profilePic[0].path) : '',
                permanentAddress: profileDetail.admin.tenantId && profileDetail.admin.tenantId.permanentAddress,
                propertyAddress: profileDetail.admin.tenantId && profileDetail.admin.tenantId.propertyId.address,
                phoneNumber: profileDetail.admin.phoneNumber.replace('+1', '')
            })
        }
    }, []);

    const {profileReducer: {profiles}} = props;

    const prevProfile = usePrevious(profiles);

    useEffect(() => {
        if (isSubmitted && !profiles.success && profiles.profileDetail != prevProfile.profileDetail) {
            toastr.error(profiles.message)
        } else if (isSubmitted && profiles.success && profiles.profileDetail != prevProfile.profileDetail) {
            toastr.success("Successfully updated");
        }
    }, [profiles.profileDetail, prevProfile]);

    const handleChange = (e) => {
        const {name, value} = e.target
        if (name === 'phoneNumber') {
            setProfile({
                ...profile,
                [name]: value
            })
        } else {
            setProfile({
                ...profile,
                [name]: value
            })
        }
    }

    //set profilePic  on adding image
    const logoImage = e => {
        if (e.target.files.length) {
            if (isImage(e.target.files[0].name)) {
                setProfile({
                    ...profile,
                    profilePic: e.target.files[0]
                })
                setLogoChanged(true)
            }
        }
    };

    const validate = (value, name, type) => {
        if (submit) {
            switch (type) {
                case "required":
                    return validation.REQUIRED(value, name) != true &&
                        <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
                case "number":
                    return validation.NUMBER_ONLY(value, name) != true &&
                        <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
                case "letter":
                    return validation.LETTER_ONLY(value, name) != true &&
                        <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "email":
                    return validation.EMAIL(value, name) != true &&
                        <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div>
                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) ?
                            <div className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div>
                            :
                            null
                    )
            }
        } else return null
    }

    const onSubmit = async (e) => {
        const {userRole, profileDetail} = props;
        setSubmit(true);
       
        if (firstName && email && lastName && phoneNumber) {
            if (validation.LETTER_ONLY(firstName, 'firstName') == true &&
                validation.LETTER_ONLY(lastName, 'lastName') == true &&
                validation.PHONE_NO(phoneNumber, 'lastName') == true
            ) {
                const params = {
                    ...profile,
                    phoneNumber: phoneNumber ? phoneNumber.replace(/\D/g, '') : '',
                }

                // For admin
                if ((typeof params.profilePic == 'string')) {
                    delete params.profilePic
                }
                setIsSubmitted(true)
                // For tenant user
                // if (userRole === 'tenant') {
                //     params.permanentAddress = permanentAddress
                //     params.id = profileDetail.admin && profileDetail.admin._id
                //     params.profilePicture = [profilePic] || []
                // }
                // setIsSubmitted(true);
                // if (userRole === 'admin' ) {
                //     await props.editProfile(params);
                // } else if(userRole === 'tenant'){
                //     await props.addTenant(params);
                //     await props.getTenantProperty(params)
                // }
                await props.editProfile(params);
                await props.getProfile();
                setTimeout(() => {
                    setIsSubmitted(false)
                    setSubmit(false)
                }, 1000)
            }
        }
    }

    const handleAddressSearch = address => {
        if (address) {
            setProfile({
               ...profile,
                permanentAddress: address

            })


        } else {
            setProfile({
                ...profile,
                permanentAddress: ''
            })
        }
    }


    const handleSelect = address => {
        setProfile({
            ...profile,
            permanentAddress: address
        })
    }

    const renderAddress = () => {
        const {profileDetail, commonReducer: {propertyDetailsByTenant}} = props;
        if (userRole === 'tenant') {
            return (
                <>
                    <label>Permanent Address</label>
                    {/*<textarea*/}
                    {/*    className="form-control textareaHeight"*/}
                    {/*    placeholder="Permanent Address"*/}
                    {/*    id=""*/}
                    {/*    cols="30"*/}
                    {/*    rows="10"*/}
                    {/*    name="permanentAddress"*/}
                    {/*    value={permanentAddress}*/}
                    {/*    onChange={handleChange}*/}
                    {/*></textarea>*/}
                    <PlacesAutocomplete
                        value={permanentAddress}
                        onChange={handleAddressSearch}
                        onSelect={handleSelect}
                        // searchOptions={
                        //     { types: 'address'}
                        // }
                    >
                        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                            <div className="autocompleteOuter">
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Address',
                                        className: 'location-search-input form-control',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div className="loader">Loading...</div>}
                                    {suggestions.map((suggestion, suggKey) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className
                                                })}
                                            >
                                                                            <span
                                                                                key={suggKey}>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <label>Property Address</label>
                    <textarea
                        className="form-control textareaHeight"
                        placeholder="Property Address"
                        id=""
                        cols="30"
                        rows="10"
                        name="propertyAddress"
                        disabled
                        value={
                            profile.propertyAddress
                        }
                        onChange={handleChange}
                    ></textarea>
                </>
            )

        } else {
            return (
                <>
                    <label>Address</label>
                    <textarea
                        className="form-control textareaHeight"
                        placeholder="Address"
                        id=""
                        cols="30"
                        rows="10"
                        name="address"
                        value={address}
                        onChange={handleChange}
                    ></textarea>
                </>
            )
        }
    }

    const getImage = () => {
        const {profileDetail, userRole} = props;

        if (
            profileDetail.admin.profilePic && profileDetail.admin.profilePic.length && profileDetail.admin.profilePic[0].path) {
            return MAIN_ROUTE + profileDetail.admin.profilePic[0].path
        } else {
            return '/images/prof.png'
        }
    }


    const {firstName, lastName, email, phoneNumber, address, profilePic, permanentAddress} = profile;
    const {profileDetail, userRole} = props;

    return (
        <div className="profileSec">
            {isSubmitted && <LoaderSpinner/>}
            {/* <ToastContainer /> */}
            {profileDetail &&
            <div className="col-md-8">
                <div className="profileHead">
                    <h1>Edit Profile</h1>
                </div>
                <div className="profileForm">
                    <div className="formSection">
                        <div className="col-md-12 profileEditSec">
                            {userRole == 'tenant' ? 
                            <div className="profileImg"> {firstName && lastName ? firstName.charAt(0).toUpperCase() +
                                lastName.charAt(0).toUpperCase() : null}</div> : <> <input
                                type="file"
                                className="form-control"
                                id="files"
                                name="profilePic"
                                onChange={(e) => logoImage(e)}
                            />
                                <div className="">
                                    <label htmlFor="files" className="custom-file-upload">
                                        <div className="profileImg">
                                            {logoChanged ?
                                                <img
                                                    src={profilePic ? URL.createObjectURL(profilePic) : '/images/prof.png'}/>
                                                :
                                                // <img src={
                                                //     profileDetail.admin.profilePic &&
                                                //         profileDetail.admin.profilePic.length &&
                                                //         profileDetail.admin.profilePic[0].path
                                                //         ? MAIN_ROUTE + profileDetail.admin.profilePic[0].path :
                                                //         '/images/prof.png'} />
                                                <img src={getImage()}/>
                                            }

                                            {/* <img src="/images/prof.png" /> */}
                                            <span className="editIcon"><i className="fas fa-pen"> </i></span>
                                        </div>
                                    </label>
                                </div>
                            </>}
                        </div>
                        <div className="profileDetail">
                            <h4>{profileDetail.profileDetail && profileDetail.profileDetail.admin.firstName}</h4>
                            <p className="place">{profileDetail.profileDetail && profileDetail.admin.profileDetail.address && profileDetail.profileDetail.admin.address}</p>
                        </div>
                        <div className="row formSec">
                            <div className="col-md-6 formInp">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleChange}
                                    maxLength="25"
                                />
                                {validate(firstName, "First Name", "required")}
                                {firstName && validate(firstName, "Name", "letter")}
                            </div>
                            <div className="col-md-6 formInp">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    name="lastName"
                                    value={lastName}
                                    onChange={handleChange}
                                    maxLength="25"
                                />
                                {validate(lastName, "Last Name", "required")}
                                {lastName && validate(lastName, "Name", "letter")}
                            </div>
                            <div className="col-md-6 formInp">
                                <label>Phone Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={handleChange}
                                    maxLength="10"
                                />
                                {validate(phoneNumber, "Phone Number", "required")}
                                {phoneNumber && validate(phoneNumber, "Phone Number", "number")}
                            </div>
                            <div className="col-md-6 formInp">
                                <label>Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email ID"
                                    name="email"
                                    disabled={true}
                                    value={email}
                                    onChange={handleChange}
                                />
                                {validate(email, "Email", "required")}
                                {email && validate(email, "Email", "email")}
                            </div>
                            <div className="col-md-12 formInp">
                                {renderAddress()}
                            </div>
                            <div className="col-md-12 passwordChange">
                                {userRole == 'tenant' ? null : <p
                                    className="changePasswordText"
                                    onClick={() => props.onOpenModal()}
                                >
                                    Change Password
                                </p>}
                                <div className="formSubmit submitBtn">
                                    <button
                                        type="button"
                                        className="btn btn-primary btnSubmit"
                                        onClick={() => onSubmit()}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}


const mapStateToProps = state => {
    const {profileReducer, commonReducer} = state;
    return {
        profileReducer,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...tenantAction,
    ...commonAction,
    ...action
})(EditProfile);

