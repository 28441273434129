import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import renderHTML from 'react-render-html';

import action from "../../action";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactDOM from 'react-dom';
import CKEditor from 'ckeditor4-react';
import commonAction from '../../../Common/action'
import tenantAction from '../../../Tenants/action'
import toastr from '../../../Common/components/Toaster';
import history from "../../../../config/history";
import { status } from "../../constant";
import * as validation from '../../../Common/components/validation';
import LoaderSpinner from "../../../Common/components/Loader";
import messageAction from '../../../Messaging/action';
import AsyncSelect from 'react-select/async';
import { formatUsPhone } from '../../../Common/components/utils';



class AddNote extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            id: '',
            description: "",
            status: null,
            priority: null,
            adminUserId: null,
            cardData: props.cardData,
            uploadAttachmentsHeader: 'Upload',
            uploadAttachment: [],
            attachmentChanged: false,
            isSubmitted: false,
            adminId: null,
            editData: false,
            isLoder: false,
            tenantList: [],
            requestById: null,
            tenantName: null,
            contact: null

        };
    }

    componentDidMount = async () => {
        window.scroll(0, 0)
        await this.props.getAllAdminUsers()
        // await this.props.getMaintenanceById(this.props.maintenanceId)
        // await this.props.refreshMail()
        await this.props.getTenant({ isDeleted: false, isClosed: false })
        const { tenantReducer: { tenants } } = this.props;
        const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));
        this.setState({
            tenantList
        })


        const { maintenanceDetailsById } = this.props
        if (maintenanceDetailsById && maintenanceDetailsById.maintenanceTickets) {
            this.setState({
                title: maintenanceDetailsById.maintenanceTickets.title,
                status: maintenanceDetailsById.maintenanceTickets.status,
                requestById: maintenanceDetailsById.maintenanceTickets.requestById ? maintenanceDetailsById.maintenanceTickets.requestById._id : null,
                priority: maintenanceDetailsById.maintenanceTickets.priority,
                adminUserId: maintenanceDetailsById.maintenanceTickets.adminUserId ? maintenanceDetailsById.maintenanceTickets.adminUserId : null,
                amount: maintenanceDetailsById.maintenanceTickets.amount,
                maintenancePicture: maintenanceDetailsById.maintenanceTickets.maintenancePicture,
                maintenanceVideo: maintenanceDetailsById.maintenanceTickets.maintenanceVideo,
                tenantName: maintenanceDetailsById.maintenanceTickets.requestById ?
                    `${maintenanceDetailsById.maintenanceTickets.requestById.firstName + " " + maintenanceDetailsById.maintenanceTickets.requestById.lastName}` : null,
                contact: maintenanceDetailsById.maintenanceTickets.requestById ? maintenanceDetailsById.maintenanceTickets.requestById.phoneNumber : null
            })
        }
        if (this.props.isEdit) {
            const { mail } = this.props
            this.setState({
                messageId: mail._id,
                description: mail.message,
                type: mail.type,
                isSubmitted: false,
                editData: true
            })
        }


    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.mail != this.props.mail) {
            if (this.props.isEdit) {
                const { mail } = this.props
                this.setState({
                    messageId: mail._id,
                    description: mail.message,
                    type: mail.type,
                    isSubmitted: false,
                    editData: true
                })
            }
        }
        if (this.state.isSubmitted && prevProps.editNoteDetails != this.props.editNoteDetails) {
            if (this.props.editNoteDetails.maintenanceTickets) {
                if (this.props.editNoteDetails.maintenanceTickets.success) {
                    toastr.success(this.props.editNoteDetails.maintenanceTickets.message)
                } else {
                    toastr.error(this.props.editNoteDetails.maintenanceTickets.message)
                }
            }

        }
    }


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleChangeText = (e) => {
        // let newContent = evt.editor.getData();
        this.setState({
            ...this.state,
            description: e.target.value
        })
    }

    isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };

    handleAddAttachment = e => {
        if (this.isIterable(e.target.files)) {
            this.setState({
                uploadError: ''
            });
            if (e.target.files.length) {
                this.setState({
                    uploadAttachmentsHeader: 'Add'
                })
            }
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const invalidExt = ["exe", "dll", "dmg", "vb", "cmd", "bat", "jar", "vbs", "jse", "pif", "vxd", "jse", "apk", "ins", "sct", "msi"];
                // const acceptableExt = ["pdf", "docx", "doc", "txt", "png", "jpg", "ttf", "avi", "flv", "wmv", "mov", "mp4", "zip"];
                if (!invalidExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        this.setState({
                            attachmentChanged: true,
                            uploadAttachment: [...this.state.uploadAttachment, ...e.target.files],
                        });
                    } else {
                        this.setState({
                            uploadError: 'File is too big. Files with size greater than 25MB is not allowed.'
                        })
                    }
                } else {
                    this.setState({
                        attachmentChanged: false,
                        uploadError: '* Accept only image / video / emoji / documents !!!'
                    })
                }
            })
        }
    };

    deleteAttachment = (index) => {
        let fileArray = Object.values(this.state.uploadAttachment)
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            this.setState({
                uploadAttachmentsHeader: 'Upload',
                attachmentChanged: false,
                uploadAttachment: []
            });
        }
        this.setState({
            fileChanged: true,
            uploadAttachment: fileArray
        });
        document.getElementById('attachmentFiles').value = null
    };


    handleAddReplay = async () => {
        this.setState({
            isSubmitted: true
        })
        let params = {
            limit: 5,
            offset: 0,
            id: this.props.maintenanceId
        }
        let replayData = {
            _id: this.props.maintenanceId,
            description: this.state.description,
            priority: this.state.priority,
            status: this.state.status,
            uploadAttachment: this.state.uploadAttachment,
            adminUserId: this.state.adminUserId,
            type: "email"
        }
        if (this.state.description && this.state.requestById) {
            this.setState({
                isLoder: true
            })
            if (this.state.editData) {
                let data = {
                    messageId: this.state.messageId,
                    message: this.state.description,
                }
                await this.props.editNote(data, { type: "Message" })
            } else {

                await this.props.addMaintenanceTicket(replayData)
                this.props.resetEdit()

            }
            await this.props.viewMaintenanceById(params);
            this.setState({
                isSubmitted: false
            })
            await this.props.getMaintenanceById(this.props.maintenanceId)
            // await this.props.refreshMail()
            const { maintenanceDetailsById } = this.props
            if (maintenanceDetailsById && maintenanceDetailsById.maintenanceTickets) {
                this.setState({
                    _id: this.props.maintenanceId,
                    title: maintenanceDetailsById.maintenanceTickets.title,
                    status: maintenanceDetailsById.maintenanceTickets.status,
                    requestById: maintenanceDetailsById.maintenanceTickets.requestById ? maintenanceDetailsById.maintenanceTickets.requestById._id : null,
                    priority: maintenanceDetailsById.maintenanceTickets.priority,
                    adminUserId: maintenanceDetailsById.maintenanceTickets.adminUserId ? maintenanceDetailsById.maintenanceTickets.adminUserId : null,
                    amount: maintenanceDetailsById.maintenanceTickets.amount,
                    maintenancePicture: maintenanceDetailsById.maintenanceTickets.maintenancePicture,
                    maintenanceVideo: maintenanceDetailsById.maintenanceTickets.maintenanceVideo,
                    isLoder: false
                })
            }
            this.setState({
                description: "",
                messageId: '',
                isLoder: false,
                editData: false,
            })
        }


    }

    handleAddNote = async () => {
        this.setState({
            isSubmitted: true,

        })

        let params = {
            limit: 5,
            offset: 0,
            id: this.props.maintenanceId
        }
        let replayData = {
            _id: this.props.maintenanceId,
            description: this.state.description,
            priority: this.state.priority,
            status: this.state.status,
            uploadAttachment: this.state.uploadAttachment,
            adminUserId: this.state.adminUserId,
            type: "note",
            messageId: this.state.messageId
        }
        if (this.state.description) {
            this.setState({
                isLoder: true
            })
            if (this.state.editData) {
                let data = {
                    messageId: this.state.messageId,
                    message: this.state.description,
                }
                await this.props.editNote(data, { type: "Note" })
            } else {
                await this.props.addMaintenanceTicket(replayData)
                this.props.resetEdit()

            }
            await this.props.viewMaintenanceById(params);
            this.setState({
                isSubmitted: false
            })
            await this.props.getMaintenanceById(this.props.maintenanceId)
            const { maintenanceDetailsById } = this.props
            if (maintenanceDetailsById && maintenanceDetailsById.maintenanceTickets) {
                this.setState({
                    _id: this.props.maintenanceId,
                    title: maintenanceDetailsById.maintenanceTickets.title,
                    status: maintenanceDetailsById.maintenanceTickets.status,
                    requestById: maintenanceDetailsById.maintenanceTickets.requestById ? maintenanceDetailsById.maintenanceTickets.requestById._id : null,
                    priority: maintenanceDetailsById.maintenanceTickets.priority,
                    adminUserId: maintenanceDetailsById.maintenanceTickets.adminUserId ? maintenanceDetailsById.maintenanceTickets.adminUserId : null,
                    amount: maintenanceDetailsById.maintenanceTickets.amount,
                    maintenancePicture: maintenanceDetailsById.maintenanceTickets.maintenancePicture,
                    maintenanceVideo: maintenanceDetailsById.maintenanceTickets.maintenanceVideo,
                    isLoder: false
                })
            }
            this.setState({
                description: "",
                messageId: '',
                editData: false,
                isLoder: false

            })

        }

    }

    handleChange = (e) => {
        const { name, value } = e.target

        this.setState({
            ...this.state,
            [name]: value
        })
    }

    validate = (value, name, type) => {
        const { isSubmitted } = this.state;
        if (isSubmitted) {
            switch (type) {

                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
                case "email":
                    return (
                        validation.EMAIL(value, name) != true ?
                            <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div> : null
                    );
                case "letter":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div> : null
                    )

                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) != true ?
                            <div
                                className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div> : null
                    )


                case "cardNumber":
                    return (
                        validation.CARD_NUMBER(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.CARD_NUMBER(value, name)}</div> : null
                    )

                case "code":
                    return (
                        validation.CODE(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.CODE(value, name)}</div> : null
                    )
                case "ssn":
                    return (
                        validation.SSN(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.SSN(value, name)}</div> : null
                    )


                case "zip":
                    return (
                        validation.ZIP_CODE(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.ZIP_CODE(value, name)}</div> : null
                    )
            }
        } else return null
    }


    handleReturn = () => {
        history.push('/Maintenance')
    }

    promiseOptionsTenant = async val => {
        const { tenantReducer: { tenants } } = this.props;
        await this.props.getTenant({ name: val, isDeleted: false, isClosed: false })
        const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));
        return tenantList.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    handleChangeTenant = async (value) => {
        const { maintenanceDetailsById } = this.props
        const { tenantReducer: { tenants } } = this.props;
        let tenantDetails = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.find(t => t._id === value.value)
        this.setState({
            ...this.state,
            requestById: value.value,
            tenantName: value.label,
            contact: tenantDetails.phoneNumber
        })
        let replayData = {
            _id: this.props.maintenanceId,
            requestById: value.value,
            maintenancePicture: maintenanceDetailsById.maintenanceTickets.maintenancePicture ? maintenanceDetailsById.maintenanceTickets.maintenancePicture : [],
            maintenanceVideo: maintenanceDetailsById.maintenanceTickets.maintenanceVideo ? maintenanceDetailsById.maintenanceTickets.maintenanceVideo : []
        }
        let params = {
            limit: 5,
            offset: 0,
            id: this.props.maintenanceId
        }
        await this.props.maintenanceUpdate(replayData)
        await this.props.viewMaintenanceById(params);
    }


    render() {
        const { maintenanceDetailsById } = this.props
        const { tenantList, tenantName, requestById, contact } = this.state
        return (
            <>
                <div className="support-otr">
                    {this.state.isLoder ? <LoaderSpinner /> : null}
                    <div className="texteditorSec">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className={"customEmailHeader pb-2"}>From :  {maintenanceDetailsById &&
                                    maintenanceDetailsById.maintenanceTickets && maintenanceDetailsById.maintenanceTickets.fromAddress ?
                                    maintenanceDetailsById.maintenanceTickets.fromAddress :
                                    "N/A"}</div>
                                <div className={"customEmailHeader pb-2"}>Tenant Name :  {tenantName ? tenantName : "N/A"}</div>
                                <div className={"customEmailHeader pb-2"}>Contact :  {contact ? formatUsPhone(contact) : "N/A"}</div>
                                <div className={"customEmailHeader pb-2"}>Subject :  {maintenanceDetailsById && maintenanceDetailsById.maintenanceTickets ? maintenanceDetailsById.maintenanceTickets.title : "N/A"}</div>
                                <div className={"customEmailHeader pb-2"}>Assign To :  {maintenanceDetailsById &&
                                    maintenanceDetailsById.maintenanceTickets && maintenanceDetailsById.maintenanceTickets.assignee ? (
                                        maintenanceDetailsById.maintenanceTickets.assignee == "3" ?
                                            (maintenanceDetailsById.maintenanceTickets.contractorName ? maintenanceDetailsById.maintenanceTickets.contractorName : "N/A") :
                                            (maintenanceDetailsById.maintenanceTickets.assignee == "1" ? "Un Assigned" : (maintenanceDetailsById.maintenanceTickets.assignee == "2" ? "To Self" : "N/A"))) : "N/A"}
                                </div>


                                {/* <CKEditor
                                    config={{
                                        toolbar: [
                                            ['Bold', 'Italic', 'Strike', 'Underline', '-', 'RemoveFormat'],
                                            ['Cut', 'Copy', 'Undo', 'Redo', 'Anchor', 'PasteText', 'PasteFromWord',],
                                            ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
                                            ['Specialchar'],
                                            ['Link', 'Unlink'],

                                            // [ 'Source', '-', 'NewPage', 'Preview', '-', 'Templates' ],
                                        ]
                                    }}

                                    data={this.state.description} onChange={this.handleChangeText}
                                /> */}
                                <textarea onChange={this.handleChangeText}
                                    style={{ minHeight: "250px" }}
                                    className={"form-control"}
                                    value={this.state.description}>{this.state.description}</textarea>
                                {this.validate(this.state.description, "Description", "required")}


                                {/*<Editor*/}
                                {/*editorState={this.state.editorState}*/}
                                {/*toolbarClassName="toolbarClassName"*/}
                                {/*wrapperClassName="wrapperClassName"*/}
                                {/*editorClassName="editorClassName"*/}
                                {/*onEditorStateChange={this.onEditorStateChange}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="responseSec">
                        <div className="formSection">
                            <div className="row outrForm p-0 m-0">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3 formInp formSecInp">
                                            <label>Status</label>
                                            <div className="selctOtr">
                                                <select className="form-control" name={"status"}
                                                    value={this.state.status}
                                                    onChange={this.handleChange}>
                                                    <option value={""}>---Select----</option>
                                                    {status.map(st => {
                                                        return <option value={st.value}>{st.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 formInp formSecInp">
                                            <label>Priority</label>
                                            <div className="selctOtr">
                                                <select className="form-control" name={"priority"}
                                                    value={this.state.priority}
                                                    onChange={this.handleChange}>
                                                    <option value={""}>---Select----</option>
                                                    <option value={'Low'}>Low</option>
                                                    <option value={'Normal'}>Normal</option>
                                                    <option value={'High'}>High</option>
                                                    <option value={'Critical'}>Critical</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 formInp formSecInp">
                                            <label>Flag</label>
                                            <div className="selctOtr">
                                                <select className="form-control" name={"adminUserId"}
                                                    value={this.state.adminUserId}
                                                    onChange={this.handleChange}>
                                                    <option value={""}>---Select----</option>
                                                    {this.props.commonReducer.adminDetails
                                                        && this.props.commonReducer.adminDetails.admin
                                                        && this.props.commonReducer.adminDetails.admin.length &&
                                                        this.props.commonReducer.adminDetails.admin.map((ad) => {
                                                            return <option value={ad._id}>{ad.firstName}</option>

                                                        })

                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 formInp formSecInp">
                                            <label>Attachment</label>
                                            <div className="attachRow">
                                                <input type="file" className="customfile form-control"
                                                    placeholder="Insert knowledgebase Link"
                                                    onChange={this.handleAddAttachment} />
                                                {/*<button className="btn addmore" ><i className="fas fa-plus"></i> Add*/}
                                                {/*More*/}
                                                {/*</button>*/}
                                            </div>
                                        </div>
                                        {/*<div className="col-md-3 formInp formSecInp">*/}
                                        {/*<label>Tools</label>*/}
                                        {/*<input type="text" className="form-control"*/}
                                        {/*placeholder="Insert Predefined Replay"/>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-3 formInp formSecInp">*/}
                                        {/*<label className="custm-btm"></label>*/}
                                        {/*<input type="text" className="form-control"*/}
                                        {/*placeholder="Insert knowledgebase Link"/>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="row">

                                        <div className="col-md-3 formInp formSecInp">

                                            <label>Tenant</label>
                                            <AsyncSelect
                                                cacheOptions
                                                defaultOptions={tenantList}
                                                placeholder={"Search Tenant"}
                                                loadOptions={this.promiseOptionsTenant}
                                                name="requestById"
                                                onChange={this.handleChangeTenant}
                                                value={{ value: requestById, label: tenantName }}
                                            // isDisabled={props.tenantLogin || tenantView}
                                            />
                                            {this.validate(requestById, "Tenant", "required")}
                                        </div>
                                        <div className="col-md-3 formInp formSecInp viewPrflBtn">
                                            <label className="custm-btm"></label>
                                            <button className="btn btn-primary viewPrfl" disabled={!requestById} onClick={() => { history.push(`/Tenants/${requestById}/Summary`) }}>
                                                <i className="far fa-eye pr-1"></i>View Profile</button>
                                        </div>
                                        {/*<div className="col-md-3 formInp formSecInp">*/}
                                        {/*<label className="custm-btm"></label>*/}
                                        {/*<div className="selctOtr">*/}
                                        {/*<select className="form-control">*/}
                                        {/*<option>test 1</option>*/}
                                        {/*<option>test 2</option>*/}
                                        {/*</select>*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="centerSec">
                        {this.props.isNote ? <button className="btn addresponse" onClick={this.handleAddNote}><i
                            className="fas fa-plus"></i> {this.state.editData ? 'Edit Note' : 'Add Note'}</button> :
                            <button className="btn addresponse" onClick={this.handleAddReplay}><i
                                className="fas fa-plus"></i> {this.state.editData ? 'Edit Response' : 'Add Response'}</button>}
                        <a><label className={"linking-btn"} onClick={this.handleReturn}>Return to Ticket
                            List</label></a>


                    </div>
                </div>
            </>


        )


    }
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer, tenantReducer } = state;
    return {
        maintenanceReducer,
        tenantReducer,
        commonReducer,
        editNoteDetails: maintenanceReducer.editNote,
        maintenanceDetailsById: maintenanceReducer.maintenanceDetailsById,

    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...messageAction, ...tenantAction
})(AddNote);
