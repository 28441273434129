import * as actions from "./constatnts";

const initialState = {
    password: {},
    profiles: {},
    profileDetail: {},
    updateTenantPasswordResponse: {}
};

export default function maintenanceReducer(state = initialState, action) {
    switch (action.type) {
        case actions.UPDATE_PASSWORD_REQUEST:
            return {
                ...state
            };
        case actions.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                password: action.response
            };
        case actions.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                password: action.error
            };

        case actions.GET_PROFILE_ID_REQUEST:
            return {
                ...state
            };
        case actions.GET_PROFILE_ID_SUCCESS:
            return {
                ...state,
                profileDetail: {
                    success: true,
                    type: action.profileAction.type,
                    profileDetail: action.profileAction.data
                }
            };
        case actions.GET_PROFILE_ID_FAILURE:
            return {
                ...state,
                profileDetail: {success: false, ...action.error}
            };

        case actions.ADD_PROFILE_REQUEST:
            return {
                ...state
            };
        case actions.ADD_PROFILE_SUCCESS:
            return {
                ...state,
                profiles: {success: true, profileDetail: action.profileAction.data.admin}
            };
        case actions.ADD_PROFILE_FAILURE:
            return {
                ...state,
                profiles: {success: false, ...action.error}
            };

        case actions.UPDATE_TENANT_PASSWORD_REQUEST:
            return {
                ...state
            };
        case actions.UPDATE_TENANT_PASSWORD_SUCCESS:
            return {
                ...state,
                updateTenantPasswordResponse: action.response
            };
        case actions.UPDATE_TENANT_PASSWORD_FAILURE:
            return {
                ...state,
                updateTenantPasswordResponse: {success: false, ...action.error}
            };

        default:
            return state;
    }
}

