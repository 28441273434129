import React, {useState, useEffect} from "react";
import action from "../../action";
import {connect} from "react-redux";

const MaintenanceForm3 = (props) => {

    const {level} = props;
    return (
        <div className="col-md-9 categ-sub-one p-0">
        <div className="boxSec">
          <div className="row">
            <div className="col-md-12 listing">
                <ul>
                { props.category.length ? props.category.map((e,i)=> {
                    return <li onClick={()=>props.onClickCategory((level== 3 ? e.categoryId: e._id), parseInt(props.level)+1, e.name)} key={i}>
                        <span className="img-sp">
                            <svg>
                                  <use xlinkHref="images/svg-sprite.svg#ic-dot" ></use>
                            </svg>
                        </span>
                        <span className="text-sp">{e.name}</span>
                        <span className="icn"><i className="fas fa-chevron-down"></i></span>
                    </li>
                }):''
                }
                </ul>
            </div>
          </div>
        </div>
      </div>
    )
}

const mapStateToProps = state => {
    const {maintenanceReducer, commonReducer, tenantReducer} = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        commonReducer,
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(MaintenanceForm3);

