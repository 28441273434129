import * as Service from './service';
import * as Constant from './constant';

const refreshCall = (functionName, params) => {
    functionName.call(this, params)
}


const addTenant = (tenants) => {
    const request = () => ({ type: Constant.ADD_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.ADD_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.ADD_TENANT_FAILURE,
        error
    });

    const data = tenants.profilePic && [...tenants.profilePic];
    if (data) {
        tenants.profilePic = []
    }
    let tenantData = new FormData();
    tenantData.append('data', JSON.stringify(tenants))
    data && data.length && data.map((doc) => tenantData.append(`file`, doc));

    // let profileData = new FormData();
    // tenants.profilePic && profileData.append('file', tenants.profilePic)
    // profileData.append('data', JSON.stringify( tenants))

    return async dispatch => {
        try {
            dispatch(request());
            const res = tenants.id ? await Service.updateTenant(tenantData) : await Service.addTenant(tenantData);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getTenant = (params) => {
    const request = () => ({ type: Constant.GET_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.GET_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.GET_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenant(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            // if(navigator.onLine){
            //     refreshCall(getTenant, params)
            // }
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getTenantId = (id) => {
    const request = () => ({ type: Constant.GET_TENANT_ID_REQUEST });
    const success = tenantAction => ({
        type: Constant.GET_TENANT_ID_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.GET_TENANT_ID_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantId(id);

            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getTenantProperty = (id) => {
    const request = () => ({ type: Constant.GET_TENANT_PROPERTY_REQUEST });
    const success = tenantAction => ({
        type: Constant.GET_TENANT_PROPERTY_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.GET_TENANT_PROPERTY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantProperty(id);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteTenant = (id) => {
    const request = () => ({ type: Constant.DELETE_TENANT_REQUEST });
    const success = id => ({
        type: Constant.DELETE_TENANT_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteTenant(id);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(id));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//delete permanently
const permanentDelete = (id) => {
    const request = () => ({ type: Constant.DELETE_PERMANENT_TENANT_REQUEST });
    const success = id => ({
        type: Constant.DELETE_PERMANENT_TENANT_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_PERMANENT_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.permanentDelete(id);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(id));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getInvoiceTypes = (id) => {
    const request = () => ({ type: Constant.INVOICE_TYPE_REQUEST });
    const success = tenantAction => ({
        type: Constant.INVOICE_TYPE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.INVOICE_TYPE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getInvoiceTypes(id);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getInvoicesByTenantId = (id, params) => {
    const request = () => ({ type: Constant.INVOICE_REQUEST });
    const success = tenantAction => ({
        type: Constant.INVOICE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getInvoicesByTenantId(id, params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getMessageByTenantId = (id, params) => {
    const request = () => ({ type: Constant.MESSAGE_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.MESSAGE_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.MESSAGE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getMessageByTenantId(id, params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteTenantFile = (id, tenantId) => {
    const request = () => ({ type: Constant.DELETE_TENANT_FILE_REQUEST });
    const success = id => ({
        type: Constant.DELETE_TENANT_FILE_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_TENANT_FILE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteTenantFile(id, tenantId);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(id));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteInvoice = (id) => {
    const request = () => ({ type: Constant.DELETE_INVOICE_REQUEST });
    const success = id => ({
        type: Constant.DELETE_INVOICE_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteInvoice(id);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(id));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//send lease agreement request on tenant add
const leaseAgreement = (params) => {
    const request = () => ({ type: Constant.LEASE_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.LEASE_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.LEASE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.leaseAgreement(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//send lease agreement request on tenant add
const sendLeaseAgreement = (data, params) => {
    const request = () => ({ type: Constant.LEASE_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.LEASE_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.LEASE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.sendLeaseAgreement(data, params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


//edit lease agreement request 
const editLeaseAgreement = (params) => {
    const request = () => ({ type: Constant.LEASE_TENANT_EDIT_REQUEST });
    const success = tenantAction => ({
        type: Constant.LEASE_TENANT_EDIT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.LEASE_TENANT_EDIT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.editLeaseAgreement(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//view lease agreement on tenant add (html view)
const viewLeaseAgreement = (params) => {
    const request = () => ({ type: Constant.VIEW_LEASE_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.VIEW_LEASE_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.VIEW_LEASE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.viewLeaseAgreement(params);
            if (res.status === 200) {

                const tenantDetails = res.data;

                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//list lease agreement
const viewAgreement = (params) => {
    const request = () => ({ type: Constant.VIEW_LEASE_REQUEST });
    const success = tenantAction => ({
        type: Constant.VIEW_LEASE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.VIEW_LEASE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.viewAgreement(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


//create password
const createPassword = (params) => {
    const request = () => ({ type: Constant.CREATE_PASSWORD_REQUEST });
    const success = tenantAction => ({
        type: Constant.CREATE_PASSWORD_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.CREATE_PASSWORD_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.createPassword(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//add payment for tenant
const addPaymentTenant = (params) => {
    const request = () => ({ type: Constant.ADD_PAYMENT_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.ADD_PAYMENT_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.ADD_PAYMENT_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addPaymentTenant(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const sendLobMail = (params) => {
    return async dispatch => {
        try {
            dispatch({ type: Constant.SEND_LOB_MAIL_REQUEST });
            const res = await Service.sendLobMail(params);
            if (res && res.status === 200) {
                const lobResponse = res.data;
                await dispatch({ type: Constant.SEND_LOB_MAIL_SUCCESS, data: lobResponse });
            } else {
                await dispatch({ type: Constant.SEND_LOB_MAIL_FAILURE, error: res });
            }
        } catch (e) {
            await dispatch({ type: Constant.SEND_LOB_MAIL_FAILURE, error: e });

        }
    };
}

const editInvoiceTenant = (params) => {
    const request = () => ({ type: Constant.EDIT_INVOICE_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.EDIT_INVOICE_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.EDIT_INVOICE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.editInvoiceTenant(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const viewNotice = (params) => {
    const request = () => ({ type: Constant.VIEW_NOTICE_REQUEST });
    const success = tenantAction => ({
        type: Constant.VIEW_NOTICE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.VIEW_NOTICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.viewNotice(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addRecuredDate = (params) => {
    const request = () => ({ type: Constant.ADD_RECURED_DATE_REQUEST });
    const success = tenantAction => ({
        type: Constant.ADD_RECURED_DATE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.ADD_RECURED_DATE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addRecuredDate(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const reopenTenant = (params) => {
    const request = () => ({ type: Constant.REOPEN_TENANT_REQUEST });
    const success = tenantAction => ({
        type: Constant.REOPEN_TENANT_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.REOPEN_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.reopenTenant(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addFile = (fileData) => {

    let templateAttachmentData = new FormData();
    templateAttachmentData.append("file", fileData.fileData)
    templateAttachmentData.append("data", JSON.stringify({ id: fileData.id }));
    templateAttachmentData.append("title", fileData.fileName);

    const request = () => ({ type: Constant.ADD_FILE_REQUEST });
    const success = tenantAction => ({
        type: Constant.ADD_FILE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.ADD_FILE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addFile(templateAttachmentData);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getFile = (params) => {
    const request = () => ({ type: Constant.VIEW_FILE_REQUEST });
    const success = tenantAction => ({
        type: Constant.VIEW_FILE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.VIEW_FILE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getFile(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteFile = (id, params) => {
    const request = () => ({ type: Constant.DELETE_FILE_REQUEST });
    const success = tenantAction => ({
        type: Constant.DELETE_FILE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.DELETE_FILE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteFile(id, params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteTenantEmail = (params) => {
    const request = () => ({ type: Constant.DELETE_TENANT_EMAIL_REQUEST });
    const success = tenantAction => ({
        type: Constant.DELETE_TENANT_EMAIL_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.DELETE_TENANT_EMAIL_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteTenantEmail(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getTenantEmail = (params) => {
    const request = () => ({ type: Constant.GET_TENANT_EMAIL_REQUEST });
    const success = tenantAction => ({
        type: Constant.GET_TENANT_EMAIL_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.GET_TENANT_EMAIL_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantEmail(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addTenantEmail = (params) => {
    const request = () => ({ type: Constant.ADD_TENANT_EMAIL_REQUEST });
    const success = tenantAction => ({
        type: Constant.ADD_TENANT_EMAIL_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.ADD_TENANT_EMAIL_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addTenantEmail(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const updateTenantEmail = (params) => {
    const request = () => ({ type: Constant.ADD_TENANT_EMAIL_REQUEST });
    const success = tenantAction => ({
        type: Constant.ADD_TENANT_EMAIL_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.ADD_TENANT_EMAIL_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateTenantEmail(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const generateInvoiceByTenant = (params) => {
    const request = () => ({ type: Constant.GENERATE_TENANT_INVOICE_REQUEST });
    const success = tenantAction => ({
        type: Constant.GENERATE_TENANT_INVOICE_SUCCESS,
        tenantAction
    });
    const failure = error => ({
        type: Constant.GENERATE_TENANT_INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.generateInvoiceByTenant(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

export default {
    addTenant,
    getTenant,
    getTenantId,
    getTenantProperty,
    deleteTenant,
    permanentDelete,
    getInvoiceTypes,
    getInvoicesByTenantId,
    getMessageByTenantId,
    deleteTenantFile,
    deleteInvoice,
    leaseAgreement,
    viewLeaseAgreement,
    viewAgreement,
    createPassword,
    addPaymentTenant,
    sendLobMail,
    editInvoiceTenant,
    viewNotice,
    addRecuredDate,
    reopenTenant,
    editLeaseAgreement,
    sendLeaseAgreement,
    addFile,
    getFile,
    deleteFile,
    getTenantEmail,
    addTenantEmail,
    updateTenantEmail,
    deleteTenantEmail,
    refreshCall,
    generateInvoiceByTenant
}
