import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import usePrevious from "../../Common/components/prevProps";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';
import AddMail from './AddMail';
import { formatUsPhone } from "../../Common/components/utils";

const Users = (props) => {

    const [isSubmitted, setIsSubmitted] = useState(true);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);
    const [params, setParams] = useState({ limit: 15, offset: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [mailData, setUserMail] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [addMail, setaddMail] = useState(false);

    useEffect(() => {
        async function renderCall() {
            await props.getMailDetails(params);
        }

        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }, []);

    const { settingsReducer: { deleteMailDetails: deleted, addMailDetails } } = props;

    const prevDelete = usePrevious(deleted);
    const prevAdd = usePrevious(addMailDetails);
    useEffect(() => {

        if (deleteSubmitted && prevDelete != deleted && !deleted.success) {
            toastr.error(deleted.message)
        } else if (deleteSubmitted && prevDelete != deleted && deleted.success) {
            toastr.success("Successfully deleted");
        }
    }, [prevDelete, deleted]);

    useEffect(() => {
        if (addMail && prevAdd != addMailDetails && !addMailDetails.success) {
            toastr.error(addMailDetails.message)
        } else if (addMail && prevAdd != addMailDetails && addMailDetails.success) {
            toastr.success(addMailDetails.message);
        }
    }, [prevAdd, addMailDetails]);

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setCurrentPage(page)
        await props.getMailDetails(data);
    }

    const openModalAddMail = (e) => {
        setUserModal(true);
        setUserMail(e);
    }

    const closeModalMail = () => {
        setUserModal(false)
    }

    const renderModalMail = () => {
        return <AddMail
            onCancel={closeModalMail}
            mailData={mailData}
            params={params}
            addMailAccountData={addMailAccountData}
        />
    };

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Mail Account'
            message='Do you want to delete this Mail Account?'
            onCancel={closeDeleteModal}
            onConfirm={deleteMail}
        />
    }

    const deleteMail = async () => {
        setDeleteSubmitted(true);
        setDeleteModal(false);
        await props.deleteMailDetails(deleteId);
        await props.getMailDetails(params);
        setTimeout(() => {
            setDeleteSubmitted(false);
            //props.getUsers(params);
        }, 500);
    }

    const addMailAccountData = async (userDetails) => {
        setIsSubmitted(true)
        setaddMail(true)
        if (!userDetails.id)
            await props.addMailDetails(userDetails)
        else await props.updateMailDetails(userDetails)
        setTimeout(() => {
            setIsSubmitted(false)
            setaddMail(false)
        }, 1000)
    }


    const { settingsReducer: { getMailDetails } } = props;

    return (
        <>
            {isSubmitted && <LoaderSpinner />}
            {deleteSubmitted && <LoaderSpinner />}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {userModal ? <Modal body={renderModalMail()} onCancel={closeModalMail} /> : null}
            <div className="tenants-otr settingsOuter">
                <div className="innago-table-top">
                    <div className="lft">
                        <h1>MAIL ACCOUNTS</h1>
                    </div>
                    <div className="rgt">
                        <button className="add" onClick={() => openModalAddMail()}>
                            <i className="fas fa-plus" />
                            Add Mail Account
                        </button>
                    </div>
                </div>

                <div className="settingTable">
                    <div className="innago-table table-main mb-3">
                        <div className="table-responsive">
                            <table className="table-gap table-td">
                                <tbody>
                                    <tr className="table-head">
                                        <th>Email</th>
                                        <th>Password</th>
                                        <th>SMTP Server</th>
                                        <th>Email Type</th>
                                        <th className={"widact"}>Action</th>
                                    </tr>
                                    {getMailDetails.settings && getMailDetails.settings.length ?
                                        getMailDetails.settings.map((e, i) => {
                                            return <tr key={i}>
                                                <td>
                                                    <p>
                                                        {e.userName}

                                                    </p>
                                                </td>
                                                <td>{e.password ? e.password.replace(e.password, "xxxxxx") : "N/A"}</td>
                                                <td>
                                                    <p> {e.emailHost}</p>
                                                </td>

                                                <td>
                                                    <p>{e.emailType ? e.emailType : "N/A"}</p>
                                                </td>

                                                <td>
                                                    <div className="dropdown moveDrop editDetlPrp drop-arw">
                                                        <i className="fas fa-pencil-alt mr-2"
                                                            onClick={() => openModalAddMail(e)}></i>
                                                        <i className="fa fa-trash" aria-hidden="true"
                                                            onClick={() => openDeleteModal(e._id)}></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        }) :
                                        <tr>
                                            <td className="text-center no_value_table" colSpan={7}><i
                                                className="far fa-thumbs-down"></i>There is no data to show you right now!
                                        </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="paginationSec">
                            {getMailDetails.settings && getMailDetails.settings.length ? <p className={"warningTxt"}>**Adding multiple email of same type will choose only the latest!</p> : null}
                            {getMailDetails.total && getMailDetails.total ? <Pagination
                                totalRecords={getMailDetails.total}
                                pageLimit={params.limit}
                                pageNeighbours={1}
                                currentPage={currentPage}
                                onPageChanged={onPageChanged}
                            /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { settingsReducer, commonReducer } = state;
    return {
        settingsReducer, commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(Users);
