import {
    //combineReducers,
    createStore, applyMiddleware, compose,
} from 'redux';
// To handle async actions (like API calls) in store
import thunk from 'redux-thunk';
//import createLogger from 'redux-logger';
 import reducers from '../reducers';
// Initial state
// const logger = createLogger();
import initialState from './initialState';

const configureStore = () => createStore(
    reducers,
    initialState,
    // Apply thunk middleware and add support for Redux dev tools in Google Chrome
    process.env.NODE_ENV !== 'production' && window.devToolsExtension
        ? compose(applyMiddleware(thunk), window.devToolsExtension())
        : applyMiddleware(thunk),
);

export default configureStore;
