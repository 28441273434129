import React, { useState, useEffect } from "react";
import action from "../../action";
import commonAction from '../../../Common/action';
import tenantAction from '../../../Tenants/action';
import { connect } from "react-redux";
import Pagination from '../../../Common/components/pagination';
import toastr from '../../../Common/components/Toaster'
import AddMaintenanceForm1 from './addMaintenanceform1'; 
import AddMaintenanceForm3 from './addMaintenanceForm3';
import SideFilterMaintenance from './sideFilterMaintenance'
import AddMaintenanceForm4 from './addMaintenanceForm4';
import usePrevious from "../../../Common/components/prevProps";
import LoaderSpinner from "../../../Common/components/Loader";
import moment from 'moment';
import * as validation from '../../../Common/components/validation';
import isImage from "is-image";
import history from '../../../../config/history'

const AddMaintenance = (props) => {
    const [currentPage, setcurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [cat1, setCat1] = useState('');
    const [cat2, setCat2] = useState('');
    const [cat3, setCat3] = useState('');
    const [cat4, setCat4] = useState('');
    const [videoError, setVideoError] = useState('')
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [level, setLevel] = useState(5);
    const [units, setUnits] = useState([]);
    const [property, setProperty] = useState({});
    const [tenant, setTenant] = useState({});
    const [paramsList, setParamsList] = useState({ limit: 5, offset: 0 })
    const [maintenance, setMaintenance] = useState({
        propertyId: '',
        unitId: '',
        priority: 'Normal',
        availableDate: '',
        availableTime: '',
        authorizationToEnter: false,
        petInResidence: false,
        maintenanceCategoryId: '',
        title: '',
        description: '',
        maintenancePicture: '',
        maintenanceVideo: '',
        requestById: '',
        requestInitiatedDate: '',
        requestDueDate: '',
        assignee: '',
        originalEstimateHr: '',
        originalEstimateMin: '',
        labourTime: '',
        labourTimeHr: '',
        labourTimeMin: '',
        anyDateIsFine:false ,
        isChangedImage: false,
        isChangedVideo: false,
        contractorName: null,
        propertyName: null
    })
    const [params, setParams] = useState({
        limit: 15,
        offset: 0,
        level: 1
    })
    const [isEdit, setIsEdit] = useState(false)

    const {
        match: {
            params: {maintenaceId}
        }
    } = props;
    const prevEditMaintenance = usePrevious(props.maintenanceDetails)


    useEffect(() => {
        window.scroll(0, 0)
        if (maintenaceId) {
            props.getMaintenanceById(maintenaceId)
            setIsEdit(true)
        }
        async function renderCall() {
            await props.getCategoryList(params);
            await props.getTenantMaintenance(paramsList);
            await props.getTenantPropertyDetails()
        }

        renderCall();
    }, []);

    useEffect(() => {
        if (isSubmitted && prevEditMaintenance !== props.maintenanceDetails && !props.maintenanceDetails.success) {
            toastr.error(props.maintenances.message)
        } else if (maintenaceId && prevEditMaintenance !== props.maintenanceDetails && props.maintenanceDetails.success) {
            props.getUnitsByPropertyId(props.maintenanceDetails.maintenanceTickets.propertyId._id);
            setTenant({
                value: props.maintenanceDetails.maintenanceTickets.requestById._id,
                label: props.maintenanceDetails.maintenanceTickets.requestById.firstName
            })
            setProperty({
                value: props.maintenanceDetails.maintenanceTickets.propertyId._id,
                label: props.maintenanceDetails.maintenanceTickets.propertyId.propertyName
            })
            setMaintenance({
                ...maintenance,
                _id: props.maintenanceDetails.maintenanceTickets._id,
                propertyId: props.maintenanceDetails.maintenanceTickets.propertyId._id,
                unitId: props.maintenanceDetails.maintenanceTickets.unitId._id,
                priority: props.maintenanceDetails.maintenanceTickets.priority,
                availableDate: props.maintenanceDetails.maintenanceTickets.availableDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.availableDate).format('MM/DD/YYYY') : '',
                availableTime: props.maintenanceDetails.maintenanceTickets.authorizationToEnter.availableTime,
                authorizationToEnter: props.maintenanceDetails.maintenanceTickets.authorizationToEnter,
                petInResidence: props.maintenanceDetails.maintenanceTickets.petInResidence,
                // maintenanceCategoryId: props.maintenanceDetails.maintenanceTickets.maintenanceCategoryId._id,
                title: props.maintenanceDetails.maintenanceTickets.title,
                description: props.maintenanceDetails.maintenanceTickets.description,
                maintenancePicture: props.maintenanceDetails.maintenanceTickets.maintenancePicture,
                maintenanceVideo: props.maintenanceDetails.maintenanceTickets.maintenanceVideo,
                requestById: props.maintenanceDetails.maintenanceTickets.requestById._id,
                requestInitiatedDate: props.maintenanceDetails.maintenanceTickets.requestInitiatedDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.requestInitiatedDate).format('MM/DD/YYYY') : null,
                requestDueDate: props.maintenanceDetails.maintenanceTickets.requestDueDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.requestDueDate).format('MM/DD/YYYY') : null,
                assignee: props.maintenanceDetails.maintenanceTickets.assignee,
                originalEstimateHr: props.maintenanceDetails.maintenanceTickets.originalEstimateHr
                ,
                // ? moment( props.maintenanceDetails.maintenanceTickets.originalEstimateTime).format('hh'):null,
                originalEstimateMin: props.maintenanceDetails.maintenanceTickets.originalEstimateMin,
                // ? moment(props.maintenanceDetails.maintenanceTickets.originalEstimateTime).format('mm '):null,
                labourTime: props.maintenanceDetails.maintenanceTickets.labourTime,
                // ? moment( props.maintenanceDetails.maintenanceTickets.labourTime).format('hh:mm A'):null,
                labourTimeHr: props.maintenanceDetails.maintenanceTickets.labourTimeHr,
                // ? moment( props.maintenanceDetails.maintenanceTickets.laborTime).format('hh'):null,
                labourTimeMin: props.maintenanceDetails.maintenanceTickets.labourTimeMin,
                // ? moment( props.maintenanceDetails.maintenanceTickets.laborTime).format('mm'):null,
                anyDateIsFine: props.maintenanceDetails.maintenanceTickets.anyDateIsFine,
                startedWorkdate: props.maintenanceDetails.maintenanceTickets.reportedOn ?
                    moment(props.maintenanceDetails.maintenanceTickets.reportedOn).format('MM/DD/YYYY') : null,
                completedWorkDate: props.maintenanceDetails.maintenanceTickets.completedWorkDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.completedWorkDate).format('MM/DD/YYYY') : null,
            })
        }
    }, [prevEditMaintenance, props.maintenanceDetails]);

    useEffect(() => {
        if (props.propertyDetailsByTenant.property) {
            setTenant({
                value: props.propertyDetailsByTenant.property._id,
                label: props.propertyDetailsByTenant.property.firstName + " "+ props.propertyDetailsByTenant.property.lastName
            });
            setProperty({
                value: props.propertyDetailsByTenant.property.propertyId._id,
                label: props.propertyDetailsByTenant.property.propertyId.propertyName
            });

            props.getUnitsByPropertyId(props.propertyDetailsByTenant.property.propertyId._id);

            setMaintenance({
                ...maintenance,
                unitId: props.propertyDetailsByTenant.property.unitId._id,
                requestById: props.propertyDetailsByTenant.property._id,
                title:props.propertyDetailsByTenant.property.propertyId.propertyName,
                propertyId: props.propertyDetailsByTenant.property.propertyId._id
            })
        }

    }, [props.propertyDetailsByTenant]);

    useEffect(() => {
        //Set unit list
        const { unitDetails } = props;
        let Units = []
        unitDetails && unitDetails.unitList && unitDetails.unitList.map(e => {
            Units.push({
                value: e._id,
                label: e.unitName
            })
        })
        setUnits(Units)
    }, [props.unitDetails])

    useEffect(() => {
        setMaintenance({
            ...maintenance,
            description: cat1 + " " + cat2 + " " + cat3 + " " + cat4,
        })
    }, [cat4])

    useEffect(() => {
        if (props.add.loading) {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
        if (props.add.success) {
            toastr.success(props.add.message)
        }
        else {
            toastr.error(props.add.message)
        }
    }, [props.add])

    useEffect(() => {
        if (props.categoryMaintenance.loading) {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
    }, [props.categoryMaintenance])

    const prevMaintenance = usePrevious(props.maintenances);

    useEffect(() => {
        if (isSubmitted && prevMaintenance !== props.maintenances && !props.maintenances.success) {
            toastr.error(props.maintenances.message)
        } else if (isSubmitted && prevMaintenance !== props.maintenances && props.maintenances.success) {
            toastr.success(props.maintenances.message)
        }
    }, [prevMaintenance, props.maintenances]);

    const changePriority = (value) => {
        setMaintenance({
            ...maintenance,
            priority: value
        })
    }

    const onPageChanged = async page => {
        let data = { ...paramsList }
        let offset = ((page - 1) * paramsList.limit) || 0;
        data.offset = offset;
        data.isDeleted = false;
        setParamsList(data)
        setcurrentPage(page)
        await props.getTenantMaintenance(data);
    };

    const handleAddImage = e => {
        const { name, files } = e.target;
        if (files.length) {
            if (isImage(files[0].name)) {
                setMaintenance({
                    ...maintenance,
                    maintenancePicture: files[0],
                    isChangedImage: true

                })
                setVideoError(null)
            }
            else {
                let ext = files[0].name.split('.').pop();
                const acceptableExt = ["ttf", "avi", "flv", "wmv", "mov", "mp4"];
                if (acceptableExt.includes(ext))
                    if (files[0].size < 25000000) {
                        setMaintenance({
                            ...maintenance,
                            maintenanceVideo: files[0],
                            isChangedVideo: true
                        })
                        setVideoError(null)
                    }
                    else {
                        setVideoError('*Select video of size less than 25 mb')
                    }
            }
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        const { originalEstimateHr, originalEstimateMin, labourTimeHr, labourTimeMin } = maintenance;

        if (name === 'originalEstimateHr') {
            setMaintenance({
                ...maintenance,
                originalEstimateHr: value,
                originalEstimateTime: parseInt(value) + parseInt(originalEstimateMin)
            })
        }
        else if (name === 'originalEstimateMin') {
            setMaintenance({
                ...maintenance,
                originalEstimateMin: value,
                originalEstimateTime: parseInt(value) + parseInt(originalEstimateHr)
            })
        }
        else if (name === 'labourTimeMin') {
            setMaintenance({
                ...maintenance,
                labourTimeMin: value,
                labourTime: parseInt(value) + parseInt(labourTimeHr)
            })
        }
        else if (name === 'labourTimeHr') {
            setMaintenance({
                ...maintenance,
                labourTimeHr: value,
                labourTime: parseInt(value) + parseInt(labourTimeMin)
            })
        }
        else
            setMaintenance({
                ...maintenance,
                [name]: value
            })
    }

    const changeRadio = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'authorizationToEnter' || name === 'petInResidence') {
            setMaintenance({
                ...maintenance,
                [name]: checked
            })
        }
        else
            setMaintenance({
                ...maintenance,
                [name]: value
            })
            
    }
    const changeRadioTime = (e) => {
        const { name, value, checked } = e.target;
        
            setMaintenance({
                ...maintenance,
                [name]: value,
                authorizationToEnter: true
            })
            
    }

    const onClickCategory = async (id, level, name) => {
        if (name == null) {
            setCat1('');
            setCat2('');
            setCat3('');
        }
        if (level == 2)
            setCat1(name);
        else if (level == 3)
            setCat2(name)
        else if (level == 4)
            setCat3(name)
        else if (level == 5)
            setCat4(name)
        setSearch(false);
        setLevel(level);
        id && setId(id)
        const params = {
            level: level,
            parentId: level == 1 ? null : id
        }
        setMaintenance({
            ...maintenance,
            description: cat1 + " " + cat2 + " " + cat3 + " " + cat4,
            maintenanceCategoryId: id,
        })
        await props.getCategoryList(params)
    }

    const onChangeDate = (e, name) => {
        setMaintenance({
            ...maintenance,
            [name]: moment(new Date(e)).endOf('day').toISOString(),
        })
    }

    const onChangeAvailableDate = (e, name) => {
        setMaintenance({
            ...maintenance,
            availableDate: moment(new Date(e)).endOf('day').toISOString(),
            anyDateIsFine: false
        })
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
                case "number":
                    return <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
                case "email":
                    return <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div>
                case "letter":
                    return <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
            }
        } else return null
    }

    const handleSubmit = async (e) => {
        setIsSubmitted(true);
        e.preventDefault();

        const { propertyId, unitId, priority, availableDate, anyDateIsFine, authorizationToEnter, petInResidence, maintenanceCategoryId,
            title, description, attachments, requestById, requestInitiatedDate, requestDueDate, assignee, originalEstimateHr,
            originalEstimateMin, labourTime, labourTimeHr, labourTimeMin } = maintenance;
        if (propertyId && unitId && priority && title &&
            description && requestById && (availableDate || anyDateIsFine)) {
            if (maintenance._id) {
                await props.maintenanceUpdate(maintenance);
            } else {
                await props.maintenanceAdd(maintenance);

            }
            history.push("/Tenant-Maintenance");

            setIsSubmitted(false);
            setMaintenance('');
            await props.getTenantMaintenance(paramsList);
            // let tempParams={...params}
            // tempParams.level=1
            // await props.getCategoryList(tempParams);
            // setLevel(1)
        }
    }

    const renderStatus = (status) => {
        switch (status) {
            case 1:
                return "Open"
            case 0:
                return "Closed"
            case 2:
                return "Answered"
            case 3:
                return "On-Hold"
            case 4:
                return "In-Progress"
            case 5:
                return "Scheduled"
        }
    }

    const changeAnyDate = (e) => {
        setMaintenance({
            ...maintenance,
            anyDateIsFine: e.target.checked,
            availableDate:''
        })
    }

    const changeStatus = async (e) => {
        if (e.target.value != 'null')
            await props.getTenantMaintenance({ offset: 0, limit: 5, status: e.target.value })
    }

    const refresh = async () => {
        await props.getTenantMaintenance({ offset: 0, limit: 5 })
    }

    return (
        <div className="tenants-otr">
            {loading && <LoaderSpinner />}
            <div className="innago-table-top main-table-top maint-req-top">
                <div className="lft">
                    <h1>ADD MAINTENANCE REQUESTS</h1>
                </div>
            </div>
            <div className="maintenanceOuter addMaintenance">
                <div className="inner-main-outer-sec">
                    <div className="categoryOuter">
                        {/* <h3>CLICK THE ICON OF THE CATEGORY BELOW</h3>
                        <div className="row row-frst">
                            <div className="col-md-12">
                                <div className="categorySec">
                                    <nav>
                                        <div className="nav nav-tabs nav-fill">
                                            <a className={
                                                level == 1 ? "nav-item nav-link active-current" :
                                                    (level == 2 || level === 3 || level === 4 || level === 5) && "nav-item nav-link active"
                                            }
                                                onClick={() => onClickCategory(id, 1)}
                                            >{cat1 ? cat1 : "Category"}<span>1</span></a>
                                            <a className={
                                                level == 2 ? "nav-item nav-link active-current" :
                                                    (level === 3 || level === 4 || level === 5) ? "nav-item nav-link active" :
                                                        'nav-item nav-link '}
                                            >{cat2 ? cat2 : "Sub-category"}<span> 2</span></a>
                                            <a className={level == 3 ? "nav-item nav-link active-current" :
                                                (level === 4 || level === 5) ? "nav-item nav-link active" :
                                                    'nav-item nav-link'} >{cat3 ? cat3 : "Issue"}<span> 3</span></a>
                                            <a className={level == 4 ? "nav-item nav-link active-current" :
                                                level === 5 ? "nav-item nav-link active" :
                                                    'nav-item nav-link'} >Sub-issue <span>4</span></a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div> */}
                        { search &&
                   <div className="row">
                        <div className="col-md-12 categ-sub-one p-0">
                            <div className="boxSec">
                                <div className="row">
                                    <div className="col-md-12 listing issue-section-outer">
                                        <div className="listing-issue-outer maintenance-search">
                                            <div className="formSection">
                                                <div className="formInp">
                                                    <label>Find The Category</label>
                                                    <input type="text" className="form-control" placeholder="type the category name here.." />
                                                </div>
                                            </div>
                                            <div className="col-md-12 fail-box">
                                                <img src="/images/toolbox.png" />
                                                <h4>OTHER</h4>
                                                <p>Unfortunately, maintenance categories are not custom.
                                                <br/>Contact customer support to suggest a category. Thank you :)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="row row-secnd">
                            {(level == 1 || level == 2) && props.categoryMaintenance.category && !search &&
                                <AddMaintenanceForm1
                                    onClickCategory={onClickCategory}
                                    level={level}
                                    category={props.categoryMaintenance.category}
                                    id={id}
                                />}
                            {(level == 3 || level == 4) && props.categoryMaintenance.category &&
                                <AddMaintenanceForm3
                                    onClickCategory={onClickCategory}
                                    level={level}
                                    category={props.categoryMaintenance.category}
                                    id={id}
                                />}
                            {level == 5 &&
                                <AddMaintenanceForm4
                                    onClickCategory={onClickCategory}
                                    level={level}
                                    category={props.categoryMaintenance.category}
                                    handleChange={handleChange}
                                    handleSubmit={handleSubmit}
                                    changeRadio={changeRadio}
                                    maintenance={maintenance}
                                    onChangeDate={onChangeDate}
                                    validate={validate}
                                    handleAddImage={handleAddImage}
                                    videoError={videoError}
                                    tenantLogin={true}
                                    isEdit={isEdit}
                                />}
                            {level != 5 &&
                                !search && !loading && 
                                <div className="col-md-3 serachouter-section">
                                    <div className="searchSec">
                                        <div className="categoryBox" onClick={() => setSearch(true)}>
                                            {/* <img src="/images/leak.png" /> */}
                                            <svg>
                                                <use xlinkHref="/images/svg-sprite.svg#ic-search"></use>
                                            </svg>
                                            <h4>SEARCH</h4>
                                            <p>
                                                Hard to find the right category?
                                                    Use this smart search and select
                                                    the category faster.
                                    </p>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
                <SideFilterMaintenance
                    maintenance={maintenance}
                    changePriority={changePriority}
                    handleChange={handleChange}
                    units={units}
                    tenant={tenant}
                    onChangeDate={onChangeDate}
                    changeRadio={changeRadio}
                    changeRadioTime={changeRadioTime}
                    property={property}
                    validate={validate}
                    tenantLogin={true}
                    changeAnyDate={changeAnyDate}
                    isSubmitted={isSubmitted}
                    onChangeAvailableDate={onChangeAvailableDate}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer, tenantReducer } = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        categoryMaintenance: maintenanceReducer.categoryMaintenance,
        add: maintenanceReducer.maintenanceAdd,
        maintenanceDetailsByTenant: commonReducer.maintenanceDetailsByTenant,
        propertyDetailsByTenant: commonReducer.propertyDetailsByTenant,
        tenantReducer,
        unitDetails: commonReducer.unitDetails,
        maintenanceDetails: maintenanceReducer.maintenanceDetailsById

    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...tenantAction
})(AddMaintenance);

