import React, { useEffect, useState } from "react";
import action from "../action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import { MAIN_ROUTE } from "../../../config/constants";
import { DebounceInput } from 'react-debounce-input';
import LoaderSpinner from "../../Common/components/Loader";

const TenantDetailListing = (props) => {

    const [active, setActive] = useState('active');

    useEffect(() => {
        async function renderCall() {
            await props.getTenant({ limit: 15, offset: 0, isDeleted: false })
        }
        renderCall();
    }, []);

    const handleChange = async (event) => {
        const { value } = event.target;
        await props.getTenant({ limit: 15, offset: 0, name: value ,isDeleted:false})
    };

    const activeTenants = async() => {
        setActive('active');
        await props.getTenant({ limit: 15, offset: 0, isDeleted: false })
    }

    const deletedTenants = async() => {
        setActive('inactive');
        await props.getTenant({ limit: 15, offset: 0, isDeleted: true })
    }

    const { tenantReducer: { tenants } } = props;
    const { id } = props;

    return (
        <div className="tenantDtlLeft">
            {!tenants.success && <LoaderSpinner />}
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item">
                      <a
                        className={active === 'active' ? "nav-link active": "nav-link"}
                        onClick= {()=> activeTenants()}
                        role="tab"
                        aria-selected="true"
                        >Active </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={active === 'inactive' ? "nav-link active": "nav-link"}
                        onClick= {()=> deletedTenants()}
                        role="tab"
                        aria-selected="false"
                        >In Active</a>
                    </li>
                  </ul>
            {/* <h3><img src="/images/user.png" /><span>Tenants</span></h3> */}
            <div className="searchOtr">
                <DebounceInput
                    className="form-control"
                    placeholder="Search Tenants"
                    minLength={2}
                    debounceTimeout={800}
                    onChange={handleChange}
                />
                <img src="/images/search.png" className="searchIcn" />
            </div>

            <div className="tenantList">
                <ul>
                    {
                        tenants.tenantList && Object.values(tenants.tenantList).map((tenant, i) => {
                            return <li className= {tenant._id == id ? "activeTab" : ""} onClick={() => props.onChangePerson(tenant._id)} key={i}>
                                <div className="txtNameLet">
                                    {tenant.firstName.charAt(0).toUpperCase() +
                                        tenant.lastName.charAt(0).toUpperCase()}
                                </div>
                                <div className="imgDtlSec">
                                    <h4>{tenant.firstName.charAt(0).toUpperCase() + tenant.firstName.slice(1) + " " +
                                        tenant.lastName.charAt(0).toUpperCase() + tenant.lastName.slice(1)}</h4>
                                    <p className="place"> {tenant.propertyId && tenant.propertyId.address},{" "} {tenant.propertyId && tenant.propertyId.state && tenant.propertyId.state}</p>
                                </div>
                            </li>
                        })
                    }
                </ul>
                <div className="col-md-12 text-right tenantnoneBtn">
                    <button className="btn btn-primary btnTenClose viewAllTenant">Cancel</button>
                    <button className="btn btn-primary btnTensub viewAllTenant">Submit</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer } = state;
    return {
        tenantReducer,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(TenantDetailListing);
