import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import commonAction from "../../../Common/action";
import action from "../../../Maintenance/action";
import tenantAction from '../../../Tenants/action'
import moment from 'moment';
import history from '../../../../config/history';
import settingsAction from '../../../Settings/action';
import MessageBox from "../../../Common/components/MessageBox";
import SignLease from "../../../Leases&Files/components/signModal";
import Confirm from "../../../Common/components/Confirm";
import Modal from "../../../Common/components/Modal";





const Lease = props => {
    const [isSign, setIsSign] = useState(false);
    const [leaseSign, setLeaseSign] = useState(null);
    const [leaseSignModal, setLeaseSignModal] = useState(null);
    const [message, setMessage] = useState(null);


    useEffect(() => {
        async function renderCall() {
            props.getTenantPropertyDetails()
        }
        renderCall();
    }, [props.profileDetail])
    // const { profileDetail: { profileDetail } } = props;
    const { commonReducer: { propertyDetailsByTenant } } = props;
    const { property, maintenance } = propertyDetailsByTenant || {}

    const signAgreement = (lease) => {
        if (!lease.isTenantSigned) {
            setIsSign(true)
            setLeaseSign(lease)
        }
        else {
            setMessage('Document is already signed!!')
        }


    }

    const renderSignConfirm = () => {
        return <Confirm
            title='Sign Lease'
            message='Do you want to sign now?'
            onCancel={closeSignConfirm}
            onConfirm={signLease}
        />
    }

    const closeSignConfirm = () => {
        setIsSign(false)
    }

    const signLease = () => {
        setIsSign(false)
        setLeaseSignModal(true)
    }

    const closeSignLease = async () => {
        setLeaseSignModal(false)
        await props.getTenantPropertyDetails()
    }

    const renderSignLease = () => {
        return <SignLease
            onCancel={closeSignLease}
            lease={leaseSign.documentData}
            leaseId={leaseSign._id}
            isTenantSign={true}
        />
    }


    return (
        <div className="autoplay-header mb-3">
            {message ? <Modal body={<MessageBox
                onCancel={() => setMessage(null)}
                message={message}
                title={<i style={{ 'color': 'indianred', 'fontSize': '30px' }}
                    className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
            />
            } onCancel={() => setMessage({ text: null })}
            /> : null}
            {leaseSignModal ? <Modal body={renderSignLease()} onCancel={closeSignLease} /> : null}
            {isSign ? <Modal body={renderSignConfirm()} onCancel={closeSignConfirm} /> : null}
            <div className="autoplay-setup">
                <img src="/images/Lease.png" />
                <h4 onClick={() => history.push('/Leases')}>LEASE</h4>
                {/* <p>and never miss a rental payment again.</p> */}
            </div>
            <div className="auto-ply-btn txt-not-sent">
                

                {property &&
                    property.leaseId ? property.leaseId.status === 0 ? 'Not Sent' :
                        (property.leaseId.status === 1 ? "Pending" :
                            (property.leaseId.status === 2 ? "Active" : " N/A")) : " N/A"
                }
                <div className={"pl-2"}>
                    <button className={"btn "}
                    disabled={property && !property.agreementSent}
                     onClick={()=>signAgreement(property.leaseId)}
                     >Sign Now</button>
                </div>

            </div>

            {/* {console.log("props.autoPay.success props.autoPay.success ",props.settingsReducer.autoPay )}
            <div className="auto-ply-btn" onClick={() => history.push('Auto-Pay')}>
                <button className="btn">
                    {!props.settingsReducer.autoPay.success ? 'Enable Auto Pay': 'Disable Auto Pay'}
                </button>
            </div> */}
        </div>
    )
}

function mapStateToProps(state) {
    const { commonReducer, profileReducer, maintenanceReducer, settingsReducer } = state;
    return {
        messageDetailsByTenant: commonReducer.messageDetailsByTenant,
        profileReducer,
        maintenanceReducer,
        profileDetail: profileReducer.profileDetail,
        settingsReducer,
        commonReducer

    };
}

export default
    withRouter(connect(mapStateToProps, { ...commonAction, ...action, ...settingsAction, ...tenantAction })(Lease));
