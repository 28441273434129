import * as actions from "./constant";

const initialState = {
    business: {},
    businessList: {},
    payment: {},
    paymentList: {},
    addMailAccRes: {},
    deleteUser: {},
    addUser: {},
    users: {},
    deleteBusiness: {},
    card: {},
    deleteCard: {},
    paymentHistory: { payments: [] },
    autoPay: {},
    templateData: {},
    getMailDetails: {}
};

export default function tenantsReducer(state = initialState, action) {
    switch (action.type) {

        case actions.DELETE_BUSINESS_REQUEST:
            return {
                ...state,
                deleteBusiness: {
                    success: false
                }
            };

        case actions.DELETE_BUSINESS_SUCCESS:
            return {
                ...state,
                deleteBusiness: {
                    success: true
                }
            };

        case actions.DELETE_BUSINESS_FAILURE:
            return {
                ...state,
                deleteBusiness: { success: false, ...action.error }
            };

        case actions.ADD_PAYMENT_REQUEST:
            return {
                ...state
            };

        case actions.ADD_PAYMENT_SUCCESS:
            return {
                ...state,
                payment: {
                    success: true,
                    ...action.settingsAction,
                    message: "Business Entity created successfully"
                }
            };

        case actions.ADD_PAYMENT_FAILURE:
            return {
                ...state,
                payment: { success: false, ...action.error }
            };

        case actions.ADD_MAIL_ACCOUNT_REQUEST:
            return {
                ...state,
            };

        case actions.ADD_MAIL_ACCOUNT_SUCCESS:
            return {
                ...state,
                addMailAccRes: {
                    url: action.settingsAction,
                    time: new Date(),
                }
            };

        case actions.ADD_MAIL_ACCOUNT_FAILURE:
            return {
                ...state,
                addMailAccRes: { success: false, ...action.error }
            };


        case actions.ADD_USER_ACCOUNT_REQUEST:
            return {
                ...state,
            };

        case actions.ADD_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                addUser: action.addUserRes
            };

        case actions.ADD_USER_ACCOUNT_FAILURE:
            return {
                ...state,
                addUser: { success: false, ...action.error }
            };

        case actions.GET_USERS_SUCCESS:
            return {
                ...state,
                users: {
                    success: true,
                    userList: action.settingsAction.admin,
                    count: action.settingsAction.adminListCount
                }
            };

        case actions.GET_USERS_FAILURE:
            return {
                ...state,
                getReport: { success: false, ...action.error }
            };

        case actions.DELETE_USER_REQUEST:
            return {
                ...state
            };

        case actions.DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteUser: {
                    success: true,
                },
                users: { userList: state.users.userList.filter((tnt) => tnt._id != action.id), count: state.users.count }
            };

        case actions.DELETE_USER_FAILURE:
            return {
                ...state,
                deleteUser: { success: false, ...action.error }
            };


        case actions.ADD_CARD_REQUEST:
            return {
                ...state,
            };

        case actions.ADD_CARD_SUCCESS:
            return {
                ...state,
                card: { success: true, ...action.cardDetail }
            };

        case actions.ADD_CARD_FAILURE:
            return {
                ...state,
                card: { success: false, ...action.error }
            };

        case actions.DELETE_CARD_REQUEST:
            return {
                ...state,
            };

        case actions.DELETE_CARD_SUCCESS:
            return {
                ...state,
                deleteCard: { success: true, ...action.id }
            };

        case actions.DELETE_CARD_FAILURE:
            return {
                ...state,
                deleteCard: { success: false, ...action.error }
            };

        case actions.GET_PAYMENT_HISTORY_REQUEST:
            return {
                ...state,
            };

        case actions.GET_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                paymentHistory: { success: true, payments: action.settingsAction.transactions, count: action.settingsAction.transactionsCount }
            };

        case actions.GET_PAYMENT_HISTORY_FAILURE:
            return {
                ...state,
                paymentHistory: { success: false, ...action.error }

            }

        case actions.GET_AUTO_PAY_REQUEST:
            return {
                ...state,
            };

        case actions.GET_AUTO_PAY_SUCCESS:
            return {
                ...state,
                autoPay: { ...action.settingsAction }
            };

        case actions.GET_AUTO_PAY_FAILURE:
            return {
                ...state,
                autoPay: { success: false, ...action.error }

            }
        case actions.ADD_PDF_REQUEST:
            return {
                ...state,
            };

        case actions.ADD_PDF_SUCCESS:
            return {
                ...state,
                templateData: action.settingsAction
            };

        case actions.ADD_PDF_FAILURE:
            return {
                ...state,
                templateData: { success: false, ...action.error }

            }
        case actions.ADD_EDITED_PDF_REQUEST:
            return {
                ...state,
            };

        case actions.ADD_EDITED_PDF_SUCCESS:
            return {
                ...state,
                templateEdit: action.settingsAction
            };

        case actions.ADD_EDITED_PDF_FAILURE:
            return {
                ...state,
                templateEdit: { success: false, ...action.error }

            }

        case actions.GET_TEMPLATE_REQUEST:
            return {
                ...state,
            };

        case actions.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                templateDetails: action.settingsAction
            };

        case actions.GET_TEMPLATE_FAILURE:
            return {
                ...state,
                templateDetails: { success: false, ...action.error }

            }

        case actions.ADD_MAIL_SETTINGS_REQUEST:
            return {
                ...state,
            };

        case actions.ADD_MAIL_SETTINGS_SUCCESS:
            return {
                ...state,
                addMailDetails: action.settingsAction
            };

        case actions.ADD_MAIL_SETTINGS_FAILURE:
            return {
                ...state,
                addMailDetails: { success: false, ...action.error }

            }

        case actions.GET_MAIL_SETTINGS_REQUEST:
            return {
                ...state,
            };

        case actions.GET_MAIL_SETTINGS_SUCCESS:

            return {
                ...state,
                getMailDetails: { success: true, ...action.settingsAction.data }
            };

        case actions.GET_MAIL_SETTINGS_FAILURE:
            return {
                ...state,
                getMailDetails: { success: false, ...action.error }

            }
        case actions.DELETE_MAIL_SETTINGS_REQUEST:
            return {
                ...state,
            };

        case actions.DELETE_MAIL_SETTINGS_SUCCESS:
            return {
                ...state,
                deleteMailDetails: action.settingsAction
            };

        case actions.DELETE_MAIL_SETTINGS_FAILURE:
            return {
                ...state,
                deleteMailDetails: { success: false, ...action.error }

            }
        case actions.UPDATE_MAIL_SETTINGS_REQUEST:
            return {
                ...state,
            };

        case actions.UPDATE_MAIL_SETTINGS_SUCCESS:
            return {
                ...state,
                addMailDetails: { success: true, ...action.settingsAction }
            };

        case actions.UPDATE_MAIL_SETTINGS_FAILURE:
            return {
                ...state,
                addMailDetails: { success: false, ...action.error }

            }
        default:
            return state;
    }
}

