import React, { useState, useEffect } from "react";
import action from "../action";
import tenantAction from "../../Tenants/action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import AddCreditCard from './addCreditCard';
import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';
import history from '../../../config/history';

const BankAccount = (props) => {

    const [edit, setEdit] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [id, setId] = useState('');
    const [deleteId, setDeleteId] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);
    const [bank, setBank] = useState({
        bankName:'',
        accountNumber:'',

    });

    useEffect(() => {
        async function renderCall() {
            const { profileDetail: { profileDetail } } = props;
            if (profileDetail) {
                await props.getTenantId(profileDetail.admin.tenantId._id)
                setId(profileDetail.admin.tenantId._id)
            }
        }
        renderCall();
    }, [props.profileDetail.profileDetail]);

    const { card, deleteCards } = props;

    useEffect(() => {
        //For setting primary card
        if (submit && !card.success) {
            toastr.error(card.message)
        } else if (submit && card.success) {
            toastr.success("Successfully set as primary");
        }
    }, [card]);

    const onChange = (e)=> {
        const [name, value] = e.target;
        setBank({
            ...bank,
            [name]: value
        })
    }

    const { bankName } = bank;

    return (
        <div className="credit-card-wrapper ">
            <div className="credit-header">
                <h3>Bank Details</h3>
                <button className="btn" onClick={()=>setEdit(true)}>Edit Bank Details</button>
            </div>
            <div className="cutm-padng">
                <div className="row box-otr">
                    <div className="col-md-4 box-padding">
                        <div className="bank-box">
                            <h4>Bank Name</h4>
                            {edit ?
                            <input type="text" name="bankName" value={bankName} onChange={(e)=>onChange(e)}/>
                        :
                        <p>Standard Chartered</p>
                        }
                        </div>
                    </div>
                    <div className="col-md-4 box-padding">
                        <div className="bank-box">
                            <h4>Account Number</h4>
                            <p>0910960000000000</p>
                        </div>
                    </div>
                    <div className="col-md-4 box-padding">
                        <div className="bank-box">
                            <h4>Bank City</h4>
                            <p>Los Angeles</p>
                        </div>
                    </div>

                    <div className="col-md-4 box-padding mt-2">
                        <div className="bank-box">
                            <h4>Bank State</h4>
                            <p>San Francisco</p>
                        </div>
                    </div>
                    <div className="col-md-4 box-padding mt-2">
                        <div className="bank-box">
                            <h4>Bank Zip Code</h4>
                            <p>STC10002224</p>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, settingsReducer, profileReducer } = state;
    return {
        tenantReducer,
        profileDetail: profileReducer.profileDetail,
        card: settingsReducer.card,
        deleteCards: settingsReducer.deleteCard
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...tenantAction
})(BankAccount);
