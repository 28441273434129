import React, { useEffect, useState } from "react";
import action from "../../action";
import { connect } from "react-redux";
import toastr from '../../../Common/components/Toaster'
import LoaderSpinner from "../../../Common/components/Loader";
import commonAction from '../../../Common/action';
import InvoiceListing from '../../../Invoice/components/InvoiceListing';
import Modal from '../../../Common/components/Modal'
import Pagination from "../../../Common/components/pagination";
import AmountDetails from '../../../Invoice/TenantComponents/AmountDetailsTenant';
import invoiceAction from '../../../Invoice/action';
import usePrevious from '../../../Common/components/prevProps';
import moment from 'moment';
import TenantInvoiceNew from "./TenantInvoiceNew";
import LobDataModal from './LobDataModal';
import LobConfirm from './ConfirmLobModal';
import Confirm from '../../../Common/components/Confirm'
import dashboardAction from '../../../DashBoard/action'
import history from "../../../../config/history";


const Invoice = (props) => {
    const [lobConfirmModal, setLobConfirmModal] = useState(false);
    const [lobModal, setLobModal] = useState(false);
    const [InvoiceModal, setInvoiceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [params, setParams] = useState({ limit: 15, offset: 0, invoiceTypeId: null, propertyId: null, status: "" })
    const [invoiceTypeId, setInvoiceTypeId] = useState(null);
    const [currentPage, setcurrentPage] = useState(1);
    const [sectionType, setSectionType] = useState('')
    const [smsTenantArray, setSmsTenantArray] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLobMailSent, setIsLobMailSent] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [id, setId] = useState(false);
    const prevSms = usePrevious(props.sms)

    const [editData, setEditData] = useState({
        tenantId: props.id,
        amount: null,
        startDate: null,
        dueDate: null,
        invoiceTypeId: null,
        paymentTypeId: null,
        isRecurring: false,
        status: 0,
        isEdit: false,
        isView: false
    })
    const [deleteInvoices, setDeleteInvoices] = useState(false)

    //newly added

    const [isCheckedAll, setCheckedAll] = useState(false)
    const [isButtonActive, setButtonActive] = useState(false)
    const [smsTenant, setSmsTenant] = useState(false);
    const [tenantIds, setTenantIds] = useState([])
    const [paidArray, setPaidArray] = useState([])
    const [paymentSubmit, setPaymentSubmit] = useState(false)


    const prevUser = usePrevious(props.invoiceAdd);
    const prevMerge = usePrevious(props.mergeInvoiceDetails);
    const prevInvoiceDelete = usePrevious(props.invoiceDelete)
    const prevSmsInvoice = usePrevious(props.prevSmsInvoice)
    const prevPaymentData = usePrevious(props.payment)


    useEffect(() => {
        async function renderCall() {
            await props.getInvoiceTypes(props.id);
            await props.getTenantId(props.id)
            await props.getPaymentDetails();
        }

        renderCall();
        setTimeout(() => {
            setIsLoading(false)
        }, 500)

    }, [props.id]);

    const tenantName = props.tenantReducer.tenants.tenantList && props.tenantReducer.tenants.tenantList.filter((data, i) => data._id == props.id)

    useEffect(() => {
        if (paymentSubmit && prevPaymentData != props.payment && props.payment.success)
            toastr.success(props.payment.message)
        else if (paymentSubmit && prevPaymentData != props.payment && !props.payment.success)
            if (props.payment.data && props.payment.data.length && props.payment.data.length == 1) {
                let msg = `${props.payment.message} due to ${props.payment.data[0].message}`
                toastr.error(msg)
            }
            else {
                toastr.error(props.payment.message)
            }
    }, [prevPaymentData, props.payment])

    useEffect(() => {
        if (deleteInvoices && prevInvoiceDelete != props.invoiceDelete && !props.invoiceDelete.success) {
            toastr.error(props.Invoice.message)
        } else if (deleteInvoices && prevInvoiceDelete != props.invoiceDelete && props.invoiceDelete.success) {
            toastr.success(props.invoiceDelete.message)
        }
    }, [deleteInvoices, props.invoiceDelete]);

    useEffect(() => {
        const { sendLobMailResponse } = props.tenantReducer
        if (isSubmitted && isLobMailSent && sendLobMailResponse && sendLobMailResponse.success === true) {
            setLobConfirmModal(true)
            toastr.remove();
            //  window.open(docUrl)
            setIsLobMailSent(false)
        } else if (isSubmitted && sendLobMailResponse && sendLobMailResponse.success === false) {
            toastr.error(sendLobMailResponse.message || 'Failed to generate invoice!')
        }

    }, [props.tenantReducer.sendLobMailResponse])

    const { invoicesTypes, invoices, invoiceAdd } = props;

    const invoice = invoicesTypes.invoices && invoicesTypes.invoices.map(e => ({
        id: e.invoiceTypeId && e.invoiceTypeId._id,
        name: e.invoiceTypeId && e.invoiceTypeId.name
    }));
    const typeArray = Array.from(new Set(invoice && invoice.map(e => e.id)))
        .map(id => {
            return {
                id: id,
                name: invoice.find(s => s.id == id).name
            }
        })

    useEffect(() => {
        async function renderCall() {
            if (invoice && invoice.length && invoice[0].id) {
                let tempParams = params
                //tempParams.invoiceTypeId = invoice && invoice.length && invoice[0].id
                await props.getInvoicesByTenantId(props.id, tempParams)
                setIsSubmitted(true)
                const reverse = invoice && invoice.length && invoice.reverse();
                // setSectionType(reverse && reverse[0].name)
                setSectionType('All')
            }
        }

        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)

    }, [invoicesTypes, props.id]);

    useEffect(() => {
        if (isSubmitted && prevUser !== invoiceAdd && !invoiceAdd.success) {
            toastr.error(invoiceAdd.message)
        } else if (isSubmitted && prevUser !== invoiceAdd && invoiceAdd.success) {
            toastr.success(invoiceAdd.message);
        }
    }, [prevUser, invoiceAdd]);

    // useEffect(() => {
    //         console.log("props.mergeInvoice", props.mergeInvoiceDetails)
    //
    //         if (isSubmitted && prevMerge !== props.mergeInvoiceDetails) {
    //             if (!props.mergeInvoiceDetails.success) {
    //                 toastr.error("Failed to Merge!")
    //             } else if (props.mergeInvoiceDetails.success) {
    //                 toastr.success('Merged Successfully!');
    //             }
    //         }
    //     }, [prevUser, props.mergeInvoiceDetails]
    // );

    const filterData = async (value) => {
        let tempParams = params
        if (value) {
            tempParams.sort = value
            let orderDetails = !params.orderData
            tempParams.order = orderDetails ? "ASC" : "DESC"
            tempParams.orderData = !params.orderData
            // tempParams.invoiceTypeId = invoiceTypeId
            tempParams.invoiceTypeId = invoiceTypeId
        }
        setParams(tempParams)
        setIsLoading(true)
        await props.getInvoicesByTenantId(props.id, tempParams);
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getInvoicesByTenantId(props.id, data);
    }

    const handleTabs = async (tab, id) => {
        let tempParams = params
        tempParams.invoiceTypeId = id
        await props.getInvoicesByTenantId(props.id, tempParams);
        setSectionType(tab)
        setInvoiceTypeId(id);
    }

    const addInvoiceDetails = async (invoiceForm) => {
        setIsSubmitted(true)
        if (editData.isEdit) {
            await props.updateInvoice([invoiceForm]);
        } else {
            await props.addInvoice([invoiceForm]);
        }
        setInvoiceModal(false)
        await props.getInvoiceTypes(props.id);
        await props.getInvoice(params)
        let tempParams = params
        // tempParams.invoiceTypeId = invoiceForm.invoiceTypeId
        await props.getInvoicesByTenantId(props.id, tempParams);
        setEditData({ id: "" })
        setIsSubmitted(false)
    }

    //show message on sending sms for invoice list
    useEffect(() => {
        if (smsTenantArray && prevSms != props.sms) {
            let messageSuccess = null
            let messageFailure = null
            let temp = [];
            let failures = null
            if (props.sms.message && Array.isArray(props.sms.message)) {
                messageSuccess = props.sms.message && props.sms.message.filter((e) => e.status === 'success');
                messageFailure = props.sms.message && props.sms.message.filter((e) => e.status === 'failed');
                failures = messageFailure && messageFailure.length && messageFailure.map((e) => {
                    temp.push(e.receiverNumber)
                })
            }

            if (smsTenantArray && prevSms != props.sms && !props.sms.success) {
                toastr.error(props.sms.message)
            } else if (smsTenantArray && prevSms != props.sms && props.sms.success) {
                if (props.sms.message && Array.isArray(props.sms.message)) {
                    if (messageSuccess.length) {
                        toastr.success("Message sent successfully")
                    }
                    if (messageFailure.length) {
                        toastr.error(`Message sending failed for number: ${temp} `)
                    }
                }

            }
        }
    }, [prevSms, props.sms, smsTenantArray])

    const prevPayment = usePrevious(props.invoicePayment);


    //send payment sms for in invoice edit page on sendig sms
    useEffect(() => {
        if (smsTenant && prevSmsInvoice != props.smsInvoice && !props.smsInvoice.success) {
            toastr.error(props.smsInvoice.message)
        } else if (smsTenant && prevSmsInvoice != props.smsInvoice && props.smsInvoice.success) {
            if (props.smsInvoice.message.status === 'success') {
                toastr.success("Message sent successfully")
            } else {
                toastr.error(`Message sending failed`)
            }
        }
    }, [prevSmsInvoice, props.smsInvoice, smsTenant])

    useEffect(() => {

        if (isSubmitted && prevPayment !== props.invoicePayment && !props.invoicePayment.success) {
            toastr.error(props.invoicePayment.message)
        } else if (isSubmitted && prevPayment !== props.invoicePayment && props.invoicePayment.success) {
            toastr.success(props.invoicePayment.message);
        }
    }, [prevPayment, props.invoicePayment]);

    const openModal = () => {

        setEditData({
            ...editData,
            tenantDetails: {
                value: tenantName._id,
                firstName: tenantName.firstName,
                lastName: tenantName.lastName
            },
            isTenant: true
        })

        setInvoiceModal(true)
    }

    const closeModal = () => {
        setInvoiceModal(false)
        setEditData({
            tenantId: props.id,
            amount: null,
            startDate: null,
            dueDate: null,
            invoiceTypeId: null,
            paymentTypeId: null,
            isRecurring: false,
            status: 0,
            isEdit: false,
            isView: false,
            isTenant: true
        })
    }


    const attemptCapture = async () => {
        setIsSubmitted(true)
        await props.payInvoice(editData.paymentData)
        setInvoiceModal(false)
        // await props.getInvoice(params)
        await props.getInvoicesByTenantId(props.id, {
            offset: params.offset,
            limit: params.limit,
            invoiceTypeId: params.invoiceTypeId
        });
        setIsSubmitted(false)
        setIsLoading(false)
    }

    const renderModal = () => {
        return (
            <TenantInvoiceNew
                editData={editData}
                addInvoiceDetails={addInvoiceDetails}
                onCancel={closeModal}
                attemptCapture={attemptCapture}
                tenantInvoice={true}
                sendPaymentSms={sendPaymentSms}
                tempParams={params}
                id={props.id}
            />
        )
    }

    const sendPaymentSms = async (id, no, editData) => {

        if (editData.cardDetails && editData.cardDetails.length) {
            let isDefaultCard = editData.cardDetails.find(tc => tc.defaultCard).cardNumber
            if (isDefaultCard) {
                setSmsTenant(true);
                await props.sendSmsEachInvoice({ receiverId: id, invoiceNumber: no })
                setTimeout(() => setSmsTenant(false), 500)
            }
        }
        else {
            toastr.error("Please add a valid card for the selected invoice ")
        }
    }



    const editInvoice = async (invoice) => {
        const { invoiceList } = props.tenantReducer;

        let editDataTemp = {
            id: invoice._id,
            invoiceNumber: invoice.invoiceNumber,
            lastCaptureAttempt: invoice.attemptToCaptureDate,
            balance: invoiceList && invoiceList.data && invoiceList.data.balance,
            status: invoice.status,
            startDate:invoice.startDate ? moment((invoice.startDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null,
            dueDate:invoice.dueDate ? moment((invoice.dueDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null, 
            // dueDate:moment(new Date(invoice.startDate)).format('MM/DD/YYYY'),
            tenantId: invoice.tenantId._id,
            tenantName: invoice.tenantId && props.invoices.tenantId ? (props.invoices.tenantId.firstName + ' ' + props.invoices.tenantId.lastName) : '',
            tenantAddress: invoice.tenantId && invoice.tenantId.propertyId && invoice.tenantId.propertyId.propertyName,
            isRecurring: invoice.isRecurring,
            amount: invoice.amount,
            invoiceTypeId: invoice.invoiceTypeId._id,
            invoiceTypeName: invoice.invoiceTypeId.name,
            paymentTypeId: invoice.paymentTypeId._id,
            paymentTypeName: invoice.paymentTypeId.name,
            description: invoice.description || invoice.title,
            paidAmount: invoiceList && invoiceList.data && invoiceList.data.paidAmount,
            totalDue: invoiceList && invoiceList.data && invoiceList.data.totalDue,
            leaseStartDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.startDate,
            leaseEndDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.endDate,
            cardDetails: invoice.tenantId && invoice.tenantId.cardDetails,
            tenantDetails: {
                value: invoice.tenantId._id,
                firstName: invoice.tenantId.firstName,
                lastName: invoice.tenantId.lastName
            },
            invoices: invoice.charges,
            isEdit: true,
            isView: false,
            isTenant: true,
            paymentData: {
                invoiceId: invoice._id,
                businessID: invoice.tenantId && invoice.tenantId.propertyId && invoice.tenantId.propertyId.paymentId,
                tenantID: invoice.tenantId._id,
                customerPaymentProfileId: invoice.tenantId && invoice.tenantId.customerPaymentProfileId,
                customerProfileId: invoice.tenantId && invoice.tenantId.customerProfileId,
                amount: invoice.amount,
            }
        }

        setEditData(editDataTemp)
        setInvoiceModal(true)
    }

    const handleAttemptCapture = async () => {
        setIsLoading(true)
        if (paidArray && paidArray.length) {
            let isPaid = paidArray.find(pa => pa.status == 1)
            if (isPaid) {
                toastr.error("Please choose unpaid to continue!!")
                setIsLoading(false)

            } else {
                setPaymentSubmit(true)
                let tempArray = []
                paidArray.map(pa => tempArray.push(pa.id))
                await props.makeMultiplePayment({ invoiceId: tempArray })
                await props.getInvoicesByTenantId(props.id, params)
                setPaymentSubmit(false)
                setPaidArray([])
                setCheckedAll(false)
                setButtonActive(false)
                setIsLoading(false)

            }


        }

    }


    const sendLobMail = async () => {
        setIsSubmitted(true)
        const params = { tenantId: props.id }
        setIsLoading(true)
        setIsLobMailSent(true)
        await props.sendLobMail(params);
        setIsLoading(false)
    }

    const viewLobMail = () => {
        setIsLoading(true)
        setLobConfirmModal(false);
        setTimeout(() => {
            setIsLoading(false)
            setLobModal(true)
        }, 2000)
    }

    const closeLobModal = () => {
        setLobModal(false)
    }

    const renderLobModal = () => {
        const { sendLobMailResponse } = props.tenantReducer;
        const docUrl = sendLobMailResponse.data.invoicePath;
        return docUrl && <LobDataModal docUrl={docUrl} onCancel={closeLobModal} />
    }

    const closeLobConfirmModal = () => {
        setLobConfirmModal(false);
    }

    const renderLobConfirmModal = () => {
        return <LobConfirm
            close={closeLobConfirmModal}
            onView={viewLobMail}
        />
    }

    const handleDelete = async (id) => {
        setId(id)
        setIsSubmitted(true)
        setDeleteModal(true)
    }

    const deleteInvoice = async () => {
        setDeleteInvoices(true);
        await props.deleteInvoiceDetails([{ id: id, status: 1 }]);
        setIsSubmitted(false)
        setId('');
        await props.getInvoiceTypes(props.id);
        await props.getInvoicesByTenantId(props.id, params)

        setDeleteModal(false);
        setDeleteInvoices(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Invoice'
            message='Do you want to delete this invoice?'
            onCancel={closeDeleteModal}
            onConfirm={id ? deleteInvoice : deleteCheckedInvoice}
        />
    }

    const deleteCheckedInvoice = async () => {
        setIsSubmitted(true)
        setDeleteInvoices(true);
        await props.deleteInvoiceDetails(paidArray)
        setPaidArray([])
        setCheckedAll(false)
        await props.getInvoiceTypes(props.id);
        await props.getInvoicesByTenantId(props.id, params);
        window.location.reload()
        setDeleteModal(false)
        setIsSubmitted(false)
        setDeleteInvoices(false);
    }

    const closeDeleteModal = () => {
        setPaidArray([])
        setDeleteModal(false)
        setIsSubmitted(false)
        setButtonActive(false)
        setCheckedAll(false)
        window.location.reload()
    }


    //newly added


    const handleCheck = (e, invoice) => {
        let tempArray = paidArray;
        let tenantArray = tenantIds;
        if (e.target.checked) {
            //set tenants and invoice id for sms
            let tenant = {
                id: invoice._id,
                tenantId: invoice.tenantId ? invoice.tenantId._id : null,
                invoiceNumber: invoice.invoiceNumber,
                cardDetails: invoice.tenantId && invoice.tenantId.cardDetails

            }
            tenantArray.push(tenant);
            setTenantIds(tenantArray);

            //set invoices for delete


            //set unpaid invoices id for delete and mark as paid
            setButtonActive(true)
            let data = {
                id: invoice._id,
                status: invoice.status,
                tenantId: invoice.tenantId ? invoice.tenantId._id : null,
                cardDetails: invoice.tenantId && invoice.tenantId.cardDetails
            }
            tempArray.push(data);

            return setPaidArray(tempArray)
        } else {
            setCheckedAll(false)
            tempArray = tempArray.filter(t => t.id !== invoice.id)
            tenantArray = tenantArray.filter(t => t.id !== invoice.id)
            if (!tempArray.length) setButtonActive(false);
            setTenantIds(tenantArray);
            return setPaidArray(tempArray)
        }
    }

    const handleCheckAll = (e) => {
        let tempArray = []
        if (e.target.checked) {
            setButtonActive(true)
            setCheckedAll(true)
            return props.invoices && props.invoices.invoice && props.invoices.invoice.length ?
                props.invoices.invoice.map((invoice, key) => {
                    if (!invoice.status) {
                        let data = {
                            id: invoice._id,
                            tenantId: invoice.tenantId ? invoice.tenantId._id : null,
                            status: invoice.status,
                            invoiceNumber: invoice.invoiceNumber,
                            cardDetails: invoice.tenantId && invoice.tenantId.cardDetails

                        }
                        tempArray.push(data)
                        setPaidArray(tempArray)
                        setTenantIds(tempArray);
                    }
                })
                : null
        } else {
            setPaidArray([])
            setButtonActive(false)
            setCheckedAll(false)
        }
    }

    const handleDeleteChecked = async () => {
        setIsSubmitted(false)
        setDeleteModal(true)
    }

    const handleSendSms = async () => {
        setIsLoading(true)

        let isPaid = null
        let isCardDetails = null
        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 1)
            isCardDetails = paidArray.find(pa => pa.cardDetails && !pa.cardDetails.length)
        }
        if (isPaid) {
            toastr.error("Please choose unpaid to continue!!")
            setIsLoading(false)

        } else {
            if (!isCardDetails) {
                setSmsTenantArray(true);
                let result = []
                tenantIds.map(a => result.push({ receiverId: a.tenantId, invoiceNumber: a.invoiceNumber }))
                await props.sendSms(result);
                setPaidArray([])
                setButtonActive(false)
                setCheckedAll(false)
                setIsLoading(false)
                setTimeout(() => setSmsTenantArray(false), 500)
                setIsLoading(false)

            }
            else {
                toastr.error("Please add a valid card for the selected invoice")
                setIsLoading(false)

            }

        }

    }


    const handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = { ...params }
        tempParams.limit = value
        tempParams.offset = 0
        await props.getInvoicesByTenantId(props.id, tempParams)

        setParams({
            ...params,
            tenantDetails: null,
            [name]: value
        })
    }


    const handleMerge = async () => {
        let error = false
        let mergedArray = []
        let isPaid = null
        setIsLoading(true)

        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 1)
        }
        if (isPaid) {
            toastr.error("Please choose unpaid to continue!!")
        } else {
            if (paidArray.length > 1) {
                paidArray.map((pa, key) => {
                    let invoiceList = props.invoices.invoice.length && props.invoices.invoice.find((invoice) => invoice._id === pa.id)
                    return invoiceList ? mergedArray.length ? (mergedArray[key - 1].tenantId._id === pa.tenantId) ?
                        mergedArray.push(invoiceList)
                        : error = true
                        : mergedArray.push(invoiceList) :
                        null
                })
                if (error) {
                    setPaidArray([])
                    setCheckedAll(false)
                    toastr.error("Tickets should belongs to same tenant!!!")
                    window.location.reload()
                    mergedArray = []
                    error = false
                } else {
                    if (mergedArray.length) {
                        let chargeArray = []
                        let paymentId = props.commonReducer.paymentMethods.find(pm => pm.name === "Credit Card")
                        let invoiceTypeId = props.commonReducer.InvoiceTypeList.find(it => it.name === "Private")
                        mergedArray.map(ma => {
                            return ma.charges.map((ch) => chargeArray.push({
                                id: ma._id,
                                amount: ch.amount,
                                description: ch.description ? ch.description : ma.invoiceTypeId.name + '-' +
                                    (props.invoices.tenantId.tenantAddress || props.invoices.tenantId.propertyId.propertyName + '-' + '(' +
                                        moment(ma.startDate).format('MM/DD/YYYY') + '-' + moment(ma.dueDate).format('MM/DD/YYYY') + ')')
                            }))
                        })
                        // mergedArray.map(ma => {
                        //     console.log("mergedArray", mergedArray)
                        //     return chargeArray.push({
                        //         id: ma._id,
                        //         amount: ma.amount,
                        //         description: ma.charges[0].description ? ma.charges[0].description : ma.invoiceTypeId.name + '-' +
                        //             (props.invoices.tenantId.tenantAddress || props.invoices.tenantId.propertyId.propertyName + '-' + '(' +
                        //                 moment(ma.startDate).format('MM/DD/YYYY') + '-' + moment(ma.dueDate).format('MM/DD/YYYY') + ')')
                        //     })
                        // })
                        let tempData = {
                            description: mergedArray[0].charges[0].description ? mergedArray[0].charges[0].description : mergedArray[0].invoiceTypeId.name + '-' +
                                (props.invoices.tenantId.tenantAddress || props.invoices.tenantId.propertyId.propertyName + '-' + '(' +
                                    mergedArray[0].startDate + '-' + moment(mergedArray[0].dueDate).format('MM/DD/YYYY') + ')'),
                            invoiceTypeId: invoiceTypeId._id,
                            title: mergedArray[0].title,
                            startDate:moment(new Date()).format('MM/DD/YYYY'),
                            paymentTypeId: paymentId._id,
                            tenantId: mergedArray[0].tenantId,
                            dueDate: moment(mergedArray[0].dueDate).format('MM/DD/YYYY'),
                            charges: chargeArray
                        }
                        setIsSubmitted(true)
                        await props.mergeInvoice(tempData)
                        window.location.reload()
                        setIsSubmitted(false)

                        setPaidArray([])
                        // setCheckedAll(false)
                        mergedArray = []
                        setButtonActive(false)
                        await props.getInvoicesByTenantId(props.id, params)
                    }
                }

            } else {
                toastr.error("Select at least 2 tickets!!")
            }
        }
        setIsLoading(false)



        // if (error) {
        //     toastr.error("Error")
        // } else {
        //     alert("alertyk")
        // }

    }

    const handleUpdate = async () => {
        setIsLoading(true)

        let isPaid = null
        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 1)
        }
        if (isPaid) {
            toastr.error("Please choose unpaid to continue!!")
            setIsLoading(false)

        } else {
            if (paidArray && paidArray.length) {
                let tempArrayPay = []
                paidArray.map(pa => {
                    let tempData = {
                        id: pa.id,
                        tenantId: pa.tenantId ? pa.tenantId : null,
                        status: 1,
                        attemptToCaptureDate:moment(new Date()).toISOString()
                      
                    }
                    tempArrayPay.push(tempData)
                })
                setIsSubmitted(true)
                await props.updateInvoice(tempArrayPay);
                setIsSubmitted(false)
            } else {
                setIsSubmitted(true)
                await props.updateInvoice(paidArray);
                setIsSubmitted(false)
            }
            setIsLoading(false)

            // await props.payInvoice(paidArray)
            setPaidArray([])
            setCheckedAll(false)
            setButtonActive(false)
            await props.getInvoicesByTenantId(props.id, params)
            setIsLoading(false)

            setIsSubmitted(false)

        }
        setIsLoading(false)


    }

    const handleChangeStatus = async (e) => {
        setIsLoading(true)
        const { name, value } = e.target
        let tempParams = { ...params }
        if (value) {
            tempParams.status = parseInt(value)
            setParams(tempParams)
            await props.getInvoicesByTenantId(props.id, tempParams)
        }
        else {
            delete tempParams.status
            setParams(tempParams)
            await props.getInvoicesByTenantId(props.id, tempParams)
        }
        setIsLoading(false)

    }

    const handleUnpaid = async () => {
        let isPaid = null
        setIsLoading(true)

        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 0)
        }
        if (isPaid) {
            toastr.error("Please choose paid to continue!!")
            setIsLoading(false)

        } else {
            if (paidArray && paidArray.length) {
                let tempArrayPay = []
                paidArray.map(pa => {
                    let tempData = {
                        id: pa.id,
                        tenantId: pa.tenantId ? pa.tenantId : null,
                        status: 0
                       
                    }
                    tempArrayPay.push(tempData)
                })
                setIsSubmitted(true)
                await props.updateInvoice(tempArrayPay);
                setIsSubmitted(false)
            } else {
                setIsSubmitted(true)
                await props.updateInvoice(paidArray);
                setIsSubmitted(false)
            }
            // await props.payInvoice(paidArray)
            setPaidArray([])
            setCheckedAll(false)
            setButtonActive(false)
            await props.getInvoicesByTenantId(props.id, params)
            setIsLoading(false)
            setIsSubmitted(false)
            // history.push('/Invoice/All')
        }
        setIsLoading(false)

    }

    const { sendLobMailResponse } = props.tenantReducer;
    return (
        <div className="tenants-otr-invoice outrTabBg">
            {/* <div className="markpaidBtn mr-3">
                <button className="markPaid" onClick={sendLobMail}>
                    <i className="fas fa-envelope"></i>&nbsp;Send Notice
                </button>
            </div> */}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {(isLoading) && <LoaderSpinner />}
            {lobConfirmModal ? <Modal body={renderLobConfirmModal()} onCancel={closeLobConfirmModal} /> : null}
            {sendLobMailResponse.data && sendLobMailResponse.data.invoicePath &&
                lobModal ? <Modal body={renderLobModal()} onCancel={closeLobModal} /> : null}

            {
                InvoiceModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            <div className="tenants-inner">
                <AmountDetails openModal={openModal} sendLobMail={sendLobMail}
                    paid={invoices.paid}
                    rent={invoices.tenantId}
                    unPaid={invoices.unPaid} 
                    overdueAmount={invoices.overdueAmount} />
                <section className="tenant-tabs">
                    {/* <div className="row"> */}
                    <div className="col-md-12 tab-shadow d-flex justify-content-between">
                        <nav>
                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a
                                    className={sectionType === 'All' ? "nav-item nav-link active" : "nav-item nav-link"}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleTabs('All')}
                                    aria-selected="true">All</a>
                                {typeArray.length ? typeArray.reverse().map((type, key) => {
                                    return <a
                                        className={sectionType === type.name ? "nav-item nav-link active" : "nav-item nav-link"}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleTabs(type.name, type.id)}
                                        aria-selected="true" key={key}>{type.name}</a>
                                }) : null}
                            </div>

                        </nav>
                        <div className="invoice-btn">
                            <button className="btnInvo" onClick={openModal}><i
                                className="fas fa-plus"></i> Add
                                Invoice
                            </button>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-private" role="tabpanel"
                                aria-labelledby="nav-private-tab">
                                <div className="innago-table table-main invoiceTable">
                                    {/* <div className="markpaidBtn">
                                            <button className="markPaid" onClick={sendLobMail}>
                                                <i className="fas fa-envelope"></i>
                                                &nbsp;Send Notice
                                            </button>
                                        </div> */}
                                    <InvoiceListing
                                        offset={params.offset}
                                        isTenantView={false}
                                        editInvoice={editInvoice}
                                        filterData={filterData}
                                        handleDelete={handleDelete}
                                        handleUpdate={handleUpdate}
                                        InvoiceList={invoices}
                                        handleCheck={handleCheck}
                                        handleCheckAll={handleCheckAll}
                                        paidArray={paidArray}
                                        isCheckedAll={isCheckedAll}
                                        isButtonActive={isButtonActive}
                                        handleDeleteChecked={handleDeleteChecked}
                                        tenantIds={tenantIds}
                                        handleSendSms={handleSendSms}
                                        limit={params.limit}
                                        handleChange={handleChangeLimit}
                                        handleMerge={handleMerge}
                                        handleAttemptCapture={handleAttemptCapture}
                                        handleUnpaid={handleUnpaid}
                                        handleChangeStatus={handleChangeStatus}
                                        status={params.status}
                                        count={props.tenantReducer.invoiceList.invoiceCount}
                                        isTenantViewDetail={true}

                                    />
                                    <div className="paginationSec">
                                        {props.tenantReducer && props.tenantReducer.invoiceList.invoiceCount ?
                                            <Pagination
                                                totalRecords={props.tenantReducer.invoiceList.invoiceCount}
                                                pageLimit={params.limit}
                                                pageNeighbours={1}
                                                currentPage={currentPage}
                                                onPageChanged={onPageChanged}
                                            /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </section>
            </div>
        </div>

    )
}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer, invoiceReducer, dashboardReducer } = state;
    return {
        invoiceAdd: invoiceReducer.Invoice,
        commonReducer,
        invoices: tenantReducer.invoiceList,
        invoicesTypes: tenantReducer.invoicesTypes,
        tenantReducer,
        invoiceDelete: invoiceReducer.invoiceDelete,
        Invoice: invoiceReducer.Invoice,
        sms: invoiceReducer.sms,
        smsInvoice: invoiceReducer.smsInvoice,
        invoicePayment: invoiceReducer.invoicePayment,
        mergeInvoiceDetails: invoiceReducer.mergeInvoice,
        payment: dashboardReducer.payment

    };
}

export default connect(mapStateToProps, { ...action, ...commonAction, ...invoiceAction, ...dashboardAction })(Invoice);
