import React, { useState, useEffect } from "react";
import { MAIN_ROUTE } from "../../../config/constants";
import moment from 'moment';
import ViewMaintenance from './maintenanceView'
import toastr from '../../Common/components/Toaster'
import LoaderSpinner from "../../Common/components/Loader";
import Modal from '../../Common/components/Modal'
import action from "../action";
import { connect } from "react-redux";
import history from '../../../config/history'

const MaintenanceList = (props) => {

    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
    const [viewMaintenance, setViewMaintenance] = useState(false)
    const [email, setEmail] = useState('');
    const [id, setId] = useState('');

    const parseBackgoundImage = (image) => {
        if (image.length) {
            if (image[0].type && image[0].type == "zillo") {
                return image[0].path
            } else {
                return MAIN_ROUTE + image[0].path
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }

    useEffect(() => {
        if (props.maintenanceList) {
            if (props.maintenanceList.maintenanceList && props.maintenanceList.maintenanceList.length) {
                const { maintenanceList } = props.maintenanceList
                let closeArray = maintenanceList.filter(item => item.status != 0)
                setIsCheckboxDisabled(closeArray.length === 0)
            } else {
                setIsCheckboxDisabled(true)
            }
        } else {
            setIsCheckboxDisabled(true)
        }
    }, [props.maintenanceList])


    const renderStatus = (status) => {
        switch (status) {
            case 0:
                return <span className="statusclose">Closed</span>
            case 1:
                return <span className="answered">Open</span>
            case 2:
                return <span className="inProgress">Answered</span>
            case 3:
                return <span className="inProgress">Customer-Replay</span>
            case 4:
                return <span className="answered">On Hold</span>
            case 5:
                return <span className="answered">In Progress</span>

            case 6:
                return <span className="inProgress">Scheduled</span>

        }
    }

    const openViewMaintenance = (id, email) => {
        history.push(`/SupportTicket/${id}/AddReplay`)
        // setViewMaintenance(true);
        // setEmail(email);
        // setId(id);
        // props.viewMaintenanceById(id);
    }

    const closeViewMaintenance = () => {
        setViewMaintenance(false);
    }

    const renderViewMaintenance = () => {
        return props.viewMaintenance.data && email && <ViewMaintenance
            onCancel={closeViewMaintenance}
            maintenances={props.viewMaintenance.data}
            email={email}
            reloadData={reloadData}
        />
    }

    const deleteMaintenance = () => {

    }

    const reloadData = async () => {
        await props.viewMaintenanceById(id);
    }


    return (
        <div className={`table-responsive  ${props.maintenanceList && props.maintenanceList.maintenanceList && props.maintenanceList.maintenanceList.length ? " " : "tab-arw"}`}>
            {viewMaintenance ? <Modal body={renderViewMaintenance()} onCancel={closeViewMaintenance} /> : null}
            {props.maintenanceList && props.maintenanceList.maintenanceList && props.maintenanceList.maintenanceList.length ?
                <table className="table-gap table-td">
                    <tr className="table-head">
                        <th className="maintenanceChkbxPadng">
                            <label className="maintenanceCheckbox"
                                style={{ cursor: isCheckboxDisabled ? 'no-drop' : '' }}
                            ><input type="checkbox" name="isArchived" onClick={props.handleCheckAll}
                                disabled={isCheckboxDisabled}
                                checked={props.isCheckedAll} />
                                <span className="checkmark"></span>
                            </label>
                        </th>
                        <th  onClick={() => props.filterData("maintenanceTicketNumber")}>
                            <div className="thTab">
                                <div className="txtTabl">Ticket Number</div>
                                <div className="TxtImg">
                                    <img src="/images/doublearrow.png" className="imgArw" /></div>
                            </div>
                        </th>
                        <th  onClick={() => props.filterData("department")}>
                            <div className="thTab">
                                <div className="txtTabl">Department</div>
                                <div className="TxtImg">
                                    <img src="/images/doublearrow.png" className="imgArw" /></div>
                            </div>
                        </th>
                        <th  onClick={() => props.filterData("title")}>
                            <div className="thTab">
                                <div className="txtTabl">Subject</div>
                                <div className="TxtImg">
                                    <img src="/images/doublearrow.png" className="imgArw" /></div>
                            </div>
                        </th>
                        <th  onClick={() => props.filterData("contractorName")}>
                            <div className="thTab">
                                <div className="txtTabl">Assign To</div>
                                <div className="TxtImg">
                                    <img src="/images/doublearrow.png" className="imgArw" /></div>
                            </div>
                        </th>
                        <th  onClick={() => props.filterData("firstName")}>
                            <div className="thTab">
                                <div className="txtTabl">Submitter</div>
                                <div className="TxtImg">
                                    <img src="/images/doublearrow.png" className="imgArw" /></div>
                            </div>
                        </th>
                        <th  onClick={() => props.filterData("status")}>
                            <div className="thTab">
                                <div className="txtTabl">Status</div>
                                <div className="TxtImg">
                                    <img src="/images/doublearrow.png" className="imgArw" /></div>
                            </div>
                        </th>
                        <th  onClick={() => props.filterData("lastMessageCreatedAt")}>
                            <div className="thTab">
                                <div className="txtTabl">Last Reply</div>
                                <div className="TxtImg">
                                    <img src="/images/doublearrow.png" className="imgArw" /></div>
                            </div>
                        </th>
                        <th>Action</th>
                    </tr>
                    {props.maintenanceList.maintenanceList.map((maintenance, key) => {
                        let isChecked = props.closeArray && props.closeArray.length ? props.closeArray.find(p => p.id === maintenance._id) : null
                        return <tr key={key}>
                            <td className="maintenanceChkbxPadng ">
                                <label className="maintenanceCheckbox"
                                    style={{ cursor: !maintenance.status ? 'no-drop' : '' }}
                                ><input type="checkbox" name="isArchived"
                                    disabled={!maintenance.status}
                                    checked={isChecked}
                                    onChange={(e) => props.handleCheck(e, maintenance)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </td>
                            <td>
                                <p className={'reporter'}>{maintenance.maintenanceTicketNumber ? maintenance.maintenanceTicketNumber : "N/A"}</p>
                            </td>
                            <td className="cursorPointer"
                                onClick={() => openViewMaintenance(maintenance._id, maintenance.requestById && maintenance.requestById.email)}>
                                <p className={"suppot"}><i className="fas fa-flag"></i> Support</p>
                            </td>
                            <td className="cursorPointer"
                                onClick={() => openViewMaintenance(maintenance._id, maintenance.requestById && maintenance.requestById.email)}>
                                <p className={"maintenanceSubj"} style={{ width: "320px" }}>
                                    {/*#820413-808 */}
                                    {maintenance.title}
                                </p>
                            </td>

                            <td>
                                <p className={'reporter'}>{
                                    maintenance.assignee == "3" ?
                                        (maintenance.contractorName ? maintenance.contractorName : "N/A") :
                                        (maintenance.assignee == "1" ? "Un Assigned" : (maintenance.assignee == "2" ? "To Self" : "N/A"))

                                }</p>
                            </td>

                            <td>
                                <p className={'reporter'}>{maintenance.requestById ? maintenance.requestById.firstName + " " + maintenance.requestById.lastName : "N/A"}</p>
                            </td>

                            <td>{renderStatus(maintenance.status)}</td>
                            <td className={"reporter"}>{maintenance.lastMessageCreatedAt ? (moment(maintenance.lastMessageCreatedAt)).fromNow() : "N/A"}</td>
                            <td>
                                {maintenance.status ?
                                    <>

                                        <div className="justify-content-center editDetlPrp d-flex">
                                            <i
                                                className="fas fa-pencil-alt mr-2"
                                                onClick={() => props.editMaintenance(maintenance)}></i>
                                            <i
                                                className="fa fa-trash "
                                                onClick={() => props.deleteMaintenanceById(maintenance)}></i>

                                        </div>
                                    </>
                                    :
                                    <>

                                        <div className="justify-content-center disable-div editDetlPrp d-flex">
                                            <i
                                                className="fas fa-pencil-alt mr-2" style={{ cursor: 'no-drop' }}></i>
                                            <i
                                                className="fa fa-trash " style={{ cursor: 'no-drop' }}></i>

                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    })}

                </table>
                : <div className="no_value"><i className="far fa-thumbs-down"></i> Click here to add your maintenance request!!!</div>}

        </div>
    )
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer, tenantReducer } = state;
    return {
        viewMaintenance: maintenanceReducer.viewMaintenance,
        commonReducer,
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(MaintenanceList);
