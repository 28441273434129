import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from 'moment'
import action from "../../action";
import commonAction from '../../../Common/action'
import Pagination from '../../../Common/components/pagination';
import TransactionList from "./transactionList";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import 'rc-menu/assets/index.css';
import animate from 'css-animation';
import PlaidLinkButton from "react-plaid-link";
import axios from "axios";
import LoaderSpinner from "../../../Common/components/Loader";
import Confirm from '../../../Common/components/Confirm';
import Modal from '../../../Common/components/Modal'
import CommonDatePicker from '../../../Common/components/CommonDatePicker'
import usePrevious from "../../../Common/components/prevProps";
import { numberWithCommas } from "../../../Common/components/utils";
import toastr from "../../../Common/components/Toaster";




const DataSource = (props) => {


    const [params, setParams] = useState({
        limit: 25, offset: 0, maxDate: null, minDate: null,
        minAmount: null, maxAmount: null,
        account: null, category: null, categoryName: null,
        accountDetails: null
    })
    const [currentPage, setcurrentPage] = useState(1);
    const [dateFilter, isDateFilter] = useState(false)
    const [amountFilter, isAmountFilter] = useState(false)
    const [isMenu, setMenu] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isConfirm, setConfirm] = useState(false)
    const [handleCategoryId, setHandleCategory] = useState(null)
    const [isTextArea, setTextArea] = useState(null)
    const [collapsed, setCollapsed] = useState(true)
    const [checkedArray, setCheckedArray] = useState([])
    const [checkedAll, setCheckedAll] = useState(false)
    const [deleteModal, isDeleteModal] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)




    const prevLimit = usePrevious(props.commonReducer.limitData);


    const handleKeyUp = () => {
        setCollapsed(false)
        if (isTextArea) {
            setTextArea(null)
        }
        if (handleCategoryId) {
            setHandleCategory(null)
        }
    }

    useEffect(() => {
        props.getLimitData()
        props.getCategories()

    }, []);


    useEffect(() => {
        if (prevLimit != props.commonReducer.limitData) {
            const { limitData } = props.commonReducer
            if (limitData) {
                let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 8)
                let tempParams = params
                tempParams.limit = limitCount ? limitCount.count : 25
                setParams(tempParams)
                props.getTransaction(tempParams)
                props.getDataSource({ status: 1 })
            }
        }

    }, [prevLimit, props.commonReducer.limitData]);





    // useEffect(() => {
    //         document.addEventListener('mousedown', handleKeyUp);
    //         return () => document.addEventListener('mousedown', handleKeyUp);
    //     }, [collapsed]
    // )

    const onPageChanged = async page => {
        setHandleCategory(null)
        setTextArea(null)
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getTransaction(data);
    }


    useEffect(() => {
        if (!collapsed) {
            const handleKeyUp = (event) => {
                setTextArea(null)
                setHandleCategory(null)
            }

            window.addEventListener("mousedown", handleKeyUp);
            return () => window.removeEventListener("mousedown", handleKeyUp);
        }
    }, [collapsed]);


    const handleClickOutside = () => {
        setTextArea(null)
        setHandleCategory(null)
    }

    const openDateFilter = () => {
        isDateFilter(!dateFilter)
    }

    const closeDateFilter = () => {
        isDateFilter(false)
    }

    const onChangeDate = (value, type) => {
        setParams({ ...params, [type]: moment(new Date(value)).endOf('day').toISOString()})
    }

    const openAmountFilter = () => {
        isAmountFilter(!amountFilter)
    }

    const closeAmountFilter = () => {
        isAmountFilter(false)
    }

    const onChangeAmount = (e) => {
        const { name, value } = e.target
        setParams({ ...params, [name]: value })
    }

    const filterData = () => {
        props.getTransaction(params)
    }

    const clearDateFilter = () => {
        let tempParams = { ...params }
        tempParams.minDate = null
        tempParams.maxDate = null

        setParams({ ...params, minDate: "", maxDate: "" })
        props.getTransaction(tempParams)

    }

    const clearAmountFilter = () => {
        let tempParams = { ...params }
        tempParams.minAmount = null
        tempParams.maxAmount = null
        setParams({ ...params, minAmount: "", maxAmount: "" })
        props.getTransaction(tempParams)
    }


    const Accounts = props.dataSources && props.dataSources.accounts && props.dataSources.accounts.length && props.dataSources.accounts.map(source => ({
        value: source._id,
        label: source.name
    }))

    const promiseOptionsProperty = async val => {
        await props.getDataSource({ name: val, status: 1 })
        const Accounts = props.dataSources && props.dataSources.accounts && props.dataSources.accounts.length && props.dataSources.accounts.map(source => ({
            value: source._id,
            label: source.name
        }))
        return Accounts.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeAccount = async (value) => {
        let tempParams = { ...params }
        tempParams.account = value.value
        setParams({ ...params, account: value.value, accountDetails: value })
        props.getTransaction(tempParams)
    }

    const renderSubmenu = (category) => {
        return category.subCategories.map(sub => {
            return <MenuItem value={sub._id}>{sub.title}</MenuItem>
        })
    }

    const handleOption = async (info) => {
        setMenu(false)
        let tempParams = { ...params }
        tempParams.category = info.item.props.value
        setParams({ ...params, category: info.item.props.value, categoryName: info.item.props.children })
        await props.getTransaction(tempParams)
        await props.getCategories()

    }

    const handleOnSuccess = async (public_token, metadata) => {
        //send token to client server
        // axios.post("/auth/public_token", {
        //     public_token: public_token
        // });
        setConfirm(false)
        setIsSubmitted(true)
        await props.postPlaidRequest({ publicToken: public_token })
        setIsSubmitted(false)

        await props.getDataSource({ status: 1 })
        await props.getTransaction(params)
    }

    const handleOnExit = () => {
        setIsSubmitted(true)
        // handle the case when your user exits Link
        // For the sake of tutorial, we're not going to be doing anything here.
    }

    const openConfirm = () => {

        setConfirm(true)

    }

    const closeConfirm = () => {
        setConfirm(false)
    }

    const handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = params
        tempParams.limit = value
        tempParams.offset = 0
        setParams(tempParams)
        await props.updateLimitData({ type: 8, count: value });
        await props.getDataSource({ status: 1 })
        await props.getTransaction(tempParams)
    }

    const renderConfirm = () => {
        return <Confirm
            title={"Change Account"}
            onCancel={closeConfirm}
            message={'All your data will lost by changing!!!  Are you sure you want to continue?'}
            plaidConfirm={true}
            handleOnExit={handleOnExit}
            handleOnSuccess={handleOnSuccess}
        />
    }

    const clearAllFilter = async () => {
        let tempParams = { ...params }
        tempParams.minDate = null
        tempParams.maxDate = null
        tempParams.minAmount = null
        tempParams.maxAmount = null
        tempParams.category = null
        tempParams.account = null

        setParams({
            ...params,
            minDate: "", maxDate: "", minAmount: "", maxAmount: "",
            account: "", category: "", categoryName: "",
            accountDetails: ""
        })
        await props.getTransaction(tempParams)
    }

    const handleKeyIndex = (e, value) => {
        e.preventDefault()

        setHandleCategory(value)
    }

    const handleCheck = (e, transaction) => {
        let amt = totalAmount
        let tempArray = checkedArray
        if (e.target.checked) {
            tempArray.push({
                transactionId: transaction._id
            })
            amt += transaction.amount
            setTotalAmount(amt)
            setCheckedArray(tempArray)
        }
        else {
            setCheckedAll(false)
            amt -= transaction.amount
            setTotalAmount(amt)
            tempArray = tempArray.filter(t => t.transactionId !== transaction._id)
            setCheckedArray(tempArray)

        }




    }
    const handleCheckAll = (e) => {
        let amt = totalAmount
        if (e.target.checked) {
            if (props.transactionData && props.transactionData.transactions && props.transactionData.transactions.length) {
                let tempArray = []
                props.transactionData.transactions.map(transaction => {
                    amt += transaction.amount
                    tempArray.push({
                        transactionId: transaction._id
                    })
                })
                setCheckedArray(tempArray)
                setTotalAmount(amt)

                setCheckedAll(true)
            }
        }
        else {
            setCheckedArray([])
            setCheckedAll(false)
            setTotalAmount(0)
        }
    }

    const deleteMultiple = () => {
        if (checkedArray.length) {
            isDeleteModal(true)
        }
        else {
            toastr.error("Please select a transaction!")
        }
    }

    const unSelectAll = () => {
        setCheckedArray([])
        setCheckedAll(false)
        setCheckedArray([])
        setTotalAmount(0)
    }

    const renderDelete = () => {
        return <Confirm
            title='Delete Transaction'
            message='Do you want to delete this transaction?'
            onCancel={closeTransaction}
            onConfirm={deleteTransactionData}
        />
    }

    const closeTransaction = () => {
        isDeleteModal(false)
        setCheckedAll(false)
        setCheckedArray([])
        setTotalAmount(0)
    }

    const deleteTransactionData = async () => {
        if (checkedArray.length) {
            isDeleteModal(false)
            setIsSubmitted(true)
            await props.deleteTransactionMultiple(checkedArray)
            await props.getTransaction(params)
            setIsSubmitted(false)
            setCheckedAll(false)
            setCheckedArray([])
            setTotalAmount(0)

        }
        else {
            toastr.error("Please select a transaction!")
        }
    }

    const handleSearch = async (e) => {
        await props.getCategories({ title: e.target.value })
    }

    const openMerge = () => {
        setIsSubmitted(true)
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000);

    }

    return (
        <>
            {isSubmitted && <LoaderSpinner />}
            {isConfirm && <Modal body={renderConfirm()} onCancel={closeConfirm} />}
            {deleteModal ? <Modal body={renderDelete()} onCancel={closeTransaction} /> : null}
            <div className="row m-0">
                <div className=" transactionOuter col-md-9">
                    <div className="formsection transactionSelectForms">
                        <div className="row outrForm m-0 p-0">
                            <div className="col-md-12 p-0">
                                <div className="d-flex formInp">
                                    <div className="col-md-2 formsectionPadding">
                                        <div className="selctOtr fltr">
                                            <div className="form-control" onClick={() => openDateFilter()}>All Dates
                                            </div>
                                        </div>
                                        {dateFilter ?
                                            <div className="filterSide dropdownFilter" style={{ display: 'block' }}>
                                                <div className="formSection outerBg">
                                                    <button type="button" className="close" onClick={closeDateFilter}>
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                    <div className="col-md-12 row m-0 p-0 fltrOtr">
                                                        <div className="col-md-6 formInp">
                                                            <label>From</label>
                                                            {/*<input*/}
                                                            {/*data-date-format="mm/dd/yyyy"*/}
                                                            {/*id="datepicker"*/}
                                                            {/*placeholder="From"*/}
                                                            {/*className="form-control"*/}
                                                            {/*/>*/}
                                                            <CommonDatePicker
                                                                name="minDate"
                                                                placeholder="From"
                                                                disableClock={true}
                                                                value={params.minDate}
                                                                onChange={(value) => onChangeDate(value, 'minDate')}
                                                                showYearDropdown='true'
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 formInp">
                                                            <label>To</label>
                                                            <CommonDatePicker
                                                                name="maxDate"
                                                                placeholder="To"
                                                                disableClock={true}
                                                                value={params.maxDate}
                                                                onChange={(value) => onChangeDate(value, 'maxDate')}
                                                                showYearDropdown='true'
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="btnOtr text-right">
                                                        {/*<button*/}
                                                        {/*type="button"*/}
                                                        {/*className="btn btn-primary btnClear"*/}
                                                        {/*onClick={clearDateFilter}*/}
                                                        {/*>*/}
                                                        {/*Clear Filters*/}
                                                        {/*</button>*/}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btnApply"
                                                            onClick={filterData}
                                                        >
                                                            Apply Filters
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> : null}
                                    </div>
                                    <div className="col-md-3 formsectionPadding">
                                        <div className="selctOtr" contentEditable={true}>
                                            <input type={"text"} placeholder={"All Categories"} className="form-control"
                                                value={params.categoryName}
                                                onChange={(e) => handleSearch(e)}
                                                onClick={() => setMenu(!isMenu)} />
                                        </div>

                                        {isMenu && props.categoryData && props.categoryData.length ?
                                            <Menu onSelect={handleOption}> {props.categoryData.map((category, key) => {
                                                {
                                                    return !category.subCategories.length ?
                                                        <MenuItem value={category._id}>{category.title}</MenuItem>
                                                        : <SubMenu
                                                            title={category.title}
                                                            value={category._id}> {renderSubmenu(category)}</SubMenu>
                                                }
                                            })}</Menu> : null}
                                        {/*return <>{!category.subCategories.length ? <MenuItem>{category.title}</MenuItem>*/}
                                        {/*{category.subCategories.length ? renderSubmenu(category) :null}*/}
                                        {/*</>*/}
                                        {/*}) : null*/}
                                        {/*} </Menu> : null}*/}
                                        {/*<Menu>*/}
                                        {/*<MenuItem>1</MenuItem>*/}
                                        {/*<SubMenu title="2">*/}
                                        {/*<MenuItem>2-1</MenuItem>*/}
                                        {/*</SubMenu>*/}
                                        {/*</Menu>*/}

                                    </div>
                                    <div className="col-md-3 formsectionPadding">
                                        <div className="selctOtr amountFltr" onClick={openAmountFilter}>
                                            <div className="form-control">All Amounts</div>
                                        </div>
                                        {amountFilter ? <div className="amountFilter" style={{ display: 'block' }}>
                                            <div className="formSection outerBg">
                                                <button type="button" className="close" onClick={closeAmountFilter}>
                                                    <span aria-hidden="true" >×</span>
                                                </button>
                                                <div className="col-md-12 row m-0 p-0 fltrOtr">
                                                    <div className="col-md-6 formInp">
                                                        <label>Minimum</label>
                                                        <input
                                                            type="text"
                                                            name={"minAmount"}
                                                            className="form-control amountSymbol"
                                                            value={params.minAmount}
                                                            onChange={onChangeAmount}
                                                        /><span className="dollarSymbol">$</span>
                                                    </div>
                                                    <div className="col-md-6 formInp">
                                                        <label>Maximum</label>
                                                        <input
                                                            type="text"
                                                            name={"maxAmount"}
                                                            className="form-control amountSymbol"
                                                            value={params.maxAmount}
                                                            onChange={onChangeAmount}
                                                        /><span className="dollarSymbol">$</span>
                                                    </div>
                                                </div>
                                                <div className="btnOtr text-right">
                                                    {/*<button*/}
                                                    {/*type="button"*/}
                                                    {/*className="btn btn-primary btnClear"*/}
                                                    {/*onClick={clearAmountFilter}*/}
                                                    {/*>*/}
                                                    {/*Clear Filters*/}
                                                    {/*</button>*/}
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btnApply"
                                                        onClick={filterData}
                                                    >
                                                        Apply Filters
                                                    </button>
                                                </div>
                                            </div>
                                        </div> : null}
                                    </div>
                                    <div className="col-md-3 formsectionPadding">
                                        {/*<div className="selctOtr">*/}
                                        {/*<select className="form-control">*/}
                                        {/*<option>All Accounts</option>*/}
                                        {/*<option>option 1</option>*/}
                                        {/*</select>*/}
                                        {/*</div>*/}
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={Accounts}
                                            placeholder={"All Accounts"}
                                            loadOptions={promiseOptionsProperty}
                                            name="account"
                                            onChange={handleChangeAccount}
                                            value={params.accountDetails}
                                        />
                                    </div>

                                    <div className="col-md-1 formsectionPadding">
                                        <div className="selctOtr">
                                            {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                                            <select className="form-control" value={params.limit} name={"limit"}
                                                onChange={handleChangeLimit}>
                                                <option value={15}>15</option>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="account-btn col-md-3">
                    <button onClick={() => deleteMultiple()} className={"btnadd col-md-4"}>Delete</button>
                    <button onClick={() => openMerge()} className={"btnadd col-md-4"}>Merge</button>
                    <button className="btnadd col-md-4" onClick={clearAllFilter}>
                        Reset
                    </button>
                </div>


                {/*: <PlaidLinkButton*/}
                {/*clientName="React Plaid Setup"*/}
                {/*env="sandbox"*/}
                {/*product={["auth", "transactions"]}*/}
                {/*publicKey={'ae68f79fe745c7f843145765679f80'}*/}
                {/*onExit={handleOnExit}*/}
                {/*onSuccess={handleOnSuccess}*/}
                {/*className="btnadd plaidbtn"*/}
                {/*>*/}
                {/*<button className="btnadd">*/}
                {/*<i className="fas fa-plus"></i> Add Account*/}
                {/*</button>*/}
                {/*</PlaidLinkButton>}*/}

                {/*</div>*/}
            </div>
            <div className="innago-table table-main transactionTable pt-1">
                <div className="unSelect">{checkedArray.length ? <label className="un-slct">Selected {checkedArray.length} transaction Totaling
                                        {totalAmount ? <> ${numberWithCommas(totalAmount)}</> : "$ 0.00"}
                    <span onClick={unSelectAll}>UnselectAll <i class="fas fa-undo"></i></span>
                </label> : null}</div>
                < TransactionList
                    transactionData={props.transactionData}
                    setMenu={setMenu}
                    isMenu={isMenu}
                    categoryData={props.categoryData}
                    params={params}
                    setHandleCategory={setHandleCategory}
                    handleCategoryId={handleCategoryId}
                    setTextArea={setTextArea}
                    isTextArea={isTextArea}
                    handleKeyIndex={handleKeyIndex}
                    handleCheck={handleCheck}
                    handleCheckAll={handleCheckAll}
                    deleteMultiple={deleteMultiple}
                    checkedArray={checkedArray}
                    checkedAll={checkedAll}




                />
                <div className="paginationSec">
                    {props.transactionData && props.transactionData.totalTransactions ? <Pagination
                        totalRecords={props.transactionData.totalTransactions}
                        pageLimit={params.limit}
                        pageNeighbours={1}
                        currentPage={currentPage}
                        onPageChanged={onPageChanged}
                    /> : null}
                </div>
            </div>
        </>


    )
}

const mapStateToProps = state => {
    const { plaidReducer, profileReducer, commonReducer } = state;
    return {
        transactionData: plaidReducer.transactionData,
        dataSources: plaidReducer.dataSources,
        categoryData: plaidReducer.categoryData,
        profileDetail: profileReducer.profileDetail,
        commonReducer

    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(DataSource);

