import * as actionTypes from "./constants";
import * as Service from "./service";

//add PlaidRequest details
const postPlaidRequest =(value)  => {

    const request = data => ({
        type: actionTypes.ADD_PLAID_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_PLAID_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_PLAID_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.postPlaidRequest(value);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get data source details
const getDataSource =(params)  => {

    const request = data => ({
        type: actionTypes.GET_DATASOURCE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_DATASOURCE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_DATASOURCE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getDataSource(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//get transaction data
const getTransaction =(params)  => {

    const request = data => ({
        type: actionTypes.GET_TRANSACTION_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_TRANSACTION_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_TRANSACTION_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTransaction(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get category data
const getCategories =(params)  => {


    const request = data => ({
        type: actionTypes.GET_CATEGORY_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_CATEGORY_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_CATEGORY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getCategories(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//update Transaction
const updateTransaction =(value)  => {
    let transactionDetails = new FormData();
    if (value.files && value.files.length) {
        value.files.map((item, i)=>{
            transactionDetails.append("file", item )
        })
    }
    transactionDetails.append('data', JSON.stringify(value));
    const request = data => ({
        type: actionTypes.UPDATE_TRANSACTION_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.UPDATE_TRANSACTION_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.UPDATE_TRANSACTION_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateTransaction(transactionDetails);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//delete Transaction
const deleteTransaction =(params)  => {

    const request = data => ({
        type: actionTypes.DELETE_TRANSACTION_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_TRANSACTION_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_TRANSACTION_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteTransaction(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//delete Multiple Transaction
const deleteTransactionMultiple =(params)  => {

    const request = data => ({
        type: actionTypes.DELETE_TRANSACTION_MULTIPLE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_TRANSACTION_MULTIPLE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_TRANSACTION_MULTIPLE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteTransactionMultiple(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get property bank details
const getPropertyBankDetails =(params)  => {


    const request = data => ({
        type: actionTypes.GET_PROPERTYBANK_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_PROPERTYBANK_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_PROPERTYBANK_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPropertyBankDetails(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//update property bank details
const updateBankDetails =(params)  => {

    const request = data => ({
        type: actionTypes.UPDATE_PROPERTYBANK_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.UPDATE_PROPERTYBANK_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.UPDATE_PROPERTYBANK_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateBankDetails(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Import details
const importData =(params)  => {

    const request = data => ({
        type: actionTypes.UPDATE_IMPORT_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.UPDATE_IMPORT_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.UPDATE_IMPORT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.importData(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                await dispatch(success(responseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};




export default {
    postPlaidRequest,
    getDataSource,
    getTransaction,
    getCategories,
    updateTransaction,
    deleteTransaction,
    getPropertyBankDetails,
    updateBankDetails,
    importData,
    deleteTransactionMultiple

};
