import React from "react";
import AddTenants from "../components/AddTenants";
import Modal from '../../Common/components/Modal';
import action from "../action";
import { connect } from "react-redux";
import FilterTenant from '../components/FilterTenant.jsx';
import commonAction from '../../Common/action';
import Confirm from '../../Common/components/Confirm';
import TenantDetailLease from './TenantDetailLease';
import LoaderSpinner from "../../Common/components/Loader";
import usePrevious from "../../Common/components/prevProps";
// import { ToastContainer, toast } from "react-toastify";
import toastr from '../../Common/components/Toaster'
import { MAIN_ROUTE } from "../../../config/constants";
import ConfirmLease from './ConfirmLease';
import TemplateListing from './templateListModal';
import settingsAction from '../../Settings/action';
import MessageBox from "../../Common/components/MessageBox";
import LeaseEdit from './leaseEditModal';
import SignLease from "../../Leases&Files/components/signModal";


class Tenants extends React.Component {
    state = {
        isFilter: false,
        tenantName: '',
        propertyId: '',
        unitId: '',
        limit: 15,
        offset: 0,
        accountStatus: '',
        tenantStatus: '',
        tenantId: '',
        submitLease: false,
        viewLeaseAgreement: false,
        tenantModal: false,
        modal: false,
        invoiceTypeId: '',
        templateId: null,
        templateModal: false,
        message: null,
        isLeaseEdit: false,
        submitEdit: false,
        isEditView: false,
        isSign: false,
        leaseSign: null,
        leaseSignModal: false,
        addTenant: false
    }

    componentDidMount = async () => {
        await this.props.getProperty();
        await this.props.getAllUnits();
        await this.props.getInvoiceType()
        await this.props.getTemplates()
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { tenantReducer: { lease: prevLease, } } = prevProps;
        const { tenantReducer: { lease } } = this.props;
        const { sendLeaseLoad, addTenant, isSubmitted, submitLease } = this.state;
        if (sendLeaseLoad && !lease.success && prevLease != lease) {
            toastr.error(lease.message)
        } else if (sendLeaseLoad && lease.success && prevLease != lease) {
            toastr.success(lease.message);
        }
        if (addTenant && this.props.commonReducer.emailCheck && prevProps.commonReducer.emailCheck != this.props.commonReducer.emailCheck && !this.props.commonReducer.emailCheck.success) {
            toastr.error("Please add a notification email!")
        } else if (addTenant && this.props.commonReducer.emailCheck.success && prevProps.commonReducer.emailCheck != this.props.commonReducer.emailCheck) {
            this.setState({
                modal: false,
                submitLease: false,

            })
            const { settingsReducer: { templateDetails } } = this.props;
            if (templateDetails && templateDetails && templateDetails.length && templateDetails.length === 1) {
                this.setState({
                    templateData: templateDetails[0],
                    templateId: templateDetails[0]._id,
                    isSubmitted: true
                })
                this.props.editLeaseAgreement({ tenantId: this.props.id, templateId: templateDetails[0]._id });
                this.setState({
                    isSubmitted: false,
                    submitLease: true
                })

            }
            else {
                this.setState({
                    isSubmitted: false,
                    submitLease: false,
                    templateModal: true
                })

            }
        }
        if (isSubmitted && prevProps.tenantReducer.leaseEdit != this.props.tenantReducer.leaseEdit) {
            if (this.props.tenantReducer.leaseEdit.success) {

                this.setState({
                    isLeaseEdit: true
                })
            }
            else {
                toastr.error("Error while adding lease")
            }
        }

        if (submitLease && this.props.tenantReducer.lease && !this.props.tenantReducer.lease.success && prevProps.tenantReducer.lease != this.props.tenantReducer.lease) {
            toastr.error(this.props.tenantReducer.lease.message)
        } else if (submitLease && this.props.tenantReducer.lease && this.props.tenantReducer.lease.success && prevProps.tenantReducer.lease != this.props.tenantReducer.lease) {
            toastr.success(this.props.tenantReducer.lease.message);

            this.setState({
                isSign: true,
                leaseSign: this.props.tenantReducer.lease.lease
            })


        }
    }

    onOpenModal = async (id) => {
        // if (this.props.profileDetail.profileDetail && this.props.profileDetail.profileDetail.admin.gmail) {
        if (id) {
            await this.props.getTenantId(id);
            this.setState({
                tenantModal: true,
                viewLeaseAgreement: false
            })
        }
        else {
            await this.props.emailCheck({ emailType: "notification" })
            const { commonReducer: { emailCheck } } = this.props;
            if (emailCheck && emailCheck.success) {
                this.setState({
                    tenantModal: true,
                    viewLeaseAgreement: false
                })
            }
            else {
                toastr.error("Please add a notification email!")
            }
        }


        // } else {
        //     toastr.error('Please Add a google account to add property!!')
        // }


    }

    onCloseModal = async () => {
        this.setState({
            tenantModal: false,
            tenantId: ''
        })
        await this.props.getTenant(this.props.params)
    }

    renderModal = () => {
        const { tenantReducer: { tenantsById: { tenant: tenantDetail } } } = this.props;
        return <AddTenants
            onCancel={this.onCloseModal}
            leaseModal={this.leaseModal}
            editId={this.state.tenantId}
            tenantDetail={tenantDetail && tenantDetail}
            params={this.props.params}
        />
    }

    leaseModal = (id) => {
        const { settingsReducer: { templateDetails } } = this.props;
        if (templateDetails.length) {
            this.setState({
                tenantModal: false,
                modal: true,
                tenantId: id
            })
            if (templateDetails.length === 1) {
                this.setState({
                    tenantModal: false,
                    modal: true,
                    tenantId: id,
                    templateId: templateDetails[0]._id
                })
            }
            else {
                this.setState({
                    modal: true,
                })
            }

        }
        else {
            this.setState({
                message: 'Please add a template to send lease agreement!!'
            })
        }
    }

    closeModalTemplate = () => {

        this.setState({
            templateModal: false,
        })
    }

    renderTemplateList = () => {
        const { settingsReducer: { templateDetails } } = this.props;
        return <TemplateListing
            onCancel={this.closeModalTemplate}
            templateDetails={templateDetails}
            submitTemplate={this.submitTemplate}
            handleChangeTemplate={this.handleChangeTemplate}
            templateId={this.state.templateId}
        />
    };

    closeModal = async () => {
        this.setState({
            modal: false,
            tenantId: null,
            // isButtonDisabled: false
        })
        await this.props.getTenant(this.props.params)
    }

    submitTemplate = async () => {
        if (this.state.templateId) {
            const { templateId, tenantId } = this.state
            this.setState({
                submitEdit: true
            })
            await this.props.editLeaseAgreement({ tenantId: tenantId, templateId: templateId });
            // await this.props.getTenant(this.props.params);
            setTimeout(() => {
                this.setState({
                    modal: false,
                    // tenantId: null,
                    templateModal: false,
                    // templateId: null,
                    submitLease: false,
                    submitEdit: false
                })
            }, 100);
        }
        else {
            toastr.error("Please choose a template")
        }
    }

    renderModalLease = () => {
        return <ConfirmLease
            title='Lease Agreement'
            message='Do you want to send lease agreement?'
            close={this.closeModal}
            onConfirm={this.leaseAgreement}
            onView={this.viewAgreement}
            isButtonDisabled={true}

        />
    }

    handleChangeTemplate = (e, details) => {

        if (e.target.checked) {

            this.setState({
                templateId: details._id
            })
        }
        else {
            this.setState({
                templateId: null
            })
        }
    }

    leaseAgreement = async () => {
        // this.setState({
        //     viewLeaseAgreement: false,
        //     modal: false,
        //     submitLease: true,
        // });
        // await this.props.leaseAgreement({ tenantId: this.state.tenantId, templateId: this.state.templateId });
        // setTimeout(() => {
        //     this.setState({
        //         submitLease: false
        //     })
        // }, 1000);

        this.setState({
            addTenant: true,
            leaseModal: false
        })
        await this.props.emailCheck({ emailType: "notification" })
        this.setState({
            addTenant: false
        })
    }

    viewAgreement = async () => {
        this.setState({
            submitLease: true,
            modal: false
        });
        await this.props.viewLeaseAgreement({ tenantId: this.state.tenantId });
        setTimeout(() => {
            this.setState({
                submitLease: false,
                viewLeaseAgreement: true
            })
        }, 1000);
    }

    closeViewLeaseModal = () => {
        this.setState({
            viewLeaseAgreement: false,
            tenantId: ''
        })
    }

    renderViewLeaseModal = () => {
        return <TenantDetailLease
            tenantId={this.state.tenantId}
            onCancel={this.closeViewLeaseModal}
            // lease={props.tenantReducer.lease}
            lease={this.props.tenantReducer.viewLease}
            leaseAgreement={this.leaseAgreement}
            closeModal={this.onCloseModal}
            onOpenModal={this.onOpenModal}
        />
    }

    handleFilter = async () => {
        this.setState({
            isFilter: false
        })
    }

    handleChange = (e) => {
        this.setState({ tenantName: e.target.value });
    }

    onChangeProperty = async (e) => {
        this.setState({ propertyId: e });
        await this.props.getUnitsByPropertyId(e.value);
    }

    promiseOptionsProperty = async val => {
        await this.props.getProperty({ name: val })
        const { commonReducer: { Property } } = this.props;
        const Properties = Property.success && Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    applyFilter = async () => {
        const params = {
            name: (this.state.tenantName.trim()),
            property: this.state.propertyId.value,
            unit: this.state.unitId,
            limit: this.props.params.limit,
            offset: this.state.offset,
            invoiceTypeId: this.state.invoiceTypeId,
            isDeleted: false,
            isClosed: false
        }

        await this.props.filterTenant(params);
        const { tenantReducer: { tenants: { tenantList } } } = this.props;
        let id = '';
        id = tenantList && tenantList.length && tenantList[0]._id;
        this.props.onChangePerson(id);
        this.setState({
            isFilter: false
        });
    }

    clearFilter = async () => {
        this.setState({
            tenantName: '',
            propertyId: '',
            unitId: '',
            invoiceTypeId: ''
        });
        await this.props.getAllUnits();
        await this.props.clearFilter({
            limit: this.props.params.limit,
            offset: this.state.offset,
            accountStatus: '',
            tenantStatus: '',
            isDeleted: false,
            isClosed: false
        })
    }

    //  const account = accountStatus.join();


    onTenantStatusChange = (e) => {
        const { value, checked } = e.target;
        let statusArray = [];
        if (checked) {
            statusArray.push(value, ...this.state.tenantStatus);
        } else {
            statusArray = this.state.tenantStatus.filter((i) => i != value)
        }
        this.setState({
            tenantStatus: statusArray
        });
    }

    viewLease = async () => {
        const { tenantReducer: { tenantsPropertyById: { tenant } } } = this.props;
        if (tenant && tenant.agreementSent) {
            this.setState({
                submitLease: true
            });
            await this.props.viewAgreement({ tenantId: this.props.tenantId });
            const { tenantReducer } = this.props;
            if (tenantReducer && tenantReducer.viewLeasePath.lease) {
                window.open(MAIN_ROUTE + tenantReducer.viewLeasePath.lease, '_blank');
                this.setState({
                    submitLease: false
                });
            }
        } else {
            toastr.error("Please send the lease agreement")
        }
    }


    closeModalTemplate = () => {
        this.setState({ templateModal: false })
    }

    renderTemplateList = () => {
        const { settingsReducer: { templateDetails } } = this.props;
        return <TemplateListing
            onCancel={this.closeModalTemplate}
            templateDetails={templateDetails}
            submitTemplate={this.submitTemplate}
            handleChangeTemplate={this.handleChangeTemplate}
            templateId={this.state.templateId}

        />
    };

    renderModalLeaseEdit = () => {
        return <LeaseEdit
            templateData={this.props.tenantReducer.leaseEdit}
            onCancel={this.closeModalLeaseEdit}
            addEditedLeaseData={this.addEditedLeaseData}
            editId={this.props.tenantReducer.leaseEdit._id}
        />
    }

    closeModalLeaseEdit = () => {
        this.setState({
            isEditView: false,
            isButtonDisabled: false,
            isLeaseEdit: false
        })
    }

    submitTemplate = async () => {
        const { tenantId, templateId } = this.state
        if (templateId) {
            this.setState({
                modal: false,
                templateModal: false,
                submitLease: false,
                isSubmitted: true
            })
            await this.props.editLeaseAgreement({ tenantId: tenantId, templateId: templateId });
            this.setState({
                isSubmitted: false
            })
            await this.props.onChangePerson(this.props.id);
            setTimeout(() => {
                this.setState({
                    isSubmitted: false,
                    isButtonDisabled: false
                })
                // setTemplateId(null)
            }, 100);
        }
        else {
            toastr.error("Please choose a template")
        }
    }

    addEditedLeaseData = async (data) => {
        const { templateId, tenantId } = this.state

        this.setState({
            submitLease: true,
            isLeaseEdit: false,
            isEditView: false
        })
        await this.props.sendLeaseAgreement(data, { templateId, tenantId })
        await this.props.getTenant(this.props.params);
        this.setState({
            submitLease: false,
            isLeaseEdit: false
        })
    }




    signAgreement = (lease) => {
        // if (!lease.isOwnerSigned) {
        //     setIsSign(true)
        //     setLeaseSign(lease)
        // }
        // else {
        //     setMessage('Document is already signed!!')
        // }
        this.setState({
            isSign: true,
            leaseSign: lease
        })

    }

    renderSignConfirm = () => {
        return <Confirm
            title='Sign Lease'
            message='Do you want to sign now?'
            onCancel={this.closeSignConfirm}
            onConfirm={this.signLease}
        />
    }

    closeSignConfirm = () => {
        this.setState({
            isSign: false
        })
    }

    signLease = () => {
        this.setState({
            isSign: false,
            leaseSignModal: true
        })
    }

    closeSignLease = async () => {
        this.setState({

            leaseSignModal: false
        })
        await this.props.getTenant(this.props.params);

    }

    renderSignLease = () => {
        const { leaseSign } = this.state
        return <SignLease
            onCancel={this.closeSignLease}
            lease={leaseSign.documentData}
            leaseId={leaseSign._id}
        />
    }



    render() {
        //const array = this.state.tenantStatus.join();

        const { commonReducer: { Property, unitDetails, InvoiceTypeList }, role } = this.props;
        const { templateModal } = this.state

        const Properties = Property.success && Object.values(Property.propertyList).map(e => ({
            value: e._id,
            label: e.propertyName
        }));

        const Units = unitDetails && unitDetails.unitList && unitDetails.unitList.map(e => ({
            value: e._id,
            label: e.unitName
        }));
        const { message, isEditView, leaseSignModal, isSign, isLeaseEdit } = this.state;
        const { isButtonActive, generateInvoice, isGenerateVisible } = this.props;

        return (
            <div className="innago-table-top">

                <div className="lft">
                    <h1>{this.props.tenantDetail ? "TENANT DETAIL" : "TENANTS"}</h1>
                </div>
                {message ? <Modal body={<MessageBox
                    onCancel={() => this.setState({ message: null })}
                    message={message}
                    title={<i style={{ 'color': 'indianred', 'fontSize': '30px' }}
                        className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
                />
                }
                    onCancel={() => this.setState({ message: null })}
                /> : null}
                {isEditView || isLeaseEdit ? <Modal body={this.renderModalLeaseEdit()} onCancel={() => this.setState({ isEditView: false })} /> : null}

                {this.state.submitLease && <LoaderSpinner />}

                {leaseSignModal ? <Modal body={this.renderSignLease()} onCancel={this.closeSignLease} /> : null}
                {isSign ? <Modal body={this.renderSignConfirm()} onCancel={this.closeSignConfirm} /> : null}

                <div className="rgt">
                    {isGenerateVisible ? <button
                        className="add ml-3"
                        disabled={!isButtonActive}
                        onClick={() => this.props.generateInvoice()}
                    >
                        Generate Invoice
                    </button> : null}
                    {!this.props.tenantDetail ?
                        <button className="btn-dvd blu-bg">
                            Total Tenant
                         <strong>
                                {this.props.totalCount ? this.props.totalCount : 0}
                            </strong>
                        </button>
                        : null
                    }
                    {this.state.tenantModal ? <Modal body={this.renderModal()} onCancel={this.onCloseModal} /> : null}
                    {this.state.modal ? <Modal body={this.renderModalLease()} onCancel={this.closeModal} /> : null}

                    {this.state.viewLeaseAgreement ?
                        <Modal body={this.renderViewLeaseModal()} onCancel={this.closeViewLeaseModal} /> : null}
                    {templateModal ? <Modal body={this.renderTemplateList()} onCancel={this.closeViewLeaseModal} /> : null}


                    <button className="export tenantViewBut" id="viewAllTenant"><i className="far fa-eye"></i>View All
                        Tenants
                    </button>

                    {!this.props.tenantDetail ? <button className="fltr fltrList" onClick={() => this.setState({ isFilter: !this.state.isFilter })}>
                        <i className="fas fa-sliders-h"></i>Filter
                    </button> : null}
                    {role == "maintenance" ? null : <button className="add ml-3"
                        onClick={() => {
                            this.onOpenModal();
                            this.setState({ isFilter: false })
                        }}>
                        <svg>
                            <use xlinkHref="/images/svg-sprite.svg#ic-add" />
                        </svg>
                        Add New Tenant
                    </button>}

                    {this.props.isHomeView ? <div className="formsectionPadding selTenant">
                        <div className="selctOtr">
                            {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                            <select className="form-control" value={this.props.params.limit} name={"limit"}
                                onChange={this.props.handleChangeLimit}>
                                <option value={15}>15</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div> : null}

                    {this.state.isFilter ?
                        <FilterTenant
                            handleFilter={this.handleFilter}
                            onTenantStatusChange={this.onTenantStatusChange}
                            clearFilter={this.clearFilter}
                            applyFilter={this.applyFilter}
                            onChange={this.onChange}
                            Properties={Properties}
                            Units={Units}
                            promiseOptionsProperty={this.promiseOptionsProperty}
                            onChangeProperty={this.onChangeProperty}
                            handleChange={this.handleChange}
                            tenantName={this.state.tenantName}
                            propertyId={this.state.propertyId}
                            unitId={this.state.unitId}
                            InvoiceTypeList={InvoiceTypeList}
                            invoiceTypeId={this.state.invoiceTypeId}
                        /> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer, profileReducer, settingsReducer } = state;
    return {
        tenantReducer,
        commonReducer,
        profileDetail: profileReducer.profileDetail,
        settingsReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...settingsAction
})(Tenants);
