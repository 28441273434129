import * as actions from "./constant";

const initialState = {
    maintenances: {},
    maintenanceList:{},
    viewMaintenance: {},
    categoryMaintenance: {category: []},
    maintenanceAdd: {}
};

export default function maintenanceReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_MAINTENANCE_REQUEST:
            return {
                ...state
            };
        case actions.GET_MAINTENANCE_SUCCESS:
            return {
                ...state,
                maintenanceList: action.response.data
            };
        case actions.GET_MAINTENANCE_FAILURE:
            return {
                ...state,
                maintenanceList: {success: false, ...action.error}
            };
        case actions.ADD_MAINTENANCE_REQUEST:
            return {
                ...state
            };
        case actions.ADD_MAINTENANCE_SUCCESS:

            return {
                ...state,
                maintenances: {success:true, ...action.response}
            };
        case actions.ADD_MAINTENANCE_FAILURE:
            return {
                ...state,
                maintenances: {success: false, ...action.error}
            };
        case actions.DELETE_MAINTENANCE_REQUEST:
            return {
                ...state
            };
        case actions.DELETE_MAINTENANCE_SUCCESS:
            return {
                ...state,
                maintenances: {success:true,...action.data}
            };
        case actions.DELETE_MAINTENANCE_FAILURE:
            return {
                ...state,
                maintenances: {success: false, ...action.error}
            };
        case actions.VIEW_MAINTENANCE_REQUEST:
            return {
                ...state
            };
        case actions.VIEW_MAINTENANCE_SUCCESS:

            return {
                ...state,
                viewMaintenance: {...action.data}
            };
        case actions.VIEW_MAINTENANCE_FAILURE:
            return {
                ...state,
                viewMaintenance: {success: false, ...action.error}
            };

        case actions.GET_MAINTENANCE_CATEGORY_REQUEST:
                return {
                    ...state,
                    categoryMaintenance: {loading: true}
                };
        case actions.GET_MAINTENANCE_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryMaintenance: {success: true, category: action.data.maintenanceCategories, loading: false}
            };
        case actions.GET_MAINTENANCE_CATEGORY_FAILURE:
            return {
                ...state,
                categoryMaintenance: {success: false, ...action.error}
            };

        case actions.MAINTENANCE_ADD_REQUEST:
                return {
                    ...state
                    
                };

        case actions.MAINTENANCE_ADD_SUCCESS:
            return {
                ...state,
                maintenanceAdd: {loading: false, success: action.response.success, message: action.response.message}
            };

        case actions.MAINTENANCE_ADD_FAILURE:
            return {
                ...state,
                maintenanceAdd: {success: false, ...action.error}
            };

        case actions.GET_TENANT_MAINTENANCE_REQUEST:
            return {
                ...state,
                maintenanceTenant: {loading: true}
            };

        case actions.GET_TENANT_MAINTENANCE_SUCCESS:
            return {
                ...state,
                maintenanceTenant: {loading: false, success: action.response.success, maintenance: action.response.data}
            }

        case actions.GET_TENANT_MAINTENANCE_FAILURE:
            return {
                ...state,
                maintenanceTenant: {success: false, ...action.error}
            };

        case actions.GET_MAINTENANCE_DETAILS_REQUEST:
            return {
                ...state,
                maintenanceDetailsById: {loading: true}
            };

        case actions.GET_MAINTENANCE_DETAILS_SUCCESS:
            return {
                ...state,
                maintenanceDetailsById: {loading: false, success: action.response.success,
                     maintenanceTickets: action.response.data.maintenanceTickets,
                     invoice:action.response.data.invoice}
            }

        case actions.GET_MAINTENANCE_DETAILS_FAILURE:
            return {
                ...state,
                maintenanceDetailsById: {success: false, ...action.error}
            };

        case actions.EDIT_NOTE_REQUEST:
            return {
                ...state,
                editNote: {loading: true}
            };

        case actions.EDIT_NOTE_SUCCESS:
            return {
                ...state,
                editNote: {loading: false, maintenanceTickets: action.response}
            }

        case actions.EDIT_NOTE_FAILURE:
            return {
                ...state,
                editNote: {success: false, ...action.error}
            };



        default:
            return state;
    }
}

