import React, { useState, useEffect } from "react";
import action from "../../Login/action";
import { connect } from "react-redux";
import toastr from '../../Common/components/Toaster'
import "react-toastify/dist/ReactToastify.css";
// import LoaderSpinner from "./Loader";
import usePrevious from "./prevProps";
import * as validation from "./validation";

const Password = props => {

    let params = new URLSearchParams(window.location.search);
    let key = params.get("key");



    const [password, setPassword] = useState({ password: null, confirmPassword: null })
    const [isSubmitted, setIsSubmitted] = useState(false)

    const prevPasssword = usePrevious(props.setPassword);

    useEffect(() => {
        props.validateToken({ token: key })
    }, [])

    useEffect(() => {
        if (isSubmitted && prevPasssword !== props.setPassword && !props.setPassword.success) {
            toastr.error(props.setPassword.message)

        } else if (isSubmitted && prevPasssword !== props.setPassword && props.setPassword.success) {
            toastr.success(props.setPassword.message);
            let email = params.get("email");
            let loginData = {
                email,
                password: password.password
            }
            setTimeout(() => props.signIn(loginData), 300);

        }
    }, [prevPasssword, props.setPassword]);


    const handleResetPassword = async () => {
        setIsSubmitted(true)
        let regex = /^(?=.*?[A-Z]).{5,}$/
        let email = params.get("email");
        password.token = key
        setIsSubmitted(true)
        if (password.password && password.confirmPassword && (password.password === password.confirmPassword) && validation.UNMATCHED_PASSWORDS(password.confirmPassword, password.password)
            && regex.test(password.password)) {
            await props.passwordSet(password, email)

        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPassword({
            ...password,
            [name]: value
        })
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        validation.NUMBER_ONLY(value, name) != true ? <div className={"errorMsg"}>
                            {validation.NUMBER_ONLY(value, name)}
                        </div> : null
                    );

                case "password match":
                    return (
                        validation.UNMATCHED_PASSWORDS(value, name) != true ? <div className={"errorMsg"}>
                            {validation.UNMATCHED_PASSWORDS(value, name)}
                        </div> : null
                    );
                case "password":
                    return (
                        validation.PASSWORD(value, name) != true ? <div className={"errorMsg"}>
                            {validation.PASSWORD(value, name)}
                        </div> : null
                    );


            }
        } else return null;
    };

    return (
        <div className="login-outer">
            {/* <ToastContainer autoClose={2500}/> */}
            {/*{isSubmitted && <LoaderSpinner />}*/}
            {props.tokenValidity.success ? <div className="container custom-container">
                <div className="row">
                    <div className="d-flex w-100 loginSec">
                        <div className="col-lg-6 col-md-6 loginBg p-0">
                            <div className="opac">
                                <div className="col-md-12 logoSec">
                                    <img src="/images/logo-white.png" />
                                </div>
                                <div className="contentLog">
                                    <h3>Welcome</h3>
                                    <p className="dtlLog">
                                        This property management portal will allow you to manage your tenant profile.
                                            Login now to access your lease,
                                            pay your rent, submit maintenance requests, and much more!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 loginDetail formSection">
                            <div className="loginSecDtl">
                                {/*{isSubmitted ?props.setPassword &&  !props.setPassword.success ?*/}
                                {/*<div className="alert alert-danger col-md-12">*/}
                                {/*{props.setPassword ? props.setPassword.message :null}*/}
                                {/*</div> : <div className="alert alert-success col-md-12">*/}
                                {/*{props.setPassword ? props.setPassword.message :null}*/}
                                {/*<a href={"/"}> Click here to login..</a>*/}
                                {/*</div> : null}*/}
                                <div className="titleLog">
                                    <h3>SET PASSWORD </h3>
                                </div>
                                {/*<form onSubmit={(e) => handleResetPassword(e)}>*/}
                                <div className="col-md-12 formInp">
                                    <label> Password </label>

                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Enter Password"
                                        name={"password"}
                                        value={password.password}
                                        onChange={handleChange}

                                    />
                                    {validate(password.password, "New Password", "required")}
                                    {password.password && validate(password.password, 'password', "password")}
                                </div>
                                <div className="col-md-12 formInp">
                                    <label>Confirm Password </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        name={"confirmPassword"}
                                        value={password.confirmPassword}
                                        onChange={handleChange}

                                    />
                                    {validate(password.confirmPassword, "Confirm Password", "required")}
                                    {password.confirmPassword && password.password &&
                                        validation.UNMATCHED_PASSWORDS(password.confirmPassword, password.password) != true ? <div className={"errorMsg"}>
                                            {validation.UNMATCHED_PASSWORDS(password.confirmPassword, password.password)}
                                        </div> : null
                                    }
                                </div>
                                <div className="col-md-12 btnOtr">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btnSubmit"
                                        onClick={handleResetPassword}
                                    >
                                        Submit
                                    </button>
                                </div>

                                {/*</form>*/}
                            </div>
                        </div>
                        <footer>
                            By using the project name , you agree to our Privacy Policy and
                            Terms and Conditions
                        </footer>
                    </div>
                </div>
            </div> : <div>{props.tokenValidity.message}</div>}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        tokenValidity: state.loginReducer.tokenValidity,
        setPassword: state.loginReducer.setPassword,
        resetPassword: state.loginReducer.resetPassword


    };
};

export default connect(mapStateToProps, {
    ...action
})(Password);
