export const SET_PROFILE = 'SET-PROFILE ';
//add tenant
export const ADD_LEASE_REQUEST = 'ADD_LEASE_REQUEST ';
export const ADD_LEASE_SUCCESS = 'ADD_LEASE_SUCCESS ';
export const ADD_LEASE_FAILURE = 'ADD_LEASE_FAILURE ';

//get tenant
export const GET_LEASE_REQUEST = 'GET_LEASE_REQUEST ';
export const GET_LEASE_SUCCESS = 'GET_LEASE_SUCCESS ';
export const GET_LEASE_FAILURE = 'GET_LEASE_FAILURE ';

//view agreement
export const VIEW_LEASE_DETAIL_REQUEST = 'VIEW_LEASE_DETAIL_REQUEST ';
export const VIEW_LEASE_DETAIL_SUCCESS = 'VIEW_LEASE_DETAIL_SUCCESS ';
export const VIEW_LEASE_DETAIL_FAILURE = 'VIEW_LEASE_DETAIL_FAILURE ';

//update agreement
export const UPDATE_LEASE_REQUEST = 'UPDATE_LEASE_REQUEST ';
export const UPDATE_LEASE_SUCCESS = 'UPDATE_LEASE_SUCCESS ';
export const UPDATE_LEASE_FAILURE = 'UPDATE_LEASE_FAILURE ';

//delete lease
export const DELETE_LEASE_REQUEST = 'DELETE_LEASE_REQUEST ';
export const DELETE_LEASE_SUCCESS = 'DELETE_LEASE_SUCCESS ';
export const DELETE_LEASE_FAILURE = 'DELETE_LEASE_FAILURE ';

