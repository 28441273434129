import React, { useState, useEffect } from "react";
import action from "../../action";
import commonAction from '../../../Common/action';
import tenantAction from '../../../Tenants/action';
import { connect } from "react-redux";

import toastr from '../../../Common/components/Toaster'
import AddMaintenanceForm1 from './addMaintenanceform1';
import AddMaintenanceForm3 from './addMaintenanceForm3';
import SideFilterMaintenance from './sideFilterMaintenance'
import AddMaintenanceForm4 from './addMaintenanceForm4';
import usePrevious from "../../../Common/components/prevProps";
import LoaderSpinner from "../../../Common/components/Loader";
import moment from 'moment';
import * as validation from '../../../Common/components/validation';
import isImage from "is-image";
import AdvancedSearch from './advancedSearch';
import history from "../../../../config/history";

const AddMaintenance = (props) => {
    const [id, setId] = useState('');
    const [cat1, setCat1] = useState('');
    const [cat2, setCat2] = useState('');
    const [cat3, setCat3] = useState('');
    const [cat4, setCat4] = useState('');
    const [videoError, setVideoError] = useState('')
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [level, setLevel] = useState(5);
    const [properties, setProperties] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [units, setUnits] = useState([]);
    const [property, setProperty] = useState({});
    const [tenant, setTenant] = useState({});
    const [maintenance, setMaintenance] = useState({
        propertyId: '',
        unitId: '',
        priority: 'Normal',
        availableDate: '',
        availableTime: '',
        authorizationToEnter: false,
        petInResidence: false,
        maintenanceCategoryId: '',
        title: '',
        description: '',
        maintenancePicture: [],
        maintenanceVideo: '',
        requestById: '',
        requestInitiatedDate: '',
        requestDueDate: '',
        assignee: '',
        originalEstimateHr: '',
        originalEstimateMin: '',
        labourTime: '',
        labourTimeHr: '',
        labourTimeMin: '',
        anyDateIsFine: false,
        tenantView: false,
        contractorName: null,
        propertyName: null
    })
    const [params, setParams] = useState({
        limit: 15,
        offset: 0,
        level: 1
    })
    const [isEdit, setIsEdit] = useState(false)

    const {
        match: {
            params: { maintenaceId }
        }
    } = props;

    useEffect(() => {
        window.scroll(0, 0)


        async function renderCall() {
            let url_string = window.location.href
            let url = new URL(url_string);
            let tenanatId = url.searchParams.get("tenanatId");
            if (tenanatId) {
                await props.getTenantId(tenanatId)
            }
            if (maintenaceId) {
                props.getMaintenanceById(maintenaceId)
                setIsEdit(true)
                setLevel(5)
            } else {
                await props.getTenant({ isDeleted: false, isClosed: false })
            }
            await props.getCategoryList(params);
            await props.getProperty();
        }


        setMaintenance({
            _id: '',
            propertyId: '',
            unitId: '',
            priority: 'Normal',
            availableDate: '',
            availableTime: '',
            authorizationToEnter: false,
            petInResidence: false,
            maintenanceCategoryId: '',
            title: '',
            description: '',
            maintenancePicture: [],
            maintenanceVideo: '',
            requestById: '',
            requestInitiatedDate: '',
            requestDueDate: '',
            assignee: '',
            originalEstimateHr: '',
            originalEstimateMin: '',
            labourTime: '',
            labourTimeHr: '',
            labourTimeMin: '',
            anyDateIsFine: false,
            property: null,
            startedWorkdate: null,
            isChangedImage: false,
            isChangedVideo: false,
            tenantView: false

        })
        setTenant({})
        setProperties({})

        renderCall();
    }, []);



    useEffect(() => {
        window.scroll(0, 0)
        async function renderCall() {
            let url_string = window.location.href
            let url = new URL(url_string);
            let tenanatId = url.searchParams.get("tenanatId");
            if (tenanatId) {
                await props.getTenantId(tenanatId)
            }
            if (maintenaceId) {
                props.getMaintenanceById(maintenaceId)
                setIsEdit(true)
                setLevel(5)
            } else {
                await props.getTenant({ isDeleted: false, isClosed: false })
            }
            await props.getCategoryList(params);
            await props.getProperty();
        }


        setMaintenance({
            _id: '',
            propertyId: '',
            unitId: '',
            priority: 'Normal',
            availableDate: '',
            availableTime: '',
            authorizationToEnter: false,
            petInResidence: false,
            maintenanceCategoryId: '',
            title: '',
            description: '',
            maintenancePicture: [],
            maintenanceVideo: '',
            requestById: '',
            requestInitiatedDate: '',
            requestDueDate: '',
            assignee: '',
            originalEstimateHr: '',
            originalEstimateMin: '',
            labourTime: '',
            labourTimeHr: '',
            labourTimeMin: '',
            anyDateIsFine: false,
            property: null,
            startedWorkdate: null,
            isChangedImage: false,
            isChangedVideo: false,
            tenantView: false

        })
        setTenant({})
        setProperties({})

        renderCall();
    }, [maintenaceId]);

    const prevTenant = usePrevious(props.tenantsById);

    useEffect(() => {
        const { tenant } = props.tenantsById
        let url_string = window.location.href
        let url = new URL(url_string);
        let tenanatId = url.searchParams.get("tenanatId");
        if (tenanatId && prevTenant != tenant) {

            if (tenant && tenant.tenant) {
                props.getUnitsByPropertyId(tenant.tenant.propertyId._id);
                setMaintenance({
                    tenantView: true,
                    unitId: tenant.tenant.unitId,
                    propertyId: tenant.tenant.propertyId._id,
                    requestById: tenant.tenant._id,
                    priority: 'Normal',
                    availableDate: '',
                    availableTime: '',
                    authorizationToEnter: false,
                    petInResidence: false,
                    maintenanceCategoryId: '',
                    title: tenant.tenant.propertyId.propertyName,
                    description: '',
                    maintenancePicture: [],
                    maintenanceVideo: '',
                    requestInitiatedDate: '',
                    requestDueDate: '',
                    assignee: '',
                    originalEstimateHr: '',
                    originalEstimateMin: '',
                    labourTime: '',
                    labourTimeHr: '',
                    labourTimeMin: '',
                    anyDateIsFine: false,
                    property: null,
                    startedWorkdate: null,
                    isChangedImage: false,
                    isChangedVideo: false,
                    contractorName: ""

                })

                setTenant({
                    value: tenant.tenant._id,
                    label: tenant.tenant.firstName + " " + tenant.tenant.lastName
                })
                setProperty({
                    value: tenant.tenant.propertyId._id,
                    label: tenant.tenant.propertyId.propertyName
                })
            }

        } else {
            // props.getUnitsByPropertyId()
            setMaintenance({
                propertyId: '',
                unitId: '',
                priority: 'Normal',
                availableDate: '',
                availableTime: '',
                authorizationToEnter: false,
                petInResidence: false,
                maintenanceCategoryId: '',
                title: '',
                description: '',
                maintenancePicture: [],
                maintenanceVideo: '',
                requestById: '',
                requestInitiatedDate: '',
                requestDueDate: '',
                assignee: '',
                originalEstimateHr: '',
                originalEstimateMin: '',
                labourTime: '',
                labourTimeHr: '',
                labourTimeMin: '',
                anyDateIsFine: false,
                property: null,
                startedWorkdate: null,
                isChangedImage: false,
                isChangedVideo: false,
                tenantView: false,
                contractorName: ""


            })
            setTenant({})
            setProperties({})
        }


    }, [prevTenant, props.tenantsById])

    useEffect(() => {
        setMaintenance({
            ...maintenance,
            description: cat1 + " " + cat2 + " " + cat3 + " " + cat4,
        })
    }, [cat4])

    useEffect(() => {
        if (props.add.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
        if (isSubmitted) {
            if (props.add.success) {
                toastr.success(props.add.message)
            } else {
                toastr.error(props.add.message)
            }
        }
    }, [props.add])

    // useEffect(() => {
    //     if (props.categoryMaintenance.loading) {
    //         setLoading(true);
    //     } else {
    //         setLoading(false);
    //     }
    // }, [props.categoryMaintenance])

    useEffect(() => {
        //Set property list
        const { commonReducer: { Property } } = props;
        const Properties = Property.propertyList && Object.values(Property.propertyList).map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        setProperties(Properties);
    }, [props.commonReducer.Property])

    useEffect(() => {
        //Set unit list
        const { commonReducer: { unitDetails } } = props;
        let Units = []
        unitDetails && unitDetails.unitList && unitDetails.unitList.map(e => {
            Units.push({
                value: e._id,
                label: e.unitName
            })
        })
        if (Units.length)
            setMaintenance({
                ...maintenance,
                unitId: Units[0].value
            });
        setUnits(Units)
    }, [props.commonReducer.unitDetails])

    useEffect(() => {
        //Set tenant list
        let url_string = window.location.href
        let url = new URL(url_string);
        let tenanatId = url.searchParams.get("tenanatId");
        if (!tenanatId) {
            const { tenantReducer: { tenants } } = props;
            const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
                value: e._id,
                label: e.firstName + " " + e.lastName
            }));
            setTenants(tenantList);

            if (tenants.tenantList && tenants.tenantList.length) {
                let tenantUnit = tenants.tenantList.find(t => t.unitId._id = maintenance.unitId)
                setTenant({
                    value: tenantUnit && tenantUnit._id,
                    label: tenantUnit && tenantUnit.firstName + " " + tenantUnit.lastName
                })

                setMaintenance({
                    ...maintenance,
                    requestById: tenants.tenantList[0]._id,
                })


            }
            else {
                setTenant({})
                setMaintenance({
                    ...maintenance,
                    requestById: null
                })
            }
        }
    }, [props.tenantReducer.tenants])



    const prevMaintenance = usePrevious(props.maintenances);


    const prevEditMaintenance = usePrevious(props.maintenanceDetails)

    useEffect(() => {
        if (isSubmitted && prevMaintenance !== props.maintenances && !props.maintenances.success) {
            toastr.error(props.maintenances.message)
        } else if (isSubmitted && prevMaintenance !== props.maintenances && props.maintenances.success) {
            toastr.success(props.maintenances.message)
        }
    }, [prevMaintenance, props.maintenances]);

    useEffect(() => {
        if (isSubmitted && prevEditMaintenance !== props.maintenanceDetails && !props.maintenanceDetails.success) {
            toastr.error(props.maintenances.message)
        } else if (maintenaceId && prevEditMaintenance !== props.maintenanceDetails && props.maintenanceDetails.success) {
            props.maintenanceDetails.maintenanceTickets.unitId && props.getTenant({ unit: props.maintenanceDetails.maintenanceTickets.unitId._id, isDeleted: false, isClosed: false })
            props.maintenanceDetails.maintenanceTickets.propertyId && props.getUnitsByPropertyId(props.maintenanceDetails.maintenanceTickets.propertyId._id);
            setTenant({
                value: props.maintenanceDetails.maintenanceTickets.requestById ? props.maintenanceDetails.maintenanceTickets.requestById._id : null,
                label: props.maintenanceDetails.maintenanceTickets.requestById ? props.maintenanceDetails.maintenanceTickets.requestById.firstName : null
            })
            setProperty({
                value: props.maintenanceDetails.maintenanceTickets.propertyId ? props.maintenanceDetails.maintenanceTickets.propertyId._id : null,
                label: props.maintenanceDetails.maintenanceTickets.propertyId ? props.maintenanceDetails.maintenanceTickets.propertyId.propertyName : null
            })
            setMaintenance({
                ...maintenance,
                _id: props.maintenanceDetails.maintenanceTickets._id,
                propertyId: props.maintenanceDetails.maintenanceTickets.propertyId ? props.maintenanceDetails.maintenanceTickets.propertyId._id : null,
                unitId: props.maintenanceDetails.maintenanceTickets.unitId ? props.maintenanceDetails.maintenanceTickets.unitId._id : null,
                priority: props.maintenanceDetails.maintenanceTickets.priority,
                availableDate: props.maintenanceDetails.maintenanceTickets.availableDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.availableDate).format('MM/DD/YYYY') : '',
                availableTime: props.maintenanceDetails.maintenanceTickets.availableTime,
                authorizationToEnter: props.maintenanceDetails.maintenanceTickets.authorizationToEnter,
                petInResidence: props.maintenanceDetails.maintenanceTickets.petInResidence,
                // maintenanceCategoryId: props.maintenanceDetails.maintenanceTickets.maintenanceCategoryId._id,
                title: props.maintenanceDetails.maintenanceTickets.title,
                description: props.maintenanceDetails.maintenanceTickets.description,
                maintenancePicture: props.maintenanceDetails.maintenanceTickets.maintenancePicture,
                maintenanceVideo: props.maintenanceDetails.maintenanceTickets.maintenanceVideo,
                requestById: props.maintenanceDetails.maintenanceTickets.requestById ? props.maintenanceDetails.maintenanceTickets.requestById._id : null,
                requestInitiatedDate: props.maintenanceDetails.maintenanceTickets.requestInitiatedDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.requestInitiatedDate).format('MM/DD/YYYY') : null,
                requestDueDate: props.maintenanceDetails.maintenanceTickets.requestDueDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.requestDueDate).format('MM/DD/YYYY') : null,
                assignee: props.maintenanceDetails.maintenanceTickets.assignee,
                originalEstimateHr: props.maintenanceDetails.maintenanceTickets.originalEstimateHr
                ,
                // ? moment( props.maintenanceDetails.maintenanceTickets.originalEstimateTime).format('hh'):null,
                originalEstimateMin: props.maintenanceDetails.maintenanceTickets.originalEstimateMin,
                // ? moment(props.maintenanceDetails.maintenanceTickets.originalEstimateTime).format('mm '):null,
                labourTime: props.maintenanceDetails.maintenanceTickets.labourTime,
                // ? moment( props.maintenanceDetails.maintenanceTickets.labourTime).format('hh:mm A'):null,
                labourTimeHr: props.maintenanceDetails.maintenanceTickets.labourTimeHr,
                // ? moment( props.maintenanceDetails.maintenanceTickets.laborTime).format('hh'):null,
                labourTimeMin: props.maintenanceDetails.maintenanceTickets.labourTimeMin,
                // ? moment( props.maintenanceDetails.maintenanceTickets.laborTime).format('mm'):null,
                anyDateIsFine: props.maintenanceDetails.maintenanceTickets.anyDateIsFine,
                startedWorkdate: props.maintenanceDetails.maintenanceTickets.startedWorkdate ?
                    moment(props.maintenanceDetails.maintenanceTickets.startedWorkdate).format('MM/DD/YYYY') : null,
                completedWorkDate: props.maintenanceDetails.maintenanceTickets.completedWorkDate ?
                    moment(props.maintenanceDetails.maintenanceTickets.completedWorkDate).format('MM/DD/YYYY') : null,
                tenantView: false,
                contractorName: props.maintenanceDetails.maintenanceTickets.contractorName
            })
        }
    }, [prevEditMaintenance, props.maintenanceDetails]);


    const promiseOptionsProperty = async val => {
        await props.getProperty({ name: val })
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const promiseOptionsTenant = async val => {
        const { tenantReducer: { tenants } } = props;
        await props.getTenant({ name: val, isDeleted: false, isClosed: false })
        const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));
        return tenantList.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeTenant = async (value) => {
        let tempParams = params
        tempParams.requestById = value.value;
        const { tenantReducer: { tenants } } = props;

        let tenantDetails = tenants.tenantList && tenants.tenantList.find(t => t._id == value.value)

        
        setMaintenance({
            ...maintenance,
            requestById: value.value,
            propertyId: tenantDetails.propertyId._id,
            unitId: tenantDetails.unitId._id,
            title: tenantDetails.propertyId.propertyName
        })
        setProperty({
            value: tenantDetails.propertyId._id,
            label: tenantDetails.propertyId.propertyName
        })
        setTenant(value)
        await props.getProperty({ tenantId: value.value })
        await props.getUnitsByPropertyId(tenantDetails.propertyId._id);
    }

    const changePriority = (value) => {
        setMaintenance({
            ...maintenance,
            priority: value
        })
    }

    const handleAddImage = e => {
        const { name, files } = e.target;
        if (files.length) {
            if (isImage(files[0].name)) {
                setMaintenance({
                    ...maintenance,
                    maintenancePicture: files[0],
                    isChangedImage: true

                })
                setVideoError(null)
            } else {
                let ext = files[0].name.split('.').pop();
                const acceptableExt = ["ttf", "avi", "flv", "wmv", "mov", "mp4"];
                if (acceptableExt.includes(ext))
                    if (files[0].size < 25000000) {
                        setMaintenance({
                            ...maintenance,
                            maintenanceVideo: files[0],
                            isChangedVideo: true
                        })
                        setVideoError(null)
                    } else {
                        setVideoError('*Select video of size less than 25 mb')
                    }
            }
        }
        // Object.values(e.target.files).map((img, i) => {
        //     if (isImage(img.name)) {
        //         setMaintenance({
        //             ...maintenance,
        //             maintenancePicture: [...maintenance.maintenancePicture, ...e.target.files]
        //         })
        //     }
        // })
    };
    const onChangeAvailableDate = (e, name) => {
        setMaintenance({
            ...maintenance,
            availableDate: moment(new Date(e)).endOf('day').toISOString(),
            anyDateIsFine: false
        })
    }

    const handleChange = async (e) => {
        const { name, value } = e.target;
        const { originalEstimateHr, originalEstimateMin, labourTimeHr, labourTimeMin } = maintenance;
        if (name === 'unitId') {
            await props.getTenant({ unit: value, isDeleted: false, isClosed: false })
        }
        else if (name === 'contractorName') {
            let title = maintenance.propertyName ? maintenance.propertyName + " - " : ""
            setMaintenance({
                ...maintenance,
                [name]: value,
                // title: title + value

            })
        }
        // if (name === 'originalEstimateHr') {
        //     setMaintenance({
        //         ...maintenance,
        //         originalEstimateHr: value,
        //         originalEstimateTime: parseInt(value) + parseInt(originalEstimateMin)
        //     })
        // } else if (name === 'originalEstimateMin') {
        //     setMaintenance({
        //         ...maintenance,
        //         originalEstimateMin: value,
        //         originalEstimateTime: parseInt(value) + parseInt(originalEstimateHr)
        //     })
        // } else if (name === 'labourTimeMin') {
        //     setMaintenance({
        //         ...maintenance,
        //         labourTimeMin: value,
        //         labourTime: parseInt(value) + parseInt(labourTimeHr)
        //     })
        // } else if (name === 'labourTimeHr') {
        //     setMaintenance({
        //         ...maintenance,
        //         labourTimeHr: value,
        //         labourTime: parseInt(value) + parseInt(labourTimeMin)
        //     })
        else
            setMaintenance({
                ...maintenance,
                [name]: value
            })
    }

    const changeRadio = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'authorizationToEnter' || name === 'petInResidence') {
            setMaintenance({
                ...maintenance,
                [name]: checked
            })
        } else
            setMaintenance({
                ...maintenance,
                [name]: value
            })
    }

    const changeRadioTime = (e) => {
        const { name, value, checked } = e.target;

        setMaintenance({
            ...maintenance,
            [name]: value,
            authorizationToEnter: true
        })

    }

    const handleChangeProperty = async (value) => {
        let tempParams = params
        tempParams.propertyId = value.value
        // let title = maintenance.contractorName ? value.label + "-" + maintenance.contractorName : value.label
        setMaintenance({
            ...maintenance,
            propertyId: value.value,
            propertyName: value.label,
            unitId: null,
            requestById: null,
            title: value.label
        })
        setProperty(value)
        setTenant({})
        await props.getUnitsByPropertyId(value.value);
        await props.getTenant({ property: value.value, isDeleted: false, isClosed: false })

    }

    const onClickCategory = async (id, level, name) => {
        if (name == null) {
            setCat1('');
            setCat2('');
            setCat3('');
        }
        if (level == 2)
            setCat1(name);
        else if (level == 3)
            setCat2(name)
        else if (level == 4)
            setCat3(name)
        else if (level == 5)
            setCat4(name)
        setSearch(false);
        setLevel(level);
        id && setId(id)
        const params = {
            level: level,
            parentId: level == 1 ? null : id
        }
        setMaintenance({
            ...maintenance,
            description: cat1 + " " + cat2 + " " + cat3 + " " + cat4,
            maintenanceCategoryId: id,
        })
        await props.getCategoryList(params)
    }

    const onChangeDate = (e, name) => {
        setMaintenance({
            ...maintenance,
            [name]: moment(new Date(e)).endOf('day').toISOString()
        })
    }

    const changeAnyDate = (e) => {
        setMaintenance({
            ...maintenance,
            anyDateIsFine: e.target.checked,
            availableDate: ''
        })
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return validation.REQUIRED(value, name) != true ?
                        <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                case "number":
                    return <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
                case "email":
                    return <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div>
                case "letter":
                    return <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
            }
        } else return null
    }

    const handleSubmit = async (e) => {
        setIsSubmitted(true);
        e.preventDefault();

        const {
            propertyId, unitId, priority, availableDate, anyDateIsFine, authorizationToEnter, petInResidence, maintenanceCategoryId,
            title, description, attachments, requestById, requestInitiatedDate, requestDueDate, assignee, originalEstimateHr,
            originalEstimateMin, labourTime, labourTimeHr, labourTimeMin
        } = maintenance;
        if (propertyId && unitId && priority && title &&
            description && requestById && (availableDate || anyDateIsFine)) {
            if (maintenance.assignee === "3") {
                if (maintenance.contractorName) {
                    if (maintenance._id) {
                        await props.maintenanceUpdate(maintenance);
                    } else {
                        await props.maintenanceAdd(maintenance);

                    }
                    history.push('/Maintenance')
                    setIsEdit(false)
                    setIsSubmitted(false);
                    setMaintenance('');
                    let tempParams = { ...params }
                    tempParams.level = 1
                    await props.getCategoryList(tempParams);
                    setLevel(1)
                }


            }
            else {
                if (maintenance._id) {
                    await props.maintenanceUpdate(maintenance);
                } else {
                    await props.maintenanceAdd(maintenance);

                }
                history.push('/Maintenance')
                setIsEdit(false)
                setIsSubmitted(false);
                setMaintenance('');
                let tempParams = { ...params }
                tempParams.level = 1
                await props.getCategoryList(tempParams);
                setLevel(1)
            }


        }
    }

    return (
        <div className="tenants-otr">
            {loading && <LoaderSpinner />}
            <div className="innago-table-top main-table-top maint-req-top">
                <div className="lft">
                    <h1>ADD MAINTENANCE REQUESTS</h1>
                </div>
            </div>
            <div className="maintenanceOuter addMaintenance">
                <div className="inner-main-outer-sec">
                    <div className="categoryOuter">
                        {/* <h3>CLICK THE ICON OF THE CATEGORY BELOW</h3>
                        <div className="row row-frst">
                            <div className="col-md-12">
                                <div className="categorySec">
                                    <nav>
                                        <div className="nav nav-tabs nav-fill">
                                            <a className={
                                                level == 1 ? "nav-item nav-link active-current" :
                                                    (level == 2 || level === 3 || level === 4 || level === 5) && "nav-item nav-link active"
                                            }
                                                onClick={() => onClickCategory(id, 1)}
                                            >{cat1 ? cat1 : "Category"}<span>1</span></a>
                                            <a className={
                                                level == 2 ? "nav-item nav-link active-current" :
                                                    (level === 3 || level === 4 || level === 5) ? "nav-item nav-link active" :
                                                        'nav-item nav-link '}
                                            >{cat2 ? cat2 : "Sub-category"}<span> 2</span></a>
                                            <a className={level == 3 ? "nav-item nav-link active-current" :
                                                (level === 4 || level === 5) ? "nav-item nav-link active" :
                                                    'nav-item nav-link'}>{cat3 ? cat3 : "Issue"}<span> 3</span></a>
                                            <a className={level == 4 ? "nav-item nav-link active-current" :
                                                level === 5 ? "nav-item nav-link active" :
                                                    'nav-item nav-link'}>Sub-issue <span>4</span></a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div> */}
                        {search && <AdvancedSearch />}
                        <div className="row row-secnd">
                            {(level == 1 || level == 2) && props.categoryMaintenance.category &&
                                !search &&
                                <AddMaintenanceForm1
                                    onClickCategory={onClickCategory}
                                    level={level}
                                    category={props.categoryMaintenance.category}
                                    id={id}
                                />}
                            {(level == 3 || level == 4) && props.categoryMaintenance.category &&
                                <AddMaintenanceForm3
                                    onClickCategory={onClickCategory}
                                    level={level}
                                    category={props.categoryMaintenance.category}
                                    id={id}
                                />}
                            {level == 5 &&
                                <AddMaintenanceForm4
                                    onClickCategory={onClickCategory}
                                    level={level}
                                    category={props.categoryMaintenance.category}
                                    handleChange={handleChange}
                                    handleSubmit={handleSubmit}
                                    changeRadio={changeRadio}
                                    maintenance={maintenance}
                                    onChangeDate={onChangeDate}
                                    validate={validate}
                                    handleAddImage={handleAddImage}
                                    videoError={videoError}
                                    isEdit={isEdit}
                                />}
                            {level != 5 &&
                                !search && !loading &&
                                <div className="col-md-3 serachouter-section" onClick={() => setSearch(true)}>
                                    <div className="searchSec">
                                        <div className="categoryBox">
                                            {/* <img src="/images/leak.png" /> */}
                                            <svg>
                                                <use xlinkHref="/images/svg-sprite.svg#ic-search"></use>
                                            </svg>
                                            <h4>SEARCH</h4>
                                            <p>
                                                Hard to find the right category?
                                                Use this smart search and select
                                                the category faster.
                                        </p>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
                <SideFilterMaintenance
                    maintenance={maintenance}
                    changePriority={changePriority}
                    promiseOptionsProperty={promiseOptionsProperty}
                    properties={properties}
                    handleChangeProperty={handleChangeProperty}
                    handleChange={handleChange}
                    units={units}
                    tenants={tenants}
                    promiseOptionsTenant={promiseOptionsTenant}
                    tenant={tenant}
                    handleChangeTenant={handleChangeTenant}
                    onChangeDate={onChangeDate}
                    changeRadio={changeRadio}
                    property={property}
                    validate={validate}
                    isSubmitted={isSubmitted}
                    changeRadioTime={changeRadioTime}
                    changeAnyDate={changeAnyDate}
                    onChangeAvailableDate={onChangeAvailableDate}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer, tenantReducer } = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        categoryMaintenance: maintenanceReducer.categoryMaintenance,
        add: maintenanceReducer.maintenanceAdd,
        commonReducer,
        tenantReducer,
        maintenanceDetails: maintenanceReducer.maintenanceDetailsById,
        tenantsById: tenantReducer.tenantsById
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...tenantAction
})(AddMaintenance);

