

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const ClientInfoAddTenant = props => {

    const { state, validate, handleChange } = props;

    return (
        <div className="clientInfo">
            <h3>Client Information</h3>
            <div className="formSection">
                <div className="row outrForm p-0 m-0">
                    <div className="col-md-12 FormrightOutr">
                        <div className="row">
                            <div className="col-md-3 formInp formSecInp">
                                <label>First Name</label>
                                <input type="text" className="form-control"
                                    placeholder="Enter First Name" name={"firstName"}
                                    onChange={handleChange} value={state.firstName} maxLength="25" />
                                {validate(state.firstName, "First Name", "required")}
                                {state.firstName && validate(state.firstName, "First Name", "letter")}
                            </div>
                            <div className="col-md-3 formInp formSecInp">
                                <label>Last Name</label>
                                <input type="text" className="form-control"
                                    placeholder="Enter Last Name" value={state.lastName}
                                    name={"lastName"} onChange={handleChange} maxLength="20" />
                                {validate(state.lastName, "Last Name", "required")}
                                {state.lastName && validate(state.lastName, "Last Name", "letter")}
                            </div>
                            <div className="col-md-3 formInp formSecInp">
                                <label>Email</label>
                                <input type="text" className="form-control"
                                    placeholder="Enter Email" value={state.email}
                                    name={"email"} onChange={handleChange} />
                                {validate(state.email, "Email Address", "required")}
                                {state.email && validate(state.email, "Email", "email")}
                            </div>
                            <div className="col-md-3 formInp formSecInp">
                                <label>Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Phone"
                                    value={state.phoneNumber}
                                    onChange={handleChange}
                                    name={"phoneNumber"}
                                    maxLength="12" />
                                {validate(state.phoneNumber, "Phone Number", "required")}
                                {state.phoneNumber && validate(state.phoneNumber, "Phone Number", "phone")}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientInfoAddTenant;
