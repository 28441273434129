import React, { useState, useEffect } from "react";
import Modal from '../Common/components/Modal';
import action from "./action";
import { connect } from "react-redux";
import commonAction from '../Common/action';
import TenantListing from './components/TenantListing.jsx';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../Common/components/Loader";
import Pagination from '../Common/components/pagination';
import TenantTopSection from './components/TenantTopSection1';
import usePrevious from "../Common/components/prevProps";
// import toastr from '../Common/components/Toaster'
import toastr from '../Common/components/Toaster';
import { SocialPeople } from "material-ui/svg-icons";




const Tenants = (props) => {

    const [params, setParams] = useState({ limit: 15, offset: 0, isDeleted: false, isClosed: false })
    const [currentPage, setcurrentPage] = useState(1);
    const [isCheckedAll, setCheckedAll] = useState(false)
    const [tenantIds, setTenantIds] = useState([])
    const [paidArray, setPaidArray] = useState([])
    const [tenantInvoice, setTenantInvoice] = useState([])
    const [isButtonActive, setButtonActive] = useState(false)
    const [submitted, setSubmitted] = useState(true);
    const [stateChange, setChange] = useState(false);
    const [id, setId] = useState('')

    const prevLimit = usePrevious(props.commonReducer.limitData);
    const prevMessage = usePrevious(props.tenantReducer.generateInvoiceByTenant);


    useEffect(() => {
        props.getLimitData()
    }, []);


    useEffect(() => {

        if (prevLimit != props.commonReducer.limitData) {
            const { limitData } = props.commonReducer
            if (limitData) {
                let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 2)
                let tempParams = params
                tempParams.limit = limitCount ? limitCount.count : 15
                //tempParams.limit =1
                setParams(tempParams)
                async function renderCall() {
                    await props.getTenant(tempParams);
                    setSubmitted(false)
                }
                renderCall();
            }

        }
    }, [prevLimit, props.commonReducer.limitData]);

    useEffect(() => {
        const success = props.tenantReducer.generateInvoiceByTenant.success;
        const message = props.tenantReducer.generateInvoiceByTenant.message;
        if (prevMessage != props.tenantReducer.generateInvoiceByTenant && stateChange) {
            success ?
                toastr.success(message)
                :
                toastr.error(message || "Something went wrong")
        }
    }, [prevMessage, props.tenantReducer.generateInvoiceByTenant])


    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        data.isDeleted = false;
        data.isClosed = false
        setParams(data)
        setcurrentPage(page)
        await props.getTenant(data);
    };

    const onChangePerson = (id) => {
        setId(id);
    }

    const handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = params
        tempParams.limit = value
        tempParams.offset = 0
        setParams(tempParams)
        await props.updateLimitData({ type: 2, count: value });
        await props.getTenant(tempParams);
    }

    const filterTenant = async (params) => {
        setParams(params)
        await props.getTenant(params);
    }

    const clearFilter = async (params) => {
        setParams(params)
        await props.getTenant(params);
    }

    const handleCheckAll = async (e) => {
        const { tenantReducer: { tenants } } = props;
        let tempArray = []
        if (e.target.checked) {
            setButtonActive(true)
            setCheckedAll(true)
            return tenants && tenants.tenantList && tenants.tenantList.length ?
                tenants.tenantList.map((tenant, key) => {
                    // if (!invoice.status) {
                    let data = {
                        id: tenant._id,
                        email: tenant.email
                        // tenantId: tenant.tenantId ? invoice.tenantId._id : null,
                        // status: invoice.status,
                        // invoiceNumber: invoice.invoiceNumber,
                        // cardDetails: invoice.tenantId && invoice.tenantId.cardDetails
                    }
                    tempArray.push(data)
                    let tenantData = tempArray.map(i => i.id)
                    setTenantIds(tempArray);
                    setPaidArray(tempArray)
                    setTenantInvoice(tenantData);
                    // }
                })
                : null
        } else {
            setPaidArray([])
            setTenantInvoice([]);
            setButtonActive(false)
            setCheckedAll(false)
        }
    }

    const handleCheck = async (e, tenant) => {
        let tempArray = paidArray;
        let tenantArray = tenantIds;
        if (e.target.checked) {
            setTenantIds(tenantArray);
            setButtonActive(true)
            let data = {
                id: tenant._id
            };
            tempArray.push(data);
            let tenantData = tempArray.map(i => i.id)
            setPaidArray(tempArray);
            setTenantInvoice(tenantData);
            return setPaidArray(tempArray)
        } else {
            setCheckedAll(false)
            tempArray = tempArray.filter(t => t.id !== tenant._id)
            tenantArray = tenantArray.filter(t => t.id !== tenant._id)
            let tenantData = tempArray.map(i => i.id)
            if (!tempArray.length) setButtonActive(false);
            setTenantIds(tenantArray);
            setTenantInvoice(tenantData);
            await setPaidArray(tempArray)
            return setPaidArray(tempArray)
        }
    }

    const generateInvoice = async () => {
        setSubmitted(true);
        setChange(true);
        await props.generateInvoiceByTenant(tenantInvoice);
        setSubmitted(false);
        setChange(false);
        setPaidArray([])
        setTenantInvoice([]);
        setButtonActive(false)
        setCheckedAll(false)
    }

    const { tenantReducer: { tenants } } = props;

    const role = props.profileDetails && props.profileDetails.profileDetail && props.profileDetails.profileDetail.admin.role;

    return (
        <div className="tenants-otr">
            {/* <ToastContainer /> */}
            {submitted && <LoaderSpinner />}
            <TenantTopSection
                role={role}
                params={params}
                isHomeView={true}
                isGenerateVisible={true}
                tenantDetail={false}
                isButtonActive={isButtonActive}
                totalCount={tenants ? tenants.count : 0}
                clearFilter={clearFilter}
                filterTenant={filterTenant}
                onChangePerson={onChangePerson}
                generateInvoice={generateInvoice}
                handleChangeLimit={handleChangeLimit}
            />
            {tenants.success &&
                <div className={`innago-table ${tenants.tenantList && !tenants.tenantList.length ? "addarw" : "invoiceTable"}`}>
                    <TenantListing
                        role={role}
                        params={params}
                        paidArray={paidArray}
                        isCheckedAll={isCheckedAll}
                        tenants={tenants.tenantList}
                        handleCheck={handleCheck}
                        handleCheckAll={handleCheckAll}
                    />
                    <div className="paginationSec">
                        {tenants.count ?
                            <Pagination
                                totalRecords={tenants.count}
                                pageLimit={params.limit}
                                pageNeighbours={2}
                                currentPage={currentPage}
                                onPageChanged={onPageChanged}
                            /> : null}
                    </div>
                </div>}
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer, profileReducer } = state;
    return {
        tenantReducer,
        commonReducer,
        profileDetails: profileReducer.profileDetail,
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(Tenants);

