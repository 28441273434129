import * as Service from './service';
import * as Constant from './constant';

const addBusinessEntity = (entities) => {
    const request = () => ({ type: Constant.ADD_PAYMENT_REQUEST });
    const success = settingsAction => ({
        type: Constant.ADD_PAYMENT_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.ADD_PAYMENT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = entities._id ? await Service.editBusinessEntity(entities) : await Service.addBusinessEntity(entities);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addMailAccount = (params) => {
    const request = () => ({ type: Constant.ADD_MAIL_ACCOUNT_REQUEST });
    const success = settingsAction => ({
        type: Constant.ADD_MAIL_ACCOUNT_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.ADD_MAIL_ACCOUNT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addMailAccount(params);
            if (res.status === 200) {
                const addMailRes = res.data;
                if (addMailRes.success) {
                    dispatch(success(addMailRes));
                } else {
                    dispatch(failure(addMailRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
};

const addMailAccountCode = (code,userId) => {
    const request = () => ({ type: Constant.ADD_MAIL_ACCOUNT_REQUEST });
    const success = settingsAction => ({
        type: Constant.ADD_MAIL_ACCOUNT_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.ADD_MAIL_ACCOUNT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addMailAccountCode(code,userId);
            if (res.status === 200) {
                const addMailRes = res.data;
                if (addMailRes.success) {
                    dispatch(success(addMailRes));
                } else {
                    dispatch(failure(addMailRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteBusiness = (entities) => {
    const request = () => ({ type: Constant.DELETE_BUSINESS_REQUEST });
    const success = settingsAction => ({
        type: Constant.DELETE_BUSINESS_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.DELETE_BUSINESS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteBusiness(entities);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addUser = (code) => {
    const request = () => ({ type: Constant.ADD_MAIL_ACCOUNT_REQUEST });
    const success = addUserRes => ({
        type: Constant.ADD_USER_ACCOUNT_SUCCESS,
        addUserRes
    });
    const failure = error => ({
        type: Constant.ADD_USER_ACCOUNT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = code.id ? await Service.editUser(code) : await Service.addUser(code);
            if (res.status === 200) {
                const addUserRes = res.data;
                if (addUserRes.success) {
                    dispatch(success(addUserRes));
                } else {
                    dispatch(failure(addUserRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteUser = (entities) => {
    const request = () => ({ type: Constant.DELETE_USER_REQUEST });
    const success = id => ({
        type: Constant.DELETE_USER_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_USER_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteUser(entities);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(entities));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getUsers = (params) => {
    const request = () => ({ type: Constant.GET_USERS_REQUEST });
    const success = settingsAction => ({
        type: Constant.GET_USERS_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.GET_USERS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getUsers(params);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails.data));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addCardDetails = (code) => {
    const request = () => ({ type: Constant.ADD_CARD_REQUEST });
    const success = cardDetail => ({
        type: Constant.ADD_CARD_SUCCESS,
        cardDetail
    });
    const failure = error => ({
        type: Constant.ADD_CARD_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = code.cardId ? await Service.setPrimaryCard(code):await Service.addCardDetails(code);
            if (res.status === 200) {
                const addUserRes = res.data;
                if (addUserRes.success) {
                    dispatch(success(addUserRes));
                } else {
                    dispatch(failure(addUserRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteCard = (entities) => {
    const request = () => ({ type: Constant.DELETE_CARD_REQUEST });
    const success = id => ({
        type: Constant.DELETE_CARD_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_CARD_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteCard(entities);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getPaymentHistory = (params) => {
    const request = () => ({ type: Constant.GET_PAYMENT_HISTORY_REQUEST });
    const success = settingsAction => ({
        type: Constant.GET_PAYMENT_HISTORY_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.GET_PAYMENT_HISTORY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPaymentHistory(params);
            if (res.status === 200) {
                const settingDetails = res.data;
                
                if (settingDetails.success) {
                    dispatch(success(settingDetails.data));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const autoPay = (params) => {
    const request = () => ({ type: Constant.AUTO_PAY_REQUEST });
    const success = settingsAction => ({
        type: Constant.AUTO_PAY_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.AUTO_PAY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.autoPay(params);
            if (res.status === 200) {
                const settingDetails = res.data;
                
                if (settingDetails.success) {
                    dispatch(success(settingDetails.data));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getAutoPay = (params) => {
    const request = () => ({ type: Constant.GET_AUTO_PAY_REQUEST });
    const success = settingsAction => ({
        type: Constant.GET_AUTO_PAY_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.GET_AUTO_PAY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getAutoPay(params);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                   
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addTemplate = (fileData) => {

    let templateAttachmentData = new FormData();
            templateAttachmentData.append("file", fileData )

       
    const request = () => ({ type: Constant.ADD_PDF_REQUEST });
    const success = settingsAction => ({
        type: Constant.ADD_PDF_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.ADD_PDF_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addTemplate(templateAttachmentData);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addEditedTemplate = (templateData) => {
       
    const request = () => ({ type: Constant.ADD_EDITED_PDF_REQUEST });
    const success = settingsAction => ({
        type: Constant.ADD_EDITED_PDF_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.ADD_EDITED_PDF_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addEditedTemplate(templateData);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getTemplates = (params) => {
    const request = () => ({ type: Constant.GET_TEMPLATE_REQUEST });
    const success = settingsAction => ({
        type: Constant.GET_TEMPLATE_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.GET_TEMPLATE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTemplates(params);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails.data));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteTemplate = (entities) => {
    const request = () => ({ type: Constant.DELETE_TEMPLATE_REQUEST });
    const success = id => ({
        type: Constant.DELETE_TEMPLATE_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_TEMPLATE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteTemplate(entities);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const updateTemplate = (templateData) => {
    const request = () => ({ type: Constant.ADD_EDITED_PDF_REQUEST });
    const success = settingsAction => ({
        type: Constant.ADD_EDITED_PDF_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.ADD_EDITED_PDF_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateTemplate(templateData);
            if (res.status === 200) {
                const settingDetails = res.data;
                if (settingDetails.success) {
                    dispatch(success(settingDetails));
                } else {
                    dispatch(failure(settingDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addMailDetails = (params) => {
    const request = () => ({ type: Constant.ADD_MAIL_SETTINGS_REQUEST });
    const success = settingsAction => ({
        type: Constant.ADD_MAIL_SETTINGS_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.ADD_MAIL_SETTINGS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addMailDetails(params);
            if (res.status === 200) {
                const addMailRes = res.data;
                if (addMailRes.success) {
                    dispatch(success(addMailRes));
                } else {
                    dispatch(failure(addMailRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
};

const getMailDetails = (params) => {
    const request = () => ({ type: Constant.GET_MAIL_SETTINGS_REQUEST });
    const success = settingsAction => ({
        type: Constant.GET_MAIL_SETTINGS_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.GET_MAIL_SETTINGS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getMailDetails(params);
            if (res.status === 200) {
                const addMailRes = res.data;
                if (addMailRes.success) {
                    dispatch(success(addMailRes));
                } else {
                    dispatch(failure(addMailRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
};

const deleteMailDetails = (params) => {
    const request = () => ({ type: Constant.DELETE_MAIL_SETTINGS_REQUEST });
    const success = settingsAction => ({
        type: Constant.DELETE_MAIL_SETTINGS_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.DELETE_MAIL_SETTINGS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteMailDetails(params);
            if (res.status === 200) {
                const addMailRes = res.data;
                if (addMailRes.success) {
                    dispatch(success(addMailRes));
                } else {
                    dispatch(failure(addMailRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
};

const updateMailDetails = (params) => {
    const request = () => ({ type: Constant.UPDATE_MAIL_SETTINGS_REQUEST });
    const success = settingsAction => ({
        type: Constant.UPDATE_MAIL_SETTINGS_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: Constant.UPDATE_MAIL_SETTINGS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateMailDetails(params);
            if (res.status === 200) {
                const addMailRes = res.data;
                if (addMailRes.success) {
                    dispatch(success(addMailRes));
                } else {
                    dispatch(failure(addMailRes));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
};


export default {
    addBusinessEntity,
    addMailAccount,
    addMailAccountCode,
    deleteBusiness,
    addUser,
    getUsers,
    deleteUser,
    addCardDetails,
    deleteCard,
    getPaymentHistory,
    autoPay,
    getAutoPay,
    addTemplate,
    addEditedTemplate,
    getTemplates,
    deleteTemplate,
    updateTemplate,
    addMailDetails,
    getMailDetails,
    deleteMailDetails,
    updateMailDetails

}
