//add plaid
export const ADD_PLAID_REQUEST = "ADD_PLAID_REQUEST"
export const ADD_PLAID_SUCCESS = "ADD_PLAID_SUCCESS"
export const ADD_PLAID_FAILURE = "ADD_PLAID_FAILURE"

//get datasource details
export const GET_DATASOURCE_REQUEST = "GET_DATASOURCE_REQUEST"
export const GET_DATASOURCE_SUCCESS = "GET_DATASOURCE_SUCCESS"
export const GET_DATASOURCE_FAILURE = "GET_DATASOURCE_FAILURE"

//get datasource details
export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST"
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS"
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE"

//get category details
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE"

//UPDATE category details
export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST"
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS"
export const UPDATE_TRANSACTION_FAILURE = "UPDATE_TRANSACTION_FAILURE"

//delete transaction
export const DELETE_TRANSACTION_REQUEST = "DELETE_TRANSACTION_REQUEST"
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS"
export const DELETE_TRANSACTION_FAILURE = "DELETE_TRANSACTION_FAILURE"

//delete transaction Multiple
export const DELETE_TRANSACTION_MULTIPLE_REQUEST = "DELETE_TRANSACTION_MULTIPLE_REQUEST"
export const DELETE_TRANSACTION_MULTIPLE_SUCCESS = "DELETE_TRANSACTION_MULTIPLE_SUCCESS"
export const DELETE_TRANSACTION_MULTIPLE_FAILURE = "DELETE_TRANSACTION_MULTIPLE_FAILURE"

//get property bank details
export const GET_PROPERTYBANK_REQUEST = "GET_PROPERTYBANK_REQUEST"
export const GET_PROPERTYBANK_SUCCESS = "GET_PROPERTYBANK_SUCCESS"
export const GET_PROPERTYBANK_FAILURE = "GET_PROPERTYBANK_FAILURE"

//update property bank details
export const UPDATE_PROPERTYBANK_REQUEST = "UPDATE_PROPERTYBANK_REQUEST"
export const UPDATE_PROPERTYBANK_SUCCESS = "UPDATE_PROPERTYBANK_SUCCESS"
export const UPDATE_PROPERTYBANK_FAILURE = "UPDATE_PROPERTYBANK_FAILURE"


//import bank details
export const UPDATE_IMPORT_REQUEST = "UPDATE_IMPORT_REQUEST"
export const UPDATE_IMPORT_SUCCESS = "UPDATE_IMPORT_SUCCESS"
export const UPDATE_IMPORT_FAILURE = "UPDATE_IMPORT_FAILURE"
