import React from "react";
import action from "./action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import history from "../../config/history";
import ScreeningHeader from './components/headerSection';
import * as validation from '../Common/components/validation';
import Confirm from "../Common/components/Confirm";

class Screening extends React.Component {
    state = {
        email: '',
        submit: false,
    }

    onChange = e => {
        this.setState({
            email: e.target.value
        })
    }

    handleSubmit = async () => {
        this.setState({ submit: true })
        const { email } = this.state;
        if (email && validation.EMAIL(email, 'email') == true) {
            await this.props.addEmail({ email: this.state.email });
            history.push('/screening-Add');
        }
    }

    render() {
        const { email, submit } = this.state;
        return (
            <React.Fragment>
                <nav className="navbar navbar-expand-lg col-md-12">
                    <div className="container">
                        <a className="navbar-brand logo " href="#"><img src="/images/logo.png" /></a>
                    </div>
                </nav>
                <div className="otrprop">
                    <ScreeningHeader />
                    <div className="col-md-12 mail-sec-otr">
                        <div className="container">
                            <div className="col-md-6 m-auto mail-contnt text-center">
                                <h3>
                                    Let's Get Started
			                    </h3>
                                <p className="txtCont">
                                    First,we need to check and see if your email is associated with an innago
                                    account or an application.Please enter your email address below
			                    </p>
                                <div className="form-section-dtl d-flex">
                                    <div className="col-md-9 form-input p-0">
                                        <input type="text"
                                            placeholder="Enter Your Email"
                                            className="form-control"
                                            value={this.state.email}
                                            onChange={this.onChange} />
                                        {submit && email && <div className={"errorMsg"}>{validation.EMAIL(this.state.email, 'email')}</div>}
                                    </div>
                                    <div className="col-md-3 otr-btn text-center">
                                        <button type="button" onClick={() => this.handleSubmit()}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

const mapStateToProps = state => {
    const { screeningReducer } = state;
    return {
        screeningReducer
    };
};

export default withRouter(connect(mapStateToProps, { ...action })(Screening));
