import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../../Common/components/Loader";
import Pagination from '../../../Common/components/pagination';
import Modal from '../../../Common/components/Modal';
import Confirm from '../../../Common/components/Confirm';
import toastr from '../../../Common/components/Toaster';
import AddMail from './AddEmailId';
import { formatUsPhone } from "../../../Common/components/utils";

const Users = (props) => {

    const [isSubmitted, setIsSubmitted] = useState(true);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);
    const [params, setParams] = useState({ limit: 15, offset: 0, _id: props.id });
    const [currentPage, setCurrentPage] = useState(1);
    const [emailData, setEmailData] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [addUserModal, setaddUser] = useState(false);

    useEffect(() => {
        async function renderCall() {

            await props.getTenantEmail(params);
        }

        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }, []);

    const { tenantReducer: { tenantEmailDelete: deleted, tenantEmailAdd } } = props;

    useEffect(() => {
        if (deleteSubmitted && !deleted.success) {
            toastr.error(deleted.message)
        } else if (deleteSubmitted && deleted.success) {
            toastr.success("Successfully deleted");
        }
    }, [deleted]);

    useEffect(() => {
        if (isSubmitted && tenantEmailAdd && !tenantEmailAdd.success) {
            toastr.error(tenantEmailAdd.message)
        } else if (isSubmitted && tenantEmailAdd && tenantEmailAdd.success) {
            toastr.success(tenantEmailAdd.message);
        }
    }, [tenantEmailAdd]);

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setCurrentPage(page)
        await props.getTenantEmail(data);
    }

    const openModalAddMail = (e) => {
        setUserModal(true);
        setEmailData(e);
    }

    const closeModalMail = () => {
        setUserModal(false)
    }

    const renderModalMail = () => {
        return <AddMail
            onCancel={closeModalMail}
            emailData={emailData}
            params={params}
            id={props.id}
            addEmailAddress={addEmailAddress}
        />
    };

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Mail'
            message='Do you want to delete this Mail?'
            onCancel={closeDeleteModal}
            onConfirm={deleteMail}
        />
    }

    const deleteMail = async () => {
        setDeleteSubmitted(true);
        setDeleteModal(false);
        let deleteData = {
            tenantId: props.id,
            alternateEmailsId: deleteId
        }
        await props.deleteTenantEmail(deleteData);
        await props.getTenantEmail(params);

        setTimeout(() => {
            setDeleteSubmitted(false);
            //props.getUsers(params);
        }, 500);
    }

    const openModalAddUser = () => {
        setaddUser(true)
    }

    const closeModalAddMail = () => {
        setaddUser(false)
    }


    const addEmailAddress = async (userDetails) => {
        setaddUser(false)
        setIsSubmitted(true)
        let req = null
        if (userDetails.alternateEmailsId) {
            req = {
                tenantId: userDetails.tenantId,
                alternateEmailsId: userDetails.alternateEmailsId,
                emailAddress: userDetails.emailAddress,
            }
            await props.updateTenantEmail(req)
        }
        else {
            req = {
                _id: userDetails._id,
                emailAddress: userDetails.emailAddress,
            }
            await props.addTenantEmail(req)
        }

        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }

    const renderAlternatives = (alternative) => {
        return alternative.map((e, i) => {
            return <tr key={i}>
                <td>
                    <p>{e.emailAddress ? e.emailAddress : "N/A"}</p>
                </td>
                <td>
                    <div className="dropdown moveDrop editDetlPrp drop-arw">
                        <i className="fas fa-pencil-alt mr-2"
                            onClick={() => openModalAddMail(e)}></i>
                        <i className="fa fa-trash" aria-hidden="true"
                            onClick={() => openDeleteModal(e._id)}></i>
                    </div>
                </td>
            </tr>
        })

    }


    const { tenantReducer: { tenantEmailDetails } } = props;

    return (
        <>
            {isSubmitted && <LoaderSpinner />}
            {deleteSubmitted && <LoaderSpinner />}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {userModal ? <Modal body={renderModalMail()} onCancel={closeModalMail} /> : null}
            <div className="tab-content" id="pills-tabContent">
                <div className="tenants-otr settingsOuter">
                    <div className="innago-table-top">
                        <div className="lft">
                            <h1>Emails</h1>
                        </div>
                        <div className="rgt">
                            <button className="add" onClick={() => openModalAddMail()}>
                                <i className="fas fa-plus" />
                            Add Mail Account
                        </button>
                        </div>
                    </div>

                    <div className="settingTable">
                        <div className="innago-table table-main mb-3">
                            <div className="table-responsive">
                                <table className="table-gap table-td">
                                    <tbody>
                                        <tr className="table-head">
                                            <th>Email Address</th>
                                            <th className={"widact"}>Action</th>
                                        </tr>
                                        {tenantEmailDetails && tenantEmailDetails.tenant && tenantEmailDetails.tenant.length ?
                                            tenantEmailDetails.tenant.map((e, i) => {

                                                return <> <tr key={i}>
                                                    <td>
                                                        <p>{e.email ? e.email : "N/A"}</p>
                                                    </td>
                                                    <td>
                                                        <div className="justify-content-center disable-div editDetlPrp d-flex">
                                                            <i
                                                                className="fas fa-pencil-alt mr-2" title={"Primary Email"} style={{ cursor: 'no-drop' }}></i>
                                                            <i
                                                                className="fa fa-trash " title={"Primary Email"} style={{ cursor: 'no-drop' }}></i>

                                                        </div>
                                                    </td>
                                                </tr>
                                                    {e.alternateEmails.length ? renderAlternatives(e.alternateEmails) : null}
                                                </>

                                            }) :
                                            <tr>
                                                <td className="text-center no_value_table" colSpan={7}><i
                                                    className="far fa-thumbs-down"></i>There is no data to show you right now!
                                        </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="paginationSec">
                                {tenantEmailDetails && tenantEmailDetails.count && tenantEmailDetails.count ? <Pagination
                                    totalRecords={tenantEmailDetails.count}
                                    pageLimit={params.limit}
                                    pageNeighbours={1}
                                    currentPage={currentPage}
                                    onPageChanged={onPageChanged}
                                /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer } = state;
    return {
        tenantReducer, commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(Users);
