import * as actionTypes from "./constant";
import * as Service from "./service";

//add invoive details
const addInvoice =(value)  => {

    const request = data => ({
        type: actionTypes.ADD_INVOICE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_INVOICE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_INVOICE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addInvoice(value);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//update invoive details
const updateInvoice =(value)  => {

    const request = data => ({
        type: actionTypes.ADD_INVOICE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_INVOICE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_INVOICE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateInvoice(value);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get all the invoice details
const getInvoice =(params)  => {

    const request = data => ({
        type: actionTypes.GET_INVOICE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_INVOICE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getInvoice(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get all the tenant invoice details
const getTenantInvoices =(params)  => {

    const request = data => ({
        type: actionTypes.GET_INVOICE_TENANT_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_INVOICE_TENANT_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_INVOICE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantInvoices(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//get all the tenant invoice details
const getTenantInvoiceTypes =(params)  => {

    const request = data => ({
        type: actionTypes.GET_INVOICE_TENANT_TYPE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_INVOICE_TENANT_TYPE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_INVOICE_TENANT_TYPE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantInvoiceTypes(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//make invoice payment
const payInvoice =(params)  => {

    const request = data => ({
        type: actionTypes.PAY_INVOICE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.PAY_INVOICE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.PAY_INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.payInvoice(params);

            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//delete invoice payment
const deleteInvoiceDetails =(params)  => {

    const request = data => ({
        type: actionTypes.DELETE_INVOICE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_INVOICE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteInvoice(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//send sms to the selected tenants
const sendSms =(params)  => {

    const request = data => ({
        type: actionTypes.SEND_SMS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.SEND_SMS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.SEND_SMS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.sendSms(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails.data));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//send sms to the selected tenants
const sendSmsEachInvoice =(params)  => {

    const request = data => ({
        type: actionTypes.SEND_SMS_INVOICE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.SEND_SMS_INVOICE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.SEND_SMS_INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.sendSmsEachInvoice(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails.data));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//send sms to the selected tenants
const mergeInvoice =(params)  => {

    const request = data => ({
        type: actionTypes.MERGE_INVOICE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.MERGE_INVOICE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.MERGE_INVOICE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.mergeInvoice(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails.data));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get invoice by id
const getInvoiceById =(params)  => {

    const request = data => ({
        type: actionTypes.GET_INVOICE_ID_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_INVOICE_ID_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_INVOICE_ID__FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getInvoiceById(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails.data));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

export default {
    addInvoice,
    getInvoice,
    updateInvoice,
    getTenantInvoices,
    getTenantInvoiceTypes,
    payInvoice,
    deleteInvoiceDetails,
    sendSms,
    sendSmsEachInvoice,
    mergeInvoice,
    getInvoiceById
};
