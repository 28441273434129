import React, { useState, useEffect } from "react";
import action from "../action";
import tenantAction from "../../Tenants/action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import AddCreditCard from './addCreditCard';
import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';
import history from '../../../config/history';
import moment from 'moment';
import {numberWithCommas} from "../../Common/components/utils";
import ReactToPrint from 'react-to-print';

const PaymentDetail = (props) => {

 
  
    const { detail } = props;
    const { profileDetail: { profileDetail } } = props;
    return (
        <div
      className="modal addTenant addInvoiceModal"
      id="modalId"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{display:'block'}}
     // ref={componentRef}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Invoice
            </h5>
            <button
              type="button"
              className="close"
              onClick={()=>props.onCancel()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="payment-history-box">
              <div className="col-md-8 pay-his-sec">
                <div className="col-md-12 pay-his-sec-otr">
                  <h4>Subject</h4>
                  <p>{detail.invoiceId.title.charAt(0).toUpperCase()+detail.invoiceId.title.slice(1)} due on {detail.invoiceId.dueDate && moment(new Date(detail.invoiceId.startDate)).format('MM/DD/YYYY')}</p>
                </div>
              </div>
              {/* <div className="col-md-4  pay-his-sec">
                <div className="col-md-12 pay-his-sec-otr">
                  <h4>Sent To</h4>
                  <p>{profileDetail.admin.tenantId.email}</p>
                </div>
              </div> */}
              <div className="col-md-4 pay-his-sec">
                <div className="col-md-12 pay-his-sec-otr ">
              
                  <span>
                    <i className="fas fa-print"></i>
                      {/* <ReactToPrint
                        trigger={() => <button>Print this out!</button>}
                        content={() => componentRef.current}
                      /> */}
                    Print
                    </span>
                </div>
              </div>
            </div>
            <div className="innago-table table-main">
              <div className="table-responsive">
                <table className="table-gap table-td">
                  <tr className="table-head">
                    <th>Item</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                  <tr>
                    <td>
                    {detail.invoiceId.title}
                    </td>
                    <td>
                    {detail.invoiceId.charges.length &&  detail.invoiceId.charges[0].description}
                    </td>
                    <td>
                      1
                    </td>
                    <td>${numberWithCommas(detail.invoiceId.charges.length && detail.invoiceId.charges[0].amount)||0}</td>
                    <td>${numberWithCommas(detail.amount)||0}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="innago-table table-main payment-received-table">
              <h2>Payment Received(1)</h2>
              <div className="table-responsive">
                <table className="table-gap table-td">
                  <tr className="table-head">
                    <th>Payer</th>
                    <th>Receivde On</th>
                    <th>Method</th>
                    <th>Amount</th>
                  </tr>
                  <tr>
                    <td>
                      <p>{profileDetail.admin.tenantId.firstName+ profileDetail.admin.tenantId.lastName}</p>
                    </td>
                    <td>
                      {detail.createdAt && moment(new Date(detail.createdAt)).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      Card
                    </td>
                    <td><b>${numberWithCommas(detail.amount)||0}</b></td>
                  </tr>
                  {/* <tr>
                    <td colspan="3">
                      <div className="d-flex justify-content-end">
                        <p><b>Remaining Balance</b></p>
                      </div>
                    </td>
                    <td colspan="2">
                      <p className="text-left amount"><b>$1,250.00</b></p>
                    </td>
                  </tr> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, settingsReducer, profileReducer } = state;
    return {
        tenantReducer,
        profileDetail: profileReducer.profileDetail,
        card: settingsReducer.card,
        deleteCards: settingsReducer.deleteCard,
        settingsReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...tenantAction
})(PaymentDetail);


