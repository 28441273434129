import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import action from '../../Settings/action';
import tenantAction from '../../Tenants/action'
import LoaderSpinner from "../../Common/components/Loader";
import AddCreditCard from '../../Settings/components/addCreditCard';

import { payInvoice } from "../service";
import toastr from "../../Common/components/Toaster";
import Modal from "../../Common/components/Modal";


const CardSelect = (props) => {

    const { templateDetails } = props

    return (
        <div className="modal  addTenant addInvoiceModal makepayment" id="modalId" style={{ display: 'block' }}>
            <div className="modal-dialog modal-xs" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Select Template
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true" onClick={props.onCancel}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="cardMakepay">
                            <div className="cardOtrSection">
                                <ul>
                                    {templateDetails && templateDetails.length ? templateDetails.map((template, i) => {
                                        return <li>
                                            <div className="customRadioBtn">
                                                <label className="radioBtn">
                                                    <input type="checkbox"
                                                        name={"cardDetails"}
                                                        onChange={(e) => props.handleChangeTemplate(e, template)}
                                                        checked={props.templateId===template._id}
                                                    />
                                                    <span className="radiocheckmark"></span>
                                                </label>
                                            </div>
                                            <span>{template.templateName}</span>
                                        </li>
                                    }) : <li>No Templates Added!!</li>}

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit"
                            onClick={() => props.submitTemplate()}>
                            Submit
                        </button>


                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {

        templateDetails: state.settingsReducer.templateDetails

    };
};

export default connect(mapStateToProps, {
    ...action, ...tenantAction
})(CardSelect);
