import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//get all the tenant details
export const getTenant = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.tenant, { params });

//add tenant details
export const addTenant = (value) =>
    innagoGateWay.post(serviceEndpoints.tenantEndpoints.tenant, value);

//add tenant RECURED date details
export const addRecuredDate = (value) =>
    innagoGateWay.put(serviceEndpoints.tenantEndpoints.recuredDate, value);


//update tenant details
export const updateTenant = (value) =>
    innagoGateWay.put(serviceEndpoints.tenantEndpoints.tenant, value);

//get tenant details by id
export const getTenantId = (id) =>
    innagoGateWay.get(`${serviceEndpoints.tenantEndpoints.tenant}/${id}`);

//get tenant property details by id
export const getTenantProperty = (id) =>
    innagoGateWay.get(`${serviceEndpoints.tenantEndpoints.tenantProperty}/${id}`);

//DELETE tenant details by id
export const deleteTenant = (id) =>
    innagoGateWay.delete(`${serviceEndpoints.tenantEndpoints.tenant}/${id}`);

//get invoice types by tenant id
export const getInvoiceTypes = (id) =>
    innagoGateWay.get(`${serviceEndpoints.tenantEndpoints.invoicesTypes}/${id}`);

//get invoices by tenant id
export const getInvoicesByTenantId = (id, params) =>
    innagoGateWay.get(`${serviceEndpoints.tenantEndpoints.invoices}/${id}`, { params });

//get messages by tenant id
export const getMessageByTenantId = (id, params) =>
    innagoGateWay.get(`${serviceEndpoints.tenantEndpoints.messages}/${id}`, { params });

//DELETE tenant file details by fileid and tenant id
export const deleteTenantFile = (id, tenantId) =>
    innagoGateWay.put(`${serviceEndpoints.tenantEndpoints.deleteTenantFile}/${tenantId}?fileid=${id}`);

//DELETE invoice details by id
export const deleteInvoice = (id) =>
    innagoGateWay.delete(`${serviceEndpoints.invoiceEndpoints.invoice}/${id}`);

//send the leaseAgreement on tenant add
export const leaseAgreement = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.leaseAgreement, { params });

//edit leaseAgreement 
export const editLeaseAgreement = (params, data) =>
    innagoGateWay.put(serviceEndpoints.tenantEndpoints.leaseAgreement, data, { params });

//view the tenant leaseAgreement on tenant add
export const viewLeaseAgreement = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.viewLeaseAgreement, { params });

//view/list the leaseAgreement
export const viewAgreement = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.viewAgreement, { params });

//create password of tenant by admin
export const createPassword = (value) =>
    innagoGateWay.put(serviceEndpoints.tenantEndpoints.createPassword, value);

//create password of tenant by admin
export const addPaymentTenant = (value) =>
    innagoGateWay.post(serviceEndpoints.tenantEndpoints.paymentTenant, value);

// Send Lob mail
export const sendLobMail = (value) =>
    innagoGateWay.post(serviceEndpoints.invoiceEndpoints.sendLobMail, value);

//DELETE tenant details by id permanently
export const permanentDelete = (id) =>
    innagoGateWay.delete(`${serviceEndpoints.tenantEndpoints.closeAccount}/${id}`);

export const editInvoiceTenant = (value) =>
    innagoGateWay.put(serviceEndpoints.invoiceEndpoints.invoice, value);

//view html view of invoice of a tenant
export const viewNotice = (value) =>
    innagoGateWay.post(serviceEndpoints.invoiceEndpoints.viewNotice, value);

//reopen tenant
export const reopenTenant = (id) =>
    innagoGateWay.put(`${serviceEndpoints.tenantEndpoints.reOpenTenant}/${id}`);
export const sendLeaseAgreement = (value, params) =>
    innagoGateWay.post(serviceEndpoints.tenantEndpoints.submitLease, value, { params });

//add File for tenant
export const addFile = (value) =>
    innagoGateWay.post(serviceEndpoints.tenantEndpoints.file, value);

//delete File for tenant
export const deleteFile = (id, params) =>
    innagoGateWay.put(`${serviceEndpoints.tenantEndpoints.fileDelete}/${id}?fileid=${params}`);

//view File for tenant
export const getFile = (value) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.file, value);

//delete tenant email
export const deleteTenantEmail = (data) =>
    innagoGateWay.delete(`${serviceEndpoints.tenantEndpoints.emailManageDelete}`, { data });

//add tenant email
export const addTenantEmail = (params) =>
    innagoGateWay.post(`${serviceEndpoints.tenantEndpoints.emailManageAdd}`, params);


//update tenant email
export const updateTenantEmail = (params) =>
    innagoGateWay.put(`${serviceEndpoints.tenantEndpoints.emailManageUpdate}`, params);

//get tenant email
export const getTenantEmail = (params) =>
    innagoGateWay.get(`${serviceEndpoints.tenantEndpoints.emailManageGet}`, { params });

export const generateInvoiceByTenant = (params) =>
    innagoGateWay.post(`${serviceEndpoints.tenantEndpoints.generateInvoice}`, params);
