import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import commonAction from '../../Common/action'
import * as validation from '../../Common/components/validation';
import action from '../action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import tenantAction from '../../Tenants/action';
import AsyncSelect from 'react-select/async';
import LoaderSpinner from "../../Common/components/Loader";
import NumberFormat from 'react-number-format';
import CommonDatePicker from '../../Common/components/CommonDatePicker'



const AddInvoice = (props) => {
    const [invoiceForm, setInvoiceForm] = useState({
        tenantId: null,
        amount: null,
        startDate: null,
        dueDate: null,
        invoiceTypeId: null,
        paymentTypeId: null,
        tenantDetails: null,
        isRecurring: false,
        status: null,
        description: ""
    })
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (props.invoiceDetails) setInvoiceForm(props.invoiceDetails)

        async function renderCall() {
            await props.getProperty()
            await props.getInvoiceType()
            await props.getTenant({ limit: 10, isDeleted: false, isClosed: false })
            await props.getPaymentDetails()
        }

        renderCall();
        if (props.tenantId) {
            setInvoiceForm({
                ...invoiceForm,
                tenantId: props.tenantId[0].value
            })
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 900)
    }, []);

    const { tenantReducer: { tenants } } = props;

    const tenantLists = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.filter((e) => !e.isDeleted);
    const tenantList = tenantLists.length && tenantLists.map(e =>
        ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));

    const handleChange = async (e) => {
        const { name, value } = e.target
        if (name === "isRecurring") {
            if (e.target.checked) {
                setInvoiceForm({
                    ...invoiceForm,
                    isRecurring: true
                })
            } else {
                setInvoiceForm({
                    ...invoiceForm,
                    isRecurring: false
                })
            }
        } else if (name === "amount") {
            setInvoiceForm({
                ...invoiceForm,
                [name]: value.replace("$", "").replace(/,/g, '')
            })
        } else {
            setInvoiceForm({
                ...invoiceForm,
                [name]: value
            })
        }

    }

    const handleChangeDate = (name, value) => {
        let date = moment(value).endOf('day').toISOString()
        setInvoiceForm({
            ...invoiceForm,
            [name]: date
        })
    }

    const addinvoice = async () => {
        setIsSubmitted(true);
        if (invoiceForm.tenantId) {
            let tenantDetails = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.find(t => invoiceForm.tenantId === t._id)
            if (invoiceForm.tenantId
                && invoiceForm.paymentTypeId
                && invoiceForm.amount
                && invoiceForm.startDate
                && invoiceForm.description
                && invoiceForm.dueDate && invoiceForm.invoiceTypeId) {
                // && (tenantDetails.unitId && tenantDetails.unitId.monthlyRent > invoiceForm.amount)) {
                await props.addInvoiceDetails(invoiceForm)
                await props.getInvoice
            }
        }

    }


    const validate = (value, name, type) => {

        if (isSubmitted) {
            switch (type) {
                case "required":
                    return validation.REQUIRED(value, name) != true ?
                        <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                case "isNumber":
                    return <div
                        className={"errorMsg"}>{!(validation.NUMBER_ONLY(value)) ? "* Only numbers are allowed" : null}</div>
                case "isGreater":
                    let tenantDetails = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.find(t => invoiceForm.tenantId === t._id)
                    return tenantDetails.unitId && tenantDetails.unitId.monthlyRent < value ?
                        <div className={"errorMsg"}>*Amount is greater than monthly rent</div> : null
                // value>
                // return <div
                //     className={"errorMsg"}>{!(validation.NUMBER_ONLY(value)) ? "* Only numbers are allowed" : null}</div>
            }
        } else return null
    }


    const promiseOptionsTenant = async val => {
        await props.getTenant({ name: val, isDeleted: false, isClosed: false })
        const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));
        return tenantList.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeTenant = (value) => {
        setInvoiceForm({
            ...invoiceForm,
            tenantDetails: value,
            tenantId: value.value
        })

    }

    const renderContent = (type) => {
        switch (type) {
            case "view":
                return "View Invoice Details"
                break;
            case "edit":
                return "Update Invoice Details"
                break;
            case "add":
                return "Add Invoice Details"
                break;
            default:
                return null
        }
    }

    const DepositId = props.commonReducer.InvoiceTypeList.length ? props.commonReducer.InvoiceTypeList.find(i => i.name === "Deposit") : null
    const RentId = props.commonReducer.InvoiceTypeList.length ? props.commonReducer.InvoiceTypeList.find(i => i.name === "Fees") : null

    return (
        <div className="modal  addTenant addInvoiceModal" id="modalId" style={{ display: 'block' }}>
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {invoiceForm && renderContent(invoiceForm.isView === true ? 'view' : invoiceForm.isEdit === true ? 'edit' : 'add')}</h5>
                        <button type="button" className="close" onClick={props.onCancel}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo invoice">
                            <div className="formSection">
                                <div className="row outrForm m-0 p-0">

                                    <div className="col-md-4 formInp">
                                        <label>Tenant Name</label>
                                        <AsyncSelect
                                            cacheOptions
                                            isDisabled={invoiceForm.isView || invoiceForm.isEdit}
                                            defaultOptions={props.tenantId && props.tenantId[0] ? props.tenantId : tenantList}
                                            loadOptions={promiseOptionsTenant}
                                            name="tenantId"
                                            onChange={handleChangeTenant}
                                            value={
                                                (props.tenantId && props.tenantId[0]) ?
                                                    props.tenantId[0] :
                                                    tenantList && tenantList.filter(option => option.value === invoiceForm.tenantId)
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4 formInp">
                                        <label>Invoice Type</label>
                                        <div className="selctOtr">
                                            <select className="form-control" name={"invoiceTypeId"}
                                                disabled={invoiceForm.isView}
                                                value={invoiceForm.invoiceTypeId}
                                                onChange={handleChange}>
                                                <option>Select Type</option>
                                                {props.commonReducer.InvoiceTypeList.length ? props.commonReducer.InvoiceTypeList.map((type, key) => {
                                                    return <option value={type._id}>{type.name}</option>
                                                }) : null}
                                            </select>
                                            {validate(invoiceForm.invoiceTypeId, "Invoice Type", "required")}
                                        </div>
                                    </div>

                                    <div className="col-md-4 formInp">
                                        <label>Invoice Amount</label>
                                        <NumberFormat
                                            thousandSeparator={true}
                                            placeholder={"Enter Amount"}
                                            prefix={'$'}
                                            className="form-control"
                                            name="amount"
                                            onChange={handleChange}
                                            value={invoiceForm.amount}
                                        />
                                        {validate(invoiceForm.amount, "Amount", "required")}
                                        {invoiceForm.amount && validate(invoiceForm.amount, "Amount", "isNumber")}
                                    </div>
                                    <div className="col-md-4 formInp">
                                        <label>Invoice Date</label>
                                        <CommonDatePicker
                                            name="startDate"
                                            placeholder="Start Date"
                                            format="YYYY-MM-DD"
                                            className="form-control"
                                            disabled={invoiceForm.isView || invoiceForm.isEdit}
                                            onChange={(value) => handleChangeDate("startDate", value)}
                                            value={invoiceForm.startDate}
                                            min={new Date()}
                                        />
                                        {validate(invoiceForm.startDate, "StartDate", "required")}
                                    </div>
                                    <div className="col-md-4 formInp">
                                        <label>Due Date</label>
                                        <CommonDatePicker
                                            name="dueDate"
                                            placeholder="Due Date"
                                            format="YYYY-MM-DD"
                                            className="form-control"
                                            disabled={invoiceForm.isView || invoiceForm.isEdit}
                                            onChange={(value) => handleChangeDate("dueDate", value)}
                                            value={invoiceForm.dueDate}
                                            min={new Date()}
                                        />
                                        {validate(invoiceForm.dueDate, "DueDate", "required")}
                                    </div>
                                    <div className="col-md-4 formInp">
                                        <label>Invoice Payments</label>
                                        <div className="selctOtr">
                                            <select className="form-control" name={"paymentTypeId"}
                                                disabled={invoiceForm.isView ? invoiceForm.isView : false}
                                                value={invoiceForm.paymentTypeId}
                                                onChange={handleChange}>
                                                <option>Select Payment Type</option>
                                                {props.commonReducer.paymentMethods &&
                                                    props.commonReducer.paymentMethods.length ? props.commonReducer.paymentMethods.map((type, key) => {
                                                        return <option value={type._id}
                                                            key={key}>{type.name}</option>
                                                    }) : null}
                                            </select>
                                            {validate(invoiceForm.paymentTypeId, "Pay Type", "required")}
                                        </div>
                                    </div>
                                    <div className="col-md-12 formInp">
                                        <label>Description</label>
                                        <textarea className="form-control" placeholder="description" name={"description"}
                                            disabled={invoiceForm.isView || invoiceForm.isEdit}
                                            value={invoiceForm.description}
                                            onChange={handleChange}>{invoiceForm.description}
                                        </textarea>
                                    </div>
                                    <div className="col-md-12 formInp checkBx p-0">
                                        <div className="col-md-4">
                                            <label className="containerCheck">Recurring
                                                    <input type="checkbox" name={"isRecurring"} onChange={handleChange}
                                                    checked={invoiceForm.isRecurring}
                                                    disabled={invoiceForm.isView} />
                                                <span className="checkmark" readonly></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {!invoiceForm.isView ? invoiceForm.isEdit ?
                            <button type="button" className="btn btn-primary btnSubmit"
                                onClick={addinvoice}>Update</button> :
                            <button type="button" className="btn btn-primary btnSubmit"
                                onClick={addinvoice}>Submit</button> :
                            <button type="button" className="btn btn-primary btnSubmit"
                                onClick={props.onCancel}>Cancel</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { invoiceReducer, commonReducer, tenantReducer } = state;
    return {
        commonReducer, invoiceReducer, tenantReducer
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction, ...tenantAction })(AddInvoice);
