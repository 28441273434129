import React, { useState, useEffect } from "react";
import parser from 'html-react-parser';
import { savePDF } from '@progress/kendo-react-pdf';


const TenantDetailLease = (props) => {

    const { htmlData } = props;

    useEffect(() => {
        if (htmlData) {
            let inputtype = document.getElementById("inp1")
            let inputtype2 = document.getElementById("inp2")
            inputtype.remove()
            inputtype2.remove()
        }
    }, [htmlData]);

    const downloadAgreement = () => {
        let page1 = document.getElementById("leaseAgreement")
        savePDF(page1, {
            scale: 0.5,
            paperSize:"A4",
            margin: "1cm",
            fileName: 'form.pdf',
        })
    }



    return (

        <div className="modal addTenant leaseAgreement" role="dialog" id="modalId" style={{ display: 'block' }}>

            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="customtest">
                            <h5 className="modal-title" id="exampleModalLabel">Lease Agreement</h5>
                            <div className="leaseDiv">
                                <button className="btn sendbutton"
                                    onClick={() => {
                                        downloadAgreement()
                                    }}>
                                    <i class="fas fa-download pr-1"></i>Download
            </button>

                            </div>
                        </div>

                        <button type="button" className="close" onClick={() => props.onCancel()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" id="leaseAgreement">
                        {htmlData && parser(JSON.parse(htmlData))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TenantDetailLease;