import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import action from "../../action";

const Navigation = (props) => {

    return (
        <div className="expenseTab ticketTab">
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a
                        className={`nav-link ${props.section === 'AddReplay' ? "active" : ""}`}
                        onClick={() => props.handleTab('AddReplay')}
                        href={"#"}
                    >Add Reply</a
                    >
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${props.section === 'AddNote' ? "active" : ""}`}
                        onClick={() => props.handleTab('AddNote')}
                        href={"#"}
                    >Add Note</a
                    >
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${props.section === 'OtherTickets' ? "active" : ""}`}
                        onClick={() => props.handleTab('OtherTickets')}
                        href={"#"}

                    >Other Tickets</a
                    >
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${props.section === 'ClientLog' ? "active" : ""}`}
                        onClick={() => props.handleTab('ClientLog')}
                        href={"#"}

                    >Client Log</a
                    >
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${props.section === 'Options' ? "active" : ""}`}
                        onClick={() => props.handleTab('Options')}
                        href={"#"}
                    >Options</a
                    >
                </li>
                {/*<li className="nav-item">*/}
                {/*    <a*/}
                {/*        className={`nav-link ${props.section === 'Log' ? "active" : ""}`}*/}
                {/*        onClick={() => props.handleTab('Log')}*/}
                {/*        href={"#"}*/}
                {/*    >Log</a*/}
                {/*    >*/}
                {/*</li>*/}
            </ul>
            {/*<div className="ticketFilter">*/}
            {/*    <i className="fas fa-sliders-h"></i> Filter*/}
            {/*</div>*/}
        </div>

    )


}

const mapStateToProps = state => {
    const {maintenanceReducer} = state;
    return {
        maintenanceReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(Navigation);
