import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { MAIN_ROUTE } from "../../../config/constants";
import history from '../../../config/history'
import action from '../../Properties/action'
import commonAction from '../../Common/action'
import AddPropertyModal from "./AddPropertyModal";
import Modal from "../../Common/components/Modal";
import LoaderSpinner from "../../Common/components/Loader";
import MessageBox from "../../Common/components/MessageBox";
import Confirm from "../../Common/components/Confirm";
import toastr from "../../Common/components/Toaster";
import { numberWithCommas } from "../../Common/components/utils";
import usePrevious from "../../Common/components/prevProps";

const PropertyList = (props) => {

    const [editPropertyModal, setEditPropertyModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [propertyToDelete, setPropertyToDelete] = useState(null)
    const [message, setMessage] = useState({ text: null })
    const [params, setParams] = useState({
        limit: 15, offset: 0, isIncluded: null
    })
    const prevUser = usePrevious(props.property);

    useEffect(() => {
        if (isSubmitted && prevUser !== props.property && props.property.success == false) {
            toastr.error(props.property.message);
        } else if (
            isSubmitted &&
            prevUser !== props.property &&
            props.property.success
        ) {

            toastr.success("Property created successfully");
        }
    }, [prevUser, props.property]);

    const getPropertyList = async () => {
        const {
            match: {
                params: { section }
            }
        } = props;
        let tempParams = props.params
        if (section) {
            if (section === 'Vacant') {
                setParams({ ...props.params, isIncluded: 1 })
                tempParams.isIncluded = 1
            } else if (section === 'Occupied') {
                setParams({ ...props.params, isIncluded: 0 })
                tempParams.isIncluded = 0
            }
            props.getProperty(tempParams);
        }
        await props.getProperty(tempParams);
    }

    const updateProperty = async (params) => {
        let propertyDetails = new FormData();
        params.images.map(img => {
            propertyDetails.append("file", img.image[0]);
        })
        propertyDetails.append("data", JSON.stringify(params.data));
        setIsLoading(true)
        setIsSubmitted(true)
        await props.updateProperty(propertyDetails)
        await getPropertyList()
        setEditPropertyModal(false)
        setIsLoading(false)
        setIsSubmitted(false)
    }

    useEffect(() => {
        if (isSubmitted && props.propertyReducer.updatePropertyResponse && props.propertyReducer.updatePropertyResponse.success) {
            toastr.success("Property updated successfully");
        } else if (isSubmitted && props.propertyReducer.updatePropertyResponse && props.propertyReducer.updatePropertyResponse.success == false) {
            toastr.error('Failed to udpate property!');
        }
    }, [props.propertyReducer.updatePropertyResponse]);

    const renderEditPropertyModal = () => {
        return (
            <AddPropertyModal
                onCancel={() => setEditPropertyModal(false)}
                propertyDetails={editPropertyModal}
                addPropertyDetails={updateProperty}
                getPropertyList={getPropertyList}
                renderLoader={isLoading}
            />
        );
    }

    const deleteProperty = async (property) => {
        if (property.tenantCount > 0) {
            setMessage({
                text: 'A tenant is associated with this property. Remove tenant to delete this property!!',
            })
        } else {
            setConfirmDelete(true)
            setPropertyToDelete(property)
        }
    }

    const deletePropertyConfirmed = async () => {
        setIsLoading(true)
        if (propertyToDelete) {
            await props.deleteProperty({ id: propertyToDelete._id })
            await getPropertyList()
        }
        setIsLoading(false)
        setConfirmDelete(false)
    }

    const viewPropertyDetail = (id) => {
        if (props.role != "maintenance")
            history.push(`/PropertyDetails/${id}`)
    }

    const parseBackgoundImage = (image) => {
        if (image.length) {
            if (image[0] && image[0].type && image[0].type == "zillo") {
                return image[0].path
            } else if (image[0]) {
                return MAIN_ROUTE + image[0].path
            } else {
                return '/images/def_property.png'
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }

    const getDefaultImage = (property) => {
        // src={(property.propertyImage[0]) ? parseBackgoundImage(property.propertyImage) : "/images/def_property.png"}
        let defImg = [];
        if (property &&
            property.propertyImage &&
            property.propertyImage.filter(img => img.isDefault) &&
            property.propertyImage.filter(img => img.isDefault).length
        ) {
            defImg = property.propertyImage.filter(img => img.isDefault);
        } else {
            defImg = property.propertyImage[0]
        }
        if (!Array.isArray(defImg)) {
            defImg = [defImg]
        }
        if (defImg) {
            return parseBackgoundImage(defImg)
        } else {
            return "/images/def_property.png"
        }
    }

    return (
        <>
            {editPropertyModal ? <Modal body={renderEditPropertyModal()} onCancel={() => setEditPropertyModal(false)} /> : null}
            {isLoading && <LoaderSpinner />}
            {message.text ? <Modal body={<MessageBox
                onCancel={() => setMessage({ text: null })}
                message={message.text}
                title={<i style={{ 'color': 'indianred', 'fontSize': '30px' }} className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
            />
            }
                onCancel={() => setMessage({ text: null })}
            /> : null}
            {
                confirmDelete ? <Modal body={
                    <Confirm
                        title='Delete Property'
                        message='Do you want to delete this property?'
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={() => {
                            deletePropertyConfirmed()
                        }}
                    />
                } />
                    : null
            }
            <div className={`table-responsive  ${props.Property && props.Property.propertyList && props.Property.propertyList.length ? " " : "tab-arw"}`}>
                {props.Property && props.Property.propertyList && props.Property.propertyList.length ?
                    <table className="table-gap table-td table-hover">
                        <tr className="table-head">
                            <th className="propertywidth">Property Details</th>
                            <th className="unitSec">Total Unit</th>
                            <th className="avlSec">Availability</th>
                            <th className="monRent"> Monthly Rent</th>
                            <th className="totAvlRent"> Available Unit Rent</th>
                            <th className="tenNme cstmWidth">Tenants</th>
                            <th className="openMain">Open Maintenance</th>
                            {props.role == "maintenance" ? null : <th className="">Action</th>}
                        </tr>
                        {props.Property.propertyList.map((property, key) => {
                            return <tr
                                key={key}
                            // onClick={() => viewPropertyDetail(property._id)}
                            >
                                <td className="propOuter" onClick={() => viewPropertyDetail(property._id)}>
                                    <div className="c-td propDtl">
                                        <div className="imgProp">
                                            <img
                                                // src={(property.propertyImage[0]) ? parseBackgoundImage(property.propertyImage) : "/images/def_property.png"}
                                                src={getDefaultImage(property)}
                                                alt="" />
                                        </div>
                                        <div className="dtlSecprop">
                                            <div className="name">{property.propertyName}
                                            </div>
                                            {/*<div className="loction">(910) 967-7373</div>*/}
                                        </div>
                                    </div>
                                </td>
                                <td className="unitSec">
                                    <div className="c-td propDtlUnit unitnum">
                                        <p className="dtlHed"> {property.unitCount}</p>
                                    </div>
                                </td>
                                <td className="unitSec avlSec avlNum">
                                    <div className="c-td propDtlUnit">
                                        <p className="dtlHed"> {property.availableunitCount}</p>
                                    </div>
                                </td>
                                <td className="unitSec">
                                    <div className="c-td propDtlUnit">
                                        <p className="dtlHed"> ${numberWithCommas(property.totalMonthlyRent) || 0.00}</p>
                                    </div>
                                </td>
                                <td className="unitSec">
                                    <div className="c-td propDtlUnit">
                                        <p className="dtlHed"> ${numberWithCommas(property.availableMonthlyRent) || 0.00}</p>
                                    </div>
                                </td>
                                <td className="unitSec">
                                    <div className="c-td propDtlUnit">
                                        <p className="dtlHed"> {property.tenantCount}</p>
                                    </div>
                                </td>
                                <td className="unitSec">
                                    <div className="c-td propDtlUnit">
                                        <p className="dtlHed">  {property.availablemaintenanceCount ? property.availablemaintenanceCount : 0}</p>
                                    </div>
                                </td>
                                {props.role == "maintenance" ? null : <td>
                                    <div className="c-td justify-content-center editDetlPrp d-flex">
                                        <i
                                            className="fas fa-pencil-alt mr-1 ml-1"
                                            // onClick={(e)=>props.openModal(e,property)}
                                            onClick={() => setEditPropertyModal(property)}
                                        ></i>
                                        <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            onClick={() => {
                                                deleteProperty(property)
                                            }}
                                        ></i>
                                    </div>
                                </td>}
                            </tr>

                        })}
                    </table>
                    : <div className="no_value"><i className="far fa-thumbs-down"></i> Add Your First Property To Get Started!!!</div>}
            </div>
        </>

    )
}

const mapStateToProps = state => {
    const { propertyReducer } = state
    return { propertyReducer };
}

export default withRouter(connect(mapStateToProps, { ...action, ...commonAction })(PropertyList))
