
//add business entity for admin
export const ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST ';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS ';
export const ADD_PAYMENT_FAILURE = 'ADD_PAYMENT_FAILURE ';

//add business entity for admin
export const DELETE_BUSINESS_REQUEST = 'DELETE_BUSINESS_REQUEST ';
export const DELETE_BUSINESS_SUCCESS = 'DELETE_BUSINESS_SUCCESS ';
export const DELETE_BUSINESS_FAILURE = 'DELETE_BUSINESS_FAILURE ';

//add mail account
export const ADD_MAIL_ACCOUNT_REQUEST = 'ADD_MAIL_ACCOUNT_REQUEST ';
export const ADD_MAIL_ACCOUNT_SUCCESS = 'ADD_MAIL_ACCOUNT_SUCCESS ';
export const ADD_MAIL_ACCOUNT_FAILURE = 'ADD_MAIL_ACCOUNT_FAILURE ';


//add user account
export const ADD_USER_ACCOUNT_REQUEST = 'ADD_USER_ACCOUNT_REQUEST ';
export const ADD_USER_ACCOUNT_SUCCESS = 'ADD_USER_ACCOUNT_SUCCESS ';
export const ADD_USER_ACCOUNT_FAILURE = 'ADD_USER_ACCOUNT_FAILURE ';

//delete a users
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST ';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS ';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE ';

//get all users
export const GET_USERS_REQUEST = ' GET_USERS_REQUEST ';
export const GET_USERS_SUCCESS = ' GET_USERS_SUCCESS ';
export const GET_USERS_FAILURE = ' GET_USERS_FAILURE ';

//add card details
export const ADD_CARD_REQUEST = ' ADD_CARD_REQUEST ';
export const ADD_CARD_SUCCESS = ' ADD_CARD_SUCCESS ';
export const ADD_CARD_FAILURE = ' ADD_CARD_FAILURE ';

//delete a users
export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST ';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS ';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE ';


//get PAYMENT HISTORY
export const GET_PAYMENT_HISTORY_REQUEST = ' GET_PAYMENT_HISTORY_REQUEST ';
export const GET_PAYMENT_HISTORY_SUCCESS = ' GET_PAYMENT_HISTORY_SUCCESS ';
export const GET_PAYMENT_HISTORY_FAILURE = ' GET_PAYMENT_HISTORY_FAILURE ';

//add auto pay
export const AUTO_PAY_REQUEST = ' AUTO_PAY_REQUEST ';
export const AUTO_PAY_SUCCESS = ' AUTO_PAY_SUCCESS ';
export const AUTO_PAY_FAILURE = ' AUTO_PAY_FAILURE ';

//add auto pay
export const GET_AUTO_PAY_REQUEST = ' GET_AUTO_PAY_REQUEST ';
export const GET_AUTO_PAY_SUCCESS = ' GET_AUTO_PAY_SUCCESS ';
export const GET_AUTO_PAY_FAILURE = ' GET_AUTO_PAY_FAILURE ';

//add template pdf
export const ADD_PDF_REQUEST = 'ADD_PDF_REQUEST ';
export const ADD_PDF_SUCCESS = 'ADD_PDF_SUCCESS ';
export const ADD_PDF_FAILURE = 'ADD_PDF_FAILURE ';


//add template pdf
export const ADD_EDITED_PDF_REQUEST = 'ADD_EDITED_PDF_REQUEST ';
export const ADD_EDITED_PDF_SUCCESS = 'ADD_EDITED_PDF_SUCCESS ';
export const ADD_EDITED_PDF_FAILURE = 'ADD_EDITED_PDF_FAILURE ';

export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST ';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS ';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE ';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST ';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS ';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE ';

//add mail accounts
export const ADD_MAIL_SETTINGS_REQUEST = 'ADD_MAIL_SETTINGS_REQUEST ';
export const ADD_MAIL_SETTINGS_SUCCESS = 'ADD_MAIL_SETTINGS_SUCCESS ';
export const ADD_MAIL_SETTINGS_FAILURE = 'ADD_MAIL_SETTINGS_FAILURE ';

//get mail accounts
export const GET_MAIL_SETTINGS_REQUEST = 'GET_MAIL_SETTINGS_REQUEST ';
export const GET_MAIL_SETTINGS_SUCCESS = 'GET_MAIL_SETTINGS_SUCCESS ';
export const GET_MAIL_SETTINGS_FAILURE = 'GET_MAIL_SETTINGS_FAILURE ';

//delete mail accounts
export const DELETE_MAIL_SETTINGS_REQUEST = 'DELETE_MAIL_SETTINGS_REQUEST ';
export const DELETE_MAIL_SETTINGS_SUCCESS = 'DELETE_MAIL_SETTINGS_SUCCESS ';
export const DELETE_MAIL_SETTINGS_FAILURE = 'DELETE_MAIL_SETTINGS_FAILURE ';

//update mail accounts
export const UPDATE_MAIL_SETTINGS_REQUEST = 'UPDATE_MAIL_SETTINGS_REQUEST ';
export const UPDATE_MAIL_SETTINGS_SUCCESS = 'UPDATE_MAIL_SETTINGS_SUCCESS ';
export const UPDATE_MAIL_SETTINGS_FAILURE = 'UPDATE_MAIL_SETTINGS_FAILURE ';