import React from "react";
import {connect} from "react-redux";
import action from "../action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {withRouter} from "react-router-dom"
import moment from 'moment';
import MessagingFilterForm from './messagingFilterForm'
import queryString from 'query-string'

class MessagingFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            isLoaded: false,
            fromDate: null,
            toDate: null,
            keyWord: '',
            listType: '',
            listParams: {
                limit: 1,
                offset: 1,
                type: 'inbox',
            },
        };
    }

    handleShowFilter = () => {
        const {showFilter} = this.state;
        this.setState({
            showFilter: !showFilter
        });
    };

    onChangeFromDate = async (value) => {
        await this.setState({
            fromDate: moment(new Date(value)).format('YYYY-MM-DD')
        })
    };

    onChangeToDate = async (value) => {
        await this.setState({
            toDate: moment(new Date(value)).format('YYYY-MM-DD')
        })
    };

    handleClearFilter = async () => {
        await this.setState({
            toDate: null,
            fromDate: null,
            keyWord: ''
        });
        // const {toDate, fromDate, keyWord} = this.state;
        // let filterParams = {
        //     toDate,
        //     fromDate,
        //     keyWord,
        // };
        // await this.props.updateFilterParams(filterParams);
        // this.handleShowFilter();
    };

    handleKeyWordOnChange = async (e) => {
        const {value} = e.target;
        await this.setState({
            keyWord: value
        })
    };

    handleApplyFilter = async (e) => {
        const {toDate,fromDate, keyWord} = this.state;
        const {location} = this.props;
        await this.setState({
            listType: location.pathname === '/Messaging/Email/Sent' ? 'sent' : 'inbox'
        });
        let filterParams = {
            toDate,
            fromDate,
            keyWord,
        }
        this.props.updateFilterParams(filterParams);
        this.handleShowFilter();
    };

    render() {
        const {showFilter} = this.state;
        return (
            <>
                <button className="fltr fltrList" onClick={this.handleShowFilter}>
                    <i className="fas fa-sliders-h"> </i>Filter
                </button>
                {showFilter
                    ? <MessagingFilterForm
                        handleShowFilter={this.handleShowFilter}
                        onChangeFromDate={this.onChangeFromDate}
                        onChangeToDate={this.onChangeToDate}
                        handleClearFilter={this.handleClearFilter}
                        handleKeyWordOnChange={this.handleKeyWordOnChange}
                        handleApplyFilter={this.handleApplyFilter}
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        keyWord={this.state.keyWord}
                    />
                    : null
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(MessagingFilter));