import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import commonAction from '../../Common/action';
import ReactHtmlParser from 'react-html-parser';

import { connect } from "react-redux";
import action from "../action";
import tenantAction from "../../Tenants/action";
import CommonDatePicker from "../../Common/components/CommonDatePicker";
import ErrorPage from "../../Common/components/error";
import Modal from "../../Common/components/Modal";
import UploadMOdal from "./uploadModal"
import Doc from './doc';
import PdfContainer from './PdfContainer';
import usePrevious from "../../Common/components/prevProps";
import toastr from '../../Common/components/Toaster'
import LoaderSpinner from '../../Common/components/Loader';
import history from "../../../config/history"



const LeaseAgreement = (props) => {
    const [params, setParams] = useState({
        property: null,
        unit: null,
        from: null,
        to: null,
        propertyDetails: "",
        status: null,
        currentTarget: null
    })
    const [modal, setModal] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)




    const prevLeaseAgreementUpdate = usePrevious(props.leaseAgreementUpdate);

    const prevLeaseAgreement = usePrevious(props.leaseAgreement);



    useEffect(() => {
        if (isSubmitted && prevLeaseAgreementUpdate !== props.leaseAgreementUpdate && !props.leaseAgreementUpdate.success) {
            toastr.error(props.leaseAgreementUpdate.message)


        } else if (isSubmitted && prevLeaseAgreementUpdate !== props.leaseAgreementUpdate && props.leaseAgreementUpdate.success) {

            toastr.success("Signature uploaded successfully!!");
            let url_string = window.location.href
            let url = new URL(url_string);
            let id = url.searchParams.get("id");
            let type = url.searchParams.get("type");
            let btn = document.getElementById("btnSubmit")
            btn.disabled = true
            let inputtype = document.getElementsByClassName("btn")

            if (inputtype) {
                Object.values(inputtype).map((ip, key) => {
                    ip.setAttribute("disabled", true)
                })
            }
            setTimeout(() => history.push('/home'), 500)




            // let inputtype = document.getElementById("inp1")
            // inputtype && inputtype.addEventListener("click", handleImage1);
            // let inputtype2 = document.getElementById("inp2")
            // inputtype2 && inputtype2.addEventListener("click", handleImage2);
            // let img1 = document.getElementById("img1")
            // let img2 = document.getElementById("img2")
            // console.log("inputtype-->errorCheck", inputtype, inputtype2, img1, img2)
            // if (type === "owner") {
            //     if (inputtype2) {
            //         inputtype2.disabled = true
            //         if (img1 && img1.hasAttribute("src")) {
            //             btn.disabled = true
            //             inputtype.disabled = true
            //         }
            //     }

            // }
            // else if (type === "tenant") {
            //     if (inputtype) {
            //         inputtype.disabled = true
            //         if (img2 && img2.hasAttribute("src")) {
            //             btn.disabled = true
            //             inputtype2.disabled = true
            //         }
            //     }

            // }


        }
    }, [prevLeaseAgreementUpdate, props.leaseAgreementUpdate]);


    useEffect(() => {
        async function renderCall() {
            let url_string = window.location.href
            let url = new URL(url_string);
            let id = url.searchParams.get("id");
            await props.getLeaseView({ leaseId: id })
        }
        renderCall();
    }, []);

    useEffect(() => {
        if (props.leaseReducer && props.leaseReducer.leaseAgreement && prevLeaseAgreement != props.leaseReducer.leaseAgreement && props.leaseReducer.leaseAgreement.data && props.leaseReducer.leaseAgreement.data.documentData) {
            async function renderCall() {
                var inputs = document.getElementsByTagName("p");
                for (var i = 0; i < inputs.length; i++) {
                    inputs[i].innerHTML = inputs[i].innerHTML.trim();
                }
                let replaceText = document.getElementsByClassName("text-center");
                let url_string = window.location.href
                let url = new URL(url_string);
                let type = url.searchParams.get("type");
                if (replaceText) {
                    Object.values(replaceText).map((text, key) => {
                        if (text.innerHTML == "**signature_1**") {
                            if (type === "owner") {
                                let div = document.createElement('div')
                                let button = document.createElement('button')
                                button.setAttribute("class", "btn btn-primary")
                                button.innerHTML = "Signature"
                                button.id = `inpOwner${key}`
                                div.append(button)
                                let img = document.createElement('img')
                                img.id = `imgOwner${key}`
                                img.style.height = "55px"
                                div.append(img)
                                text.replaceWith(div);
                            }
                        }
                        if (text.innerHTML == "**signature_2**") {

                            if (type === "tenant") {
                                let div = document.createElement('div')
                                let button = document.createElement('button')
                                button.setAttribute("class", "btn btn-primary")
                                button.innerHTML = "Signature"
                                button.id = `inpTenant${key}`
                                div.append(button)
                                let img = document.createElement('img')
                                img.id = `imgTenant${key}`
                                img.style.height = "55px"
                                div.append(img)
                                text.replaceWith(div);
                            }

                        }
                    })
                    let inputtype = document.getElementsByClassName("btn")
                    if (inputtype) {
                        Object.values(inputtype).map((ip, key) => {
                            ip.addEventListener("click", handleUpload);
                        }
                        )
                    }

                    let btn = document.getElementById("btnSubmit")
                    btn.disabled = true

                }
            }
            renderCall();
        }


    }, [prevLeaseAgreement, props.leaseReducer.leaseAgreement]);


    const handleUpload = (event) => {
        var currentEventTarget = event.currentTarget.parentNode;
        setParams({ currentTarget: currentEventTarget })
        setModal(true)
    }

    const handleImage1 = (e) => {
        setModal(true)
    }

    const handleImage2 = (e) => {
        setModal(true)
    }

    const closeModal = () => {
        setModal(false)
    }

    const renderModal = () => {
        return <UploadMOdal onCancel={closeModal}
            handleImage={handleImage}
            handleSign={handleSign} />
    }

    const handleImage = (e) => {
        setModal(false)
        var FR = new FileReader();
        let url_string = window.location.href
        let url = new URL(url_string);
        let type = url.searchParams.get("type");
        let data = null
        let inputtype = document.getElementsByClassName("btn")
        if (inputtype) {
            Object.values(inputtype).map((ip, key) => {
                let isDisabled = ip.getAttribute('disabled')
                if (!isDisabled) {
                    var img = ip.parentNode.getElementsByTagName("img");
                    if (img) {
                        let imgId = []
                        if (img) {
                            Object.values(img).map((data, key) => {
                                imgId = data.getAttribute('id')
                                FR.addEventListener("load", function (e) {
                                    document.getElementById(imgId).src = e.target.result;
                                });


                            })
                        }
                    }
                }

            })
        }
        FR.readAsDataURL(e.target.files[0]);
        let btn = document.getElementById("btnSubmit")
        btn.disabled = false
    }

    const handleSign = (path, signature) => {
        setModal(false)
        var FR = new FileReader();
        let url_string = window.location.href
        let url = new URL(url_string);
        let type = url.searchParams.get("type");
        let data = null
        let inputtype = document.getElementsByClassName("btn")
        if (inputtype) {
            Object.values(inputtype).map((ip, key) => {
                let isDisabled = ip.getAttribute('disabled')

                if (!isDisabled) {
                    var img = ip.parentNode.getElementsByTagName("img");
                    if (img) {
                        let imgId = []
                        if (img) {
                            Object.values(img).map((dataImg, key) => {
                                imgId = dataImg.getAttribute('id')
                                document.getElementById(imgId).src = path;
                                data = document.getElementById(imgId)
                                signature.fromDataURL(path);

                                //   document.getElementById("b64").innerHTML = e.target.result;

                            })
                        }
                    }
                }

            })
        }
        let btn = document.getElementById("btnSubmit")
        btn.disabled = false
    }



    const handleSubmit = async () => {
        let url_string = window.location.href
        let url = new URL(url_string);
        let id = url.searchParams.get("id");
        let type = url.searchParams.get("type");
        let inputtype = document.getElementsByClassName("btn")
        let replaceText = document.getElementsByClassName("text-center");
        let isAllImageUploaded = true
        Object.values(replaceText).map((ip, key) => {
            if (ip.innerHTML == "**signature_1**" || ip.innerHTML == "**signature_2**") {
                isAllImageUploaded = false
            }
        })
        if (inputtype) {
            Object.values(inputtype).map((ip, key) => {

                let isDisabled = ip.getAttribute('disabled')
                if (isDisabled) ip.removeAttribute("disabled")
                var img = ip.parentNode.getElementsByTagName("img");
                if (img) {
                    Object.values(img).map((dataImg, key) => {
                        let isImage = dataImg.getAttribute('src')
                    })
                }
                ip.parentNode.removeChild(ip)

            })
            if (isAllImageUploaded) {
                let isOwnerSigned = true
                let isTenantSigned = true
                setIsSubmitted(true)
                let data = document.getElementById("ui").innerHTML
                await props.updateLease({ documentData: data.toString(), status: 2, isOwnerSigned, isTenantSigned }, { leaseId: id })
                setIsSubmitted(false)
            }
            else {
                setIsSubmitted(true)
                let isOwnerSigned = false
                let isTenantSigned = false
                if (type == "owner") {
                    isOwnerSigned = true
                }
                else if (type == "tenant") {
                    isTenantSigned = true
                }
                let data = document.getElementById("ui").innerHTML
                await props.updateLease({ documentData: data.toString(), status: 1, isOwnerSigned, isTenantSigned }, { leaseId: id })
                setIsSubmitted(false)
            }
        }


    }





    return (
        <>
            {props.leaseReducer && props.leaseReducer.leaseAgreement ? props.leaseReducer.leaseAgreement.success ?
                <>
                    <div className="agree-outer">
                        {isSubmitted && <LoaderSpinner />}
                        {modal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
                        <header>
                            <div className="col-md-12 heder-section">
                                <div className="container">
                                    <div className="col-md-4">
                                        <img src="/images/agreelogo.png" />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="agree-detail">
                            <div className="text-hed">
                                <div className="container">
                                    <h2>Agreement</h2>
                                </div>
                            </div>
                            <div className="container otr-dtl-section " >
                                {/* <PdfContainer createPdf={createPdf}> */}
                                <div className="content-sec" id="ui" >
                                    {props.leaseReducer && props.leaseReducer.leaseAgreement && props.leaseReducer.leaseAgreement.data ?
                                        ReactHtmlParser(JSON.parse(props.leaseReducer.leaseAgreement.data.documentData)) : null}
                                </div>
                                {/* </PdfContainer> */}
                                {/* <button onClick={() => setModal(true)}>Upload</button> */}
                            </div>
                        </div>
                    </div>
                    <footer>
                        <div className="col-md-12 ftOtr-outr">
                            <div className="container">
                                <div className="ftOtr-prt">
                                    <div className="copy-txt">
                                        All Rights Reserved  cruzcontrol.io
                                    </div>
                                    <div className="btn-ftr">
                                        <button className="btn-sub" id={"btnSubmit"} onClick={() => handleSubmit()}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </> : <ErrorPage /> : null}
        </>

    )
}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer, leaseReducer } = state;
    return {
        commonReducer,
        tenantReducer,
        leaseReducer,
        leaseAgreementUpdate: leaseReducer.leaseAgreementUpdate
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction, ...tenantAction })(LeaseAgreement);
