import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/Common/components/privateRoute";
import Login from "./components/Login";
import PublicRoute from "./components/Common/components/publicRoute";
import history from "./config/history";
import DashBoard from "./components/DashBoard/Admin";
import Index from "./components/Properties";
import Tenants from "./components/Tenants/index.jsx";
import TenantDetail from "./components/Tenants/components/TenantDetail.jsx";
import Invoice from "./components/Invoice";
import Expense from "./components/Expense";
import Leases from "./components/Leases&Files";
import Maintenance from "./components/Maintenance";
import Messaging from "./components/Messaging";
import Board from "./components/Board";
import MessageDetails from "./components/Messaging/components/details";
import { connect } from "react-redux";
import action from "./components/Login/action";
import PropertyDetails from './components/Properties/components/PropertyDetails';
import Settings from './components/Settings'
import Profile from './components/Profile'
import Password from './components/Common/components/passwordSet';
import TenantHome from './components/DashBoard/Tenant';
import TenantProperty from './components/Properties/Tenant';
import TenantInvoice from './components/Invoice/TenantComponents';
import MaintenanceTenant from './components/Maintenance/Tenant';
import Add_Maintenance from './components/Maintenance/components/AddMaintenance/addMaintenanceModified';
import Add_Maintenance_Tenant from './components/Maintenance/components/AddMaintenance/addMaintenanceTenantModified';
import Report from "./components/Report";
import AdminUserHome from './components/DashBoard/AdminUser';
import MaintenanceHome from './components/DashBoard/Maintenance';
import ScreeningPublic from './components/ScreeningPublic';
import ScreeningAdd from './components/ScreeningPublic/components/addScreening';
import ScreeningAddPublic from './components/ScreeningPublic/components/addScreeningPublic';

import Users from './components/Settings/components/Users';
import MailSettings from './components/Settings/components/MailSettings';

import DataSource from './components/Plaid/components/dataSources';
import PropertyHeader from "./components/Properties/components/PropertyDetails/PropertyHeader";
import Transaction from './components/Plaid/components/Transactions/index'
import Plaid from './components/Plaid';
import SupportTicket from './components/Maintenance/SupportTickets/Components';
import AutoPay from './components/Settings/components/AutoPay';
import BankAccount from './components/Settings/components/cards';
import PaymentHistory from './components/Settings/components/PaymentHistory';
import LeaseTenant from './components/Leases&Files/Tenant/index';
import Agreement from './components/Leases&Files/components/leaseAgreementView';
import Error from "./components/Common/components/error"
import profileAction from "./components/Profile/action"
import InvoiceTransaction from "./components/Expense/components/invoiceTransaction"

class App extends React.Component {


    render() {
        let role = this.props.profileDetails && this.props.profileDetails.profileDetail && this.props.profileDetails.profileDetail.admin.role;
        return (
            <Router history={history}>
                <Switch>

                    <PublicRoute exact path="/" component={Login} />
                    <PublicRoute exact path="/password/set" component={Password} />
                    <PublicRoute exact path="/Settings/leaseview" component={Agreement} />

                    {/* --- ---- --- PRIVATE PAGES --- --- ---*/}
                    <PrivateRoute exact path="/"
                        component={role && role === "tenant" ? TenantHome : (role && role === "adminuser" ? AdminUserHome : (role && role === "maintenance" ? MaintenanceHome : DashBoard))} />
                    <PrivateRoute exact path="/home"
                        component={role && role === "tenant" ? TenantHome : (role && role === "adminuser" ? AdminUserHome : (role && role === "maintenance" ? MaintenanceHome : DashBoard))} />
                    <PrivateRoute exact path="/Profile" component={Profile} />
                    <PrivateRoute exact path="/Properties/:section"
                        component={role && role === "tenant" ? TenantProperty : Index} />
                    <PrivateRoute exact path="/PropertyDetails/:id" component={PropertyDetails} />
                    <PrivateRoute exact path="/Tenants" component={Tenants} />
                    <PrivateRoute exact path="/Tenants/:id/:section" component={TenantDetail} />
                    <PrivateRoute exact path="/Invoice/:section"
                        component={role && role === "tenant" ? TenantInvoice : Invoice} />
                    <PrivateRoute exact path="/Report" component={Report} />
                    <PrivateRoute exact path="/Expense" component={Expense} />
                    <PrivateRoute exact path="/Leases" component={role && role === "tenant" ? LeaseTenant : Leases} />
                    <PrivateRoute exact path="/Maintenance"
                        component={role && role === "tenant" ? MaintenanceTenant : Maintenance} />
                    <PrivateRoute exact path="/Messaging"
                        component={role && role === "tenant" ? Messaging : MessageDetails} />
                    <PrivateRoute exact path="/Messaging/Email/Inbox" component={Messaging} />
                    <PrivateRoute exact path="/Messaging/Email/Sent" component={Messaging} />
                    <PrivateRoute exact path="/Messaging/Sms" component={Messaging} />
                    <PrivateRoute exact path="/Messaging/Details/:id" component={MessageDetails} />
                    <PrivateRoute exact path="/Settings" component={Settings} />
                    <PrivateRoute exact path="/Planner" component={Board} />
                    <PrivateRoute exact path="/Users" component={Users} />
                    <PrivateRoute exact path="/Email/Settings" component={MailSettings} />
                    <PrivateRoute exact path="/InvoiceTransaction" component={Expense} />


                    <PrivateRoute exact path='/DataSource' component={DataSource} />
                    <PrivateRoute exact path='/Transaction' component={Expense} />
                    <PrivateRoute exact path='/Plaid' component={Plaid} />
                    <PrivateRoute exact path='/Add-Maintenance' component={Add_Maintenance} />
                    <PrivateRoute exact path='/Add-Maintenance/:maintenaceId' component={Add_Maintenance} />
                    <PrivateRoute exact path='/Add-Maintenance-Tenant' component={Add_Maintenance_Tenant} />
                    <PrivateRoute exact path='/Add-Maintenance-Tenant/:maintenaceId' component={Add_Maintenance_Tenant} />
                    <PrivateRoute exact path="/SupportTicket/:id/:section" component={SupportTicket} />
                    <PrivateRoute exact path='/Auto-Pay' component={AutoPay} />
                    <PrivateRoute exact path='/Accounts/:section' component={BankAccount} />
                    <PrivateRoute exact path='/PaymentHistory' component={PaymentHistory} />
                    <PrivateRoute exact path='/Tenant-Maintenance' component={MaintenanceTenant} />

                    {/* --- --- --- PUBLIC PAGES --- --- --- */}
                    <PublicRoute exact path="/Screening" component={ScreeningPublic} />
                    <PublicRoute exact path="/Screening-Add" component={ScreeningAdd} />
                    <PublicRoute exact path="/Screening-AddDetails/:email" component={ScreeningAddPublic} />

                    <Route component={Error} />

                </Switch>
            </Router>

        );
    }

}


const mapStateToProps = state => {
    return {
        user: state.loginReducer.User,
        profileDetails: state.profileReducer.profileDetail
    };
};

export default connect(mapStateToProps, {
    ...action, ...profileAction
})(App);
