import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import action from "../../action";
import Navigation from './Navigation';
import AddNote from './AddNote'
import MailDetails from './mailDetails';
import history from '../../../../config/history'
import ClientLog from './ClientLog';
import OtherTickets from './OthersTickets'
import Options from './Options'
import renderHTML from "react-render-html";
import messageAction from '../../../Messaging/action'

const SupportTicket = (props) => {
    const {
        match: {
            params: { id, section }
        }
    } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [mail, setMail] = useState({});


    useEffect(() => {
        props.getMaintenanceById(id)
        // props.refreshMail()
    }, [])

    const handleTab = (tab) => {
        switch (tab) {
            case 'AddReplay':
                history.push(`/SupportTicket/${id}/AddReplay`)
                break;
            case 'AddNote':
                history.push(`/SupportTicket/${id}/AddNote`)
                break;
            case 'OtherTickets':
                history.push(`/SupportTicket/${id}/OtherTickets`)
                break;
            case 'ClientLog':
                history.push(`/SupportTicket/${id}/ClientLog`)
                break;
            case 'Options':
                history.push(`/SupportTicket/${id}/Options`)
                break;
            case 'Log':
                history.push(`/SupportTicket/${id}/Log`)
                break;
            default:
                history.push(`/SupportTicket/${id}/AddReplay`)
                break;
        }
    }

    const renderComponanent = () => {
        switch (section) {
            case "AddReplay":
                return <AddNote maintenanceId={id} isNote={false} isEdit={isEdit} mail={mail} resetEdit={resetEdit} />
            case "AddNote":
                return <AddNote isNote={true} maintenanceId={id} isEdit={isEdit} mail={mail} resetEdit={resetEdit} />
            case "OtherTickets":
                return <OtherTickets maintenanceId={id} />
            case "ClientLog":
                return <ClientLog maintenanceId={id} />
            case "Options":
                return <Options maintenanceId={id} />
            case "Log":
                return <AddNote />
        }
    }

    const handleEdit = async (e,mail) => {
        e.preventDefault()
        window.scroll(0, 0)
        setIsEdit(true)
        setMail(mail)
        if(mail.type=="note"){
            history.push(`/SupportTicket/${id}/AddNote`)
        }
        else{
            history.push(`/SupportTicket/${id}/AddReplay`)
        }
    }

    const resetEdit = () => {
        setIsEdit(false)
        setMail(null)
    }

    const updateMaintenance = async () => {
        let data = {
            _id: id,
            status: 0,
            maintenancePicture: props.maintenanceDetails.maintenanceTickets.maintenancePicture,
            maintenanceVideo: props.maintenanceDetails.maintenanceTickets.maintenanceVideo,

        }
        await props.maintenanceUpdate(data)
        await props.getMaintenanceById(id)
    }


    return (
        <div className="tenants-otr supportTicket">
            <div className="innago-table-top main-table-top">
                <div className="lft">
                    <h1>SUPPORT TICKETS</h1>
                </div>
            </div>
            <div className="ticketHeader">
                <h3>

                    <div>
                    <span className={"tktNo"}>{props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets && props.maintenanceDetails.maintenanceTickets.maintenanceTicketNumber
                            ? ` #${props.maintenanceDetails.maintenanceTickets.maintenanceTicketNumber}  ` : null}</span>
                        {props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets && `- ${props.maintenanceDetails.maintenanceTickets.title}`} 
                    </div>
                </h3>
                <div className="ticketRight">
                    {/*<div className="selctOtr">*/}
                    {/*    <select className="form-control">*/}
                    {/*        <option value="">Select Status</option>*/}
                    {/*        <option value="All">All</option>*/}
                    {/*        <option value="0">In Progress</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    {props.maintenanceDetails && props.maintenanceDetails.handleEditmaintenanceTickets && props.maintenanceDetails.maintenanceTickets.status == 0 ?
                        <button className="btn btn-primary closeBtn" disabled={true}>Closed</button> :
                        <button className="btn btn-primary closeBtn" onClick={updateMaintenance}>Close</button>
                    }
                </div>
            </div>
            <Navigation handleTab={handleTab} section={section} />
            <div className="tab-content transaction-tab" id="pills-tabContent">
                {renderComponanent()}
                <MailDetails maintenanceId={id} maintenanceDetails={props.maintenanceDetails} handleEdit={handleEdit} />
            </div>
        </div>
    )


}

const mapStateToProps = state => {
    const { maintenanceReducer } = state;
    return {
        maintenanceReducer,
        maintenanceDetails: maintenanceReducer.maintenanceDetailsById
    };
};

export default connect(mapStateToProps, {
    ...action, ...messageAction
})(SupportTicket);
