import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
    "closeButton": true,
    "debug": true,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "4000",
    "extendedTimeOut": 0,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
    "tapToDismiss": false
}

// toastr.info('Your Message!!', 'Toast Header')
// toastr.warning('Your Message!!', 'Toast Header')
// toastr.success('Your Message!!', 'Toast Header')
// toastr.error('Your Message!!', 'Toast Header')

// // Immediately remove current toasts without using animation
// toastr.remove()

// // Remove current toasts using animation
// toastr.clear()

// // Override global options
// toastr.success('Your Message!!', 'Toast Header', {timeOut: 5000})


// Prevent from Auto Hiding

// toastr.options.timeOut = 0;
// toastr.options.extendedTimeOut = 0;

export default toastr
