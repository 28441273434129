import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import commonAction from '../../Common/action';

import {connect} from "react-redux";
import action from "../../Invoice/action";
import tenantAction from "../../Tenants/action";
import CommonDatePicker from "../../Common/components/CommonDatePicker";

const LeaseFilter = (props) => {
    const [params, setParams] = useState({
        property: null,
        unit: null,
        from: null,
        to: null,
        propertyDetails:"",
        status:null
    })

    useEffect(() => {
        async function renderCall() {
            await props.getProperty()
            await props.getAllUnits()
        }

        renderCall();
    }, []);


    const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));


    const filterData = async () => {

        await props.filterLease(props.params)
    }




    return (
        <div className="filterSide fltrMaint" style={{display: 'block'}}>
            <div className="formSection outerBg">
                <button type="button" className="close" onClick={props.closeFilter}><span aria-hidden="true">×</span></button>
                <div className="col-md-12 row m-0 p-0 fltrOtr">
                    <div className="col-md-6 formInp mb-3">
                        <label>Property</label>
                        {/*<div className="selOtr">*/}
                            {/*<div className="selOtr">*/}
                                {/*<select className="form-control" name="property"*/}
                                        {/*onChange={handleChange} value={params.property}*/}
                                {/*>*/}
                                    {/*<option default>Select Property</option>*/}
                                    {/*{*/}
                                        {/*Properties && Properties.map((e, i) => {*/}
                                            {/*return <option value={e.value} key={i}>{e.label}</option>*/}
                                        {/*})*/}
                                    {/*}*/}
                                {/*</select>*/}
                            {/*</div>*/}
                            <AsyncSelect
                                cacheOptions
                                defaultOptions={Properties}
                                value={props.params.propertyDetails}
                                loadOptions={props.promiseOptionsProperty}
                                name="property"
                                onChange={props.handleChangeProperty}
                            />
                        {/*</div>*/}
                    </div>
                    <div className="col-md-6 formInp mb-3">
                        <label>Unit</label>
                        {props.commonReducer.unitDetails && props.commonReducer.unitDetails.unitList &&
                        props.commonReducer.unitDetails.unitList.length ?
                            <div className="selOtr">

                                <select className="form-control" name={"unit"}
                                        value={props.params.unit}
                                        onChange={props.handleChange}>
                                    <option>Select Unit</option>
                                    {props.commonReducer.unitDetails.unitList.map((unit, key) => {
                                        return <option value={unit._id} key={key}>{unit.unitName}</option>
                                    })}

                                </select></div> :null
                        }
                </div>
                <div className="col-md-4 formInp">
                    <label>Status</label>
                    <div className="selOtr">
                        <select className="form-control" name={"status"} onChange={props.handleChange}
                                value={props.params.status}>
                            <option>Select Status</option>
                            <option value={0}>Not send</option>
                            <option value={1}>In Process</option>
                            <option value={2}>Active</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4 formInp">
                    <label>From</label>
                    <CommonDatePicker
                        name="from"
                        placeholder="From Date"
                        format="YYYY-MM-DD"
                        className="form-control"
                        onChange={(value) => props.handleChangeDate(value,"from" )}
                        value={props.params.from}
                        min={new Date()}
                    />
                </div>
                <div className="col-md-4 formInp">
                    <label>To</label>
                    <CommonDatePicker
                        name="to"
                        placeholder="From Date"
                        format="YYYY-MM-DD"
                        className="form-control"
                        onChange={(value) => props.handleChangeDate(value,"to" )}
                        value={props.params.to}
                        min={new Date()}
                    />
                </div>

            </div>

            <div className="btnOtr text-right">
                <button type="button" className="btn btn-primary btnClear" onClick={props.clearFilterData}>Clear
                </button>
                <button type="button" className="btn btn-primary btnApply" onClick={filterData}>Apply
                </button>
            </div>
        </div>
        </div>

    )
}

function mapStateToProps(state) {
    const {commonReducer, tenantReducer} = state;
    return {
        commonReducer,
        tenantReducer
    };
}

export default connect(mapStateToProps, {...action, ...commonAction, ...tenantAction})(LeaseFilter);
