import React, { useEffect, useState } from "react";
import action from "../action";
import { connect } from "react-redux";
import AmountDetails from './AmountDetailsTenant';
import InvoiceListing from './InvoiceList';
import history from '../../../config/history';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from "../../Common/components/pagination";
import dashboardAction from '../../DashBoard/action';
import toastr from '../../Common/components/Toaster';
import Modal from "../../Common/components/Modal";
import CardList from './cardList'

const Invoice = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    const [params, setParams] = useState({
        limit: 15, offset: 0, invoiceTypeId: null, sort: null
    })
    const [currentPage, setcurrentPage] = useState(1);
    const [paymentSubmit, setPaymentSubmit] = useState(false);
    const [cardModal, setCardModal] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null)


    const {
        match: {
            params: { section }
        }
    } = props;


    useEffect(() => {
        window.scroll(0, 0)
        // history.push('/Invoice/All')
        async function renderCall() {
            await props.getTenantInvoiceTypes();
            await props.getTenantInvoices(params)
        }

        renderCall();
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }, []);

    useEffect(() => {
        if (paymentSubmit && props.payment.success)
            toastr.success(props.payment.message)
        else
            if (paymentSubmit && !props.payment.success)
                toastr.error(props.payment.message)
    }, [props.payment])

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getTenantInvoices(data);
    }


    const handleTabs = async (tab, id) => {
        let sectionTemp = tab.replace(/ +/g, "")

        switch (sectionTemp) {
            case 'All':
                history.push('/Invoice/All');
                break;
            case 'Private':
                history.push('/Invoice/Private');
                break;
            case 'Trillium':
                history.push('/Invoice/Trillium');
                break;
            case 'Section8':
                history.push('/Invoice/Section8');
                break;
            case 'BenefitsManagement':
                history.push('/Invoice/BenefitsManagement');
                break;
            case 'SecurityDeposit':
                history.push('/Invoice/SecurityDeposit');
                break;
            case 'Fees':
                history.push('/Invoice/Fees');
                break;
        }

        let paramsTemp = { ...params };
        paramsTemp.invoiceTypeId = id;
        paramsTemp.offset = 0;
        paramsTemp.limit = 15;
        window.scroll(0, 0)
        setParams(paramsTemp)
        await props.getTenantInvoices(paramsTemp);
    }

    const filterData = async (value) => {
        let tempParams = params
        if (value) {
            tempParams.sort = value
            let orderDetails = !params.orderData
            tempParams.order = orderDetails ? "ASC" : "DESC"
            tempParams.orderData = !params.orderData
        }
        setParams(tempParams)
        setIsLoading(true)
        await props.getInvoice(tempParams)
        setTimeout(() => {
            setIsLoading(false)

        }, 1000)
    }

    const { InvoiceList, invoiceTenantTypes } = props;
    const invoice = invoiceTenantTypes && invoiceTenantTypes.map(e => ({
        id: e.invoiceTypeId && e.invoiceTypeId._id,
        name: e.invoiceTypeId && e.invoiceTypeId.name
    }));
    const typeArray = Array.from(new Set(invoice && invoice.map(e => e.id)))
        .map(id => {
            return {
                id: id,
                name: invoice.find(s => s.id == id).name
            }
        });

    const makePayment = async (id) => {
        setInvoiceId(id)
        setCardModal(true)


    }

    const payInvoice = async () => {
        setPaymentSubmit(true);
        setCardModal(false)
        setIsLoading(true)
        await props.makeMultiplePayment({ invoiceId: [invoiceId] });

        setTimeout(() => {
            props.getTenantInvoices(params)
            setPaymentSubmit(false)
            setIsLoading(false)

        }, 1000)
    }

    const closeCardModal = () => {
        setCardModal(false)
    }

    const renderCardList = () => {
        const { profileDetail: { profileDetail } } = props;
        return <CardList
            onCancel={closeCardModal}
            id={profileDetail.admin.tenantId._id}
            payInvoiceData={payInvoice}
            tenatDetailView={false}
        />
    }

    return (
        <div className="tenants-otr">
            {(isLoading || paymentSubmit) && <LoaderSpinner />}
            {cardModal ? <Modal body={renderCardList()} onCancel={closeCardModal} /> : null}
            <div className="tenants-inner">
                <div className="innago-table-top main-table-top">
                    <div className="lft">
                        <h1>INVOICE DETAILS</h1>
                    </div>
                </div>
                {props.tenant && <AmountDetails
                    paid={props.Invoice.paidAmount}
                    invoiceList={props.Invoice}
                    tenantSection={true}
                    rent={props.Invoice && props.Invoice.tenant && props.Invoice.tenant.tenantId} />}
                <section className="tenant-tabs">
                    <div className="row p-0 m-0">
                        <div className="col-md-12 tab-shadow">
                            <nav>
                                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                    <a
                                        className={section === 'All' ? "nav-item nav-link active" : "nav-item nav-link"}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleTabs('All')}
                                        aria-selected="true">All</a>
                                    {typeArray.length ? typeArray.map((type, key) => {

                                        return <a
                                            className={section === (type.name.replace(/ +/g, "")) ? "nav-item nav-link active" : "nav-item nav-link"}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleTabs(type.name, type.id)}
                                            aria-selected="true" key={key}>{type.name}</a>
                                    }) : null}
                                </div>
                            </nav>
                        </div>

                        <div className="col-md-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-private" role="tabpanel"
                                    aria-labelledby="nav-private-tab">

                                    <div className="innago-table table-main invoiceTable invforTentTbl">
                                        <InvoiceListing
                                            InvoiceList={InvoiceList}
                                            offset={params.offset}
                                            makePayment={makePayment}
                                            invoice={props.Invoice}
                                            tenantView={false}
                                            count={props.Invoice.invoiceCount}
                                        />

                                        <div className="paginationSec">
                                            {props.Invoice.invoiceCount ? <Pagination
                                                totalRecords={props.Invoice.invoiceCount}
                                                pageLimit={params.limit}
                                                pageNeighbours={1}
                                                currentPage={currentPage}
                                                onPageChanged={onPageChanged}
                                            /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { invoiceReducer, dashboardReducer, profileReducer } = state;
    return {
        Invoice: invoiceReducer.InvoiceTenantList,
        InvoiceList: invoiceReducer.InvoiceTenantList.invoice,
        invoiceTenantTypes: invoiceReducer.invoiceTenantTypes.invoice,
        tenant: invoiceReducer.InvoiceTenantList.tenant,
        profileDetail: profileReducer.profileDetail,
        invoiceReducer,
        payment: dashboardReducer.payment
    };
}

export default connect(mapStateToProps, { ...action, ...dashboardAction })(Invoice);
