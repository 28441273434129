import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//add expense details
export const addExpense = (value) =>
    innagoGateWay.post(serviceEndpoints.expenseEndpoints.expenses, value);

//update expense details
export const updateExpense = (value) =>
    innagoGateWay.put(serviceEndpoints.expenseEndpoints.expenses, value);

//get expense details
export const getExpense = (params) =>
    innagoGateWay.get(serviceEndpoints.expenseEndpoints.expenses, {params});

    //get invoice details
export const getInvoiceTransaction = (params) =>
innagoGateWay.get(serviceEndpoints.expenseEndpoints.getInvoiceTransaction, {params});
    