import React from "react";
import moment from "moment";

class IncomingMessage extends React.Component {

    render() {
        const {messageDetails} = this.props;
        return (
            <div className="incomingMsg">
                <div className="incomingMsgName">
                    <i className="fas fa-user"/>
                </div>
                <div className="receivedMsg">
                    <p>{messageDetails.message}</p>
                    <span className="time">{moment(messageDetails.createdAt).format('hh:mm  A')}</span>
                </div>
            </div>
        )
    }
}

export default IncomingMessage;