import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import commonAction from '../../../Common/action'
import * as validation from '../../../Common/components/validation';
import action from '../../action';
import LoaderSpinner from "../../../Common/components/Loader";
import toastr from '../../../Common/components/Toaster';
import history from '../../../../config/history';
import { formatUsPhone } from "../../../Common/components/utils";

const AddExpense = (props) => {
    const [userForm, setUserForm] = useState({
        id: '',
        email: "",
        alternateEmailsId: null,
        tenantId: null
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [role, setRole] = useState('');

    useEffect(() => {
        const { emailData } = props;
        props.getPayment()
        const { profileReducer: { profileDetail } } = props;
        let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
        setRole(role);

        if (emailData)
            setUserForm({
                tenantId: props.id,
                alternateEmailsId: emailData.emailAddress ? emailData._id : null,
                email: emailData.emailAddress ? emailData.emailAddress : emailData.email,
            })
    }, []);

    const { settingsReducer: { addUser } } = props;

    useEffect(() => {
        if (submit && !addUser.success) {
            toastr.error(addUser.message)
        } else if (submit && addUser.success) {
            toastr.success(addUser.message);
        }
    }, [addUser]);

    const handleChange = async (e) => {
        const { name, value } = e.target
        if (name === "phoneNumber") {
            setUserForm({ ...userForm, [name]: formatUsPhone(value) })

        } else {
            setUserForm({ ...userForm, [name]: value })
        }
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
                case "email":
                    return (
                        validation.EMAIL(value, name) != true ?
                            <div className={"errorMsg"}>*{validation.EMAIL(value, name)}</div> : null
                    )
                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) ?
                            <div className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div>
                            :
                            null
                    )
                case "password":
                    return (validation.PASSWORD(value, name) !== true ?
                        <div className={"errorMsg"}>{validation.PASSWORD(value, name)}</div>
                        :
                        null)

                case "letter":
                    return validation.LETTER_ONLY(value, name) &&
                        <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "password match":
                    return (
                        validation.UNMATCHED_PASSWORDS(value, name) != true ? <div className={"errorMsg"}>
                            {validation.UNMATCHED_PASSWORDS(value, name)}
                        </div> : null
                    );

            }
        } else return null;
    };

    const handleChangeUser = (e, value) => {
        if (e.target.checked) {
            setUserForm({ ...userForm, role: value })
        } else {
            setUserForm({ ...userForm, role: null })
        }
    }

    const addTenantEmailAddress = async () => {
        setIsSubmitted(true);
        const { email, id, alternateEmailsId } = userForm;
        if (email && validation.EMAIL(email, 'email') == true) {
            setSubmit(true)
            let userData = {
                _id: props.id,
                tenantId: props.id,
                alternateEmailsId: alternateEmailsId,
                emailAddress: email,
            }
            await props.addEmailAddress(userData)
            setTimeout(() => {
                setIsSubmitted(false)
                setSubmit(false)
                props.onCancel()
            }, 1000)
            await props.getTenantEmail(props.params);
        }
    }

    return (
        <div className="modal  addTenant addInvoiceModal addpsw" id="modalId" style={{ display: "block" }}>
            {submit && <LoaderSpinner />}
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"
                            id="exampleModalLabel">{props.emailData ? "Update Mail" : "Add Mail"}</h5>
                        <button type="button" className="close" onClick={props.onCancel}>
                            {/* <h3>Add User</h3> */}
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <div className="formSection">
                                <div className="row outrForm p-0 m-0">
                                    <div className="col-md-12 FormrightOutr">
                                        <div className="row">

                                            <div className="col-md-12 formInp formSecInp">
                                                <label>Email</label>
                                                <input type="text" className="form-control"
                                                    autoComplete={false}
                                                    placeholder="Enter Email"
                                                    value={userForm.email}
                                                    name={"email"} onChange={handleChange} />
                                                {validate(userForm.email, "Email Address", "required")}
                                                {userForm.email && validate(userForm.email, "Email Address", "email")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit"
                            onClick={addTenantEmailAddress}
                        >{props.emailData ? "Update Mail" : "Add Mail"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(userForm) {
    const { settingsReducer, commonReducer, profileReducer } = userForm;
    return {
        commonReducer,
        settingsReducer,
        profileReducer
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction })(
    AddExpense
);
