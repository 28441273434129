import React from "react";
import { numberWithCommas } from "../../Common/components/utils";

const AmountDetails = (props) => {
    return (
        <div className="amountSection">
            <div className="row">
                <div className="col-md-12">
                    <div className="amountOuter">
                        <div className="col-md-3">
                            <div className="paid-amount unpaid-line">
                                <h3>Total Unpaid</h3>
                                <p className="unpaid-color">${props.unPaid ?
                                    numberWithCommas(props.unPaid) || 0.00
                                    : props.invoiceList && numberWithCommas(props.invoiceList.unpaidAmount) || 0.00}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="paid-amount ">
                                <h3>Total Paid</h3>
                                <p>${props.paid ?
                                    numberWithCommas(props.paid) || 0.00
                                    : props.invoiceList && numberWithCommas(props.invoiceList.paid) || 0.00}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="paid-amount overdue">
                                <h3>Total Overdue</h3>
                                <p className={"overdue"}>${props.overdueAmount ? numberWithCommas(props.overdueAmount) || 0.00
                                    : props.invoiceList && numberWithCommas(props.invoiceList.overdueAmount) || 0.00}</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="paid-amount monthly-rent">
                                <h3>Rent Amount</h3>
                                <p className="montly-color">
                                    ${numberWithCommas(props.rent && props.rent.rent &&
                                        props.rent.rent[0] ? props.rent.rent[0].amount:
                                        (props.rent && props.rent.unitId&&props.rent.unitId.monthlyRent))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AmountDetails;
