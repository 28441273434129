import React from "react";
import {connect} from 'react-redux';
import action from "../action";
import MessagingFilter from "./messagingFilter"
import {withRouter} from "react-router-dom"
import history from "../../../config/history";
import queryString from 'query-string'
import Modal from '../../Common/components/Modal';
import NewMessageModal from './newMessageModal';
import settingAction from '../../Settings/action'
import components from "react-trello/dist/components";
import ChangePassword from "../../Profile/components/changePassword";

class PageTitle extends React.Component {

    componentDidUpdate = () => {
        if (this.props.settingsReducer.addMailAccRes && this.props.settingsReducer.addMailAccRes.url) {
            if (typeof (this.props.settingsReducer.addMailAccRes.url.data) !== 'undefined' && this.props.settingsReducer.addMailAccRes.url.data) {
                window.location.href = this.props.settingsReducer.addMailAccRes.url.data;
            }
        }
    }

    componentDidMount=async ()=> {
        const {location: {search}} = this.props;
        const urlDataQuery = queryString.parse(search);
        let userData=JSON.parse(localStorage.getItem('userDetails'))
        let userId=userData.data.user._id
        if (urlDataQuery && urlDataQuery.code && urlDataQuery.code.length) {
            await this.props.addMailAccountCode(urlDataQuery.code,userId);
           history.push('/Messaging/Email/Inbox')
        }
    }

    onCloseModalNewMessage = () => {
        history.push(`${this.props.match.url}`)
    };

    renderNewMessageBody = () => {
        return <NewMessageModal
            onCancel={this.onCloseModalNewMessage}
        />
    };

     addMailAccount = async () => {
        await this.props.addMailAccount({type:'tenant'});
    };

    renderModalNewMessage = () => {
        if (!this.props.location) return null;
        const {location: {search}} = this.props;
        const query = queryString.parse(search);

        if (query.compose) {
            return <Modal
                onCancel={this.onCloseModalNewMessage}
                body={this.renderNewMessageBody()}
            />
        }
        return null;
    };
    refreshMails=async ()=>{
        this.props.setLoding()

    }

    render() {
        const {history, location: {pathname}, updateChatReceiver} = this.props;

        let userRole=this.props.profileReducer.profileDetail.profileDetail && this.props.profileReducer.profileDetail.profileDetail.admin.role
        return (
            <div className="innago-table-top">
                <div className="lft">
                    <h1>{(pathname === '/Messaging/Email/Inbox' || pathname === '/Messaging/Email/Sent' || pathname === '/Messaging') ? 'MESSAGING' : 'CHAT'}</h1>
                </div>
                <div className="rgt">
                    {/*{userRole=='tenant'?*/}
                    {/*    <button className="add" onClick={() => this.addMailAccount()} disabled={true}>*/}
                    {/*        <i className="fas fa-plus" />*/}
                    {/*        Add Google Account*/}
                    {/*    </button>*/}
                    {/*:*/}
                        {/* <button className="add" onClick={() => this.addMailAccount()} >
                        <i className="fas fa-plus" />
                        Add Google Account
                    </button> */}
                    <button className="add" onClick={() => this.refreshMails()} >
                        <i className="fas fa-redo" />
                        Refresh
                    </button>
                    {/*}*/}
                    {pathname !== '/Messaging/Sms'
                        ? <button className="add" onClick={() => history.push(`${this.props.match.url}?compose=true`)}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-add"/>
                            </svg>
                            New Email
                        </button>
                        : <button className="add" onClick={() => updateChatReceiver()}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-add"/>
                            </svg>
                            New Chat
                        </button>
                    }

                    {this.renderModalNewMessage()}
                    {(pathname === '/Messaging/Email/Inbox' || pathname === '/Messaging/Email/Sent' || pathname === '/Messaging')
                        ? <MessagingFilter updateFilterParams={this.props.updateFilterParams}/>
                        : null
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {...state}
};

export default withRouter(connect(mapStateToProps, {...action, ...settingAction})(PageTitle));