import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import history from '../../../config/history';
import action from '../action';
import toastr from '../../Common/components/Toaster';
import profileAction from '../../Profile/action'
import LoaderSpinner from "../../Common/components/Loader";
//import tenantAction from ''

const AddAutoPay = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuto, setIsAuto] = useState(false);
  const [changeAuto, setChangeAuto] = useState(false);
  const { profileDetail: { profileDetail } } = props;
  let cardAdded = 0;
  if (profileDetail)
    cardAdded = profileDetail.admin.tenantId.cardDetails && profileDetail.admin.tenantId.cardDetails.length;

  useEffect(() => {
    async function renderCall() {
      if (profileDetail)
        props.getAutoPay({ tenantId: profileDetail.admin.tenantId._id })
      // props.getProfile()
    }
    renderCall();
    setTimeout(() => setIsLoading(false), 1000)
    
  }, [props.profileDetail])

  const changeAutoPay = async (e) => {
    setChangeAuto(true)

    if (cardAdded > 0) {
      if (e.target.checked) {
        setIsAuto(true)

      }
      else
        setIsAuto(false)
      await props.autoPay(
        {
          tenantId: profileDetail.admin.tenantId._id,
          status: e.target.checked ? 1 : 0
        }
      );
      await props.getAutoPay({ tenantId: profileDetail.admin.tenantId._id })
    }
    else {
      toastr.error("Please add your card details");
    }
  }

  return (
    <div className="tenants-otr">
      {isLoading&&<LoaderSpinner/>}
      <div className="innago-table-top">
        <div className="lft">
          <h1>AUTO PAY</h1>
        </div>
      </div>
      <div className="autopay-outer">
        <div className="auto-pay-wrapper">
          <div className="turn-on-sec">
            <div className="auto-pay-header">
              <div className="left-head">
                <h2>Turn on auto pay</h2>
                <p>Auto pay settings only apply to rental invoice</p>
              </div>
              <div className="switchToggle">
                <input type="checkbox" id="switch"
                  onChange={(e) => changeAutoPay(e)}
                  checked={changeAuto ? isAuto : props.settingsReducer.autoPay.success} />
                <label for="switch">Toggle</label>
              </div>
            </div>
            <div className="row custm-margn">
              <div className="col-md-6 m-auto" onClick={() => history.push('Accounts/Cards')}>
                <div className="payment-box">
                  {/* <div  className="customRadioBtn">
                            <label  className="radioBtn">
                              <input type="radio"/>
                              <span  className="radiocheckmark"></span>
                            </label>
                          </div> */}
                  <i className="far fa-credit-card"></i>
                  <h3>Credit/Debit Card</h3>
                  {/* <h5>A 2.75% fee will be added</h5> */}
                  <p>Pay using a Credit or Debit Card</p>
                </div>
              </div>
              {/* <div  className="col-md-6" 
                      //onClick={()=>history.push('Accounts/Banks')}
                      >
                        <div  className="payment-box">
                          <div  className="customRadioBtn">
                            <label  className="radioBtn">
                              <input type="radio"/>
                              <span  className="radiocheckmark"></span>
                            </label>
                          </div>
                          <i  className="fas fa-university"></i>
                          <h3>Bank Account</h3>
                          <h5>At max, a$2.00 Fee will be added</h5>
                          <p>Pay directly from a checking or saving account</p>
                        </div>
                      </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { settingsReducer, profileReducer } = state;
  return {
    settingsReducer,
    profileDetail: profileReducer.profileDetail,
  };
};

export default connect(mapStateToProps, { ...action, ...profileAction })(AddAutoPay);

