import React, { useState, useEffect } from "react";
import action from "../action";
import tenantAction from "../../Tenants/action";
import invoiceActions from "../../Invoice/action"
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import usePrevious from "../../Common/components/prevProps";


import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';
import history from '../../../config/history';
import TenantInvoiceNew from "../../Tenants/components/Invoice/TenantInvoiceNew"
import CommonDatePicker from '../../Common/components/CommonDatePicker'

import moment from 'moment';
import { numberWithCommas } from "../../Common/components/utils";

const PaymentHistory = (props) => {

    const [paymentModal, setPaymentModal] = useState('');
    const [detail, setDetail] = useState('');
    const [id, setId] = useState('');
    const [InvoiceModal, setInvoiceModal] = useState(false);
    const [deleteInvoices, setDeleteInvoices] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [editData, setEditData] = useState({
        tenantId: null,
        amount: null,
        startDate: null,
        dueDate: null,
        invoiceTypeId: null,
        paymentTypeId: null,
        isRecurring: false,
        status: 0,
        isEdit: false
    })
    const [params, setParams] = useState({ limit: 10, offset: 0, startDate: (moment(new Date()).startOf('day').toISOString()), endDate: moment(new Date()).endOf('day').toISOString() })
    const [currentPage, setcurrentPage] = useState(1);

    const prevInvoiceDelete = usePrevious(props.invoiceAdd)


    useEffect(() => {
        async function renderCall() {
            await props.getInvoiceTransaction({ limit: 10, offset: 0, startDate: (moment(new Date()).startOf('day').toISOString()), endDate: moment(new Date()).endOf('day').toISOString() })
        }
        renderCall();
    }, []);

    useEffect(() => {
        if (deleteInvoices && prevInvoiceDelete != props.invoiceAdd && !props.invoiceAdd.success) {
            toastr.error(props.invoiceDelete.message)
        } else if (deleteInvoices && prevInvoiceDelete != props.invoiceAdd && props.invoiceAdd.success) {
            toastr.success("Successfully deleted the Transaction");
        }
    }, [deleteInvoices, props.invoiceAdd]);



    const handleDelete = async (id) => {
        setId(id)
        setIsSubmitted(true)
        setDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
        setIsSubmitted(false)


    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Transaction'
            message='Do you want to delete this transaction?'
            onCancel={closeDeleteModal}
            onConfirm={deleteInvoice}
        />
    }

    const deleteInvoice = async () => {
        setDeleteInvoices(true);
        setDeleteModal(false)
        setIsSubmitted(true)
        await props.updateInvoice([{ id: id, status: 2 }]);
        await props.getInvoiceTransaction({ limit: 10, offset: 0, startDate: (moment(new Date()).startOf('day').toISOString()), endDate: moment(new Date()).endOf('day').toISOString() })
        setIsSubmitted(false)
        setDeleteInvoices(false);

    }

    const viewInvoice = async (invoice) => {
        const { invoiceList } = props.InvoiceList;

        let editDataTemp = {
            id: invoice._id,
            invoiceNumber: invoice.invoiceNumber,
            lastCaptureAttempt: invoice.attemptToCaptureDate,
            balance: invoiceList && invoiceList.data.balance,
            status: invoice.status,
            startDate: invoice.startDate ? moment((invoice.startDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null,
            dueDate: invoice.dueDate ? moment((invoice.dueDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null,
            tenantId: invoice.tenantId && invoice.tenantId._id,
            tenantName: invoice.tenantId ? (invoice.tenantId.firstName + ' ' + invoice.tenantId.lastName) : '',
            tenantAddress: invoice.tenantId && invoice.tenantId.propertyId.propertyName,
            isRecurring: invoice.isRecurring,
            amount: invoice.amount,
            invoiceTypeId: invoice.invoiceTypeId._id,
            invoiceTypeName: invoice.invoiceTypeId.name,
            paymentTypeId: invoice.paymentTypeId._id,
            paymentTypeName: invoice.paymentTypeId.name,
            description: invoice.description || invoice.title,
            paidAmount: invoiceList && invoiceList.data.paidAmount,
            totalDue: invoiceList && invoiceList.data.totalDue,
            leaseStartDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.startDate,
            leaseEndDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.endDate,
            tenantDetails: {
                value: invoice.tenantId._id,
                firstName: invoice.tenantId.firstName,
                lastName: invoice.tenantId.lastName
            },
            invoices: invoice.charges,
            isEdit: true,
            transactionId: invoice.transactionId,
            cardDetails: invoice.tenantId && invoice.tenantId.cardDetails,
            paymentData: {
                invoiceId: invoice._id,
                businessID: invoice.tenantId && invoice.tenantId.propertyId && invoice.tenantId.propertyId.paymentId,
                tenantID: invoice.tenantId._id,
                customerPaymentProfileId: invoice.tenantId && invoice.tenantId.customerPaymentProfileId,
                customerProfileId: invoice.tenantId && invoice.tenantId.customerProfileId,
                amount: invoice.amount,
            }
        }
        setEditData(editDataTemp)
        setInvoiceModal(true)
    }

    const renderModal = () => {
        return <TenantInvoiceNew
            editData={editData}
            // addInvoiceDetails={addInvoiceDetails}
            onCancel={closeModal}
            // attemptCapture={attemptCapture}
            // sendPaymentSms={sendPaymentSms}
            // tempParams={params}
            isView={true}

        />
    }

    const closeModal = () => {
        setInvoiceModal(false)
        setEditData({
            tenantId: null,
            amount: null,
            startDate: null,
            dueDate: null,
            invoiceTypeId: null,
            paymentTypeId: null,
            isRecurring: false,
            status: 0,
            isEdit: false
        })
    }

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getInvoiceTransaction(data);
    }

    const onChangeDate = async (value, type) => {
        let data = { ...params }
        if (type == "startDate") {
            data[type] = moment(new Date(value)).startOf('day').toISOString()
        }
        else {
            data[type] = moment(new Date(value)).endOf('day').toISOString()
        }
        setParams(data)
        await props.getInvoiceTransaction(data)
    }

    const { expenseReducer: { invoiceTransactions } } = props;

    return (
        <div className="row m-0 transaction-tab-invoice innago-table-top">
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {InvoiceModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}

            <div className="formSection col-md-12 p-0">

                <div className=" transactionOuter col-md-12">
                    <div className="formsection transactionSelectForms ">
                        <div className="row outrForm m-0 p-0">
                            <div className="col-md-12 p-0">
                                <div className="d-flex formInp custon-flex">


                                    <div className="col-md-3 formsectionPadding ">

                                        <label>From</label>
                                        <CommonDatePicker
                                            name="startDate"
                                            placeholder="Strat Date"
                                            disableClock={true}
                                            value={params.startDate}
                                            onChange={(value) => onChangeDate(value, 'startDate')}
                                            showYearDropdown='true'
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-3 formsectionPadding">
                                        <label>To</label>
                                        <CommonDatePicker
                                            name="endDate"
                                            placeholder="End Date"
                                            disableClock={true}
                                            value={params.endDate}
                                            onChange={(value) => onChangeDate(value, 'endDate')}
                                            showYearDropdown='true'
                                            className="form-control"

                                        />
                                    </div>
                                    <div className="rgt">
                                        <button className="btn-dvd blu-bg">
                                            Total Amount<strong className={"amnt-bg"}>${invoiceTransactions && invoiceTransactions.data && invoiceTransactions && invoiceTransactions.data.paidAmount ? numberWithCommas(invoiceTransactions.data.paidAmount) : 0.00}
                                            </strong></button>
                                        <button className="btn-dvd green-bg mr-0">
                                            Total Count<strong>{invoiceTransactions && invoiceTransactions.data && invoiceTransactions && invoiceTransactions.data.count}
                                            </strong></button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="innago-table table-main maintenanceTable">

                <div className="table-responsive">
                    <table className="table-gap table-td payment-histroy">
                        <tr className="table-head">
                            <th>Client Name</th>
                            <th>Date</th>
                            <th>Payment Method</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th className="transaction-status">Action</th>
                        </tr>
                        {invoiceTransactions && invoiceTransactions.data && invoiceTransactions.data.invoice && invoiceTransactions.data.invoice.length ?
                            invoiceTransactions.data.invoice.map((inv, key) => {
                                return <tr key={key}>
                                    <td onClick={() => window.open(`/Tenants/${inv.tenantId._id}/Summary`, "_blank")}>{
                                        (inv.tenantId.firstName).charAt(0).toUpperCase() + (inv.tenantId.firstName).slice(1) + " " +
                                        (inv.tenantId.lastName).charAt(0).toUpperCase() + (inv.tenantId.lastName).slice(1)
                                    }
                                    </td>
                                    <td
                                        className="cursorPointer"
                                    >
                                        {inv.attemptToCaptureDate ? moment(inv.attemptToCaptureDate).format('MM/DD/YYYY') : "N/A"}
                                    </td>
                                    <td>
                                        {inv.paymentTypeId ? inv.paymentTypeId.name : null}
                                    </td>
                                    <td onClick={() => viewInvoice(inv)}>#{inv.invoiceNumber}</td>
                                    <td>
                                        ${inv.amount ? numberWithCommas(inv.amount) : 0.00}
                                    </td>

                                    <td className="text-center">  <div className="dropdown moveDrop editDetlPrp drop-arw">
                                        <i
                                            disabled="true"
                                            className="fa fa-trash"
                                            onClick={() => handleDelete(inv._id)}
                                        ></i>
                                    </div></td>
                                </tr>
                            })
                            :
                            <tr>
                                <td className="text-center no_value_table" colSpan={7}><i className="far fa-thumbs-down"></i>There is no data to show you right now!</td>
                            </tr>
                        }
                    </table>
                </div>
                <div className="paginationSec">
                    {invoiceTransactions && invoiceTransactions.data && invoiceTransactions && invoiceTransactions.data.count ?
                        <Pagination
                            totalRecords={invoiceTransactions.data.count}
                            pageLimit={params.limit}
                            pageNeighbours={2}
                            currentPage={currentPage}
                            onPageChanged={onPageChanged}
                        /> : null}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { expenseReducer, invoiceReducer } = state;
    return {
        expenseReducer,
        InvoiceList: invoiceReducer.InvoiceList,
        invoiceDelete: invoiceReducer.invoiceDelete,
        invoiceAdd: invoiceReducer.Invoice,

    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...invoiceActions
})(PaymentHistory);


