import React, { useEffect, useState } from "react"
import moment from 'moment'
import { MAIN_ROUTE } from "../../../config/constants";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import AddTenants from '../../Tenants/components/AddTenants';
import usePrevious from "../../Common/components/prevProps";
import toastr from "../../Common/components/Toaster";
import Confirm from "../../Common/components/Confirm";
import history from "../../../config/history";
import TenantDetailLease from './viewLeaseDtail';
import ConfirmLease from '../../Tenants/components/ConfirmLease';
import { connect } from "react-redux";
import action from "../action";
import TenantAction from "../../Tenants/action";
import commonAction from "../../Common/action";
import TemplateListing from "../../Tenants/components/templateListModal";
import settingsAction from "../../Settings/action";
import LoaderSpinner from "../../Common/components/Loader";
import SignLease from "./signModal";
import MessageBox from "../../Common/components/MessageBox";


const LeaseList = (props) => {

    const [tenantModal, setTenantModal] = useState(false);
    const [editId, setEditId] = useState(false);
    const [modal, setModal] = useState(false);
    const [tenantId, setTenantId] = useState('');
    const [submitted, setSubmitted] = useState(true);
    const [submitLease, setSubmitLease] = useState(false);
    const [viewLeaseAgreement, setViewLeaseAgreement] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [templateData, setTemplateData] = useState(null)
    const [deleteId, setDeleteId] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [isSign, setIsSign] = useState(false);
    const [leaseSign, setLeaseSign] = useState(null);
    const [leaseSignModal, setLeaseSignModal] = useState(null);
    const [message, setMessage] = useState(null);
    const [addTenant, setAddTennat] = useState(false);

    const { commonReducer: { emailCheck } } = props;
    const prevEmailCheck = usePrevious(emailCheck);



    const parseBackgoundImage = (image) => {
        if (image.length) {
            if (image[0].type && image[0].type == "zillo") {
                return image[0].path
            } else {
                return MAIN_ROUTE + image[0].path
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }


    useEffect(() => {
        if (addTenant && !emailCheck.success) {
            toastr.error("Please add a notification email!")
        } else if (addTenant && emailCheck.success && prevEmailCheck != emailCheck) {
            setTemplateModal(true)
        }
    }, [prevEmailCheck, emailCheck]);

    useEffect(() => {
        async function renderCall() {
            await props.getTenant({ limit: 15, isDeleted: false, isClosed: false })
            await props.getTemplates()
            setSubmitted(false)
        }
        renderCall();
    }, []);

    const { tenantReducer: { lease } } = props;
    const prevLease = usePrevious(lease);
    const prevTemplateDelete = usePrevious(props.leaseReducer.leaseAgreementDelete)

    useEffect(() => {
        if (submitLease && !lease.success) {
            toastr.error(lease.message)
        } else if (submitLease && lease.success && prevLease != lease) {
            toastr.success(lease.message);
        }
    }, [prevLease, lease]);

    useEffect(() => {
        if (isLoader && prevTemplateDelete != props.leaseReducer.leaseAgreementDelete) {
            if (props.leaseReducer.leaseAgreementDelete.success) {
                toastr.success(props.leaseReducer.leaseAgreementDelete.message)
            }
            else {
                toastr.error("Error while deleting template!!")
            }
        }
    }, [prevTemplateDelete, props.leaseReducer.leaseAgreementDelete])

    const onOpenModal = async (id) => {
        setEditId(id);
        await props.getTenantId(id);
        setViewLeaseAgreement(false);
        setTenantModal(true);
        setEditClicked(false);
    }

    const onCloseModal = () => {
        setTenantModal(false);
    }

    const renderModal = () => {
        return <AddTenants
            onCancel={onCloseModal}
            editId={editId}
            leaseModal={leaseModal}
            tenantDetail={tenantDetail && tenantDetail}
        />
    }



    const leaseModal = (id) => {
        const { settingsReducer: { templateDetails } } = props;
        if (templateDetails.length) {
            if (templateDetails.length === 1) {
                setModal(true);
                setTemplateData(templateDetails[0])
                setTemplateId(templateDetails[0]._id)
            }
            else {
                setTemplateModal(true)
            }
            setTenantId(id);
        }
        else {
            toastr.error("Please add a template!!")
        }
    }

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Lease'
            message='Do you want to delete Lease?'
            onCancel={closeDeleteModal}
            onConfirm={deleteLease}
        />
    }
    const deleteLease = async () => {
        setDeleteModal(false);
        setIsLoader(true)
        await props.deleteLease([{ id: deleteId }])
        await props.getTenant({ limit: 15, isDeleted: false, isClosed: false })
        await props.getLease(props.params)
        setIsLoader(false)

    }


    const submitTemplate = () => {
        if (templateId) {
            setTemplateModal(false)
            setModal(true)
        }
        else {
            toastr.error("Please choose a template")
        }
    }

    const handleChangeTemplate = (e, details) => {

        if (e.target.checked) {
            setTemplateId(details._id)
            setTemplateData(details)
        }
        else {
            setTemplateId(null)
            setTemplateData(null)
        }
    }

    const closeModal = () => {
        setModal(false);
        setTemplateId(null)
        setTemplateData(null)
    }
    const closeModalTemplate = () => {
        setTemplateModal(false)
    }

    const renderTemplateList = () => {
        const { settingsReducer: { templateDetails } } = props;
        return <TemplateListing
            onCancel={closeModalTemplate}
            templateDetails={templateDetails}
            submitTemplate={submitTemplate}
            handleChangeTemplate={handleChangeTemplate}
            templateId={templateId}

        />
    };

    const renderModalLease = () => {

        return <ConfirmLease
            title='Lease Agreement'
            message='Do you want to send lease agreement?'
            close={closeModal}
            onConfirm={leaseAgreement}
            onView={viewAgreement}
        />
    }

    const leaseAgreement = async () => {
        setViewLeaseAgreement(false);
        setModal(false);
        setSubmitLease(true);
        await props.leaseAgreement({ tenantId: tenantId, templateId: templateId });
        await props.getLease(props.params)
        setTimeout(() => {
            setViewLeaseAgreement(false);
            setSubmitLease(false);
            setTemplateId(null)
        }, 100);
    }

    const viewAgreement = async () => {
        setSubmitLease(true);
        // setModal(false);
        await props.viewLeaseAgreement({ tenantId: tenantId, templateId: templateId });
        setTimeout(() => {
            setSubmitLease(false);
            setViewLeaseAgreement(true);
        }, 1000);
    }

    const closeViewLeaseModal = () => {
        setViewLeaseAgreement(false);
    }

    const renderViewLeaseModal = () => {

        return <TenantDetailLease
            tenantId={tenantId}
            onCancel={closeViewLeaseModal}
            // lease={props.tenantReducer.lease}
            leaseData={templateData.templateContent}
            leaseAgreement={leaseAgreement}
            closeModal={closeModal}
            onOpenModal={onOpenModal}
            leaseViewDetails={true}
        />
    }

    const sendLeaseAgreement = async (id) => {
        setTenantId(id)
        setAddTennat(true)
        await props.emailCheck({ emailType: "notification" })
        // setTemplateModal(true)

    }

    const signAgreement = (lease) => {
        if (!lease.isOwnerSigned) {
            setIsSign(true)
            setLeaseSign(lease)
        }
        else {
            setMessage('Document is already signed!!')
        }


    }

    const renderSignConfirm = () => {
        return <Confirm
            title='Sign Lease'
            message='Do you want to sign now?'
            onCancel={closeSignConfirm}
            onConfirm={signLease}
        />
    }

    const closeSignConfirm = () => {
        setIsSign(false)
    }

    const signLease = () => {
        setIsSign(false)
        setLeaseSignModal(true)
    }

    const closeSignLease = async () => {
        setLeaseSignModal(false)
        await props.getLease(props.params)
    }

    const renderSignLease = () => {
        return <SignLease
            onCancel={closeSignLease}
            lease={leaseSign.documentData}
            leaseId={leaseSign._id}
        />
    }



    const { tenantReducer: { tenants, tenantsById: { tenant: tenantDetail } } } = props;

    return (
        <div class="table-responsive">
            <table class="table-gap table-td">
                <tr class="table-head">

                    <th class="wdt-02" onClick={() => props.filterData("propertyName")}>  <div className="thTab">
                        <div className="txtTabl">Property</div>
                        <div className="TxtImg">
                            <img src="/images/doublearrow.png" className="imgArw" /></div>
                    </div>
                    </th>
                    <th class="" onClick={() => props.filterData("unitName")}>
                        <div className="thTab">
                            <div className="txtTabl">Unit</div>
                            <div className="TxtImg">
                                <img src="/images/doublearrow.png" className="imgArw" /></div>
                        </div>
                    </th>
                    <th class="" onClick={() => props.filterData("status")}>
                        <div className="thTab">
                            <div className="txtTabl">Status</div>
                            <div className="TxtImg">
                                <img src="/images/doublearrow.png" className="imgArw" /></div>
                        </div>
                    </th>
                    <th class="" onClick={() => props.filterData("firstName")}>
                        <div className="thTab">
                            <div className="txtTabl">Tenants</div>
                            <div className="TxtImg">
                                <img src="/images/doublearrow.png" className="imgArw" /></div>
                        </div></th>

                    <th class="" onClick={() => props.filterData("startDate")}>
                        <div className="thTab">
                            <div className="txtTabl">Start Date</div>
                            <div className="TxtImg">
                                <img src="/images/doublearrow.png" className="imgArw" /></div>
                        </div></th>
                    <th class="" onClick={() => props.filterData("endDate")}>
                        <div className="thTab">
                            <div className="txtTabl">End Date</div>
                            <div className="TxtImg">
                                <img src="/images/doublearrow.png" className="imgArw" /></div>
                        </div></th>

                    <th class="actionSec">Action</th>
                </tr>
                {message ? <Modal body={<MessageBox
                    onCancel={() => setMessage(null)}
                    message={message}
                    title={<i style={{ 'color': 'indianred', 'fontSize': '30px' }}
                        className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
                />
                } onCancel={() => setMessage({ text: null })}
                /> : null}

                {leaseSignModal ? <Modal body={renderSignLease()} onCancel={closeSignLease} /> : null}
                {isSign ? <Modal body={renderSignConfirm()} onCancel={closeSignConfirm} /> : null}
                {tenantModal ? <Modal body={renderModal()} onCancel={onCloseModal} /> : null}
                {templateModal ? <Modal body={renderTemplateList()} onCancel={closeViewLeaseModal} /> : null}

                {isLoader && <LoaderSpinner />}
                {modal ? <Modal body={renderModalLease()} onCancel={closeModal} /> : null}

                {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
                {viewLeaseAgreement ? <Modal body={renderViewLeaseModal()} onCancel={closeViewLeaseModal} /> : null}

                {props.leaseList && props.leaseList.leases && props.leaseList.leases.LeaseList.length ? props.leaseList.leases.LeaseList.map((lease, key) => {
                    const imgPath = lease.propertyId && lease.propertyId.propertyImage && lease.propertyId.propertyImage[0] && lease.propertyId.propertyImage[0].path

                    return <tr key={key}>
                        <td className="widProf">
                            <div className="img-name">
                                <i>
                                    {/*<img src="/images/property.png" alt=""/>*/}
                                    <img
                                        src={lease.propertyId && lease.propertyId.propertyImage && lease.propertyId.propertyImage[0] ? parseBackgoundImage(lease.propertyId.propertyImage) : '/images/def_property.png'}
                                        alt="" />
                                </i>
                                <div>
                                    <p>{lease.propertyId ? lease.propertyId.propertyName : null}</p>
                                    {/*<span>Australia</span>*/}

                                </div>
                            </div>
                        </td>
                        <td>
                            <p>
                                {lease.unitId ? lease.unitId.unitName : null}
                            </p>
                        </td>
                        <td>
                            <div className="statusSec">
                                <div
                                    className={lease.status === 0 ? "status-bg send-bg" : (lease.status === 1 ? "status-bg process-bg" : (lease.status === 2 ? "status-bg " : null))}>
                                </div>
                                <div
                                    className="status">{lease.status === 0 ? 'Not Sent' : (lease.status === 1 ? "Pending" : (lease.status === 2 ? "Active" : null))}</div>
                            </div>
                        </td>

                        <td>
                            <p>{lease.tenantId ? lease.tenantId.firstName + " " + lease.tenantId.lastName : null}</p>
                            {/*<span>South Australian Avenue</span>*/}

                        </td>
                        <td>{moment(lease.startDate).format("MM/DD/YYYY")}</td>


                        <td>{moment(lease.endDate).format("MM/DD/YYYY")}</td>
                        <td>
                            <div className="dropdown moveDrop editDetlPrp drop-arw">
                                {/*<a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"*/}
                                {/*data-toggle="dropdown" data-boundary="window" aria-haspopup="true"*/}
                                {/*aria-expanded="false">*/}

                                {/*<i className="fas fa-ellipsis-v"/>*/}
                                {/*</a>*/}
                                {/*<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">*/}
                                {/*<a className="dropdown-item">*/}
                                <i className="far fa-eye mr-1" title="View Agreement"
                                    onClick={() => props.viewLease(lease.tenantId && lease.tenantId._id)} />
                                {/*</a>*/}
                                {/*<a className="dropdown-item" href="#">*/}
                                {(lease.status === 1) ?
                                    <i class="fas fa-file-signature mr-1" onClick={() => signAgreement(lease)}
                                        title="Sign Agreement"></i> :
                                    <i class="fas fa-file-signature mr-1" title="Sign Agreement" style={{ cursor: 'no-drop' }}></i>}
                                <i className={"fas fa-paper-plane mr-1  "} title="Resend Agreement"
                                    onClick={() => sendLeaseAgreement(lease.tenantId && lease.tenantId._id)} />
                                <i className={"fa fa-trash mr-1  "} title="Delete Agreement"
                                    onClick={() => openDeleteModal(lease._id)} />
                                <i className={"fas fa-pencil-alt"} title="Edit Agreement"
                                    onClick={() => {
                                        setEditClicked(true)
                                        onOpenModal(lease.tenantId && lease.tenantId._id)
                                    }} />
                                {/*</div>*/}
                            </div>
                        </td>
                    </tr>
                }) : <tr>
                        <td className="text-center" colSpan={7}><i className="far fa-thumbs-down" />No Data Found!!</td>
                    </tr>}

            </table>
        </div>
    )
};

const mapStateToProps = state => {
    const { leaseReducer, tenantReducer, commonReducer, settingsReducer } = state;
    return {
        leaseReducer,
        tenantReducer,
        commonReducer,
        settingsReducer
    };
};

export default connect(mapStateToProps, {
    ...action,
    ...TenantAction,
    ...commonAction,
    ...settingsAction
})(LeaseList);


