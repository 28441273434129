import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//get all the Report details
export const getReport = (params) =>
    innagoGateWay.get(serviceEndpoints.reportEndpoints.report, { params });

//add Report details
export const addReport = (value) =>
    innagoGateWay.post(serviceEndpoints.reportEndpoints.report, value);

//update Report details
export const updateReport = (value) =>
    innagoGateWay.put(serviceEndpoints.reportEndpoints.report, value);

//DELETE report details by id
export const deleteReport = (params) =>
    innagoGateWay.delete(serviceEndpoints.reportEndpoints.report, {params});

//invite by email
export const inviteScreening = (value) =>
    innagoGateWay.post(serviceEndpoints.reportEndpoints.inviteScreening, value);

