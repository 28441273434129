import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import commonAction from '../../Common/action'
import * as validation from '../../Common/components/validation';
import action from '../action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tenantAction from '../../Tenants/action';
import LoaderSpinner from "../../Common/components/Loader";


const ForgotPassword = (props) => {
    const [email, setEmail] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        let {name, value} = e.target
         setEmail(value)
    }

    const resetPassword =()=>{
        setIsSubmitted(true)
        if(email){
            props.submitMail({email})
        }
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
                case "email":
                    return <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div>
            }
        } else return null
    }

    return (
        <div className="modal  addTenant addInvoiceModal addpsw"  id="modalId" style={{display: 'block'}}>
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Forgot Password</h5>
                        <button type="button" className="close" onClick={props.onCancel}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <div className="formSection">
                                <div className="outrForm">
                                    <div className="col-md-12 formInp">
                                        <label>Email</label>
                                        {/*<input type="text" className="form-control" placeholder="Enter your Email" />*/}
                                        <input type="text" className="form-control"
                                               placeholder="Enter your Email"
                                               value={email}
                                               name={"email"} onChange={handleChange}/>
                                        {validate(email, "Email", "required")}
                                        {email && validate(email, "Email", "email")}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit"  onClick={resetPassword}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer.User
    };
};

export default connect(mapStateToProps, {
    ...action
})(ForgotPassword);
