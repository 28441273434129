import React, { Component } from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import commonActions from '../../Common/action';
import * as utils from '../../Common/components/utils';
import Pagination from '../../Common/components/pagination';
import { MAIN_ROUTE } from '../../../config/constants';

const options = {
    items: 1,
    nav: true,
    rewind: true,
    loop: true,
    autoplay: false,
    navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>'
    ]
};
class index extends Component {
    constructor(props){
        super(props);
        this.state = {
            params: {
                limit: 5,
                offset: 0
            },
            currentPage: 1
        }
    }

    componentDidMount() {
        const { params } = this.state

        this.props.getTenantPropertyDetails(params)
    }

    onPageChanged = async page => {
        const { params } = this.state
        let data = {...params};
        data.offset = ((page - 1) * params.limit) || 0;
        this.setState({
            params: data,
            currentPage: page
        })
        await this.props.getTenantPropertyDetails(params);
    }

    parsePropImage = (image) => {
        if (!image) {
            return '/images/def_property.png'
        }
        if (image.type == 'innago') {
            return MAIN_ROUTE + image.path
        } else if (image.type == 'zillo') {
            return image.path
        } else {
            return '/images/def_property.png'
        }
    }

    render() {
        const { commonReducer: { propertyDetailsByTenant } } = this.props;
        const { currentPage } = this.state;
        const { property, maintenance } = propertyDetailsByTenant || {}
        return (
            <div className="tenants-otr">
                <div className="innago-table-top tenantDtlTop">
                    <div className="lft">
                        <h1>PROPERTIES</h1>
                    </div>
                    <div className="rgt">
                        <button className="export tenantViewBut" id="viewAllTenant">
                            <i className="far fa-eye" />
                            View All Tenants
                        </button>
                    </div>
                </div>
                <div className="tenantDtlOutr propertyOuter tenantpropOtr">
                    <div className="col-md-12 tenantDtl p-0">
                        <div className="col-md-3 propLeftImg">
                            {
                                property &&
                                    property.propertyId.propertyImage &&
                                    property.propertyId.propertyImage.length
                                    ? <OwlCarousel options={options} >
                                        {
                                            property.propertyId.propertyImage.map((img, id) => {
                                                return (
                                                    <div key={id} className="propImg">
                                                        <img src={this.parsePropImage(img)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                    : <div className="propImg">
                                        <img src='/images/def_property.png' />
                                    </div>
                            }
                            <div className="propCont">
                                <h3>
                                    {property ? property.propertyId.propertyName : ''}
                                </h3>
                                <p className="col-md-11 p-0 contadr">
                                    {property ? property.propertyId.address : ''}
                                </p>
                            </div>
                        </div>
                        <div className="propertyDtlRight col-md-9">
                            <div className="rightBg">
                                <div className="tab-content">
                                    <div id="unit1" className="unitDetl">
                                        <h3 className="hedDtl">Unit Details</h3>
                                        <div className="unitBox">
                                            <div className="box">
                                                <div className="centerContent">
                                                    <div className="unitImg">
                                                        <img src="/images/Homee.png" />
                                                    </div>
                                                    <div className="unitcontent">
                                                        <p>Square feet</p>
                                                        <p>
                                                            {property ? property.unitId.squareFeet : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box">
                                                <div className="centerContent">
                                                    <div className="unitImg">
                                                        <img src="/images/Bed.png" />
                                                    </div>
                                                    <div className="unitcontent">
                                                        <p>Bed</p>
                                                        <p className="text-center">
                                                            {property ? property.unitId.bedroomCount : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box">
                                                <div className="centerContent">
                                                    <div className="unitImg">
                                                        <img src="/images/Bath.png" />
                                                    </div>
                                                    <div className="unitcontent">
                                                        <p>Bath</p>
                                                        <p className="text-center">
                                                            {property ? property.unitId.bathroomCount : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box">
                                                <div className="centerContent">
                                                    <div className="unitImg">
                                                        <img src="/images/Monthly-Rent.png" />
                                                    </div>
                                                    <div className="unitcontent">
                                                        <p>Monthly Rent</p>
                                                        <p>
                                                            {property ? `$${utils.numberWithCommas(property.unitId.monthlyRent)}` : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box">
                                                <div className="centerContent">
                                                    <div className="unitImg">
                                                        <img src="/images/Deposit.png" />
                                                    </div>
                                                    <div className="unitcontent">
                                                        <p>Deposit</p>
                                                        <p>
                                                            {property ? `$${utils.numberWithCommas(property.unitId.deposit)}` : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="innago-table leaseOtr">
                                            <h3>Lease Details</h3>
                                            <div className="col-md-12 leaseDtlOtr d-flex">
                                                <div className="col-md-6 d-flex leaseDate bdtr">
                                                    <div className=" leaseImg">
                                                        <img src="/images/calender.png" />
                                                    </div>
                                                    <div className="leaseCont">
                                                        <h4>
                                                            Lease Start Date
                                                        </h4>
                                                        <p className="dateSection">
                                                            {property ? utils.formatDate(property.leaseId ? property.leaseId.startDate : '') : ''}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 d-flex leaseDate">
                                                    <div className=" leaseImg">
                                                        <img src="/images/calender.png" />
                                                    </div>
                                                    <div className="leaseCont">
                                                        <h4>
                                                            Lease End Date
                                                        </h4>
                                                        <p className="dateSection">
                                                            {property ? utils.formatDate(property.leaseId ? property.leaseId.endDate : '') : ''}
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="innago-table">
                                            <h3>Maintenance Request</h3>
                                            <div className="table-responsive">
                                                <table className="table-gap table-td">
                                                    <tbody>
                                                        <tr className="table-head">
                                                            <th className="wdt-01">
                                                                <i><img src="/images/icon-dots.png" alt=""/></i>
                                                            </th>
                                                            <th className="wdt-issue">Issue</th>
                                                            <th className="wdt-ron">Reported On</th>
                                                            <th className="wdt-sts">Status</th>
                                                        </tr>
                                                        {
                                                            maintenance && maintenance.length
                                                                ? maintenance.map((maint, idx) => {
                                                                    return (<tr key={idx} className="detailRow">
                                                                        <td className="text-center">
                                                                            <i><img src="/images/icon-dots.png" alt="" /></i>
                                                                        </td>
                                                                        <td>
                                                                            <div className="c-td">
                                                                                {maint.description}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="c-td">{utils.formatDate(maint ? maint.createdAt : '')}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="c-td">
                                                                                <p>
                                                                                    {maint.status ? 'Resolved' : 'Pending'}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>)
                                                                })
                                                                : <tr>
                                                                    <td className="text-center no_value_table" colSpan="4">
                                                                        <i className="far fa-thumbs-down"></i>No Data Found!!
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="paginationSec">
                                                {maintenance
                                                    ? <Pagination
                                                        totalRecords={maintenance ? maintenance.length : 0}
                                                        pageLimit={5}
                                                        pageNeighbours={1}
                                                        currentPage={currentPage}
                                                        onPageChanged={this.onPageChanged}
                                                    /> : 'null'}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { commonReducer } = state;
    return {
        commonReducer
    };
}

export default connect(mapStateToProps, { ...commonActions })(index)
