import React, { useState, useEffect } from "react";
import parser from 'html-react-parser';

const ViewNotice = (props) => {

 // const {lease: {lease}} = props;
  return (

    <div className="modal addTenant leaseAgreement view-notice" id="modalId" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="customtest">
            <h5 className="modal-title" id="exampleModalLabel">View Notice</h5>
            <div className="leaseDiv">
            <button className="btn sendbutton"
              onClick={()=>{
                props.sendLobMail()
              }}>
                Send
            </button>
            </div>
            </div>

            <button type="button" className="close" onClick={() => props.onCancel()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.notice && parser(props.notice)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewNotice;