import React from "react";

class ScreeningHeader extends React.Component {

    render() {

        return (
            <>
                <div className="col-md-12 blue-navsection">
                    <div className="container">
                        <div className="outer-detail d-flex ">
                            <div className="cruzhed">
                                <h3>Cruz Properties</h3>
                            </div>
                            <div className="cruzesub">
                                <h3>Rental Applications</h3>
                                <p>Powered by CruzRentals</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 property-image-sec">
                    <div className="container">
                        <div className="property-otr d-flex  col-md-10 m-auto p-0">
                            <div className="col-md-7 img-prop">
                                <img src="/images/image-property.jpg" />
                            </div>
                            <div className="col-md-5 prop-detail-content">
                                <h3>815 Grace,Street Wilmington,North Carolina</h3>
                                <h4>815 Grace,Street Wilmington, North Carolina 28401</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ScreeningHeader;
