import * as Service from './service';
import * as Constant from './constant';

const addScreening = (report) => {
    const request = () => ({ type: Constant.ADD_SCREENING_REQUEST });
    const success = reportAction => ({
        type: Constant.ADD_SCREENING_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.ADD_SCREENING_FAILURE,
        error
    });
    const data = [...report.reportDocuments];
    let businessData = new FormData();
    businessData.append('data', JSON.stringify(report))
    data.length && data.map((image) => businessData.append(`file`, image));

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addScreening(businessData);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


const getProperties = (params) => {
    const request = () => ({ type: Constant.GET_SCREENING_PROPERTY_REQUEST });
    const success = reportAction => ({
        type: Constant.GET_SCREENING_PROPERTY_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.GET_SCREENING_PROPERTY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getProperties(params);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const addEmail = (data) => async dispatch => {
    data.email && dispatch({ type: Constant.ADD_EMAIL, data });
};

const getPropertyDetail = (id) => {
    const request = () => ({ type: Constant.GET_SCREENING_PROPERTY_REQUEST });
    const success = reportAction => ({
        type: Constant.GET_SCREENING_PROPERTY_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.GET_SCREENING_PROPERTY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPropertyDetail(id);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails.data));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const makePayMent = (detaiils) => {
    const request = () => ({ type: Constant.MAKE_PAYMENT_REQUEST });
    const success = reportAction => ({
        type: Constant.MAKE_PAYMENT_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.MAKE_PAYMENT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.makePayMent(detaiils);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getVacantProperties = (params) => {
    const request = () => ({ type: Constant.GET_VACANT_PROPERTY_REQUEST });
    const success = reportAction => ({
        type: Constant.GET_VACANT_PROPERTY_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.GET_VACANT_PROPERTY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getVacantProperties(params);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getAllUnitProperties = (id) => {
    const request = () => ({ type: Constant.GET_VACANT_UNIT_REQUEST });
    const success = reportAction => ({
        type: Constant.GET_VACANT_UNIT_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.GET_VACANT_UNIT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getAllUnitProperties(id);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

export default {
    addScreening,
    getProperties,
    addEmail,
    getPropertyDetail,
    makePayMent,
    getVacantProperties,
    getAllUnitProperties
}
