
import * as Service from './service';
import * as Constant from './constatnts';

const refreshCall = (functionName, params) => {
    functionName.call(this, params)
}


const updatePassword = (value) => {
    let maintenanceDetails = new FormData();
    if (value.maintenanceFile && value.maintenanceFile.length) {
        value.maintenanceFile.map((item, i)=>{
            maintenanceDetails.append("file", item )
        })
    }
    maintenanceDetails.append('data', JSON.stringify(value));
    const request = () => ({ type: Constant.UPDATE_PASSWORD_REQUEST });
    const success = response => ({
        type: Constant.UPDATE_PASSWORD_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.UPDATE_PASSWORD_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updatePassword(value);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch(success(Details));
                } else {
                    dispatch(failure(Details));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}
const updateTenantPassword = (value) => {
    return async dispatch => {
        try {
            dispatch({ type: Constant.UPDATE_TENANT_PASSWORD_REQUEST });
            const res = await Service.updateTenantPassword(value);
            if (res.status === 200) {
                const Details = res.data;
                if (Details.success) {
                    dispatch({
                        type: Constant.UPDATE_TENANT_PASSWORD_SUCCESS,
                        response: Details
                    });
                } else {
                    dispatch({
                        type: Constant.UPDATE_TENANT_PASSWORD_FAILURE,
                        error:Details
                    });
                }
            } else {
                dispatch({
                    type: Constant.UPDATE_TENANT_PASSWORD_FAILURE,
                    error:res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: Constant.UPDATE_TENANT_PASSWORD_FAILURE,
                error:e.response && e.response.data
            });
        }
    };
}

const editProfile = ( profile ) => {
    const request = () => ({ type: Constant.ADD_PROFILE_REQUEST });
    const success = profileAction => ({
        type: Constant.ADD_PROFILE_SUCCESS,
        profileAction
    });
    const failure = error => ({
        type: Constant.ADD_PROFILE_FAILURE,
        error
    });

    let profileData = new FormData();
    profile.profilePic && profileData.append('file', profile.profilePic)
    profileData.append('data', JSON.stringify( profile))


    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.editProfile(profileData);
            if (res.status === 200) {
                const profileDetails = res.data;
                if (profileDetails.success) {
                    dispatch(success(profileDetails));
                } else {
                    dispatch(failure(profileDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getProfile = () => {
    const request = () => ({ type: Constant.GET_PROFILE_ID_REQUEST });
    const success = profileAction => ({
        type: Constant.GET_PROFILE_ID_SUCCESS,
        profileAction
    });
    const failure = error => ({
        type: Constant.GET_PROFILE_ID_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getProfile();
            if (res.status === 200) {
                const profileDetails = res.data;
                if (profileDetails.success) {
                    dispatch(success(profileDetails));
                } else {
                    dispatch(failure(profileDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            // refreshCall(getProfile)
            dispatch(failure(e.response && e.response.data));
        }
    };
}

export default {
    updatePassword,
    editProfile,
    getProfile,
    updateTenantPassword,
    refreshCall
}
