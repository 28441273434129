import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import action from "../../action";
import * as validation from "../../../Common/components/validation";
import usePrevious from "../../../Common/components/prevProps";
import toastr from '../../../Common/components/Toaster';

const CreatePaaword = (props) => {
    const [password, setPassword] = useState({ password: null, confirmPassword: null })
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [submit, setSubmit] = useState(false)
    const prevPasssword = usePrevious(props.password);

    useEffect(() => {
        if (submit && !props.password.success) {
            toastr.error(props.password.message)
        } else if (submit && props.password.success) {
            toastr.success(props.password.message);
        }
    }, [props.password]);

    const handleSubmit = async () => {
        setIsSubmitted(true)
        let regex = /^(?=.*?[A-Z]).{5,}$/
        setIsSubmitted(true)
        if (password.password && password.confirmPassword && (password.password === password.confirmPassword) &&
            validation.UNMATCHED_PASSWORDS(password.confirmPassword, password.password) && regex.test(password.password)) {
            setSubmit(true)
            await props.createPassword({ id: props.id, password: password.password });
            props.onCancel();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPassword({
            ...password,
            [name]: value
        })
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        validation.NUMBER_ONLY(value, name) != true ? <div className={"errorMsg"}>
                            {validation.NUMBER_ONLY(value, name)}
                        </div> : null
                    );

                case "password match":
                    return (
                        validation.UNMATCHED_PASSWORDS(value, name) != true ? <div className={"errorMsg"}>
                            {validation.UNMATCHED_PASSWORDS(value, name)}
                        </div> : null
                    );
                case "password":
                    return (
                        validation.PASSWORD(value, name) != true ? <div className={"errorMsg"}>
                            {validation.PASSWORD(value, name)}
                        </div> : null
                    );
            }
        } else return null;
    };

    return (
        <div className="modal  addTenant changePasswordModal" id="modalId" style={{ display: "block" }}>

            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Create Password
                        </h5>
                        <button
                            type="button"
                            className="close"
                            onClick={props.onCancel}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo invoice">
                            <div className="formSection">
                                <div className="row outrForm m-0 p-0">
                                    <div className="col-md-12 formInp">
                                        <label>New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="New Password"
                                            value={password.password}
                                            name={"password"}
                                            onChange={handleChange}
                                        />
                                        {validate(password.password, "New Password", "required")}
                                        {password.password && validate(password.password, 'password', "password")}
                                    </div>
                                    <div className="col-md-12 formInp">
                                        <label>Confirm Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            name={"confirmPassword"}
                                            value={password.confirmPassword}
                                            onChange={handleChange}

                                        />
                                        {validate(password.confirmPassword, "Confirm Password", "required")}
                                        {password.confirmPassword && password.password &&
                                            validation.UNMATCHED_PASSWORDS(password.confirmPassword, password.password) != true ? <div className={"errorMsg"}>
                                                {validation.UNMATCHED_PASSWORDS(password.confirmPassword, password.password)}
                                            </div> : null
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

function mapStateToProps(state) {
    const { tenantReducer } = state;
    return {
        password: tenantReducer.password

    };
}

export default connect(mapStateToProps, { ...action })(CreatePaaword);
