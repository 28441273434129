import React, { useState, useEffect } from "react";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import moment from 'moment'

import { numberWithCommas } from "../../Common/components/utils";
import usePrevious from '../../Common/components/prevProps'

const PopoverItem = props => {
    const { id, item, amount, invoice } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    // const [splitUp, setSplitUp] = useState([])


    // const prevInvoice = usePrevious(invoice)

    // useEffect(() => {
    //     if (prevInvoice != invoice) {
    //         if (invoice && invoice.charges && invoice.charges[0] && !setSplitUp[0].description) {
    //             setSplitUp[0].description = invoice.charges[0].description ? invoice.charges[0].description : invoice.invoiceTypeId.name +
    //                 '-' +
    //                 invoice.tenantId.propertyId.propertyName +
    //                 '-' +
    //                 '(' +
    //                 moment(invoice.startDate).format('MM/DD/YYYY') +
    //                 '-' +
    //                 // editData.dueDate +
    //                 moment(invoice.dueDate).format('MM/DD/YYYY') +
    //                 ')'
    //         }
    //         setSplitUp(invoice.charges)
    //     }

    // }, [invoice])

    const toggle = () => setPopoverOpen(!popoverOpen);





    return (
        <>
            <td
                // onClick={()=>{}}
                onMouseEnter={() => setPopoverOpen(true)}
                onMouseLeave={() => setPopoverOpen(false)}
                id={"Popover-" + id}
                type="td"
            >
                ${amount ? numberWithCommas(amount) || 0 : 0}
            </td>
            <Popover
                placement={item}
                isOpen={popoverOpen}
                target={"Popover-" + id}
                toggle={toggle}
            >
                {/* <PopoverHeader>Popover Title</PopoverHeader> */}
                <PopoverBody>
                    <div className="table-responsive invoiceTableScroll">
                        <table className="table-gap table-td">
                            <tr className="table-head">
                                <th className="descwidth">Description</th>
                                <th>Amount</th>
                            </tr>
                            {invoice && invoice.charges && invoice.charges[0] ?
                                invoice.charges.map((inv, key) => {
                                    return <tr>
                                        <td>{inv.description ? inv.description : (invoice.invoiceTypeId && invoice.invoiceTypeId.name +
                                            '-' +
                                            invoice.tenantId.propertyId.propertyName +
                                            '-' +
                                            '(' +
                                            moment(invoice.startDate).format('MM/DD/YYYY') +
                                            '-' +
                                            // editData.dueDate +
                                            moment(invoice.dueDate).format('MM/DD/YYYY') +
                                            ')')}</td>
                                        <td>${inv.amount ? numberWithCommas(inv.amount) || 0 : 0}</td>
                                    </tr>
                                }) : null}
                            <tr>
                                <td>Sub Total</td>
                                <td>${invoice.amount ? numberWithCommas(invoice.amount) || 0 : 0}</td>
                            </tr>

                        </table></div>
                </PopoverBody>
            </Popover>
        </>
    );
};

export default PopoverItem;
