import * as actionType from "./constant";

const initialState = {
    Property: [],
    zillloData: {},
    deletePropertyResponse: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_PROPERTY_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_PROPERTY_SUCCESS:
            return {
                ...state,
                Property:{ success: true, ...action.data.data.propertyList}
                // Property: {}
            };
        case actionType.ADD_PROPERTY_FAILURE:
            return {
                ...state,
                Property: { success: false, ...action.error }
            };

        case actionType.GET_ZILLOW_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_ZILLOW_DATA_SUCCESS:
            return {
                ...state,
                zillloData:{ success: true, ...action.data}
            };
        case actionType.GET_ZILLOW_DATA_FAILURE:
            return {
                ...state,
                zillloData: { success: false, ...action.error }
            };

        case actionType.UPDATE_PROPERTY_IMAGE_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_PROPERTY_IMAGE_SUCCESS:
            return {
                ...state,
                updatePropImageResponse:{ success: true, ...action.data}
            };
        case actionType.UPDATE_PROPERTY_IMAGE_FAILURE:
            return {
                ...state,
                updatePropImageResponse: { success: false, ...action.error }
            };

        case actionType.UPDATE_PROPERTY_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_PROPERTY_SUCCESS:
            return {
                ...state,
                updatePropertyResponse:{ success: true, ...action.data}
            };
        case actionType.UPDATE_PROPERTY_FAILURE:
            return {
                ...state,
                updatePropertyResponse: { success: false, ...action.error }
            };

        case actionType.DELETE_PROPERTY_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_PROPERTY_SUCCESS:
            return {
                ...state,
                deletePropertyResponse:{ success: true, ...action.data}
            };
        case actionType.DELETE_PROPERTY_FAILURE:
            return {
                ...state,
                deletePropertyResponse: { success: false, ...action.error }
            };

        case actionType.MAKE_IMAGE_DEFAULT_REQUEST:
            return {
                ...state
            };
        case actionType.MAKE_IMAGE_DEFAULT_SUCCESS:
            return {
                ...state,
                makeDefaultImageResponse: { success: true, ...action.data }
            };
        case actionType.MAKE_IMAGE_DEFAULT_FAILURE:
            return {
                ...state,
                makeDefaultImageResponse: { success: false, ...action.error }
            };

        case actionType.DELETE_PROPERTY_IMAGE_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_PROPERTY_IMAGE_SUCCESS:
            return {
                ...state,
                deletePropertyImgResponse: { success: true, ...action.data }
            };
        case actionType.DELETE_PROPERTY_IMAGE_FAILURE:
            return {
                ...state,
                deletePropertyImgResponse: { success: false, ...action.error }
            };
        default:
            return state;
    }
};
