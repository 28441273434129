import * as actionTypes from "./constant";
import * as Service from "./service";

//add the expense details
const addExpense =(value)  => {
    let expenseDetails = new FormData();
    if (value.expenseFile && value.expenseFile.length) {
        value.expenseFile.map((item, i)=>{
            expenseDetails.append("file", item )
        })
    }
    // value.maintenanceFile && maintenanceDetails.append('file', value.maintenanceFile);
    expenseDetails.append('data', JSON.stringify(value));
    const request = data => ({
        type: actionTypes.ADD_EXPENSE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_EXPENSE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_EXPENSE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addExpense(expenseDetails);
            if (res.status === 200) {
                const expenseDetails = res.data;
                await dispatch(success(expenseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//update the expense details
const updateExpense =(value)  => {
    let expenseDetails = new FormData();
    if (value.expenseFile && value.expenseFile.length) {
        value.expenseFile.map((item, i)=>{
            expenseDetails.append("file", item )
        })
    }
    // value.maintenanceFile && maintenanceDetails.append('file', value.maintenanceFile);
    expenseDetails.append('data', JSON.stringify(value));
    const request = data => ({
        type: actionTypes.ADD_EXPENSE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_EXPENSE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_EXPENSE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateExpense(expenseDetails);
            if (res.status === 200) {
                const expenseDetails = res.data;
                await dispatch(success(expenseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get all the expense details
const getExpense =(params)  => {

    const request = data => ({
        type: actionTypes.GET_EXPENSE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_EXPENSE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_EXPENSE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getExpense(params);
            if (res.status === 200) {
                const expenseDetails = res.data;
                await dispatch(success(expenseDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get all the invoice transaction details
const getInvoiceTransaction =(params)  => {

    const request = data => ({
        type: actionTypes.GET_INVOICE_TRANSACTION_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_INVOICE_TRANSACTION_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_INVOICE_TRANSACTION_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getInvoiceTransaction(params);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

export default {
    addExpense,
    getExpense,
    updateExpense,
    getInvoiceTransaction
};
