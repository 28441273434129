import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import maintenanceAction from '../../../Maintenance/action';
import history from '../../../../config/history'
import MaintenanceAdd from '../../../Maintenance/components/maintenanceAdd'
import Modal from '../../../Common/components/Modal'
import toastr from "../../../Common/components/Toaster";
import usePrevious from "../../../Common/components/prevProps";
import LoaderSpinner from "../../../Common/components/Loader";
import commonAction from '../../../Common/action';


const Maintanance = props => {
    const [maintenanceModal, setMaintenanceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [editData, setEditData] = useState('')
    const [addMaintenanceSet, setAddMaintenance] = useState(false);


    const prevMaintenance = usePrevious(props.maintenances);
    const { commonReducer: { emailCheck } } = props;
    const prevEmailCheck = usePrevious(emailCheck);

    useEffect(() => {
        if (isSubmitted && prevMaintenance !== props.maintenances && !props.maintenances.success) {
            toastr.error(props.maintenances.message)
        } else if (isSubmitted && prevMaintenance !== props.maintenances && props.maintenances.success) {
            toastr.success(props.maintenances.message)
        }
    }, [prevMaintenance, props.maintenances]);

    useEffect(() => {
        if (addMaintenanceSet && !emailCheck.success) {
            toastr.error("Please add a maintenance email!")
        } else if (addMaintenanceSet && emailCheck.success && prevEmailCheck != emailCheck) {
            history.push('/Add-Maintenance')

        }
    }, [prevEmailCheck, emailCheck]);

    const addMaintenance = async () => {
        setAddMaintenance(true)
        await props.emailCheck({ emailType: "maintenance" })
        setAddMaintenance(false)
    }

    useEffect(() => {
        async function renderCall() {
            await props.getMaintenance()
        }

        renderCall();
    }, []);

    const viewMore = () => {
        history.push('/Maintenance')
    }

    const openModal = () => {
        setMaintenanceModal(true)
    }

    const closeModal = async () => {
        setMaintenanceModal(false)
        setEditData({
            _id: null,
            propertyId: null,
            maintenanceFile: null,
            description: null,
            categoryId: null,
            requestById: null,
            isEdit: false,
            isView: false
        })
    }

    const renderModal = () => {
        return <MaintenanceAdd
            addMaintenanceDetails={addMaintenanceDetails}
            onCancel={closeModal}
            maintenanceDetails={editData}
        />
    }

    const addMaintenanceDetails = async (maintenanceDetails) => {
        setIsSubmitted(true);
        if (!maintenanceDetails._id)
            await props.addMaintenance(maintenanceDetails)
        else
            await props.updateMaintenance(maintenanceDetails)
        setMaintenanceModal(false)
        await props.getMaintenance()
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
        setEditData({
            _id: null,
            propertyId: null,
            maintenanceFile: null,
            description: null,
            categoryId: null,
            requestById: null,
            isEdit: false,
            isView: false
        })
    }

    const editMaintenance = async (maintenance) => {
        let editData = {
            _id: maintenance._id,
            propertyDetail: {
                value: maintenance.propertyId && maintenance.propertyId._id,
                label: maintenance.propertyId && maintenance.propertyId.propertyName
            },
            propertyId: maintenance.propertyId && maintenance.propertyId._id,
            maintenancePicture: maintenance.maintenancePicture,
            maintenanceFile: [],
            description: maintenance.description,
            categoryId: maintenance.categoryId && maintenance.categoryId._id,
            requestById: maintenance.requestById && maintenance.requestById._id,
            isEdit: true,
            status: JSON.stringify(maintenance.status),
            amount: maintenance.amount,
            paymentId: maintenance.paymentId && maintenance.paymentId._id,
            propertyOwnerName: maintenance.paymentId && maintenance.paymentId.entityName,
            tenantName: maintenance.requestById && maintenance.requestById.firstName + " " + maintenance.requestById.lastName,
            unitId: maintenance.unitId,
        }
        setMaintenanceModal(true)
        setEditData(editData)
    }

    const viewMaintenance = (maintenance) => {
        let viewData = {
            _id: maintenance._id,
            propertyDetail: {
                value: maintenance.propertyId && maintenance.propertyId._id,
                label: maintenance.propertyId && maintenance.propertyId.propertyName,
            },
            propertyId: maintenance.propertyId && maintenance.propertyId._id,
            maintenancePicture: maintenance.maintenancePicture,
            maintenanceFile: [],
            description: maintenance.description,
            categoryId: maintenance.categoryId && maintenance.categoryId._id,
            requestById: maintenance.requestById && maintenance.requestById._id,
            isEdit: false,
            isView: true,
            status: JSON.stringify(maintenance.status),
            amount: maintenance.amount,
            paymentId: maintenance.paymentId && maintenance.paymentId._id,
            propertyOwnerName: maintenance.paymentId && maintenance.paymentId.entityName,
            tenantName: maintenance.requestById && maintenance.requestById.firstName + " " + maintenance.requestById.lastName,
            unitId: maintenance.unitId,
        }
        setEditData(viewData)
        setMaintenanceModal(true)
    }

    return (
        <div
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-6 col-sm-12 occupancy maintenance mb-3">
            {isSubmitted && <LoaderSpinner />}
            <div className="occupancyOtr maintenanceOtr">
                {maintenanceModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
                <div className="headerSection d-flex">
                    <h3>Maintenance</h3>
                    <div className="viewMore">
                        {/*<button className="addmaintenance"onClick={()=>history.push('/Add-Maintenance')}><i class="fas fa-plus"></i> Add Maintenance</button>button*/}
                        <img src="/images/more.png" /><span onClick={() => viewMore()}>View More</span>
                    </div>
                </div>
                {props.maintenanceDetails.maintenanceList && props.maintenanceDetails.maintenanceList[0] ?
                    <ul className="maintul">
                        {props.maintenanceDetails.maintenanceList.slice(0, 7).map((maintenance, key) => {
                            return <li className="d-flex" key={key} style={{ cursor: "pointer" }}>
                                <div className="imgSec">
                                    <img src="/images/note.png" />
                                </div>
                                <div className="maintCont maintenanceBrdr" onClick={() => history.push(`/SupportTicket/${maintenance._id}/AddReplay`)}>
                                    <h4>{maintenance.title ? maintenance.title : null}
                                        {maintenance.requestById ? ` - ${maintenance.requestById.firstName + " " + maintenance.requestById.lastName}` : null}</h4>
                                    {/*<h5>{maintenance.propertyId ? maintenance.propertyId.address : null}</h5>*/}
                                    {/* <p className="contDtl">
                                        {maintenance.title}
                                    </p> */}
                                    {/* <p className="btnCont">
                                        {maintenance.status ?
                                            <span className="view"
                                                onClick={() => history.push(`/Add-Maintenance/${maintenance._id}`)}>Edit</span>
                                            : null}
                                    </p> */}
                                </div>
                            </li>
                        })}
                    </ul>
                    :
                    <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>}
                <button className="btnadd" onClick={() => addMaintenance()}>
                    <i className="fas fa-plus"></i> Add Maintenance
                </button>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer } = state;
    return {
        maintenanceDetails: maintenanceReducer.maintenanceList,
        maintenances: maintenanceReducer.maintenances,
        commonReducer

    };
};

export default connect(mapStateToProps, {
    ...maintenanceAction, ...commonAction
})(Maintanance);
