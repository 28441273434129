import React, { useState, useEffect } from "react";

const ConfirmLease = (props) => {

    return (
        <div className="modal addTenant leaseagreeModal" id="modalId" style={{ display: "flex", alignItems: "center" }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Lease Agreement</h5>
                        <button type="button" className="close" onClick={() => props.close()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="leaseAgreement">
                            <p className="textLease">Do you want to send lease agreement?</p>
                            <div className="col-md-12 btnOutrLease text-center">
                                <button type="button" className="btn btn-primary btnview" disabled={props.isButtonDisabled} onClick={props.onView}>View</button>
                                <button type="button" className="btn btn-primary btnSend" onClick={props.onConfirm}>Send Now</button>
                                {!props.edit && <button type="button" className="btn btn-primary btnlatr" onClick={props.close}>Send Later</button>}

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmLease;
