import React, {useState, useEffect} from "react";
import {withRouter} from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import {connect} from "react-redux";

import commonAction from "../../../Common/action";
import action from "../../../Maintenance/action";
import moment from "moment";
import {numberWithCommas} from "../../../Common/components/utils";
import history from '../../../../config/history'

const PaidInvoice = props => {
    useEffect(() => {
        props.getTenantPaidInvoice()
    }, [])

    const handleViewMore=()=>{
        history.push('/Invoice/All')
    }

    return (
        <div
             draggable={props.draggable}
             onDragStart={props.onDragStart()}
             onDragOver={props.onDragOver()}
             onDrop={props.onDrop()}
             id={props.idx}
             className="drpbl col-lg-4 col-md-12 col-sm-12 occupancy mb-3 paidInvo"
        >

            <div className="occupancyOtr invoiceOuter">
                <div className="headerSection d-flex">
                    <h3>PAID INVOICE</h3>
                    <div className="viewMore" onClick={()=>handleViewMore()}>
                        <img src="/images/more.png"/><span>View More</span>
                    </div>
                </div>
                <div className="table-responsive col-md-12 otrTableInvo">
                    <table className="table table-Invo">
                        <tbody>
                        {props.paidInvoiceDetailsByTenant && props.paidInvoiceDetailsByTenant.invoice && props.paidInvoiceDetailsByTenant.invoice.length ?
                            props.paidInvoiceDetailsByTenant.invoice.slice(0,4).map((invoice) => {
                                return <tr>

                                    <td>
                                        <div className="invSec">
                                            <p className="hed">Invoice No</p>
                                            <p className="cont"> {invoice.invoiceNumber ? invoice.invoiceNumber : null}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="invSec">
                                            <p className="hed">Invoice Date</p>
                                            <p className="cont"> {invoice.startDate ? moment((invoice.startDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="invSec">
                                            <p className="hed">Due Date</p>
                                            <p className="cont"> {invoice.dueDate ? moment((invoice.dueDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="invSec">
                                            <p className="hed">Amount</p>
                                            <p className="cont amt">${numberWithCommas(invoice.amount)}</p>
                                        </div>
                                    </td>

                                </tr>

                            }) : <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {commonReducer, profileReducer, maintenanceReducer} = state;
    return {
        paidInvoiceDetailsByTenant: commonReducer.paidInvoiceDetailsByTenant,
        profileReducer,
        maintenanceReducer
    };
}

export default withRouter(connect(mapStateToProps, {...commonAction, ...action})(PaidInvoice));

