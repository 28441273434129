import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import CommonDatePicker from "../../Common/components/CommonDatePicker";


const PersonalInformation = props => {

    const {
        state, validate, handleChange, status, onChangeDob, onChangeMoveInDate, id, onChangeProperty, Properties,
        promiseOptionsProperty, Units, handleAddressSearch, handleSelect
    } = props;

    return (
        <>
            <div className="clientInfo">
                <h3>Personal Details</h3>
                <form autoComplete="off">
                    <div className="form-section-dtl">
                        <div className="col-md-12 p-0">
                            <div className="row p-0">
                                {props.isEmailSection ? <>
                                    <div className="col-md-4 form-input">
                                        <label>Property</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={Properties}
                                            placeholder={"Search Property"}
                                            loadOptions={promiseOptionsProperty}
                                            name="propertyId"
                                            onChange={onChangeProperty}
                                            value={state.property && state.property}
                                        />
                                        {validate(state.firstName, "First Name", "required")}
                                        {state.firstName && validate(state.firstName, "First Name", "letter")}
                                    </div>
                                    <div className="col-md-4 form-input">
                                        <label>Unit</label>
                                        <select className="form-control"
                                                name="unitId"
                                                onChange={(e) => handleChange(e)}
                                        >
                                            <option value="">Select Unit</option>
                                            {
                                                Units.map((e, i) => {

                                                    return <option
                                                        value={e._id}
                                                        key={i}
                                                        selected={Units && e.value == state.unitId}
                                                    >
                                                        {e.unitName}
                                                    </option>
                                                })
                                            }
                                        </select>
                                        {validate(state.firstName, "First Name", "required")}
                                        {state.firstName && validate(state.firstName, "First Name", "letter")}
                                    </div>
                                </> : null}
                                <div className="col-md-4 form-input">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        name={"firstName"}
                                        onChange={handleChange}
                                        value={state.firstName}
                                        maxLength="25"
                                        disabled={status === 'view'}
                                    />
                                    {validate(state.firstName, "First Name", "required")}
                                    {state.firstName && validate(state.firstName, "First Name", "letter")}
                                </div>
                                <div className="col-md-4 form-input">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        value={state.lastName}
                                        name={"lastName"}
                                        onChange={handleChange}
                                        maxLength="20"
                                        disabled={status === 'view'}
                                    />
                                    {validate(state.lastName, "Last Name", "required")}
                                    {state.lastName && validate(state.lastName, "Last Name", "letter")}
                                </div>
                                <div className="col-md-4 form-input">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={state.email}
                                        name={"email"}
                                        onChange={handleChange}
                                        disabled={status === 'view'}
                                    />
                                    {validate(state.email, "Email Address", "required")}
                                    {state.email && validate(state.email, "Email", "email")}
                                </div>
                                <div className="col-md-4 form-input">
                                    <label>DOB</label>


                                    <CommonDatePicker
                                        value={state.dob ? new Date(state.dob) : null}
                                        onChange={onChangeDob}
                                        className="form-control "
                                        name="dob"
                                        placeholderText="MM/DD/YYYY"
                                        maxDate={new Date()}
                                        disabled={status === 'view'}
                                        showYearDropdown='true'

                                    />
                                    {/*    name="dob"*/}
                                    {/*    placeholder="DOB"*/}
                                    {/*    disableClock={true}*/}
                                    {/*    className="form-control"*/}
                                    {/*    value={state.dob && moment(new Date(state.dob)).format('MM/DD/YYYY')}*/}
                                    {/*    onChange={onChangeDob}*/}
                                    {/*    maxDate={new Date()}*/}
                                    {/*    showYearDropdown='true'*/}
                                    {/*    disabled={status === 'view'}*/}
                                    {/*    onChangeRaw={props.handleChangeDate}*/}

                                    {/*/>*/}
                                    {validate(state.dob, "DOB", "required")}
                                    {state.dob && validate(state.dob, "DOB", "dob")}
                                </div>
                                <div className="col-md-4 form-input">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone"
                                        value={state.phoneNumber}
                                        onChange={handleChange}
                                        name={"phoneNumber"}
                                        maxLength={state.addedCharacter ? "12" : "10"}/>
                                    {validate(state.phoneNumber, "Phone Number", "required")}
                                    {state.phoneNumber && validate(state.phoneNumber, "Phone", "phone")}
                                </div>
                                <div className="col-md-4 form-input">
                                    <label>Social Security Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Security Number"
                                        value={state.socialSecurityNumber}
                                        maxLength="9"
                                        name={"socialSecurityNumber"}
                                        onChange={handleChange}
                                        disabled={status === 'view'}
                                    />
                                    {validate(state.socialSecurityNumber, "Security Number", "required")}
                                    {state.socialSecurityNumber && validate(state.socialSecurityNumber, "Security Number", "ssn")}
                                </div>
                                <div className="col-md-4 form-input">
                                    <label>Move In Date</label>
                                    <CommonDatePicker
                                        value={state.moveInDate ? new Date(state.moveInDate) : null}
                                        onChange={onChangeMoveInDate}
                                        className="form-control "
                                        name="moveInDate"
                                        placeholder="MM/DD/YYYY"
                                        maxDate={new Date()}
                                        disabled={status === 'view'}
                                        showYearDropdown='true'
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="clientInfo">
                <h3>Address Details</h3>
                <div className="form-section-dtl">
                    <div className="col-md-12 p-0">
                        <div className="row p-0">
                            <div className="col-md-12 form-input">
                                <label>Address</label>
                                {/*<textarea*/}
                                {/*    type="text"*/}
                                {/*    className="form-control"*/}
                                {/*    placeholder="Enter address"*/}
                                {/*    value={state.address}*/}
                                {/*    name={"address"}*/}
                                {/*    onChange={handleChange}*/}
                                {/*    disabled={status === 'view'}*/}
                                {/*></textarea>*/}


                                <PlacesAutocomplete
                                    value={state.address}
                                    onChange={handleAddressSearch}
                                    onSelect={handleSelect}
                                    disabled={status === 'view'}
                                    // searchOptions={
                                    //     { types: 'address'}
                                    // }
                                >
                                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                        <div className="autocompleteOuter">
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Search Address',
                                                    className: 'location-search-input form-control',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div className="loader">Loading...</div>}
                                                {suggestions.map((suggestion, suggKey) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        // eslint-disable-next-line react/jsx-key
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className
                                                            })}
                                                        >
                                                                            <span
                                                                                key={suggKey}>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>


                                {validate(state.address, "Address", "required")}
                            </div>
                            <div className="col-md-4 form-input">
                                <label>City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter City"
                                    value={state.city}
                                    name={"city"}
                                    onChange={handleChange}
                                    disabled={status === 'view'}
                                />
                                {validate(state.city, "City", "required")}
                                {state.city && validate(state.city, "City", "letter")}
                            </div>
                            <div className="col-md-4 form-input">
                                <label>State </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter State"
                                    value={state.stateCode}
                                    name={"stateCode"}
                                    onChange={handleChange}
                                    disabled={props.status === 'view'}
                                />
                                {validate(state.stateCode, "State", "required")}
                                {state.stateCode && validate(state.stateCode, "State", "letter")}
                            </div>
                            <div className="col-md-4 form-input">
                                <label>Zip Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Zip Code"
                                    value={state.postalCode}
                                    maxLength="5"
                                    name={"postalCode"}
                                    onChange={handleChange}
                                    disabled={status === 'view'}
                                />
                                {validate(state.postalCode, "Zip Code", "required")}
                                {state.postalCode && validate(state.postalCode, "Zip Code", "zip")}
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default PersonalInformation;
