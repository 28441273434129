/* eslint-disable no-unused-vars */
import axios from "axios";
import { API_ROUTE, AUTH_ROUTE } from "../config/constants";

const innagoGateWay = axios.create({
  baseURL: API_ROUTE,
  timeout: 20000
});

innagoGateWay.interceptors.response.use(
  res => {
    return res;
  },
  function(res) {
    if (res.response && res.response.status === 401)  {
      localStorage.clear();
      window.location.href = "/";
    }
    // else if(res.response && res.response.status === 404){
    //     window.location.href = '/error*';
    // }
    return res;
  }
);
innagoGateWay.interceptors.request.use(function(config) {
  const token = localStorage.getItem("token");
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  return config;
});

export default innagoGateWay;

export const innagoAuthenticator = axios.create({
  baseURL: AUTH_ROUTE,
  timeout: 20000
});
