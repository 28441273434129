//add Property
export const ADD_PROPERTY_REQUEST = "ADD_PROPERTY_REQUEST"
export const ADD_PROPERTY_SUCCESS = "ADD_PROPERTY_SUCCESS"
export const ADD_PROPERTY_FAILURE = "ADD_PROPERTY_FAILURE"

//get Zillow Data
export const GET_ZILLOW_DATA_REQUEST = "GET_ZILLOW_DATA_REQUEST"
export const GET_ZILLOW_DATA_SUCCESS = "GET_ZILLOW_DATA_SUCCESS"
export const GET_ZILLOW_DATA_FAILURE = "GET_ZILLOW_DATA_FAILURE"

// Update Property Image
export const UPDATE_PROPERTY_IMAGE_REQUEST = "UPDATE_PROPERTY_IMAGE_REQUEST"
export const UPDATE_PROPERTY_IMAGE_SUCCESS = "UPDATE_PROPERTY_IMAGE_SUCCESS"
export const UPDATE_PROPERTY_IMAGE_FAILURE = "UPDATE_PROPERTY_IMAGE_FAILURE"

//Update Property
export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST"
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS"
export const UPDATE_PROPERTY_FAILURE = "UPDATE_PROPERTY_FAILURE"

//Delete Property
export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST"
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS"
export const DELETE_PROPERTY_FAILURE = "DELETE_PROPERTY_FAILURE"

// update default image
export const MAKE_IMAGE_DEFAULT_REQUEST = "MAKE_IMAGE_DEFAULT_REQUEST"
export const MAKE_IMAGE_DEFAULT_SUCCESS = "MAKE_IMAGE_DEFAULT_SUCCESS"
export const MAKE_IMAGE_DEFAULT_FAILURE = "MAKE_IMAGE_DEFAULT_FAILURE"

// delet property image
export const DELETE_PROPERTY_IMAGE_REQUEST = "DELETE_PROPERTY_IMAGE_REQUEST"
export const DELETE_PROPERTY_IMAGE_SUCCESS = "DELETE_PROPERTY_IMAGE_SUCCESS"
export const DELETE_PROPERTY_IMAGE_FAILURE = "DELETE_PROPERTY_IMAGE_FAILURE"

// delet property UNIT
export const DELETE_PROPERTY_UNIT_REQUEST = "DELETE_PROPERTY_UNIT_REQUEST"
export const DELETE_PROPERTY_UNIT_SUCCESS = "DELETE_PROPERTY_UNIT_SUCCESS"
export const DELETE_PROPERTY_UNIT_FAILURE = "DELETE_PROPERTY_UNIT_FAILURE"