import {combineReducers} from 'redux';
import loginReducer from '../components/Login/reducer';
import tenantReducer from '../components/Tenants/reducer';
import commonReducer from '../components/Common/reducer';
import propertyReducer from '../components/Properties/reducer';
import expenseReducer from '../components/Expense/reducer';
import invoiceReducer from '../components/Invoice/reducer';
import maintenanceReducer from '../components/Maintenance/reducer';
import leaseReducer from '../components/Leases&Files/reducer';
import dashboardReducer from '../components/DashBoard/reducer';
import settingsReducer from '../components/Settings/reducer';
import messageReducer from '../components/Messaging/reducer';
import boardReducer from '../components/Board/reducer';
import profileReducer from '../components/Profile/reducer';
import reportReducer from '../components/Report/reducer';
import screeningReducer from '../components/ScreeningPublic/reducer';
import plaidReducer from '../components/Plaid/reducer'

const rootReducer = combineReducers({
    loginReducer,
    tenantReducer,
    commonReducer,
    propertyReducer,
    expenseReducer,
    invoiceReducer,
    maintenanceReducer,
    leaseReducer,
    dashboardReducer,
    settingsReducer,
    messageReducer,
    profileReducer,
    reportReducer,
    screeningReducer,
    plaidReducer,
    boardReducer
});
export default rootReducer;
