import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//update password
export const updatePassword = (value) =>
    innagoGateWay.put(serviceEndpoints.profileEndpoints.changePassword, value);

export const editProfile = (value) =>
    innagoGateWay.put(serviceEndpoints.profileEndpoints.profile, value);

export const getProfile = () =>
    innagoGateWay.get(serviceEndpoints.profileEndpoints.profile);

export const updateTenantPassword = (params) =>
    innagoGateWay.put(serviceEndpoints.profileEndpoints.updateTenantPassword, params);

