import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//get all the maintenance details
export const getMaintenance = (params) =>
    innagoGateWay.get(serviceEndpoints.maintenanceEndpoints.maintenance, { params });

//add all the maintenance details
export const addMaintenance = (value) =>
    innagoGateWay.post(serviceEndpoints.maintenanceEndpoints.maintenance, value);

//update all the maintenance details
export const updateMaintenance = (value) =>
    innagoGateWay.put(serviceEndpoints.maintenanceEndpoints.maintenance, value);

//closeMaintenance
export const closeMaintenance = (value) =>
    innagoGateWay.put(serviceEndpoints.maintenanceEndpoints.closeMaintenace, value);

//delete maintenance
export const deleteMaintenance = (data) =>
    innagoGateWay.delete(serviceEndpoints.maintenanceEndpoints.maintenance, { data });

//VIEW maintenance
export const viewMaintenanceById = (params) =>
    innagoGateWay.get(serviceEndpoints.maintenanceEndpoints.viewMaintenance, { params });

//get category maintenance
export const getCategoryList = (params) =>
    innagoGateWay.get(serviceEndpoints.maintenanceEndpoints.getCategoryList, { params });

//add all the maintenance details
export const maintenanceAdd = (value) =>
    innagoGateWay.post(serviceEndpoints.maintenanceEndpoints.maintenanceAdd, value);

//update all the maintenance details
export const maintenanceUpdate = (value) =>
    innagoGateWay.put(serviceEndpoints.maintenanceEndpoints.maintenanceAdd, value);


//add all the maintenance ticket details
export const addMaintenanceTicket = (value) =>
    innagoGateWay.put(serviceEndpoints.maintenanceEndpoints.getMaintenanceTicket, value);


//get all the tenant maintenance ticket details
export const getTenantMaintenanceDetails = (params) =>
    innagoGateWay.get(serviceEndpoints.maintenanceEndpoints.getTenantMaintenceDetails, { params });

//get maintenance by id
export const getMaintenanceById = (id) =>
    innagoGateWay.get(`${serviceEndpoints.maintenanceEndpoints.getMaintenanceDetailsById}/${id}`);


//get advanced category list
export const getAdvancedSearchDetails = (id) =>
    innagoGateWay.get(`${serviceEndpoints.maintenanceEndpoints.getMaintenanceDetailsById}/${id}`);

//delete Note
export const editNote = (data, params) =>
    innagoGateWay.put(serviceEndpoints.maintenanceEndpoints.editNoteDetails, data, { params });