import React from "react";
import {connect} from 'react-redux';
import action from "../action";
import {withRouter} from "react-router-dom"

class SuccessModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {hideSuccessModal, modalContents:{title, message}} = this.props;
        return (
            <div className="modal success-modal-container" id="modalId">
                <div className="modal-dialog modal-success">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="icon-box">
                                <i className="fas fa-check"/>
                            </div>
                            <h4 className="modal-title">{title}</h4>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">{message}</p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success btn-block" onClick={hideSuccessModal}>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {...state}
};

export default withRouter(connect(mapStateToProps, {...action})(SuccessModal));