import React from "react";
import action from "../action";
import { connect } from 'react-redux';
import commonAction from '../../Common/action';
import moment from 'moment';
import * as validation from '../../Common/components/validation';
import toastr from '../../Common/components/Toaster';
import LoaderSpinner from "../../Common/components/Loader";
import AsyncSelect from 'react-select/async';
import InvoiceSplitUps from './InvoiceSplitUps';
import CreditCardInput from 'react-credit-card-input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatUsPhone } from '../../Common/components/utils';
import ClientInfo from './ClientInfoAddTenant';
import CommonDatePicker from '../../Common/components/CommonDatePicker';
import MessageBox from "../../Common/components/MessageBox";


class AddTenants extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        isSubmitted: false,
        submitted: false,
        Properties: [],
        Units: [],
        propertyId: '',
        unitId: '',
        cardName: '',
        cardNumber: '',
        cvv: '',
        selectedId: '',
        leaseStartDate: null,
        leaseEndDate: null,
        unitLoading: false,
        stateCode: '',
        postalCode: '',
        dob: '',
        address: '',
        city: '',
        socialSecurityNumber: '',
        invoice: {
            amount1: '',
            amount2: '',
            invoiceTypeId1: '',
            invoiceTypeId2: '',
            paymentTypeId1: '',
            paymentTypeId2: '',
            autoProcessing: false,
            isRecurring: true,
            startDate1: null,
            startDate2: null,
            dueDate1: null,
            dueDate2: null,
            rentId: null,
            securityDepositId: null

        },
        submitSuccess: false,
        newInvoice: [],
        allInvoice: true,
        modal: true,
        amtMsg: "",
        message: false

    }

    componentDidMount = async () => {

        await this.props.getProperty({ isIncluded: 1 });
        await this.props.getInvoiceType();
        await this.props.getPaymentDetails();
        //----------------------set data for EDIT tenant-------------------//
        if (this.props.tenantDetail && this.props.editId) {
            this.setState({ submitted: true });

            const { tenantDetail: { tenant, Invoices, lease } } = this.props;
            if (tenant) {
                this.setState({
                    submitted: false,
                    firstName: tenant.firstName,
                    lastName: tenant.lastName,
                    tenantImage: tenant.tenantImage,
                    email: tenant.email,
                    phoneNumber: formatUsPhone(tenant.phoneNumber),
                    propertyId: { value: tenant.propertyId._id, label: tenant.propertyId.propertyName },
                    propertyName: tenant.propertyName,
                    unitId: tenant.unitId,
                    // cardName: tenant.cardDetails && tenant.cardDetails[0] && tenant.cardDetails[0].cardName,
                    // cardNumber: tenant.cardDetails && tenant.cardDetails[0] && tenant.cardDetails[0].cardNumber,
                    // expiryDate: tenant.cardDetails && tenant.cardDetails[0] && tenant.cardDetails[0].expiryDate,
                    // cvv: tenant.cardDetails && tenant.cardDetails[0] && tenant.cardDetails[0].cvv,
                    // customerPaymentProfileId:tenant.cardDetails && tenant.cardDetails[0] && tenant.cardDetails[0].customerPaymentProfileId,
                    cardDetails: tenant.cardDetails,
                    city: tenant.city,
                    stateCode: tenant.stateCode,
                    postalCode: tenant.postalCode,
                    dob: tenant.dob,
                    address: tenant.permanentAddress,
                    socialSecurityNumber: tenant.socialSecurityNumber
                })
                await this.props.getUnitDetails(tenant.unitId);
            }
            if (lease) {
                this.setState({
                    leaseEndDate: lease.endDate,
                    leaseStartDate: lease.startDate
                })
            }
            //For invoices
            const newInvoice1 = Invoices && Invoices.filter((data, i) => data.defaultInvoice == 1);
            const newInvoice2 = Invoices && Invoices.filter((data, i) => data.defaultInvoice == 2);
            const newInvoice3 = Invoices && Invoices.filter((data, i) => (data.defaultInvoice != 1 && data.defaultInvoice != 2));
            let otherInvoice = Object.keys(tenant).filter(key => key.match(/otherInvoice_/g) ? tenant[key] : null)
            // else 
            if (tenant.securityDeposit && tenant.securityDeposit.length) {

                this.setState({
                    invoice: {
                        ...this.state.invoice,
                        id1: newInvoice1 && newInvoice1.length && newInvoice1[0]._id,
                        amount1: tenant.securityDeposit[0].amount,
                        paymentTypeId1: tenant.securityDeposit[0].paymentTypeId && tenant.securityDeposit[0].paymentTypeId,
                        invoiceTypeId1: tenant.securityDeposit[0].invoiceTypeId,
                        startDate1: tenant.securityDeposit[0].startDate && tenant.securityDeposit[0].startDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        dueDate1: tenant.securityDeposit[0].dueDate && tenant.securityDeposit[0].dueDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        defaultInvoice: 1
                    }
                })
            }
            else if (newInvoice1 && newInvoice1.length) {
                this.setState({
                    invoice: {
                        ...this.state.invoice,
                        id1: newInvoice1[0]._id,
                        amount1: newInvoice1[0].amount,
                        paymentTypeId1: newInvoice1[0].paymentTypeId && newInvoice1[0].paymentTypeId._id,
                        invoiceTypeId1: newInvoice1[0].invoiceTypeId._id,
                        startDate1: newInvoice1[0].startDate && newInvoice1[0].startDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        dueDate1: newInvoice1[0].dueDate && newInvoice1[0].dueDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        defaultInvoice: 1,
                        securityDepositId: tenant.securityDeposit && tenant.securityDeposit[0] ? tenant.securityDeposit[0]._id : null

                    }
                })
            }

            if (tenant.rent && tenant.rent.length) {
                this.setState({
                    invoice: {
                        ...this.state.invoice,
                        id2: newInvoice2 && newInvoice2.length ? newInvoice2[0]._id : null,
                        amount2: tenant.rent[0].amount,
                        paymentTypeId2: tenant.rent[0].paymentTypeId && tenant.rent[0].paymentTypeId._id,
                        invoiceTypeId2: tenant.rent[0].invoiceTypeId && tenant.rent[0].invoiceTypeId._id,
                        autoProcessing: tenant.rent[0].invoiceTypeId ? tenant.rent[0].autoProcessing : null,
                        startDate2: tenant.rent[0].startDate && tenant.rent[0].startDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        dueDate2: tenant.rent[0] && tenant.rent[0].dueDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        isRecurring: tenant.rent[0].invoiceTypeId ? tenant.rent[0].isRecurring : null,
                        defaultInvoice: 2
                    }
                })
            }
            else if (newInvoice2 && newInvoice2.length) {

                this.setState({
                    invoice: {
                        ...this.state.invoice,
                        id2: newInvoice2[0]._id,
                        amount2: newInvoice2[0].amount,
                        paymentTypeId2: newInvoice2[0].paymentTypeId && newInvoice2[0].paymentTypeId._id,
                        invoiceTypeId2: newInvoice2[0].invoiceTypeId._id,
                        autoProcessing: newInvoice2[0].autoProcessing && newInvoice2[0].autoProcessing,
                        startDate2: newInvoice2[0].startDate && newInvoice2[0].startDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        dueDate2: newInvoice2[0].dueDate && newInvoice2[0].dueDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        isRecurring: newInvoice2[0].isRecurring,
                        defaultInvoice: 2,
                        rentId: tenant.rent && tenant.rent[0] ? tenant.rent[0]._id : null
                    }
                })

            }

            if (tenant && otherInvoice && otherInvoice.length) {
                let tempArray = []
                otherInvoice.map((inv, key) => {
                    let invoiceDetails = newInvoice3 && newInvoice3.length && newInvoice3.find(inDetails => inDetails.defaultInvoice == key + 3)

                    if (tenant[inv][0]) {
                        tempArray.push({
                            otherId: tenant[inv][0]._id,
                            id: invoiceDetails && invoiceDetails._id,
                            title: tenant[inv][0].title,
                            amount: tenant[inv][0].amount,
                            paymentTypeId: tenant[inv][0].paymentTypeId._id,
                            invoiceTypeId: tenant[inv][0].invoiceTypeId._id,
                            startDate: tenant[inv][0].startDate && tenant[inv][0].startDate.replace(/-/g, '/')
                                .replace(/T.+/, ''),
                            dueDate: tenant[inv][0].dueDate && tenant[inv][0].dueDate.replace(/-/g, '/')
                                .replace(/T.+/, ''),
                            autoProcessing: tenant[inv][0].autoProcessing,
                            isRecurring: tenant[inv][0].isRecurring,
                            defaultInvoice: key + 3
                        })
                    }

                })
                this.setState({
                    newInvoice: tempArray
                })

            }
            else if (newInvoice3 && newInvoice3.length) {
                let tempArray = []
                newInvoice3.map((inv, key) => {
                    tempArray.push({
                        id: inv._id,
                        title: inv.title,
                        amount: inv.amount,
                        paymentTypeId: inv.paymentTypeId._id,
                        invoiceTypeId: inv.invoiceTypeId._id,
                        startDate: inv.startDate && inv.startDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        dueDate: inv.dueDate && inv.dueDate.replace(/-/g, '/')
                            .replace(/T.+/, ''),
                        autoProcessing: inv.autoProcessing,
                        isRecurring: inv.isRecurring,
                        defaultInvoice: key + 3
                    })
                })
                this.setState({
                    newInvoice: tempArray
                })
            }
            //Set unit list based on property id
            if (tenant.propertyId) {
                await this.props.getUnitsByPropertyId(tenant.propertyId._id);
                let Units = []
                this.props.commonReducer &&
                    this.props.commonReducer.unitDetails && this.props.commonReducer.unitDetails.unitList
                    && this.props.commonReducer.unitDetails.unitList.map(e => {
                        Units.push({
                            value: e._id,
                            label: e.unitName
                        })
                    })
                if (Units.length)
                    this.setState({ unitId: Units[0].value })
                this.setState({ Units });
            }
        }
        //--------------------------EDIT tenant ends--------------------//
        else {
            //-------------------set data for add tenant ------------------//
            //set dates
            const currentDate = moment(new Date()).format('MM/DD/YYYY');
            const futureMonth = moment(currentDate).add(30, 'days').format('MM/DD/YYYY');

            //Set invoice type and dates for first 2 invoices
            this.setState({
                leaseStartDate: new Date(),
                leaseEndDate: moment(currentDate).add(1, 'year')
            });

            const { commonReducer: { InvoiceTypeList, paymentMethods } } = this.props;
            const invoiceType1 = InvoiceTypeList && InvoiceTypeList.filter((e, i) => e.name == "Security Deposit")
            const invoiceType2 = InvoiceTypeList && InvoiceTypeList.filter((e, i) => e.name == "Private")
            const payment = paymentMethods && paymentMethods.filter((e, i) => e.name == "Credit Card")


            if (invoiceType1.length && invoiceType2.length) {
                this.setState({
                    invoice: {
                        ...this.state.invoice,
                        invoiceTypeId1: invoiceType1[0]._id,

                        invoiceTypeId2: invoiceType2[0]._id,
                        paymentTypeId1: payment[0] ? payment[0]._id : null,
                        paymentTypeId2: payment[0] ? payment[0]._id : null,
                        startDate1: currentDate,
                        startDate2: currentDate,
                        // dueDate1: moment(currentDate).add(1, 'year'),
                        // dueDate2: futureMonth
                        dueDate1: currentDate,
                        dueDate2: currentDate
                    }
                })
            }

            //Set property list
            const { commonReducer: { Property } } = this.props;
            const Properties = Property.propertyList && Object.values(Property.propertyList).map(e => ({
                value: e._id,
                label: e.propertyName
            }));
            this.setState({ Properties });
        }
        //----------------------data for ADD tenant ends---------------------//

        //-------------------add tenant form from property page-------------//
        if (this.props.property) {
            this.setState({
                propertyId: this.props.property,
                Properties: this.props.property
            })


            //Set unit list based on property id
            await this.props.getUnitsByPropertyId(this.props.property.value);
            let Units = [];
            const { commonReducer: { unitDetails } } = this.props;
            unitDetails.unitList && unitDetails.unitList.map(e => {
                if (e.isAvailable) {
                    Units.push({
                        value: e._id,
                        label: e.unitName
                    })
                }
            })
            if (Units.length)
                this.setState({ unitId: this.props.unitId })
            this.setState({ Units });
        }
        //--------------------add tenant form from property page ends---------------//

        //------------------set data when opening from report page-------------//
        const { reports } = this.props;
        if (reports) {
            this.setState({
                firstName: reports.firstName,
                lastName: reports.lastName,
                email: reports.email,
                phoneNumber: formatUsPhone(reports.phone),
                cardName: reports.cardDetails[0] && reports.cardDetails[0].name,
                expiryDate: reports.cardDetails[0] && reports.cardDetails[0].expiry,
                cvv: reports.cardDetails[0] && reports.cardDetails[0].cvv,
                cardNumber: reports.cardDetails[0] && reports.cardDetails[0].number
            })
        }
        //------------------data when opening from report page ends-------------//
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const { tenantReducer: { tenant: tenants, tenantsById } } = this.props;
        const { isSubmitted } = this.state;
        const { tenantReducer: { tenant: prevTenants, tenantsById: prevTenantsById } } = prevProps;

        //Message on tenant submit for add and edit
        if (isSubmitted && prevTenants !== tenants && !tenants.success) {
            toastr.error(tenants.message);
        } else if (isSubmitted && prevTenants != tenants && tenants.success) {
            toastr.success(tenants.message);
        }

        //On changing unit id amount and rent of invoices will be updated
        if ((prevState.unitId != this.state.unitId) && !this.props.editId) {
            await this.props.getUnitDetails(this.state.unitId);
            const { commonReducer: { unitDetailsByUnitId } } = this.props;
            if (unitDetailsByUnitId && unitDetailsByUnitId.length) {
                this.setState({
                    invoice: {
                        ...this.state.invoice,
                        amount1: unitDetailsByUnitId[0].deposit,
                        amount2: unitDetailsByUnitId[0].monthlyRent
                    }
                })
            }
        }
    }

    promiseOptionsProperty = async val => {
        await this.props.getProperty({ name: val, isIncluded: 1 })
        const { commonReducer: { Property } } = this.props;
        const Properties = Property.success && Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    handleChange = async (e) => {
        const { name, value } = e.target;
        if (name == 'phoneNumber') {
            this.setState({
                [name]: formatUsPhone(value)
            });
        } else if (name == "firstName" || name == "lastName") {
            this.setState({
                [name]: value,
            }, () => {
                this.setState({ cardName: this.state.firstName + " " + this.state.lastName })
            });
        } else {
            this.setState({
                [name]: value
            });
        }
        if (name == 'unitId') {
            await this.props.getUnitDetails(value);
        }
    }

    onChangeInvoice1 = (e) => {
        const { name, value } = e.target
        const { invoice } = this.state;
        if (name == 'amount') {
            this.setState({
                invoice: { ...invoice, amount1: value ? value : null }
            })
        } else if (name == 'invoiceTypeId') {
            this.setState({
                invoice: { ...invoice, invoiceTypeId1: value }
            })
        } else {
            this.setState({
                invoice: { ...invoice, paymentTypeId1: value }
            })
        }

    }

    onChangeInvoice2 = (e) => {
        const { commonReducer: { unitDetailsByUnitId } } = this.props;

        const { name, value } = e.target
        const { invoice, newInvoice } = this.state;
        if (name == 'amount') {
            this.setState({
                invoice: { ...invoice, amount2: value ? value : null }
            })
            // if (newInvoice && (parseInt(value.replace("$", "").replace(/,/g, '')) + (newInvoice && newInvoice.amount && parseInt(newInvoice.amount.toString().replace("$", "").replace(/,/g, '')))) >
            //     (unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent)) {
            //     this.setState({ amtMsg: `Please note: Total rent amount is ${unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent}` })
            // } else {
            //     this.setState({ amtMsg: "" })
            // }
            // if (parseInt(value.replace("$", "").replace(/,/g, '')) > (unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent)) {
            //     this.setState({
            //         allInvoice: false,
            //         amtMsg: `Please note: Total rent amount is ${unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent}`
            //     })
            // } else {
            //     this.setState({ amtMsg: "", allInvoice: true })
            // }
        } else if (name == 'invoiceTypeId') {
            this.setState({
                invoice: { ...invoice, invoiceTypeId2: value }
            })
        } else {
            const { commonReducer: { InvoiceTypeList, paymentMethods } } = this.props;
            let payment = paymentMethods && paymentMethods.filter((e, i) => e.name == "Credit Card")
            if (payment[0]._id === value) {
                this.setState({
                    invoice: { ...invoice, paymentTypeId2: value }
                })
            }
            else {
                this.setState({
                    invoice: { ...invoice, paymentTypeId2: value, autoProcessing: false }
                })
            }

        }
    }


    onChangeInvoice3 = (e, index) => {
        const { commonReducer: { unitDetailsByUnitId } } = this.props;
        const { name, value } = e.target
        const { newInvoice, invoice } = this.state;


        if (name == 'amount') {
            newInvoice[index][name] = value ? value.toString().replace("$", "").replace(/,/g, '') : null
            this.setState({
                newInvoice: newInvoice
            })
            // if ((parseInt(invoice.amount2.toString().replace("$", "").replace(/,/g, '')) + parseInt(value.replace("$", "").replace(/,/g, ''))) > (unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent)) {
            //     this.setState({amtMsg: `Please note: Total rent amount is ${unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent}`})
            // } else {
            //     this.setState({amtMsg: ""})
            // }
        } else {
            const { commonReducer: { InvoiceTypeList, paymentMethods } } = this.props;
            let payment = paymentMethods && paymentMethods.filter((e, i) => e.name == "Credit Card")
            if (payment[0]._id === value) {
                newInvoice[index][name] = value
            }
            else {
                newInvoice[index][name] = value
                newInvoice[index]["autoProcessing"] = false
            }

            this.setState({
                newInvoice: newInvoice
            })
        }

    }

    addInvoice = async () => {
        const { commonReducer: { InvoiceTypeList, paymentMethods } } = this.props;
        const currentDate = new Date();
        let payment = paymentMethods && paymentMethods.filter((e, i) => e.name == "Credit Card")
        const futureMonth = moment(currentDate).add(1, 'M').format('YYYY-MM-DD');
        let count = 3 + this.state.newInvoice.length
        let temp = {
            title: '',
            amount: '',
            startDate: currentDate,
            dueDate: currentDate,
            paymentTypeId: payment[0]._id,
            invoiceTypeId: '',
            autoProcessing: false,
            isRecurring: true,
            defaultInvoice: count,
            description: ''
        }
        let tempArray = [...this.state.newInvoice]
        tempArray.push(temp)
        this.setState({
            newInvoice: tempArray,
            allInvoice: false
        })
    }

    closeInvoice = (index) => {
        let tempArray = [...this.state.newInvoice];
        tempArray.splice(index, 1)
        this.setState({
            newInvoice: tempArray,
            allInvoice: true
        })
        // this.setState({newInvoice: null, allInvoice: true})
    }

    onChangeDate = value => {
        this.setState({
            expiryDate: moment(new Date(value)).format('MM/YY')
        })
    }

    onChangeDob = value => {
        this.setState({
            dob: value
        })
    }

    onChangeStartDate1 = value => {
        this.setState({
            invoice: {
                ...this.state.invoice,
                startDate1: value
            }
        })
        // if (moment(value).diff(moment(this.state.invoice.dueDate1), 'days') <= 0) {
        //     this.setState({ invoice1Error: '' })
        // } else {
        //     this.setState({ invoice1Error: "Invoice end date should be greater than invoice start date" });
        // }
    }

    onChangeDueDate1 = value => {
        this.setState({
            invoice: {
                ...this.state.invoice,
                dueDate1: value
            }
        })
        // if (moment(value).diff(moment(this.state.invoice.startDate1), 'days') >= 0) {
        //     this.setState({ invoice1Error: '' })
        // } else {
        //     this.setState({ invoice1Error: "Invoice end date should be greater than invoice start date" });
        // }
    }

    onChangeStartDate2 = value => {
        this.setState({
            invoice: {
                ...this.state.invoice,
                startDate2: value
            }
        })
        // if (moment(value).diff(moment(this.state.invoice.dueDate2), 'days') <= 0) {
        //     this.setState({ invoice2Error: '' })
        // } else {
        //     this.setState({ invoice2Error: "Invoice end date should be greater than invoice start date" });
        // }
    }

    onChangeDueDate2 = value => {
        this.setState({
            invoice: {
                ...this.state.invoice,
                dueDate2: value
            }
        })
        // if (moment(value).diff(moment(this.state.invoice.startDate2), 'days') >= 0) {
        //     this.setState({ invoice2Error: '' })
        // } else {
        //     this.setState({ invoice2Error: "Invoice end date should be greater than invoice start date" });
        // }
    }

    onChangeStartDate = (value, index) => {
        const { newInvoice, invoice } = this.state;
        newInvoice[index].startDate = value
        this.setState({
            newInvoice: newInvoice
        })
        // if (moment(value).diff(moment(this.state.newInvoice.dueDate), 'days') <= 0) {
        //     this.setState({ invoice3Error: '' })
        // } else {
        //     this.setState({ invoice3Error: "Invoice end date should be greater than invoice start date" });
        // }
    }

    onChangeDueDate = (value, index) => {

        const { newInvoice, invoice } = this.state;
        newInvoice[index].dueDate = value
        this.setState({
            newInvoice: newInvoice
        })

        // if (moment(value).diff(moment(this.state.newInvoice.startDate), 'days') >= 0) {
        //     this.setState({ invoice3Error: '' })
        // } else {
        //     this.setState({ invoice3Error: "Invoice end date should be greater than invoice start date" });
        // }
    }

    onChangeProperty = async (e) => {
        this.setState({ propertyId: e });
        this.setState({ unitLoading: true })
        await this.props.getUnitsByPropertyId(e.value);
        this.setState({ unitLoading: false })
        const { commonReducer: { unitDetails } } = this.props;
        let Units = []
        unitDetails && unitDetails.unitList && unitDetails.unitList.map(e => {
            if (e.isAvailable) {
                Units.push({
                    value: e._id,
                    label: e.unitName
                })
            }
        })
        if (Units.length)
            this.setState({ unitId: Units[0].value })
        this.setState({ Units });
    }

    handleInvoiceCheckBox1 = (e, i, id) => {
        const { name, value, checked } = e.target;
        const { commonReducer: { InvoiceTypeList, paymentMethods } } = this.props;

        const payment = paymentMethods && paymentMethods.filter((e, i) => e.name == "Credit Card")


        if (name == "autoProcessing" && checked === true) {
            if (!this.props.editId && this.state.cardNumber && this.state.expiryDate && this.state.cardName) {
                if (id === payment[0]._id) {
                    this.setState({
                        invoice: {
                            ...this.state.invoice,
                            [name]: name === 'type' ? value : checked,
                            isRecurring: true
                        }
                    })
                }

                else {
                    toastr.error("Please Select payment type as credit card")
                }

            } else if (this.props.editId) {
                if (this.state.cardDetails[0] && this.state.cardDetails[0].cardNumber) {
                    if (id === payment[0]._id) {
                        this.setState({
                            invoice: {
                                ...this.state.invoice,
                                [name]: name === 'type' ? value : checked,
                                isRecurring: true
                            }
                        })
                    }

                    else {
                        toastr.error("Please Select payment type as credit card")
                    }
                }
                else {
                    toastr.error("Please enter card details to provide auto processing")
                }
            } else {
                toastr.error("Please enter card details to provide auto processing")
            }
        } else if (name == "isRecurring" && this.state.invoice.autoProcessing === true) {
            this.setState({
                invoice: {
                    ...this.state.invoice,
                    isRecurring: true
                }
            })
        } else {
            this.setState({
                invoice: {
                    ...this.state.invoice,
                    [name]: name === 'type' ? value : checked
                }
            })


        }

    }

    handleInvoiceCheckBox2 = (e, index, id) => {
        const { name, value, checked } = e.target;
        const { commonReducer: { unitDetailsByUnitId } } = this.props;
        const { commonReducer: { InvoiceTypeList, paymentMethods } } = this.props;

        const payment = paymentMethods && paymentMethods.filter((e, i) => e.name == "Credit Card")
        const { newInvoice, invoice } = this.state;
        if (name == "autoProcessing" && checked === true) {
            if (!this.props.editId && this.state.cardNumber && this.state.expiryDate && this.state.cardName) {
                if (id === payment[0]._id) {

                    newInvoice[index][name] = name === 'type' ? value : checked
                    newInvoice[index].isRecurring = name === 'type' ? value : checked
                    this.setState({
                        newInvoice: newInvoice,
                    })
                }
                else {
                    toastr.error("Please Select payment type as credit card")
                }

            } else if (this.props.editId) {
                if (this.state.cardDetails[0] && this.state.cardDetails[0].cardNumber) {
                    if (id === payment[0]._id) {

                        newInvoice[index][name] = name === 'type' ? value : checked
                        newInvoice[index].isRecurring = name === 'type' ? value : checked
                        this.setState({
                            newInvoice: newInvoice,
                        })
                    }
                    else {
                        toastr.error("Please Select payment type as credit card")
                    }
                }
                else {
                    toastr.error("Please enter card details to provide auto processing")
                }
            } else {
                toastr.error("Please enter card details to provide auto processing")
            }
        }
        else if (name == "isRecurring" && newInvoice[index].autoProcessing === true) {
            newInvoice[index].isRecurring = true
            this.setState({
                newInvoice: newInvoice,
            })
        } else {
            newInvoice[index][name] = name === 'type' ? value : checked
            this.setState({
                newInvoice: newInvoice,
            })
        }

    }

    handleCardNumberChange = e => {
        this.setState({
            cardNumber: e.target.value
        })
    }

    handleCardExpiryChange = (e) => {
        this.setState({
            expiryDate: e.target.value
        })
    }

    handleCvvChange = (e) => {
        this.setState({
            cvv: e.target.value
        })
    }

    onChangeLeaseStartDate = (e) => {
        let tempArray = this.state.newInvoice
        if (!this.props.editId && this.state.newInvoice.length) {
            tempArray.map(te => {
                return te.startDate = e,
                    te.dueDate = moment(e).add(1, 'month')
            }

            )
            this.setState({
                newInvoice: tempArray
            })

        }
        if (!this.props.editId) {
            this.setState({
                leaseStartDate: e,
                leaseEndDate: moment(e).add(1, 'year'),

                invoice: {
                    ...this.state.invoice,
                    startDate1: e,
                    startDate2: e,
                    dueDate1: moment(e).add(1, 'year'),
                    dueDate2: moment(e).add(1, 'month')

                }
            })
        }
        else {
            this.setState({
                leaseStartDate: e,
                leaseEndDate: moment(e).add(1, 'year'),

            })
        }


        if (moment(e).diff(moment(this.state.leaseEndDate), 'days') < 0) {
            this.setState({ leaseError: '' })
        } else {
            this.setState({ leaseError: "Lease end date should be greater than lease start date" });
        }
    }

    onChangeLeaseEndDate = (e) => {
        this.setState({
            leaseEndDate: e,
        })
        if (moment(e).diff(moment(this.state.leaseStartDate), 'days') > 0) {
            this.setState({ leaseError: '' })
        } else {
            this.setState({ leaseError: "Lease end date should be greater than lease start date" });
        }
    }

    validate = (value, name, type) => {
        const { isSubmitted } = this.state;
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
                case "number":
                    return <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
                case "email":
                    return <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div>
                case "letter":
                    return <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "cardNumber":
                    return <div className={"errorMsg"}>{validation.CARD_NUMBER(value, name)}</div>
                case "code":
                    return <div className={"errorMsg"}>{validation.CODE(value, name)}</div>
                case "ssn":
                    return <div className={"errorMsg"}>{validation.SSN(value, name)}</div>
                case "zip":
                    return <div className={"errorMsg"}>{validation.ZIP_CODE(value, name)}</div>
                case "phone":
                    return <div className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div>
            }
        } else return null
    }

    handleSubmit = async () => {
        this.setState({ isSubmitted: true });
        const {
            firstName, lastName, email, phoneNumber, invoice, propertyId, unitId, expiryDate,
            cardName, cardNumber, cvv, newInvoice,
            allInvoice, leaseEndDate, leaseStartDate, leaseError, cardDetails
        } = this.state;
        let subInvoiceError = allInvoice
        let tempInvoices = newInvoice
        newInvoice.map((invoice, key) => {
            tempInvoices[key]["startDate"] = moment(invoice.startDate).format('MM/DD/YYYY')
            tempInvoices[key]["dueDate"] = moment(invoice.dueDate).format('MM/DD/YYYY')
        })
        let invArray1 = [{
            id: this.props.tenantDetail && this.props.tenantDetail.Invoices && this.props.tenantDetail.Invoices.length ? invoice.id1 && invoice.id1 : null,
            title: "Security Deposit",
            amount: invoice.amount1 ? invoice.amount1.toString().replace("$", "").replace(/,/g, '') : null,
            // startDate: moment(new Date(invoice.startDate1)).endOf('day').toISOString(),
            // dueDate: moment(new Date(invoice.dueDate1)).endOf('day').toISOString(),
            startDate: moment(invoice.startDate1).format('MM/DD/YYYY'),
            // .replace(/-/g, '/')
            // .replace(/T.+/, ''),
            dueDate: moment(invoice.dueDate1).format('MM/DD/YYYY'),
            // .replace(/-/g, '/')
            // .replace(/T.+/, ''),
            paymentTypeId: invoice.paymentTypeId1,
            invoiceTypeId: invoice.invoiceTypeId1,
            defaultInvoice: 1,

        },
        {
            id: this.props.tenantDetail && this.props.tenantDetail.Invoices && this.props.tenantDetail.Invoices.length ? invoice.id2 && invoice.id2 : null,
            title: 'Rent',
            amount: invoice.amount2 ? invoice.amount2.toString().replace("$", "").replace(/,/g, '') : null,
            // startDate: moment(new Date(invoice.startDate2)).endOf('day').toISOString(),
            // dueDate: moment(new Date(invoice.dueDate2)).endOf('day').toISOString(),
            startDate: moment(invoice.startDate1).format('MM/DD/YYYY'),
            // .replace(/-/g, '/')
            // .replace(/T.+/, ''),
            dueDate: moment(invoice.startDate1).format('MM/DD/YYYY'),
            // .replace(/-/g, '/')
            // .replace(/T.+/, ''),
            paymentTypeId: invoice.paymentTypeId2,
            isRecurring: invoice.isRecurring,
            autoProcessing: invoice.autoProcessing,
            invoiceTypeId: invoice.invoiceTypeId2,
            defaultInvoice: 2
        },]
        let tempArray = tempInvoices.concat(invArray1)
        //add isDEleted to removed invoice
        let mergedArray = tempArray
        if (this.props.tenantDetail && this.props.editId) {
            const { tenantDetail: { tenant } } = this.props;
            const { tenantDetail: { Invoices } } = this.props;
            let tempInvoiceArray = [];
            let invoiceArrayValue = []

            Invoices && Invoices.map((invoice, index) => {
                let tempInvoice = {
                    id: invoice._id,
                    title: invoice.title,
                    amount: invoice.amount,
                    // startDate: moment(new Date(invoice.startDate)).endOf('day').toISOString(),
                    // dueDate: moment(new Date(invoice.dueDate)).endOf('day').toISOString(),
                    startDate: moment(invoice.startDate).format('MM/DD/YYYY'),
                    // .replace(/-/g, '/')
                    // .replace(/T.+/, ''),
                    dueDate: moment(invoice.dueDate).format('MM/DD/YYYY'),
                    // .replace(/-/g, '/')
                    // .replace(/T.+/, ''),
                    paymentTypeId: invoice.paymentTypeId._id,
                    invoiceTypeId: invoice.invoiceTypeId._id,
                    defaultInvoice: invoice.defaultInvoice,
                }
                tempInvoiceArray.push(tempInvoice)
            })




            tempInvoiceArray && tempInvoiceArray.length && tempInvoiceArray.map(invoice => {
                let deletedValue = tempArray.find(na => na.id === invoice.id)
                if (!deletedValue) {

                    let temp = {
                        id: invoice.id,
                        title: invoice.title,
                        amount: invoice.amount ? invoice.amount.toString().replace("$", "").replace(/,/g, '') : null,
                        // startDate: moment(new Date(invoice.startDate)).endOf('day').toISOString(),
                        // dueDate: moment(new Date(invoice.dueDate)).endOf('day').toISOString(),
                        startDate: moment(invoice.startDate).format('MM/DD/YYYY'),
                        // .replace(/-/g, '/')
                        // .replace(/T.+/, ''),
                        dueDate: moment(invoice.dueDate).format('MM/DD/YYYY'),
                        // .replace(/-/g, '/')
                        // .replace(/T.+/, ''),
                        paymentTypeId: invoice.paymentTypeId,
                        invoiceTypeId: invoice.invoiceTypeId,
                        defaultInvoice: invoice.defaultInvoice,
                        isDeleted: "true"
                    }
                    invoiceArrayValue.push(temp)
                }

            })

            mergedArray = tempArray.concat(invoiceArrayValue)

        }


        //data to send for adding tenant     
        const req = {
            id: this.props.editId && this.props.editId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber ? phoneNumber.replace(/\D/g, '') : '',
            propertyId: propertyId.value,
            unitId: unitId,
            startDate: new Date(leaseStartDate),
            endDate: new Date(leaseEndDate),
            profilePic: [],
            cardDetails: (this.props.editId) ? cardDetails : {
                expiryDate: expiryDate,
                cardName: cardName,
                cardNumber: cardNumber ? cardNumber.replace(/ +/g, "") : null,
                cvv: cvv,
                defaultCard: 1
            },
            // cardDetails:[],
            rent: [{
                id: this.props.tenantDetail && this.props.tenantDetail.tenant && this.props.tenantDetail.tenant.rent && this.props.tenantDetail.tenant.rent[0] && this.props.tenantDetail.tenant.rent[0]._id,
                title: 'Rent',
                amount: invoice.amount2 ? invoice.amount2.toString().replace("$", "").replace(/,/g, '') : null,
                startDate: moment(invoice.startDate2).format('MM/DD/YYYY'),
                dueDate: moment(invoice.dueDate2).format('MM/DD/YYYY'),
                paymentTypeId: invoice.paymentTypeId2,
                isRecurring: invoice.isRecurring,
                autoProcessing: invoice.autoProcessing,
                invoiceTypeId: invoice.invoiceTypeId2,
                defaultInvoice: 2
            }],
            securityDeposit: [{
                id: this.props.tenantDetail && this.props.tenantDetail.tenant && this.props.tenantDetail.tenant.securityDeposit && this.props.tenantDetail.tenant.securityDeposit[0] && this.props.tenantDetail.tenant.securityDeposit[0]._id,
                title: "Security Deposit",
                amount: invoice.amount1 ? invoice.amount1.toString().replace("$", "").replace(/,/g, '') : null,
                startDate: moment(invoice.startDate1).format('MM/DD/YYYY'),
                dueDate: moment(invoice.dueDate1).format('MM/DD/YYYY'),
                paymentTypeId: invoice.paymentTypeId1,
                invoiceTypeId: invoice.invoiceTypeId1,
                defaultInvoice: 1,
            }],
            invoice: mergedArray

        }
        let dataResult = {}
        newInvoice.map((invoice, key) => {
            dataResult[`otherInvoice_${key + 1}`] = []
            dataResult[`otherInvoice_${key + 1}`][0] = Object.create(invoice)
            return (dataResult[`otherInvoice_${key + 1}`][0].id = invoice.otherId,
                dataResult[`otherInvoice_${key + 1}`][0].title = invoice.title,
                dataResult[`otherInvoice_${key + 1}`][0].amount = invoice.amount ? invoice.amount.toString().replace("$", "").replace(/,/g, '') : null,
                // dataResult[`otherInvoice_${key + 1}`][0].startDate = moment(new Date(invoice.startDate)).endOf('day').toISOString(),
                // dataResult[`otherInvoice_${key + 1}`][0].dueDate = moment(new Date(invoice.dueDate)).endOf('day').toISOString(),
                dataResult[`otherInvoice_${key + 1}`][0].startDate = moment(invoice.startDate).format('MM/DD/YYYY'),
                dataResult[`otherInvoice_${key + 1}`][0].dueDate = moment(invoice.dueDate).format('MM/DD/YYYY'),
                dataResult[`otherInvoice_${key + 1}`][0].paymentTypeId = invoice.paymentTypeId,
                dataResult[`otherInvoice_${key + 1}`][0].invoiceTypeId = invoice.invoiceTypeId,
                dataResult[`otherInvoice_${key + 1}`][0].defaultInvoice = invoice.defaultInvoice,
                dataResult[`otherInvoice_${key + 1}`][0].isRecurring = invoice.isRecurring,
                dataResult[`otherInvoice_${key + 1}`][0].autoProcessing = invoice.autoProcessing
            )
        })
        let deletedOtherInvoices = {}
        //------To Add empty array for delete other invoice---//
        if (this.props.tenantDetail && this.props.editId) {

            let otherInvoice = Object.keys(this.props.tenantDetail.tenant).filter(key => key.match(/otherInvoice_/g) ? this.props.tenantDetail.tenant[key] : null)
            let currentInvoice = Object.keys(dataResult).filter(key => key.match(/otherInvoice_/g) ? dataResult[key] : null)

            otherInvoice && otherInvoice.length && otherInvoice.map(oi => {
                let deletedOther = currentInvoice.find(na => na === oi)

                if (!deletedOther) {
                    deletedOtherInvoices = { ...deletedOtherInvoices, [oi]: [] }
                }

            })


        }
        const finalReq = { ...req, ...dataResult, ...deletedOtherInvoices }


        if (!this.props.editId && !req.cardDetails.cardNumber) {
            finalReq.cardDetails = []
        }
        const { commonReducer: { unitDetailsByUnitId } } = this.props;

        if (newInvoice && newInvoice.length) {

            //Validation of the 3rd invoice added

            let totalamt = 0
            // newInvoice.map(amtData => totalamt = parseInt(amtData.amount.toString().replace("$", "").replace(/,/g, '')))

            newInvoice.forEach(amtData => {
                totalamt += amtData.amount ? parseInt(amtData.amount.toString().replace("$", "").replace(/,/g, '')) : 0
            });
            let amt = invoice.amount2 ? parseInt(invoice.amount2.toString().replace("$", "").replace(/,/g, '')) : 0 + totalamt
            this.setState({ allInvoice: false });
            newInvoice.map(amtData => {

                if (amtData.title && amtData.amount != null && amtData.paymentTypeId && amtData.invoiceTypeId) {
                    subInvoiceError = true
                    // if (amt > (unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent)) {
                    //     this.setState({ amtMsg: `Please note: Total rent amount is ${unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent}` })
                    // } else {
                    //     this.setState({ amtMsg: "" })
                    // }
                    this.setState({ allInvoice: true });
                } else {
                    subInvoiceError = false
                    this.setState({ allInvoice: false });
                    //validation for amount of the second invoice
                    // if (invoice.amount2.toString().replace("$", "").replace(/,/g, '') > (unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent)) {
                    //     this.setState({allInvoice: false});
                    //     this.setState({amtMsg: `Please note: Total rent amount is ${unitDetailsByUnitId.length && unitDetailsByUnitId[0].monthlyRent}`})
                    // }
                }
            })

        }


        if (firstName && lastName && email && phoneNumber && propertyId && unitId
            && invoice.amount1 != null && invoice.amount2 != null && invoice.paymentTypeId1 && invoice.paymentTypeId2 &&
            invoice.invoiceTypeId1 && invoice.invoiceTypeId2 && subInvoiceError
        ) {
            if (validation.EMAIL(email, 'email') == true &&
                validation.LETTER_ONLY(firstName, 'firstName') == true &&
                validation.LETTER_ONLY(lastName, 'lastName') == true) {

                this.setState({ submitSuccess: true });
                await this.props.addTenant(finalReq);

                if (this.props.tenantReducer.tenant.success) {

                    //Get details of the above added tenant
                    await this.props.getTenantId(this.props.editId || this.props.tenantReducer.tenant.data.tenant._id);
                    this.props.onCancel();
                    setTimeout(async () => {
                        this.setState({ submitSuccess: false });
                        this.setState({ isSubmitted: true });

                        //clear the state
                        this.setState({
                            firstName: '',
                            lastName: '',
                            tenantImage: '',
                            email: '',
                            phoneNumber: '',
                            propertyId: '',
                            unitId: '',
                            cardName: '',
                            cardNumber: '',
                            cvv: '',
                            invoice: {},
                            newInvoice: null
                        })

                        //For payment of tenant
                        if (this.props.tenantReducer.tenantsById.tenant) {
                            const { tenantReducer: { tenantsById: { tenant: { tenant } } } } = this.props;
                            if (tenant.cardDetails && tenant.cardDetails.length && tenant.cardDetails[0].cardNumber) {
                                // const paymentRequest = {
                                //     businessID: tenant.propertyId && tenant.propertyId.paymentId,
                                //     tenantID: this.props.tenantReducer.tenant.data.tenant._id,
                                //     creditCardDetails: {
                                //         CardNumber: tenant.cardDetails && tenant.cardDetails[0].cardNumber.replace(/ +/g, ""),
                                //         ExpirationDate: tenant.cardDetails && tenant.cardDetails[0].expiryDate.replace(/ +/g, "").replace("/", "")
                                //     },
                                //     customerDetails: {
                                //         FirstName: tenant.firstName,
                                //         LastName: tenant.lastName,
                                //         Address: tenant.address,
                                //         phoneNumber: tenant.phoneNumber,
                                //         email: tenant.email,
                                //         tenantID: this.props.tenantReducer.tenant.data.tenant._id
                                //     }
                                // }
                                const paymentRequest = {
                                    tenantId: this.props.tenantReducer.tenant.data.tenant._id,
                                    status: "1"
                                }
                                // setTimeout(async () => {
                                //     await this.props.addPaymentTenant(paymentRequest);
                                // }, 15000);
                            }
                        }
                        //Show the lease modal to view and send
                        !this.props.editId && this.props.leaseModal(this.props.tenantReducer.tenant.data.tenant._id);

                        //Get tenant list
                        await this.props.getTenantId(this.props.tenantReducer.tenant.data.tenant._id)
                        await this.props.getTenant(this.props.params);
                    }, 1000);
                } else {
                    this.setState({ submitSuccess: false });
                }
            }
        }
    }

    render() {
        const { submitSuccess, Properties, submitted, leaseEndDate, leaseStartDate } = this.state;
        const { commonReducer: { InvoiceTypeList, paymentMethods, unitDetails } } = this.props;
        return (
            <div className="modal addTenant" id="modalId" role="dialog" style={{ display: 'block' }}>
                {/* <ToastContainer /> */}

                {submitSuccess && <LoaderSpinner />}
                {/* {submitted ? <LoaderSpinner /> : */}
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"
                                id="exampleModalLabel">{this.props.editId ? "Update Tenant" : "Add new Tenant"}</h5>
                            <button type="button" className="close" onClick={() => this.props.onCancel()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ClientInfo state={this.state} validate={this.validate} handleChange={this.handleChange}
                                onChangeDob={this.onChangeDob} />
                            <div className="col-md-12 d-flex propertySec p-0">
                                <div className="clientInfo propOtr">
                                    <h3>Property</h3>
                                    <div className="formSection">
                                        <div className="row outrForm p-0 m-0">
                                            <div className="col-md-6 formInp">
                                                <label>Choose Property</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions={Properties}
                                                    placeholder={"Search Property"}
                                                    loadOptions={this.promiseOptionsProperty}
                                                    name="propertyId"
                                                    onChange={this.onChangeProperty}
                                                    value={this.state.propertyId && this.state.propertyId}
                                                    isDisabled={this.props.property}

                                                />
                                                {this.validate(this.state.propertyId, "Property", "required")}
                                            </div>
                                            <div className="col-md-6 formInp">

                                                <label>Unit</label>
                                                <div className="selctOtr">
                                                    <select className="form-control"
                                                        name="unitId"
                                                        onChange={(e) => this.handleChange(e)}
                                                    >
                                                        {/* <option value="">Select Unit</option> */}
                                                        {
                                                            this.state.Units.map((e, i) => {

                                                                return <option
                                                                    value={e.value}
                                                                    key={i}
                                                                    selected={this.state.Units && e.value == this.state.unitId}
                                                                >
                                                                    {e.label}
                                                                </option>
                                                            })
                                                        }
                                                    </select>
                                                    {this.state.unitLoading &&
                                                        <div className="selectOtrLoad">
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    }
                                                    {this.validate(this.state.unitId, "Unit", "required")}
                                                </div>
                                            </div>
                                            <div className="col-md-3 formInp formSecInp">
                                                <label>Lease Start Date</label>
                                                <CommonDatePicker
                                                    name="leaseStartDate"
                                                    placeholderText={"MM/DD/YYYY"}
                                                    disableClock={true}
                                                    className="form-control"
                                                    value={leaseStartDate && moment(new Date(leaseStartDate)).format('MM/DD/YYYY')}
                                                    onChange={this.onChangeLeaseStartDate}
                                                    showYearDropdown='true'
                                                />
                                                {this.validate(leaseStartDate, "Date", "required")}
                                            </div>
                                            <div className="col-md-3 formInp formSecInp">
                                                <label>Lease End Date</label>

                                                <CommonDatePicker
                                                    name="leaseEndDate"
                                                    placeholderText={"MM/DD/YYYY"}
                                                    disableClock={true}
                                                    className="form-control"
                                                    value={leaseEndDate && moment(new Date(leaseEndDate)).format('MM/DD/YYYY')}
                                                    onChange={this.onChangeLeaseEndDate}
                                                    showYearDropdown='true'
                                                />
                                                {this.validate(leaseEndDate, "Date", "required")}
                                                {/* <div className={"errorMsg"}>{this.state.leaseError}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.props.editId ? null : <div className="clientInfo cardOtr">
                                    <h3>Credit card Information</h3>
                                    <div className="formSection">
                                        <div className="row outrForm p-0 m-0">

                                            <div className="col-md-4 formInp">
                                                <label>Name on Card</label>
                                                <input
                                                    type="text"
                                                    name="cardName"
                                                    className="form-control"
                                                    placeholder="Name on Card"
                                                    value={this.state.cardName}
                                                    onChange={this.handleChange}
                                                    maxLength="25"

                                                />
                                                {/* {this.validate(this.state.cardName, "Name", "required")} */}
                                                {this.state.cardName && this.validate(this.state.cardName, "Name", "letter")}
                                            </div>

                                            <div className="col-md-6 formInp">
                                                <label>Card Details</label>
                                                <div className="creditcardOtr">
                                                    <CreditCardInput
                                                        customTextLabels={{
                                                            invalidCvc: '  ',
                                                            cardNumberPlaceholder: 'Card Number',
                                                            expiryPlaceholder: 'MM/YY',
                                                            cvcPlaceholder: 'CVV'
                                                        }}
                                                        cardNumberInputProps={{
                                                            value: this.state.cardNumber,
                                                            onChange: this.handleCardNumberChange
                                                        }}
                                                        cardExpiryInputProps={{
                                                            value: this.state.expiryDate,
                                                            onChange: this.handleCardExpiryChange
                                                        }}
                                                        cardCVCInputProps={{
                                                            value: this.state.cvv,
                                                            onChange: this.handleCvvChange
                                                        }}
                                                        dangerTextClassName={"errorMsg"}
                                                        invalidClassName={null}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            {
                                this.state.unitId &&
                                <div className="clientInfo invoice">
                                    <h3 className="unitDtll">
                                        <span>Invoice Split Up</span>
                                        {/* {this.props.editId ? null :  */}
                                        <span className="anothrUnitt" onClick={() => this.addInvoice()}><i
                                            className="fas fa-plus"></i> Add Invoice Item</span>

                                    </h3>
                                    <div className="formSection invSecond">
                                        <h2 className="invSecondTxt"> Security Deposit</h2>
                                        <InvoiceSplitUps
                                            invoice="invoice1" InvoiceSplitUps
                                            onChange={this.onChangeInvoice1}
                                            paymentMethods={paymentMethods}
                                            validate={this.validate}
                                            title={"Security Deposit"}
                                            type={InvoiceTypeList && InvoiceTypeList.length && InvoiceTypeList.filter((e, i) => e.name == "Security Deposit") || []}
                                            amount={this.state.invoice.amount1}
                                            paymentTypeId={this.state.invoice.paymentTypeId1}
                                            invoiceTypeId={this.state.invoice.invoiceTypeId1}
                                            InvoiceTypeList={InvoiceTypeList && InvoiceTypeList.length && InvoiceTypeList || []}
                                            startDate={this.state.invoice.startDate1}
                                            dueDate={this.state.invoice.dueDate1}
                                            onChangeDueDate={this.onChangeDueDate1}
                                            onChangeStartDate={this.onChangeStartDate1}
                                            invoiceError={this.state.invoice1Error}
                                        />
                                    </div>
                                    <div className="formSection invSecond">
                                        <h2 className="invSecondTxt">Rent</h2>
                                        <InvoiceSplitUps
                                            invoice="invoice2"
                                            onChange={this.onChangeInvoice2}
                                            handleInvoiceCheckBox={this.handleInvoiceCheckBox1}
                                            paymentMethods={paymentMethods}
                                            validate={this.validate}
                                            title={"Rent"}
                                            type={InvoiceTypeList && InvoiceTypeList.length && InvoiceTypeList.filter((e, i) => e.name == "Private") || []}
                                            amount={this.state.invoice.amount2}
                                            paymentTypeId={this.state.invoice.paymentTypeId2}
                                            autoProcessing={this.state.invoice.autoProcessing}
                                            invoiceTypeId={this.state.invoice.invoiceTypeId2}
                                            InvoiceTypeList={InvoiceTypeList && InvoiceTypeList.length && InvoiceTypeList || []}
                                            onChangeDueDate={this.onChangeDueDate2}
                                            onChangeStartDate={this.onChangeStartDate2}
                                            startDate={this.state.invoice.startDate2}
                                            dueDate={this.state.invoice.dueDate2}
                                            amtMsg={this.state.amtMsg}
                                            isRecurring={this.state.invoice.isRecurring}
                                            govtType={InvoiceTypeList && InvoiceTypeList.length && InvoiceTypeList.filter((e, i) => e.name == "Security Deposit")}
                                            invoiceError={this.state.invoice2Error}
                                        />
                                    </div>
                                    {
                                        this.state.newInvoice && this.state.newInvoice.length ? this.state.newInvoice.map((invoice, key) => {
                                            return (
                                                <div className="formSection gvr">
                                                    <h2 className="invSecondTxt">
                                                        {/* Government Deposit */}
                                                        <span onClick={() => this.closeInvoice(key)}
                                                            className="invClose">×</span>
                                                    </h2>

                                                    <InvoiceSplitUps
                                                        invoice="invoice3"
                                                        index={key}
                                                        onChange={this.onChangeInvoice3}
                                                        paymentMethods={paymentMethods}
                                                        validate={this.validate}
                                                        type={InvoiceTypeList && InvoiceTypeList.filter((e, i) => (e.name == "Benefits Management" || e.name == "Trillium" || e.name == "Section 8"))}
                                                        amount={invoice.amount}
                                                        title={invoice.title}
                                                        paymentTypeId={invoice.paymentTypeId}
                                                        invoiceTypeId={invoice.invoiceTypeId}
                                                        InvoiceTypeList={InvoiceTypeList}
                                                        onChangeDueDate={this.onChangeDueDate}
                                                        onChangeStartDate={this.onChangeStartDate}
                                                        startDate={invoice.startDate}
                                                        dueDate={invoice.dueDate}
                                                        amtMsg={this.state.amtMsg}
                                                        govtType={InvoiceTypeList && InvoiceTypeList.filter((e, i) => (e.name == "Security Deposit"))}
                                                        autoProcessing={invoice.autoProcessing}
                                                        isRecurring={invoice.isRecurring}
                                                        handleInvoiceCheckBox={this.handleInvoiceCheckBox2}
                                                        invoiceError={this.state.invoice3Error}
                                                    />
                                                </div>
                                            )
                                        }) : null}
                                </div>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary btnSubmit"
                                onClick={this.handleSubmit}>Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { tenantReducer, commonReducer } = state;
    return {
        tenantReducer,
        commonReducer
    };
}

export default connect(mapStateToProps, { ...commonAction, ...action })(AddTenants);
