import React from "react";
import ReactApexChart from 'react-apexcharts'
import moment from 'moment'
import {numberWithCommas} from "../../Common/components/utils";
import ExpenseFilter from "./expenseFilter";


const ExpenseReport = (props) => {

    const {expenseGraph: {graphPlots}} = props

    let currentYear = JSON.stringify(moment().format('YYYY'))

    let graphPlotsKey = graphPlots ? Object.keys(graphPlots) : currentYear

    let graphData = graphPlotsKey && graphPlots && graphPlots[graphPlotsKey] && graphPlots[graphPlotsKey].map((graph) => {
        return graph.expense
    })

    const series = [{
        name: "Expenses",
        data: graphData
    }]
    const options = {
        chart: {
            height: 320,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            },
        },
        stroke: {
            width: 4,
            curve: 'smooth',
        },
        markers: {
            size: 2,
            color: '#ff5858',
            strokeColor: '#ff5858'
        },
        colors: ['#ff5858'],
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
            labels: {
                show: false,
            },
            showAlways: false,
        },
        grid: {
            show: false,
        }
    }


    return (
        <div className="expense-otr">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 cstm-pdg">
                    <div className="expense-dlr-bx">
                        <div className="expense">
                            <h2>Total Expense</h2>
                            <div className="doller">${props.ExpenseList.totalAmount ? numberWithCommas(props.ExpenseList.totalAmount) || 0.00 : 0}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 cstm-pdg">
                    <div className="expense-dlr-bx">
                        <div className="expense">
                            <h2>This Month Expense</h2>
                            <div className="doller">${props.ExpenseList.monthExpense ? numberWithCommas(props.ExpenseList.monthExpense) || 0.00 : 0}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="graph-name">Expence Graph</div>
                    <ReactApexChart options={options} series={series} type="line" width="230" height="130"
                                    className="mychart"/>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 cstm-pdg">
                    <div className="expense-btn">
                        <button className="btnadd"  onClick={props.openModal}>
                            <i className="fas fa-plus"></i> Add Expenses
                        </button>
                        <button className="expensefltr"  onClick={props.handleFilter}>
                            <i className="fas fa-sliders-h"></i>Filter
                        </button>
                        {props.setFilter ?
                            <ExpenseFilter filterExpense={props.filterExpense} closeFilter={props.closeFilter}/> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpenseReport;
