import React from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import messageAction from '../../../Messaging/action';
import invoiceAction from '../../../Invoice/action';
import { numberWithCommas, formatDate } from "../../../Common/components/utils";
import DatePicker from "react-datepicker";
import moment from 'moment';
import toastr from "../../../Common/components/Toaster";
import LoaderSpinner from "../../../Common/components/Loader";
import AsyncSelect from 'react-select/async';
import SplitUp from "./SplitUp";
import * as validation from '../../../Common/components/validation';
import CommonDatePicker from "../../../Common/components/CommonDatePicker";
import { ThemeProvider } from "styled-components";


let Tenants = []

class TenantInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedInvoice: {
                status: 0
            },
            splitups: [
                {
                    description: '',
                    amount: 0,
                }
            ],
            splitupError: [
                {
                    description: '',
                    amount: '',
                }
            ],
            isSubmitted: false,
            errors: {},
            lastDate: '',
            currentStatus: null
        };
    }

    componentDidMount = async () => {
        const { editData } = this.props;
        // setting selected invoice data to the component state
        let tempSelectedInvoice = this.props.editData.invoices ? {
            ...this.props.editData.invoices[0],
            invoiceNumber: editData.invoiceNumber,
            status: editData.status,
            startDate: editData.startDate || moment(new Date()).format('MM/DD/YYYY'),
            dueDate: editData.dueDate || moment(new Date()).format('MM/DD/YYYY'),
            tenantId: editData.tenantId,
            tenantName: editData.tenantName,
            tenantAddress: editData.tenantAddress,
            paidAmount: editData.paidAmount,
            totalDue: editData.totalDue,
            invoiceTypeId: editData.invoiceTypeId,
            invoiceTypeName: editData.invoiceTypeName,
            paymentTypeId: editData.paymentTypeId,
            paymentTypeName: editData.paymentTypeName,
            lastCaptureAttempt: editData.lastCaptureAttempt,
            balance: editData.balance,
            transactionId: editData.transactionId
        } : {
                startDate: editData.startDate || moment(new Date()).format('MM/DD/YYYY'),
                dueDate: editData.dueDate || moment().add(30, 'd').format('MM/DD/YYYY'),
                status: 0
            }

        // format and set the first splitup description
        let tempSplitUp = this.props.editData.invoices;
        var selectedendDate = moment(this.state.selectedInvoice.startDate).endOf('month');
        var endDate = moment(editData.startDate).endOf('month');
        let dueDate = moment(new Date(endDate._d)).format('MM/DD/YYYY')
        if (tempSplitUp && tempSplitUp[0]) {
            tempSplitUp[0].description = editData.invoices[0].description ? editData.invoices[0].description : editData.invoiceTypeName +
                '-' +
                editData.tenantAddress +
                '-' +
                '(' +
                editData.startDate +
                '-' +
                // editData.dueDate +
                dueDate +
                ')'
        }
        this.setState({
            selectedInvoice: tempSelectedInvoice,
            currentStatus: tempSelectedInvoice ? tempSelectedInvoice.status : 0,
            // splitups: this.props.editData.invoices || [
            splitups: tempSplitUp || [
                {
                    description: '',
                    amount: 0,
                }
            ],
            splitupError: this.props.editData.invoices
                ? this.props.editData.invoices.map(() => {
                    return {
                        description: '',
                        amount: '',
                    }
                })
                : [{
                    description: '',
                    amount: '',
                }]
        })
        // getting data for the component
        await this.props.getPaymentDetails();
        await this.props.getInvoiceType()
        await this.props.getPhoneNumbers()

        // getting, formatting and setting Tenant List to the state
        const { messageReducer: { tenantPhoneNumbers } } = this.props;
        if (!this.props.tenantInvoice)
            Tenants = tenantPhoneNumbers && Array.isArray(tenantPhoneNumbers) && tenantPhoneNumbers.map(e => {
                return {
                    value: e._id,
                    label: (e.firstName || '') + ' ' + (e.lastName || ''),
                    tenantAddress: e.propertyId ? e.propertyId.propertyName : ''
                }
            });

        // if modal is loaded from tenant view, get the tenantName, tenantId, tenantAddress and set the first splitup description
        if (!editData.tenantName || (editData.tenantName && editData.tenantName.includes('undefined'))) {
            let currentTenant = tenantPhoneNumbers &&
                Array.isArray(tenantPhoneNumbers) &&
                tenantPhoneNumbers.filter(item => item._id === editData.tenantId)
            // setting the first splitup description
            let tempSplitUp = this.props.editData.invoices
            var endDate = moment(editData.startDate).endOf('month');
            let dueDate = moment(new Date(endDate._d)).format('MM/DD/YYYY')

            if (tempSplitUp && tempSplitUp[0]) {
                //
                tempSplitUp[0].description = editData.invoiceTypeName + '-' +
                    (editData.tenantAddress || currentTenant[0].propertyId.propertyName + '-' + '(' +
                        editData.startDate + '-' + dueDate + ')')
            }
            this.setState({
                selectedInvoice: {
                    ...this.state.selectedInvoice,
                    tenantName: currentTenant && currentTenant.length ? currentTenant[0].firstName + ' ' + currentTenant[0].lastName : '',
                    tenantId: currentTenant && currentTenant.length ? currentTenant[0]._id : '',
                    tenantAddress: currentTenant && currentTenant.length ? currentTenant[0].propertyId.propertyName : '',
                },
                selectedSplitUp: (!editData.isEdit) ? 0 : null,
                currentStatus: editData ? editData.status : 0
            })
        }
    }

    // componentDidUpdate = (prevProps, prevState, snapshot) => {
    //     if (prevProps.invoicePayment != this.props.invoicePayment) {
    //         if (!(this.props.invoicePayment.success != true)) {
    //             toastr.error("Payment Failed")
    //         } else if (this.props.invoicePayment.success) {
    //             toastr.success("Payment Was Successfully completed!!");
    //         }
    //     }
    // }

    // setting the selected invoice splitup
    selectInvoice = (e, invoice, idx) => {
        this.setState({
            selectedSplitUp: e.target.checked ? idx : null
        })
    }

    deleteInvoice = (invoice, index) => {
        const { selectedInvoice, splitups } = this.state;
        let tempArray = splitups
        tempArray.splice(index, 1)
        this.setState({
            splitups: tempArray
        })
    }

    getSubTotal = () => {
        let sum = 0
        this.state.splitups && this.state.splitups.map(item => {
            let amt = item.amount && String(item.amount).replace('$', '')
            amt = amt.toString().replace(/,/g, '')
            if (!isNaN(amt)) {
                sum += parseFloat(amt.replace(/,/g, ''))
            }
        })
        return (!isNaN(sum) || sum == 0) ? '$' + (sum == 0 ? '0.00' : sum) : '$0.00'
    }

    setDescription = (name, value) => {
        const { selectedInvoice } = this.state;
        let typeId = ''
        typeId = name === 'invoiceTypeId' ? value : selectedInvoice.invoiceTypeId
        if (selectedInvoice.tenantAddress && (name === 'invoiceTypeId' || selectedInvoice.invoiceTypeId)) {
            let desc = this.parseDescription(typeId);
            let tempSplitUps = this.state.splitups
            tempSplitUps[0].description = desc
            this.setState({
                splitups: tempSplitUps
            })
            // this.isDataValid()
        }
    }

    handleAttempt = async (e, field) => {
        const { selectedInvoice } = this.state;
        const { editData } = this.props;
        const { name, value } = e.target;
        var index = e.target.selectedIndex;
        let selectedOptionText = e.target[index].text
        this.setState({
            selectedInvoice: {
                ...selectedInvoice,
                paymentTypeName: selectedOptionText,
                [name]: value
            }
        })
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: this.validate(field, value)
            }
        })
        const params = {
            paymentTypeId: value,

        }
        if (this.props.editData.isEdit) {
            params.id = this.props.editData.id
        }
        // this.setState({
        //     isSubmitted: true
        // })
        await this.props.updateInvoice([params]);
        await this.props.getInvoice(this.props.tempParams)
        await this.props.getInvoicesByTenantId(this.props.id, this.props.tempParams);
        // this.setState({
        //     isSubmitted: false
        // })
    }

    handleChange = (e, field) => {
        const { selectedInvoice } = this.state;
        const { editData } = this.props;

        // format and set the first splitup description
        if (field == 'startDate') {
            var endDate = moment(editData.startDate).endOf('month');
            let tempSplitUp = this.props.editData.invoices;

            if (tempSplitUp && tempSplitUp[0]) {
                tempSplitUp[0].description = editData.invoiceTypeName +
                    '-' +
                    editData.tenantAddress +
                    '-' +
                    '(' +
                    editData.startDate +
                    '-' +
                    // editData.dueDate +
                    moment(new Date(endDate._d)).format('MM/DD/YYYY') +
                    ')'
                this.setState({
                    splitups: tempSplitUp
                })
            }
        }

        // handling date pickers
        if (e.label) {
            this.setState({
                selectedInvoice: {
                    ...selectedInvoice,
                    tenantId: e.value,
                    tenantName: e.label,
                    tenantAddress: e.tenantAddress
                },
                errors: {
                    ...this.state.errors,
                    tenantId: this.validate('tenantId', e.value)
                }
            })
        } else if (field) {

            this.setState({
                selectedInvoice: {
                    ...selectedInvoice,
                    [field]: formatDate(e)
                },
                errors: {
                    ...this.state.errors,
                    [field]: this.validate(field, formatDate(e))
                }
            }, () => {
                // Setting first description
                this.setDescription(field, formatDate(e));
            })

        } else if (e.target) {
            const { name, value } = e.target;
            // Update paymentTypeId and paymentTypeName for payment Method
            if (name == 'paymentTypeId') {
                var index = e.target.selectedIndex;
                let selectedOptionText = e.target[index].text
                this.setState({
                    selectedInvoice: {
                        ...selectedInvoice,
                        paymentTypeName: selectedOptionText,
                        [name]: value
                    }
                })
                this.setState({
                    errors: {
                        ...this.state.errors,
                        [name]: this.validate(field, value)
                    }
                })
            } else {

                this.setState({
                    selectedInvoice: {
                        ...selectedInvoice,
                        [name]: value
                    },
                    errors: {
                        ...this.state.errors,
                        [name]: this.validate(field, value)
                    }
                })
                // Setting first description
                this.setDescription(name, value);
            }
        }
    }

    validate = (name, value) => {
        switch (name) {
            case 'tenantId':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Tenant name is required.'
                } else {
                    return false;
                }
            case 'startDate':
                if (value === undefined || value == null || value == '' || value <= 0 || !value) {
                    return 'Start date is required.';
                } else {
                    return false;
                }
            case 'dueDate':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Due date is required.'
                } else {
                    return false;
                }
            case 'paymentTypeId':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Payment Type is required.'
                } else {
                    return false;
                }
            case 'invoiceTypeId':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Invoice Type is required'
                } else {
                    return false;
                }
            case 'amount':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Amount is required.'
                } else if (validation.NUMBER_ONLY(value) !== true) {
                    return 'Only number is allowed'
                } else {
                    return false;
                }
            case 'description':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Description is required.'
                } else {
                    return false;
                }
            case 'status':
                if (value === undefined || value === null || value === '' || ![0, 1, 2].includes(value)) {
                    return 'Status is required.'
                } else {
                    return false;
                }
            default:
                return false;
        }
    }

    isDataValid = async () => {
        const { splitups } = this.state;
        let tempError = this.state.splitupError
        let isFormValid = true;
        return splitups.length && splitups.map((item, index) => {
            let descStatus = splitups[index].description ? null : 'Description is required'
            let amountStatus = splitups[index].amount ? null : 'Amount is required'
            tempError[index].description = descStatus
            tempError[index].amount = amountStatus
            if (descStatus !== null || amountStatus !== null) {
                isFormValid = false
            }
            this.setState(prevState => ({
                ...prevState,
                splitupError: tempError
            }))
            return isFormValid
        })
    }

    handleSubmit = async () => {
        const {
            selectedInvoice: {
                tenantId,
                startDate,
                dueDate,
                paymentTypeId,
                invoiceTypeId,
                status
            }, splitups, selectedInvoice
        } = this.state;
        let tempTotalAmount = this.getSubTotal().replace('$', '')


        let tempSplitups = splitups && splitups.map(item => {
            let amt = item.amount && item.amount.toString().replace('$', '')
            amt = amt.toString().replace(',', '')
            return {
                ...item,
                amount: parseFloat(amt && amt)
            }
        })

        // let tempTotalAmount = this.getSubTotal().replace('$', '')
        const params = {
            tenantId: tenantId,
            title: 'title',
            amount: tempTotalAmount,
            startDate: moment(new Date(startDate)).format('MM/DD/YYYY'),
            dueDate: moment(new Date(dueDate)).format('MM/DD/YYYY'),
            paymentTypeId: paymentTypeId,
            invoiceTypeId: invoiceTypeId,
            charges: tempSplitups,
            status: status
        }
        if (this.props.editData.isEdit) {
            params.id = this.props.editData.id
        }
        this.setState({
            errors: {
                ...this.state.errors,
                tenantId: this.validate('tenantId', selectedInvoice.tenantId),
                startDate: this.validate('startDate', selectedInvoice.startDate),
                dueDate: this.validate('dueDate', selectedInvoice.dueDate),
                paymentTypeId: this.validate('paymentTypeId', selectedInvoice.paymentTypeId),
                invoiceTypeId: this.validate('invoiceTypeId', selectedInvoice.invoiceTypeId),
                status: this.validate('status', selectedInvoice.status),
            }
        })
        let isFormValid = await this.isDataValid()
        if (tenantId && startDate && dueDate && paymentTypeId && [0, 1, 2].includes(Number(status)) && invoiceTypeId && (!isFormValid.includes(false))) {
            this.setState({
                isSubmitted: true
            })
            await this.props.addInvoiceDetails(params)
            this.setState({
                isSubmitted: false
            })
        } else {
            return false
        }
    }

    attemptCapturePayment = async () => {

        this.setState({
            isSubmitted: true
        })
        await this.props.attemptCapture()
        this.setState({
            isSubmitted: false
        })
    }

    promiseOptionsProperty = async val => {
        const { messageReducer: { tenantPhoneNumbers } } = this.props;
        const Tenants = tenantPhoneNumbers && tenantPhoneNumbers.map(e => ({
            value: e._id,
            label: (e.firstName || '') + ' ' + (e.lastName || ''),
            tenantAddress: e.propertyId ? e.propertyId.propertyName : ''
        }));
        return Tenants.filter(i => i.label.toLowerCase().includes(val.toLowerCase()))
    }

    handleSplitups = (event, index) => {


        const { splitups, splitupError } = this.state;
        if (event.target) {

            const { name, value } = event.target;
            // setting splitup values
            let tempSplitUps = splitups;
            tempSplitUps[index][name] = value;
            this.setState({
                splitups: tempSplitUps
            });
            // Handling splitup error
            let tempSplitupError = splitupError
            tempSplitupError[index][name] = splitups[index][name] ? null : `${name} is required`
            // tempSplitupError[index][name] = this.validate(name.toString(), splitups[index][name])
            this.setState({
                splitupError: tempSplitupError
            })
        } else if (event.label) {
            let tempSplitUps = splitups;
            tempSplitUps[index].description = event.value;
            this.setState({
                splitups: tempSplitUps
            });
        }
    }

    addNewSubInvoice = async () => {
        let isOk = await this.isDataValid()
        // this.state.splitups.map(x => {
        //     if(Object.values(x).includes('')){
        //         isOk = false
        //     }
        // })
        if (!isOk.includes(false)) {
            this.setState({
                splitups: [
                    ...this.state.splitups,
                    {
                        isNewCharge: true,
                        description: '',
                        amount: 0,
                    }
                ],
                splitupError: [
                    ...this.state.splitupError,
                    {
                        description: '',
                        amount: '',
                    }
                ]
            })
        }
    }

    parseDescription = (value) => {
        let { tenantAddress, startDate, dueDate, isEdit } = this.props.editData;
        var selectedendDate = moment(this.state.selectedInvoice.startDate).endOf('month');
        var endDate = moment(startDate).endOf('month');
        ;
        var lastDate = moment(new Date(endDate._d)).format('MM/DD/YYYY');
        let invoiceTypeName = '';
        startDate = this.state.selectedInvoice.startDate
        // lastDate= moment(new Date(endDate._d)).format('MM/DD/YYYY')
        // lastDate=moment(new Date(this.state.selectedInvoice.startDate)).format('MM/DD/YYYY')
        dueDate = moment(new Date(selectedendDate._d)).format('MM/DD/YYYY');
        var endDate = moment(startDate).endOf('month');
        var lastDate = moment(new Date(endDate._d)).format('MM/DD/YYYY');
        tenantAddress = this.state.selectedInvoice.tenantAddress;
        invoiceTypeName = this.state.selectedInvoice.invoiceTypeName;

        if (value) {
            let descObj = this.props.commonReducer.InvoiceTypeList.filter(type => type._id == value);
            let descText = descObj && descObj.length ? descObj[0].name : '';
            let finalDesc = `${descText || invoiceTypeName || ''} - ${tenantAddress || ''} - (${startDate || ' '}-${lastDate || ' '})`
            return finalDesc
        } else {
            return ''
        }
    }

    // sendLobMail = async () => {
    //     const { selectedInvoice, splitups } = this.state;
    //     const{sendLobMailResponse} = this.props;
    //     let invoiceType = [];
    //     if (this.props.commonReducer.InvoiceTypeList && Array.isArray(this.props.commonReducer.InvoiceTypeList)) {
    //         invoiceType =  this.props.commonReducer.InvoiceTypeList.filter(type => type._id == selectedInvoice.invoiceTypeId)
    //     }
    //     const params = {
    //         invoiceId: selectedInvoice._id,
    //         tenantName: selectedInvoice.tenantName,
    //         invoiceDate: selectedInvoice.startDate,
    //         dueDate: selectedInvoice.dueDate,
    //         dueAamount: selectedInvoice.totalDue,
    //         balance: selectedInvoice.balance,
    //         invoiceType: invoiceType && invoiceType.length && invoiceType[0].name,
    //         status: selectedInvoice.status == 1 ? 'Paid' : 'Unpaid',
    //         description: splitups
    //     }
    //     this.setState({ isSubmitted: true })
    //     await this.props.sendLobMail(params)
    //     this.setState({ isSubmitted: false })

    // }

    render() {
        const { editData, messageReducer: { tenantPhoneNumbers } } = this.props;
        const { isEdit, isTenant } = editData;
        const { selectedInvoice, splitups, errors, splitupError } = this.state;
        return (
            <div
                className="modal addTenant invoiceModal"
                id="modalId"
                style={{ display: 'block' }}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                {this.state.isSubmitted && <LoaderSpinner />}
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {
                                    (isEdit)
                                        ? `Invoice #${editData.invoiceNumber}`
                                        : 'Add Invoice'
                                }
                            </h5>
                            <button onClick={this.props.onCancel} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body invoiceSection">
                            <div className="row m-0 p-0 mb-4">
                                <div className="col-md-6">
                                    <div className="invoiceInner">
                                        <div className="clientSec">
                                            <div className="nameLetter">
                                                {
                                                    selectedInvoice && selectedInvoice.tenantName
                                                        ? selectedInvoice.tenantName.split(' ')[0].charAt(0).toUpperCase() +
                                                        selectedInvoice.tenantName.split(' ')[1].charAt(0).toUpperCase()
                                                        : 'N/A'
                                                }
                                            </div>
                                            <div className="clientDetail">
                                                <AsyncSelect
                                                    isDisabled={isEdit || isTenant || this.props.isView}
                                                    cacheOptions
                                                    defaultOptions={Tenants}
                                                    placeholder={"Search Tenants"}
                                                    loadOptions={this.promiseOptionsProperty}
                                                    name="tenantId"
                                                    onChange={this.handleChange}
                                                    value={{
                                                        value: (selectedInvoice && selectedInvoice.tenantName) ? selectedInvoice.tenantId : '',
                                                        label: (selectedInvoice && selectedInvoice.tenantName) ? selectedInvoice.tenantName : '',
                                                        tenantAddress: (selectedInvoice && selectedInvoice.tenantName) ? selectedInvoice.tenantAddress : ''
                                                    }}
                                                />
                                                <span className="errorText">{errors.tenantId}</span>
                                            </div>
                                        </div>
                                        <div className="informationSec d-flex invoiceDate">
                                            <div className="col-md-6 invoiceText text-left">
                                                <h4>Invoice Date</h4>
                                                <CommonDatePicker
                                                    name="startDate"
                                                    placeholderText={"MM/DD/YYYY"}
                                                    disableClock={true}
                                                    disabled={this.props.isView}
                                                    className="form-control dateInput"
                                                    value={
                                                        selectedInvoice.startDate
                                                            ? moment(new Date(selectedInvoice.startDate)).format('MM/DD/YYYY')
                                                            : ''
                                                    }
                                                    onChange={val => this.handleChange(val, 'startDate')}
                                                />
                                                <span className="errorText">{errors.startDate}</span>
                                            </div>
                                            <div className="col-md-6 invoiceText text-left">
                                                <h4>Due Date</h4>
                                                <CommonDatePicker
                                                    name="dueDate"
                                                    placeholderText={"MM/DD/YYYY"}
                                                    disableClock={true}
                                                    disabled={this.props.isView}
                                                    className="form-control dateInput"
                                                    value={
                                                        selectedInvoice.dueDate
                                                            ? moment(new Date(selectedInvoice.dueDate)).format('MM/DD/YYYY')
                                                            : ''
                                                    }
                                                    onChange={val => this.handleChange(val, 'dueDate')}
                                                />
                                                <span className="errorText">{errors.dueDate}</span>
                                            </div>
                                        </div>
                                        <div className="informationSec d-flex">
                                            <div className="col-md-6 invoiceText text-left">
                                                <h4>Due Amount</h4>
                                                <p>
                                                    {'$' + numberWithCommas(this.getSubTotal().replace('$', ''))}
                                                </p>
                                            </div>
                                            <div className="col-md-6 invoiceText text-left">
                                                <h4>Balance</h4>
                                                <p>{
                                                    selectedInvoice.balance
                                                        ? '$' + numberWithCommas(editData.balance) || 0
                                                        : '$0.00'
                                                }</p>
                                            </div>
                                        </div>
                                        <div className="informationSec d-flex invoiceDate">
                                            <div className="col-md-6 invoiceText text-left">
                                                <h4>Invoice Type</h4>
                                                <div className="selctOtr">
                                                    <select
                                                        value={selectedInvoice.invoiceTypeId}
                                                        onChange={this.handleChange}
                                                        name='invoiceTypeId'
                                                        className="form-control"
                                                        disabled={this.props.isView}
                                                    >
                                                        <option value={null} selected>Select</option>
                                                        {
                                                            this.props.commonReducer.InvoiceTypeList &&
                                                            Array.isArray(this.props.commonReducer.InvoiceTypeList) &&
                                                            this.props.commonReducer.InvoiceTypeList.map((type, idx) => {
                                                                return <option key={idx}
                                                                    value={type._id}>{type.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorText">{errors.invoiceTypeId}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 invoiceText text-left">
                                                <h4>Status</h4>
                                                <div className="selctOtr">
                                                    <select
                                                        value={selectedInvoice.status}
                                                        onChange={this.handleChange}
                                                        name='status'
                                                        className="form-control"
                                                        disabled={selectedInvoice.transactionId || this.props.isView}
                                                    >
                                                        <option value={0}>Unpaid</option>
                                                        <option value={1}>Paid</option>
                                                        <option value={2}>Cancelled</option>
                                                    </select>
                                                    {selectedInvoice.status == 0 ? " " :
                                                        <span className="errorText">{errors.status}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="invoiceInner unpaidInner">
                                        <div className="clientSec">
                                            <p
                                                className={`unpaidText ${editData.status == 1 ? 'unpaide' : editData.status == 2 ? "" : 'price'}`}
                                            >{editData.status == 1 ? "Paid" : editData.status == 2 ? "Cancelled" : "Unpaid"}</p>

                                        </div>
                                        <div className="informationSec unpaidSec">
                                            <div className="row">
                                                <div className="col-md-6 invoiceText">
                                                    <h4>Payment Method</h4>
                                                    <div className="invoicePay">
                                                        <div className="selctOtr">
                                                            <select
                                                                value={selectedInvoice.paymentTypeId}
                                                                disabled={editData.status == 1 || this.props.isView}
                                                                onChange={editData.paymentTypeId ? this.handleAttempt : this.handleChange}
                                                                name='paymentTypeId'
                                                                style={{ cursor: editData.status == 1 ? 'no-drop' : '' }}
                                                                className="form-control"
                                                            >
                                                                <option value={null}>Select</option>
                                                                {
                                                                    this.props.commonReducer.paymentMethods &&
                                                                        this.props.commonReducer.paymentMethods.length
                                                                        ? this.props.commonReducer.paymentMethods.map((type, key) => {
                                                                            return <option value={type._id}
                                                                                key={key}>{type.name}</option>
                                                                        })
                                                                        : null
                                                                }
                                                            </select>
                                                            <span className="errorText">{errors.paymentTypeId}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (isEdit) &&
                                                    <div className="col-md-6 invoiceText">
                                                        <h4>{this.state.currentStatus == 0 ? "Last Capture Attempt" : "Paid Date"}</h4>
                                                        {
                                                            selectedInvoice.lastCaptureAttempt
                                                                ? selectedInvoice.lastCaptureAttempt ? formatDate(selectedInvoice.lastCaptureAttempt) : 'N/A'
                                                                : 'N/A'
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {!this.props.isView ? <div className="bottomBtn">
                                            {(
                                                (editData.status == 0 || editData.status == 2) &&
                                                // (editData && editData.paymentTypeName == 'Credit Card') &&
                                                (editData && this.state.selectedInvoice.paymentTypeName == 'Credit Card')
                                            ) ?
                                                <button className="add btn btn-default unpaidBtn attemptBg"
                                                    onClick={this.attemptCapturePayment}
                                                >Attempt Capture</button>

                                                : <button className={`add btn btn-default unpaidBtn attemptBg disabled`}
                                                    disabled={true}

                                                >Attempt Capture</button>
                                            }
                                            {
                                                (editData && editData.paymentTypeName !== 'Credit Card') ?
                                                    <button
                                                        className={`add btn btn-default unpaidBtn smsBg disabled`}
                                                        disabled={true}
                                                    > Send Payment SMS
                                                    </button>
                                                    :
                                                    <button
                                                        className={`add btn btn-default unpaidBtn smsBg ${(editData.status == 0 || editData.status == 2) ? '' : 'disabled'}`}
                                                        onClick={() => this.props.sendPaymentSms(editData.tenantDetails.value, editData.invoiceNumber, editData)}
                                                        disabled={this.props.smsInvoice.loading || editData.status == 1}
                                                    > Send Payment SMS
                                                        {this.props.smsInvoice.loading && <i class="fa fa-spinner"></i>}
                                                    </button>}

                                            {/* <button
                                                className={`add btn btn-default unpaidBtn emailBg disabled ${editData.status == 0 ? '' : 'disabled'}`}
                                            > Send Email
                                            </button> */}
                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                            <section className="tenant-tabs">
                                <div className="col-md-12 p-0 m-0">
                                    <div className="innago-table table-main invoiceitemTable">
                                        <div className="tableHeader">
                                            <h4>Invoice Items</h4>
                                            {!this.props.isView ?
                                                <div onClick={this.addNewSubInvoice} className="subinvoice-btn">
                                                    <button className="btnadd">
                                                        <i className="fas fa-plus"></i>
                                                        &nbsp; Add Sub Invoice
                                                    </button>
                                                </div> : null
                                            }
                                        </div>
                                        <div className="table-responsive invoiceTableScroll">
                                            <table className="table-gap table-td">
                                                <tr className="table-head">
                                                    {/* <th className="checkboxWidth">
                                                        <div className="checkboxCenter">
                                                            <label className="invoiceCheckbox">
                                                                <input
                                                                    type="checkbox"
                                                                    name="isArchived"
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </th> */}
                                                    <th className="descwidth">Description</th>
                                                    <th>Amount</th>
                                                    {/* <th>Invoice Type</th> */}
                                                    {/* <th className="actionWidth">Status</th> */}
                                                    {!this.props.isView ? <th>Action</th> : null}
                                                </tr>
                                                {
                                                    splitups && splitups.map((invoice, idx) => {
                                                        let spiltupLength = splitups.length
                                                        return <SplitUp
                                                            key={idx}
                                                            selectedInvoice={invoice}
                                                            spiltupLength={spiltupLength}
                                                            desc={invoice.description}
                                                            splitupError={[...splitupError]}
                                                            handleChange={this.handleSplitups}
                                                            splitupIndex={idx}
                                                            deleteInvoice={this.deleteInvoice}
                                                            selectInvoice={this.selectInvoice}
                                                            parseDescription={this.parseDescription}
                                                            selectedSplitUp={this.state.selectedSplitUp}
                                                            isEdit={isEdit}
                                                            isView={this.props.isView}
                                                        />
                                                    })
                                                }

                                                <tr>
                                                    <td colSpan="1">
                                                        <div className="d-flex justify-content-end">
                                                            <p>Sub Total</p>
                                                        </div>
                                                    </td>
                                                    <td colSpan="2">
                                                        {/* <p className="text-left amount">{this.getSubTotal()}</p> */}
                                                        <p className="text-left amount">
                                                            {'$' + numberWithCommas(this.getSubTotal().replace('$', ''))}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        {!this.props.isView ? <div className="tablefooterBtn">
                                            {
                                                <button
                                                    // disabled={!selectedInvoice.id}
                                                    className="add btn btn-default footerBtn saveBg"
                                                    onClick={() => this.handleSubmit()}> Save Changes
                                                </button>
                                            }
                                            {
                                                <button className="add btn btn-default footerBtn cancelBg"
                                                    onClick={this.props.onCancel}> Cancel
                                                </button>
                                            }
                                        </div> : null}

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer, invoiceReducer, messageReducer } = state;
    return {
        messageReducer,
        invoiceAdd: invoiceReducer.Invoice,
        commonReducer,
        invoices: tenantReducer.invoiceList,
        invoicesTypes: tenantReducer.invoicesTypes,
        invoicePayment: invoiceReducer.invoicePayment,
        sendLobMailResponse: invoiceReducer.sendLobMailResponse,
        smsInvoice: invoiceReducer.smsInvoice,
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction, ...invoiceAction, ...messageAction })(TenantInvoice);
