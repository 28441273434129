import * as actions from "./constant";

const initialState = {
    leaseList: []
};

export default function leaseReducer(state = initialState, action) {
    switch (action.type) {

        case actions.ADD_LEASE_REQUEST:
            return {
                ...state
            };
        case actions.ADD_LEASE_SUCCESS:
            return {
                ...state,
                lease: { success: true, ...action.response }
            };
        case actions.ADD_LEASE_FAILURE:
            return {
                ...state,
                lease: { success: false, ...action.error }
            };
        case actions.GET_LEASE_REQUEST:
            return {
                ...state
            };
        case actions.GET_LEASE_SUCCESS:
            return {
                ...state,
                leaseList: {
                    success: action.leaseDetails.success,
                    leases: action.leaseDetails.data,
                    leaseCount: action.leaseDetails.leaseCount
                }
            };
        case actions.GET_LEASE_FAILURE:
            return {
                ...state,
                leaseList: { success: false, ...action.error }
            };

        case actions.VIEW_LEASE_DETAIL_REQUEST:
            return {
                ...state
            };
        case actions.VIEW_LEASE_DETAIL_SUCCESS:

            return {
                ...state,
                leaseAgreement: action.leaseDetails
            };
        case actions.VIEW_LEASE_DETAIL_FAILURE:

            return {
                ...state,
                leaseAgreement: { success: false, ...action.error }
            };
        case actions.UPDATE_LEASE_REQUEST:
            return {
                ...state
            };
        case actions.UPDATE_LEASE_SUCCESS:

            return {
                ...state,
                leaseAgreementUpdate: action.leaseDetails
            };
        case actions.UPDATE_LEASE_FAILURE:

            return {
                ...state,
                leaseAgreementUpdate: { success: false, ...action.error }
            };
            case actions.DELETE_LEASE_REQUEST:
                return {
                    ...state
                };
            case actions.DELETE_LEASE_SUCCESS:
    
                return {
                    ...state,
                    leaseAgreementDelete: action.leaseDetails
                };
            case actions.DELETE_LEASE_FAILURE:
    
                return {
                    ...state,
                    leaseAgreementDelete: { success: false, ...action.error }
                };
        default:
            return state;
    }
}

