import React from "react";
import moment from "moment";
import {numberWithCommas} from "../utils";

const SearchResult = (props) => {
    const {advanceSearchData} = props
    return (
        <>
            {advanceSearchData && advanceSearchData.success && props.nodata ? <div className="listSec">
                {advanceSearchData.property.length ? <ul className="outer-list">
                    <h3>Properties</h3>{advanceSearchData.property.map((pro, key) => {
                    return <li onClick={() => props.handleProperty(pro._id)}>{pro.propertyName}</li>
                })}</ul> : null}
                {advanceSearchData.tenant.length ? <ul className="outer-list">
                    <h3>Tenant</h3>{advanceSearchData.tenant.map((ten, key) => {
                    return <li
                        onClick={() => props.handleTenant(ten._id)}>{ten.firstName + " " + ten.lastName} </li>
                })}</ul> : null}

                {advanceSearchData.invoice.length ? <ul className="outer-list">
                    <h3>Invoice</h3>{advanceSearchData.invoice.map((ten, key) => {
                    return <li
                        onClick={() => props.handleInvoice(ten)}>{ten.invoiceNumber + "-" + moment(ten.dueDate).format('MM/DD/YYYY') + "-$" + numberWithCommas(ten.amount)} </li>
                })}</ul> : null
                }
                {/*{advanceSearchData.unitList.length ? <ul className="outer-list">*/}
                {/*    <h3>Unit</h3>{advanceSearchData.unitList.map((ten, key) => {*/}
                {/*    console.log("pro:debuggg", ten)*/}
                {/*    return <li onClick={() => props.handleUnit(ten.propertyId)}>{ten.unitName} </li>*/}
                {/*})}</ul> : null*/}
                {/*}*/}
                {advanceSearchData.maintenance.length ? <ul className="outer-list">
                    <h3>Maintenance</h3>{advanceSearchData.maintenance.map((ten, key) => {
                    return <li onClick={() => props.handleMaintenance(ten._id)}>{ten.title} </li>
                })}</ul> : null}
                {advanceSearchData.settings.length ? <ul className="outer-list">
                    <h3>Business Entity</h3>{advanceSearchData.settings.map((ten, key) => {
                    return <li onClick={() => props.handleSettings(ten._id)}>{ten.entityName} </li>
                })}</ul> : null
                }
            </div> : <div className="listSec">
                <ul className="outer-list">
                    <div className="no_value"><i className="far fa-thumbs-down"></i>No Data Found!!</div>
                </ul>
            </div>
            }
        </>
    )
}

export default SearchResult

