import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import expenseAction from '../../../Expense/action'
import commonAction from '../../../Common/action'
import history from '../../../../config/history'
import { numberWithCommas } from "../../../Common/components/utils";
import CommonDatePicker from '../../../Common/components/CommonDatePicker'


class CustomInput extends React.Component {
    render() {
        return (
            <>
                <span className="input-group-addon">
                    <i className="far fa-calendar" onClick={this.props.onClick}></i>
                </span>
                <input onClick={this.props.onClick} onChange={this.props.onChange}
                    autoComplete={false} placeholder={this.props.placeholder}
                    className={this.props.className}
                    // selected={this.props.selected}
                    value={this.props.value}
                    type="text" />
            </>
        )
    }
}

const Expense = props => {
    const [year, setYear] = useState('')

    const [params, setParams] = useState({
        startDate: moment(new Date()).startOf('month').toISOString(),
        endDate: moment(new Date()).endOf('month').toISOString(), propertyDetails: null
    })

    useEffect(() => {
        async function renderCall() {
            await props.getExpense(params);
            await props.getProperty({ limit: 15, offset: 0 });
            await props.getExpenseGraphDetails()
        }

        renderCall();
    }, []);

    const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));

    const promiseOptionsProperty = async val => {
        await props.getProperty({ name: val })
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeProperty = async (value) => {
        let tempParams = params
        tempParams.propertyId = value.value
        await props.getExpense(tempParams)
        await props.getExpenseGraphDetails(tempParams)
        setParams({
            ...params,
            propertyId: value.value,
            propertyDetails: value
        })
    }

    const handleChangeDate = async (value, name) => {
        let tempParams = params
        let date = moment(new Date(value)).endOf('day').toISOString()

        tempParams[name] = date
        await props.getExpense(tempParams)
        setParams({
            ...params,
            [name]: date,
        })
    }

    const viewMoreExpense = () => {
        history.push('/Expense')
    }

    const { expenseGraph: { graphPlots } } = props.commonReducer

    let currentYear = JSON.stringify(moment().format('YYYY'))

    let graphPlotsKey = graphPlots ? Object.keys(graphPlots) : currentYear

    let graphData = graphPlotsKey && graphPlots && graphPlots[graphPlotsKey] && graphPlots[graphPlotsKey].map((graph) => {
        return graph.expense
    })

    const series = [{
        name: "Expenses",
        data: graphData
    }]

    const options = {
        chart: {
            width: '100%',
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            },
        },

        stroke: {
            width: 4,
            curve: 'smooth',
        },
        responsive: [{
            options: {
                chart: {},
                legend: {
                    show: false
                }
            }
        }],
        markers: {
            size: 2,
            color: '#ff5858',
            strokeColor: '#ff5858'
        },
        colors: ['#ff5858'],
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
            labels: {
                show: false,
            },
            showAlways: false,
        },
        grid: {
            show: false,
        }
    }

    const resetFilter = async () => {
        setParams({
            ...params, propertyId: '', propertyDetails: "", startDate: "",
            endDate: ""
        })
        await props.getExpense();
        await props.getProperty();
        await props.getExpenseGraphDetails()
    }


    return (
        <div
            // ref={props.reference.ref}
            // {...props.dragHandleProps}
            // {...props.draggableProps}
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-6 col-sm-12 occupancy revenue mb-3 expense">
            <div className="occupancyOtr">
                <div className="headerSection d-flex">
                    <h3>Expense</h3>
                    <form autoComplete="off">
                        <div className="viewMore d-flex align-items-center">
                            {/*<img src="images/more.png" className="pleft-15"/><span*/}
                            {/*onClick={viewMoreExpense}>View More</span>*/}

                            <div className="dateSec d-flex">
                                <div className="datePic1">
                                    {/*<span className="input-group-addon">*/}
                                    {/*    <span className="far fa-calendar"></span>*/}
                                    {/*</span>*/}
                                    {/* <input data-date-format="mm/dd/yyyy" id="datepicker" placeholder="Start-date"
                                       className="date1"/> */}
                                    <CommonDatePicker
                                        name="startDate"
                                        placeholderText="Start Date"
                                        disableClock={true}
                                        style={{ display: 'none' }}
                                        format="mm/yy"
                                        className="date1"
                                        // customInput={<CustomInput/>}
                                        onChange={(value) => handleChangeDate(value, "startDate")}
                                        value={params.startDate && new Date(params.startDate)}
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 10px'
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                                boundariesElement: 'viewport'
                                            }
                                        }}

                                    />

                                </div>
                                <div className="datePic2">
                                    {/*<span className="input-group-addon">*/}
                                    {/*    <span className="far fa-calendar"></span>*/}
                                    {/*</span>*/}
                                    <CommonDatePicker
                                        name="endDate"
                                        placeholderText="End Date"
                                        disableClock={true}
                                        format="mm/yy"
                                        className="date2"
                                        autoComplete={false}
                                        // customInput={<CustomInput/>}
                                        onChange={(value) => handleChangeDate(value, "endDate")}
                                        value={params.endDate && new Date(params.endDate)}
                                        popperPlacement="top-end"
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 10px'
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                    />
                                </div>

                            </div>

                            <i className="fas fa-redo refreshBg" onClick={() => resetFilter()}></i>
                        </div>
                    </form>
                </div>
                <div className="col-md-12 selectOtr">
                    <AsyncSelect
                        cacheOptions
                        className="col-md-12"
                        defaultOptions={Properties}
                        placeholder={"All Properties"}
                        loadOptions={promiseOptionsProperty}
                        value={params.propertyDetails}
                        name="tenantId"
                        onChange={handleChangeProperty}
                    // menuIsOpen={true}
                    />
                </div>
                <div className="col-md-12 chartSec">
                    <div className="col-md-12 chartOtr">
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="line"
                            width="100%"
                            height="160"
                            className="mychart"
                        />
                    </div>
                </div>
                <div className="d-flex expText">
                    <div className="col-md-6 revText text-center">
                        <h4>All Time Expense</h4>
                        <h3>$ {props.expenseList ? numberWithCommas(props.expenseList.totalAmount) || 0.00 : 0}</h3>
                    </div>
                    <div className="col-md-6 revText text-center monthTh">
                        <h4>This Month Expenses</h4>
                        <h3>$ {props.expenseList ? numberWithCommas(props.expenseList.monthExpense) || 0.00 : 0}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    const { expenseReducer, commonReducer } = state;
    return {
        expenseList: expenseReducer.ExpenseList,
        commonReducer
    };
}

export default connect(mapStateToProps, { ...expenseAction, ...commonAction })(Expense);
