let baseUrl = "";
let frontEndUrl = "";
let plaidKey = "";
let plaidEnv = ""
// process.env.TZ = UTC
if (process.env.REACT_APP_NODE_ENV === "local") {
    baseUrl = "https://b65ed7e8.ngrok.io";
    frontEndUrl = "localhost:3000";
    plaidKey = "ae68f79fe745c7f843145765679f80"
    plaidEnv = "sandbox"
} else if (process.env.REACT_APP_NODE_ENV === "development") {
    baseUrl = "https://cruzdevapi.spericorn.com";
    frontEndUrl = "`https://cruzdev.spericorn.com";
    plaidKey = "ae68f79fe745c7f843145765679f80"
    plaidEnv = "sandbox"


} else if (process.env.REACT_APP_NODE_ENV === "staging") {
    baseUrl = "https://cruzstagingapi.spericorn.com";
    frontEndUrl = "https://cruzstaging.spericorn.com";
    plaidKey = "ae68f79fe745c7f843145765679f80"
    plaidEnv = "sandbox"


} else if (process.env.REACT_APP_NODE_ENV === "cruzControl") {
    //  baseUrl =  "https://api.cruzrentals.com";
    //  frontEndUrl ="https://portal.cruzrentals.com";
    baseUrl = "https://api.cruzcontrol.io";
    frontEndUrl = "https://apto.cruzcontrol.io"
    plaidKey = "ae68f79fe745c7f843145765679f80"
    plaidEnv = "production"

} else if (process.env.REACT_APP_NODE_ENV === "production") {
    baseUrl = "https://cruzapi.spericorn.com";
    frontEndUrl = "https://cruz.spericorn.com";
    plaidKey = "ae68f79fe745c7f843145765679f80"
    plaidEnv = "sandbox"

} else if (process.env.REACT_APP_NODE_ENV === "internal") {
    baseUrl = "https://cruzinternalapi.spericorn.com";
    frontEndUrl = "https://cruzinternal.spericorn.com";
    plaidKey = "ae68f79fe745c7f843145765679f80"
    plaidEnv = "sandbox"


} else {
    baseUrl = "";
    frontEndUrl = "";
    plaidKey = "";
    plaidEnv = "sandbox"
}

if (!baseUrl) {
    throw new Error("API Url is not provided.");
}

// export const FRONT_END_ROUTE_ROUTE = `cruz.spericorn.com/`;
export const MAIN_ROUTE = `${baseUrl}/`;
export const API_ROUTE = `${baseUrl}`;
export const AUTH_ROUTE = `${baseUrl}/auth`;
export const FRONT_END_ROUTE_ROUTE = `${frontEndUrl}/`;
export const PLAID_KEY = plaidKey
export const PLAID_ENV = plaidEnv
