import React, { useState, useEffect } from "react";
import action from "./action";
import { connect } from "react-redux";
// import {ToastContainer, toast} from "react-toastify";
import toastr from '../Common/components/Toaster'
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../Common/components/Loader";
import usePrevious from "../Common/components/prevProps";
import ForgotModal from "../Login/components/forgotPasswordModal";
import Modal from '../Common/components/Modal';
import * as validation from '../Common/components/validation'

const Login = props => {

    const [username, setUsername] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [forgotModal, setForgotModal] = useState(false);

    const prevUser = usePrevious(props.user);
    const prevPassword = usePrevious(props.resetPassword)
    const prevPasswordSet = usePrevious(props.setPassword)

    useEffect(() => {
        if (isSubmitted && prevUser !== props.user && !props.user.success) {
            toastr.error(props.user.message);
            //toastr.error("Invalid username or password");

        }
    }, [prevUser, props.user, props.user.message]);


    useEffect(() => {
        if (isSubmitted && prevPassword !== props.resetPassword && !props.resetPassword.success) {
            toastr.error("Email not found");
        }
        else if (isSubmitted && prevPassword !== props.resetPassword && props.resetPassword.success) {
            toastr.success(props.resetPassword.message);
        }
    }, [prevPassword, props.resetPassword]);

    useEffect(() => {
        if (prevPasswordSet !== props.setPassword && !props.setPassword.success) {
            toastr.error("Email not found");
        }
        else if (prevPasswordSet !== props.setPassword && props.setPassword.success) {
            toastr.success(props.setPassword.message);
        }
    }, [prevPasswordSet, props.setPassword]);

    const handleChange = e => {
        if (e.target.name === "username") {
            setUsername(e.target.value);
        } else if (e.target.name === "password") {
            setPassword(e.target.value);
        } else if (e.target.name === "rememberMe") {
            setRememberMe(e.target.checked);
        }
        validateField(e.target.name, e.target.value);
        setIsSubmitted(false);
    };

    const validateField = (field, value) => {
        setUserNameError("");
        setPasswordError("");
        switch (field) {
            case "password":
                if (!value) {
                    setPasswordError("* Please enter Password");
                } else if (validation.PASSWORD(value, field) !== true) {
                    setPasswordError("* Invalid Password");
                } else {
                    setPasswordError(false)
                }
                break;
            case "username":
                if (!value) {
                    setUserNameError("* Please enter Email");
                } else if (validation.EMAIL(value, field) !== true) {
                    setUserNameError("* Invalid Email");
                } else {
                    setPasswordError(false)
                }
                break;
            default:
                break;
        }
    };

    const openForgotModal = () => {
        setForgotModal(true)
    }

    const closeForgotModal = () => {
        setForgotModal(false)
    }

    const renderForgotModal = () => {
        return <ForgotModal
            onCancel={closeForgotModal}
            submitMail={submitMail}
        />
    }

    const submitMail = async (data) => {
        setIsSubmitted(true)
        await props.forgotPassword(data)
        setForgotModal(false)
        setTimeout(() => {
            setIsSubmitted(false);
        }, 2500)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (!username) {
            setUserNameError("* Please enter Email");
        }
        if (!password) {
            setPasswordError("* Please enter Password");
        }
        let isFormValid = !passwordError && !userNameError;
        if (isFormValid) {
            setIsSubmitted(true);
            let data = {
                email: username,
                password,
                rememberMe
            };
            localStorage.setItem("email", username)

            await props.signIn(data);
            setTimeout(() => {
                setIsSubmitted(false);
            }, 2500)

        }
        return true;
    };

    return (
        <div className="login-outer">
            {forgotModal ? <Modal body={renderForgotModal()} onCancel={closeForgotModal} /> : null}
            {/* <ToastContainer autoClose={2500}/> */}
            {/*{isSubmitted && <LoaderSpinner />}*/}
            <div className="container custom-container">
                <div className="row">
                    <div className="d-flex w-100 loginSec">
                        <div className="col-lg-6 col-md-6 loginBg p-0">
                            <div className="opac">
                                <div className="col-md-12 logoSec">
                                    <img src="/images/logo-white.png" />
                                </div>
                                <div className="contentLog">
                                    <h3>Welcome</h3>
                                    <p className="dtlLog">
                                        This property management portal will allow you to manage your tenant profile.
                                        Login now to access your lease,
                                        pay your rent, submit maintenance requests, and much more!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 loginDetail formSection">
                            <div className="loginSecDtl">
                                <div className="titleLog">
                                    <h3> LOGIN </h3>
                                </div>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="col-md-12 formInp">
                                        <label> Email </label>
                                        <input
                                            type="text"
                                            className={
                                                userNameError
                                                    ? "form-control errorborder"
                                                    : "form-control"
                                            }
                                            placeholder="Enter Email"
                                            name={"username"}
                                            value={username}
                                            onChange={handleChange}
                                            maxLength="50"
                                        />
                                        {userNameError ? <span className="errorMsg">{userNameError}</span> : null}
                                    </div>
                                    <div className="col-md-12 formInp">
                                        <label> Password </label>
                                        <input
                                            type="password"
                                            className={
                                                passwordError
                                                    ? "form-control errorborder"
                                                    : "form-control"
                                            }
                                            placeholder="Enter Password"
                                            name={"password"}
                                            value={password}
                                            onChange={handleChange}
                                            maxLength="25"
                                        />
                                        {passwordError ? <span className="errorMsg">{passwordError}</span> : null}
                                    </div>
                                    <div className="col-md-12 formInp">
                                        <label className="containerCheck">
                                            {" "}
                                            Remember Me For 30 Days.
                                            <input
                                                type="checkbox"
                                                name="rememberMe"
                                                onChange={e => handleChange(e)}
                                            />
                                            <span className="checkmark"> </span>
                                        </label>
                                    </div>
                                    <div className="col-md-12 btnOtr">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btnSubmit"
                                            disabled={isSubmitted}
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div className="col-md-12 fgt" data-toggle="modal" data-target="#addInvoice"
                                        onClick={openForgotModal}>
                                        Forgot Password?
                                    </div>
                                    {/*<span onClick={openForgotModal}>Forgot Password ?</span>*/}
                                </form>
                            </div>
                        </div>
                        <footer>
                            By using the cruzcontrol , you agree to our Privacy Policy and
                            Terms and Conditions
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.loginReducer.User,
        resetPassword: state.loginReducer.resetPassword,
        setPassword: state.loginReducer.setPassword
    };
};

export default connect(mapStateToProps, {
    ...action
})(Login);
