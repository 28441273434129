import * as actionType from "./constants";

const initialState = {
    plideResponse:{},
    dataSources:[],
    transactionData:{},
    bankDetails:{}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case actionType.ADD_PLAID_REQUEST:
            return {
                ...state,
            };
        case actionType.ADD_PLAID_SUCCESS:
            return {
                ...state,
              plideResponse:action
            };

        case actionType.ADD_PLAID_FAILURE:
            return {
                ...state,
                plideResponse: {success: false, ...action.error}
            };

        case actionType.GET_DATASOURCE_REQUEST:
            return {
                ...state,
            };
        case actionType.GET_DATASOURCE_SUCCESS:
            return {
                ...state,
                dataSources:action.data.data
            };

        case actionType.GET_DATASOURCE_FAILURE:
            return {
                ...state,
                dataSources: {success: false, ...action.error}
            };
        case actionType.GET_TRANSACTION_REQUEST:
            return {
                ...state,
            };
        case actionType.GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                transactionData:action.data.data
            };

        case actionType.GET_TRANSACTION_FAILURE:
            return {
                ...state,
                transactionData: {success: false, ...action.error}
            };

        case actionType.GET_CATEGORY_REQUEST:
            return {
                ...state,
            };
        case actionType.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryData:action.data.data
            };

        case actionType.GET_CATEGORY_FAILURE:
            return {
                ...state,
                categoryData: {success: false, ...action.error}
            };

        case actionType.DELETE_TRANSACTION_REQUEST:
            return {
                ...state,
            };
        case actionType.DELETE_TRANSACTION_SUCCESS:
            return {
                ...state,
                deleteTransactionData:action.data
            };

        case actionType.DELETE_TRANSACTION_FAILURE:
            return {
                ...state,
                deleteTransactionData: {success: false, ...action.error}
            };
        case actionType.UPDATE_TRANSACTION_REQUEST:
            return {
                ...state,
            };
        case actionType.UPDATE_TRANSACTION_SUCCESS:
            // return {
            //     ...state,
            //     updateTransactionData:action.data
            // };
            return state

        case actionType.UPDATE_TRANSACTION_FAILURE:
            return {
                ...state,
                updateTransactionData: {success: false, ...action.error}
            };

        case actionType.GET_PROPERTYBANK_REQUEST:
            return {
                ...state,
            };
        case actionType.GET_PROPERTYBANK_SUCCESS:
            return {
                ...state,
                bankDetails:action.data.data
            };

        case actionType.GET_PROPERTYBANK_FAILURE:
            return {
                ...state,
                bankDetails: {success: false, ...action.error}
            };

        case actionType.UPDATE_PROPERTYBANK_REQUEST:
            return {
                ...state,
            };
        case actionType.UPDATE_PROPERTYBANK_SUCCESS:
            return {
                ...state,
                updateBankDetails:action.data.data
            };

        case actionType.UPDATE_PROPERTYBANK_FAILURE:
            return {
                ...state,
                updateBankDetails: {success: false, ...action.error}
            };

        case actionType.UPDATE_IMPORT_REQUEST:
            return {
                ...state,
            };
        case actionType.UPDATE_IMPORT_SUCCESS:
            return {
                ...state,
                updateImportDetails:action.data
            };

        case actionType.UPDATE_IMPORT_FAILURE:
            return {
                ...state,
                updateImportDetails: {success: false, ...action.error}
            };

        default:
            return state;
    }
};
