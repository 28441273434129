import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import commonAction from '../../Common/action'
import * as validation from '../../Common/components/validation';
import action from '../action';
import LoaderSpinner from "../../Common/components/Loader";
import toastr from '../../Common/components/Toaster';
import history from '../../../config/history';
import { formatUsPhone } from "../../Common/components/utils";

const AddExpense = (props) => {
    const [userForm, setUserForm] = useState({
        id: '',
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        role: "",
        password: '',
        confirmPassword: null
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [role, setRole] = useState('');

    useEffect(() => {
        const { userData } = props;
        const { profileReducer: { profileDetail } } = props;
        let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
        setRole(role);
        if (userData)
            setUserForm({
                id: userData._id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                phoneNumber: userData.phoneNumber,
                address: userData.address,
                role: userData.role,
                password: userData.password,
                confirmPassword: userData.password,
            })
    }, []);

    const { settingsReducer: { addUser } } = props;

    useEffect(() => {
        if (submit && !addUser.success) {
            toastr.error(addUser.message)
        } else if (submit && addUser.success) {
            toastr.success(addUser.message);
        }
    }, [addUser]);

    const handleChange = async (e) => {
        const { name, value } = e.target
        if (name === "phoneNumber") {
            setUserForm({ ...userForm, [name]: formatUsPhone(value) })

        } else {
            setUserForm({ ...userForm, [name]: value })
        }
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
                case "email":
                    return (
                        validation.EMAIL(value, name) != true ?
                            <div className={"errorMsg"}>*{validation.EMAIL(value, name)}</div> : null
                    )
                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) ?
                            <div className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div>
                            :
                            null
                    )
                case "password":
                    return (validation.PASSWORD(value, name) !== true ?
                        <div className={"errorMsg"}>{validation.PASSWORD(value, name)}</div>
                        :
                        null)

                case "letter":
                    return validation.LETTER_ONLY(value, name) &&
                        <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "password match":
                    return (
                        validation.UNMATCHED_PASSWORDS(value, name) != true ? <div className={"errorMsg"}>
                            {validation.UNMATCHED_PASSWORDS(value, name)}
                        </div> : null
                    );

            }
        } else return null;
    };

    const handleChangeUser = (e, value) => {
        if (e.target.checked) {
            setUserForm({ ...userForm, role: value })
        } else {
            setUserForm({ ...userForm, role: null })
        }
    }

    const addUserDetails = async () => {
        setIsSubmitted(true);
        const { firstName, lastName, email, phoneNumber, address, role, id, password, confirmPassword } = userForm;
        if (firstName && lastName && email && phoneNumber && address && role && validation.EMAIL(email, 'email') == true &&
            validation.LETTER_ONLY(firstName, 'firstName') == true && validation.LETTER_ONLY(lastName, 'lastName') == true &&
            validation.PHONE_NO(phoneNumber.replace(/\D/g, ''), 'phoneNumber') == true
            && password && validation.PASSWORD(password, "password") == true &&
            (password === confirmPassword) &&
            validation.UNMATCHED_PASSWORDS(confirmPassword, password)
        ) {
            setSubmit(true)
            let userData = {
                id: id && id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber ? phoneNumber.replace(/\D/g, '') : '',
                address: address,
                role: role,
                password: password
            }

            await props.addUser(userData)
            setTimeout(() => {
                setIsSubmitted(false)
                setSubmit(false)
                props.onCancel()
            }, 1000)
            await props.getUsers(props.params);
            !userData.id && history.push('/Users')
        }
    }

    return (
        <div className="modal addTenant small-modal" id="modalId" style={{ display: "block" }}>
            {submit && <LoaderSpinner />}
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"
                            id="exampleModalLabel">{props.userData ? "Update User" : "Add User"}</h5>
                        <button type="button" className="close" onClick={props.onCancel}>
                            {/* <h3>Add User</h3> */}
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <h3>User Information</h3>
                            <div className="formSection">
                                <div className="row outrForm p-0 m-0">
                                    <div className="col-md-12 FormrightOutr">
                                        <div className="row">
                                            <div className="col-md-6 formInp formSecInp">
                                                <label>First Name</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Enter First Name" name={"firstName"}
                                                    onChange={handleChange} value={userForm.firstName}
                                                    maxLength="25" />
                                                {validate(userForm.firstName, "First Name", "required")}
                                                {userForm.firstName && validate(userForm.firstName, "First Name", "letter")}
                                            </div>
                                            <div className="col-md-6 formInp formSecInp">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Enter Last Name" value={userForm.lastName}
                                                    name={"lastName"} onChange={handleChange} maxLength="20" />
                                                {validate(userForm.lastName, "Last Name", "required")}
                                                {userForm.lastName && validate(userForm.lastName, "Last Name", "letter")}
                                            </div>
                                            <div className="col-md-6 formInp formSecInp">
                                                <label>Email</label>
                                                <input type="text" className="form-control"
                                                    // disabled={props.userData}
                                                    placeholder="Enter Email" value={userForm.email}
                                                    name={"email"} onChange={handleChange} />
                                                {validate(userForm.email, "Email Address", "required")}
                                                {userForm.email && validate(userForm.email, "Email Address", "email")}
                                            </div>

                                            <div className="col-md-6 formInp formSecInp">
                                                <label>Phone</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Phone"
                                                    value={userForm.phoneNumber}
                                                    onChange={handleChange}
                                                    name={"phoneNumber"}
                                                    maxLength="10" />
                                                {validate(userForm.phoneNumber, "Phone Number", "required")}
                                                {userForm.phoneNumber && validate(userForm.phoneNumber, "Phone Number", "phone")}
                                            </div>
                                            <div className="col-md-6 formInp formSecInp">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    value={userForm.password}
                                                    onChange={handleChange}
                                                    name={"password"}
                                                />
                                                {validate(userForm.password, "Password", "required")}
                                                {userForm.password && validate(userForm.password, "Password", "password")}
                                            </div>
                                            <div className="col-md-6 formInp formSecInp">
                                                <label>Confirm Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Confirm Password"
                                                    name={"confirmPassword"}
                                                    value={userForm.confirmPassword}
                                                    onChange={handleChange}

                                                />
                                                {validate(userForm.confirmPassword, "Confirm Password", "required")}
                                                {/* {userForm.confirmPassword && userForm.password && validate(userForm.confirmPassword, userForm.password, "password match")} */}
                                                {userForm.confirmPassword && userForm.password &&
                                                    validation.UNMATCHED_PASSWORDS(userForm.confirmPassword, userForm.password) != true ? <div className={"errorMsg"}>
                                                        {validation.UNMATCHED_PASSWORDS(userForm.confirmPassword, userForm.password)}
                                                    </div> : null
                                                }
                                            </div>
                                            <div className="col-xl-12 formInp">
                                                <label>Address</label>
                                                <textarea className="form-control" placeholder="Enter Address"
                                                    value={userForm.address}
                                                    name={"address"} cols="30" rows="10"
                                                    onChange={handleChange}>{userForm.address}</textarea>
                                                {validate(userForm.address, "Address", "required")}
                                            </div>

                                            {/* {props.userData && userForm.role && props.userData._id && props.userData.role == "admin" ? null : */}
                                            <div className="col-md-12 formInp  p-0 ml-2">
                                                <div className="col-md-9 otrTenantCheck p-0">
                                                    <div className="otrRec">
                                                        {role != "adminuser" &&
                                                            <label className="containerCheck">Admin User
                                                            <input
                                                                    type="checkbox"
                                                                    name="role"
                                                                    onChange={(e) => handleChangeUser(e, "adminuser")}
                                                                    checked={userForm.role === "adminuser"}
                                                                    value={userForm.role}
                                                                    disabled={role === "adminuser"}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>}
                                                    </div>

                                                    <div className="otrProcess">
                                                        <label className="containerCheck">Maintenance User
                                                            <input
                                                                type="checkbox"
                                                                name="role"
                                                                onChange={(e) => handleChangeUser(e, "maintenance")}
                                                                checked={userForm.role === "maintenance"}
                                                                value={userForm.role}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    {role != "adminuser" && <div className="otrProcess">
                                                        <label className="containerCheck">Super Admin
                                                            <input
                                                                type="checkbox"
                                                                name="role"
                                                                onChange={(e) => handleChangeUser(e, "admin")}
                                                                checked={userForm.role === "admin"}
                                                                value={userForm.role}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>}

                                                </div>
                                                {validate(userForm.role, "Role", "required")}

                                            </div>
                                            {/* } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit"
                            onClick={addUserDetails}
                        >{props.userData ? "Update User" : "Add User"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(userForm) {
    const { settingsReducer, commonReducer, profileReducer } = userForm;
    return {
        commonReducer,
        settingsReducer,
        profileReducer
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction })(
    AddExpense
);
