import React from "react";
// import action from "./action";
// import { connect } from "react-redux";
import history from "../../../config/history";
import moment from 'moment';
import base64url from "base64url";
import renderHTML from 'react-render-html';
import parser from 'html-react-parser';



class MessageListItem extends React.Component {

    render() {
        const { messageItem, listParams, handleDeleteMessage } = this.props;
        const encryptedSubject = messageItem.thread ? base64url(messageItem.thread) : '';
        return (
            <tr className="custom-row">
                <td className="checkboxPadding "><label className="invoiceCheckbox "
                    style={{ marginLeft: "9px" }}>
                    <input
                        type="checkbox"
                        name="isArchived"
                        // disabled={props.status}
                        defaultChecked={this.props.isChecked ? true : false}
                        onChange={(e) => this.props.handleCheck(e, messageItem)}
                    />
                    <span className="checkmark "></span>
                </label></td>
                <td className="text-center messageImg"
                    onClick={() => history.push(`/Messaging/Details/${encryptedSubject}?fSrs=${listParams.type}`)}>
                    <img src="/images/inbox-message.png" alt="" />
                </td>
                <td className="name-width div-column"
                    onClick={() => history.push(`/Messaging/Details/${encryptedSubject}?fSrs=${listParams.type}`)}>
                    <div className="c-td">
                        <div className="name">{messageItem.from}</div>
                    </div>
                </td>
                {/* <td className="div-column"
                    onClick={() => history.push(`/Messaging/Details/${encryptedSubject}?fSrs=${listParams.type}`)}>
                    <div className="c-td">
                        <p className="mail-content">{messageItem.subject ? messageItem.subject : "**No Subject"}</p>
                    </div>
                </td> */}
                <td className="div-column"
                    onClick={() => history.push(`/Messaging/Details/${encryptedSubject}?fSrs=${listParams.type}`)}>
                    <div className="c-td date">{moment(messageItem.createdAt).format('h:mm A')}</div>
                </td>
                <td>
                    <div className="c-td">
                        <i className="fas fa-trash" onClick={() => handleDeleteMessage(messageItem.messageId)} />
                    </div>
                </td>
            </tr>
        )
    }
}

export default MessageListItem;