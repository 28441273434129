import innagoGateWay from "../../services/authorizationService";

import serviceEndpoints from "../../config/serviceEndpoints";

export const postPlaidRequest = params =>
    innagoGateWay.post(serviceEndpoints.plaideEndpoints.postRequest, params);

export const getDataSource = params =>
    innagoGateWay.get(serviceEndpoints.plaideEndpoints.getDataSourceDetails, { params });

export const getTransaction = params =>
    innagoGateWay.get(serviceEndpoints.plaideEndpoints.getTransaction, { params });

export const getCategories = params =>
    innagoGateWay.get(serviceEndpoints.plaideEndpoints.getCategory, { params });

export const updateTransaction = params =>
    innagoGateWay.put(serviceEndpoints.plaideEndpoints.getTransaction, params);

export const deleteTransaction = params =>
    innagoGateWay.delete(serviceEndpoints.plaideEndpoints.getTransaction, { params });

export const deleteTransactionMultiple = data =>
    innagoGateWay.delete(serviceEndpoints.plaideEndpoints.deleteTransaction, { data });

export const getPropertyBankDetails = params =>
    innagoGateWay.get(serviceEndpoints.plaideEndpoints.getBankDetails, { params });

export const updateBankDetails = params =>
    innagoGateWay.put(serviceEndpoints.plaideEndpoints.updateBankDetails, params);

export const importData = () =>
    innagoGateWay.get(serviceEndpoints.plaideEndpoints.importDetails);
