// import React from "react";
// import { connect } from 'react-redux'
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import Property from './components/property';
// import OccupancyStatics from './components/occupancyStatics';
// import Maintanance from './components/maintanance';
// import Revenue from './components/revenue';
// import Tenants from './components/tenants';
// import Messages from './components/messages';
// import Invoice from './components/invoice';
// import Expense from './components/expense';
// import Lease from './components/lease';
// import action from './action'

// class index extends React.Component {
// constructor(props) {
//     super(props);
//     this.state = {
//         defaultPositions: [
//             {
//                 "_id": "5e1ea2e638ce592f7cff2531",
//                 "title": "property",
//                 "orderNo": 0
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff252f",
//                 "title": "maintenance",
//                 "orderNo": 1
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff2530",
//                 "title": "occupancy",
//                 "orderNo": 2
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff252e",
//                 "title": "revenue",
//                 "orderNo": 3
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff252d",
//                 "title": "tenants",
//                 "orderNo": 4
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff252c",
//                 "title": "message",
//                 "orderNo": 5
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff252b",
//                 "title": "invoice",
//                 "orderNo": 6
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff252a",
//                 "title": "expense",
//                 "orderNo": 7
//             },
//             {
//                 "_id": "5e1ea2e638ce592f7cff2529",
//                 "title": "lease",
//                 "orderNo": 8
//             }
//         ],
//         cardPositions: []
//     }
// }

// async componentDidMount() {
//     await this.props.getCardPositions();
//     const { dashboardReducer: { dashboardCardList } } = this.props;
//     if (dashboardCardList.data && dashboardCardList.data.dashboardList && dashboardCardList.data.dashboardList.length) {
//         const cardPosition = dashboardCardList.data.dashboardList[0].orderList;
//         this.setState({ cardPositions: cardPosition.length ? cardPosition : this.state.defaultPositions })
//     }
// }
// // sort cards wrt order
// sortCards = (cards) => {
//     if (!cards)
//         return []
//     else
//         return cards.sort((a, b) => (a.orderNo > b.orderNo) ? 1 : ((b.orderNo > a.orderNo) ? -1 : 0))
// }
// // render cards wrt item title
// renderCard = (item, ref, draggableProps, dragHandleProps, index) => {
//     switch (item.title) {
//         case 'property':
//             return <Property key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'occupancy':
//             return <OccupancyStatics key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'maintenance':
//             return <Maintanance key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'revenue':
//             return <Revenue key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'tenants':
//             return <Tenants key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'message':
//             return <Messages key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'invoice':
//             return <Invoice key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'expense':
//             return <Expense key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         case 'lease':
//             return <Lease key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//         default:
//             return <Lease key={index} reference={ref} draggableProps={draggableProps} dragHandleProps={dragHandleProps} />
//     }
// }

// onDragEnd = (result) => {
//     if (!result.destination) return;
//     const { destination, source } = result;
//     const { dashboardReducer: { dashboardCardList } } = this.props;
//     let temp = this.state.cardPositions;
//     temp[source.index]['orderNo'] = destination.index;
//     temp[destination.index]['orderNo'] = source.index
//     this.setState({ cardPositions: temp });
//     if (dashboardCardList.data && dashboardCardList.data.dashboardList && dashboardCardList.data.dashboardList.length) {
//         const params = {
//             orderList: this.state.cardPositions,
//             id: dashboardCardList.data.dashboardList[0]._id
//         }
//         this.props.updateCardPositions(params)
//     }
// }



//     render() {
//         return (
//             <div className="otrDetail d-flex drg">
//                 <DragDropContext
//                     onDragEnd={this.onDragEnd}
//                     onDragStart={this.onDragStart}
//                     onDragUpdate={this.onDragUpdate}
//                     onBeforeDragStart={this.onBeforeDragStart}
//                 >
//                     <Droppable droppableId="droppable">
//                         {(provided, snapshot) => (
//                             <div
//                                 className="droppable-otr d-flex flex-wrap col-md-12 p-0"
//                                 {...provided.droppableProps}
//                                 ref={provided.innerRef}
//                             >
// {
//     this.sortCards(this.state.cardPositions).map((item, index) => (
//         <Draggable key={item._id} draggableId={item._id} index={index}>
//             {(provided, snapshot) => {
//                 return this.renderCard(
//                     item,
//                     { ref: provided.innerRef },
//                     { ...provided.draggableProps },
//                     { ...provided.dragHandleProps },
//                     index
//                 )
//             }}
//         </Draggable>
//     ))
// }
//                             </div>
//                         )}
//                     </Droppable>
//                 </DragDropContext>
//             </div>
//         );
//     }
// }
// const mapStateToProps = state => {
//     const { dashboardReducer } = state;
//     return { dashboardReducer }
// }
// export default connect(mapStateToProps, { ...action })(index);


import React from "react";
import Property from './components/property';
import OccupancyStatics from './components/occupancyStatics';
import Maintanance from './components/maintanance'
import Revenue from './components/revenue'
import Tenants from './components/tenants'
import Messages from './components/messages';
import Invoice from './components/invoice';
import Expense from './components/expense'
import Lease from './components/lease'
import { connect } from 'react-redux'
import action from '../action'
import LoaderSpinner from "../../Common/components/Loader";


class index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultPositions: [
                {
                    "_id": "5e1ea2e638ce592f7cff2530",
                    "title": "occupancy",
                    "orderNo": 0
                },
                {
                    "_id": "5e1ea2e638ce592f7cff2531",
                    "title": "property",
                    "orderNo": 1
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252f",
                    "title": "maintenance",
                    "orderNo": 2
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252c",
                    "title": "message",
                    "orderNo": 3
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252d",
                    "title": "tenants",
                    "orderNo": 4
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252e",
                    "title": "revenue",
                    "orderNo": 5
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252b",
                    "title": "invoice",
                    "orderNo": 6
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252a",
                    "title": "expense",
                    "orderNo": 7
                },
                {
                    "_id": "5e1ea2e638ce592f7cff2529",
                    "title": "lease",
                    "orderNo": 8
                }
            ],
            cardPositions: [],
            isLoader: false
        }
    }

    async componentDidMount() {
        this.setState({ isLoader: true })
        await this.props.getCardPositions();
        const { dashboardReducer: { dashboardCardList } } = this.props;
        if (dashboardCardList.data && dashboardCardList.data.dashboardList && dashboardCardList.data.dashboardList.length) {
            const cardPosition = dashboardCardList.data.dashboardList[0].orderList;
            this.setState({ cardPositions: cardPosition.length ? cardPosition : this.state.defaultPositions })
        }
        this.setState({ isLoader: false })

    }

    // sort cards wrt order
    sortCards = (cards) => {
        if (!cards)
            return []
        else
            return cards.sort((a, b) => (a.orderNo > b.orderNo) ? 1 : ((b.orderNo > a.orderNo) ? -1 : 0))
    }

    // render cards wrt item title
    renderCard = (item, index) => {
        switch (item.title) {
            case 'property':
                return <Property key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'occupancy':
                return <OccupancyStatics key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'maintenance':
                return <Maintanance key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'revenue':
                return <Revenue key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'tenants':
                return <Tenants key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'message':
                return <Messages key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'invoice':
                return <Invoice key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'expense':
                return <Expense key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'lease':
                return <Lease key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            default: return null
            // return <Lease key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
        }
    }

    handleDragStart = () => event => {
        event.dataTransfer.setData("application/my-app", event.target.id);
        event.dataTransfer.dropEffect = "move";
    };

    handleDragOver = () => event => {
        event.preventDefault(); // Necessary. Allows us to drop.
    };

    handleDrop = () => event => {
        event.preventDefault();
        const { dashboardReducer: { dashboardCardList } } = this.props;
        const incomingElement = event.dataTransfer.getData("application/my-app");
        const targetElement = event.target.closest('.drpbl').id;

        if (incomingElement != targetElement) {
            let tempState = this.state.cardPositions;
            let inc = tempState[incomingElement]
            // Re-ordering elements
            let x = [];
            tempState.map((card, idx) => {
                if (incomingElement >= targetElement) {
                    if (idx == targetElement) {
                        x.push(inc)
                    }
                    if (idx != incomingElement) {
                        x.push(card)
                    }
                } else {
                    if (idx != incomingElement) {
                        x.push(card)
                    }
                    if (idx == targetElement) {
                        x.push(inc)
                    }
                }
            })
            // setting data in state
            this.setState({
                cardPositions: x.map((c, i) => { return { ...c, orderNo: i } })
            }, () => {
                if (dashboardCardList.data && dashboardCardList.data.dashboardList && dashboardCardList.data.dashboardList.length) {
                    const params = {
                        orderList: this.state.cardPositions,
                        id: dashboardCardList.data.dashboardList[0]._id
                    }
                    this.props.updateCardPositions(params)
                }
            })

        }
    };

    render() {
        const { isLoader } = this.state
        return (
            <div className="otrDetail d-flex drg">
                {isLoader && <LoaderSpinner />}
                {
                    this.sortCards(this.state.cardPositions).map((item, index) => this.renderCard(item, index))
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { dashboardReducer } = state;
    return { dashboardReducer }
}

export default connect(mapStateToProps, { ...action })(index);
