import React from "react";
import {connect} from "react-redux";
import action from "../action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {withRouter} from "react-router-dom"
import moment from 'moment';
import CommonDatePicker from "../../Common/components/CommonDatePicker";

class MessagingFilterForm extends React.Component {

    render() {
        const {handleShowFilter, onChangeFromDate, onChangeToDate, handleClearFilter, handleKeyWordOnChange, handleApplyFilter, fromDate, toDate, keyWord} = this.props;

        return (
            <>
                <div className="filterSide filterBottom" style={{'display': 'block'}}>
                    <div className="formSection outerBg">
                        <button type="button" className="close" onClick={handleShowFilter}><span
                            aria-hidden="true">×</span></button>
                        <div className="col-md-12 row m-0 p-0 fltrOtr">
                            <div className="col-md-12 formInp">
                                <label>Keyword</label>
                                <input
                                    onChange={handleKeyWordOnChange}
                                    type="text"
                                    className="form-control"
                                    placeholder="Keyword"
                                    value={keyWord}
                                />
                            </div>
                            <div className="col-md-6 formInp">
                                <label>From</label>
                                <CommonDatePicker
                                    name="fromDate"
                                    placeholderText="From Date"
                                    disableClock={true}
                                    format="mm/dd/yyyy"
                                    className="date1 form-control"
                                    onChange={onChangeFromDate}
                                    value={fromDate}
                                />
                            </div>
                            <div className="col-md-6 formInp">
                                <label>To</label>
                                <CommonDatePicker
                                    name="toDate"
                                    placeholderText="To Date"
                                    disableClock={true}
                                    format="mm/dd/yyyy"
                                    className="date1 form-control"
                                    onChange={onChangeToDate}
                                    value={toDate}
                                />
                            </div>
                        </div>

                        <div className="btnOtr text-right">
                            <button type="button" className="btn btn-primary btnClear"
                                    onClick={handleClearFilter}>Clear Filters
                            </button>
                            <button type="button" className="btn btn-primary btnApply" onClick={handleApplyFilter}>Apply
                                Filters
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(MessagingFilterForm));