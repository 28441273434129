import React, { useState, useEffect } from "react";
import Modal from '../../../Common/components/Modal';
import AddTenants from '../../../Tenants/components/AddTenants';
import Confirm from '../../../Common/components/Confirm';
import action from "../../../Tenants/action";
import { connect } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import commonAction from '../../../Common/action';

import toastr from '../../../Common/components/Toaster'
import LoaderSpinner from "../../../Common/components/Loader";
import usePrevious from "../../../Common/components/prevProps";
import TenantDetailLease from '../../../Tenants/components/TenantDetailLease';
import ConfirmLease from '../../../Tenants/components/ConfirmLease';
import { formatUsPhone } from "../../../Common/components/utils";
import history from '../../../../config/history';
import settingsAction from '../../../Settings/action';
import TemplateListing from '../../../Tenants/components/templateListModal';
import LeaseEdit from '../../../Tenants/components/leaseEditModal'
import SignLease from "../../../Leases&Files/components/signModal";






const TenantDetails = (props) => {
    const [tenantId, setTenantId] = useState('');
    const [editId, setEditId] = useState('');
    const [submitLease, setSubmitLease] = useState(false);
    const [viewLeaseAgreement, setViewLeaseAgreement] = useState(false);
    const [tenantModal, setTenantModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [templateData, setTemplateData] = useState(null)
    const [oldId, setOldId] = useState(null)
    const [isButtonDisabled, setIsButton] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLeaseEdit, setIsLeaseEdit] = useState(false);
    const [isSign, setIsSign] = useState(false);
    const [leaseSign, setLeaseSign] = useState(null);
    const [leaseSignModal, setLeaseSignModal] = useState(null);
    const [addTenant, setAddTennat] = useState(false);


    const prevTemplateEdit = usePrevious(props.tenantReducer.leaseEdit);



    const { tenantReducer: { lease }, propertyDetails } = props;

    const prevLease = usePrevious(lease);
    const { commonReducer: { emailCheck } } = props;
    const prevEmailCheck = usePrevious(emailCheck);

    const property = { value: propertyDetails && propertyDetails._id, label: propertyDetails && propertyDetails.propertyName };

    useEffect(() => {
        props.getTemplates()
    }, [])

    useEffect(() => {
        if (addTenant && !emailCheck.success) {
            toastr.error("Please add a notification email!")
        } else if (addTenant && emailCheck.success && prevEmailCheck != emailCheck) {
            if (editId)
                 props.getTenantId(editId);
            setViewLeaseAgreement(false);
            setTenantModal(true);
        }
    }, [prevEmailCheck, emailCheck]);

    useEffect(() => {
        if (submitLease && !lease.success) {
            toastr.error(lease.message)

        } else if (submitLease && lease.success && prevLease != lease) {
            toastr.success(lease.message);
            setIsSign(true)
            setLeaseSign(lease.lease)
        }
    }, [prevLease, lease]);

    useEffect(() => {
        if (isSubmitted && prevTemplateEdit != props.tenantReducer.leaseEdit) {
            if (props.tenantReducer.leaseEdit.success) {
                setIsLeaseEdit(true)
            }
            else {
                toastr.error("Error while adding lease")
            }
        }
    }, [prevTemplateEdit, props.tenantReducer.leaseEdit])

    //add tenant modal
    const onOpenModal = async (id) => {
        setEditId(id);
        setAddTennat(true)
        await props.emailCheck({ emailType: "notification" })

    }

    const onCloseModal = async () => {
        setTenantModal(false);
        await props.getProperty();
    }

    const renderModal = () => {
        const { tenantReducer: { tenantsById: { tenant: tenantDetail } } } = props;

        return <AddTenants
            onCancel={onCloseModal}
            leaseModal={leaseModal}
            property={property}
            unitId={props.unit._id}
            tenantDetail={tenantDetail && tenantDetail}
            editId={editId}
            params={{ unit: props.unit._id, isDeleted: false , isClosed: false }}
        />
    }

    //Confirm of lease add and show
    const leaseModal = (id) => {
        const { settingsReducer: { templateDetails } } = props;
        if (templateDetails && templateDetails.length) {
            setIsButton(true)
            if (templateDetails.length === 1) {
                setModal(true);
                setTemplateData(templateDetails[0])
                setTemplateId(templateDetails[0]._id)
            }
            else {
                setModal(true);
            }
            setTenantId(id);

        }
        else {
            toastr.error("Please add a template to send Lease!!")
        }

    }

    const closeModalTemplate = () => {
        setTemplateModal(false)
        setIsButton(false)
        setIsSubmitted(false)
        setIsSubmitted(false)
        setTemplateId(null)
    }

    const renderTemplateList = () => {
        const { settingsReducer: { templateDetails } } = props;
        return <TemplateListing
            onCancel={closeModalTemplate}
            templateDetails={templateDetails}
            submitTemplate={submitTemplate}
            handleChangeTemplate={handleChangeTemplate}
            templateId={templateId}

        />
    };

    const submitTemplate = async () => {
        setTemplateModal(false)
        setModal(false)
        setIsSubmitted(true)
        setSubmitLease(true)
        await props.editLeaseAgreement({ tenantId: tenantId, templateId: templateId });
        setTimeout(() => {
            setIsSubmitted(false)
            setSubmitLease(false)

        }, 1000);

    }

    const handleChangeTemplate = (e, details) => {

        if (e.target.checked) {
            setTemplateId(details._id)
            setTemplateData(details)
        }
        else {
            setTemplateId(null)
            setTemplateData(null)
        }
    }


    const closeModal = () => {
        setModal(false);

    }

    const renderModalLease = () => {
        return <ConfirmLease
            title='Lease Agreement'
            message='Do you want to send lease agreement?'
            close={closeModal}
            onConfirm={leaseAgreement}
            onView={viewAgreement}
            isButtonDisabled={true}
        />
    }

    const leaseAgreement = async () => {
        setTemplateModal(true)
        setViewLeaseAgreement(false);
        setModal(false);
        // setSubmitLease(true);
        // await props.leaseAgreement({ tenantId: tenantId, templateId: templateId });
        // setTimeout(() => {
        //     setViewLeaseAgreement(false);
        //     setSubmitLease(false);
        //     setTemplateId(null)
        // }, 100);
    }

    //View lease agreement modal
    const viewAgreement = async () => {
        setSubmitLease(true);
        setTemplateModal(true)
        // setModal(false);
        // await props.viewLeaseAgreement({ tenantId: tenantId });
        setTimeout(() => {
            setSubmitLease(false);
            setViewLeaseAgreement(true);
        }, 1000);
    }

    const closeViewLeaseModal = () => {
        setViewLeaseAgreement(false);
    }

    const renderViewLeaseModal = () => {
        return <TenantDetailLease
            tenantId={tenantId}
            onCancel={closeViewLeaseModal}
            // lease={props.tenantReducer.lease}
            lease={templateData.templateContent}
            leaseAgreement={leaseAgreement}
            closeModal={onCloseModal}
            onOpenModal={onOpenModal}
        />
    }

    const noUnit = () => {
        toastr.error("This unit is already occupied!!")
    }

    const renderTenantFormat = (id) => {
        history.push(`/Tenants/${id}/Summary`)
    }

    const closeModalLeaseEdit = () => {
        setIsLeaseEdit(false);
        setIsButton(false)

    }

    const renderModalLeaseEdit = () => {

        return <LeaseEdit
            templateData={props.tenantReducer.leaseEdit}
            onCancel={closeModalLeaseEdit}
            addEditedLeaseData={addEditedLeaseData}
            editId={props.tenantReducer.leaseEdit._id}
        />
    }

    const addEditedLeaseData = async (data) => {
        setSubmitLease(true);
        setIsLeaseEdit(false);
        await props.sendLeaseAgreement(data, { templateId, tenantId })

        await props.getTenant({ unit: props.unit._id, limit: 10, offSet: 0, isDeleted: false, isClosed: false });
        setSubmitLease(false);
        setIsSubmitted(false);

    }

    const renderSignConfirm = () => {
        return <Confirm
            title='Sign Lease'
            message='Do you want to sign now?'
            onCancel={closeSignConfirm}
            onConfirm={signLease}
        />
    }

    const closeSignConfirm = () => {
        setIsSign(false)
    }

    const signLease = () => {
        setIsSign(false)
        setLeaseSignModal(true)
    }

    const closeSignLease = async () => {
        setLeaseSignModal(false)
    }

    const renderSignLease = () => {
        return <SignLease
            onCancel={closeSignLease}
            lease={leaseSign.documentData}
            leaseId={leaseSign._id}
        />
    }

    const unitAvailable = props.unit && props.unit.isAvailable
    return (
        <div className="innago-table">
            {submitLease && <LoaderSpinner />}
            {/* <ToastContainer /> */}
            {templateModal ? <Modal body={renderTemplateList()} onCancel={closeViewLeaseModal} /> : null}
            {tenantModal ? <Modal body={renderModal()} onCancel={onCloseModal} /> : null}

            {modal ? <Modal body={renderModalLease()} onCancel={closeModal} /> : null}
            {isLeaseEdit ? <Modal body={renderModalLeaseEdit()} onCancel={closeModalLeaseEdit} /> : null}
            {viewLeaseAgreement ? <Modal body={renderViewLeaseModal()} onCancel={closeViewLeaseModal} /> : null}
            {leaseSignModal ? <Modal body={renderSignLease()} onCancel={closeSignLease} /> : null}
            {isSign ? <Modal body={renderSignConfirm()} onCancel={closeSignConfirm} /> : null}
            <h3 className="propDetlTenant">
                <div>Tenants Details</div>
                <button type="button" className="editright" onClick={() => unitAvailable ? onOpenModal() : noUnit()}> Add Tenant</button>
            </h3>

            <div className="table-responsive">
                <table className="table-gap table-td">
                    <tbody>

                        <tr className="table-head">
                            {/*<th className="wdt-01"></th>*/}
                            <th className="wdt-03">Name</th>
                            <th className="wdt-03">Address</th>
                            <th className="emailTxtTab">Email</th>
                            <th className="phoneNo">Phone</th>
                        </tr>
                        {props.tenantList && props.tenantList.length ? props.tenantList.map((tenant, key) => {
                            return <tr className="detailRow" key={key}>

                                <td onClick={() => renderTenantFormat(tenant._id)} style={{ cursor: 'pointer' }}>
                                    <div className="c-td">
                                        <div className="img-name">
                                            <i >
                                                <div className="txtNameLet">
                                                    {tenant.firstName.charAt(0).toUpperCase() +
                                                        tenant.lastName.charAt(0).toUpperCase()}
                                                </div>
                                            </i>
                                            <div className="name">{tenant.firstName + " " + tenant.lastName}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="c-td">
                                        <p>
                                            {tenant.propertyId ? tenant.propertyId.address : null}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="c-td">
                                        {tenant.email ? tenant.email : null}
                                    </div>
                                </td>
                                <td>
                                    <div className="c-td">{tenant.phoneNumber ? formatUsPhone(tenant.phoneNumber) : null}</div>
                                </td>
                            </tr>
                        }) : <tr>
                                {/*<i*/}
                                {/*><img src="/images/icon-dots.png" alt=""*/}
                                {/*/></i>*/}
                                <td colSpan={4} className="text-center no_value_table"><i className="far fa-thumbs-down"></i>No details Found!!!</td>
                            </tr>}

                    </tbody>
                </table>
            </div>
        </div>

    )
}
const mapStateToProps = state => {
    const { tenantReducer, commonReducer, settingsReducer } = state;
    return {
        tenantReducer,
        commonReducer,
        settingsReducer

    };
};

export default connect(mapStateToProps, {
    ...action,
    ...commonAction,
    ...settingsAction
})(TenantDetails);

