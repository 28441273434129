import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to) => _.range(from, to + 1);

class Pagination extends Component {

    componentDidMount() {
        this.gotoPage(1);
    }

    gotoPage = page => {
        const {onPageChanged, totalRecords} = this.props;
        let {currentPage} = this.props;
        currentPage = Math.max(0, Math.min(page, totalRecords));
        onPageChanged(currentPage)
    };

    handleClick = page => e => {
        e.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = e => {
        e.preventDefault();
        this.gotoPage(this.props.currentPage - 1);
    };

    handleMoveRight = e => {
        e.preventDefault();
        this.gotoPage(this.props.currentPage + 1);
    };

    fetchPageNumbers = () => {

        const {totalRecords, pageNeighbours, pageLimit, currentPage} = this.props;
        const totalPages = Math.ceil(totalRecords / pageLimit);

        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {

            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);
            let extraPages;

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                case (!hasLeftSpill && hasRightSpill): {
                    extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    };

    render() {

        const {totalRecords = 1, pageLimit = 10, currentPage} = this.props;

        const totalPages = Math.ceil(totalRecords / pageLimit);

        if (!totalRecords || totalPages <= 1) return null;

        const pages = this.fetchPageNumbers();
        return (
            <Fragment>
                <ul className="pagination">

                    {pages.map((page, index) => {
                        if (page === LEFT_PAGE) return (
                            <li key={index} className="page-item">
                                <a className="page-link" href="#" tabIndex="-1" onClick={this.handleMoveLeft}>
                                    <i className="fas fa-chevron-left"/></a>
                            </li>
                        );

                        if (page === RIGHT_PAGE) return (
                            <li className="page-item" key={index}>
                                <a className="page-link" href="#" onClick={this.handleMoveRight}
                                   aria-label={<i className="fas fa-chevron-right"/>}>
                                    <i className="fas fa-chevron-right"/></a>
                            </li>
                        );

                        return (
                            <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                                <a className="page-link" href="#" onClick={this.handleClick(page)}>{page}</a>
                            </li>

                        );
                    })}

                </ul>
            </Fragment>
        );

    }
}

Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func,
    currentPage: PropTypes.number
};

export default Pagination;

