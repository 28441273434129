import * as actionTypes from "./constant";
import * as Service from "./service";

//add the property details
const addProperty = value => {
  let propertyDetails = new FormData();
  value.images.map(img => {
    propertyDetails.append("file", img.image[0]);
  })
  propertyDetails.append("data", JSON.stringify(value.data));
  const request = data => ({
    type: actionTypes.ADD_PROPERTY_REQUEST,
    data
  });
  const success = data => ({
    type: actionTypes.ADD_PROPERTY_SUCCESS,
    data
  });
  const failure = error => ({
    type: actionTypes.ADD_PROPERTY_FAILURE,
    error
  });

  return async dispatch => {
    try {
      dispatch(request());
      const res = await Service.addProperty(propertyDetails);
      if (res.status === 200) {
        const userDetails = res.data;
        await dispatch(success(userDetails));
      } else {
        await dispatch(failure(res.response.data));
      }
    } catch (e) {
      await dispatch(failure(e.response && e.response.data));
    }
  };
};

const getZillowData = value => {
  const request = data => ({
    type: actionTypes.GET_ZILLOW_DATA_REQUEST,
    data
  });
  const success = data => ({
    type: actionTypes.GET_ZILLOW_DATA_SUCCESS,
    data
  });
  const failure = error => ({
    type: actionTypes.GET_ZILLOW_DATA_FAILURE,
    error
  });

  return async dispatch => {
    try {
      dispatch(request());
      const res = await Service.getZillowData(value);
      if (res.status === 200) {
        const zillloData = res.data;
        await dispatch(success(zillloData));
      } else {
        await dispatch(failure(res.response.data));
      }
    } catch (e) {
      await dispatch(failure(e.response && e.response.data));
    }
  };
};

const updatePropertyImage = params => {
  const request = data => ({
    type: actionTypes.UPDATE_PROPERTY_IMAGE_REQUEST,
    data
  });
  const success = data => ({
    type: actionTypes.UPDATE_PROPERTY_IMAGE_SUCCESS,
    data
  });
  const failure = error => ({
    type: actionTypes.UPDATE_PROPERTY_IMAGE_FAILURE,
    error
  });
  return async dispatch => {
    try {
      dispatch(request());
      const res = await Service.updatePropertyImage(params);
      if (res.status === 200) {
        const updatePropImageResponse = res.data;
        await dispatch(success(updatePropImageResponse));
      } else {
        await dispatch(failure(res.response.data));
      }
    } catch (e) {
      await dispatch(failure(e.response && e.response.data));
    }
  };
};
const updateProperty = params => {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.UPDATE_PROPERTY_REQUEST });
      const res = await Service.updateProperty(params);
      if (res.status === 200) {
        const updatePropImageResponse = res.data;
        await dispatch({
          type: actionTypes.UPDATE_PROPERTY_SUCCESS,
          data: updatePropImageResponse
        });
      } else {
        await dispatch({
          type: actionTypes.UPDATE_PROPERTY_FAILURE,
          error: res.response && res.response.data
        });
      }
    } catch (e) {
      await dispatch({
        type: actionTypes.UPDATE_PROPERTY_FAILURE,
        error: e.response
      });
    }
  };
};
const deleteProperty = params => {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.DELETE_PROPERTY_REQUEST });
      const res = await Service.deleteProperty(params);
      if (res.status === 200) {
        const deletePropImageResponse = res.data;
        await dispatch({
          type: actionTypes.DELETE_PROPERTY_SUCCESS,
          data: deletePropImageResponse
        });
      } else {
        await dispatch({
          type: actionTypes.DELETE_PROPERTY_FAILURE,
          error: res.response && res.response.data
        });
      }
    } catch (e) {
      await dispatch({
        type: actionTypes.DELETE_PROPERTY_FAILURE,
        error: e.response
      });
    }
  };
};
const makeImageDefault = params => {
  let propertyDetails = new FormData();
  params.image && propertyDetails.append("file", params.image);
  propertyDetails.append("data", JSON.stringify(params));
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.UPDATE_PROPERTY_REQUEST });
      const res = await Service.makeImageDefault(propertyDetails, params.id, params.imageid);
      if (res.status === 200) {
        const updatePropImageResponse = res.data;
        await dispatch({
          type: actionTypes.MAKE_IMAGE_DEFAULT_SUCCESS,
          data: updatePropImageResponse
        });
      } else {
        await dispatch({
          type: actionTypes.MAKE_IMAGE_DEFAULT_FAILURE,
          error: res.response && res.response.data
        });
      }
    } catch (e) {
      await dispatch({
        type: actionTypes.MAKE_IMAGE_DEFAULT_FAILURE,
        error: e.response
      });
    }
  };
};
const deletePropertyImage = params => {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.DELETE_PROPERTY_IMAGE_REQUEST });
      const res = await Service.deletePropertyImage(params);
      if (res.status === 200) {
        const deletePropertyImgResponse = res.data;
        await dispatch({
          type: actionTypes.DELETE_PROPERTY_IMAGE_SUCCESS,
          data: deletePropertyImgResponse
        });
      } else {
        await dispatch({
          type: actionTypes.DELETE_PROPERTY_IMAGE_FAILURE,
          error: res.response && res.response.data
        });
      }
    } catch (e) {
      await dispatch({
        type: actionTypes.DELETE_PROPERTY_IMAGE_FAILURE,
        error: e.response
      });
    }
  };
};

//delete a unit
const deletePropertyUnit = params => {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.DELETE_PROPERTY_UNIT_REQUEST });
      const res = await Service.deletePropertyUnit(params);
      if (res.status === 200) {
        const deletePropertyImgResponse = res.data;
        await dispatch({
          type: actionTypes.DELETE_PROPERTY_UNIT_SUCCESS,
          data: deletePropertyImgResponse
        });
      } else {
        await dispatch({
          type: actionTypes.DELETE_PROPERTY_UNIT_FAILURE,
          error: res.response && res.response.data
        });
      }
    } catch (e) {
      await dispatch({
        type: actionTypes.DELETE_PROPERTY_UNIT_FAILURE,
        error: e.response
      });
    }
  };
};




export default {
  addProperty,
  getZillowData,
  updatePropertyImage,
  updateProperty,
  deleteProperty,
  makeImageDefault,
  deletePropertyImage,
  deletePropertyUnit
};
