import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AsyncSelect from 'react-select/async';
import { status } from "../constant";
import commonAction from "../../Common/action";
import tenantAction from "../../Tenants/action";

const MaintenanceFilter = (props) => {



    useEffect(() => {
        async function renderCall() {
            await props.getProperty()
            await props.getExpenseCategory()
        }

        renderCall();
    }, []);



    const { tenantReducer: { tenants } } = props;

    const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
        value: e._id,
        label: e.firstName + " " + e.lastName
    }));





    const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));

    const handleFilter = () => {
        props.applyFilter()
    }


    return (
        <div className="filterSide fltrMaint" style={{ 'display': "block" }}>
            <div className="formSection outerBg">
                <button type="button" className="close" onClick={props.closeFilter}><span aria-hidden="true">×</span>
                </button>
                <div className="col-md-12 row m-0 p-0 fltrOtr">
                    <div className="col-md-6 formInp mb-3">
                        <label>Status</label>
                        <div className="selOtr">
                            <select className="form-control" name={"status"} onChange={props.handleChange}
                                value={props.params.status}>
                                <option>Select Status</option>
                                <option value={"1,2,3,4,5,6"}>All Active Tickets</option>
                                {status.map(st => {
                                    return <option value={st.value}>{st.name}</option>
                                })}
                                
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 formInp mb-3">
                        <label>Contracter Name</label>
                        {/* <div className="selOtr">
                            <select className="form-control" name={"assignee"}
                                    onChange={props.handleChange} value={props.params.categoryId}>
                                <option>Select Assignee</option>MessagingFilterForm
                                <option value={1}>Unassigned</option>
                                <option value={2}>Self</option>
                                <option value={3}>Contractor</option>
                            </select>
                        </div> */}
                        <input type={"text"} className="form-control" name={"contractorName"}
                            onChange={props.handleChange} placeholder={"Contracter Name"} value={props.params.contractorName} />
                    </div>
                    <div className="col-md-6 formInp mb-3">
                        <label>Property</label>
                        {/*<div className="selOtr">*/}
                        {/*<select className="form-control" name={"propertyId"}*/}
                        {/*onChange={handleChange} value={params.propertyId}>*/}
                        {/*<option>Select Property</option>*/}
                        {/*{props.commonReducer.Property && props.commonReducer.Property.propertyList ? props.commonReducer.Property.propertyList.map((property, key) => {*/}
                        {/*if (property._id) return <option*/}
                        {/*value={property._id}>{property.propertyName}</option>*/}
                        {/*}) : null}*/}
                        {/*</select>*/}
                        {/*</div>*/}
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={Properties}
                            value={props.params.propertyDetails}
                            loadOptions={props.promiseOptionsProperty}
                            name="propertyId"
                            onChange={props.handleChangeProperty}
                        />
                    </div>
                    <div className="col-md-6 formInp mb-3">
                        <label>Tenant Name</label>
                        {/*<div className="selOtr">*/}
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={tenantList}
                            value={props.params.requestByDetails}
                            loadOptions={props.promiseOptionsTenant}
                            name="requestById"
                            onChange={props.handleChangeTenant}
                        />
                        {/*</div>*/}
                    </div>
                </div>
                <div className="btnOtr text-right">
                    <button type="button" className="btn btn-primary btnClear" onClick={props.clearFilter}>Clear
                    </button>
                    <button type="button" className="btn btn-primary btnApply" onClick={handleFilter}>Apply
                    </button>
                </div>
            </div>
        </div>

    )
}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer } = state;
    return {
        commonReducer,
        tenantReducer
    };
}

export default connect(mapStateToProps, { ...commonAction, ...tenantAction })(
    MaintenanceFilter
);
