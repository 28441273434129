import React, { useEffect, useState } from "react";
import EditProfile from '../Profile/components/editProfile'
import ChangePassword from '../Profile/components/changePassword'
import Modal from '../Common/components/Modal';
import LoaderSpinner from "../Common/components/Loader";
import { connect } from "react-redux";
import action from "./action";

const Profile = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        async function renderCall() {
            await props.getProfile();
        }
        renderCall();
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }, []);

    const onOpenModal = () => {
        setModal(true);
    }

    const onCloseModal = () => {
        setModal(false);
    }

    const renderModal = () => {
        return <ChangePassword
            onCancel={onCloseModal}
            userRole={profileDetail.profileDetail && profileDetail.profileDetail.admin.role}
            profileDetail={profileDetail || {}}
        />
    }

    const { profileReducer: { profileDetail } } = props;

    return (
        // <div className="dtlSec">
        <>
            {isLoading && <LoaderSpinner />}
            {modal ? <Modal body={renderModal()} onCancel={onCloseModal} /> : null}
            <div className="tenants-otr">
                {
                    profileDetail && profileDetail.profileDetail &&
                    <EditProfile
                        onOpenModal={onOpenModal}
                        profileDetail={profileDetail.profileDetail}
                        userRole={profileDetail.profileDetail && profileDetail.profileDetail.admin.role}
                    />
                }
            </div>
            </>
        // </div>
    )
}

const mapStateToProps = state => {
    const { profileReducer } = state;
    return {
        profileReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(Profile);
