import React from "react";
import moment from "moment";

class ChatItem extends React.Component {

    render() {
        const { handleChatSelect, chatContents, chatReceiverId, deleteSelectedMessage, chatReceiverPhoneNo } = this.props;
        return (
            /*activeChat*/
            <div className={`emailSec ${(chatContents._tid ? chatReceiverId === chatContents._tid : chatReceiverPhoneNo === chatContents._id) ? 'activeChat' : ''} `}
                onClick={() => handleChatSelect(chatContents._tid, chatContents.name, chatContents._id)}>
                {/* <div className="checkboxPadding "><label className="invoiceCheckbox "
                    style={{ marginLeft: "9px" }}>
                    <input
                        type="checkbox"
                        name="isArchived"
                        // disabled={props.status}
                        checked={this.props.isChecked ? true : false}
                        onChange={(e) => this.props.handleCheck(e, chatContents)}
                    />
                    <span className="checkmark "></span>
                </label></div> */}
                <div className="contentOuter">
                    <label class="transactionCheckbox" ><input type="checkbox"
                        name="isArchived"
                        // disabled={props.status}
                        checked={this.props.isChecked ? true : false}
                        onChange={(e) => this.props.handleCheck(e, chatContents)} />
                        <span class="checkmark"></span>
                    </label>
                    <div className="userImage">
                        <i className="fas fa-user" />
                    </div>
                    <div className="person-name">
                        <h1>{chatContents.name || chatContents._id}</h1>
                        <p>{chatContents.message}</p>
                    </div>
                </div>
                <div className="date">
                    <span>{moment(chatContents.createDate).format('MMMM DD')}</span>
                    <i class="fas fa-trash pl-3" onClick={() => deleteSelectedMessage(chatContents)} ></i>
                </div>


            </div >
        )
    }
}

export default ChatItem;