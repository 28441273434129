import React, {useEffect, useState} from "react";
// import {ToastContainer, toast} from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toastr from '../../Common/components/Toaster'
import {connect} from "react-redux";
import tenantAction from '../../Tenants/action'
import action from "../action";
import * as validation from "../../Common/components/validation";
import usePrevious from "../../Common/components/prevProps";


const ChangePaaword = (props) => {
    const [password, setPassword] = useState({oldpassword: null, password: null, confirmPassword: null})
    const [isSubmitted, setIsSubmitted] = useState(false)

    const prevPasssword = usePrevious(props.password);
    const prevPassswordTenant = usePrevious(props.passwordTenant);

    // useEffect(() => {
    //     if (isSubmitted && !props.passwordTenant.success) {
    //         toastr.error(props.passwordTenant.message)
    //     } else if (isSubmitted && props.passwordTenant.success) {
    //         toastr.success(props.passwordTenant.message);
    //     }
    // }, [ props.passwordTenant]);

    useEffect(() => {
        if (isSubmitted && prevPasssword !== props.password && !props.password.success) {
            toastr.error(props.password.message)
        } else if (isSubmitted && prevPasssword !== props.password && props.password.success) {
            toastr.success("Password Updated Successfully!");
        }
    }, [prevPasssword, props.password]);

    useEffect(() => {
        if (isSubmitted && prevPassswordTenant !== props.passwordTenant && !props.passwordTenant.success) {
            toastr.error(props.passwordTenant.message)
        } else if (isSubmitted && prevPasssword !== props.passwordTenant && props.passwordTenant.success) {
            toastr.success(props.passwordTenant.message);
        }
    }, [prevPassswordTenant, props.passwordTenant]);


    const handleResetPassword = async () => {
        setIsSubmitted(true)
        let regex =/^(?=.*?[A-Z]).{5,}$/
        setIsSubmitted(true)
        if (password.password &&
            password.confirmPassword &&
            (password.password === password.confirmPassword) &&
            validation.UNMATCHED_PASSWORDS(password.confirmPassword, password.password) &&
            regex.test(password.password)) {
            if(props.userRole == 'tenant'){
                let tenant= props.profileDetail.profileDetail.admin &&props.profileDetail.profileDetail.admin.tenantId._id
                let params = {
                     id: tenant,
                    password:password.password
                }
                await props.createPassword(params)
                setPassword({
                    ...password,
                    password:"",
                    confirmPassword:"",
                    _id:""

                })
            }else if(password.oldpassword){
            await props.updatePassword(password)
                setPassword({
                    ...password,
                    password:"",
                    oldpassword:"",
                    confirmPassword:"",
                    _id:""

                })
            }

            props.onCancel()
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setPassword({
            ...password,
            [name]: value
        })
        // if (validation.MISSING_NUMBER_CHARACTER(value) != true) {
        //     debugger
        //     return <div className={"errorMsg"}>
        //         {validation.MISSING_NUMBER_CHARACTER(value)}
        //     </div>
        // } else if (validation.PASSWORD_LENGTH(value)) {
        //     debugger
        //     return <div className={"errorMsg"}>
        //         {validation.PASSWORD_LENGTH(value)}
        //     </div>
        // } else if (validation.MISSING_UPPERCASE_CHARACTER(value) != true) {
        //     return <div className={"errorMsg"}>
        //         {validation.MISSING_UPPERCASE_CHARACTER(value)}
        //     </div>
        // } else if (validation.MISSING_LOWERCASE_CHARACTER(value) != true) {
        //     return <div className={"errorMsg"}>
        //         {validation.MISSING_LOWERCASE_CHARACTER(value)}
        //     </div>
        // } else return null

    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        validation.NUMBER_ONLY(value, name) != true ? <div className={"errorMsg"}>
                            {validation.NUMBER_ONLY(value, name)}
                        </div> : null
                    );

                case "password match":
                    return (
                        validation.UNMATCHED_PASSWORDS(value, name) != true ? <div className={"errorMsg"}>
                            {validation.UNMATCHED_PASSWORDS(value, name)}
                        </div> : null
                    );
                case "password":
                    return (
                        validation.PASSWORD(value, name) != true ? <div className={"errorMsg"}>
                            {validation.PASSWORD(value, name)}
                        </div> : null
                    );
                // if (validation.MISSING_NUMBER_CHARACTER(value) != true) {
                //     return <div className={"errorMsg"}>
                //         {validation.MISSING_NUMBER_CHARACTER(value)}
                //     </div>
                // } else if (validation.PASSWORD_LENGTH(value)) {
                //     return <div className={"errorMsg"}>
                //         {validation.PASSWORD_LENGTH(value)}
                //     </div>
                // } else if (validation.MISSING_UPPERCASE_CHARACTER(value) != true) {
                //     return <div className={"errorMsg"}>
                //         {validation.MISSING_UPPERCASE_CHARACTER(value)}
                //     </div>
                // } else if (validation.MISSING_LOWERCASE_CHARACTER(value) != true) {
                //     return <div className={"errorMsg"}>
                //         {validation.MISSING_LOWERCASE_CHARACTER(value)}
                //     </div>
                // } else return null
                // validation.MISSING_NUMBER_CHARACTER(value) != true ?
                //     <div className={"errorMsg"}>
                //         {validation.MISSING_NUMBER_CHARACTER(value)}
                //     </div>
                //     : validation.PASSWORD_LENGTH(value) != true ? <div className={"errorMsg"}>
                //         {validation.PASSWORD_LENGTH(value)}
                //     </div>
                //     : validation.MISSING_UPPERCASE_CHARACTER(value) != true ? <div className={"errorMsg"}>
                //         {validation.MISSING_UPPERCASE_CHARACTER(value)}
                //     </div>
                //      : validation.MISSING_LOWERCASE_CHARACTER(value) != true ? <div className={"errorMsg"}>
                //         {validation.MISSING_LOWERCASE_CHARACTER(value)}
                //     </div> : null


            }
        } else return null;
    };

    const {profileDetail: {tenant}} = props.profileDetail

    return (
        <div className="modal  addTenant changePasswordModal" id="modalId" style={{display: "block"}}>
            {/* <ToastContainer/> */}
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Change Password
                        </h5>
                        <button
                            type="button"
                            className="close"
                            onClick={props.onCancel}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo invoice">
                            <div className="formSection">
                                <div className="row outrForm m-0 p-0">
                                    {props.userRole == 'tenant' ? null : <div className="col-md-12 formInp">
                                        <label>Old Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Old Password"
                                            name={"oldpassword"}
                                            value={password.oldpassword}
                                            onChange={handleChange}
                                        />
                                        {validate(password.oldpassword, "Old Password", "required")}
                                    </div>}
                                    <div className="col-md-12 formInp">
                                        <label>New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="New Password"
                                            value={password.password}
                                            name={"password"}
                                            onChange={handleChange}

                                        />
                                        {validate(password.password, "New Password", "required")}
                                        {password.password && validate(password.password, 'password', "password")}

                                    </div>

                                    <div className="col-md-12 formInp">
                                        <label>Confirm Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            name={"confirmPassword"}
                                            value={password.confirmPassword}
                                            onChange={handleChange}

                                        />
                                        {validate(password.confirmPassword, "Confirm Password", "required")}
                                        {password.confirmPassword && password.password && validate(password.confirmPassword, password.password, "password match")}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit" onClick={handleResetPassword}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

function mapStateToProps(state) {
    const {profileReducer,tenantReducer} = state;
    return {
        password: profileReducer.password,
        passwordTenant: tenantReducer.password

    };
}

export default connect(mapStateToProps, {...action, ...tenantAction})(ChangePaaword);
