import React, { useEffect, useState } from "react";
import action from "./action";
import { connect } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
import toastr from '../Common/components/Toaster'
import moment from 'moment'
import InvoiceFilter from './components/InvoiceFilter'
import AmountDetails from './components/AmountDetails';
import InvoiceListing from './components/InvoiceListing';
import history from '../../config/history';
import Modal from '../Common/components/Modal'
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../Common/components/Loader";
import usePrevious from "../Common/components/prevProps";
import commonAction from '../Common/action'
import Pagination from "../Common/components/pagination";
import tenantAction from '../Tenants/action'
import TenantInvoiceNew from "../Tenants/components/Invoice/TenantInvoiceNew";
import Confirm from '../Common/components/Confirm';
import dashboardAction from '../DashBoard/action'

const Invoice = (props) => {
    const [InvoiceModal, setInvoiceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCheckedAll, setCheckedAll] = useState(false)
    const [isButtonActive, setButtonActive] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [smsTenant, setSmsTenant] = useState(false);
    const [smsTenantArray, setSmsTenantArray] = useState(false);
    const [id, setId] = useState(false);
    const [deleteInvoices, setDeleteInvoices] = useState(false)
    const [params, setParams] = useState({
        limit: 15, offset: 0, invoiceTypeId: null, propertyId: null,
        propertyDetails: null, tenantDetails: null, sort: null, orderData: 0, order: "", status: 0
    })
    const [tenantIds, setTenantIds] = useState([])
    const [paidArray, setPaidArray] = useState([])
    const [invoiceTypeId, setinvoiceType] = useState(null);
    const [currentPage, setcurrentPage] = useState(1);
    const [editData, setEditData] = useState({
        tenantId: null,
        amount: null,
        startDate: null,
        dueDate: null,
        invoiceTypeId: null,
        paymentTypeId: null,
        isRecurring: false,
        status: 0,
        isEdit: false
    })
    const [paymentSubmit, setPaymentSubmit] = useState(false)

    const prevUser = usePrevious(props.Invoice);
    const prevSmsInvoice = usePrevious(props.smsInvoice)
    const prevSms = usePrevious(props.sms)

    //  const prevInvoiceList = usePrevious(props.InvoiceList.invoice);
    const prevPayment = usePrevious(props.invoicePayment);
    const prevMerge = usePrevious(props.mergeInvoiceDetails);
    const prevInvoiceDelete = usePrevious(props.invoiceDelete)
    const prevPaymentData = usePrevious(props.payment)

    const {
        match: {
            params: { section }
        }
    } = props;

    const prevLimit = usePrevious(props.commonReducer.limitData);

    useEffect(() => {
        props.getLimitData()
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        history.push('/Invoice/All')
        if (prevLimit != props.commonReducer.limitData) {
            const { limitData } = props.commonReducer
            if (limitData) {
                let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 3)
                let tempParams = params
                tempParams.limit = limitCount ? limitCount.count : 15
                setParams(tempParams)

                async function renderCall() {
                    await props.getInvoiceType()
                    await props.getInvoice(tempParams)
                    await props.getProperty()
                    await props.getAllUnits()
                    await props.getTenant({ limit: 15, isDeleted: false, isClosed: false })
                    await props.getPaymentDetails();
                }

                renderCall();
                setTimeout(() => {
                    setIsLoading(false)
                }, 300)
            }

        }


    }, [prevLimit, props.commonReducer.limitData]);

    useEffect(() => {
        if (paymentSubmit && prevPaymentData != props.payment && props.payment.success)
            toastr.success(props.payment.message)
        else if (paymentSubmit && prevPaymentData != props.payment && !props.payment.success)
            if (props.payment.data && props.payment.data.length && props.payment.data.length == 1) {
                let msg = `${props.payment.message} due to ${props.payment.data[0].message}`
                toastr.error(msg)
            }
            else {
                toastr.error(props.payment.message)
            }
    }, [prevPaymentData, props.payment])


    useEffect(() => {
        if (isSubmitted && prevUser !== props.Invoice && !props.Invoice.success) {
            toastr.error(props.Invoice.message)
        } else if (isSubmitted && prevUser !== props.Invoice && props.Invoice.success) {
            toastr.success(props.Invoice.message);
        }
    }, [prevUser, props.Invoice]);

    useEffect(() => {
        if (deleteInvoices && prevInvoiceDelete != props.invoiceDelete && !props.invoiceDelete.success) {
            toastr.error(props.invoiceDelete.message)
        } else if (deleteInvoices && prevInvoiceDelete != props.invoiceDelete && props.invoiceDelete.success) {
            toastr.success("Successfully deleted the invoice");
        }
    }, [deleteInvoices, props.invoiceDelete]);

    //show message on sending sms for invoice list
    useEffect(() => {
        if (smsTenantArray && prevSms != props.sms) {
            let messageSuccess = null
            let messageFailure = null
            let temp = [];
            let failures = null
            if (props.sms.message && Array.isArray(props.sms.message)) {
                messageSuccess = props.sms.message && props.sms.message.filter((e) => e.status === 'success');
                messageFailure = props.sms.message && props.sms.message.filter((e) => e.status === 'failed');
                failures = messageFailure && messageFailure.length && messageFailure.map((e) => {
                    temp.push(e.receiverNumber)
                })
            }

            if (smsTenantArray && prevSms != props.sms && !props.sms.success) {
                toastr.error(props.sms.message)
            } else if (smsTenantArray && prevSms != props.sms && props.sms.success) {
                if (props.sms.message && Array.isArray(props.sms.message)) {
                    if (messageSuccess.length) {
                        toastr.success("Message sent successfully")
                    }
                    if (messageFailure.length) {
                        toastr.error(`Message sending failed for number: ${temp} `)
                    }
                }

            }
        }
    }, [prevSms, props.sms, smsTenantArray])

    //send payment sms for in invoice edit page on sendig sms
    useEffect(() => {
        if (smsTenant && prevSmsInvoice != props.smsInvoice && !props.smsInvoice.success) {
            toastr.error(props.smsInvoice.message)
        } else if (smsTenant && prevSmsInvoice != props.smsInvoice && props.smsInvoice.success) {
            if (props.smsInvoice.message.status === 'success') {
                toastr.success("Message sent successfully")
            } else {
                toastr.error(`Message sending failed`)
            }
        }
    }, [prevSmsInvoice, props.smsInvoice, smsTenant])

    useEffect(() => {
        if (isSubmitted && prevPayment !== props.invoicePayment && !props.invoicePayment.success) {
            toastr.error(props.invoicePayment.message)
        } else if (isSubmitted && prevPayment !== props.invoicePayment && props.invoicePayment.success) {
            toastr.success(props.invoicePayment.message);
        }
    }, [prevPayment, props.invoicePayment]);

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        data.limit = params.limit
        setParams(data)
        setcurrentPage(page)
        await props.getInvoice(data);
    }

    const handleTabs = async (tab, id) => {

        let sectionTemp = tab.replace(/ +/g, "")

        switch (sectionTemp) {
            case 'All':
                history.push('/Invoice/All');
                break;
            case 'Private':
                history.push('/Invoice/Private');
                break;
            case 'Trillium':
                history.push('/Invoice/Trillium');
                break;
            case 'Section8':
                history.push('/Invoice/Section8');
                break;
            case 'BenefitsManagement':
                history.push('/Invoice/BenefitsManagement');
                break;
            case 'SecurityDeposit':
                history.push('/Invoice/SecurityDeposit');
                break;
        }

        let paramsTemp = { ...params };
        paramsTemp.invoiceTypeId = id;
        paramsTemp.offset = 0;
        paramsTemp.limit = params.limit;
        window.scroll(0, 0)
        setinvoiceType(id);
        setParams(paramsTemp)
        await props.getInvoice(paramsTemp)
    }

    const addInvoiceDetails = async (invoiceForm) => {
        setIsSubmitted(true)
        if (editData.isEdit) {
            await props.updateInvoice([invoiceForm]);
        } else {
            await props.addInvoice([invoiceForm]);
        }
        setEditData({
            tenantId: null,
            amount: null,
            startDate: null,
            dueDate: null,
            invoiceTypeId: null,
            paymentTypeId: null,
            isRecurring: false,
            status: null,
            isEdit: false,
        })
        setInvoiceModal(false)
        await props.getInvoice(params)
        setIsSubmitted(false)
    }

    const openModal = () => {
        setEditData({
            isEdit: false,
        })
        setInvoiceModal(true)
    }

    const closeModal = () => {
        setInvoiceModal(false)
        setEditData({
            tenantId: null,
            amount: null,
            startDate: null,
            dueDate: null,
            invoiceTypeId: null,
            paymentTypeId: null,
            isRecurring: false,
            status: 0,
            isEdit: false
        })
    }

    const attemptCapture = async () => {
        setIsSubmitted(true);
        await props.payInvoice(editData.paymentData);
        setInvoiceModal(false);
        await props.getInvoice(params);
        setIsLoading(false);
        setIsSubmitted(false);
    }

    const renderModal = () => {
        return (!editData.id)
            ? <TenantInvoiceNew
                editData={editData}
                addInvoiceDetails={addInvoiceDetails}
                onCancel={closeModal}
                attemptCapture={attemptCapture}
                sendPaymentSms={sendPaymentSms}
                tempParams={params}

            />
            : <TenantInvoiceNew
                editData={editData}
                addInvoiceDetails={addInvoiceDetails}
                onCancel={closeModal}
                attemptCapture={attemptCapture}
                sendPaymentSms={sendPaymentSms}
                tempParams={params}

            />
    }

    const sendPaymentSms = async (id, no, editData) => {

        if (editData.cardDetails && editData.cardDetails.length) {
            let isDefaultCard = editData.cardDetails.find(tc => tc.defaultCard).cardNumber
            if (isDefaultCard) {
                setSmsTenant(true);
                await props.sendSmsEachInvoice({ receiverId: id, invoiceNumber: no })
                setTimeout(() => setSmsTenant(false), 500)
            }
        }
        else {
            toastr.error("Please add a valid card for the selected invoice ")
        }
    }

    const handleChange = async (e) => {
        const { name, value } = e.target
        await props.getTenant({ [name]: value, isDeleted: false, isClosed: false })

        setParams({
            ...params,
            tenantDetails: null,
            [name]: value
        })
    }

    const handleChangeStatus = async (e) => {
        setIsLoading(true)

        const { name, value } = e.target

        let tempParams = { ...params }
        if (value) {
            tempParams.status = parseInt(value)
            setParams(tempParams)
            await props.getInvoice(tempParams)
        }
        else {
            delete tempParams.status
            setParams(tempParams)
            await props.getInvoice(tempParams)
        }
        setIsLoading(false)

    }

    const handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = { ...params }
        tempParams.limit = value
        tempParams.offset = 0
        await props.updateLimitData({ type: 3, count: value });
        await props.getInvoice(tempParams)
        setParams({
            ...params,
            limit: tempParams.limit,
            tenantDetails: null,
            [name]: value
        })
    }

    // useEffect(() => {
    //     console.log("props.mergeInvoice", props.mergeInvoiceDetails)
    //
    //     if (isSubmitted && prevMerge !== props.mergeInvoiceDetails && !props.mergeInvoiceDetails.success) {
    //         console.log("props.mergeInvoice", props.mergeInvoiceDetails)
    //         toastr.error("Failed to Merge!")
    //     } else if (isSubmitted && prevMerge !== props.mergeInvoiceDetails && props.mergeInvoiceDetails.success) {
    //         toastr.success('Merged Successfully!');
    //     }
    // }, [prevUser, props.mergeInvoiceDetails]);

    const handleChangeTenant = async (value) => {
        const { tenantReducer: { tenants } } = props;
        let tenentDetail = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.find((t) => t._id === value.value)
        await props.getUnitsByPropertyId(tenentDetail.propertyId._id)
        setParams({
            ...params,
            tenantDetails: value,
            propertyId: tenentDetail.propertyId._id,
            propertyDetails: { value: tenentDetail.propertyId._id, label: tenentDetail.propertyId.propertyName },
            unitId: tenentDetail.unitId._id,
            tenantId: value.value
        })
    }

    const handleChangeProperty = async (value) => {
        await props.getUnitsByPropertyId(value.value)
        setParams({
            ...params,
            propertyId: value.value,
            propertyDetails: value
        })
    }

    const filterData = async (value) => {
        let tempParams = params
        if (value) {
            tempParams.sort = value
            let orderDetails = !params.orderData
            tempParams.order = orderDetails ? "ASC" : "DESC"
            tempParams.orderData = !params.orderData
            tempParams.invoiceTypeId = invoiceTypeId
        }
        setParams(tempParams)
        setIsLoading(true)
        await props.getInvoice(tempParams)
        setTimeout(() => {
            setIsLoading(false)

        }, 1000)
    }

    const editInvoice = async (invoice) => {
        const { invoiceList } = props.InvoiceList;

        let editDataTemp = {
            id: invoice._id,
            invoiceNumber: invoice.invoiceNumber,
            lastCaptureAttempt: invoice.attemptToCaptureDate,
            balance: invoiceList && invoiceList.data.balance,
            status: invoice.status,
            startDate:invoice.startDate ? moment((invoice.startDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null,
            dueDate:invoice.dueDate ? moment((invoice.dueDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null, 
            tenantId: invoice.tenantId && invoice.tenantId._id,
            tenantName: invoice.tenantId ? (invoice.tenantId.firstName + ' ' + invoice.tenantId.lastName) : '',
            tenantAddress: invoice.tenantId && invoice.tenantId.propertyId.propertyName,
            isRecurring: invoice.isRecurring,
            amount: invoice.amount,
            invoiceTypeId: invoice.invoiceTypeId._id,
            invoiceTypeName: invoice.invoiceTypeId.name,
            paymentTypeId: invoice.paymentTypeId._id,
            paymentTypeName: invoice.paymentTypeId.name,
            description: invoice.description || invoice.title,
            paidAmount: invoiceList && invoiceList.data.paidAmount,
            totalDue: invoiceList && invoiceList.data.totalDue,
            leaseStartDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.startDate,
            leaseEndDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.endDate,
            tenantDetails: {
                value: invoice.tenantId._id,
                firstName: invoice.tenantId.firstName,
                lastName: invoice.tenantId.lastName
            },
            invoices: invoice.charges,
            isEdit: true,
            transactionId: invoice.transactionId,
            cardDetails: invoice.tenantId && invoice.tenantId.cardDetails,
            paymentData: {
                invoiceId: invoice._id,
                businessID: invoice.tenantId && invoice.tenantId.propertyId && invoice.tenantId.propertyId.paymentId,
                tenantID: invoice.tenantId._id,
                customerPaymentProfileId: invoice.tenantId && invoice.tenantId.customerPaymentProfileId,
                customerProfileId: invoice.tenantId && invoice.tenantId.customerProfileId,
                amount: invoice.amount,
            }
        }
        setEditData(editDataTemp)
        setInvoiceModal(true)
    }

    const viewInvoice = (invoice) => {
        let viewData = {
            id: invoice._id,
            tenantId: invoice.tenantId._id,
            amount: invoice.amount,
            startDate: moment(invoice.startDate).format('MM/DD/YYYY'),
            dueDate: moment(invoice.dueDate).format('MM/DD/YYYY'),
            invoiceTypeId: invoice.invoiceTypeId && invoice.invoiceTypeId._id,
            paymentTypeId: invoice.paymentTypeId && invoice.paymentTypeId._id,
            isRecurring: invoice.isRecurring,
            cardDetails: invoice.tenantId && invoice.tenantId.cardDetails,
            tenantDetails: {
                value: invoice.tenantId._id,
                label: invoice.tenantId.firstName + " " + invoice.tenantId.lastName
            },
            description: invoice.description,
            status: invoice.status,
        }
        setEditData(viewData)
        setInvoiceModal(true)
    }

    const clearFilter = async () => {
        let tempParams = { offset: 0, limit: 15 }
        setParams({
            invoiceTypeId: null,
            propertyId: null,
            propertyDetails: null,
            tenantDetails: null,
            unit: " ",
            offset: 0,
            limit: 15
        })
        setIsLoading(true)
        await props.getInvoice(tempParams)
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    const handleCheck = (e, invoice) => {

        let tempArray = paidArray;
        let tenantArray = tenantIds;
        if (e.target.checked) {
            //set tenants and invoice id for sms
            let tenant = {
                id: invoice._id,
                tenantId: invoice.tenantId._id,
                invoiceNumber: invoice.invoiceNumber,
                cardDetails: invoice.tenantId && invoice.tenantId.cardDetails
            }
            tenantArray.push(tenant);
            setTenantIds(tenantArray);

            //set invoices for delete


            //set unpaid invoices id for delete and mark as paid
            setButtonActive(true)
            let data = {
                id: invoice._id,
                status: invoice.status,
                tenantId: invoice.tenantId ? invoice.tenantId._id : null,
                cardDetails: invoice.tenantId && invoice.tenantId.cardDetails
            }
            tempArray.push(data);
            return setPaidArray(tempArray)
        } else {
            setCheckedAll(false)
            tempArray = tempArray.filter(t => t.id !== invoice.id)
            tenantArray = tenantArray.filter(t => t.id !== invoice.id)
            if (!tempArray.length) setButtonActive(false);
            setTenantIds(tenantArray);
            return setPaidArray(tempArray)
        }
    }

    const handleCheckAll = (e) => {
        let tempArray = []
        if (e.target.checked) {
            setButtonActive(true)
            setCheckedAll(true)
            return props.InvoiceList && props.InvoiceList.invoice && props.InvoiceList.invoice.length ?
                props.InvoiceList.invoice.map((invoice, key) => {
                    // if (!invoice.status) {
                    let data = {
                        id: invoice._id,
                        tenantId: invoice.tenantId ? invoice.tenantId._id : null,
                        status: invoice.status,
                        invoiceNumber: invoice.invoiceNumber,
                        cardDetails: invoice.tenantId && invoice.tenantId.cardDetails
                    }
                    tempArray.push(data)
                    setTenantIds(tempArray);
                    setPaidArray(tempArray)
                    // }
                })
                : null
        } else {
            setPaidArray([])
            setButtonActive(false)
            setCheckedAll(false)
        }
    }

    const handleUpdate = async () => {
        let isPaid = null
        setIsLoading(true)

        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 1)
        }
        if (isPaid) {
            toastr.error("Please choose unpaid to continue!!")
        } else {
            if (paidArray && paidArray.length) {
                let tempArrayPay = []
                paidArray.map(pa => {
                    let tempData = {
                        id: pa.id,
                        tenantId: pa.tenantId ? pa.tenantId : null,
                        status: 1,
                        attemptToCaptureDate: moment(new Date()).toISOString()
                    }
                    tempArrayPay.push(tempData)
                })
                setIsSubmitted(true)
                await props.updateInvoice(tempArrayPay);
                setIsSubmitted(false)
            } else {
                setIsSubmitted(true)
                await props.updateInvoice(paidArray);
                setIsSubmitted(false)
            }
            // await props.payInvoice(paidArray)
            setPaidArray([])
            setCheckedAll(false)
            setButtonActive(false)
            await props.getInvoice(params)
            setIsSubmitted(false)
            setIsLoading(false)

            history.push('/Invoice/All')
        }
        setIsLoading(false)


    }

    const handleSendSms = async () => {
        let isPaid = null
        let isCardDetails = null
        setIsLoading(true)

        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 1)
            isCardDetails = paidArray.find(pa => pa.cardDetails && !pa.cardDetails.length)

        }


        if (isPaid) {
            toastr.error("Please choose unpaid to continue!!")
        } else {
            if (!isCardDetails) {
                setSmsTenantArray(true);
                let result = []
                tenantIds.map(a => result.push({ receiverId: a.tenantId, invoiceNumber: a.invoiceNumber }))
                await props.sendSms(result);
                setCheckedAll(false)
                setButtonActive(false)
                setTimeout(() => setSmsTenantArray(false), 500)
                setPaidArray([])
                setCheckedAll(false)
                setButtonActive(false)
                history.push('/Invoice/All')
            }
            else {
                toastr.error("Please add a valid card for the selected invoice")
            }

        }
        setIsLoading(false)
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
        setPaidArray([])
        setCheckedAll(false)
        setIsSubmitted(false)
        setIsLoading(false)

        window.location.reload()
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Invoice'
            message='Do you want to delete this invoice?'
            onCancel={closeDeleteModal}
            onConfirm={id ? deleteInvoice : deleteCheckedInvoice}
        />
    }

    const handleDelete = async (id) => {
        setId(id)
        setIsSubmitted(true)
        setDeleteModal(true)
    }

    const handleDeleteChecked = async () => {
        setIsSubmitted(false)
        setDeleteModal(true)
    }

    const deleteCheckedInvoice = async () => {
        setIsSubmitted(false)
        setDeleteInvoices(true);
        await props.deleteInvoiceDetails(paidArray)
        window.location.reload()
        setPaidArray([])
        setCheckedAll(false)
        await props.getInvoice(params)
        setDeleteModal(false)
        // setIsSubmitted(false)

    }

    const deleteInvoice = async () => {
        setDeleteInvoices(true);
        setDeleteModal(false)
        setIsSubmitted(true)
        await props.deleteInvoiceDetails([{ id: id, status: 1 }]);
        setPaidArray([])
        setCheckedAll(false)
        setButtonActive(false)
        await props.getInvoice(params)
        setIsSubmitted(false)
        setDeleteInvoices(false);
    }

    const handleAttemptCapture = async () => {
        setIsLoading(true)

        if (paidArray && paidArray.length) {
            let isPaid = paidArray.find(pa => pa.status == 1)
            if (isPaid) {
                toastr.error("Please choose unpaid to continue!!")
            } else {
                setPaymentSubmit(true)
                setIsLoading(true)
                let tempArray = []
                paidArray.map(pa => tempArray.push(pa.id))
                await props.makeMultiplePayment({ invoiceId: tempArray })
                await props.getInvoice(params);
                setPaymentSubmit(false)
                setPaidArray([])
                setIsLoading(false)
                setCheckedAll(false)
                setButtonActive(false)
            }
        }
        setIsLoading(false)

    }

    const handleMerge = async () => {
        let error = false
        let mergedArray = []
        let isPaid = null
        setIsLoading(true)
        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 1)
        }
        if (isPaid) {
            toastr.error("Please choose unpaid to continue!!")
        } else {
            if (paidArray.length > 1) {
                paidArray.map((pa, key) => {
                    let invoiceList = props.InvoiceList.invoice.length && props.InvoiceList.invoice.find((invoice) => invoice._id === pa.id)
                    return invoiceList ? mergedArray[key - 1] ? (mergedArray[key - 1].tenantId._id === pa.tenantId) ?
                        mergedArray.push(invoiceList)
                        : error = true
                        : mergedArray.push(invoiceList) :
                        null
                })
                if (error) {
                    setPaidArray([])
                    setCheckedAll(false)
                    toastr.error("Tickets should belongs to same tenant!!!")
                    window.location.reload()
                    mergedArray = []
                    error = false
                } else {
                    if (mergedArray.length) {
                        let chargeArray = []
                        let paymentId = props.commonReducer.paymentMethods.find(pm => pm.name === "Credit Card")
                        let invoiceTypeId = props.commonReducer.InvoiceTypeList.find(it => it.name === "Private")
                        mergedArray.map(ma => {
                            return ma.charges.map((ch) => chargeArray.push({
                                id: ma._id,
                                amount: ch.amount,
                                description: ch.description ? ch.description : ma.invoiceTypeId.name + '-' +
                                    (ma.tenantId.tenantAddress || ma.tenantId.propertyId.propertyName + '-' + '(' +
                                        moment(ma.startDate).format('MM/DD/YYYY') + '-' + moment(ma.dueDate).format('MM/DD/YYYY') + ')')
                            }))
                        })
                        let tempData = {
                            description: mergedArray[0].charges[0].description ? mergedArray[0].charges[0].description : mergedArray[0].invoiceTypeId.name + '-' +
                                (mergedArray[0].tenantId.tenantAddress || mergedArray[0].tenantId.propertyId.propertyName + '-' + '(' +
                                    mergedArray[0].startDate + '-' + moment(mergedArray[0].dueDate).format('MM/DD/YYYY') + ')'),
                            invoiceTypeId: invoiceTypeId._id,
                            title: mergedArray[0].title,
                            paymentTypeId: paymentId._id,
                            tenantId: mergedArray[0].tenantId._id,
                            startDate:moment(new Date()).format('MM/DD/YYYY'),
                            dueDate: moment(mergedArray[0].dueDate).format('MM/DD/YYYY'),
                            charges: chargeArray
                        }
                        await props.mergeInvoice(tempData)
                        window.location.reload()
                        history.push('/Invoice/All')
                        setPaidArray([])
                        setCheckedAll(false)
                        mergedArray = []
                        setButtonActive(false)
                        await props.getInvoice(params)
                    }
                }
            } else {
                toastr.error("Select at least 2 tickets!!")
            }
        }
        setIsLoading(false)



    }


    const handleUnpaid = async () => {
        let isPaid = null
        setIsLoading(true)

        if (paidArray && paidArray.length) {
            isPaid = paidArray.find(pa => pa.status == 0)
        }
        if (isPaid) {
            toastr.error("Please choose paid to continue!!")
        } else {
            if (paidArray && paidArray.length) {
                let tempArrayPay = []
                paidArray.map(pa => {
                    let tempData = {
                        id: pa.id,
                        tenantId: pa.tenantId ? pa.tenantId : null,
                        status: 0,

                    }
                    tempArrayPay.push(tempData)
                })
                setIsSubmitted(true)
                await props.updateInvoice(tempArrayPay);
                setIsSubmitted(false)
            } else {
                setIsSubmitted(true)
                await props.updateInvoice(paidArray);
                setIsSubmitted(false)
            }
            // await props.payInvoice(paidArray)
            setPaidArray([])
            setCheckedAll(false)
            setButtonActive(false)
            await props.getInvoice(params)
            setIsSubmitted(false)
            setIsLoading(false)

            history.push('/Invoice/All')
        }
        setIsLoading(false)

    }

    return (
        <div className="tenants-otr">
            {(isSubmitted || isLoading || props.sms.loading) && <LoaderSpinner />}
            {InvoiceModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            <div className="tenants-inner">
                <InvoiceFilter Property={props.commonReducer.Property}
                    handleChange={handleChange}
                    unit={props.commonReducer.unitDetails}
                    params={params}
                    tenants={props.tenantReducer}
                    clearFilter={clearFilter}
                    handleChangeTenant={handleChangeTenant}
                    handleChangeProperty={handleChangeProperty}
                    filterData={filterData} />

                <AmountDetails openModal={openModal} invoiceList={props.InvoiceList} />
                <section className="tenant-tabs">
                    <div className="row p-0 m-0">
                        <div className="col-md-12 tab-shadow">
                            <nav>
                                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                    <a
                                        className={section === 'All' ? "nav-item nav-link active" : "nav-item nav-link"}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleTabs('All')}
                                        aria-selected="true">All</a>
                                    {props.commonReducer.InvoiceTypeList.length ? props.commonReducer.InvoiceTypeList.map((type, key) => {

                                        return <> {type.name != "Security Deposit" ? <a
                                            className={section === (type.name.replace(/ +/g, "")) ? "nav-item nav-link active" : "nav-item nav-link"}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleTabs(type.name, type._id)}
                                            aria-selected="true" key={key}>{type.name}</a> : null}</>
                                    }) : null}
                                </div>
                            </nav>
                        </div>
                        <div className="col-md-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-private" role="tabpanel"
                                    aria-labelledby="nav-private-tab">
                                    <div className="innago-table table-main invoiceTable">
                                        <InvoiceListing
                                            InvoiceList={props.InvoiceList}
                                            editInvoice={editInvoice}
                                            handleUpdate={handleUpdate}
                                            handleCheck={handleCheck}
                                            handleCheckAll={handleCheckAll}
                                            offset={params.offset}
                                            paidArray={paidArray}
                                            countArray={paidArray.length}
                                            viewInvoice={viewInvoice}
                                            isCheckedAll={isCheckedAll}
                                            isButtonActive={isButtonActive}
                                            filterData={filterData}
                                            handleDeleteChecked={handleDeleteChecked}
                                            handleDelete={handleDelete}
                                            tenantIds={tenantIds}
                                            handleSendSms={handleSendSms}
                                            limit={params.limit}
                                            status={params.status}
                                            handleChange={handleChangeLimit}
                                            handleMerge={handleMerge}
                                            handleAttemptCapture={handleAttemptCapture}
                                            handleUnpaid={handleUnpaid}
                                            handleChangeStatus={handleChangeStatus}
                                            count={props.InvoiceList.invoiceCount}
                                        />
                                        <div className="paginationSec">
                                            {props.InvoiceList.invoiceCount ? <Pagination
                                                totalRecords={props.InvoiceList.invoiceCount}
                                                pageLimit={params.limit}
                                                pageNeighbours={1}
                                                currentPage={currentPage}
                                                onPageChanged={onPageChanged}
                                            /> : null}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { invoiceReducer, commonReducer, tenantReducer, dashboardReducer } = state;
    return {
        Invoice: invoiceReducer.Invoice,
        InvoiceList: invoiceReducer.InvoiceList,
        invoiceDelete: invoiceReducer.invoiceDelete,
        sms: invoiceReducer.sms,
        smsInvoice: invoiceReducer.smsInvoice,
        commonReducer,
        tenantReducer,
        invoicePayment: invoiceReducer.invoicePayment,
        mergeInvoiceDetails: invoiceReducer.mergeInvoice,
        payment: dashboardReducer.payment
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction, ...tenantAction, ...dashboardAction })(Invoice);
