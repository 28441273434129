import React from "react";
import Property from './components/propertyDetails';
// import Tenants from './components/'
import Messages from './components/messages';
import PaidInvoice from './components/paidInvoice';
import UnpaidInvoice from './components/unpaidInvoice'
import { connect } from 'react-redux'
import action from '../action'
import Maintenance from "./components/maintenance";
import AutoPay from './components/autoPay';
import AllInvoices from './components/allIncoices';
import Lease from './components/lease';
import invoiceAction from "../../Invoice/action";
import LoaderSpinner from "../../Common/components/Loader"

class index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultPositions: [
                {
                    "_id": "5e1ea2e638ce592f7cff2531",
                    "title": "property",
                    "orderNo": 1
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252f",
                    "title": "maintenance",
                    "orderNo": 2
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252c",
                    "title": "message",
                    "orderNo": 3
                },
                {
                    "_id": "5e1ea2e638ce592f7cff252d",
                    "title": "paidInvoice",
                    "orderNo": 4
                },

                {
                    "_id": "5e1ea2e638ce592f7cff252b",
                    "title": "unPaidInvoice",
                    "orderNo": 5
                },

            ],
            cardPositions: [],
            isLoder:true
        }
    }

    async componentDidMount() {
        await this.props.getTenantInvoices({ limit: 10, offset: 0, invoiceTypeId: null, sort: null, status: 0 })
        await this.props.getCardPositions();
        const { dashboardReducer: { dashboardCardList } } = this.props;
        if (dashboardCardList.data && dashboardCardList.data.dashboardList && dashboardCardList.data.dashboardList.length) {
            const cardPosition = dashboardCardList.data.dashboardList[0].orderList;
            this.setState({ cardPositions: cardPosition.length ? cardPosition : this.state.defaultPositions })
        }
        this.setState({
            isLoder:false
        })
    }

    // sort cards wrt order
    sortCards = (cards) => {
        if (!cards)
            return []
        else
            return cards.sort((a, b) => (a.orderNo > b.orderNo) ? 1 : ((b.orderNo > a.orderNo) ? -1 : 0))
    }
    // render cards wrt item title
    renderCard = (item, index) => {
        switch (item.title) {
            case 'property':
                return <Property key={index} idx={index} draggable="true" onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'maintenance':
                return <Maintenance key={index} idx={index} draggable="true" onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'message':
                return <Messages key={index} idx={index} draggable="true" onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'paidInvoice':
                return <PaidInvoice key={index} idx={index} draggable="true" onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver} onDrop={this.handleDrop} />
            case 'unPaidInvoice':
                return <UnpaidInvoice key={index} idx={index} draggable="true" onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver} onDrop={this.handleDrop} />

            default:
                return null
                {/*<Lease key={index} idx={index} draggable="true" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} />*/
                }
        }
    }


    handleDragStart = () => event => {
        event.dataTransfer.setData("application/my-app", event.target.id);
        event.dataTransfer.dropEffect = "move";
    };
    handleDragOver = () => event => {
        event.preventDefault(); // Necessary. Allows us to drop.
    };
    handleDrop = () => event => {
        event.preventDefault();
        const { dashboardReducer: { dashboardCardList } } = this.props;
        const incomingElement = event.dataTransfer.getData("application/my-app");
        const targetElement = event.target.closest('.drpbl').id;

        if (incomingElement != targetElement) {
            let tempState = this.state.cardPositions;
            let inc = tempState[incomingElement]
            // Re-ordering elements
            let x = [];
            tempState.map((card, idx) => {
                if (incomingElement >= targetElement) {
                    if (idx == targetElement) {
                        x.push(inc)
                    }
                    if (idx != incomingElement) {
                        x.push(card)
                    }
                } else {
                    if (idx != incomingElement) {
                        x.push(card)
                    }
                    if (idx == targetElement) {
                        x.push(inc)
                    }
                }
            })
            // setting data in state
            this.setState({
                cardPositions: x.map((c, i) => {
                    return { ...c, orderNo: i }
                })
            }, () => {
                if (dashboardCardList.data && dashboardCardList.data.dashboardList && dashboardCardList.data.dashboardList.length) {
                    const params = {
                        orderList: this.state.cardPositions,
                        id: dashboardCardList.data.dashboardList[0]._id
                    }
                    this.props.updateCardPositions(params)
                }
            })

        }
    };

    render() {
        return (
            <div className="tenants-otr">
                {this.state.isLoder?<LoaderSpinner/>:null}
                <div className="innago-table tenant-dashboard-new">
                    <div className="row">
                        {/*{*/}
                        {/*this.sortCards(this.state.cardPositions).map((item, index) => this.renderCard(item, index))*/}
                        {/*}*/}
                        <div className="col-md-8">
                            <Lease />
                            <AutoPay />
                            <UnpaidInvoice />
                            <AllInvoices />
                        </div>
                        <Maintenance />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { dashboardReducer } = state;
    return { dashboardReducer }
}
export default connect(mapStateToProps, { ...action, ...invoiceAction })(index);
