import {
  innagoGateWay,
  innagoAuthenticator
} from "../../services/authorizationService";

import serviceEndpoints from "../../config/serviceEndpoints";

export const SignIn = payload =>
  innagoAuthenticator.post(serviceEndpoints.userEndpoints.login, payload);

export const passwordSet = data =>
    innagoAuthenticator.post(serviceEndpoints.userEndpoints.password, data);

export const validateToken = params =>
    innagoAuthenticator.get(serviceEndpoints.userEndpoints.validateToken, {params});

export const forgotPassword = data =>
    innagoAuthenticator.post(serviceEndpoints.userEndpoints.forgotPassword, data);
