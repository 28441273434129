import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import action from "../../action";
import moment from "moment";
import renderHTML from "react-render-html";
import Pagination from "../../../Common/components/pagination";
import LoaderSpinner from "../../../Common/components/Loader";

class ClientLog extends React.Component {

    state = {
        params: {
            limit: 5,
            offset: 0,
            id: null
        },
        currentPage: 1,
        loader: true
    }


    componentDidMount = async () => {
        let tempParams = this.state.params

        tempParams.id = this.props.maintenanceId
        this.setState({
            id: this.props.maintenanceId,
        })
        await this.props.viewMaintenanceById(tempParams);
        this.setState({
            loader: false
        })
    }


    onPageChanged = async page => {
        let data = this.state.params
        let offset = ((page - 1) * this.state.limit) || 0;
        data.offset = offset;
        this.setState({
            params: data,
            currentPage: page
        })
        await this.props.viewMaintenanceById(data);
    }


    render() {
        return (
            <div className="support-otr">
                {this.state.loader ? <LoaderSpinner/> : null}
                <div className="innago-table table-main p-0 mb-3 clientlogTable">
                    <div className="table-responsive">
                        <table className="table-gap table-td">
                            <tbody>
                            <tr className="table-head">
                                <th>Date Submitted</th>
                                <th>Details</th>
                                <th>Username</th>
                                {/*<th>IP Address</th>*/}
                            </tr>
                            {this.props.viewMaintenance.data && this.props.viewMaintenance.data.messages && this.props.viewMaintenance.data.messages.length ?
                                this.props.viewMaintenance.data.messages.map((maintenance, key) => {
                                    return <tr>
                                        <td>
                                            {moment(maintenance.createdAt).format('MMMM Do YYYY')}
                                            <span
                                                className="ml-2"> {moment(maintenance.createdAt).format('h:mm A')}</span>
                                        </td>
                                        <td>
                                            {maintenance.message }
                                        </td>
                                        <td>
                                            {maintenance.from}
                                        </td>
                                        {/*<td>103.79.221.146</td>*/}
                                    </tr>
                                }) : <tr>
                                    <td colSpan={5} className="text-center no_value_table"><i
                                        className="far fa-thumbs-down"></i> No Data Found!!!
                                    </td>
                                </tr>}


                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="paginationSec">
                    <ul className="pagination">
                        {this.props.viewMaintenance.data && this.props.viewMaintenance.data.messageListCount ?
                            <Pagination
                                totalRecords={this.props.viewMaintenance.data && this.props.viewMaintenance.data.messageListCount}
                                pageLimit={this.state.params.limit}
                                pageNeighbours={1}
                                currentPage={this.state.currentPage}
                                onPageChanged={this.onPageChanged}
                            /> : null}
                    </ul>
                </div>
            </div>
        )


    }

}

const mapStateToProps = state => {
    const {maintenanceReducer} = state;
    return {
        maintenanceReducer,
        viewMaintenance: maintenanceReducer.viewMaintenance,


    };
};

export default connect(mapStateToProps, {
    ...action
})(ClientLog);
