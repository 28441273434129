import React from 'react'
import NumberFormat from 'react-number-format';

const PropertyUnit = (props) => {
    const { unitData, handleEditUnits, idx, removeUnit, unitValidations } = props;
 
    return (
      
        <div key={idx} className="clientInfo invoice">
            <h3 className="unitDtl">
                <span>Unit Details</span>
                {/* {
               idx != 0   
               // unitData.isNewUnit
                    ? <span className="close-icn" onClick={() => removeUnit(idx,unitData)}>&times;</span>
                    : null
                } */}
                <span className="close-icn" onClick={() => removeUnit(idx,unitData)}>&times;</span>
            </h3>
            <div className="formSection">
                <div className="row outrForm m-0 p-0">
                    <div className="col-md-5 formInp unitSectn">
                        <label>Unit Name</label>
                        <input
                            type="text"
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].unitName ? 'errorborder' : ''}`}
                            placeholder="Enter Unit Name"
                            name="unitName"
                            maxLength="50"
                            value={unitData.unitName}
                            onChange={e => handleEditUnits(idx, 'unitName', e)}
                        />
                    </div>
                    <div className="col-md-2 formInp bdroom">
                        <label>Bedrooms</label>
                        <input
                            type="text"
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].bedRoom ? 'errorborder' : ''}`}
                            placeholder="0"
                            maxLength="4"
                            name="bedRoom"
                            value={unitData.bedRoom}
                            onChange={e => handleEditUnits(idx, 'bedRoom', e)}
                        />
                    </div>
                    <div className="col-md-2 formInp bdroom">
                        <label>Bathrooms</label>
                        <input
                            type="text"
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].bathroom ? 'errorborder' : ''}`}
                            placeholder="0"
                            maxLength="4"
                            name="bathroom"
                            value={unitData.bathroom}
                            onChange={e => handleEditUnits(idx, 'bathroom', e)}
                        />
                    </div>
                    <div className="col-md-3 formInp bdroom">
                        <label>Square Feet</label>
                        <input
                            type="text"
                            maxLength="10"
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].squareFeet ? 'errorborder' : ''}`}
                            placeholder="0"
                            name="squareFeet"
                            value={unitData.squareFeet}
                            onChange={e => handleEditUnits(idx, 'squareFeet', e)}
                        />
                    </div>
                    <div className="col-md-3 formInp depounit">
                        <label>Monthly Rent</label>
                        {/* <input
                            type="text"
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].monthly ? 'errorborder' : ''}`}
                            placeholder="$0.00"
                            name="monthly"
                            value={unitData.monthly}
                            onChange={e => handleEditUnits(idx, 'monthly', e)}
                        /> */}
                        <NumberFormat
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].monthly ? 'errorborder' : ''}`}
                            placeholder="$0.00"
                            name="monthly"
                            maxLength="10"
                            value={unitData.monthly}
                            onChange={e => handleEditUnits(idx, 'monthly', e)}
                        />
                    </div>
                    <div className="col-md-3 formInp depounit">
                        <label>Deposit</label>
                        {/* <input
                            type="text"
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].deposit ? 'errorborder' : ''}`}
                            placeholder="$0.00"
                            name="deposit"
                            value={unitData.deposit}
                            onChange={e => handleEditUnits(idx, 'deposit', e)}
                        /> */}
                        <NumberFormat
                            thousandSeparator={true}
                            prefix={'$'}
                            className={`form-control ${unitValidations[idx] && unitValidations[idx].deposit ? 'errorborder' : ''}`}
                            name="deposit"
                            maxLength="10"
                            placeholder="$0.00"
                            value={unitData.deposit}
                            onChange={e => handleEditUnits(idx, 'deposit', e)}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PropertyUnit;
