import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import invoiceAction from '../../../Invoice/action'
import history from '../../../../config/history'
import Modal from '../../../Common/components/Modal';
import AddInvoice from '../../../Invoice/components/AddInvoice'
import usePrevious from "../../../Common/components/prevProps";

import moment from "moment";
import { numberWithCommas } from "../../../Common/components/utils";
import toastr from '../../../Common/components/Toaster'
import TenantInvoiceNew from "../../../Tenants/components/Invoice/TenantInvoiceNew";

const Invoice = props => {

    const [InvoiceModal, setInvoiceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [invoiceStatus, setInvoiceStatus] = useState("unpaid");
    const [invoiceData, setInvoiceData] = useState("unpaid");
    const [smsTenant, setSmsTenant] = useState(false);
    const [paymentSubmit, setPaymentSubmit] = useState(false)
    const [params, setParams] = useState({
        limit: 5, offset: 0, invoiceTypeId: null, propertyId: null,
        propertyDetails: null, tenantDetails: null, sort: null, orderData: 0, order: ""
    })

    const prevPaymentData = usePrevious(props.payment)
    const prevSmsInvoice = usePrevious(props.smsInvoice)
    const prevPayment = usePrevious(props.invoicePayment);




    const [editData, setEditData] = useState({
        tenantId: null,
        amount: null,
        startDate: null,
        dueDate: null,
        invoiceTypeId: null,
        paymentTypeId: null,
        isRecurring: false,
        isEdit: false,
        isView: false
    })

    useEffect(() => {
        async function renderCall() {
            await props.getInvoice()
        }

        renderCall();
    }, []);

    const handleViewMore = () => {
        history.push('/Invoice/All')
    }


    const addInvoiceDetails = async (invoiceForm) => {
        setIsSubmitted(true)
        if (editData.isEdit) {
            await props.updateInvoice([invoiceForm])
        } else {
            await props.addInvoice([invoiceForm])
        }
        setInvoiceModal(false)
        await props.getInvoice()
        setIsSubmitted(false)
    }


    const closeModal = () => {
        setInvoiceModal(false)
        setEditData({
            tenantId: null,
            amount: null,
            startDate: null,
            dueDate: null,
            invoiceTypeId: null,
            paymentTypeId: null,
            isRecurring: false,
            isEdit: false,
            isView: false
        })
    }

    const attemptCapture = async () => {

        setPaymentSubmit(true)
        await props.payInvoice(editData.paymentData)
        setInvoiceModal(false)
        setPaymentSubmit(false)
        await props.getInvoice()
        // setIsLoading(false)
    }

    const renderModal = () => {
        // return <AddInvoice
        //     addInvoiceDetails={addInvoiceDetails}
        //     onCancel={closeModal}
        //     invoiceDetails={editData}
        // />
        return <TenantInvoiceNew
            editData={editData}
            addInvoiceDetails={addInvoiceDetails}
            onCancel={closeModal}
            attemptCapture={attemptCapture}
            sendPaymentSms={sendPaymentSms}

        />
    }

    useEffect(() => {
        if (paymentSubmit && prevPayment !== props.invoicePayment && !props.invoicePayment.success) {
            toastr.error(props.invoicePayment.message)
        } else if (paymentSubmit && prevPayment !== props.invoicePayment && props.invoicePayment.success) {
            toastr.success(props.invoicePayment.message);
        }
    }, [prevPayment, props.invoicePayment]);

    //send payment sms for in invoice edit page on sendig sms
    useEffect(() => {
        if (smsTenant && prevSmsInvoice != props.smsInvoice && !props.smsInvoice.success) {
            toastr.error(props.smsInvoice.message)
        } else if (smsTenant && prevSmsInvoice != props.smsInvoice && props.smsInvoice.success) {
            if (props.smsInvoice.message.status === 'success') {
                toastr.success("Message sent successfully")
            } else {
                toastr.error(`Message sending failed`)
            }
        }
    }, [prevSmsInvoice, props.smsInvoice, smsTenant])



    const sendPaymentSms = async (id, no, editData) => {
        console.log("editData-->", editData)
        if (editData.cardDetails && editData.cardDetails.length) {
            let isDefaultCard = editData.cardDetails.find(tc => tc.defaultCard).cardNumber
            if (isDefaultCard) {
                setSmsTenant(true);
                await props.sendSmsEachInvoice({ receiverId: id, invoiceNumber: no })
                setTimeout(() => setSmsTenant(false), 500)
            }
        }
        else {
            toastr.error("Please add a valid card for the selected invoice ")
        }
    }

    const editInvoice = async (invoice) => {
        let editDataTemp = {
            id: invoice._id,
            tenantId: invoice.tenantId._id,
            amount: invoice.amount,
            startDate:invoice.startDate ? moment((invoice.startDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null,
            dueDate:invoice.dueDate ? moment((invoice.dueDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null, 
            invoiceTypeId: invoice.invoiceTypeId && invoice.invoiceTypeId._id,
            paymentTypeId: invoice.paymentTypeId && invoice.paymentTypeId._id,
            isRecurring: invoice.isRecurring,
            tenantDetails: {
                value: invoice.tenantId._id,
                label: invoice.tenantId.firstName + " " + invoice.tenantId.lastName
            },
            isEdit: true,
            status: invoice.status

        }
        setEditData(editDataTemp)
        setInvoiceModal(true)
    }

    const viewInvoice = (invoice) => {
        const { invoiceList } = props.invoiceList;
        // let viewData = {
        //     id: invoice._id,
        //     tenantId: invoice.tenantId._id,
        //     amount: invoice.amount,
        //     startDate: invoice.startDate && moment(invoice.startDate).format('MM/DD/YYYY'),
        //     dueDate: invoice.dueDate && moment(invoice.dueDate).format('MM/DD/YYYY'),
        //     invoiceTypeId: invoice.invoiceTypeId && invoice.invoiceTypeId._id,
        //     paymentTypeId: invoice.paymentTypeId && invoice.paymentTypeId._id,
        //     isRecurring: invoice.isRecurring,
        //     tenantDetails: {
        //         value: invoice.tenantId._id,
        //         label: invoice.tenantId.firstName + " " + invoice.tenantId.lastName
        //     },
        //     isView: true,
        //     status: invoice.status

        // }

        let viewData = {
            id: invoice._id,
            invoiceNumber: invoice.invoiceNumber,
            lastCaptureAttempt: invoice.attemptToCaptureDate,
            balance: invoiceList && invoiceList.data.balance,
            status: invoice.status,
            startDate: moment(invoice.startDate).format('MM/DD/YYYY'),
            dueDate: moment(invoice.dueDate).format('MM/DD/YYYY'),
            tenantId: invoice.tenantId && invoice.tenantId._id,
            tenantName: invoice.tenantId ? (invoice.tenantId.firstName + ' ' + invoice.tenantId.lastName) : '',
            tenantAddress: invoice.tenantId && invoice.tenantId.propertyId.propertyName,
            isRecurring: invoice.isRecurring,
            amount: invoice.amount,
            invoiceTypeId: invoice.invoiceTypeId._id,
            invoiceTypeName: invoice.invoiceTypeId.name,
            paymentTypeId: invoice.paymentTypeId._id,
            paymentTypeName: invoice.paymentTypeId.name,
            description: invoice.description || invoice.title,
            paidAmount: invoiceList && invoiceList.data.paidAmount,
            totalDue: invoiceList && invoiceList.data.totalDue,
            leaseStartDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.startDate,
            leaseEndDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.endDate,
            tenantDetails: {
                value: invoice.tenantId._id,
                firstName: invoice.tenantId.firstName,
                lastName: invoice.tenantId.lastName
            },
            invoices: invoice.charges,
            isEdit: true,
            transactionId: invoice.transactionId,
            cardDetails: invoice.tenantId && invoice.tenantId.cardDetails,
            paymentData: {
                invoiceId: invoice._id,
                businessID: invoice.tenantId && invoice.tenantId.propertyId && invoice.tenantId.propertyId.paymentId,
                tenantID: invoice.tenantId._id,
                customerPaymentProfileId: invoice.tenantId && invoice.tenantId.customerPaymentProfileId,
                customerProfileId: invoice.tenantId && invoice.tenantId.customerProfileId,
                amount: invoice.amount,
            }
        }

        setEditData(viewData)
        // setIsLoading(true)
        // setTimeout(() => {
        //     setIsLoading(false)
        setInvoiceModal(true)
        // }, 1000)
    }

    const compare_item = (a, b) => {
        console.log("a==>",a,b)
        // a should come before b in the sorted order
        if (a.amount < b.amount) {
            return 1;
            // a should come after b in the sorted order
        } else if (a.amount > b.amount) {
            return -1;
            // and and b are the same
        } else {
            return 0;
        }
    }


    const paidInvoice = props.invoiceList && props.invoiceList.invoice && props.invoiceList.invoice.length && props.invoiceList.invoice.filter((i) => i.status).sort(compare_item)
    const unPaidInvoice = props.invoiceList && props.invoiceList.invoice && props.invoiceList.invoice.length && props.invoiceList.invoice.filter((i) => !(i.status)).sort(compare_item)

    const handleStatus = (value) => {
        setInvoiceStatus(value)
        if (value === 'paid') {
            setInvoiceData(paidInvoice)
        } else {
            setInvoiceData(unPaidInvoice)
        }

    }

    

    return (
        <div
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-12 col-sm-12 occupancy mb-3 invoicee">
            {InvoiceModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            {console.log("paidInvoice", unPaidInvoice)}
            <div className="occupancyOtr invoiceOuter">
                <div className="headerSection d-flex">
                    <h3>INVOICE</h3>
                    <div className="viewMore" onClick={handleViewMore}>
                        <img src="images/more.png" /><span>View More</span>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 invoiceTab">
                        <nav>
                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a className={invoiceStatus === "unpaid" ? "nav-item nav-link active" : "nav-item nav-link"}
                                    onClick={() => handleStatus("unpaid")}>Unpaid</a>
                                <a className={invoiceStatus === "paid" ? "nav-item nav-link active" : "nav-item nav-link"}
                                    onClick={() => handleStatus("paid")}>Paid</a>

                                <div className="invoiceRightText">
                                    {invoiceStatus === "paid" ?
                                        `Paid Invoice $${props.invoiceList ? numberWithCommas(props.invoiceList.paidAmount) || 0.00 : 0}` :
                                        `Unpaid Invoice $${props.invoiceList ? numberWithCommas(props.invoiceList.unpaidAmount) || 0.00 : 0}`}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>



                {invoiceStatus === "paid" ? paidInvoice && paidInvoice.length ?
                    <div className="col-md-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="row tab-pane fade active show" id="nav-paid" role="tabpanel"
                                aria-labelledby="nav-paid-tab">
                                <div className="table-responsive  p-0">
                                    <table className="table table-bordered table-styles">
                                        {/* <thead>
                                        <tr>
                                            <th colSpan="2">Paid
                                                Invoice <span>(${props.invoiceList ? numberWithCommas(props.invoiceList.paidAmount) || 0.00 : 0})</span>
                                            </th>
                                        </tr>
                                        </thead> */}
                                        <tbody>
                                            {paidInvoice.slice(0, 3).map((invoice, key) => {


                                                return <tr key={key} onClick={() => viewInvoice(invoice)}
                                                    style={{ cursor: 'Pointer' }}>
                                                    <td>
                                                        <div className="otr">
                                                            <strong>{invoice.tenantId ? invoice.tenantId.firstName + " " + invoice.tenantId.lastName : null}</strong>
                                                            <p>Invoice NO: {invoice.invoiceNumber}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="price">
                                                            <p>Price</p>
                                                            <strong>${invoice.amount ? numberWithCommas(invoice.amount) || 0.00 : 0}</strong>
                                                        </div>
                                                    </td>
                                                    {/*<td>
                                                    <div className="dropdown moveDrop drop1">
                                                        <a className="dropdown-toggle" href="#" role="button"
                                                            id="dropdownMenuLink" data-toggle="dropdown"
                                                            data-boundary="window"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            <i className="fas fa-ellipsis-v"></i>
                                                        </a>
                                                        <div className="dropdown-menu"
                                                                aria-labelledby="dropdownMenuLink">
                                                            <a className="dropdown-item"
                                                                onClick={() => viewInvoice(invoice)}><i
                                                                className="far fa-eye"></i> View</a>
                                                            <a className="dropdown-item"
                                                                onClick={() => editInvoice(invoice)}><i
                                                                className="far fa-edit"></i> Edit</a>
                                                        </div>
                                                    </div>
                                                </td>*/}
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="no_value"><i className="far fa-thumbs-down"></i> No Data
                                Found!</div>
                    : unPaidInvoice && unPaidInvoice.length ?
                        <div className="col-md-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="row tab-pane fade active show" id="nav-paid" role="tabpanel"
                                    aria-labelledby="nav-paid-tab">
                                    <div className="table-responsive  p-0">
                                        <table className="table table-bordered table-styles">
                                            {/* <thead>
                                            <tr>
                                                <th colSpan="3">UnPaid
                                                    Invoice <span>(${props.invoiceList ? numberWithCommas(props.invoiceList.unpaidAmount) || 0.00 : 0})</span>
                                                </th>
                                            </tr>
                                            </thead> */}
                                            <tbody>
                                                {unPaidInvoice.slice(0, 3).map((invoice, key) => {

                                                    return <tr key={key} onClick={() => viewInvoice(invoice)}
                                                        style={{ cursor: 'Pointer' }}>
                                                        <td>
                                                            <div className="otr">
                                                                <strong>{invoice.tenantId ? invoice.tenantId.firstName + " " + invoice.tenantId.lastName : null}</strong>
                                                                <p>Invoice NO: {invoice.invoiceNumber}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="price">
                                                                <p>Price</p>
                                                                <strong
                                                                    className={"price unpaide"}>${invoice.amount ? numberWithCommas(invoice.amount) || 0.00 : 0}</strong>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="no_value"><i className="far fa-thumbs-down"></i>No data
                            found!
                                </div>
                }
            </div>
        </div>

    )
}


const mapStateToProps = state => {
    const { invoiceReducer } = state;
    return {
        invoiceList: invoiceReducer.InvoiceList,
        invoicePayment: invoiceReducer.invoicePayment,
        sms: invoiceReducer.sms,
        smsInvoice: invoiceReducer.smsInvoice

    };
};

export default connect(mapStateToProps, {
    ...invoiceAction
})(Invoice);

