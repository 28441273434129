import * as actionType from "./constants";

const initialState = {
  loading: false,
  User: {},
  tokenValidity:{},
  resetPassword:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN_REQUEST:
      return {
        ...state
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        User: { success: true, ...action.loginAction }
      };
    case actionType.LOGIN_FAILURE:
      return {
        ...state,
        User: { success: false, ...action.error }
      };

    case actionType.VALIDATE_KEY_REQUEST:
      return {
        ...state
      };
    case actionType.VALIDATE_KEY_SUCCESS:
      return {
        ...state,
        tokenValidity: { success: true, ...action.passwordAction }
      };
    case actionType.VALIDATE_KEY_FAILURE:
      return {
        ...state,
        tokenValidity: { success: false, ...action.error }
      };
    case actionType.PASSWORD_FORGOT_REQUEST:
      return {
        ...state
      };
    case actionType.PASSWORD_FORGOT_SUCCESS:
      return {
        ...state,
        resetPassword: { success: true, ...action.passwordAction }
      };
    case actionType.PASSWORD_FORGOT_FAILURE:
      return {
        ...state,
        resetPassword: { success: false, ...action.error }
      };
    case actionType.PASSWORD_CHANGE_REQUEST:
      return {
        ...state
      };
    case actionType.PASSWORD_CHANGE_SUCCESS:

      return {
        ...state,
        setPassword: { success: true, ...action.loginAction }
      };
    case actionType.PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        setPassword: { success: false, ...action.error }
      };
    default:
      return state;
  }
};
