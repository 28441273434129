import React from "react";

import {MAIN_ROUTE} from "../../../config/constants";
import {numberWithCommas} from "../../Common/components/utils";


const ExpenseList = (props) => {

    const parseBackgoundImage = (image) => {

        if (image.length) {
            if (image[0].type && image[0].type == "zillo") {

                return image[0].path
            } else {
                return MAIN_ROUTE + image[0].path
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }

    return (
        <div className="table-responsive" id={"table-to-xls"}>
            <table className="table-gap table-td">
                <tr className="table-head">
                    <th className="wdt-01">Property</th>
                    <th className="wdt-04">Unit</th>
                    <th className="wdt-04">Amount</th>
                    <th className="wdt-04">Category</th>
                    <th className="wdt-03">Description</th>
                    {/* <th className="actionSec">Action</th> */}
                </tr>
                <tbody>
                {props.ExpenseList && props.ExpenseList.expenses && props.ExpenseList.expenses.length ? props.ExpenseList.expenses.map((expense, key) => {
                    const imgPath = expense.propertyId && expense.propertyId.propertyImage && expense.propertyId.propertyImage[0] && expense.propertyId.propertyImage[0].path
                    return <tr key={key}>
                        <td>
                            <div className="img-name">
                                <i>
                                    <img
                                        src={expense.propertyId && expense.propertyId.propertyImage && expense.propertyId.propertyImage[0] ? parseBackgoundImage(expense.propertyId.propertyImage) : '/images/def_property.png'}
                                        alt=""/>
                                </i>
                                <div>
                                    <p>{expense.propertyId ? expense.propertyId.propertyName : null}</p>
                                    <span>{expense.propertyId ? expense.propertyId.address : null}</span>
                                </div>

                            </div>
                        </td>

                        <td>{expense.unitId ? expense.unitId.unitName : null}</td>
                        <td>${numberWithCommas(expense.amount)||0.00}</td>
                        <td>{expense.maintenanceCategoryId ? expense.maintenanceCategoryId.name : null}</td>
                        <td>{expense.description}
                        </td>

                        {/* <td>
                            <div className="dropdown moveDrop editDetlPrp drop-arw">

                                {expense.maintenanceId ? "closed":<>
                                    <i
                                    className="fas fa-pencil-alt"
                                    aria-hidden="true"
                                    onClick={() => props.editExpense(expense)}
                                    ></i>

                                </>
                                }
                            </div>
                        </td> */}
                    </tr>
                }) : <tr>
                    <td className="text-center no_value_table" colSpan={7}><i className="far fa-thumbs-down"></i>No Data Found!!</td>
                </tr>}

                </tbody>
            </table>

        </div>


    )
}

export default ExpenseList;
