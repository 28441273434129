//add screening
export const ADD_SCREENING_REQUEST = 'ADD_SCREENING_REQUEST ';
export const ADD_SCREENING_SUCCESS = 'ADD_SCREENING_SUCCESS ';
export const ADD_SCREENING_FAILURE = 'ADD_SCREENING_FAILURE ';

//get available property in screening
export const GET_SCREENING_PROPERTY_REQUEST = 'GET_SCREENING_PROPERTY_REQUEST ';
export const GET_SCREENING_PROPERTY_SUCCESS = 'GET_SCREENING_PROPERTY_SUCCESS ';
export const GET_SCREENING_PROPERTY_FAILURE = 'GET_SCREENING_PROPERTY_FAILURE ';

//ADD EMAIL
export const ADD_EMAIL = 'ADD_EMAIL ';

//make Payment
export const MAKE_PAYMENT_REQUEST = ' MAKE_PAYMENT_REQUEST ';
export const MAKE_PAYMENT_SUCCESS = ' MAKE_PAYMENT_SUCCESS ';
export const MAKE_PAYMENT_FAILURE = ' MAKE_PAYMENT_FAILURE ';

//get vacant property in screening
export const GET_VACANT_PROPERTY_REQUEST = 'GET_VACANT_PROPERTY_REQUEST ';
export const GET_VACANT_PROPERTY_SUCCESS = 'GET_VACANT_PROPERTY_SUCCESS ';
export const GET_VACANT_PROPERTY_FAILURE = 'GET_VACANT_PROPERTY_FAILURE ';

//get vacant property in screening
export const GET_VACANT_UNIT_REQUEST = 'GET_VACANT_UNIT_REQUEST ';
export const GET_VACANT_UNIT_SUCCESS = 'GET_VACANT_UNIT_SUCCESS ';
export const GET_VACANT_UNIT_FAILURE = 'GET_VACANT_UNIT_FAILURE ';