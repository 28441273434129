import React from "react";

const Notification = (props) => {

    return (
        <div className="notificationScroll">
            {props.unreadNotifications ? props.notifications && props.notifications.length ? props.notifications.map((notification, key) => {
                return (
                    notification.count != 0 ?
                        <li className="listNoti" key={key} onClick={() => props.viewDetailNotification(notification.type)}>
                            <div className="messageImg">
                                <img src="/images/message.png" />
                            </div>
                            <div className="imgCont">
                                <p className="desc">
                                    You have {notification.count} new {notification.type}
                                </p>
                                {/*<p className="timr">*/}
                                {/*1 minutes ago*/}
                                {/*</p>*/}
                            </div>
                        </li> : null
                )

            }) : null : <li className="listNoti notification_nodata"><i className="far fa-thumbs-down"></i>No Notifications!</li>}
        </div>
    )
}

export default Notification

