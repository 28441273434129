import React, { useState, useEffect } from "react";
import action from "./action";
import { connect } from "react-redux";
import BusinessEntity from "./components/BusinessEntity";
import AddBusiness from './components/AddBusiness';
import Modal from '../Common/components/Modal';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import AddUser from './components/addUser'
import usePrevious from "../Common/components/prevProps";
import toastr from '../Common/components/Toaster'
import history from "../../config/history";
import profileAction from '../Profile/action'
import Template from './components/template';
import AddTemplate from './components/addTemplateModal';
import TemplateEdit from "./components/templateEdit"
import { toast } from "react-toastify";
import LoaderSpinner from "../Common/components/Loader";
import settingsAction from '../Settings/action'

const Settings = (props) => {

    const [businessModal, setBusinessModal] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [templateEditModal, setTemplateEditModal] = useState(false);
    const [templateSubmit, setTemplateSubmit] = useState(false);


    const [addUserModal, setaddUser] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [pdfData, setPdf] = useState(null)

    const prevUser = usePrevious(props.settingsReducer.addUser);
    const prevTemplate = usePrevious(props.templateData);
    const prevTemplateEdit = usePrevious(props.templateEdit);


    useEffect(() => {
        if (props.settingsReducer.addMailAccRes && props.settingsReducer.addMailAccRes.url) {
            if (typeof (props.settingsReducer.addMailAccRes.url.data) !== 'undefined' && props.settingsReducer.addMailAccRes.url.data) {
                window.location.href = props.settingsReducer.addMailAccRes.url.data;
            }
        }
    }, [props.settingsReducer.addMailAccRes.url])

    useEffect(() => {
        if (isSubmitted && prevTemplate != props.templateData) {
            if (props.templateData.success) {
                setTemplateEditModal(true)
            }
            else {
                toastr.error("Error while uploading template")
            }
        }
    }, [prevTemplate, props.templateData])

    useEffect(() => {
        if (templateSubmit && prevTemplateEdit != props.templateEdit) {
            if (props.templateEdit.success) {
                toastr.success("Template Added Successfully!!")
            }
            else {
                toastr.error("Error while uploading template")
            }
        }
    }, [prevTemplateEdit, props.templateEdit])

    useEffect(() => {
        const callAsync = async () => {
            const { location: { search } } = props;
            const urlDataQuery = queryString.parse(search);
            let userData = JSON.parse(localStorage.getItem('userDetails'))
            let userId = userData.data.user._id
            if (urlDataQuery && urlDataQuery.code && urlDataQuery.code.length) {
                await props.addMailAccountCode(urlDataQuery.code, userId);
                props.history.push('/settings')
            }
        }
        callAsync()
    }, []);

    const openModalBusiness = () => {
        setBusinessModal(true)
    }

    const closeModalBusiness = () => {
        setBusinessModal(false)
    }

    const renderModalBusiness = () => {
        return <AddBusiness
            onCancel={closeModalBusiness}
        />
    };
    const openModalTemplate = () => {
        setTemplateModal(true)
    }

    const closeModalTemplate = () => {
        setTemplateModal(false)
    }

    const renderModalTemplate = () => {
        return <AddTemplate
            onCancel={closeModalTemplate}
            handlePdfdata={handlePdfdata}
            submitData={submitData}
            isTemplateView={false}

        />
    };

    const handlePdfdata = (e) => {
        if (e.target) {
            let ext = e.target.files[0].name.split('.').pop();
            if (ext === "pdf") {
                setPdf(e.target.files[0])
            }
            else {
                toastr.error("Please Choose a pdf file!!")
            }
        }

    }

    const submitData = async () => {
        if (pdfData) {
            setTemplateModal(false)
            setIsSubmitted(true)
            await props.addTemplate(pdfData)
            setIsSubmitted(false)
        }
    }

    const addMailAccount = async () => {
        await props.addMailAccount({ type: 'admin' });
    };



    const closeModalEditTemplate = () => {
        setTemplateEditModal(false)
    }

    const renderModalEditTemplate = () => {
        return <TemplateEdit
            onCancel={closeModalEditTemplate}
            templateData={props.templateData.data}
            addEditedTemplateData={addEditedTemplateData}
            isTemplateView={false}
            isEdit={true}


        />
    };

    const addEditedTemplateData = async (data) => {
        setTemplateEditModal(false)
        setTemplateSubmit(true)
        await props.addEditedTemplate(data)
        await props.getTemplates({ limit: 10, offset: 0 })
        setTemplateSubmit(false)


    }


    return (
        <>
            {businessModal ? <Modal body={renderModalBusiness()} onCancel={closeModalBusiness} /> : null}
            {templateModal ? <Modal body={renderModalTemplate()} onCancel={closeModalTemplate} /> : null}
            {templateEditModal ? <Modal body={renderModalEditTemplate()} onCancel={closeModalEditTemplate} /> : null}
            {isSubmitted && <LoaderSpinner />}
            <div className="tenants-otr settingsOuter">
             
                <div className="innago-table-top">
                    <div className="lft">
                        <h1>SETTINGS</h1>
                    </div>
                    <div className="rgt">
                        <button className="add" onClick={() => history.push('/DataSource')}>
                            {/* <i className="fas fa-plus"/> */}
                            Data Source
                        </button>

                        <button className="add" onClick={() => history.push('/Users')}>
                            <i className="far fa-eye mr-1" ></i> Users
                        </button>
                         <button className="add" onClick={() => history.push('/Email/Settings')}>
                            <i class="far fa-envelope"></i> Mail Settings
                        </button>   
                         {/* <button className="add btn" onClick={() => addMailAccount()} >
                            <i className="fas fa-plus" />
                            Add Google Account
                        </button> */}
                    </div>
                </div>
                <div className="settingTable">
                    <div className="innago-table table-main mb-3">
                        <div className="table-header-flex">
                            <h3>Business Entity</h3>
                            <button
                                className="add-btn"
                                onClick={() => openModalBusiness()}
                            >
                                <i className="fas fa-plus" /> Add Business Entity
                            </button>
                        </div>
                        <BusinessEntity />

                    </div>
                    <div className="innago-table table-main ">

                        <div className="table-header-flex">
                            <h3>Lease Template</h3>
                            <button
                                className="add-btn"
                                onClick={() => openModalTemplate()}

                            >
                                <i className="fas fa-plus" /> Add Template
                            </button>
                        </div>
                        <Template />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { settingsReducer, profileReducer } = state;
    return {
        settingsReducer,
        profileReducer,
        templateData: settingsReducer.templateData,
        templateEdit: settingsReducer.templateEdit

    };
};

export default withRouter(connect(mapStateToProps, { ...action, ...profileAction, ...settingsAction })(Settings));
