import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//add business entity details
export const addBusinessEntity = (value) =>
    innagoGateWay.post(serviceEndpoints.settingsEndpoints.businessEntity, value);

//edit business entity details
export const editBusinessEntity = (value) =>
    innagoGateWay.put(serviceEndpoints.settingsEndpoints.businessEntity, value);

//add Mail Account
export const addMailAccount = (params) =>
    innagoGateWay.get(serviceEndpoints.settingsEndpoints.addMailAccount, { params });

//add Mail Account
export const addMailAccountCode = (code, userId) => {
    return innagoGateWay.get(`${serviceEndpoints.settingsEndpoints.addMailAccountCode}?code=${code}&userId=${userId}`);
}

//DELETE BUSINESS by id
export const deleteBusiness = (id) =>
    innagoGateWay.delete(`${serviceEndpoints.settingsEndpoints.businessEntity}/${id}`);

//add user details
export const addUser = (value) =>
    innagoGateWay.post(serviceEndpoints.profileEndpoints.profile, value);

//DELETE user by id
export const deleteUser = (id) =>
    innagoGateWay.delete(`${serviceEndpoints.profileEndpoints.profile}/${id}`);

//get all users
export const getUsers = (params) =>
    innagoGateWay.get(`${serviceEndpoints.settingsEndpoints.user}`, { params });

//add user details
export const editUser = (value) =>
    innagoGateWay.put(`${serviceEndpoints.settingsEndpoints.editUser}/${value.id}`, value);

//add card details
export const addCardDetails = (value) =>
    innagoGateWay.post(serviceEndpoints.settingsEndpoints.card, value);

//add primary card
export const setPrimaryCard = (value) =>
    innagoGateWay.put(serviceEndpoints.settingsEndpoints.card, value);

//delete card details
export const deleteCard = (value) =>
    innagoGateWay.post(serviceEndpoints.settingsEndpoints.deleteCard, value);

//get all users
export const getPaymentHistory = (params) =>
    innagoGateWay.get(serviceEndpoints.settingsEndpoints.paymentHistory, { params });

//add autoPay details
export const autoPay = (value) =>
    innagoGateWay.post(serviceEndpoints.settingsEndpoints.autoPay, value);

//get auto pay
export const getAutoPay = (params) =>
    innagoGateWay.get(serviceEndpoints.settingsEndpoints.autoPay, { params });

//add template pdf
export const addTemplate = (value) =>
    innagoGateWay.post(serviceEndpoints.settingsEndpoints.addTemplate, value);

//add template pdf
export const addEditedTemplate = (value) =>
    innagoGateWay.post(serviceEndpoints.settingsEndpoints.addEditedTemplate, value);

//update template pdf
export const updateTemplate = (value) =>
    innagoGateWay.put(serviceEndpoints.settingsEndpoints.addEditedTemplate, value);


export const getTemplates = (params) =>
    innagoGateWay.get(serviceEndpoints.settingsEndpoints.getTemplates, { params });


//delete card details
export const deleteTemplate = (id) =>
    innagoGateWay.delete(`${serviceEndpoints.settingsEndpoints.deleteTemplate}/${id}`);

//add Mail Account
export const addMailDetails = (params) =>
    innagoGateWay.post(serviceEndpoints.settingsEndpoints.addMailDetails, params);

//get Mail Account
export const getMailDetails = (params) =>
    innagoGateWay.get(serviceEndpoints.settingsEndpoints.addMailDetails, { params });

//delete Mail Account
export const deleteMailDetails = (id) =>
    innagoGateWay.delete(`${serviceEndpoints.settingsEndpoints.addMailDetails}/${id}`);

//update Mail Account
export const updateMailDetails = (params) =>
    innagoGateWay.put(`${serviceEndpoints.settingsEndpoints.addMailDetails}/${params.id}`, params);