import * as Service from './service';
import * as Constant from './constant';


const addLease = (lease) => {
    const request = () => ({ type: Constant.ADD_LEASE_REQUEST });
    const success = response => ({
        type: Constant.ADD_LEASE_SUCCESS,
        response
    });
    const failure = error => ({
        type: Constant.ADD_LEASE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addLease(lease);
            if (res.status === 200) {
                const leaseDetails = res.data;
                if (leaseDetails.success) {
                    dispatch(success(leaseDetails));
                } else {
                    dispatch(failure(leaseDetails));
                }
            } else {
            dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getLease = (params) => {
    const request = () => ({ type: Constant.GET_LEASE_REQUEST });
    const success = leaseDetails => ({
        type: Constant.GET_LEASE_SUCCESS,
        leaseDetails
    });
    const failure = error => ({
        type: Constant.GET_LEASE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getLease(params);
            if (res.status === 200) {
                const leaseDetails = res.data;
                if (leaseDetails.success) {
                    dispatch(success(leaseDetails));
                } else {
                    dispatch(failure(leaseDetails));
                }
            } else {
            dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getLeaseView = (params) => {
    const request = () => ({ type: Constant.VIEW_LEASE_DETAIL_REQUEST });
    const success = leaseDetails => ({
        type: Constant.VIEW_LEASE_DETAIL_SUCCESS,
        leaseDetails
    });
    const failure = error => ({
        type: Constant.VIEW_LEASE_DETAIL_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getLeaseView(params);
            if (res.status === 200) {
                const leaseDetails = res.data;
                if (leaseDetails.success) {
                    dispatch(success(leaseDetails));
                } else {
                    dispatch(failure(leaseDetails));
                }
            } else {
            dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const updateLease = (value,params) => {
    const request = () => ({ type: Constant.UPDATE_LEASE_REQUEST });
    const success = leaseDetails => ({
        type: Constant.UPDATE_LEASE_SUCCESS,
        leaseDetails
    });
    const failure = error => ({
        type: Constant.UPDATE_LEASE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateLease(value,params);
            if (res.status === 200) {
                const leaseDetails = res.data;
                if (leaseDetails.success) {
                    dispatch(success(leaseDetails));
                } else {
                    dispatch(failure(leaseDetails));
                }
            } else {
            dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


const deleteLease = (value) => {
    const request = () => ({ type: Constant.DELETE_LEASE_REQUEST });
    const success = leaseDetails => ({
        type: Constant.DELETE_LEASE_SUCCESS,
        leaseDetails
    });
    const failure = error => ({
        type: Constant.DELETE_LEASE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteLease(value);
            if (res.status === 200) {
                const leaseDetails = res.data;
                if (leaseDetails.success) {
                    dispatch(success(leaseDetails));
                } else {
                    dispatch(failure(leaseDetails));
                }
            } else {
            dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const updateLeaseSign = (value,params) => {
    const request = () => ({ type: Constant.UPDATE_LEASE_REQUEST });
    const success = leaseDetails => ({
        type: Constant.UPDATE_LEASE_SUCCESS,
        leaseDetails
    });
    const failure = error => ({
        type: Constant.UPDATE_LEASE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateLeaseSign(value,params);
            if (res.status === 200) {
                const leaseDetails = res.data;
                if (leaseDetails.success) {
                    dispatch(success(leaseDetails));
                } else {
                    dispatch(failure(leaseDetails));
                }
            } else {
            dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

export default {
    addLease,
    getLease,
    getLeaseView,
    updateLease,
    deleteLease,
    updateLeaseSign
}
