import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from '../../../Common/components/Modal'
import usePrevious from "../../../Common/components/prevProps";
import { connect } from "react-redux";
import commonAction from "../../../Common/action";
import action from "../../../Maintenance/action";
import history from '../../../../config/history'
import MaintenanceAdd from '../../../Maintenance/components/maintenanceAdd';
import toastr from '../../../Common/components/Toaster';
import moment from "moment";

const Maintenance = props => {

    const [params, setParams] = useState({
        limit: 3, offset: 0, invoiceTypeId: null, sort: null, status: 1
    })

    const [isAdd, setIsAdd] = useState(false);
    const prevUser = usePrevious(props.profileReducer.profileDetail);
    const [maintenanceModal, setMaintenanceModal] = useState(false);
    const [editData, setEditData] = useState({
        propertyId: null,
        maintenanceFile: [],
        maintenanceFileEdit: [],
        description: null,
        categoryId: null,
        requestById: null,
        isEdit: false,
        isView: false,
        maintenancePicture: [],
        status: 1
    })

    useEffect(() => {
        //props.getTenantPropertyDetails()
        async function renderCall() {
            await props.getTenantMaintenance(params)
        }

        renderCall();
    }, [])

    const prevMaintenance = usePrevious(props.maintenanceDetailsByTenant);

    useEffect(() => {
        if (isAdd && !props.maintenances.success) {
            toastr.error(props.maintenanceDetailsByTenant.message)
        } else if (isAdd && props.maintenances.success) {
            toastr.success("Successfully added")
        }
    }, [prevMaintenance, props.maintenances]);

    const handleViewMore = () => {
        history.push('/Tenant-Maintenance')
    }

    const openModal = () => {

        if (props.propertyDetailsByTenant.property._id) {
            const { propertyDetailsByTenant: { property } } = props;
            setEditData({
                propertyDetail: {
                    value: property && property.propertyId && property.propertyId._id,
                    label: property && property.propertyId && property.propertyId.propertyName
                },
                propertyId: property && property.propertyId && property.propertyId._id,
                unitId: property && property.unitId && property.unitId._id,
                maintenanceFile: [],
                maintenanceFileEdit: [],
                description: null,
                categoryId: null,
                requestById: property && property._id,
                isEdit: false,
                isView: false,
                maintenancePicture: [],
                status: 1,
                isTenant: true,
                tenantName: property && property.firstName + " " + property.lastName,
                paymentId: property && property.propertyId.paymentId,
                propertyOwnerName: property && property.propertyId.paymentId && property.propertyId.paymentId.entityName
            })
            setTimeout(() => {
                setMaintenanceModal(true)
            }, 10)
        }
    }

    const closeModal = () => {
        setMaintenanceModal(false)
        setEditData({
            _id: null,
            propertyId: null,
            maintenanceFile: [],
            maintenanceFileEdit: [],
            description: null,
            categoryId: null,
            requestById: null,
            isEdit: false,
            isView: false,
            status: 1,
            paymentId: null
        })
    }

    const renderModal = () => {
        return <MaintenanceAdd
            addMaintenanceDetails={addMaintenanceDetails}
            onCancel={closeModal}
            maintenanceDetails={editData}
        />
    }

    const addMaintenanceDetails = async (maintenanceDetails) => {
        setIsAdd(true);
        await props.addMaintenance(maintenanceDetails)
        await props.getTenantMaintenance({ offset: 0, limit: 10 })

        setEditData({
            _id: null,
            propertyId: null,
            maintenanceFile: [],
            maintenanceFileEdit: [],
            description: null,
            categoryId: null,
            requestById: null,
            isEdit: false,
            isView: false,
            status: 1,
            paymentId: null
        })
        setMaintenanceModal(false)
        setTimeout(() => {
            setIsAdd(false)
        }, 500)
    }

    const renderStatus = (status) => {
        switch (status) {
            case 1:
                return "Open"
            case 0:
                return "Closed"
        }
    }

    const renderTimeSlot = (status) => {
        switch (status) {
            case '1':
                return "Any time"
            case '2':
                return "8am - 12pm"
            case '3':
                return "12pm - 4pm"
            case '4':
                return "4pm - 8pm"
        }
    }

    const viewMoreMaintenance = () => {
        history.push('/Tenant-Maintenance')
    }

    return (
        <div className="col-md-4">
            <div className="maintenance-issue">
                <div className="payable-head justyfy-space">
                    <div className="d-flex align-items-center">
                        <i className="fas fa-wrench"></i>
                        <p>OPEN MAINTENANCE</p>
                    </div>
                    <button className="btn" onClick={() => history.push('/Add-Maintenance-Tenant')}>
                        <i className="fas fa-plus"></i> Add Maintenance
                    </button>
                </div>
                {props.maintenanceDetailsByTenant && props.maintenanceDetailsByTenant.maintenanceList &&
                    props.maintenanceDetailsByTenant.maintenanceList.length ?
                    <>
                        {props.maintenanceDetailsByTenant.maintenanceList.slice(0, 2).map((maintenance) => {

                            return <div className="available-box mb-2">

                                <div className="payment-sec">
                                    <span className="rent">{maintenance.title}</span>

                                </div>
                                <div className="row custm-mrgn">
                                    <div className="col-md-6 bdrRight pl-0">
                                        <div className="invoice-sec"><p>Priority</p><span>{maintenance.priority}</span>
                                        </div>
                                        <div className="client-sec"><p>Status</p><span
                                            className="available-status">{renderStatus(maintenance.status)}</span></div>
                                    </div>
                                    <div className="col-md-6 pr-0">
                                        <div className="invoice-sec"><p>REQUEST DATE</p>
                                            <span>{moment(maintenance.requestInitiatedDate).format('MM/DD/YYYY')}</span>
                                        </div>
                                        <div className="invoice-sec"><p>AVAILABLE TIME </p><span>
                                            {maintenance.availableTime ? renderTimeSlot(maintenance.availableTime) : 'N/A'}
                                        </span></div>
                                    </div>
                                </div>
                                {/*<div className= "box-footer">*/}
                                {/*    <button className= "btn">View Details</button>*/}
                                {/*</div>*/}


                                {/*<div className="col-md-6 bdrRight pl-0">*/}
                                {/*    <div className="invoice-sec">*/}
                                {/*        <p>Priority</p>*/}
                                {/*        <span>{maintenance.priority}</span>*/}
                                {/*        <hr/>*/}
                                {/*    </div>*/}
                                {/*    <div className="client-sec">*/}
                                {/*        <p> Status</p>*/}
                                {/*     */}
                                {/*<span className="available-status">{renderStatus(maintenance.status)}</span>*/}

                                {/*        /!* <div className="userImage">*/}
                                {/*            <img src="images/profile-image.png"/>*/}
                                {/*        </div> *!/*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-6 pr-0">*/}
                                {/*    <div className="invoice-sec">*/}
                                {/*        <p>REQUEST DATE</p>*/}
                                {/*        <span>{moment(maintenance.requestInitiatedDate).format('MM/DD/YYYY')}</span>*/}
                                {/*        <hr/>*/}
                                {/*    </div>*/}
                                {/*    <div className="invoice-sec">*/}
                                {/*        <p>AVAILABLE TIME </p>*/}
                                {/*        <span>{maintenance.availableTime}</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className="box-footer">*/}
                                {/*    <button className="btn">View Details</button>*/}
                                {/*</div>*/}
                            </div>
                        })}
                        <div className="view-more-text" onClick={viewMoreMaintenance}>
                            View More
                        </div>
                    </>
                    :
                    <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>
                }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { commonReducer, profileReducer, maintenanceReducer } = state;
    return {
        maintenanceDetailsByTenant: commonReducer.maintenanceDetailsByTenant,
        propertyDetailsByTenant: commonReducer.propertyDetailsByTenant,
        profileReducer,
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        commonReducer
    };
}

export default withRouter(connect(mapStateToProps, { ...commonAction, ...action })(Maintenance));
