import React, { useState, useEffect } from "react";
import { MAIN_ROUTE } from "../../../../config/constants";
import { numberWithCommas } from "../../../Common/components/utils";


const PropertyHeader = (props) => {

    // const imgPath = props.propertyDetails[0] && props.propertyDetails[0].propertyImageId
    //     && props.propertyDetails[0].propertyImageId && props.propertyDetails[0].propertyImageId[0]
    //     && props.propertyDetails[0].propertyImageId[0].images && props.propertyDetails[0].propertyImageId[0].images.find(i => i.isDefault === true)
    //
    // let path = imgPath && imgPath.path
    //
    // let tempPath = null
    //
    // if (props.propertyDetails[0] && props.propertyDetails[0].propertyImage && props.propertyDetails[0].propertyImage[0]) {
    //     tempPath = props.propertyDetails[0].propertyImage[0].path
    // }

    const getDefaultImage = (property) => {
        // src={(property.propertyImage[0]) ? parseBackgoundImage(property.propertyImage) : "/images/def_property.png"}
        let defImg = [];
        if (property &&
            property.propertyImage && property.propertyImage.length &&
            property.propertyImage.filter(img => img.isDefault) &&
            property.propertyImage.filter(img => img.isDefault).length
        ) {
            defImg = property.propertyImage.filter(img => img.isDefault);
        } else if (property.propertyImage[0]) {
            defImg = property.propertyImage[0]
        }
        if (!Array.isArray(defImg)) {
            defImg = [defImg]
        }
        if (defImg && defImg[0]) {
            return parseBackgoundImage(defImg)
        } else {
            return "/images/def_property.png"
        }
    }

    const parseBackgoundImage = (image) => {
        if (image.length) {
            if (image[0] && image[0].type && image[0].type == "zillo") {
                return image[0].path
            } else if (image[0]) {
                return MAIN_ROUTE + image[0].path
            } else {
                return '/images/def_property.png'
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }

   

    return (
        <div className="propertyDtlSecn">
            <div className="outer-section-prop d-flex">
                <div className="prop-nameDtl dtlContProp">
                    <div className="propImg">
                        <img
                            src={props.propertyDetails[0] && (props.propertyDetails[0].propertyImage) ? getDefaultImage(props.propertyDetails[0]) : "/images/def_property.png"} />
                    </div>
                    <div className="propcont">
                        <h3>{props.propertyDetails[0] ? props.propertyDetails[0].address.replace(", USA", "") : null}</h3>
                        {/*<h5>*/}
                        {/*{props.propertyDetails[0] ? props.propertyDetails[0].address : null}*/}
                        {/*</h5>*/}
                    </div>
                </div>
                <div className="tenantnme dtlContProp">
                    <h3>Total Units</h3>
                    <p>{props.propertyDetails[0] ? props.propertyDetails[0].unitCount : 0}</p>
                </div>
                <div className="tenantnme dtlContProp">
                    <h3>Available Units </h3>
                    <p>{props.propertyDetails[0] ? props.propertyDetails[0].availableunitCount : 0}</p>
                </div>
                <div className="tenantnme dtlContProp">
                    <h3>Tenants</h3>
                    <p>{props.propertyDetails[0] ? props.propertyDetails[0].tenantCount : 0}</p>
                </div>
                <div className="rentDtl dtlContProp">
                    <h3>Total Monthly Rent</h3>
                    <p>${props.propertyDetails[0] ? numberWithCommas(props.propertyDetails[0].totalMonthlyRent) : 0.00}</p>
                </div>
                <div className="rentDtl dtlContProp">
                    <h3>Total Available Rent</h3>
                    <p>${props.propertyDetails[0] ? numberWithCommas(props.propertyDetails[0].availableMonthlyRent) || 0.00 : 0.00}</p>
                </div>
                <div className="rentDtl dtlContProp">
                    <h3>Property Owner</h3>
                    <p>{props.propertyDetails[0] ? props.propertyDetails[0].propertyOwnerName[0] ?
                        props.propertyDetails[0].propertyOwnerName[0].entityName : null : "N/A"}</p>
                </div>
            </div>
        </div>
    )
}
export default PropertyHeader
