import * as actionType from "./constant";

const initialState = {
    sendMessageRes: [],
    sendChatMessageRes: [],
    messageList: [],
    chatMessageList: [],
    totalMessages: 0,
    messageDetails: null,
    tenantEmails: null,
    tenantPhoneNumbers: null,
    chatMessageDetails: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.SEND_MESSAGE_REQUEST:
            return {
                ...state
            };
        case actionType.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                sendMessageRes: action.data,
            };
        case actionType.SEND_MESSAGE_FAILURE:
            return {
                ...state,
                sendMessageRes: { success: false, ...action.error }
            };

        case actionType.SEND_CHAT_MESSAGE_REQUEST:
            return {
                ...state
            };
        case actionType.SEND_CHAT_MESSAGE_SUCCESS:
            return {
                ...state,
                sendChatMessageRes: action.data,
            };
        case actionType.SEND_CHAT_MESSAGE_FAILURE:
            return {
                ...state,
                sendChatMessageRes: { success: false, ...action.error }
            };

        case actionType.GET_MESSAGES_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                messageList: { success: true, list: [...action.data.data.messages] },
                totalMessages: action.data.data.totalMessages,
            };
        case actionType.GET_MESSAGES_FAILURE:
            return {
                ...state,
                messageList: { success: false, ...action.error }
            };

        case actionType.GET_CHAT_MESSAGE_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CHAT_MESSAGE_LIST_SUCCESS:
            return {
                ...state,
                chatMessageList: { success: true, chats: [...action.data.data.chats] },
            };
        case actionType.GET_CHAT_MESSAGE_LIST_FAILURE:
            return {
                ...state,
                chatMessageList: { success: false, ...action.error }
            };

        case actionType.GET_MESSAGES_DETAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MESSAGES_DETAILS_SUCCESS:
            return {
                ...state,
                messageDetails: action.data.data.messages,
            };
        case actionType.GET_MESSAGES_DETAILS_FAILURE:
            return {
                ...state,
                messageDetails: { success: false, ...action.error }
            };


        case actionType.GET_CHAT_DETAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CHAT_DETAILS_SUCCESS:
            return {
                ...state,
                chatMessageDetails: action.data.data.chats,
            };
        case actionType.GET_CHAT_DETAILS_FAILURE:
            return {
                ...state,
                chatMessageDetails: { success: false, ...action.error }
            };

        case actionType.GET_TENANT_EMAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_TENANT_EMAILS_SUCCESS:
            return {
                ...state,
                tenantEmails: action.data.data,
            };
        case actionType.GET_TENANT_EMAILS_FAILURE:
            return {
                ...state,
                tenantPhoneNumbers: { success: false, ...action.error }
            };

        case actionType.GET_TENANT_PHONE_NUMBERS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_TENANT_PHONE_NUMBERS_SUCCESS:
            return {
                ...state,
                tenantPhoneNumbers: action.data.data,
            };
        case actionType.GET_TENANT_PHONE_NUMBERS_FAILURE:
            return {
                ...state,
                tenantPhoneNumbers: { success: false, ...action.error }
            };
        case actionType.REFRESH_REQUEST:
            return {
                ...state
            };
        case actionType.REFRESH_SUCCESS:
            return {
                ...state,
                refeshData: action.data,
            };
        case actionType.REFRESH_FAILURE:
            return {
                ...state,
                tenantPhoneNumbers: { success: false, ...action.error }
            };

        case actionType.DELETE_INBOX_MAIL_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_INBOX_MAIL_SUCCESS:
            return {
                ...state,
                deleteMailDetails: action.data,
            };
        case actionType.DELETE_INBOX_MAIL_FAILURE:
            return {
                ...state,
                deleteMailDetails: { success: false, ...action.error }
            };
        case actionType.DELETE_MESSAGE_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                deleteMessageDetails: action.data,
            };
        case actionType.DELETE_MESSAGE_FAILURE:
            return {
                ...state,
                deleteMessageDetails: { success: false, ...action.error }
            };
        default:
            return state;
    }
};
