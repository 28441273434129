import {

    innagoAuthenticator
} from "../../services/authorizationService";

import innagoGateWay from "../../services/authorizationService";

import serviceEndpoints from "../../config/serviceEndpoints";

//add screening details
export const addScreening = value =>
innagoGateWay.post(serviceEndpoints.screeningEndpoints.screening, value );

//get all the proprty details
export const getProperties = params =>
innagoGateWay.get(serviceEndpoints.screeningEndpoints.property, { params });

//get all the proprty details
export const getPropertyDetail = id =>
innagoGateWay.get(`${serviceEndpoints.screeningEndpoints.property}/${id}` );

//make payment
export const makePayMent = value =>
    innagoGateWay.post(serviceEndpoints.screeningEndpoints.makePayment, value );

//get all the proprty details
export const getAllUnitProperties = id =>
    innagoGateWay.get(`${serviceEndpoints.screeningEndpoints.getVacantUnit}/${id}` );

//get all the proprty details
export const getVacantProperties = (params) =>
    innagoGateWay.get(serviceEndpoints.screeningEndpoints.getVacantProperty, {params});