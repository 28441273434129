import React from "react";
import action from "../action";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CreditCardInput from 'react-credit-card-input';
import * as validation from '../../Common/components/validation';
import toastr from '../../Common/components/Toaster';
import PersonalInformation from './personalInformation';
import IncomeDetails from './incomeDetails';
import DocumentUpload from './documentUpload';
import isImage from "is-image";
import {formatUsPhone} from '../../Common/components/utils';
import Confirm from "../../Common/components/Confirm";
import Modal from "../../Common/components/Modal";
import LoaderSpinner from '../../Common/components/Loader';
import CardModal from './cardDetails'


class Report extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        stateCode: '',
        postalCode: '',
        dob: '',
        address: '',
        city: '',
        socialSecurityNumber: '',
        currentEmployer: '',
        incomeStartdate: '',
        occupation: '',
        monthlyIncome: '',
        contactName: '',
        companyNumber: '',
        incomeAmount1: '',
        incomeAmount2: '',
        description1: '',
        description2: '',
        name: '',
        expiry: '',
        cvv: '',
        number: '',
        submitted: '',
        proprtyId: {},
        moveInDate: '',
        Properties: [],
        propertyId: this.props.match.params.id,
        submitSuccess: false,
        currentPage: 1,
        bankName: '',
        isSubmitted: false,
        reportDocuments: [],
        addedCharacter: false,
        disableSubmit: false,
        isConfirmModal: false,
        isLoader: false,
        isCardModal:false
    }

    componentDidMount = async () => {
        // const {
        //     match: {
        //         params: {id}
        //     }
        // } = this.props;
        let url_string = window.location.href
        let url = new URL(url_string);
        let id = url.searchParams.get("id");
        let email = url.searchParams.get("email");
        let phoneNumber = url.searchParams.get("phoneNumber");
        let firstName = url.searchParams.get("firstName");
        let lastName = url.searchParams.get("lastName");
        this.setState({
            email: email,
            phoneNumber: formatUsPhone(phoneNumber),
            firstName,
            lastName
        })
        await this.props.getPropertyDetail(id);
        this.setState({
            moveInDate: new Date()
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {screeningReducer: {screening}} = this.props;
        const {isLoader} = this.state;
        const {screeningReducer: {screening: prevScreening}} = prevProps;
        if (isLoader && prevScreening !== screening && !screening.success) {

            toastr.error(screening.message);
        } else if (isLoader && prevScreening != screening && screening.success) {

            toastr.success("Successfully added the data, we will contact you.");
        }
    }

    viewNextPage = () => {
        const {
            moveInDate, propertyId, firstName, lastName, email, phoneNumber, stateCode, address, city,
            dob, socialSecurityNumber, postalCode, currentPage, contactName, currentEmployer
        } = this.state;
        this.setState({
            isSubmitted: true
        })
        // this.setState({
        //     //isSubmitted: true,
        //     currentPage: currentPage + 1,
        //     // isSubmitted:false
        // })
        if (currentPage == 1 && firstName && lastName && email && phoneNumber && stateCode && address && postalCode && dob && socialSecurityNumber
            && validation.EMAIL(email, 'email') == true &&
            validation.LETTER_ONLY(firstName, 'firstName') == true &&
            validation.LETTER_ONLY(lastName, 'lastName') == true &&
            validation.SSN(socialSecurityNumber, 'socialSecurityNumber') == true &&
            validation.ZIP_CODE(postalCode, 'postalCode') == true &&
            validation.LETTER_ONLY(stateCode, 'stateCode') == true &&
            validation.PHONE_NO(phoneNumber.replace(/\D/g, ''), 'phoneNumber') == true) {
            this.setState({
                currentPage: currentPage + 1,
                isSubmitted: false
            })
        } else if (currentPage == 2) {
            if (contactName) {
                if (validation.LETTER_ONLY(contactName, 'contactName') == true) {
                    this.setState({
                        //isSubmitted: true,
                        currentPage: currentPage + 1,
                        isSubmitted: false
                    })
                }
            } else if (currentEmployer) {
                if (validation.LETTER_ONLY(currentEmployer, 'currentEmployer') == true) {
                    this.setState({
                        //isSubmitted: true,
                        currentPage: currentPage + 1,
                        isSubmitted: false
                    })
                }
            } else {
                this.setState({
                    //isSubmitted: true,
                    currentPage: currentPage + 1,
                    isSubmitted: false
                })
            }


        }

        // if(this.state.currentPage == 1 && moveInDate && propertyId && firstName && lastName && email && phoneNumber && stateCode && address
        //     && city && dob && socialSecurityNumber && postalCode){
        //         this.setState({
        //             //isSubmitted: true,
        //             currentPage: currentPage + 1
        //         })
        //     }
    }

    viewPreviousPage = () => {
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    promiseOptionsProperty = async val => {
        await this.props.getProperties({name: val, isIncluded: 1, status: 1})
        const {screeningReducer: {getProperty}} = this.props;
        const Properties = getProperty.success && getProperty.property.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        this.setState({Properties});
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }


    onChangeProperty = async (e) => {
        this.setState({propertyId: e});
    }

    onChangeIncomeStartdate = value => {
        this.setState({
            incomeStartdate: value
        })
    }

    validate = (value, name, type) => {
        const {isSubmitted} = this.state;
        if (isSubmitted) {
            switch (type) {

                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
                case "email":
                    return (
                        validation.EMAIL(value, name) != true ?
                            <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div> : null
                    );
                case "letter":
                    return (
                        validation.REQUIRED(value, name) == true ?
                            <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div> : null
                    )

                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) != true ?
                            <div
                                className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div> : null
                    )


                case "cardNumber":
                    return (
                        validation.CARD_NUMBER(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.CARD_NUMBER(value, name)}</div> : null
                    )

                case "code":
                    return (
                        validation.CODE(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.CODE(value, name)}</div> : null
                    )
                case "ssn":
                    return (
                        validation.SSN(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.SSN(value, name)}</div> : null
                    )


                case "zip":
                    return (
                        validation.ZIP_CODE(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.ZIP_CODE(value, name)}</div> : null
                    )
            }
        } else return null
    }

    handleChange = async (e) => {
        const {name, value} = e.target
        if (name == 'phoneNumber' || name == 'companyNumber') {
            if (isNaN(value)) {
                this.setState({
                    [name]: formatUsPhone(value),
                    addedCharacter: true
                });
            } else {
                this.setState({
                    [name]: formatUsPhone(value)
                });
            }

        } else {
            this.setState({
                [name]: value
            });
        }
    }

    onChangeDob = value => {
        this.setState({
            dob: value
        })
    }

    onChangeMoveInDate = value => {
        this.setState({
            moveInDate: value
        })
    }

    handleCardNumberChange = e => {
        this.setState({
            number: e.target.value
        })
    }

    handleCardExpiryChange = (e) => {
        this.setState({
            expiry: e.target.value
        })
    }

    handleCvvChange = (e) => {
        this.setState({
            cvv: e.target.value
        })
    }

    changeDocument = (e) => {
        const {files} = e.target;
        this.setState({
            reportDocuments: [
                ...this.state.reportDocuments,
                ...files
            ]
        })
    }

    handleChangeDate = (e) => {
        e.preventDefault();

    }

    handleSubmit = async () => {

        this.setState({
            isCardModal:true
        })



    }
    closeCardModal = () => {
        this.setState({
            isCardModal:false
        })
    }

    renderCardModal=()=>{
        return <CardModal
            onCancel={this.closeCardModal}
            openConfirm={this.openConfirm}
            handleCardNumberChange={this.handleCardNumberChange}
            handleCardExpiryChange={this.handleCardExpiryChange}
            handleCvvChange={this.handleCvvChange}
            state={this.state}
            validate={this.validate}
            handleChange={this.handleChange}
        />
    }

    openConfirm=()=>{
        this.setState({
            isCardModal:false
        })
        const {expiry, name, number, cvv} = this.state;

        if (name && expiry  && number) {
            this.setState({
                isConfirmModal: true
            })
        } else {
            toastr.error('Please Enter Card Details!!')
        }
    }

    closeConfirmModal = () => {
        this.setState({
            isConfirmModal: false,
            isLoader: false
        })
    }

    renderModalConfirm = () => {
        return <Confirm
            title='Pay Application Fee'
            message='Please make sure that all the above entered details are correct,You will be charged $35 from the above card.Do you wish to continue?'
            onCancel={this.closeConfirmModal}
            onConfirm={this.payFee}
        />
    }


    payFee = async () => {
        const {name, expiry, cvv, number} = this.state
        this.setState({
            isLoader: true,
            isConfirmModal: false
        })
        if (name && expiry  && number) {
            let paymentData = {
                cardName: name,
                cardNumber: number,
                cvv: cvv,
                expiryDate: expiry
            }
            await this.props.makePayMent(paymentData)
            if (this.props.screeningPayment.success) {
                toastr.success(this.props.screeningPayment.message)
                this.setState({
                    disableSubmit: false,
                })
                const {
                    moveInDate, propertyId, firstName, lastName, email, phoneNumber, expiry, name, number, cvv, bankName, stateCode, address, city,
                    dob, socialSecurityNumber, postalCode, currentEmployer, occupation, monthlyIncome, contactName, companyNumber, incomeAmount1,
                    incomeAmount2, description1, description2, reportDocuments, incomeStartdate
                } = this.state;

                //data to send for adding report
                const req = {
                    _id: this.props.reports && this.props.reports._id,
                    moveInDate:moment(new Date(moveInDate)).endOf('day').toISOString(),
                    propertyId: propertyId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumber ? phoneNumber.replace(/\D/g, '') : '',
                    stateCode: stateCode,
                    postalCode: postalCode,
                    dob: moment(new Date(dob)).endOf('day').toISOString(),
                    address: address,
                    city: city,
                    socialSecurityNumber: socialSecurityNumber,
                    cardDetails: {
                        expiry: expiry,
                        name: name,
                        number: number,
                        bankName: bankName,
                        cvv: cvv
                    },
                    currentEmployer: currentEmployer,
                    occupation: occupation,
                    monthlyIncome: monthlyIncome && monthlyIncome.toString().replace("$", "").replace(/,/g, ''),
                    incomeStartdate: moment(new Date(incomeStartdate)).format('YYYY-MM-DD'),
                    contactName: contactName,
                    companyNumber: companyNumber ? companyNumber.replace(/\D/g, '') : '',
                    otherIncome: [
                        {
                            incomeAmount: incomeAmount1 && incomeAmount1.toString().replace("$", "").replace(/,/g, ''),
                            description: description1
                        },
                        {
                            incomeAmount: incomeAmount2 && incomeAmount2.toString().replace("$", "").replace(/,/g, ''),
                            description: description2
                        }
                    ],
                    reportDocuments: reportDocuments
                }


                await this.props.addScreening(req);
                this.setState({isLoader: false});
                if (this.props.screeningReducer.screening.success) {

                    this.setState({
                        isSubmitted: false,
                        firstName: '',
                        lastName: '',
                        email: '',
                        phoneNumber: '',
                        stateCode: '',
                        postalCode: '',
                        dob: '',
                        address: '',
                        city: '',
                        socialSecurityNumber: '',
                        name: '',
                        expiry: '',
                        cvv: '',
                        number: '',
                        moveInDate: '',
                        propertyId: '',
                        disableSubmit: true,
                        currentEmployer: '',
                        incomeStartdate: '',
                        occupation: '',
                        monthlyIncome: '',
                        contactName: '',
                        companyNumber: '',
                        incomeAmount1: '',
                        incomeAmount2: '',
                        description1: '',
                        description2: '',
                        reportDocuments: [],

                    })

                }
            } else {
                toastr.error(this.props.screeningPayment.message)
                this.setState({
                    isLoader: false,
                    name: '',
                    expiry: '',
                    cvv: '',
                    number: '',
                })
            }
        }

    }


    handleAddressSearch = address => {
        if (address) {
            this.setState({
                address
            })


        } else {
            this.setState({
                address: ''
            })
        }
    }


    handleSelect = address => {
        if (address) {
            this.setState({
                address
            })

        }
    }

    render() {

        const {screeningReducer: {getProperty}} = this.props;
        const {
            match: {
                params: {id}
            }
        } = this.props;
        return (
            <React.Fragment>
                {this.state.isLoader && <LoaderSpinner/>}
                <nav className="navbar navbar-expand-lg col-md-12">
                    {this.state.isConfirmModal ?
                        <Modal body={this.renderModalConfirm()} onCancel={this.closeConfirmModal}/> : null}

                    {this.state.isCardModal ?
                        <Modal body={this.renderCardModal()} onCancel={this.closeCardModal}/> : null}
                    <div className="container">
                        <a className="navbar-brand logo " href="#"><img src="/images/logo.png"/></a>
                    </div>
                </nav>
                <div className="otrprop">
                    <div className="col-md-12 blue-navsection">
                        <div className="container">
                            <div className="outer-detail d-flex ">
                                <div className="cruzhed">
                                    <h3>Cruz Properties</h3>
                                </div>
                                <div className="cruzesub">
                                    <h3>Rental Applications</h3>
                                    <p>Powered by CruzRentals</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 property-image-sec">
                        <div className="container">
                            <div className="property-otr d-flex  col-md-10 m-auto p-0">
                                <div className="col-md-7 img-prop">
                                    <img src="/images/image-property.jpg"/>
                                </div>
                                <div className="col-md-5 prop-detail-content">
                                    <h3>{getProperty.property && getProperty.property.propertyName}</h3>
                                    <h4>{getProperty.property && getProperty.property.address}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-outer">
                        <div className="container">
                            <div className="col-md-10 m-auto p-0">
                                <div className="rental-application">
                                    <h3>Please fill the details below</h3>
                                    {this.state.currentPage === 1 ?
                                        <PersonalInformation
                                            status={this.props.status}
                                            onChangeDob={this.onChangeDob}
                                            onChangeMoveInDate={this.onChangeMoveInDate}
                                            state={this.state}
                                            validate={this.validate}
                                            handleChange={this.handleChange}
                                            handleChangeDate={this.handleChangeDate}
                                            isEmailSection={false}
                                            handleSelect={this.handleSelect}
                                            handleAddressSearch={this.handleAddressSearch}

                                        />
                                        : this.state.currentPage === 2 ?
                                            <IncomeDetails
                                                state={this.state}
                                                validate={this.validate}
                                                handleChange={this.handleChange}
                                                onChangeIncomeStartdate={this.onChangeIncomeStartdate}
                                                handleCardNumberChange={this.handleCardNumberChange}
                                                handleCardExpiryChange={this.handleCardExpiryChange}
                                                handleCvvChange={this.handleCvvChange}
                                            />
                                            :
                                            this.state.currentPage === 3 ?
                                                <DocumentUpload
                                                    state={this.state}
                                                    validate={this.validate}
                                                    handleChange={this.handleChange}
                                                    changeDocument={this.changeDocument}
                                                    onOpenConfirmation={this.onOpenConfirmation}
                                                    handleCardNumberChange={this.handleCardNumberChange}
                                                    handleCardExpiryChange={this.handleCardExpiryChange}
                                                    handleCvvChange={this.handleCvvChange}
                                                />
                                                : null}
                                    <div className="submit-btn">

                                        {this.state.currentPage != 1 ?
                                            <button type="button" className="btn btn-submit mr-2"
                                                    onClick={this.viewPreviousPage}>Previous</button> : null
                                        }
                                        {
                                            this.state.currentPage == 3 ?
                                                <button type="button" className="btn btn-submit"
                                                        onClick={this.handleSubmit}
                                                        disabled={this.state.disableSubmit}>Submit
                                                </button> : null
                                        }
                                        {this.state.currentPage != 3 ?
                                            <button type="button" className="btn btn-submit"
                                                    onClick={this.viewNextPage}>Next
                                            </button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const
    mapStateToProps = state => {
        const {screeningReducer} = state;
        return {
            screeningReducer,
            screeningPayment: screeningReducer.screeningPayment
        };
    };

export default connect(mapStateToProps, {...action})

(
    Report
)
;

