import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//get all the property details
export const getProperty = (params) =>
    innagoGateWay.get(serviceEndpoints.propertyEndpoints.property, { params });

//get property byID
export const getPropertyById = (id) =>
    innagoGateWay.get(`${serviceEndpoints.propertyEndpoints.property}/${id}`);

//get unit by property id
export const getUnitsByPropertyId = (id) =>
    innagoGateWay.get(`${serviceEndpoints.propertyEndpoints.unit}/${id}`);

//get all unit
export const getAllUnits = () =>
    innagoGateWay.get(serviceEndpoints.propertyEndpoints.unit);

//get unit details
export const getUnitDetails = (id) =>
    innagoGateWay.get(`${serviceEndpoints.propertyEndpoints.unitDetails}/${id}`);

//get all state
export const getState = () =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.state);

//get property feathure
export const getPropertyFeature = () =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.propertyfeature);

//get property type
export const getPropertyType = () =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.propertytype);

//get property type
export const getAmminities = () =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.amenity);

//get all expense category
export const getExpenseCategory = () =>
    innagoGateWay.get(serviceEndpoints.expenseEndpoints.category);

//get all business
export const getBusiness = () =>
    innagoGateWay.get(serviceEndpoints.expenseEndpoints.business);

//get all merchant
export const getMerchant = () =>
    innagoGateWay.get(serviceEndpoints.expenseEndpoints.merchant);

//get all invoice type
export const getInvoiceType = () =>
    innagoGateWay.get(serviceEndpoints.invoiceEndpoints.invoiceTypes);

//get all invoice type
export const getPaymentDetails = () =>
    innagoGateWay.get(serviceEndpoints.invoiceEndpoints.paymentTypes);

//get all revenue
export const getRevenue = (params) =>
    innagoGateWay.get(serviceEndpoints.revenueEndPoints.revenue, { params });

//get all the payment details
export const getPayment = (params) =>
    innagoGateWay.get(serviceEndpoints.settingsEndpoints.businessEntity, { params });

//get all Expense graph
export const getExpenseGraphDetails = (params) =>
    innagoGateWay.get(serviceEndpoints.expenseEndpoints.expenseGraph, { params });

//get all Expense Amount Details
export const getExpenseDetails = (params) =>
    innagoGateWay.get(serviceEndpoints.expenseEndpoints.expenseGraph, { params });

//get Property Details by tenant
export const getTenantProperty = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.getProperty, { params });

//get paid invoice by tenant
export const getTenantPaidInvoice = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.getPaidInvoice, { params });

//get unpaid invoice by tenant
export const getTenantUnPaidInvoice = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.getUnPaidInvoice, { params });


//get message by tenant
export const getTenantMessage = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.getMessages, { params });


//get maintenance by tenant
export const getTenantMaintenance = (params) =>
    innagoGateWay.get(serviceEndpoints.tenantEndpoints.getMaintenance, { params });


//get notification
export const getNotificationDetails = () =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.notification);

//update notification
export const updateNotificationDetails = (params) =>
    innagoGateWay.put(serviceEndpoints.userEndpoints.notification, params);

//get all users
export const getAllAdminUsers = () =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.adminUsers);


//advanced searchdata
export const advancedSerachData = (params) =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.advanceSearch, { params });


//get limit data
export const getLimitData = (params) =>
    innagoGateWay.get(serviceEndpoints.userEndpoints.pageLimit, { params });

//update limit data
export const updateLimitData = (data) =>
    innagoGateWay.put(serviceEndpoints.userEndpoints.pageLimit,data);

    //email Check
export const emailCheck = (params) =>
innagoGateWay.get(serviceEndpoints.userEndpoints.emailCheck,{params});