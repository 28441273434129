import React, {useState, useEffect} from "react";
import {MAIN_ROUTE} from "../../../../config/constants";
import {DebounceInput} from 'react-debounce-input';
import {connect} from "react-redux";
import commonAction from "../../../Common/action";
import action from "../../action";
import history from "../../../../config/history";
import LoaderSpinner from "../../../Common/components/Loader";



const PropertySideList = (props) => {
    const [propertySearch, setpropertySearch] = useState(null);
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        async function renderCall() {
            await props.getProperty();
        }

        renderCall();
        setTimeout(() => {
            setIsLoading(false)

        }, 1000)
    }, []);

    const handleSearch = async (e) => {
        const {name, value} = e.target;
        setpropertySearch(value)
        await props.getProperty({name: value});


    }

    const viewPropertyDetail = async (id) => {
        setIsLoading(true)
        history.push(`/PropertyDetails/${id}`)
        await props.getPropertyById(id);
        setTimeout(() => {
            setIsLoading(false)

        }, 1000)

    }

    const parseBackgoundImage = (image) => {
        if (image.length) {
            if (image[0].type && image[0].type == "zillo") {
                return image[0].path
            } else {
                return MAIN_ROUTE + image[0].path
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }

    return (
        <div className="tenantDtlLeft">
            {isLoading && <LoaderSpinner/>}
            <h3>Property Details</h3>
            <div className="searchOtr">
                <DebounceInput
                    type="text"
                    name="salaryPerHourMin"
                    value={propertySearch}
                    className="form-control"
                    placeholder="Search Property"
                    debounceTimeout={1000}
                    onChange={e => handleSearch(e)}
                />
                <img src="/images/search.png" className="searchIcn"/>
            </div>
            <div className="tenantList">
                <ul>
                    {props.commonReducer.Property && props.commonReducer.Property.propertyList && props.commonReducer.Property.propertyList.length ? props.commonReducer.Property.propertyList.map((property, key) => {
                        const imgPath = property.propertyImageId && property.propertyImageId[0] && property.propertyImageId[0].images && property.propertyImageId[0].images.find(i => i.isDefault === true)
                        let path = imgPath && imgPath.path
                        let tempPath = null
                        if (property.propertyImage && property.propertyImage[0]) {
                            tempPath = property.propertyImage[0].path
                        }
                        return < li onClick={() => viewPropertyDetail(property._id)}
                                    className={props.currentBusinessId === property._id ? "activeTab" : null}>
                            < div className="profImg">
                                < img
                                    src={(property.propertyImage[0]) ? parseBackgoundImage(property.propertyImage) : "/images/def_property.png"}
                                />
                            </div>
                            <div className="imgDtlSec">
                                <h4>{property.propertyName}</h4>
                                {/*<p className="place">{property.address}</p>*/}
                            </div>
                        </li>
                    }) : null}
                </ul>
                <div className="col-md-12 text-right tenantnoneBtn">
                    <button className="btn btn-primary btnTenClose viewAllTenant">
                        Cancel
                    </button>
                    <button className="btn btn-primary btnTensub viewAllTenant">
                        Submit
                    </button>
                </div>
            </div>
        </div>


    )

}

function mapStateToProps(state) {
    const {propertyReducer, commonReducer} = state;
    return {
        property: propertyReducer.Property,
        commonReducer
    };
}

export default connect(mapStateToProps, {...commonAction, ...action})(PropertySideList);
