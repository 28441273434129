import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import toastr from "../../Common/components/Toaster";
import action from "../action";
import commonAction from '../../Common/action'
import AsyncSelect from 'react-select/async';
import * as validation from "../../Common/components/validation";
import {formatUsPhone} from "../../Common/components/utils";

class RentalApplication extends React.Component {
    state = {
        propertyId: '',
        // property: {},
        invitaionProcedure: null,
        Properties: [],
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        isSubmitted: false,
        addedCharacter: false

    }

    componentDidMount = async () => {
        await this.props.getProperty({isIncluded: 1});
        const {commonReducer: {Property}} = this.props;
        const Properties = Property.propertyList && Object.values(Property.propertyList).map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        this.setState({Properties});
    }


    promiseOptionsProperty = async val => {
        await this.props.getProperty({name: val, isIncluded: 1})
        const {commonReducer: {Property}} = this.props;
        const Properties = Property.success && Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    onChangeProperty = async (e) => {
        this.setState({propertyId: e.value, property: e});

    }


    cancelInvitation = () => {
        this.setState({
            propertyId: '',
            property: {},
            invitaionProcedure: null,
            Properties: [],
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            isSubmitted: false
        })
        this.props.onCancel()
    }

    validate = (value, name, type) => {
        if (this.state.isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
                case "email":
                    return (
                        validation.EMAIL(value, name) != true ?
                            <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div> : null
                    );
                case "letter":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div> : null
                    )

                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) != true ?
                            <div
                                className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div> : null
                    )

            }
        } else return null;
    };

    handleInvoiceCheckBox = (e) => {
        if (e.target.checked) {
            this.setState({
                invitaionProcedure: e.target.value
            })
        } else {
            this.setState({
                invitaionProcedure: null
            })
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target
        if (name == 'phoneNumber') {
            if (isNaN(value)) {
                this.setState({
                    [name]: formatUsPhone(value),
                    addedCharacter: true
                });
            } else {
                this.setState({
                    [name]: formatUsPhone(value),
                });
            }

        } else {
            this.setState({
                [name]: value
            })
        }

    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isSubmitted && prevProps.invite != this.props.invite) {
            if (this.state.phoneNumber) {

                if (this.props.invite.data.chat.status == "success") {

                    toastr.success("Message sent successfully")
                } else if (this.props.invite.data.chat.status == "failed") {
                    toastr.error(`Message sending failed for number: ${this.state.phoneNumber} `)
                }
            }
            if(this.state.email){
                if (this.props.invite.success) {
                    toastr.success("Email sent successfully!!")
                } else {
                    toastr.error("Failed to sent email!!")
                }
            }

            this.setState({
                propertyId: '',
                property: {},
                invitaionProcedure: null,
                Properties: [],
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                isSubmitted: false
            })

        }
    }
    inviteScreeningData = async () => {
        this.setState({
            isSubmitted: true
        })
        const {propertyId, firstName, lastName, phoneNumber, email, invitaionProcedure} = this.state
        let reqData = {
            id: propertyId,
            email,
            phoneNumber: phoneNumber ? phoneNumber.replace(/\D/g, '') : '',
            firstName,
            lastName
        }
        let firstNameError = firstName ? validation.LETTER_ONLY(firstName, 'firstName') == true ? false : true : false
        let lastNameError = lastName ? validation.LETTER_ONLY(firstName, 'lastName') == true ? false : true : false

        if (propertyId && invitaionProcedure) {
            if (invitaionProcedure == 1 && email && validation.EMAIL(email, 'email') == true && !firstNameError && !lastNameError) {
                await this.props.inviteScreening(reqData)
                this.setState({
                    isSubmitted: false,

                })
                this.props.onCancel()
            } else if (invitaionProcedure == 3 && email && validation.EMAIL(email, 'email') == true && phoneNumber && !firstNameError && !lastNameError) {
                await this.props.inviteScreening(reqData)
                this.setState({
                    isSubmitted: false,

                })
                this.props.onCancel()
            } else if (invitaionProcedure == 2 && phoneNumber && !firstNameError && !lastNameError) {
                await this.props.inviteScreening(reqData)
                this.setState({
                    isSubmitted: false
                })
                this.props.onCancel()
            }


        }
    }

    render() {
        const {property, Properties, propertyId, firstName, lastName, phoneNumber, email, invitaionProcedure, addedCharacter} = this.state
        return (
            <div className="modal  addTenant addInvoiceModal addpsw" id="modalId" style={{display: 'block'}}>
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">INVITE A RENTER TO APPLY</h5>
                            <button type="button" className="close" onClick={this.props.onCancel}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="clientInfo">
                                <div className="formSection">
                                    <div className="col-md-12 formInp ">
                                        <label>Property</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={Properties}
                                            loadOptions={this.promiseOptionsProperty}
                                            name="propertyId"
                                            value={property}
                                            onChange={this.onChangeProperty}
                                            placeholder={"Search Property"}

                                        />
                                        {this.validate(propertyId, "Property", "required")}


                                    </div>
                                    <div className="col-md-12 formInp checkBx p-0 flex-column">
                                        <label className="col-md-12 pl-2">Invite them to apply by:</label>
                                        <div className="col-md-12 otrTenantCheck pl-2 ">

                                            <div className="otrRec">
                                                <label className="containerCheck">Email
                                                    <input
                                                        type="checkbox"
                                                        name="invitaionProcedure"
                                                        value={1}
                                                        onChange={(e) => this.handleInvoiceCheckBox(e)}
                                                        checked={invitaionProcedure == 1 ? true : false}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                            <div className="otrProcess">
                                                <label className="containerCheck">Text
                                                    <input
                                                        type="checkbox"
                                                        value={2}
                                                        name="invitaionProcedure"
                                                        onChange={(e) => this.handleInvoiceCheckBox(e)}
                                                        checked={invitaionProcedure == 2 ? true : false}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="otrProcess">
                                                <label className="containerCheck">Text & Email
                                                    <input
                                                        type="checkbox"
                                                        name="invitaionProcedure"
                                                        value={3}
                                                        onChange={(e) => this.handleInvoiceCheckBox(e)}
                                                        checked={invitaionProcedure == 3 ? true : false}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {invitaionProcedure == 1 || invitaionProcedure == 3 ? <div className="outrForm">
                                        <div className="col-md-12 formInp">
                                            <label>Renter's Email</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                name="email"
                                                value={email}
                                                onChange={this.handleChange}
                                            />
                                            {this.validate(email, "Email", "required")}
                                            {email && this.validate(email, "Email", "email")}
                                        </div>

                                    </div> : null}
                                    {this.validate(invitaionProcedure, "Any One of the Method", "required")}
                                    <div className="col-md-12 FormrightOutr">
                                        <div className="row">
                                            <div className="col-md-6 formInp formSecInp">
                                                <label>First Name(Optional)</label>
                                                <input type="text" className="form-control"
                                                       placeholder="Enter First Name" name={"firstName"}
                                                       onChange={this.handleChange}
                                                       value={firstName}
                                                       maxLength="25"/>
                                                {firstName && this.validate(firstName, "First Name", "letter")}
                                            </div>
                                            <div className="col-md-6 formInp formSecInp">
                                                <label>Last Name(Optional)</label>
                                                <input type="text" className="form-control"
                                                       placeholder="Enter Last Name" value={lastName}
                                                       name={"lastName"} onChange={this.handleChange}
                                                       maxLength="20"/>
                                                {lastName && this.validate(lastName, "Last Name", "letter")}
                                            </div>
                                        </div>
                                    </div>
                                    {invitaionProcedure == 2 || invitaionProcedure == 3 ? <div className="outrForm">
                                        <div className="col-md-12 formInp">
                                            <label>Contact Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Contact-Number"
                                                name="phoneNumber"
                                                onChange={this.handleChange}
                                                value={phoneNumber}
                                                maxLength={addedCharacter ? "12" : "10"}
                                            />
                                            {this.validate(phoneNumber, "Contact Number", "required")}
                                            {phoneNumber && this.validate(phoneNumber, "Contact Number", "phone")}
                                        </div>

                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn  btn-grey" onClick={this.cancelInvitation}>CANCEL
                            </button>
                            <button type="button" className="btn btn-primary btnSubmit"
                                    onClick={this.inviteScreeningData}>Invite
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const
    mapStateToProps = state => {
        const {screeningReducer, commonReducer, reportReducer} = state;
        return {
            screeningReducer,
            screeningPayment: screeningReducer.screeningPayment,
            commonReducer,
            invite: reportReducer.invite
        };
    };

export default connect(mapStateToProps, {...action, ...commonAction})

(
    RentalApplication
)
;