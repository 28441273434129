import React, { useState, useEffect } from "react";
import SignaturePad from 'react-signature-pad-wrapper'

class UploadModal extends React.Component {
    handleSign = () => {
        let signature = this.refs.mySignature;
        let base = signature.toDataURL()
        this.props.handleSign(base, signature)

    }

    render() {

        return (
            <div className="modal  addTenant addInvoiceModal addpsw addsign" id="modalId" style={{ display: 'block' }}>
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Signature</h5>
                            <button type="button" className="close" onClick={this.props.onCancel}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="clientInfo">
                                <div className="formSection">
                                    <div className="outrForm">
                                        <div className="col-md-12 formInp">
                                            <label class="custom-file-label" for="inputGroupFile01">
                                                <img src="/images/Attach.png" alt="" /> <span>Attach Signature</span>
                                                    <input type="file" id="inputGroupFile01"  onChange={(e) => this.props.handleImage(e)} name="expenseFile" multiple="" /></label>
                                                    <span className="spanOr">OR</span>
                                                    <label>Draw</label>
                                                    <SignaturePad
                                                        clearButton="true"
                                                        // options={{ minWidth: 5, maxWidth: 10, border: 1 }}
                                                        ref="mySignature"
                                                    />
                                        </div>
                                                <button onClick={this.handleSign}>Submit</button>


                                    </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {/* <button type="button" className="btn btn-primary btnSubmit"  onClick={resetPassword}>Submit</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }
            }
            
export default UploadModal;