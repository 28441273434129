import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CreditCardInput from 'react-credit-card-input';
import * as validation from '../../Common/components/validation';
import { formatUsPhone } from '../../Common/components/utils';
import toastr from '../../Common/components/Toaster';
import LoaderSpinner from "../../Common/components/Loader";
import usePrevious from "../../Common/components/prevProps";
import CommonDatePicker from "../../Common/components/CommonDatePicker";

class Report extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        stateCode: '',
        postalCode: '',
        dob: '',
        address: '',
        city: '',
        socialSecurityNumber: '',
        name: '',
        expiry: '',
        cvv: '',
        number: '',
        modal: false,
        submitted: '',
        addedCharacter: false,
    }

    componentDidMount() {
        const { status, reports } = this.props;
        if (status !== 'add' && reports) {
            this.setState({
                _id: this.props.reports && this.props.reports._id,
                firstName: reports.firstName,
                lastName: reports.lastName,
                email: reports.email,
                phoneNumber: formatUsPhone(reports.phone),
                stateCode: reports.stateCode,
                postalCode: reports.postalCode,
                dob: reports.dob,
                address: reports.address,
                city: reports.city,
                socialSecurityNumber: reports.socialSecurityNumber,
                name: reports.cardDetails[0] && reports.cardDetails[0].name,
                expiry: reports.cardDetails[0] && reports.cardDetails[0].expiry,
                cvv: reports.cardDetails[0] && reports.cardDetails[0].cvv,
                number: reports.cardDetails[0] && reports.cardDetails[0].number
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { reportReducer: { report } } = this.props;
        const { isSubmitted } = this.state;
        const { reportReducer: { report: prevReport } } = prevProps;
        if (isSubmitted && prevReport !== report && !report.success) {

            toastr.error(report.message);
        } else if (isSubmitted && prevReport != report && report.success) {

            toastr.success(report.message);
        }
    }

    validate = (value, name, type) => {
        const { isSubmitted } = this.state;
        if (isSubmitted) {
            switch (type) {

                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
                case "email":
                    return (
                        validation.EMAIL(value, name) != true ?
                            <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div> : null
                    );
                case "letter":
                    return (
                        validation.REQUIRED(value, name) == true ?
                            <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div> : null
                    )

                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) != true ?
                            <div
                                className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div> : null
                    )


                case "cardNumber":
                    return (
                        validation.CARD_NUMBER(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.CARD_NUMBER(value, name)}</div> : null
                    )

                case "code":
                    return (
                        validation.CODE(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.CODE(value, name)}</div> : null
                    )
                case "ssn":
                    return (
                        validation.SSN(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.SSN(value, name)}</div> : null
                    )


                case "zip":
                    return (
                        validation.ZIP_CODE(value, name) != true ?
                            <div
                                className={"errorMsg"}>{validation.ZIP_CODE(value, name)}</div> : null
                    )
            }
        } else return null
    }


    handleChange = async (e) => {
        const { name, value } = e.target
        if (name == 'phoneNumber') {
            if (isNaN(value)) {
                this.setState({
                    [name]: formatUsPhone(value),
                    addedCharacter: true
                });
            } else {
                this.setState({
                    [name]: formatUsPhone(value)
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    onChangeDob = value => {
        this.setState({
            dob: value
        })
    }

    handleCardNumberChange = e => {
        this.setState({
            number: e.target.value
        })
    }

    handleCardExpiryChange = (e) => {
        this.setState({
            expiry: e.target.value
        })
    }

    handleCvvChange = (e) => {
        this.setState({
            cvv: e.target.value
        })
    }


    handleSubmit = async () => {
        this.setState({ isSubmitted: true });

        const { firstName, lastName, email, phoneNumber, expiry, name, number, cvv, stateCode, address, city, dob, socialSecurityNumber, postalCode } = this.state;
        const { status } = this.props;
        //data to send for adding report
        const req = {
            _id: this.props.reports && this.props.reports._id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phoneNumber ? phoneNumber.replace(/\D/g, '') : '',
            stateCode: stateCode,
            postalCode: postalCode,
            dob: moment(new Date(dob)).endOf('day').toISOString(),
            address: address,
            city: city,
            socialSecurityNumber: socialSecurityNumber,
            cardDetails: {
                expiry: expiry,
                name: name,
                number: number ? number.replace(/ +/g, "") : null,
                cvv: cvv
            },
            reportDocuments: []
        }

        if (status === 'add') {

            if (firstName && lastName && email && phoneNumber && stateCode && postalCode && dob && city && socialSecurityNumber
                && expiry && name  && number && validation.EMAIL(email, 'email') == true &&
                validation.LETTER_ONLY(firstName, 'firstName') == true &&
                validation.LETTER_ONLY(lastName, 'lastName') == true &&
                validation.LETTER_ONLY(name, 'cardName') == true &&
                validation.SSN(socialSecurityNumber, 'socialSecurityNumber') == true &&
                validation.ZIP_CODE(postalCode, 'postalCode') == true &&
                validation.LETTER_ONLY(stateCode, 'stateCode') == true &&
                validation.PHONE_NO(phoneNumber.replace(/\D/g, ''), 'phoneNumber') == true
            ) {

                this.setState({ submitSuccess: true })
                await this.props.addReport(req);

                if (this.props.reportReducer.report.success) {
                    await this.props.getReport({ limit: 10, offset: 0 });
                    this.props.onCancel();
                }
            }
        }
        else {
            if (firstName && lastName && email && phoneNumber && stateCode && postalCode && dob && city && socialSecurityNumber
               && validation.EMAIL(email, 'email') == true &&
                validation.LETTER_ONLY(firstName, 'firstName') == true &&
                validation.LETTER_ONLY(lastName, 'lastName') == true &&
                validation.LETTER_ONLY(name, 'cardName') == true &&
                validation.SSN(socialSecurityNumber, 'socialSecurityNumber') == true &&
                validation.ZIP_CODE(postalCode, 'postalCode') == true &&
                validation.LETTER_ONLY(stateCode, 'stateCode') == true &&
                validation.PHONE_NO(phoneNumber.replace(/\D/g, ''), 'phoneNumber') == true
            ) {

                this.setState({ submitSuccess: true })
                await this.props.addReport(req);

                if (this.props.reportReducer.report.success) {
                    await this.props.getReport({ limit: 10, offset: 0 });
                    this.props.onCancel();
                }
            }
        }
    }

    render() {
        const { status } = this.props;
        return (
            <div className="modal addTenant" role="dialog" id="modalId" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {status === 'edit' ? `Edit ` : status === 'add' ? `Add New` : `View `} Screening</h5>
                            <button type="button" className="close" onClick={() => this.props.onCancel()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="clientInfo mt-2">
                                <h3>Client Information</h3>
                                <div className="formSection">
                                    <div className="row outrForm p-0 m-0">
                                        <div className="col-md-12 FormrightOutr">
                                            <div className="row">
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter First Name"
                                                        name={"firstName"}
                                                        onChange={this.handleChange}
                                                        value={this.state.firstName}
                                                        maxLength="25"
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.firstName, "First Name", "required")}
                                                    {this.state.firstName && this.validate(this.state.firstName, "First Name", "letter")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Last Name"
                                                        value={this.state.lastName}
                                                        name={"lastName"}
                                                        onChange={this.handleChange}
                                                        maxLength="20"
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.lastName, "Last Name", "required")}
                                                    {this.state.lastName && this.validate(this.state.lastName, "Last Name", "letter")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        value={this.state.email}
                                                        name={"email"}
                                                        onChange={this.handleChange}
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.email, "Email Address", "required")}
                                                    {this.state.email && this.validate(this.state.email, "Email", "email")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>DOB</label>
                                                    {/*<DatePicker*/}
                                                    {/*    selected={this.state.dob ?  moment(new Date(this.state.dob)).format('MM/DD/YYYY') : null}*/}
                                                    {/*    onChange={this.onChangeDob}*/}
                                                    {/*    value={this.state.dob && moment(new Date(this.state.dob)).format('MM/DD/YYYY')}*/}
                                                    {/*    placeholderText={"MM/DD/YYYY"}*/}
                                                    {/*    tabIndex={1}*/}
                                                    {/*    className="form-control "*/}
                                                    {/*    name="dob"*/}
                                                    {/*    placeholder="DOB"*/}
                                                    {/*    maxDate={new Date()}*/}
                                                    {/*    disabled={this.props.status === 'view'}*/}
                                                    {/*    showYearDropdown='true'*/}
                                                    {/*/>*/}
                                                    <CommonDatePicker
                                                        selected={this.state.dob ? new Date(this.state.dob) : null}
                                                        name="dob"
                                                        placeholderText={"MM/DD/YYYY"}
                                                        disableClock={true}
                                                        className="form-control"
                                                        value={this.state.dob && moment(new Date(this.state.dob)).format('MM/DD/YYYY')}
                                                        onChange={this.onChangeDob}
                                                        maxDate={new Date()}
                                                        showYearDropdown='true'
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.dob, "DOB", "required")}
                                                    {this.state.dob && this.validate(this.state.dob, "DOB", "dob")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>Phone</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Phone"
                                                        value={this.state.phoneNumber}
                                                        onChange={this.handleChange}
                                                        name={"phoneNumber"}
                                                        maxLength={this.state.addedCharacter ? "12" : "10"} />
                                                    {this.validate(this.state.phoneNumber, "Phone Number", "required")}
                                                    {this.state.phoneNumber && this.validate(this.state.phoneNumber, "Phone", "phone")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>Social Security Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Security Number"
                                                        value={this.state.socialSecurityNumber}
                                                        maxLength="9"
                                                        name={"socialSecurityNumber"}
                                                        onChange={this.handleChange}
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.socialSecurityNumber, "Security Number", "required")}
                                                    {this.state.socialSecurityNumber && this.validate(this.state.socialSecurityNumber, "Security Number", "ssn")}
                                                </div>
                                                <div className="col-md-12 formInp formSecInp">
                                                    <label>Address</label>
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Enter address"
                                                        value={this.state.address}
                                                        name={"address"}
                                                        onChange={this.handleChange}
                                                        disabled={this.props.status === 'view'}
                                                    ></textarea>
                                                    {this.validate(this.state.address, "Address", "required")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter City"
                                                        value={this.state.city}
                                                        name={"city"}
                                                        onChange={this.handleChange}
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.city, "City", "required")}
                                                    {this.state.city && this.validate(this.state.city, "City", "city")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>State </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter State "
                                                        value={this.state.stateCode}
                                                        name={"stateCode"}
                                                        onChange={this.handleChange}
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.stateCode, "State", "required")}
                                                    {this.state.stateCode && this.validate(this.state.stateCode, "State", "letter")}
                                                </div>
                                                <div className="col-md-4 formInp formSecInp">
                                                    <label>Zip Code</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Zip Code"
                                                        value={this.state.postalCode}
                                                        maxLength="5"
                                                        name={"postalCode"}
                                                        onChange={this.handleChange}
                                                        disabled={this.props.status === 'view'}
                                                    />
                                                    {this.validate(this.state.postalCode, "Zip Code", "required")}
                                                    {this.state.postalCode && this.validate(this.state.postalCode, "Zip Code", "zip")}
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex propertySec p-0">
                                {this.props.status === 'add' ? <div className="clientInfo cardOtr">
                                    <h3>Credit card Information</h3>
                                    <div className="formSection">
                                        <div className="row outrForm p-0 m-0">
                                            <div className="col-md-4 formInp">
                                                <label>Name on Card</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Name on Card"
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                    maxLength="25"
                                                    disabled={this.props.status === 'view'}
                                                />
                                                {this.validate(this.state.name, "Name", "required")}
                                                {this.state.name && this.validate(this.state.name, "Name", "letter")}
                                            </div>

                                            <div className="col-md-6 formInp">
                                                <label>Card Details</label>
                                                <div className="creditcardOtr">
                                                    <CreditCardInput
                                                        cardNumberInputProps={{
                                                            value: this.state.number,
                                                            onChange: this.props.status !== 'view' && this.handleCardNumberChange
                                                        }}
                                                        cardExpiryInputProps={{
                                                            value: this.state.expiry,
                                                            onChange: this.props.status !== 'view' && this.handleCardExpiryChange
                                                        }}
                                                        cardCVCInputProps={{
                                                            value: this.state.cvv,
                                                            onChange: this.props.status !== 'view' && this.handleCvvChange
                                                        }}
                                                        disabled={this.props.status === 'view'}
                                                        // dangerTextClassName={"errorMsg"}
                                                        invalidClassName={null}
                                                    />
                                                    {this.state.isSubmitted && !this.state.cardNumber && !this.state.expiryDate  &&
                                                        <div className={"errorMsg"}>*Enter Card Details</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </div>
                        {this.props.status !== 'view' && <div className="modal-footer">
                            <button type="button" className="btn btn-primary btnSubmit mb-2"
                                onClick={this.handleSubmit}>Submit
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { reportReducer } = state;
    return {
        reportReducer
    };
};

export default connect(mapStateToProps, { ...action })(Report);

