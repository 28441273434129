import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import action from "../../action";
import moment from 'moment';
import Pagination from "../../../Common/components/pagination";
import LoaderSpinner from "../../../Common/components/Loader";

class OtherTickets extends React.Component {

    state = {
        params: {
            limit: 5,
            offset: 0,
            id: null,
        },

        currentPage: 1,
        isLoder: true
    }

    componentDidMount = async () => {
        let tempParams = this.state.params

        tempParams.id = this.props.maintenanceId
        this.setState({
            id: this.props.maintenanceId,
        })
        await this.props.getTenantMaintenanceDetails(tempParams);
        this.setState({
            isLoder: false
        })
    }

    // useEffect(() => {
    //     let tempParams=params
    //     tempParams.id=props.maintenanceId
    //     setParams(tempParams)
    //     // renderCall(tempParams)
    //     // async function renderCall(tempParams) {
    //         props.getTenantMaintenanceDetails(tempParams);
    //     // }
    //
    //
    // }, [])

    renderStatus = (status) => {
        switch (status) {
            case  0:
                return "Closed"
            case  1:
                return "Open"
            case 2:
                return "Answered"
            case 3:
                return "Customer-Replay"
            case 4:
                return "On-Hold"
            case 5:
                return "In-Progress"
            case 6:
                return "Scheduled"

            default:
                return "N/A"
        }
    }

    onPageChanged = async page => {
        let data = this.state.params
        let offset = ((page - 1) * this.state.limit) || 0;
        data.offset = offset;
        this.setState({
            params: data,
            currentPage:page
        })
        await this.props.getTenantMaintenanceDetails(data);
    }

    render() {


        return (


            <div className="support-otr">
                {this.state.isLoder ? <LoaderSpinner/> : null}
                <div className="innago-table table-main p-0 mb-3 otherticketTable">
                    <div className="table-responsive">
                        <table className="table-gap table-td">
                            <tbody>
                            <tr className="table-head">
                                <th>Date Submitted</th>
                                <th>Department</th>
                                <th>Subject</th>
                                <th>Status</th>
                                <th>Last Reply</th>
                            </tr>
                            {this.props.maintenanceTenant && this.props.maintenanceTenant.maintenance && this.props.maintenanceTenant.maintenance.maintenance.length ?
                                this.props.maintenanceTenant.maintenance.maintenance.map((main, key) => {
                                    return <tr key={key}>
                                        <td>
                                            {moment(main.createdAt).format('MMM Do YYYY')}
                                            <span
                                                className="ml-2"> {moment(main.createdAt).format('h:mm A')}</span>


                                        </td>
                                        <td>
                                            Support
                                        </td>
                                        <td>
                                            {main.title}
                                        </td>
                                        <td>{this.renderStatus(main.status)}</td>
                                        <td> {moment(main.lastMessageCreatedAt).format('MMM Do YYYY, h:mm A')}</td>
                                    </tr>
                                }) : <tr>
                                    <td colSpan={5} className="text-center no_value_table"><i
                                        className="far fa-thumbs-down"></i> No Data Found!!!
                                    </td>
                                </tr>}


                            </tbody>
                        </table>
                    </div>
                    <div className="paginationSec">
                        <Pagination
                            totalRecords={this.props.maintenanceTenant && this.props.maintenanceTenant.maintenance && this.props.maintenanceTenant.maintenance.maintenanceListCount}
                            pageLimit={this.state.params.limit}
                            pageNeighbours={1}
                            currentPage={this.state.currentPage}
                            onPageChanged={this.onPageChanged}
                        />
                    </div>
                </div>
                {/*<div className="centerSec">*/}
                {/*<button className="btn addresponse"><img src="/images/Save.png"/> Save Changes</button>*/}
                {/*<button className="btn cancelchanges"> Cancel Changes</button>*/}

                {/*</div>*/}
            </div>

        )

    }
}

const mapStateToProps = state => {
    const {maintenanceReducer} = state;
    return {
        maintenanceTenant: maintenanceReducer.maintenanceTenant
    };
};

export default connect(mapStateToProps, {
    ...action
})(OtherTickets);
