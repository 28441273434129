import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { numberWithCommas } from "../../Common/components/utils";
import NumberFormat from 'react-number-format';
import CommonDatePicker from "../../Common/components/CommonDatePicker";

const InvoiceSplitUp = props => {

    const { paymentMethods, amount, title, onChange, paymentTypeId, autoProcessing, invoiceTypeId, InvoiceTypeList, invoiceError, index } = props;

    const govtTypeId1 = props.govtType && props.govtType.length && props.govtType[0]._id;




    return (
        <div className="row outrForm p-0 m-0 invSecondOtr">

            <div className="col-md-4 formInp">
                <label>Invoice Title</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Invoice Title"
                    name="title"
                    onChange={(e) => onChange(e, index)}
                    value={title}
                    disabled={props.invoice == "invoice1" || props.invoice == "invoice2"}
                />
                {props.validate(title, "Invoice Title", "required")}
                {title && props.validate(title, "Invoice Title", "title")}
            </div>
            <div className="col-md-4 formInp">
                <label>Invoice Type</label>
                <div className="selctOtr">
                    <select className="form-control"
                        name="invoiceTypeId"
                        onChange={(e) => props.onChange(e, index)}
                    >
                        <option value="">Select Type</option>
                        {
                            InvoiceTypeList.map((e, i) => {
                                return <option
                                    value={e._id}
                                    key={i}
                                    selected={InvoiceTypeList.length && e._id == invoiceTypeId}
                                >
                                    {e.name}
                                </option>
                            })
                        }
                    </select>
                    {props.validate(invoiceTypeId, "Invoice Type", "required")}
                </div>
            </div>
            <div className="col-md-4 formInp">
                <label>Invoice Amount</label>
                <NumberFormat
                    thousandSeparator={true}
                    prefix={'$'}
                    className="form-control"
                    name="amount"
                    onChange={(e) => onChange(e, index)}
                    value={amount}
                />
                {/*<input
                    type="text"
                    className="form-control"
                    placeholder="Invoice Amount"
                    name="amount"
                    onChange={onChange}
                    //value={numberWithCommas(amount)}
                    value={amount}
                />*/}
                {props.validate(amount, "Invoice Amount", "required")}
                {/*{amount && props.validate(amount, "Amount", "number")}*/}
                <div className={"errorMsg"}>{props.amtMsg}</div>
            </div>
            <div className="col-md-4 formInp">
                <label>Invoice Date</label>
                <CommonDatePicker
                    name="startDate"
                    placeholderText={"MM/DD/YYYY"}
                    disableClock={true}
                    className="form-control"
                    value={props.startDate && moment(new Date(props.startDate)).format('MM/DD/YYYY')}
                    onChange={(date) => props.onChangeStartDate(date, index)}
                    // minDate={new Date()}
                    showYearDropdown='true'
                />
            </div>
            <div className="col-md-4 formInp">
                <label>Due Date</label>
                <CommonDatePicker
                    name="dueDate"
                    placeholderText={"MM/DD/YYYY"}
                    disableClock={true}
                    className="form-control"
                    value={props.dueDate && moment(new Date(props.dueDate)).format('MM/DD/YYYY')}
                    onChange={(date) => props.onChangeDueDate(date, index)}
                    minDate={props.startDate ? new Date(props.startDate) : null}
                    showYearDropdown='true'
                />
                <div className={"errorMsg"}>{invoiceError}</div>
            </div>
            <div className="col-md-4 formInp">
                <label>Payment Type</label>
                <div className="selctOtr">
                    <select className="form-control"
                        name="paymentTypeId"
                        onChange={(e) => onChange(e, index)}
                    >
                        <option value="">Select Type</option>
                        {
                            paymentMethods && paymentMethods.map((e, i) => {
                                return <option
                                    value={e._id}
                                    key={i}
                                    selected={paymentMethods && e._id == paymentTypeId}
                                >
                                    {e.name}
                                </option>
                            })
                        }
                    </select>
                    {props.validate(paymentTypeId, "Payment Type", "required")}
                </div>
            </div>

            {

                (props.govtType && govtTypeId1 && (props.invoiceTypeId != govtTypeId1)) &&
                <div className="col-md-12 formInp checkBx p-0">
                    <div className="col-md-9 otrTenantCheck">
                        <div className="otrRec">
                            <label className="containerCheck" style={{ cursor: autoProcessing ? 'no-drop' : '' }}>Recurring
                                <input
                                    type="checkbox"
                                    name="isRecurring"
                                    onChange={(e) => props.handleInvoiceCheckBox(e, index)}
                                    checked={props.govt ? props.isRecurring : props.isRecurring}
                                    disabled={autoProcessing}
                                // readOnly={autoProcessing}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="otrProcess">
                            <label className="containerCheck">Auto Processing
                                <input
                                    type="checkbox"
                                    name="autoProcessing"
                                    onChange={(e) => props.handleInvoiceCheckBox(e, index, paymentTypeId)}
                                    checked={autoProcessing}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default InvoiceSplitUp;
