import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import moment from 'moment'
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';
import AddTemplate from "./addTemplateModal";
import ViewTemplate from './templateEdit';
import usePrevious from "../../Common/components/prevProps";


const BusinessEntity = (props) => {

    const [isSubmitted, setIsSubmitted] = useState(true);
    const [params, setParams] = useState({ limit: 10, offset: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [businessEntity, setBusinessEntity] = useState('');
    const [templateModal, setTemplateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [templateData, setTemplateData] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const [id, setId] = useState(null);
    const [templateSubmit, setTemplateSubmit] = useState(false);

    const prevTemplateEdit = usePrevious(props.templateEdit);

    useEffect(() => {
        async function renderCall() {
            await props.getPayment(params);
            await props.getTemplates(params)
        }
        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }, []);

    useEffect(() => {
        if (templateSubmit && prevTemplateEdit != props.templateEdit) {
            if (props.templateEdit.success) {
                toastr.success("Template Updated Successfully!!")
            }
            else {
                toastr.error("Error while updating template")
            }
        }
    }, [prevTemplateEdit, props.templateEdit])


    const { settingsReducer: { templateDetails } } = props;


    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setCurrentPage(page)
        await props.getPayment(data);
    }

    const openModalTemplate = (e) => {
        setTemplateModal(true);
        setTemplateData(e);
    }

    const closeModalTemplate = () => {
        setTemplateModal(false)
    }

    const renderModalTemplate = () => {
        return <ViewTemplate
            onCancel={closeModalTemplate}
            businessEntity={businessEntity}
            isTemplateView={true}
            addEditedTemplateData={addEditedTemplateData}
            templateData={templateData.templateContent}
            templateTitle={templateData.templateName}
            isEdit={isEdit}
            editId={id}

        />
    };

    const addEditedTemplateData = async (data) => {
        setTemplateModal(false)
        setTemplateSubmit(true)
        await props.updateTemplate(data)
        await props.getTemplates({ limit: 10, offset: 0 })
        setTemplateSubmit(false)


    }

   

    const openEditModalTemplate = (e) => {
        setId(e._id)
        setTemplateModal(true);
        setTemplateData(e);
        setisEdit(true)
    }

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Template'
            message='Do you want to delete this Template?'
            onCancel={closeDeleteModal}
            onConfirm={deleteTemplate}
        />
    }

    const deleteTemplate = async () => {
        setIsSubmitted(true);
        setDeleteModal(false);
        await props.deleteTemplate(deleteId);
        setTimeout(() => {
            setIsSubmitted(false);
            props.getTemplates({ limit: 10, offset: 0 });
        }, 500);
    }

    const { commonReducer: { paymentList } } = props;

    return (
        <>
            {isSubmitted && <LoaderSpinner />}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {templateModal ? <Modal body={renderModalTemplate()} onCancel={closeModalTemplate} /> : null}
            <div className="table-responsive">
                <table className="table-gap table-td">
                    <tbody>
                        <tr className="table-head">
                            <th>File Name</th>
                            <th>Created Date</th>
                            <th class="widact">Action</th>
                        </tr>
                        {templateDetails && templateDetails.length ? templateDetails.map((e, i) => {
                            return <tr key={i}>

                                <td>
                                    <p>{e.templateName}</p>
                                </td>
                                <td>
                                    <p>{moment(e.createdAt).format('MM/DD/YYYY')}</p>
                                </td>

                                <td>
                                    <div class="dropdown moveDrop editDetlPrp drop-arw">
                                        <i class="fas fa-eye mr-1" title="View" onClick={() => openModalTemplate(e)}></i>
                                        <i className="fas fa-pencil-alt mr-1" title="edit" onClick={() => openEditModalTemplate(e)}></i>
                                        <i className="fa fa-trash" title="Delete" aria-hidden="true" onClick={() => openDeleteModal(e._id)}></i>
                                    </div>
                                </td>
                            </tr>
                        }) : <tr>
                                <td colSpan={4} className={"text-center no_value_table"}><i className="far fa-thumbs-down"></i>No Data Found!!!</td>
                            </tr>}

                    </tbody>
                </table>
            </div>
            <div className="paginationSec">
                {paymentList.count && paymentList.count ? <Pagination
                    totalRecords={paymentList.count}
                    pageLimit={params.limit}
                    pageNeighbours={1}
                    currentPage={currentPage}
                    onPageChanged={onPageChanged}
                /> : null}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { settingsReducer, commonReducer } = state;
    return {
        settingsReducer, commonReducer,
        templateEdit: settingsReducer.templateEdit

    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(BusinessEntity);
