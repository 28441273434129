import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//add invoice details
export const addInvoice = (value) =>
    innagoGateWay.post(serviceEndpoints.invoiceEndpoints.invoice,value);

//update invoice details
export const updateInvoice = (value) =>
    innagoGateWay.put(serviceEndpoints.invoiceEndpoints.invoice,value);

//get invoice details
export const getInvoice = (params) =>
    innagoGateWay.get(serviceEndpoints.invoiceEndpoints.invoice,{params});

//get TENANT invoice details
export const getTenantInvoices = (params) =>
    innagoGateWay.get(serviceEndpoints.invoiceEndpoints.invoiceTenants,{params});

 //get TENANT invoice details
export const getTenantInvoiceTypes = (params) =>
    innagoGateWay.get(serviceEndpoints.invoiceEndpoints.invoiceTenantsTypes,{params});

//pay invoice
export const payInvoice = (value) =>
    innagoGateWay.post(serviceEndpoints.invoiceEndpoints.payInvoice,value);

//pay invoice
export const deleteInvoice = (data) =>
    innagoGateWay.delete(serviceEndpoints.invoiceEndpoints.invoice,{data});

//sendSms
export const sendSms = (value) =>
    innagoGateWay.post(serviceEndpoints.invoiceEndpoints.sendSms,value);

    //sendSms FOR each invoice
export const sendSmsEachInvoice = (value) =>
    innagoGateWay.post(serviceEndpoints.invoiceEndpoints.sendSmsEachInvoice,value);

//merge invoice
export const mergeInvoice = (value) =>
    innagoGateWay.put(serviceEndpoints.invoiceEndpoints.mergeInvoice,value);


//get invoice details by id
export const getInvoiceById = (id) =>
    innagoGateWay.get(`${serviceEndpoints.invoiceEndpoints.invoiceById}/${id}`);
