

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CreditCardInput from 'react-credit-card-input';
import NumberFormat from 'react-number-format';
import CommonDatePicker from "../../Common/components/CommonDatePicker";


const ClientInfoAddTenant = props => {

    const { state, validate, handleChange, onChangeIncomeStartdate,handleCardNumberChange, handleCardExpiryChange, handleCvvChange } = props;

    return (
        <>
            <div className="clientInfo">
                <h3>Income Details</h3>
                <div className="form-section-dtl">
                    <div className="col-md-12 p-0">
                        <div className="row p-0">
                            <div className="col-md-4 form-input">
                                <label>Current Employer</label>
                                <input type="text" className="form-control"
                                    placeholder="Enter Current Employer" name="currentEmployer"
                                    onChange={handleChange} value={state.currentEmployer} maxLength="25" />
                                    {state.currentEmployer && validate(state.currentEmployer, "Current Employer", "letter")}
                            </div>
                            <div className="col-md-4 form-input">
                                <label>Position/Occupation</label>
                                <input type="text" className="form-control"
                                    placeholder="Enter Position" value={state.occupation}
                                    name={"occupation"} onChange={handleChange} maxLength="30" />
                            </div>
                            <div className="col-md-4 form-input">
                                <label>Start Date</label>

                                <CommonDatePicker
                                    value={state.incomeStartdate ? new Date(state.incomeStartdate):null}
                                    onChange={onChangeIncomeStartdate}
                                    className="form-control "
                                    name="incomeStartdate"
                                    placeholderText="MM/DD/YYYY"
                                    maxDate={new Date()}
                                    showYearDropdown='true'
                                />
                            </div>
                            <div className="col-md-4 form-input">
                                <label>Monthly Income</label>


                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    className="form-control"
                                    placeholder="Enter monthly income"
                                    name="monthlyIncome"
                                    onChange={handleChange}
                                    value={state.monthlyIncome}
                                />
                                    {/*{state.monthlyIncome && validate(state.monthlyIncome, "Monthly Income", "number")}*/}
                            </div>
                            <div className="col-md-4 form-input">
                                <label>Contact Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter contact name"
                                    value={state.contactName}
                                    onChange={handleChange}
                                    name={"contactName"}
                                    maxLength="10" />
                                {state.contactName && validate(state.contactName, "Contact Name", "letter")}
                            </div>
                            <div className="col-md-4 form-input">
                                <label>Contact Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Phone"
                                    value={state.companyNumber}
                                    onChange={handleChange}
                                    name={"companyNumber"}
                                    maxLength={state.addedCharacter ?"12":"10"} />
                                {state.companyNumber && validate(state.companyNumber, "Contact Number", "phone")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clientInfo">
                <h3>Other Income</h3>
                <div className="form-section-dtl">
                    <div className="col-md-12 p-0">
                        <div className="row p-0">
                            <div className="col-md-6 form-input">
                                <label>Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Description"
                                    value={state.description1}
                                    onChange={handleChange}
                                    name={"description1"} />

                            </div>
                            <div className="col-md-6 form-input">
                                <label>Amount</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    name="incomeAmount1"
                                    onChange={handleChange}
                                    value={state.incomeAmount1}
                                />
                                {/*<input*/}
                                {/*    type="text"*/}
                                {/*    className="form-control"*/}
                                {/*    placeholder="Enter Amount"*/}
                                {/*    value={state.incomeAmount1}*/}
                                {/*    onChange={handleChange}*/}
                                {/*    name={"incomeAmount1"} />*/}
                                {/*    {state.incomeAmount2 && validate(state.incomeAmount2, "Amount", "number")}*/}
                            </div>
                            <div className="col-md-6 form-input">
                                <label>Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Description"
                                    value={state.description2}
                                    onChange={handleChange}
                                    name={"description2"} />

                            </div>
                            <div className="col-md-6 form-input">
                                <label>Amount</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    name="incomeAmount2"
                                    onChange={handleChange}
                                    value={state.incomeAmount2}
                                />

                                    {/*{state.incomeAmount2 && validate(state.incomeAmount2, "Amount", "")}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientInfoAddTenant;
