//get maintenance
export const GET_MAINTENANCE_REQUEST = 'GET_MAINTENANCE_REQUEST ';
export const GET_MAINTENANCE_SUCCESS = 'GET_MAINTENANCE_SUCCESS ';
export const GET_MAINTENANCE_FAILURE = 'GET_MAINTENANCE_FAILURE ';

//add maintenance
export const ADD_MAINTENANCE_REQUEST = 'ADD_MAINTENANCE_REQUEST ';
export const ADD_MAINTENANCE_SUCCESS = 'ADD_MAINTENANCE_SUCCESS ';
export const ADD_MAINTENANCE_FAILURE = 'ADD_MAINTENANCE_FAILURE ';


//delete maintenance
export const DELETE_MAINTENANCE_REQUEST = 'DELETE_MAINTENANCE_REQUEST ';
export const DELETE_MAINTENANCE_SUCCESS = 'DELETE_MAINTENANCE_SUCCESS ';
export const DELETE_MAINTENANCE_FAILURE = 'DELETE_MAINTENANCE_FAILURE ';

//close maintenance
export const CLOSE_MAINTENANCE_REQUEST = 'CLOSE_MAINTENANCE_REQUEST ';
export const CLOSE_MAINTENANCE_SUCCESS = 'CLOSE_MAINTENANCE_SUCCESS ';
export const CLOSE_MAINTENANCE_FAILURE = 'CLOSE_MAINTENANCE_FAILURE ';

//VIEW maintenance
export const VIEW_MAINTENANCE_REQUEST = 'VIEW_MAINTENANCE_REQUEST ';
export const VIEW_MAINTENANCE_SUCCESS = 'VIEW_MAINTENANCE_SUCCESS ';
export const VIEW_MAINTENANCE_FAILURE = 'VIEW_MAINTENANCE_FAILURE ';

//GET category maintenance
export const GET_MAINTENANCE_CATEGORY_REQUEST = 'GET_MAINTENANCE_CATEGORY_REQUEST ';
export const GET_MAINTENANCE_CATEGORY_SUCCESS = 'GET_MAINTENANCE_CATEGORY_SUCCESS ';
export const GET_MAINTENANCE_CATEGORY_FAILURE = 'GET_MAINTENANCE_CATEGORY_FAILURE ';

//add maintenance
export const MAINTENANCE_ADD_REQUEST = 'MAINTENANCE_ADD_REQUEST ';
export const MAINTENANCE_ADD_SUCCESS = 'MAINTENANCE_ADD_SUCCESS ';
export const MAINTENANCE_ADD_FAILURE = 'MAINTENANCE_ADD_FAILURE ';

//add maintenance
export const ADD_MAINTENANCE_TICKET_REQUEST = 'ADD_MAINTENANCE_TICKET_REQUEST ';
export const ADD_MAINTENANCE_TICKET_SUCCESS = 'ADD_MAINTENANCE_TICKET_SUCCESS ';
export const ADD_MAINTENANCE_TICKET_FAILURE = 'ADD_MAINTENANCE_TICKET_FAILURE ';

//Get tenant maintenance
export const GET_TENANT_MAINTENANCE_REQUEST = 'GET_TENANT_MAINTENANCE_REQUEST ';
export const GET_TENANT_MAINTENANCE_SUCCESS = 'GET_TENANT_MAINTENANCE_SUCCESS ';
export const GET_TENANT_MAINTENANCE_FAILURE = 'GET_TENANT_MAINTENANCE_FAILURE ';

//Get maintenance by id
export const GET_MAINTENANCE_DETAILS_REQUEST = 'GET_MAINTENANCE_DETAILS_REQUEST ';
export const GET_MAINTENANCE_DETAILS_SUCCESS = 'GET_MAINTENANCE_DETAILS_SUCCESS ';
export const GET_MAINTENANCE_DETAILS_FAILURE = 'GET_MAINTENANCE_DETAILS_FAILURE ';

//Get advanced category
export const GET_ADVANCED_CATEGORY_REQUEST = 'GET_ADVANCED_CATEGORY_REQUEST ';
export const GET_ADVANCED_CATEGORY_SUCCESS = 'GET_ADVANCED_CATEGORY_SUCCESS ';
export const GET_ADVANCED_CATEGORY_FAILURE = 'GET_ADVANCED_CATEGORY_FAILURE ';


//delete note
export const EDIT_NOTE_REQUEST = 'EDIT_NOTE_REQUEST ';
export const EDIT_NOTE_SUCCESS = 'EDIT_NOTE_SUCCESS ';
export const EDIT_NOTE_FAILURE = 'EDIT_NOTE_FAILURE ';


export const status = [ {value: 2, name: "Answered"},
    {value: 0, name: "Closed"},
    {value: 3, name: "Customer-Reply"},
    {value: 5, name: "In-Progress"},
    {value: 1, name: "Open"},
    {value: 4, name: "On-Hold"},
    {value: 6, name: "Scheduled"},
]

