import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import {ToastContainer, toast} from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toastr from '../Common/components/Toaster'
import LoaderSpinner from "../Common/components/Loader";
import usePrevious from "../Common/components/prevProps";
import Pagination from '../Common/components/pagination';
import commonAction from "../Common/action";
import PropertyList from "./components/PropertyList";
import action from "./action";
import Modal from "../Common/components/Modal";
import AddPropertyModal from "./components/AddPropertyModal";
import history from '../../config/history'

const Index = props => {
    const [propertyModal, setPropertyModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [params, setParams] = useState({
        limit: 15, offset: 0, isIncluded: null, paymentId: null
    })
    const [currentPage, setcurrentPage] = useState(1);
    const [unitDetails, setUnitDetails] = useState({
        unitName: "",
        bedRoom: "",
        bathroom: "",
        squareFeet: "",
        features: [],
        amenities: [],
        deposit: "",
        monthlyRent: "",
    });
    const prevUser = usePrevious(props.property);
    const prevLimit = usePrevious(props.commonReducer.limitData);


    const {
        match: {
            params: { section }
        }
    } = props;

    useEffect(() => {
        props.getLimitData()
    }, []);



    useEffect(() => {
        if (prevLimit != props.commonReducer.limitData) {
            const { limitData } = props.commonReducer
            if (limitData) {
                let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 1)

                async function renderCall(tempParams) {
                    await props.getProperty(tempParams);
                    await props.getPayment()
                }
                let tempParams = params
                tempParams.limit = limitCount ? limitCount.count : 15
                setParams(tempParams)
                if (section) {
                    if (section === 'Vacant') {
                        setParams({ ...params, isIncluded: 1, limit: tempParams.limit, isSelected: 1 })
                        tempParams.isIncluded = 1
                        tempParams.isSelected = 1
                    } else if (section === 'Occupied') {
                        setParams({ ...params, isIncluded: 0, isSelected: 0, limit: tempParams.limit })
                        tempParams.isIncluded = 0
                        tempParams.isSelected = 0
                    }

                    props.getProperty(tempParams);
                    props.getPayment()
                }
                else {
                    renderCall(tempParams);
                }

                setTimeout(() => {
                    setIsLoading(false)
                }, 1000)
            }
        }

    }, [prevLimit, props.commonReducer.limitData]);


    // useEffect(() => {
    //     async function renderCall(tempParams) {
    //         await props.getProperty(tempParams);
    //         await props.getPayment()


    //     }


    //     let tempParams = params

    //     if (section) {
    //         console.log("section:debugg", section)

    //         if (section === 'Vacant') {
    //             setParams({ ...params, isIncluded: 1, isSelected: 1 })
    //             tempParams.isIncluded = 1
    //             tempParams.isSelected = 1
    //         } else if (section === 'Occupied') {
    //             setParams({ ...params, isIncluded: 0, isSelected: 0 })
    //             tempParams.isIncluded = 0
    //             tempParams.isSelected = 0
    //         }
    //         props.getProperty(tempParams);
    //     }
    //     else {
    //         renderCall(tempParams);
    //     }

    //     setTimeout(() => {
    //         setIsLoading(false)
    //     }, 500)
    // }, []);


    useEffect(() => {
        if (isSubmitted && prevUser !== props.property && !props.property.success) {
            toastr.error(props.property.message);
        } else if (
            isSubmitted &&
            prevUser !== props.property &&
            props.property.success
        ) {
            toastr.success("Property created successfully");
        }
    }, [prevUser, props.property]);

    const openModal = (e) => {
        e.preventDefault()
        // if (props.profileDetail.profileDetail && props.profileDetail.profileDetail.admin.gmail) {
        setPropertyModal(true);
        // } else {
        //     toastr.error('Please Add a google account to add property!!')
        // }

    };

    const closeModal = () => {
        setPropertyModal(false);
    };

    const addProperty = async propertyDetails => {
        closeModal();
        setIsSubmitted(true);
        await props.addProperty(propertyDetails);
        await props.getProperty(params);
        setIsSubmitted(false);
    };

    const onPageChanged = async page => {
        let data = { ...params }

        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;

        if (section === 'Vacant') {
            data.isIncluded = 1
            data.isSelected = 1
        } else if (section === 'Occupied') {
            data.isIncluded = 0
            data.isSelected = 0
        }
        setParams(data)
        setcurrentPage(page)
        await props.getProperty(data);
    }

    const renderModal = () => {
        return (
            // <AddProperty
            <AddPropertyModal
                addPropertyDetails={addProperty}
                onCancel={closeModal}
                setIsSubmitted={setIsSubmitted}
                setUnitDetails={setUnitDetails}
                unitDetails={unitDetails}
                renderLoader={isLoading}
            />
        );
    };

    const handleFilter = (e) => {
        const { name, value } = e.target
        setIsLoading(true)
        let tempParams = params

        if (parseInt(value) === 1) {
            history.push('/Properties/Vacant')
        } else if (parseInt(value) === 0) {
            history.push('/Properties/Occupied')
        } else {
            history.push('/Properties/All')
        }
        let tempValue = parseInt(value);
        if (value === 'All') {
            tempValue = null;
        }
        tempParams.offset = 0;
        tempParams.limit = params.limit;
        tempParams.isIncluded = tempValue;
        tempParams.isSelected = value;
        setTimeout(() => {
            props.getProperty(tempParams);
            setIsLoading(false)
        }, 1500)


        setcurrentPage(1)
    }


    const handleChange = async (e) => {
        setIsLoading(true)
        const { name, value } = e.target
        let tempParams = params
        tempParams.paymentId = value
        setParams(tempParams)
        await props.getProperty(tempParams);
        setIsLoading(false)

    }

    const handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = params
        tempParams.limit = value
        tempParams.offset = 0
        setParams(tempParams)
        await props.updateLimitData({ type: 1, count: value });
        await props.getProperty(tempParams);
    }

    const { totalPropertyCount, totalCount, activeUnitCount, inactiveUnitCount, totalunitListCount } = props.commonReducer.Property

    const renderCount = () => {
        switch (params.isIncluded) {
            case 0: return <button className="btn-dvd blu-bg">
                Total Occupied Unit<strong>{activeUnitCount}
                </strong></button>
            case 1: return <button className="btn-dvd blu-bg">
                Total Vacant Unit<strong>{inactiveUnitCount}
                </strong></button>
            default: return <button className="btn-dvd blu-bg">
                Total Unit<strong>{totalunitListCount}
                </strong></button>

        }
    }

    const role = props.profileDetails && props.profileDetails.profileDetail && props.profileDetails.profileDetail.admin.role;

    return (
        <div className="tenants-otr">
            {/* <ToastContainer/> */}
            {(isSubmitted || isLoading) && props.commonReducer.Property.propertyList && <LoaderSpinner />}
            {propertyModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            <div className="innago-table-top prop-table-top tenants-inner ">
                <div className="lft">
                    <h1>PROPERTIES</h1>
                </div>
                <div className={"top-bar"}>
                    <div className="rgt">
                        {/* <button className="btn-dvd blu-bg">Total Unit<strong>
                        {params.isIncluded == 1 ? inactiveUnitCount :
                            params.isIncluded == 0 ? activeUnitCount : totalunitListCount ? totalunitListCount : 0}
                    </strong></button> */}
                        {renderCount()}
                    </div>
                    <div className="col-md-3 formsectionPadding mx-sl">
                        <div className="selctOtr">
                            <select className="form-control " name={"isIncluded"} onChange={handleFilter}
                                value={params.isSelected}>
                                <option value="" disabled selected hidden>Select Status</option>
                                <option value="All">All</option>
                                <option value={0}>Occupied</option>
                                <option value={1}>Vacant</option>
                            </select>
                        </div>
                    </div>



                    <div className="col-md-3 formsectionPadding mx-sl1">
                        <div className="selctOtr">
                            <select
                                className="form-control "
                                name="propertyOwner"
                                value={params.paymentId}
                                onChange={e => handleChange(e)}
                            >
                                <option value={""}>Select Property Owner</option>
                                {
                                    props.commonReducer.paymentList.payment && props.commonReducer.paymentList.payment.map((pmnt, index) => {
                                        return <option key={index}
                                            value={pmnt._id}>{pmnt.entityName}</option>
                                    })
                                }
                                {/* <option value='aptoProperties'>Apto Properties</option> */}
                            </select>

                        </div>
                    </div>
                    <div className="col-md-1 formsectionPadding">
                        <div className="selctOtr">
                            {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                            <select className="form-control" value={params.limit} name={"limit"}
                                onChange={handleChangeLimit}>
                                <option value={15}>15</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>
                    {role == "maintenance" ? null : <div className="rgt">
                        <button className="add " data-toggle="modal" data-target="#addTenant"
                            // disabled={props.profileDetail.profileDetail && props.profileDetail.profileDetail.admin.gmail ? false : true}
                            onClick={openModal}>
                            <svg>
                                <use xlinkHref="/images/svg-sprite.svg#ic-add" />
                            </svg>
                        Add New Property
                    </button>
                    </div>
                    }
                </div>
            </div>

            <div
                className={`innago-table propTable ${props.commonReducer.Property && props.commonReducer.Property.propertyList
                    && props.commonReducer.Property.propertyList.length || role == "maintenance" ? "remarw" : "addarw"}`}>
                <PropertyList Property={props.commonReducer.Property} params={params} openModal={openModal} role={role} />
                <div className="paginationSec">
                    {totalPropertyCount ?
                        <Pagination
                            totalRecords={totalPropertyCount}
                            pageLimit={params.limit}
                            pageNeighbours={2}
                            currentPage={currentPage}
                            onPageChanged={onPageChanged}
                        /> : null}
                </div>
            </div>

        </div>

    );
};

function mapStateToProps(state) {
    const { propertyReducer, commonReducer, profileReducer } = state;
    return {
        property: propertyReducer.Property,
        commonReducer,
        profileDetails: profileReducer.profileDetail,
        profileReducer


    };
}

export default connect(mapStateToProps, { ...commonAction, ...action })(Index);

