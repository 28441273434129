import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const LoaderSpinner = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 2000,
        backgroundColor: "rgba(255,255,255,.7)",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        left: 0,
        top: 0
      }}
    >
      <Loader
        type="Bars"
        color="#007bff"
        height={100}
        width={100}
        style={{ margin: "auto" }}
      />
    </div>
  );
};

export default LoaderSpinner;
