
//update password
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST ';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS ';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE ';

//GET_PROFILE by id
export const GET_PROFILE_ID_REQUEST = 'GET_PROFILE_ID_REQUEST ';
export const GET_PROFILE_ID_SUCCESS = 'GET_PROFILE_ID_SUCCESS ';
export const GET_PROFILE_ID_FAILURE = 'GET_PROFILE_ID_FAILURE ';

//Update profile
export const ADD_PROFILE_REQUEST = 'ADD_PROFILE_REQUEST ';
export const ADD_PROFILE_SUCCESS = 'ADD_PROFILE_SUCCESS ';
export const ADD_PROFILE_FAILURE = 'ADD_PROFILE_FAILURE ';

export const UPDATE_TENANT_PASSWORD_REQUEST = 'UPDATE_TENANT_PASSWORD_REQUEST ';
export const UPDATE_TENANT_PASSWORD_SUCCESS = 'UPDATE_TENANT_PASSWORD_SUCCESS ';
export const UPDATE_TENANT_PASSWORD_FAILURE = 'UPDATE_TENANT_PASSWORD_FAILURE ';