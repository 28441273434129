import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//update board Data
export const updateBoardData = (boardData) =>innagoGateWay.put(serviceEndpoints.boardEndpoints.updateBoardData,{boardData});
export const getBoardData = () =>innagoGateWay.get(serviceEndpoints.boardEndpoints.getBoardData);
export const getBoardLabels = () =>innagoGateWay.get(serviceEndpoints.boardEndpoints.getBoardLabels);
export const addBoardLabels = (labelData) =>innagoGateWay.post(serviceEndpoints.boardEndpoints.addBoardLabels, labelData);
export const updateBoardLabels = (labelData) =>innagoGateWay.put(`${serviceEndpoints.boardEndpoints.updateBoardLabels}/${labelData._id}`, {name: labelData.name, color: labelData.color});
export const deleteBoardLabels = (labelId) =>innagoGateWay.delete(`${serviceEndpoints.boardEndpoints.deleteBoardLabels}/${labelId}`);
export const getTenants = () =>innagoGateWay.get(serviceEndpoints.boardEndpoints.getTenants);
export const getCardActivity = (cardId) =>innagoGateWay.get(`${serviceEndpoints.boardEndpoints.getCardActivity}/${cardId}`);
export const addCardActivity = (activityData) =>innagoGateWay.post(serviceEndpoints.boardEndpoints.addCardActivity, activityData);
export const getCardAttachments = (cardId) =>innagoGateWay.get(`${serviceEndpoints.boardEndpoints.getCardAttachments}/${cardId}`);
export const addCardAttachments = (attachmentsData) =>innagoGateWay.put(serviceEndpoints.boardEndpoints.addCardAttachments, attachmentsData);
export const deleteCardAttachments = (cardId, attachmentId) =>innagoGateWay.put(`${serviceEndpoints.boardEndpoints.deleteCardAttachments}/${cardId}?fileid=${attachmentId}`);
export const addCustomField = (customFieldData) =>innagoGateWay.post(serviceEndpoints.boardEndpoints.addCustomField, customFieldData);
export const getCustomFields = () =>innagoGateWay.get(serviceEndpoints.boardEndpoints.getCustomFields);
export const updateCustomField = (customFieldData) =>innagoGateWay.put(`${serviceEndpoints.boardEndpoints.updateCustomField}/${customFieldData._id}`, {name: customFieldData.name, type: customFieldData.type, options: customFieldData.options});
export const deleteCustomField = (customFieldId) =>innagoGateWay.delete(`${serviceEndpoints.boardEndpoints.deleteCustomField}/${customFieldId}`);
