import React, { useState, useEffect } from "react";
import action from "../action";
import tenantAction from "../../Tenants/action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import AddCreditCard from './addCreditCard';
import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';
import history from '../../../config/history';
import BankAccount from './BankAccount';

const Cards = (props) => {

    const [cardModal, setCardModal] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true);
    const [id, setId] = useState('');
    const [deleteId, setDeleteId] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);

    useEffect(() => {
        async function renderCall() {
            const { profileDetail: { profileDetail } } = props;
            if (profileDetail) {
                await props.getTenantId(profileDetail.admin.tenantId._id)
                setId(profileDetail.admin.tenantId._id)
            }
        }
        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }, [props.profileDetail.profileDetail]);

    const { card, deleteCards } = props;

    useEffect(() => {
        //For setting primary card
        if (submit && !card.success) {
            toastr.error(card.message)
        } else if (submit && card.success) {
            toastr.success("Successfully set as primary");
        }
    }, [card]);

    useEffect(() => {
        //For deleting card
        if (deleteSubmitted && !deleteCards.success) {
            toastr.error(deleteCards.message)
        } else if (deleteSubmitted && deleteCards.success) {
            toastr.success("Successfully deleted the card");
        }
    }, [deleteCards]);

    const openModalCard = () => {
        setCardModal(true);
    }

    const closeModalCard = () => {
        setCardModal(false)
    }

    const renderModalCard = () => {
        return <AddCreditCard
            onCancel={closeModalCard}
            id={id}
        />
    };

    const setPrimary = async (cardId) => {
        setSubmit(true);
        const req = {
            tenantId: id,
            cardId: cardId,
            defaultCard: "1"
        }
        await props.addCardDetails(req);
        await props.getTenantId(id)
        setTimeout(() => {
            setSubmit(false);
        }, 1000)
    }

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Card'
            message='Do you want to delete this card ?'
            onCancel={closeDeleteModal}
            onConfirm={onDelete}
        />
    }

    const onDelete = async () => {
        setDeleteSubmitted(true);
        closeDeleteModal();
        await props.deleteCard({ tenantId: id, cardId: deleteId });
        setTimeout(() => {
            props.getTenantId(id)
            setDeleteSubmitted(false);
        }, 1000);
    }

    const { tenantReducer: { tenantsById: { tenant } } } = props;

    return (
        <div className="tenants-otr">
            {(deleteSubmitted || isSubmitted) && <LoaderSpinner />}
            {cardModal ? <Modal body={renderModalCard()} onCancel={closeModalCard} /> : null}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            <div className="innago-table-top">
                <div className="lft">
                    <h1>CARDS</h1>
                </div>
            </div>
            <div className={`credit-card-outr ${tenant && tenant.tenant.cardDetails && tenant.tenant.cardDetails.length ? "remarw" : "addarw"}`}>
                {/* <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" onClick={() => history.push('/Accounts/Cards')}>
                        <a className={props.match.params.section === 'Cards' ? "nav-link active": "nav-link"}
                        role="tab" aria-selected="true">Cards </a></li>
                    <li className="nav-item" onClick={() => history.push('/Accounts/Banks')}>
                    <a className={props.match.params.section === 'Banks' ? "nav-link active": "nav-link"}
                        role="tab" aria-selected="false">Bank</a></li>
                </ul> */}
                {props.match.params.section === 'Cards' ?
                    <div className="credit-card-wrapper ">
                        <div className="credit-header">
                            <h3>Credit / Debit Cards</h3>
                            {tenant && tenant.tenant.cardDetails && tenant.tenant.cardDetails.length < 10 &&
                                <button className="btn" onClick={openModalCard}>
                                    <i className="fas fa-plus"></i> Add New Card
                        </button>}
                        </div>
                        <div className={`cutm-padng ${tenant && tenant.tenant.cardDetails && tenant.tenant.cardDetails.length ? " " : "tab-arw"}`}>
                            {tenant && tenant.tenant.cardDetails && tenant.tenant.cardDetails.length ?
                                <div className="row box-otr">

                                    {tenant.tenant.cardDetails && tenant.tenant.cardDetails.length &&
                                        tenant.tenant.cardDetails.map((e, i) => {
                                            return <div className={"col-md-3 credit-box-width "}>
                                                <div className="credit-box-outr cstmHeight">
                                                    <div className={`primary-head ${e.defaultCard ? "active-head" : ""}`}>
                                                        <span onClick={() => setPrimary(e._id)}><i className="fas fa-check"></i> </span>{
                                                            e.defaultCard ? "Primary" : "Set as Primary"}
                                                        <div className="vertical-menu">
                                                            <i
                                                                className="fa fa-trash"
                                                                aria-hidden="true"
                                                                title="delete"
                                                                onClick={() => {
                                                                    // e.defaultCard ?
                                                                    //     toastr.error("Primary card cannot be deleted")
                                                                    //     :
                                                                        openDeleteModal(e._id)
                                                                }}></i>
                                                        </div>
                                                    </div>
                                                    <div className={`${e.defaultCard ? "credit-box-inner active-gradient" : "credit-box-inner"}`}>
                                                        <div className="credit-card-header">
                                                            <h3>Card {i + 1}</h3>
                                                            <img src="/images/visa.png" />
                                                        </div>
                                                        <div className="center-number">
                                                            <span>xxx-xxxx-xxxx-{e.cardNumber.substring(12, 16)}</span>
                                                        </div>
                                                        <div className="credit-card-footer">
                                                            <div className="card-holder">
                                                                <span className="holdr-txt">Card Holder</span>
                                                                <span className="holdr-name">{e.cardName}</span>
                                                            </div>
                                                            <div className="card-holder">
                                                                <span className="holdr-txt">Expiry</span>
                                                                <span className="holdr-name">{e.expiryDate}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                :
                                <div className="no_value">
                                    <i className="far fa-thumbs-down"></i> Add Your First Card here!!!</div>
                            }

                        </div>
                    </div>
                    :
                    <BankAccount />
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, settingsReducer, profileReducer } = state;
    return {
        tenantReducer,
        profileDetail: profileReducer.profileDetail,
        card: settingsReducer.card,
        deleteCards: settingsReducer.deleteCard
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...tenantAction
})(Cards);
