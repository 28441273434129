import * as actionType from "./constant";

const initialState = {
    updateBoardDataRes :[],
    boardData :[],
    boardLabels :[],
    tenantList :[],
    boardActivity :[],
    cardAttachments :{},
    deleteAttachments :{},
    customFields :[],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_BOARD_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_BOARD_DATA_SUCCESS:
            return {
                ...state,
                updateBoardDataRes:action.data,
            };
        case actionType.UPDATE_BOARD_DATA_FAILURE:
            return {
                ...state,
                updateBoardDataRes: { success: false, ...action.error }
            };

        case actionType.GET_BOARD_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_BOARD_DATA_SUCCESS:
            return {
                ...state,
                boardData:action.data.data,
            };
        case actionType.GET_BOARD_DATA_FAILURE:
            return {
                ...state,
                boardData: { success: false, ...action.error }
            };

        case actionType.GET_BOARD_LABELS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_BOARD_LABELS_SUCCESS:
            return {
                ...state,
                boardLabels:action.data.data.boardLabel,
            };
        case actionType.GET_BOARD_LABELS_FAILURE:
            return {
                ...state,
                boardLabels: { success: false, ...action.error }
            };

        case actionType.ADD_BOARD_LABELS_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_BOARD_LABELS_SUCCESS:
            return {
                ...state,
                boardLabels:action.data.data.boardLabel,
            };
        case actionType.ADD_BOARD_LABELS_FAILURE:
            return {
                ...state,
                boardLabels: { success: false, ...action.error }
            };

        case actionType.DELETE_BOARD_LABELS_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_BOARD_LABELS_SUCCESS:
            return {
                ...state,
                boardLabels:action.data,
            };
        case actionType.DELETE_BOARD_LABELS_FAILURE:
            return {
                ...state,
                boardLabels: { success: false, ...action.error }
            };

        case actionType.UPDATE_BOARD_LABELS_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_BOARD_LABELS_SUCCESS:
            return {
                ...state,
                boardLabels:action.data,
            };
        case actionType.UPDATE_BOARD_LABELS_FAILURE:
            return {
                ...state,
                boardLabels: { success: false, ...action.error }
            };

        case actionType.GET_TENANTS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_TENANTS_SUCCESS:
            return {
                ...state,
                tenantList:action.data.data.tenantList,
            };
        case actionType.GET_TENANTS_FAILURE:
            return {
                ...state,
                tenantList: { success: false, ...action.error }
            };

        case actionType.GET_CARD_ACTIVITY_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CARD_ACTIVITY_SUCCESS:
            return {
                ...state,
                boardActivity:action.data.data.boardActivity,
            };
        case actionType.GET_CARD_ACTIVITY_FAILURE:
            return {
                ...state,
                boardActivity: { success: false, ...action.error }
            };

        case actionType.ADD_CARD_ACTIVITY_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_CARD_ACTIVITY_SUCCESS:
            return {
                ...state,
                boardActivity:action.data.data.boardActivity,
            };
        case actionType.ADD_CARD_ACTIVITY_FAILURE:
            return {
                ...state,
                boardActivity: { success: false, ...action.error }
            };

        case actionType.GET_CARD_ATTACHMENTS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CARD_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                cardAttachments:action.data.data,
            };
        case actionType.GET_CARD_ATTACHMENTS_FAILURE:
            return {
                ...state,
                cardAttachments: { success: false, ...action.error }
            };

        case actionType.ADD_CARD_ATTACHMENTS_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_CARD_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                cardAttachments:action.data.data,
            };
        case actionType.ADD_CARD_ATTACHMENTS_FAILURE:
            return {
                ...state,
                cardAttachments: { success: false, ...action.error }
            };

        case actionType.DELETE_CARD_ATTACHMENTS_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_CARD_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                deleteAttachments:action.data.data,
            };
        case actionType.DELETE_CARD_ATTACHMENTS_FAILURE:
            return {
                ...state,
                deleteAttachments: { success: false, ...action.error }
            };

        case actionType.ADD_CUSTOM_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                customFields:action.data.data.customField,
            };
        case actionType.ADD_CUSTOM_FIELD_FAILURE:
            return {
                ...state,
                customFields: { success: false, ...action.error }
            };

        case actionType.GET_CUSTOM_FIELDS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CUSTOM_FIELDS_SUCCESS:
            return {
                ...state,
                customFields:action.data.data.customField,
            };
        case actionType.GET_CUSTOM_FIELDS_FAILURE:
            return {
                ...state,
                customFields: { success: false, ...action.error }
            };

        case actionType.UPDATE_CUSTOM_FIELDS_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_CUSTOM_FIELDS_SUCCESS:
            return {
                ...state,
                customFields:action.data,
            };
        case actionType.UPDATE_CUSTOM_FIELDS_FAILURE:
            return {
                ...state,
                customFields: { success: false, ...action.error }
            };

        case actionType.DELETE_CUSTOM_FIELDS_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_CUSTOM_FIELDS_SUCCESS:
            return {
                ...state,
                customFields:action.data,
            };
        case actionType.DELETE_CUSTOM_FIELDS_FAILURE:
            return {
                ...state,
                customFields: { success: false, ...action.error }
            };

        default:
            return state;
    }
};
