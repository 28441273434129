import React from "react";
import {connect} from 'react-redux';
import action from "../action";
import {withRouter} from "react-router-dom"
import LoaderSpinner from "../../Common/components/Loader";
import CreatableSelect from 'react-select/creatable';

class MessagingFilterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recipient: [],
            cc: [],
            subject: "",
            description: "",
            uploadAttachmentsHeader: 'Upload',
            uploadAttachment: [],
            attachmentChanged: false,
            errors: {},
            recipientArray: [],
            ccArray: [],
            showCcField: false,
            isLoaded: false,
            uploadError: ''
        };
    }

    componentDidMount = async () => {
        const {messageParams, tempTenantEmails} = this.props;
        await this.setState({
            recipient: [messageParams.to && {value: messageParams.to, label: messageParams.to}],
            recipientArray: [messageParams.to || ''],
            isLoaded: true,
        })
    };

    checkEmail = (email) => {
        let regExp = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{3})(\.[a-z]{2})*$)/i;
        return regExp.test(email);
    }

    handleMessageSubmit = async (e) => {
        e.preventDefault();
        this.setState({errors: {}});
        const {messageParams, handleDiscard} = this.props;
        const {recipient, cc, subject, description, uploadAttachment} = this.state;
        const errors = await this.validate(recipient, cc, subject, description);
        const {recipientArray, ccArray} = this.state;

        if (Object.keys(errors).length) {
            this.setState({errors});
            return false;
        }

        let formValues = {};
        formValues.to = recipientArray;
        formValues.cc = ccArray;
        formValues.subject = messageParams.thread;
        formValues.message = description;

        await this.props.sendNewMessage(formValues, uploadAttachment);
        const {messageReducer: {sendMessageRes: {success: status}}} = this.props;
        if (status) {
            this.setState({
                isLoaded: true
            });
            handleDiscard(status);
        }
        return false;

        // submit the data...
    };

    validate = (recipient, cc, subject, description) => {
        let errors = {};
        let newRecipientsArray = [];
        let newCcArray = [];

        const {recipientArray, ccArray} = this.state;
        if (recipientArray.length) {
            let tempRecipientsArray = recipientArray;
            for (let i = 0; i <= (tempRecipientsArray.length - 1); i++) {
                if (this.checkEmail(tempRecipientsArray[i].trim())) {
                    newRecipientsArray.push(tempRecipientsArray[i])
                } else {
                    errors.recipient = "Invalid email: " + tempRecipientsArray[i];
                }
            }
            this.setState({
                recipientArray: newRecipientsArray,
            });
        } else {
            errors.recipient = "Please add recipient mails."
        }
        if (ccArray.length) {
            let tempCcArray = ccArray;
            for (let i = 0; i <= (tempCcArray.length - 1); i++) {
                if (this.checkEmail(tempCcArray[i].trim())) {
                    newCcArray.push(tempCcArray[i])
                } else {
                    errors.cc = "Invalid email: " + tempCcArray[i];
                }
            }
            this.setState({
                ccArray: newCcArray,
            });
        }
        return errors;
    };

    isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };

    handleAddAttachment = e => {
        if (this.isIterable(e.target.files)) {
            this.setState({
                uploadError: ''
            });
            if (e.target.files.length) {
                this.setState({
                    uploadAttachmentsHeader: 'Add'
                })
            }
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const invalidExt = ["exe", "dll", "dmg", "vb", "cmd", "bat", "jar", "vbs", "jse", "pif", "vxd", "jse", "apk", "ins", "sct", "msi"];
                // const acceptableExt = ["pdf", "docx", "doc", "txt", "png", "jpg", "ttf", "avi", "flv", "wmv", "mov", "mp4", "zip"];
                if (!invalidExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        this.setState({
                            attachmentChanged: true,
                            uploadAttachment: [...this.state.uploadAttachment, ...e.target.files],
                        });
                    } else {
                        this.setState({
                            uploadError: 'File is too big. Files with size greater than 25MB is not allowed.'
                        })
                    }
                } else {
                    this.setState({
                        attachmentChanged: false,
                        uploadError: '* Accept only image / video / emoji / documents !!!'
                    })
                }
            })
        }
    };

    deleteAttachment = (index) => {
        let fileArray = Object.values(this.state.uploadAttachment)
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            this.setState({
                uploadAttachmentsHeader: 'Upload',
                attachmentChanged: false,
                uploadAttachment: []
            });
        }
        this.setState({
            fileChanged: true,
            uploadAttachment: fileArray
        });
        document.getElementById('attachmentFiles').value = null
    };

    setContentsAccordingToType = (type) => {
        let imageClass = '';
        if (type === 'forward') {
            imageClass = 'forwardImg'
        }
        return {imageClass}
    };

    handleShowCc = () => {
        this.setState({
            showCcField: true
        })
    };

    handleToSelect = (selectedEmails) => {
        let newRecipientsArray = [];
        let tempRecipientsArray = [];

        (selectedEmails && selectedEmails.length) && selectedEmails.map(item => {
            tempRecipientsArray.push({value: item.value, label: item.value});
            newRecipientsArray.push(item.value);
        });
        this.setState({
            recipient: tempRecipientsArray,
            recipientArray: newRecipientsArray,
        });
    };

    handleCcSelect = (selectedEmails) => {
        let newCcArray = [];
        let tempCcArray = [];

        (selectedEmails && selectedEmails.length) && selectedEmails.map(item => {
            tempCcArray.push({value: item.value, label: item.value});
            newCcArray.push(item.value)
        });
        this.setState({
            cc: tempCcArray,
            ccArray: newCcArray,
        });
    };

    render() {
        const {handleDiscard, messageType, tempTenantEmails} = this.props;
        const {uploadAttachment, showCcField, uploadError, errors, isLoaded} = this.state;
        const {imageClass} = this.setContentsAccordingToType(messageType);
        if (!isLoaded) {
            return <LoaderSpinner/>
        }

        return (
            <div className="mailreplyOuter col-md-12 mt-4">
                <div className="mailFlex col-md-12">
                    <div className="userImg">
                        <img src="/images/profile-image.png" alt=""/>
                    </div>
                    <form onSubmit={this.handleMessageSubmit} className="reply-forward-message-form">
                        <div className="replyBox">
                            <div className="replyHeader">
                                <div className="replyDetails col-md-11">
                                    <img src="/images/Replay.png" className={imageClass}/>
                                    <div className="sendOption">
                                        <div className="messgaereplyLabel">
                                            <span className="messageLabel">To</span>
                                            <CreatableSelect
                                                isMulti
                                                onChange={this.handleToSelect}
                                                placeholder=''
                                                options={tempTenantEmails}
                                                value={this.state.recipient}
                                            />
                                        </div>

                                        {errors && errors.recipient &&
                                        <span className="text-danger"><small>{errors.recipient}</small></span>}
                                        {showCcField &&
                                        <div className="messgaereplyLabel">
                                            <span className="messageLabel">Cc</span>
                                            <CreatableSelect
                                                isMulti
                                                onChange={this.handleCcSelect}
                                                placeholder=''
                                                options={tempTenantEmails}
                                                value={this.state.cc}
                                            />
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="deleteSec">
                                    {!showCcField &&
                                    <div className="copy" onClick={this.handleShowCc}>
                                        <span>Cc</span>
                                    </div>
                                    }
                                    <div className="deleteMail" onClick={() => handleDiscard('discard')}>
                                        <img src="/images/delete.png" alt=""/>
                                        <span>Delete</span>
                                    </div>

                                </div>
                            </div>
                            <div className="replyMailBody">
                                <div className="row">
                                    <div className="col-md-12">
                                        <textarea
                                            rows="5"
                                            className="mailTextarea"
                                            onChange={evt => this.setState({description: evt.target.value})}
                                        />
                                        {uploadAttachment && uploadAttachment.length
                                            ?
                                            <div className="col-md-12 attachfileOuter">
                                                {Object.values(uploadAttachment).map((attachment, i) => {
                                                    return (
                                                        <div className="col-xl-1 col-md-3 col-sm-3 outAttachImg"
                                                             key={i}>
                                                            <div className="col-md-12 attachFileList">
                                                                {(attachment.name.split('.').pop() === "jpg" || attachment.name.split('.').pop() === "png" || attachment.name.split('.').pop() === "svg")
                                                                    ?
                                                                    <img src={URL.createObjectURL(attachment)} alt=""/>
                                                                    : (attachment.name.split('.').pop() === "pdf")
                                                                        ? <i className="far fa-file-pdf"/>
                                                                        : (attachment.name.split('.').pop() === "doc" || attachment.name.split('.').pop() === "docx")
                                                                            ?
                                                                            <i className="far fa-file-alt"/>
                                                                            : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.name.split('.').pop()))
                                                                                ? <i className="far fa-file-video"/>
                                                                                : (attachment.name.split('.').pop() === 'zip')
                                                                                && <i className="far fa-file-archive"/>
                                                                }
                                                                <button type="button" className="image-close"
                                                                        onClick={() => this.deleteAttachment(i)}>
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <p>{attachment.name}</p>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            : null
                                        }
                                        {uploadError &&
                                        <div className="text-danger text-right"><small>{uploadError}</small></div>}
                                    </div>
                                </div>
                            </div>
                            <div className="replymailFooter">
                                <div className="attachOption">
                                    <label className="attachImg" htmlFor="attachmentFiles">
                                        <img src="/images/Attach.png" alt=""/>
                                        <input
                                            type="file"
                                            name="uploadAttachments"
                                            className="file-name"
                                            multiple={true}
                                            onChange={this.handleAddAttachment}
                                            id="attachmentFiles"
                                        />
                                        <span>Attach</span>
                                    </label>
                                </div>
                                <button type="submit" className="mailSend">
                                    Send
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(MessagingFilterForm));