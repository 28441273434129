import React from "react";
import moment from 'moment'
import { numberWithCommas } from "../../Common/components/utils";
import toastr from '../../Common/components/Toaster';

const InvoiceListing = (props) => {
    let cardAdded = 0;
    cardAdded = props.invoice.tenant && props.invoice.tenant.tenantId && props.invoice.tenant.tenantId.cardDetails && props.invoice.tenant.tenantId.cardDetails.length

    return (
        <div className="table-responsive">
            <table className="table-gap table-td">
                <thead>
                    <tr className="table-head">
                        <th>
                            <div className="thTab">
                                <div className="txtTabl">Invoice No</div>
                            </div>
                        </th>
                        <th className="date-column-width">
                            <div className="thTab">
                                <div className="txtTabl">Invoice date</div>
                            </div>
                        </th>
                        <th className="date-column-width">
                            <div className="thTab">
                                <div className="txtTabl">Due date</div>
                            </div>
                        </th>
                        <th className="date-column-width">
                            <div className="thTab">
                                <div className="txtTabl">Last attempt</div>
                            </div>
                        </th>
                        <th>
                            <div className="thTab">
                                <div className="txtTabl">Total</div>
                            </div>
                        </th>
                        <th>Payment method</th>
                        <th className={"strTble"}>
                            <div className="thTab" style={{ width: "100px" }}>
                                <div className="txtTabl">Status</div>
                            </div>
                        </th>
                        <th className={"midTble"}>
                            <div className="thTab">
                                <div className="txtTabl">Action</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.InvoiceList && props.InvoiceList.length ? props.InvoiceList.map((inv, key) => {
                        return <tr key={key}>
                            <td>#{inv.invoiceNumber}</td>
                            <td>{moment(inv.startDate).format('MM/DD/YYYY')}</td>
                            <td>{moment(inv.dueDate).format('MM/DD/YYYY')}</td>
                            <td>{inv.attemptToCaptureDate ? moment(inv.attemptToCaptureDate).format('MM/DD/YYYY') : "N/A"}</td>
                            <td>${inv.amount ? numberWithCommas(inv.amount) || 0 : 0}</td>
                            <td>{inv.paymentTypeId ? inv.paymentTypeId.name : null}</td>
                            <td>
                                <div className="statusSec">
                                    <div className={inv.status == "1" ? "status-bg " : "status-bg process-bg"}>
                                    </div>
                                    <div
                                        className={inv.status == "1" ? "price unpaide" : inv.status == "0" ? "price " : ""}>{inv.status == "1" ? "Paid" : inv.status == "2" ? "Cancelled" : "Unpaid"}</div>
                                </div>
                            </td>
                            <td>
                                <div className="markpaidBtn text-center" >
                                    {!inv.status && inv.paymentTypeId.name == "Credit Card" ?
                                        <button className="markPaid " style={{ marginBottom: "0px", padding: "5px 7px" }}
                                            //</div> disabled={!inv.status && inv.paymentTypeId.name != "Credit Card"}
                                            onClick={() => {
                                                props.makePayment(inv._id)
                                            }}

                                        >Make Payment</button>
                                        :
                                        'N/A'
                                    }
                                </div> </td>
                        </tr>
                    }) : <tr>
                            <td className="text-center no_value_table" colSpan={10}><i className="far fa-thumbs-down"></i>No
                                Data
                                Found!!
                    </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div >
    )
}

export default InvoiceListing;
