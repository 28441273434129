import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import tenantAction from '../../Tenants/action';
import profileAction from '../../Profile/action'
import usePrevious from "../../Common/components/prevProps";
import toastr from '../../Common/components/Toaster';
import * as validation from '../../Common/components/validation';
import CreditCardInput from 'react-credit-card-input';
import LoaderSpinner from "../../Common/components/Loader";

const AddCredit = (props) => {

    const [cardDetails, setCardDetails] = useState({
        expiryDate: '',
        cardName: '',
        cardNumber: '',
        cvv: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const { card } = props;

    //const prevCard = usePrevious(card);

    useEffect(()=> {
        const {tenantReducer: {tenantsById: {tenant}}} = props;
        const { profileDetail: { profileDetail } } = props;
        if(tenant){
            setCardDetails({
                ...cardDetails,
                cardName: tenant.tenant.firstName+ " "+tenant.tenant.lastName
            })
        }

    },[])

    useEffect(() => {
        //For updating card
        if (isSubmitted && !card.success) {
            toastr.error(card.message)
        } else if (isSubmitted && card.success ) {
            toastr.success("Card details added successfully");
        }
    }, [card]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCardDetails({
            ...cardDetails,
            [name]: value
        });
    }

    const validate = (value, name, type) => {
        if (submitted) {
            switch (type) {
                case "required":
                    return validation.REQUIRED(value, name) != true && <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
                case "number":
                    return validation.NUMBER_ONLY(value, name) != true && <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
                case "letter":
                    return validation.NUMBER_ONLY(value, name) != true && <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "cardNumber":
                    return validation.NUMBER_ONLY(value, name) != true && <div className={"errorMsg"}>{validation.CARD_NUMBER(value, name)}</div>
            }
        } else return null
    }

    const handleCardNumberChange = e => {
        setCardDetails({
            ...cardDetails,
            cardNumber: e.target.value
        });
    }

    const handleCardExpiryChange = (e) => {
        setCardDetails({
            ...cardDetails,
            expiryDate: e.target.value
        });
    }

    const handleCvvChange = (e) => {
        setCardDetails({
            ...cardDetails,
            cvv: e.target.value
        });
    }

    const onSubmit = async () => {
        setSubmitted(true)
        const { expiryDate, cardName, cardNumber, cvv } = cardDetails;
        let req={}
        if(props.tenantView){
             req = {
                tenantId: props.id,
                expiryDate: expiryDate,
                cardName: cardName,
                cardNumber: cardNumber.replace(/ +/g, ""),
                cvv: cvv,
                defaultCard:'1'
            }
        }
        else{
             req = {
                tenantId: props.id,
                expiryDate: expiryDate,
                cardName: cardName,
                cardNumber: cardNumber.replace(/ +/g, ""),
                cvv: cvv,
                defaultCard:'0'
            }
        }

        if (expiryDate && cardName && cardNumber ) {
            if (validation.CARD_NUMBER(cardNumber, 'cardNumber') == true) {
                setIsSubmitted(true);
                await props.addCardDetails(req);
                props.onCancel();
                setCardDetails({})
                setTimeout(() => {
                    setIsSubmitted(false)
                    props.getTenantId(props.id);
                    props.getProfile()
                }, 1000)
            }
        }
    }

    const errorCredit = (a) => {
    }

    const { expiryDate, cardName, cardNumber, cvv } = cardDetails;

    return (
        <div
      className="modal addTenant small-modal addCard"
      id="modalId"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ display: "block" }}
    >
        {isSubmitted && <LoaderSpinner />}
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Add New Card</h5>
            <button
              type="button"
              className="close"
              onClick={() => props.onCancel()}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="clientInfo">
              <div className="formSection">
              <div className="row m-0">
                  <div className="col-md-12 formInp">
                    <label>Name on card</label>
                    <input
                        type="text"
                        name="cardName"
                        className="form-control"
                        placeholder="Name on Card"
                        value={cardName}
                        onChange={handleChange}
                    />
                    {validate(cardName, "Name", "required")}
                    {cardName && validate(cardName, "Name", "letter")}
                  </div>
                </div>
                <div className="row m-0">
                <div className="col-md-12 formInp">
                    <label>Card Details</label>
                    <div className="creditcardOtr">
                        <CreditCardInput
                        customTextLabels={{
                            invalidCvc:'  ',
                            cardNumberPlaceholder: 'Card Number',
                            expiryPlaceholder: 'MM/YY',
                            cvcPlaceholder: 'CVV'
                          }}
                            cardNumberInputProps={{ value: cardNumber, onChange: handleCardNumberChange }}
                            cardExpiryInputProps={{ value: expiryDate, onChange: handleCardExpiryChange }}
                            cardCVCInputProps={{ value: cvv, onChange: handleCvvChange }}
                            onError={errorCredit}
                            // fieldClassName="form-control"
                            dangerTextClassName={"errorMsg"}
                            invalidClassName={null}
                        />
                    </div>
                    {submitted && !cardNumber && !expiryDate &&
                        <div className={"errorMsg"}>Enter Card Details</div>}
                </div>

                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary btnSubmit" onClick={(e)=>onSubmit(e)}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    )
}

const mapStateToProps = state => {
    const { tenantReducer, settingsReducer, profileReducer } = state;
    return {
        tenantReducer,
        card: settingsReducer.card,
        profileDetail: profileReducer.profileDetail,

    };
};

export default connect(mapStateToProps, {
    ...action, ...tenantAction, ...profileAction
})(AddCredit);

