import innagoGateWay from "../../services/authorizationService";
import {
    innagoAuthenticator
} from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//get all the tenant details
export const getLease = (params) =>
    innagoGateWay.get(serviceEndpoints.leaseEndpoints.lease, { params });

//add tenant details
export const addLease = (value) =>
    innagoGateWay.post(serviceEndpoints.leaseEndpoints.lease, value);

//get all the lease agreement view
export const getLeaseView = (params) =>
    innagoGateWay.get(serviceEndpoints.leaseEndpoints.viewAgreement, { params });

//get all the lease agreement view
export const updateLease = (value, params) =>
    innagoGateWay.put(serviceEndpoints.leaseEndpoints.updateAgreement, value, { params });

//delete lease
export const deleteLease = (data) =>
    innagoGateWay.delete(serviceEndpoints.leaseEndpoints.lease, { data });


//get all the lease agreement view
export const updateLeaseSign = (value, params) =>
    innagoGateWay.put(serviceEndpoints.leaseEndpoints.updateAgreementSign, value, { params });