import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import ClientInformation from './clientInfo';
import Invoices from './Invoices';
import Files from './Files';
import Email from './Email';
import InvoiceSplitUp from './InvoiceSplitUp';
import Others from './Others';
import "react-toastify/dist/ReactToastify.css";
import Modal from '../../../Common/components/Modal';
import LoaderSpinner from "../../../Common/components/Loader";
import Confirm from "../../../Common/components/Confirm"
import FileUpload from './addFileModal'
import usePrevious from "../../../Common/components/prevProps";
import toastr from '../../../Common/components/Toaster';



const Summary = (props) => {

    const [isSubmitted, setIsSubmitted] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(false);

    useEffect(() => {
        window.scroll(0, 0)

        async function renderCall() {
            await props.getTenantId(props.id)
            //  await props.getTenantProperty(props.id)
        }

        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }, [props.id]);

    const prevDelete = usePrevious(props.tenantReducer.deleteFileResponse);

    useEffect(() => {
        if (deleteId && prevDelete != props.tenantReducer.deleteFileResponse) {
            if (!props.tenantReducer.deleteFileResponse.success)
                toastr.error("Error while deleteing file")
            else if (props.tenantReducer.deleteFileResponse.success)
                toastr.success("File deleted successfully")
        }

    }, [prevDelete, props.tenantReducer.deleteFileResponse])

    // const {commonReducer: {InvoiceTypeList}} = this.props;
    // console.log("paymentTypeId", paymentMethods)
    // const invoiceType1 = InvoiceTypeList && InvoiceTypeList.filter((e, i) => e.name == "Security Deposit")

    const reloadTenant = async () => {
        // await props.getTenantProperty(props.id)
        await props.getTenantId(props.id)
    }

    const handleUploadModal = () => {
        setIsModal(true)
    }

    const closeUploadModal = () => {
        setIsModal(false)
    }

    const renderUploadData = () => {
        return <FileUpload onCancel={closeUploadModal} id={props.id} />
    }

    const deleteFileDetails = (id) => {
        setDeleteId(id)
        setIsConfirm(true)
    }

    const closeConfirm = (id) => {
        setDeleteId(null)
        setIsConfirm(false)
    }

    const renderConfirm = () => {
        return <Confirm
            title='Delete File'
            message='Do you want to delete file?'
            onCancel={closeConfirm}
            onConfirm={deleteFileData}
        />
    }

    const deleteFileData = async () => {

        await props.deleteFile(props.id, deleteId)
        setDeleteId(null)
        setIsConfirm(false)
        await props.getTenantId(props.id)

    }





    const { tenantReducer: { tenantsPropertyById: { tenant }, tenantsById: { tenant: tenantById, lease } } } = props;
    return (
        <div className="tab-content" id="pills-tabContent">
            {isSubmitted && <LoaderSpinner />}
            {isConfirm ? <Modal body={renderConfirm()} onCancel={closeConfirm} /> : null}
            {isModal && <Modal body={renderUploadData()} onCancel={closeUploadModal} />}
            <div className="tab-pane fade show active" id="pills-summary" role="tabpanel">
                {props.id && tenantById &&
                    <ClientInformation id={props.id} tenant={tenant} tenantById={tenantById} reloadTenant={reloadTenant} />}
                <div className="col-md-12 row m-0 p-0 outrBilling">
                    {props.id &&
                        <Invoices id={props.id} tenantById={tenantById} tenant={tenant} reloadTenant={reloadTenant} />}
                    {props.id && tenantById && <Files id={props.id}
                        handleUploadModal={handleUploadModal}
                        tenantById={tenantById && tenantById} />}
                    {props.id && <Email id={props.id} tenantById={tenantById} deleteFileDetails={deleteFileDetails} />}
                </div>

                {tenantById && tenantById.tenant && !tenantById.tenant.isClosed ?
                    <InvoiceSplitUp tenant={tenantById} reloadTenant={reloadTenant} tenantId={props.id} /> : ''}
                {props.id && tenantById &&
                    <Others id={props.id} onChangePerson={props.onChangePerson} tenantById={tenantById}
                        reloadTenant={reloadTenant} />}
            </div>
            <div className="tab-pane fade" id="pills-invoice" role="tabpanel">...</div>
            <div className="tab-pane fade" id="pills-email" role="tabpanel">...</div>
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer } = state;
    return {
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(Summary);
