import React, {useState, useEffect} from "react";
import action from "../../action";
import {connect} from "react-redux";
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CommonDatePicker from '../../../Common/components/CommonDatePicker'

const sideFilterMaintenance = (props) => {

    const {
        maintenance, changePriority, promiseOptionsProperty, properties, handleChangeProperty, property, handleChange, units, tenants, tenant,
        promiseOptionsTenant, handleChangeTenant, onChangeAvailableDate, changeRadio, changeAnyDate, changeRadioTime
    } = props;
    const {priority, authorizationToEnter, petInResidence, availableTime, unitId, availableDate, anyDateIsFine, tenantView} = maintenance;
    return (
        <div className="priorityOuter">
            <h3>PRIORITY {props.validate(priority, "Priority", "required")}</h3>
            <ul className="list-sec-pr">
                <li className={priority === 'Low' ? "active" : ' '} onClick={() => changePriority('Low')}>Low</li>
                <li className={priority === 'Normal' ? "active" : ' '} onClick={() => changePriority('Normal')}>Normal
                </li>
                <li className={priority === 'High' ? "active" : ' '} onClick={() => changePriority('High')}>High</li>
                <li className={priority === 'Critical' ? "active" : ' '}
                    onClick={() => changePriority('Critical')}>Critical
                </li>
            </ul>

            <div className="outerfr">
                <div className="formSection">
                    <div className="formInp">
                        <label>Location Information Property</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={properties}
                            placeholder={"Search Property"}
                            loadOptions={promiseOptionsProperty}
                            name="property"
                            onChange={handleChangeProperty}
                            value={property && property}
                            isDisabled={props.tenantLogin || tenantView}
                        />
                        {props.validate(property.value, "Property", "required")}
                    </div>
                    <div className="formInp">
                        <label>Unit</label>
                        <div className="selctOtr">
                            <select className="form-control"
                                    name="unitId"
                                    onChange={(e) => handleChange(e)}
                                    disabled={props.tenantLogin || tenantView}
                                   // value={unitId}

                            >
                                {/* <option value="">Select Unit</option> */}
                                {
                                    units.map((e, i) => {

                                        return <option
                                            value={e.value}
                                            key={i}
                                            selected={maintenance.unitId}

                                        >
                                            {e.label}
                                        </option>
                                    })
                                }
                            </select>
                            {props.validate(unitId, "Unit", "required")}
                        </div>
                    </div>
                    <div className="formInp">
                        <label>Tenant</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={tenants}
                            placeholder={"Search Tenant"}
                            loadOptions={promiseOptionsTenant}
                            name="tenant"
                            onChange={handleChangeTenant}
                            value={tenant && tenant}
                            isDisabled={props.tenantLogin || tenantView}
                        />
                        {props.validate(tenant.value, "Tenant", "required")}
                    </div>
                </div>
            </div>
            <div className="outerfr secFrm">
                <div className="formSection">

                    <div className="formInp">
                        <label>Available Date & Time</label>
                        <CommonDatePicker
                            name="availableDate"
                            placeholderText={"MM/DD/YYYY"}
                            disableClock={true}
                            className="form-control"
                            value={availableDate && moment(new Date(availableDate)).format('MM/DD/YYYY')}
                            onChange={(e) => onChangeAvailableDate(e, 'availableDate')}
                            minDate={new Date()}
                        />
                    </div>
                    <div className="formInp outr-wrp">
                        <label className="containerCheck">Any date is fine
                            <input
                                type="checkbox"
                                name='anyDateIsFine'
                                checked={anyDateIsFine == true}
                                onChange={(e) => changeAnyDate(e)}
                                value='1'
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    {(props.isSubmitted && !availableDate && !anyDateIsFine) ?
                        <div className={"errorMsg"}>Date required</div>
                        : ""}
                    {/* {props.validate(availableDate, "Available Date", "required")} */}
                    <div className="col-md-12 p-0 d-flex flex-wrap outr-wrp">

                        <div className="formInp">
                            <label className="containerCheck">Any time
                                <input
                                    type="radio"
                                    name='availableTime'
                                    checked={availableTime == 1}
                                    onChange={(e) => changeRadioTime(e)}
                                    value='1'
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="formInp">
                            <label className="containerCheck">8am - 12pm
                                <input
                                    type="radio"
                                    name='availableTime'
                                    checked={availableTime == 2}
                                    onChange={(e) => changeRadioTime(e)}
                                    value='2'
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="formInp">
                            <label className="containerCheck">12pm - 4pm
                                <input
                                    type="radio"
                                    name='availableTime'
                                    checked={availableTime == 3}
                                    onChange={(e) => changeRadioTime(e)}
                                    value='3'
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="formInp">
                            <label className="containerCheck">4pm - 8pm
                                <input
                                    type="radio"
                                    name='availableTime'
                                    checked={availableTime == 4}
                                    onChange={(e) => changeRadioTime(e)}
                                    value='4'
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                    </div>
                </div>
            </div>
            <div className="outerfr outerDivTog col-md-12 d-flex p-0">
                <div className="switchToggle">
                    <input type="checkbox" id="switch" name="authorizationToEnter" checked={authorizationToEnter}
                           onChange={(e) => changeRadio(e)}/>
                    <label for="switch">Toggle</label>
                </div>
                <div className="txtfld">
                    Authorization to enter
                </div>
            </div>
            <div className="outerDivTog col-md-12 d-flex p-0">
                <div className="switchToggle">
                    <input type="checkbox" id="switch1" name="petInResidence" onChange={(e) => changeRadio(e)}/>
                    <label for="switch1">Toggle</label>
                </div>
                <div className="txtfld">
                    Pet in residence
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const {maintenanceReducer, commonReducer, tenantReducer} = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        commonReducer,
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(sideFilterMaintenance);

