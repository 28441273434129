import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import commonAction from "../../../Common/action";
import action from "../../../Maintenance/action";
import dashboardAction from '../../action'
import TenantAction from '../../../Invoice/action';
import usePrevious from "../../../Common/components/prevProps";
import toastr from '../../../Common/components/Toaster'


import moment from 'moment';
import { numberWithCommas } from "../../../Common/components/utils";
import Modal from '../../../Common/components/Modal'
import CardList from '../../../Invoice/TenantComponents/cardList';
import LoaderSpinner from "../../../Common/components/Loader";



const AllInvoices = props => {

    const [params, setParams] = useState({
        limit: 10, offset: 0, invoiceTypeId: null, sort: null, status: 0
    })
    const [cardModal, setCardModal] = useState(false);
    const [array, setArray] = useState([])
    const [paymentSubmit, setPaymentSubmit] = useState(false)

    const prevPaymentData = usePrevious(props.payment);

    useEffect(() => {
        if (paymentSubmit && prevPaymentData != props.payment && props.payment.success)
            toastr.success(props.payment.message)
        else if (paymentSubmit && prevPaymentData != props.payment && !props.payment.success)
            if (props.payment.data && props.payment.data.length && props.payment.data.length == 1) {
                let msg = `${props.payment.message} due to ${props.payment.data[0].message}`
                toastr.error(msg)
            }
            else {
                toastr.error(props.payment.message)
            }
    }, [prevPaymentData, props.payment])


    const events = {};

    const options = {
        items: 2,
        nav: true,
        //rewind: true,
        // loop: true,
        autoplay: false,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:1,
                nav:false
            },
            1000:{
                items:2,
                nav:true,
                loop:false
            }
        },
        navText: [
            '<i class="fas fa-chevron-left"></i>',
            '<i class="fas fa-chevron-right"></i>'
        ]
    };

    useEffect(() => {
        props.getTenantInvoices(params)
    }, [])

    const makePayment = async (e, id) => {
        let tempArray = array;
        tempArray.push(id);
        setArray(tempArray)
        e.preventDefault();
        setCardModal(true)
    }

    const payInvoice = async () => {
        setCardModal(false)
        setPaymentSubmit(true);
        await props.makeMultiplePayment({ invoiceId: array })

        setTimeout(async () => {
            await props.getTenantUnPaidInvoice();
            await props.getTenantInvoices(params)
            setPaymentSubmit(false)
        }, 500)


    }

    const closeCardModal = () => {
        setCardModal(false)
    }

    const renderCardList = () => {
        const { profileDetail: { profileDetail } } = props;
        return <CardList
            onCancel={closeCardModal}
            id={profileDetail.admin.tenantId._id}
            payInvoiceData={payInvoice}
            tenatDetailView={false}

        />
    }


    return (
        <div className="all-available mt-3">
            {paymentSubmit && <LoaderSpinner />}
            {cardModal ? <Modal body={renderCardList()} onCancel={closeCardModal} /> : null}
            <div className="payable-head">
                <img src="images/available.png" />
                <p>ALL AVAILABLE UNPAID INVOICES({props.invoiceReducer.InvoiceTenantList.unPaidInvoiceCount})</p>
            </div>
            {props.InvoiceList && props.InvoiceList.length ?
                <div className="row mt-3 m-0 p-0">

                    <OwlCarousel options={options}>
                        {props.InvoiceList.map((inv, key) => {
                            //if(inv.status === 0)
                            return <div className="available-box">
                                <div className="payment-sec">
                                    <div className="status-otr">
                                        {/* <span className="available-status">OVERDUE</span> */}
                                        <span
                                            className={`available-status ${(inv.status === 0 && moment().diff(moment(inv.dueDate), 'days') > 0) ? "colr-red" : ""}`}>
                                            {(inv.status === 0 && moment().diff(moment(inv.dueDate), 'days') > 0) ? "OVERDUE" :
                                                inv.status == 1 ? 'PAID' :
                                                    "UNPAID"}

                                        </span>
                                        <span
                                            className="rent">{inv.invoiceTypeId ? inv.invoiceTypeId.name : null}</span>
                                    </div>
                                    <div className="status-otr">
                                        <span className="sugg-payment">Suggested Payment</span>
                                        <span className="rent-amount">${numberWithCommas(inv.amount)}</span>
                                    </div>
                                </div>

                                <div className="row custm-mrgn">
                                    <div className="col-md-6 p-0 right-brdr">
                                        <div className="col-md-12">
                                            <div className="invoice-sec">
                                                <p>INVOICE #</p>
                                                <span>{inv.invoiceNumber}</span>
                                            </div>
                                            <div className="invoice-sec">
                                                <p>Property Owner #</p>
                                                <span>{props.tenant && props.tenant.tenantId.propertyId.paymentId ? props.tenant.tenantId.propertyId.paymentId.propertyOwnerName : "N/A"}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 p-0">
                                        <div className="col-md-12 pr-0">
                                            <div className="invoice-sec">
                                                <p>DUE DATE</p>
                                                <span>{moment(inv.dueDate).format('MM/DD/YYYY')}</span>
                                            </div>
                                            <div className="invoice-sec">
                                                <p>TOTAL OUTSTANDING</p>
                                                <span>$ {inv.status == 0 ? numberWithCommas(inv.amount) : 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-0 pt-2">
                                        <div className="payment-sec btn-bl-pay">
                                            <button className="btn addButton"
                                                disabled={inv.status || inv.paymentTypeId.name == "Cash"}
                                                onClick={(e) => makePayment(e, inv._id)}
                                            >Make Payment</button>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="box-footer">*/}
                                {/*    <button className="btn">View Details</button>*/}
                                {/*</div>*/}
                            </div>


                        })
                        }
                    </OwlCarousel>

                </div>
                :
                <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>
            }
        </div>
    )
}

function mapStateToProps(state) {
    const { commonReducer, profileReducer, maintenanceReducer, invoiceReducer, dashboardReducer } = state;
    return {
        messageDetailsByTenant: commonReducer.messageDetailsByTenant,
        profileReducer,
        maintenanceReducer,
        InvoiceList: invoiceReducer.InvoiceTenantList.invoice,
        tenant: invoiceReducer.InvoiceTenantList.tenant,
        invoiceReducer,
        profileDetail: profileReducer.profileDetail,
        payment: dashboardReducer.payment
    };
}

export default withRouter(connect(mapStateToProps, { ...commonAction, ...action, ...TenantAction, ...dashboardAction })(AllInvoices));
