import React, {useState, useEffect} from "react";
import ReactApexChart from 'react-apexcharts'

import commonAction from '../../../Common/action'
import {connect} from "react-redux";
import history from '../../../../config/history'

const Occupancy = props => {

    useEffect(() => {

        async function renderCall() {
            await props.getAllUnits()
        }

        renderCall();

    }, []);

    const {unitDetails} = props.commonReducer
    const inactiveUnitCount = unitDetails && unitDetails.inactiveUnitCount
    const activeUnitCount = unitDetails && unitDetails.activeUnitCount
    const series = [{Occupied: 11, Vacant: 3}]
    const options = {
        labels: ['Occupied', 'Vacant'],
        chart: {
            type: 'donut',
        },
        chartOptions: {
            labels: ['Apple', 'Mango']
        },
        dataLabels: {
            enabled: false
        },
        responsive: [
            {
                breakpoint: 1679,
                options: {
                    chart: {
                        height: 190
                    },
                    legend: {
                        show: false
                    }
                }
            }
        ],
        plotOptions: {
            pie: {
                donut: {
                    size: '72%'
                },
                legend: {
                    show: false
                }
            }
        },
        colors: ['#1dc896', '#ff5858'],
        legend: {
            show: false
        },

    }

    const openVecantProperty = () => {
        history.push('/Properties/Vacant')
    }

    const openOccupiedProperty = () => {
        history.push('/Properties/Occupied')

    }


    return (
        <div
            // ref={props.reference.ref}
            // {...props.dragHandleProps}
            // {...props.draggableProps}
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-6 col-sm-12 occupancy mb-3">

            <div className="occupancyOtr flexotrSec">
                <div className="headerSection d-flex">
                    <h3>Occupancy Statistics</h3>
                    {/*<div className="viewMore">*/}
                    {/*<img src="/images/more.png"/><span>View More</span>*/}
                    {/*</div>*/}
                </div>
                {props.commonReducer.unitDetails && props.commonReducer.unitDetails.unitList && props.commonReducer.unitDetails.unitList.length ?
                    <div className="row m-0">
                        <div className="chartContainer flexDrw col-md-6">
                            <div className="chartOuter text-center d-flex align-items-center justify-content-center">
                            {/* <iframe
                                className="chartjs-hidden-iframe"
                                tabindex="-1"
                                style="width: 100%; display: block; border: 0px; height: 0px; margin: 0px; position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px;"
                            ></iframe> */}
                                {/*<canvas id="pieChart" width="180" height="180" className="doughnutGr"></canvas>*/}
                                <ReactApexChart options={options}
                                                series={[activeUnitCount ? activeUnitCount : 0, inactiveUnitCount ? inactiveUnitCount : 0]}
                                                labels={['Occupied', 'Vacant']}
                                                type="donut"
                                                width="240px" height="240px"/>
                                <div className="textInnerDoughnut d-flex flex-row align-items-center">
                                    <div className="numTxt">
                                        {unitDetails && unitDetails.totalunitListCount}
                                    </div>
                                    <div className="sumTxt">
                                        <p className="tot">Total</p>
                                        <p className="unit">Units</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 chartContainer p-0">
                            <ul className="chartCont">
                                <li><span className="dot"></span><span className="textcont">Occupied</span></li>
                                <li><span className="dot colorbg"></span><span className="textcont">Vacant</span></li>
                            </ul>
                            <ul className="dtlOccupy">
                                <li className="redbrd" onClick={() => openVecantProperty()} style={{cursor: "pointer"}}>
                                    <p>Vacant</p>
                                    <h3>{inactiveUnitCount} Units</h3>
                                </li>
                            </ul>
                            <ul className="dtlOccupy">
                                <li onClick={() => openOccupiedProperty()} style={{cursor: "pointer"}}>
                                    <p>Occupied</p>
                                    <h3>{activeUnitCount} Units</h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :

                    <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>}
                </div>
            </div>

    )
}

function mapStateToProps(state) {
    const {commonReducer} = state;
    return {
        commonReducer,
    };
}

export default connect(mapStateToProps, {...commonAction})(Occupancy);

