export const userEndpoints = {
    login: "/login",
    password: '/adminuser/createPassword',
    forgotPassword: '/checkMail/changePassword',
    validateToken: '/adminuser/validateKey',
    state: "/api/state",
    propertyfeature: "/api/propertyfeature",
    propertytype: "/api/propertytype",
    amenity: "/api/amenity",
    notification: '/api/notification',
    adminUsers: '/api/maintenanceTickets/listAll/AdminUsers',
    advanceSearch: '/api/property/search/universal',
    pageLimit: '/api/countInPage',
    emailCheck: '/api/email/emailType'
};

export const propertyEndpoints = {
    property: "/api/property",
    unit: "/api/unit",
    unitDetails: "/api/unit/getByUnitId",
    makeDefault: "/api/property/makeDefault",
    deleteImage: "/api/property/imgdelete/delete",

};

export const messageEndpoints = {
    sendMessage: "/api/message",
    getMailList: "/api/message",
    getMailDetails: "/api/message",
    getTenantEmails: "/api/tenant/emails",
    deleteMessage: "/api/message/delete",
    deleteMailCheck: "/api/message/deleteMails",
    getPhoneNumbers: "/api/tenant/tenantList/number/name/forChat",
    sendChatMessage: "/api/chat",
    getChatMessageList: "/api/chat",
    getChatDetails: "/api/chat",
    deleteThread: "/api/message/deleteThread",
    refreshMail: '/api/message/gmailSync',
    deleteMessageChecked: '/api/chat/deleteChat'
};

export const boardEndpoints = {
    updateBoardData: "/api/innagoBoard",
    getBoardData: "/api/innagoBoard",
    addBoardLabels: "/api/boardLabel",
    updateBoardLabels: "/api/boardLabel",
    getBoardLabels: "/api/boardLabel",
    deleteBoardLabels: "/api/boardLabel",
    getTenants: "/api/tenant",
    getCardActivity: "/api/boardActivity",
    addCardActivity: "/api/boardActivity",
    getCardAttachments: "/api/cardAttachments",
    addCardAttachments: "/api/cardAttachments",
    deleteCardAttachments: "/api/cardAttachments/filedelete",
    addCustomField: "/api/customField",
    getCustomFields: "/api/customField",
    updateCustomField: "/api/customField",
    deleteCustomField: "/api/customField",
};

export const tenantEndpoints = {
    tenant: "/api/tenant",
    tenantProperty: "/api/tenant/tenantList",
    invoicesTypes: "/api/invoice/invoiceList",
    invoices: "/api/tenant/invoiceList",
    messages: "/api/message/messageListByTenantId",
    deleteTenantFile: "/api/tenant/file/delete",
    leaseAgreement: "/api/tenant/leaseAgreement/send",
    viewLeaseAgreement: "/api/tenant/leaseAgreement/view",
    viewAgreement: "/api/tenant/leaseAgreement/listAgreement",
    getProperty: '/api/tenant/property/list/basedTenant',
    getPaidInvoice: '/api/invoice/invoiceList/basedTenant/Paid',
    getUnPaidInvoice: '/api/invoice/unPaid',
    getMaintenance: '/api/maintenanceTickets/basedStatus',//get maintenance list for tenant login
    getMessages: '/api/message/messageList/inbox/basedTenant',
    createPassword: "/api/tenant/createPassword/tenant",
    paymentTenant: "/api/tenant/payment/autoProcess",
    deleteTenant: "/api/tenant/permanentDelete",
    closeAccount: "/api/tenant/close/tenantAccount",
    recuredDate: "/api/tenant/invoice",
    reOpenTenant: '/api/tenant/reopenTenant',
    submitLease: '/api/lease/saveTemplate',
    file: '/api/tenant/fileUpload',
    fileDelete: '/api/tenant/file/delete',
    emailManageAdd: '/api/tenant/createAlternateEmails',
    emailManageDelete: '/api/tenant/deleteAlternateEmails',
    emailManageUpdate: '/api/tenant/updateAlternateEmails',
    emailManageGet: '/api/tenant/listAlternateEmails',
    generateInvoice: "api/tenant/generateInvoiceManually"
};

export const invoiceEndpoints = {
    invoice: "/api/invoice",
    invoiceTypes: '/api/invoiceTypes',
    paymentTypes: '/api/paymentTypes',
    invoiceTenants: '/api/invoice/invoiceList/basedTenant/paid/unpaid',
    invoiceTenantsTypes: '/api/invoiceTypes/invoiceTypeBasedTenant',
    payInvoice: '/api/tenant/payment/chargeProfile',
    sendLobMail: '/api/invoice/sendLob',
    viewNotice: '/api/invoice/viewLobMail',
    sendSms: '/api/invoice/sendSms/forTenants',
    sendSmsEachInvoice: '/api/invoice/sendSms/forTenants/payment',
    mergeInvoice: '/api/invoice/mergeInvoices',
    invoiceById: '/api/property/invoiceList/basedId/'
};

export const expenseEndpoints = {
    expenses: "/api/expenses",
    category: "/api/category",
    merchant: "/api/merchant",
    business: "/api/business",
    expenseGraph: '/api/expenses/graph',
    getInvoiceTransaction: '/api/invoice/paidInvoiceBasedDate'
};

export const maintenanceEndpoints = {
    maintenance: "/api/maintenanceTickets",
    closeMaintenace: '/api/maintenanceTickets/statusUpdate',
    viewMaintenance: '/api/maintenanceTickets/messageList/basedOn/particular/maintenance',
    getCategoryList: '/api/maintenanceCategories/listCategories',
    maintenanceAdd: "/api/maintenanceTickets",
    getMaintenanceTicket: '/api/maintenanceTickets/addReply',
    getTenantMaintenceDetails: '/api/maintenanceTickets/tenant/raised/maintenances',
    getMaintenanceDetailsById: '/api/maintenanceTickets',
    editNoteDetails: '/api/maintenanceTickets/update/maintenanceNote'
};

export const leaseEndpoints = {
    lease: "/api/lease",
    viewAgreement: "/public/api/leaseAgreement/display",
    updateAgreement: "/public/api/leaseAgreement/update",
    updateAgreementSign: "/api/tenant/leaseAgreement/update"
};

export const zillowEndPoint = {
    zillow: "/zillo",
};

export const dashboardEndPoints = {
    getCardPosition: "/api/dashboard",
    makePayment: "/api/tenant/payment/multiple/chargeProfile"
};

export const revenueEndPoints = {
    revenue: "/api/dashboard/listRevenue"
}

export const settingsEndpoints = {
    business: "/api/business",
    businessEntity: "/api/settings",
    addMailAccount: "/gmailAuth/generatePemUrl",
    addMailAccountCode: "/gmailAuth",
    user: "/api/admin/allusers",
    editUser: "/api/admin/update",
    card: "/api/tenant/payment/card",
    deleteCard: "/api/tenant/payment/deleteCard",
    paymentHistory: '/api/tenant/payment/transactions',
    autoPay: '/api/tenant/payment/autoProcess',
    addTemplate: '/api/leaseTemplates',
    addEditedTemplate: '/api/leaseTemplates/newLease',
    getTemplates: '/api/leaseTemplates',
    deleteTemplate: '/api/leaseTemplates',
    addMailDetails: '/api/email'
};

export const profileEndpoints = {
    changePassword: '/api/admin/changepassword',
    profile: '/api/admin',
    updateTenantPassword: '/api/tenant/update/password/tenant'
};

export const reportEndpoints = {
    report: '/api/reports',
    inviteScreening: 'api/reports/emailSend'
};
export const screeningEndpoints = {
    screening: '/public/api/reports',
    property: '/public/api/property',
    makePayment: '/public/api/tenant/payment/screeningFee',
    getVacantProperty: '/public/api/property/vacantList/units',
    getVacantUnit: '/public/api/property/includesUnits'
};

export const plaideEndpoints = {
    postRequest: '/api/admin/plaid/token',
    getDataSourceDetails: '/api/admin/plaid/accounts',
    getTransaction: '/api/admin/plaid/transactions',
    getCategory: '/api/admin/plaid/transactions/categories',
    getBankDetails: '/api/property/bank',
    updateBankDetails: '/api/admin/plaid/accounts',
    importDetails: '/api/admin/plaid/transactions/import',
    deleteTransaction: '/api/admin/plaid/transactions/multipleDeletion'
};


const common = {
    userEndpoints,
    propertyEndpoints,
    messageEndpoints,
    boardEndpoints,
    tenantEndpoints,
    expenseEndpoints,
    invoiceEndpoints,
    maintenanceEndpoints,
    leaseEndpoints,
    zillowEndPoint,
    dashboardEndPoints,
    revenueEndPoints,
    settingsEndpoints,
    profileEndpoints,
    reportEndpoints,
    screeningEndpoints,
    plaideEndpoints
};

export default common;
