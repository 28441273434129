import * as actionTypes from "./constant";
import * as Service from "./service";

//Send new message
const updateBoardData = (boardData) => {

    const request = data => ({
        type: actionTypes.UPDATE_BOARD_DATA_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.UPDATE_BOARD_DATA_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.UPDATE_BOARD_DATA_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateBoardData(boardData);
            if (res.status === 200) {
                const boardResData = res.data;
                await dispatch(success(boardResData));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Get Board Data
const getBoardData = () => {

    const request = data => ({
        type: actionTypes.GET_BOARD_DATA_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_BOARD_DATA_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_BOARD_DATA_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getBoardData();
            if (res.status === 200) {
                const getBoardRes = res.data;
                await dispatch(success(getBoardRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Get Board Label
const getBoardLabels = () => {
    const request = data => ({
        type: actionTypes.GET_BOARD_LABELS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_BOARD_LABELS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_BOARD_LABELS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getBoardLabels();
            if (res.status === 200) {
                const getBoardLabelsRes = res.data;
                await dispatch(success(getBoardLabelsRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//Add Board Label
const addBoardLabels = (labelData) => {
    const request = data => ({
        type: actionTypes.ADD_BOARD_LABELS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_BOARD_LABELS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_BOARD_LABELS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addBoardLabels(labelData);
            if (res.status === 200) {
                const addBoardLabelsRes = res.data;
                await dispatch(success(addBoardLabelsRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Update Board Label
const updateBoardLabels = (labelData) => {
    const request = data => ({
        type: actionTypes.ADD_BOARD_LABELS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_BOARD_LABELS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_BOARD_LABELS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateBoardLabels(labelData);
            if (res.status === 200) {
                const addBoardLabelsRes = res.data;
                await dispatch(success(addBoardLabelsRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Delete Board Label
const deleteBoardLabels = (labelId) => {
    const request = data => ({
        type: actionTypes.ADD_BOARD_LABELS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_BOARD_LABELS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_BOARD_LABELS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteBoardLabels(labelId);
            if (res.status === 200) {
                const addBoardLabelsRes = res.data;
                await dispatch(success(addBoardLabelsRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Get Tenants
const getTenants = () => {
    const request = data => ({
        type: actionTypes.GET_TENANTS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_TENANTS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_TENANTS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenants();
            if (res.status === 200) {
                const getBoardLabelsRes = res.data;
                await dispatch(success(getBoardLabelsRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Get Card Activity
const getCardActivity = (cardId) => {
    const request = data => ({
        type: actionTypes.GET_CARD_ACTIVITY_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_CARD_ACTIVITY_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_CARD_ACTIVITY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getCardActivity(cardId);
            if (res.status === 200) {
                const getCardActRes = res.data;
                await dispatch(success(getCardActRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Add Card Activity
const addCardActivity = (activityData) => {
    const request = data => ({
        type: actionTypes.ADD_CARD_ACTIVITY_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_CARD_ACTIVITY_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_CARD_ACTIVITY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addCardActivity(activityData);
            if (res.status === 200) {
                const addCardActRes = res.data;
                await dispatch(success(addCardActRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Get Card Attachments
const getCardAttachments = (cardId) => {
    const request = data => ({
        type: actionTypes.GET_CARD_ATTACHMENTS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_CARD_ATTACHMENTS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_CARD_ATTACHMENTS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getCardAttachments(cardId);
            if (res.status === 200) {
                const getCardAttachRes = res.data;
                await dispatch(success(getCardAttachRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Add Card Attachments
const addCardAttachments = (cardId, files) => {

    let cardAttachmentData = new FormData();
    if (files && files.length) {
        files.map((item, i)=>{
            cardAttachmentData.append("file", item )
        })
    }
    cardAttachmentData.append("data", JSON.stringify({cardId: cardId}));

    const request = data => ({
        type: actionTypes.ADD_CARD_ATTACHMENTS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_CARD_ATTACHMENTS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_CARD_ATTACHMENTS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addCardAttachments(cardAttachmentData);
            if (res.status === 200) {
                const addCardAttachRes = res.data;
                await dispatch(success(addCardAttachRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Delete Card Attachments
const deleteCardAttachments = (cardId, attachmentId) => {

    const request = data => ({
        type: actionTypes.DELETE_CARD_ATTACHMENTS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_CARD_ATTACHMENTS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_CARD_ATTACHMENTS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteCardAttachments(cardId, attachmentId);
            if (res.status === 200) {
                const deleteCardAttachRes = res.data;
                await dispatch(success(deleteCardAttachRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Add Custom Field
const addCustomField = (customFieldData) => {

    const request = data => ({
        type: actionTypes.ADD_CUSTOM_FIELD_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.ADD_CUSTOM_FIELD_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.ADD_CUSTOM_FIELD_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.addCustomField(customFieldData);
            if (res.status === 200) {
                const addCustomFieldRes = res.data;
                await dispatch(success(addCustomFieldRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Get Custom Fields
const getCustomFields = () => {

    const request = data => ({
        type: actionTypes.GET_CUSTOM_FIELDS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_CUSTOM_FIELDS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_CUSTOM_FIELDS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getCustomFields();
            if (res.status === 200) {
                const getCustomFieldRes = res.data;
                await dispatch(success(getCustomFieldRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Get Custom Fields
const updateCustomField = (customFieldData) => {

    const request = data => ({
        type: actionTypes.UPDATE_CUSTOM_FIELDS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.UPDATE_CUSTOM_FIELDS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.UPDATE_CUSTOM_FIELDS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateCustomField(customFieldData);
            if (res.status === 200) {
                const getCustomFieldRes = res.data;
                await dispatch(success(getCustomFieldRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//Delete Custom Fields
const deleteCustomField = (customFieldId) => {

    const request = data => ({
        type: actionTypes.DELETE_CUSTOM_FIELDS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_CUSTOM_FIELDS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_CUSTOM_FIELDS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteCustomField(customFieldId);
            if (res.status === 200) {
                const getCustomFieldRes = res.data;
                await dispatch(success(getCustomFieldRes));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

export default {
    updateBoardData,
    getBoardData,
    getBoardLabels,
    addBoardLabels,
    updateBoardLabels,
    deleteBoardLabels,
    getTenants,
    getCardActivity,
    addCardActivity,
    getCardAttachments,
    addCardAttachments,
    deleteCardAttachments,
    addCustomField,
    getCustomFields,
    updateCustomField,
    deleteCustomField,
};
