import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import action from "../../action";
import commonAction from '../../../Common/action'
import { status } from "../../constant";
import { EditorState } from "draft-js";
import NumberFormat from 'react-number-format';
import toastr from "../../../Common/components/Toaster";
import LoaderSpinner from "../../../Common/components/Loader";
import { MAIN_ROUTE } from "../../../../config/constants";


class Options extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            description: "",
            status: null,
            priority: null,
            adminUserId: null,
            cardData: props.cardData,
            uploadAttachmentsHeader: 'Upload',
            uploadAttachment: [],
            attachmentChanged: false,
            title: '',
            isSubmitted: false,
            isLoaded: true,
            contractorName: '',
            assignee: ''
        };
    }

    componentDidMount = async () => {
        await this.props.getAllAdminUsers()
        await this.props.getMaintenanceById(this.props.maintenanceId)
        this.setState({
            isLoaded: false
        })
        const { maintenanceDetailsById } = this.props
        if (maintenanceDetailsById.maintenanceTickets) {
            this.setState({
                _id: maintenanceDetailsById.maintenanceTickets._id,
                title: maintenanceDetailsById.maintenanceTickets.title,
                status: maintenanceDetailsById.maintenanceTickets.status,
                requestById: maintenanceDetailsById.maintenanceTickets.requestById ? maintenanceDetailsById.maintenanceTickets.requestById._id : null,
                priority: maintenanceDetailsById.maintenanceTickets.priority,
                adminUserId: maintenanceDetailsById.maintenanceTickets.adminUserId ? maintenanceDetailsById.maintenanceTickets.adminUserId : null,
                amount: maintenanceDetailsById.maintenanceTickets.amount,
                maintenancePicture: maintenanceDetailsById.maintenanceTickets.maintenancePicture,
                maintenanceVideo: maintenanceDetailsById.maintenanceTickets.maintenanceVideo,
                contractorName: maintenanceDetailsById.maintenanceTickets.contractorName,
                assignee: maintenanceDetailsById.maintenanceTickets.assignee
            })
        }

    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
      
        if (this.state.isSubmitted && prevProps.add != this.props.add) {
          
            if (this.props.add.success) {
                toastr.success(this.props.add.message)
            } else if (!this.props.add.success){
                toastr.error(this.props.add.message)
            }
        }
    }

    handleAddReplay = async () => {
        let replayData = {
            _id: this.props.maintenanceId,
            priority: this.state.priority,
            status: this.state.status,
            adminUserId: this.state.adminUserId


        }


        await this.props.addMaintenanceTicket(replayData)
        await this.props.getMaintenanceById(this.props.maintenanceId);
    }


    handleChange = (e) => {
        const { name, value } = e.target

        this.setState({
            ...this.state,
            [name]: value
        })
    }

    handleCancelChanges = () => {
        const { maintenanceDetailsById } = this.props
        if (maintenanceDetailsById.maintenanceTickets) {
            this.setState({
                _id: maintenanceDetailsById.maintenanceTickets._id,
                title: maintenanceDetailsById.maintenanceTickets.title,
                status: maintenanceDetailsById.maintenanceTickets.status,
                requestById: maintenanceDetailsById.maintenanceTickets.requestById ? maintenanceDetailsById.maintenanceTickets.requestById._id : null,
                priority: maintenanceDetailsById.maintenanceTickets.priority,
                adminUserId: maintenanceDetailsById.maintenanceTickets.adminUserId ? maintenanceDetailsById.maintenanceTickets.adminUserId : null,
                amount: maintenanceDetailsById.maintenanceTickets.amount,
                assignee: maintenanceDetailsById.maintenanceTickets.assignee,
                contractorName: maintenanceDetailsById.maintenanceTickets.contractorName,
            })
        }
    }

    handleUpdateMaintenance = async () => {

        this.setState({
            isSubmitted: true,
            isLoaded: true,
        })
        let data = this.state

        data = {
            ...data,
            amount: this.state.amount ? this.state.amount.toString().replace("$", "").replace(/,/g, '') : null,
            assignee: this.state.contractorName ? 3 : this.state.assignee
        }

        await this.props.maintenanceUpdate(data)
        this.setState({
            isSubmitted: false,
        })
        await this.props.getMaintenanceById(this.props.maintenanceId)
        this.setState({
            isLoaded: false
        })
    }


    render() {
        return (

            <div className="support-otr">
                {this.state.isLoaded ? <LoaderSpinner /> : null}
                <div className="responseSec">
                    <div className="formSection">
                        <div className="row outrForm p-0 m-0">
                            <div className="col-md-12">
                                <div className="row">

                                    <div className="col-md-6 formInp formSecInp">
                                        <label>Subject</label>
                                        <input
                                            type={"text"}
                                            name={"title"}
                                            onChange={this.handleChange}
                                            className={"form-control"}
                                            value={this.state.title}
                                        />
                                    </div>
                                    <div className="col-md-3 formInp formSecInp">
                                        <label>Status</label>
                                        <div className="selctOtr">
                                            <select className="form-control" name={"status"}
                                                value={this.state.status}
                                                onChange={this.handleChange}>
                                                <option>---Select----</option>
                                                {status.map(st => {
                                                    return <option value={st.value}>{st.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 formInp formSecInp">
                                        <label>Flag</label>
                                        <div className="selctOtr">
                                            <select className="form-control" name={"adminUserId"}
                                                value={this.state.adminUserId}
                                                onChange={this.handleChange}>
                                                <option>---Select----</option>
                                                {this.props.commonReducer.adminDetails
                                                    && this.props.commonReducer.adminDetails.admin
                                                    && this.props.commonReducer.adminDetails.admin.length &&
                                                    this.props.commonReducer.adminDetails.admin.map((ad) => {
                                                        return <option value={ad._id}>{ad.firstName}</option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    </div>
                                    {/*<div className="col-md-2 formInp formSecInp">*/}
                                    {/*<label>CC Recipients</label>*/}
                                    {/*<input type="text" className="form-control" placeholder="Insert Predefined Replay"/>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-1 p-0 optionText">*/}
                                    {/*<p>(Comma Separated)</p>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="row">
                                    <div className="col-md-4 formInp formSecInp">
                                        <label>Priority</label>
                                        <div className="selctOtr">
                                            <select className="form-control" name={"priority"}
                                                value={this.state.priority}
                                                onChange={this.handleChange}>
                                                <option>---Select----</option>
                                                <option value={'Low'}>Low</option>
                                                <option value={'Normal'}>Normal</option>
                                                <option value={'High'}>High</option>
                                                <option value={'Critical'}>Critical</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4 formInp formSecInp">
                                        <label>Maintenance Cost</label>
                                        <NumberFormat
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            className="form-control"
                                            name="amount"
                                            onChange={this.handleChange}
                                            value={this.state.amount}
                                        />
                                    </div> */}
                                    <div className="col-md-4 formInp formSecInp">
                                        <label>ContractorName</label>
                                        <input type="text" name={"contractorName"}
                                            onChange={this.handleChange}
                                            placeholder={"Contractor Name"}
                                            value={this.state.contractorName}
                                            className={"form-control"} />
                                    </div>

                                </div>
                                {this.state.maintenancePicture && this.state.maintenancePicture.length ? <div className="row">
                                    <div className="col-md-12 formInp formSecInp">
                                        <label>Attachments</label>
                                        <div className="col-md-12 attachfileOuter">
                                            {this.state.maintenancePicture.map((attachment, i) => {
                                                return (
                                                    <div
                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => window.open(`${MAIN_ROUTE + attachment.path}`, '_blank')}
                                                        key={i}>
                                                        <div className="col-md-12 attachFileList">
                                                            {(attachment.title.split('.').pop() === "jpg" || attachment.title.split('.').pop() === "png")
                                                                ? <img
                                                                    src={MAIN_ROUTE + attachment.path}
                                                                    alt="" />
                                                                : (attachment.title.split('.').pop() === "pdf")
                                                                    ?
                                                                    <i className="far fa-file-pdf"></i>
                                                                    : (attachment.title.split('.').pop() === "doc" || attachment.title.split('.').pop() === "docx")
                                                                        ?
                                                                        <i className="far fa-file-alt"></i>
                                                                        : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.title.split('.').pop())) &&
                                                                        <i className="far fa-file-video"></i>
                                                            }
                                                            {/* {!expenseForm.isView ? <button type="button"
                                                                className="image-close"
                                                                onClick={() => deleteAttachmentEdit(i, attachment)}>
                                                                <span aria-hidden="true">×</span>
                                                            </button> : null} */}
                                                        </div>
                                                        <p>{attachment.title}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                    : null}


                            </div>
                        </div>
                    </div>

                </div>
                <div className="centerSec">
                    <button className="btn addresponse" onClick={() => this.handleUpdateMaintenance()}><img
                        src="/images/Save.png" /> Save Changes
                    </button>
                    <button className="btn cancelchanges" onClick={() => this.handleCancelChanges()}> Cancel Changes</button>
                </div>
            </div>
        )


    }
}


const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer } = state;
    return {
        maintenanceReducer,
        maintenanceDetailsById: maintenanceReducer.maintenanceDetailsById,
        commonReducer,
        add: maintenanceReducer.maintenanceAdd,
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(Options);
