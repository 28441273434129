import * as actionTypes from "./constant";
import * as Service from "./service";

//Send new message
const sendNewMessage = (value, files) => {
    let sendMessageData = new FormData();
    if (files && files.length) {
        files.map((item, i)=>{
            sendMessageData.append("file", item )
        })
    }
    sendMessageData.append("data", JSON.stringify(value));


    const request = data => ({
        type: actionTypes.SEND_MESSAGE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.SEND_MESSAGE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.SEND_MESSAGE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.sendMessage(sendMessageData);
            if (res.status === 200) {
                const messageDetails = res.data;
                await dispatch(success(messageDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};
//Send new Chat message
const sendChatMessage = (chatData) => {

    const request = data => ({
        type: actionTypes.SEND_CHAT_MESSAGE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.SEND_CHAT_MESSAGE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.SEND_CHAT_MESSAGE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.sendChatMessage(chatData);
            if (res.status === 200) {
                const chatDetails = res.data;
                await dispatch(success(chatDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const getMailList = (params) => {

    const request = data => ({
        type: actionTypes.GET_MESSAGES_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_MESSAGES_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_MESSAGES_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getMailList(params);
            if (res.status === 200) {
                const sentMessageList = res.data;
                await dispatch(success(sentMessageList));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const getChatMessageList  = (params) => {

    const request = data => ({
        type: actionTypes.GET_CHAT_MESSAGE_LIST_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_CHAT_MESSAGE_LIST_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_CHAT_MESSAGE_LIST_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getChatMessageList(params);
            if (res.status === 200) {
                const sentMessageList = res.data;
                await dispatch(success(sentMessageList));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const getMailDetails = (params, key) => {
    const request = data => ({
        type: actionTypes.GET_MESSAGES_DETAILS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_MESSAGES_DETAILS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_MESSAGES_DETAILS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getMailDetails(params, key);
            if (res.status === 200) {
                const messageDetails = res.data;
                await dispatch(success(messageDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));
        }
    };
};

const getChatDetails = (id) => {
    const request = data => ({
        type: actionTypes.GET_CHAT_DETAILS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_CHAT_DETAILS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_CHAT_DETAILS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getChatDetails(id);
            if (res.status === 200) {
                const messageDetails = res.data;
                await dispatch(success(messageDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const getTenantEmails = () => {
    const request = data => ({
        type: actionTypes.GET_TENANT_EMAILS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_TENANT_EMAILS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_TENANT_EMAILS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantEmails();
            if (res.status === 200) {
                const tenantEmails = res.data;
                await dispatch(success(tenantEmails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const getPhoneNumbers = () => {
    const request = data => ({
        type: actionTypes.GET_TENANT_PHONE_NUMBERS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_TENANT_PHONE_NUMBERS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_TENANT_PHONE_NUMBERS_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPhoneNumbers();
            if (res.status === 200) {
                const tenantEmails = res.data;
                await dispatch(success(tenantEmails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const deleteMessage = (messageId) => {
    const request = data => ({
        type: actionTypes.DELETE_INBOX_MESSAGE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_INBOX_MESSAGE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_INBOX_MESSAGE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteMessage(messageId);
            if (res.status === 200) {
                const deleteMessaage = res.data;
                await dispatch(success(deleteMessaage));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const deleteThread = (messageId) => {
    const request = data => ({
        type: actionTypes.DELETE_INBOX_THREAD_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_INBOX_THREAD_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_INBOX_THREAD_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteThread(messageId);
            if (res.status === 200) {
                const deleteThread = res.data;
                await dispatch(success(deleteThread));

            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};



const deleteMailChecked = (value) => {
    const request = data => ({
        type: actionTypes.DELETE_INBOX_MAIL_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_INBOX_MAIL_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_INBOX_MAIL_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteMailChecked(value);
            if (res.status === 200) {
                const deleteThread = res.data;
                await dispatch(success(deleteThread));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


const deleteMessageChecked = (value) => {
    const request = data => ({
        type: actionTypes.DELETE_MESSAGE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.DELETE_MESSAGE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.DELETE_MESSAGE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteMessageChecked(value);
            if (res.status === 200) {
                const deleteThread = res.data;
                await dispatch(success(deleteThread));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const refreshMail = () => {
    const request = data => ({
        type: actionTypes.REFRESH_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.REFRESH_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.REFRESH_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.refreshMail();
            if (res.status === 200) {
                const deleteThread = res.data;
                await dispatch(success(deleteThread));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

export default {
    sendNewMessage,
    getMailList,
    getMailDetails,
    getTenantEmails,
    deleteMessage,
    getPhoneNumbers,
    sendChatMessage,
    getChatMessageList,
    getChatDetails,
    deleteThread,
    refreshMail,
    deleteMailChecked,
    deleteMessageChecked
};
