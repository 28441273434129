import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import * as validation from '../../Common/components/validation';
import usePrevious from "../../Common/components/prevProps";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastr from '../../Common/components/Toaster';
import LoaderSpinner from "../../Common/components/Loader";
import commonAction from '../../Common/action';

const AddBusiness = (props) => {

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [businessForm, setBusinessForm] = useState({
    _id: null,
    entityName: null,
    address: null,
    propertyOwnerName: null,
    email: null,
    transactionKey: null,
    apiId: null,
    // stripeKey: null,
    paymentMethod: null,
    stripeSecretKey: null,
    stripePublicKey: null
  })
  const prevPayment = usePrevious(props.payment);

  const { payment } = props;

  useEffect(() => {
    const { businessEntity } = props;
    if (businessEntity)
      setBusinessForm({
        _id: businessEntity._id,
        entityName: businessEntity.entityName,
        address: businessEntity.address,
        propertyOwnerName: businessEntity.propertyOwnerName,
        email: businessEntity.email,
        transactionKey: businessEntity.transactionKey,
        apiId: businessEntity.apiId,
        paymentMethod: businessEntity.paymentMethod,
        stripeSecretKey: businessEntity.stripeSecretKey,
        stripePublicKey: businessEntity.stripePublicKey
      })
  }, [])

  useEffect(() => {
    if (isSubmitted && prevPayment !== payment && !payment.success) {
      toastr.error(payment.message)
    } else if (isSubmitted && prevPayment !== payment && payment.success) {
      toastr.success(props.businessEntity ? "Business Entity updated Successfully" : "Business Entity created Successfully");
    }
  }, [prevPayment, payment]);


  const handleChange = (e) => {
    const { name, value } = e.target
    setBusinessForm({
      ...businessForm,
      [name]: value
    })
  }
  const validate = (value, name, type) => {
    if (submit) {
      switch (type) {
        case "required":
          return validation.REQUIRED(value, name) != true && <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
        case "number":
          return validation.NUMBER_ONLY(value, name) != true && <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
        case "letter":
          return validation.LETTER_ONLY(value, name) != true && <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
        case "email":
          return validation.EMAIL(value, name) != true && <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div>
        case "transactionKey":
          return validation.TRANSACTION_KEY(value, name) != true && <div className={"errorMsg"}>{validation.TRANSACTION_KEY(value, name)}</div>
        case "apiId":
          return validation.API_ID(value, name) != true && <div className={"errorMsg"}>{validation.API_ID(value, name)}</div>
      }
    } else return null
  }

  const { propertyOwnerName, email, transactionKey, apiId, entityName, address, stripePublicKey, stripeSecretKey, paymentMethod } = businessForm;

  const handleSubmit = async () => {
    setSubmit(true);
    if (propertyOwnerName && email && entityName && validation.EMAIL(email, 'email') == true) {
      if (paymentMethod == "authorize") {
        if (transactionKey && apiId && validation.TRANSACTION_KEY(transactionKey, 'transactionKey') == true
          && validation.API_ID(apiId, 'apiId') == true) {
          setIsSubmitted(true);
          await props.addBusinessEntity(businessForm);
          await props.getPayment({ limit: 10, offset: 0 });
          setTimeout(() => {
            setIsSubmitted(false)
            props.onCancel();
            setBusinessForm({});
          }, 1000)
        }
      }
      else if (paymentMethod == "stripe") {
        if (stripePublicKey && stripeSecretKey) {
          setIsSubmitted(true);
          await props.addBusinessEntity(businessForm);
          await props.getPayment({ limit: 10, offset: 0 });
          setTimeout(() => {
            setIsSubmitted(false)
            props.onCancel();
            setBusinessForm({});
          }, 1000)
        }
      }

    }

  }

  return (
    <div className="modal addTenant settingsModal" id="modalId" style={{ display: "block" }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
      <ToastContainer />
      {isSubmitted && <LoaderSpinner />}
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.businessEntity ? "Edit Business Entity" : "Add Business Entity"}
            </h5>
            <button type="button" className="close" onClick={() => props.onCancel()} aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="clientInfo ">
              <h3>Business Entity</h3>
              <div className="formSection">
                <div className="row outrForm">
                  <div className="col-md-12 FormrightOutr">
                    <div className="row">
                      <div className="col-md-4 formInp formSecInp">
                        <label>Entity Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="entityName"
                          onChange={(e) => handleChange(e)}
                          value={entityName}
                          maxLength="25"
                        />
                        {validate(entityName, "Entity Name", "required")}
                      </div>
                      <div className="col-md-4 formInp formSecInp">
                        <label>Owner Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Owner Name"
                          name="propertyOwnerName"
                          maxLength="25"
                          onChange={(e) => handleChange(e)}
                          value={propertyOwnerName}
                        />
                        {validate(propertyOwnerName, "Owner Name", "required")}
                        {propertyOwnerName && validate(propertyOwnerName, "Owner Name", "letter")}
                      </div>
                      <div className="col-md-4 formInp formSecInp">
                        <label>Email </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          onChange={(e) => handleChange(e)}
                          value={email}
                        />
                        {validate(email, "Email", "required")}
                        {email && validate(email, "Email", "email")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 formInp">
                        <label>Address</label>
                        <textarea
                          rows="3"
                          className="form-control addressResize"
                          placeholder="Address"
                          name="address"
                          onChange={(e) => handleChange(e)}
                          value={address}
                        ></textarea>
                        {/* {validate(address, "Address", "required")} */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 formInp">
                        <label>Choose Payment Method</label>
                        <div className="selctOtr">
                          <select className="form-control"
                            name={"paymentMethod"}
                            value={paymentMethod}
                            onChange={(e) => handleChange(e)}>
                            <option value={null} >Select</option>
                            <option value={"authorize"}>Authorize .Net</option>
                            <option value={"stripe"}>Stripe</option>
                          </select>
                        </div>
                        {validate(paymentMethod, "Payment Method", "required")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex propertySec p-0">
              <div className="clientInfo propOtr">
                <h3>Authorized.Net API Details</h3>
                <div className="formSection">
                  <div className="row outrForm">
                    <div className="col-md-6 formInp formSecInp">
                      <label>API ID </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="API ID"
                        name="apiId"
                        onChange={(e) => handleChange(e)}
                        value={apiId}
                        maxLength="30"
                      />
                      {paymentMethod == "authorize" ? validate(apiId, "API ID ", "required") : null}
                      {apiId && validate(apiId, "API ID", "apiId")}
                    </div>
                    <div className="col-md-6 formInp formSecInp">
                      <label>Transaction Key</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Transaction Key"
                        name="transactionKey"
                        onChange={(e) => handleChange(e)}
                        value={transactionKey}
                        maxLength="16"
                      />
                      {paymentMethod == "authorize" ? validate(transactionKey, "Transaction Key", "required") : null}
                      {transactionKey && validate(transactionKey, "Transaction Key", "transactionKey")}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex propertySec p-0">
              <div className="clientInfo propOtr">
                <h3>Stripe Details</h3>
                <div className="formSection">
                  <div className="row outrForm">
                    <div className="col-md-6 formInp formSecInp">
                      <label> Secret Key </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Private Key"
                        name="stripeSecretKey"
                        onChange={(e) => handleChange(e)}
                        value={stripeSecretKey}
                      
                      />
                      {paymentMethod == "stripe" ? validate(stripeSecretKey, "Secret Key ", "required") : null}
                    </div>
                    <div className="col-md-6 formInp formSecInp">
                      <label> Public Key </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Public Key"
                        name="stripePublicKey"
                        onChange={(e) => handleChange(e)}
                        value={stripePublicKey}
                     
                      />
                      {paymentMethod == "stripe" ? validate(stripePublicKey, "Public Key ", "required") : null}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-primary btnSubmit" onClick={(e) => handleSubmit(e)}>
              Submit
          </button>
          </div>
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = state => {
  const { settingsReducer, commonReducer } = state;
  return {
    payment: settingsReducer.payment,
    commonReducer
  };
};

export default connect(mapStateToProps, {
  ...action, ...commonAction
})(AddBusiness);
