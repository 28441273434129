import * as Service from './service';
import * as Constant from './constant';


const addReport = (report) => {
    const request = () => ({ type: Constant.ADD_REPORT_REQUEST });
    const success = reportAction => ({
        type: Constant.ADD_REPORT_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.ADD_REPORT_FAILURE,
        error
    });
    const data = [...report.reportDocuments];
    let businessData = new FormData();
    businessData.append('data', JSON.stringify(report))
    data.length && data.map((image) => businessData.append(`file`, image));

    return async dispatch => {
        try {
            dispatch(request());
            const res = report._id ? await Service.updateReport(businessData) : await Service.addReport(businessData);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const getReport = (params) => {
    const request = () => ({ type: Constant.GET_REPORT_REQUEST });
    const success = reportAction => ({
        type: Constant.GET_REPORT_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.GET_REPORT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getReport(params);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails.data));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const deleteReport = (id) => {
    const request = () => ({ type: Constant.DELETE_REPORT_REQUEST });
    const success = id => ({
        type: Constant.DELETE_REPORT_SUCCESS,
        id
    });
    const failure = error => ({
        type: Constant.DELETE_REPORT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.deleteReport({id: id});
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(id));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

const inviteScreening = (params) => {
    const request = () => ({ type: Constant.INVITE_SCREENING_REQUEST });
    const success = reportAction => ({
        type: Constant.INVITE_SCREENING_SUCCESS,
        reportAction
    });
    const failure = error => ({
        type: Constant.INVITE_SCREENING_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.inviteScreening(params);
            if (res.status === 200) {
                const reportDetails = res.data;
                if (reportDetails.success) {
                    dispatch(success(reportDetails));
                } else {
                    dispatch(failure(reportDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


export default {
    addReport,
    getReport,
    deleteReport,
    inviteScreening
}
