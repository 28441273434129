import React from "react";
import action from "../action";
import {connect} from "react-redux";
import MessageListItem from "./MessageListItem";
import {withRouter} from "react-router-dom"
import Pagination from '../../Common/components/pagination';
import SuccessModal from "./successModal";
import SideBar from "./messagingSideBar";

class EmailContainer extends React.Component {
    render() {
        const {
            listParams,
            messageList,
            handleDeleteMessage,
            totalMessages,
            currentPage,
            handlePagination,
            hideSuccessModal,
            pathname,
            showSuccess,
            deleteMessages
        } = this.props;

        return (
            <div className="messageOuter">
                <SideBar pathname={pathname}/>
                <div className="emailOuter">
                    <div className="inboxInner">
                        <div className="email-img">
                            <span>{listParams.type === 'inbox' ? 'Inbox' : 'Sent'}</span>
                        </div>
                        <button className={"btn  btn-add"} onClick={deleteMessages}>Delete</button>
                    </div>
                    <div className="inboxWrapper">
                        <div className="inboxMail">
                            <div className="innago-table emailTable">
                                {messageList && messageList.length
                                    ? <div className="table-responsive">
                                        <table className="table-gap table-td invoiceTable">
                                            <tbody>
                                            {messageList.map((item, i) => {
                                                let isChecked=this.props.deleteArray&&this.props.deleteArray.length ? this.props.deleteArray.find(da=>da.id==item.messageId):false
                                                return <MessageListItem
                                                    messageItem={item}
                                                    listParams={listParams}
                                                    handleDeleteMessage={handleDeleteMessage}
                                                    key={i}
                                                    handleCheck={this.props.handleCheck}
                                                    isChecked={isChecked}
                                                    deleteArray={this.props.deleteArray}
                                                />
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    : <div className="no_value">
                                        <i className="far fa-thumbs-down"/>No Data Found!!!
                                    </div>
                                }
                                <div className="paginationSec">
                                    {totalMessages ? <Pagination
                                        totalRecords={totalMessages}
                                        pageLimit={listParams.limit}
                                        pageNeighbours={1}
                                        currentPage={currentPage}
                                        onPageChanged={handlePagination}
                                    /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    {showSuccess ?
                        <div className="modal addTenant addMessage" style={{display: 'block'}}>
                            <SuccessModal
                                hideSuccessModal={hideSuccessModal}
                                modalContents={{
                                    title: 'Deleted !',
                                    message: 'Mail deleted successfully !!'
                                }}
                            />
                        </div> : null
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(EmailContainer));