import React from "react";
import action from "./action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"
import commonAction from '../Common/action'
import history from '../../config/history'
import DataSource from './components/dataSources';
import Transaction from './components/Transactions';
import PlaidLinkButton from "react-plaid-link";
import axios from "axios";
import LoaderSpinner from "../Common/components/Loader";
import { PLAID_KEY, PLAID_ENV } from "../../config/constants";


class PlaidContainer extends React.Component {
    state = {
        isActiveDataSource: true,
        isActiveTransaction: false,
        transactions: [],
        isSubmitted: false
    }


    handleTab = async (tab) => {
        if (tab === "datasource") {
            this.setState({
                isActiveDataSource: true,
                isActiveTransaction: false
            }, () => history.push('/DataSource'))
        } else {
            this.setState({
                isActiveTransaction: true,
                isActiveDataSource: false
            }, () => history.push('/Transaction'))
        }

    }

    componentDidMount = () => {

    }

    handleOnSuccess = async (public_token, metadata) => {
        //send token to client server
        // axios.post("/auth/public_token", {
        //     public_token: public_token
        // });
        this.setState({isSubmitted: true})
        await this.props.postPlaidRequest({publicToken: public_token})
        this.setState({isSubmitted: false})

        await this.props.getDataSource({limit: 5, offset: 0})
        await this.props.getTransaction({limit: 5, offset: 0})
    }

    handleOnExit = () => {
        // handle the case when your user exits Link
        // For the sake of this tutorial, we're not going to be doing anything here.
    }

    handleClick = (res) => {
        axios.get("/transactions").then(res => {
            this.setState({transactions: res.data});
        });
    }

    render() {
        const {location: {pathname}} = this.props;
        return (
            <>
                <div className="tenants-otr">
                    {this.state.isSubmitted ? <LoaderSpinner/> : null}
                    <div className="innago-table-top">
                        <div className="lft">
                            <h1>{pathname === '/DataSource' ? 'Data Source' : 'Transaction'}</h1>
                        </div>
                        <div className="rgt">
                            {/*<button className="add" onClick={() => history.push(`${this.props.match.url}?compose=true`)}>*/}
                            {/*<svg>*/}
                            {/*<use xlinkHref="/images/svg-sprite.svg#ic-add"/>*/}
                            {/*</svg>*/}
                            {/*New Email*/}
                            {/*</button>*/}
                            <PlaidLinkButton
                                clientName="React Plaid Setup"
                                env={PLAID_ENV}
                                product={["auth", "transactions"]}
                                publicKey={PLAID_KEY}
                                onExit={this.handleOnExit}
                                onSuccess={this.handleOnSuccess}
                                className="add plaidbtn"
                            >
                                <button className="add">
                                    <svg>
                                        <use xlinkHref="/images/svg-sprite.svg#ic-add"/>
                                    </svg>

                                    Add Account
                                </button>
                                {/*Open Link and connect your bank!*/}
                            </PlaidLinkButton>
                            {/*}*/}

                            {/*{this.renderModalNewMessage()}*/}
                            {/*{(pathname === '/Messaging/Email/Inbox' || pathname === '/Messaging/Email/Sent' || pathname === '/Messaging')*/}
                            {/*? <MessagingFilter updateFilterParams={this.props.updateFilterParams}/>*/}
                            {/*: null*/}
                            {/*}*/}
                        </div>
                    </div>
                    <div className="chatNav">
                        <div className="nav nav-pills">

                            <a className={`nav-item nav-link ${pathname == '/DataSource' ? 'active' : ''} `}
                               onClick={() => this.handleTab("datasource")}>Data
                                Source</a>
                            <a className={`nav-item nav-link  ${pathname == '/Transaction' ? 'active' : ''}`}
                               onClick={() => this.handleTab("transaction ")}>Transaction</a>

                        </div>
                    </div>
                    {pathname == '/DataSource' ? <DataSource/> :
                        null}
                    {pathname == '/Transaction' ? <Transaction/> :
                        null}

                </div>

            </>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer, profileReducer, commonReducer,} = state;
    return {messageReducer, profileDetail: profileReducer.profileDetail, commonReducer}
};

export default withRouter(connect(mapStateToProps, {...action, ...commonAction})(PlaidContainer));
