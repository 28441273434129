import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import commonAction from "../../../Common/action";
import TenantAction from "../../../Invoice/action"
import action from "../../action";
import moment from "moment";
import { numberWithCommas } from "../../../Common/components/utils";
import history from '../../../../config/history'
import toastr from '../../../Common/components/Toaster';
import LoaderSpinner from "../../../Common/components/Loader";
import Modal from "../../../Common/components/Modal";
import CardList from '../../../Invoice/TenantComponents/cardList';
import usePrevious from "../../../Common/components/prevProps";

const UnPaidInvoice = props => {

    const [array, setArray] = useState([])
    const [isButtonActive, setButtonActive] = useState(false)
    const [paymentSubmit, setPaymentSubmit] = useState(false)
    const [cardModal, setCardModal] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null)

    const { profileDetail: { profileDetail } } = props;
    let cardAdded = 0;
    if (profileDetail)
        cardAdded = profileDetail.admin.tenantId.cardDetails && profileDetail.admin.tenantId.cardDetails.length;


    const prevPaymentData = usePrevious(props.payment);

    useEffect(() => {
        if (paymentSubmit && prevPaymentData != props.payment && props.payment.success)
            toastr.success(props.payment.message)
        else if (paymentSubmit && prevPaymentData != props.payment && !props.payment.success)
            if (props.payment.data && props.payment.data.length && props.payment.data.length == 1) {
                let msg = `${props.payment.message} due to ${props.payment.data[0].message}`
                toastr.error(msg)
            }
            else {
                toastr.error(props.payment.message)
            }
    }, [prevPaymentData, props.payment])

    useEffect(() => {
        props.getTenantUnPaidInvoice()
    }, []);

    useEffect(() => {
        if (paymentSubmit && props.payment.success)
            toastr.success(props.payment.message)
        else
            if (paymentSubmit && !props.payment.success)
                toastr.error(props.payment.message)
    }, [props.payment])

    const handleViewMore = () => {
        history.push('/Invoice/All')
    }

    const handleCheck = (e, invoice) => {
        let tempArray = array;
        if (e.target.checked) {

            //set unpaid invoices id for delete and mark as paid
            setButtonActive(true);
            tempArray.push(invoice._id);

            return setArray(tempArray)
        } else {
            tempArray = tempArray.filter(t => t !== invoice.id)
            if (!tempArray.length) setButtonActive(false);
            return setArray(tempArray)
        }
    }

    // const makePayment = async (e) => {
    //     e.preventDefault();
    //
    //     if (array.length) {
    //         if (cardAdded > 0) {
    //             setPaymentSubmit(true);
    //             await props.makeMultiplePayment({ invoiceId: array })
    //             setTimeout(async() => {
    //                 await props.getTenantUnPaidInvoice();
    //                 await props.getTenantInvoices({limit:10, offset:0})
    //                 setPaymentSubmit(false)
    //             }, 500)
    //         }
    //         else
    //             toastr.error("Please add your card details");
    //     }
    //     else
    //         toastr.error("Select an invoice");
    //
    //
    // }


    const makePayment = async (e) => {
        e.preventDefault();
        if (array.length) {
            //     if (cardAdded > 0) {

            setCardModal(true)

            // }
            // else
            //     toastr.error("Please add your card details");
        }
        else
            toastr.error("Select an invoice");




    }

    const payInvoice = async () => {
        setCardModal(false)
        setPaymentSubmit(true);
        await props.makeMultiplePayment({ invoiceId: array })

        setTimeout(async () => {
            await props.getTenantUnPaidInvoice();
            await props.getTenantInvoices({ limit: 10, offset: 0 })
            setPaymentSubmit(false)
        }, 500)


    }

    const closeCardModal = () => {
        setCardModal(false)
    }

    const renderCardList = () => {
        const { profileDetail: { profileDetail } } = props;
        return <CardList
            onCancel={closeCardModal}
            id={profileDetail.admin.tenantId._id}
            payInvoiceData={payInvoice}
            tenatDetailView={false}

        />
    }

    return (
        <div className="payable-now mt-3">
            {paymentSubmit && <LoaderSpinner />}
            {cardModal ? <Modal body={renderCardList()} onCancel={closeCardModal} /> : null}
            <div className="payable-head">
                <img src="images/money.png" />
                <p>Payable Now</p>
            </div>
            <div className="payable-middle">
                <h5>Suggested Payment</h5>
                <p><span>$</span>{props.unPaidInvoiceDetailsByTenant && props.unPaidInvoiceDetailsByTenant.unPaid ?
                    numberWithCommas(props.unPaidInvoiceDetailsByTenant.unPaid) : 0
                }</p>
                <p className="middle-text">
                    Due Date: {props.unPaidInvoiceDetailsByTenant && props.unPaidInvoiceDetailsByTenant.invoice && props.unPaidInvoiceDetailsByTenant.invoice.length ?
                        moment(props.unPaidInvoiceDetailsByTenant.invoice[0]).format('MM/DD/YYYY') : 'N/A'}<br />
                    Total Outstanding:
                    ${props.unPaidInvoiceDetailsByTenant && props.unPaidInvoiceDetailsByTenant.unPaid ?
                        numberWithCommas(props.unPaidInvoiceDetailsByTenant.unPaid) : 0
                    }
                </p>
                {props.unPaidInvoiceDetailsByTenant && props.unPaidInvoiceDetailsByTenant.invoice && props.unPaidInvoiceDetailsByTenant.invoice.length ? <>
                    {/* <button
                        className="btn btn-link"
                        type="button"
                        // data-toggle="collapse"
                        // data-target="#collapseOne"
                        aria-expanded="false"
                        //aria-controls="collapseOne"
                    >
                        <i className="fas fa-chevron-down"></i>
                    </button> */}

                    <div className="payable-table table-responsive" >
                        <table>
                            <thead>
                                <th className="checkboxWidth">
                                    {/* <label className="payableCheckbox"
                                ><input type="checkbox" name="isArchived"/><span
                                    className="checkmark"
                                ></span
                                ></label> */}
                                </th>
                                <th>INVOICE NUMBER</th>
                                <th>YOUR BREAKDOWN</th>
                                <th>AMOUNT</th>
                                <th>DUE DATE</th>
                                <th className="status-width">STATUS</th>
                            </thead>

                            {props.unPaidInvoiceDetailsByTenant.invoice.map((invoice) => {
                                return <tr>
                                    <td>
                                        <label className="payableCheckbox">
                                            <input type="checkbox" disabled={invoice.paymentTypeId.name == "Cash"}onChange={(e) => handleCheck(e, invoice)} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>
                                    <td>{invoice.invoiceNumber}</td>
                                    <td>{invoice.invoiceTypeId ? invoice.invoiceTypeId.name : null}</td>
                                    <td>${numberWithCommas(invoice.amount)}</td>
                                    <td>{moment(invoice.dueDate).format('MM/DD/YYYY')}</td>
                                    <td>
                                        {moment().diff(moment(invoice.dueDate), 'days') > 0 ?
                                            <span className="status colr-red">OVERDUE</span>
                                            :
                                            <span className="status">UNPAID</span>
                                        }
                                    </td>
                                </tr>
                            })}


                        </table>
                    </div>
                    <div className="payable-footer">
                        <button className="btn" onClick={(e) => makePayment(e)} >Make Payment</button>
                    </div>
                </> : null}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { commonReducer, profileReducer, dashboardReducer } = state;
    return {
        unPaidInvoiceDetailsByTenant: commonReducer.unPaidInvoiceDetailsByTenant,
        profileDetail: profileReducer.profileDetail,
        payment: dashboardReducer.payment
    };
}

export default withRouter(connect(mapStateToProps, { ...commonAction, ...action, ...TenantAction })(UnPaidInvoice));

