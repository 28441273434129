import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import commonAction from '../../Common/action'
import * as validation from '../../Common/components/validation';
import action from '../action';
import LoaderSpinner from "../../Common/components/Loader";
import toastr from '../../Common/components/Toaster';
import history from '../../../config/history';
import { formatUsPhone } from "../../Common/components/utils";

const AddExpense = (props) => {
    const [userForm, setUserForm] = useState({
        id: '',
        userName: "",
        password: '',
        confirmPassword: null,
        businessEntityId: null,
        emailHost: null,
        emailType: null

    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [role, setRole] = useState('');

    useEffect(() => {
        const { mailData } = props;
        props.getPayment()
        const { profileReducer: { profileDetail } } = props;
        let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
        setRole(role);
        if (mailData)
            setUserForm({
                id: mailData._id,
                userName: mailData.userName,
                emailHost: mailData.emailHost,
                // password: mailData.password,
                // confirmPassword: mailData.password,
                emailType: mailData.emailType
            })
    }, []);

    const { settingsReducer: { addUser } } = props;

    useEffect(() => {
        if (submit && !addUser.success) {
            toastr.error(addUser.message)
        } else if (submit && addUser.success) {
            toastr.success(addUser.message);
        }
    }, [addUser]);

    const handleChange = async (e) => {
        const { name, value } = e.target
        if (name === "phoneNumber") {
            setUserForm({ ...userForm, [name]: formatUsPhone(value) })

        } else {
            setUserForm({ ...userForm, [name]: value })
        }
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
                case "email":
                    return (
                        validation.EMAIL(value, name) != true ?
                            <div className={"errorMsg"}>*{validation.EMAIL(value, name)}</div> : null
                    )
                case "phone":
                    return (
                        validation.PHONE_NO(value.replace(/\D/g, ''), name) ?
                            <div className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div>
                            :
                            null
                    )
                case "password":
                    return (validation.PASSWORD(value, name) !== true ?
                        <div className={"errorMsg"}>{validation.PASSWORD(value, name)}</div>
                        :
                        null)

                case "letter":
                    return validation.LETTER_ONLY(value, name) &&
                        <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "password match":
                    return (
                        validation.UNMATCHED_PASSWORDS(value, name) != true ? <div className={"errorMsg"}>
                            {validation.UNMATCHED_PASSWORDS(value, name)}
                        </div> : null
                    );

            }
        } else return null;
    };

    const handleChangeUser = (e, value) => {
        if (e.target.checked) {
            setUserForm({ ...userForm, role: value })
        } else {
            setUserForm({ ...userForm, role: null })
        }
    }

    const addUserDetails = async () => {
        setIsSubmitted(true);
        const { userName, id, password, confirmPassword, emailHost, emailType } = userForm;
        if (userName && validation.EMAIL(userName, 'email') == true && password &&
            (password === confirmPassword) && emailHost && emailType &&
            validation.UNMATCHED_PASSWORDS(confirmPassword, password)
        ) {
            setSubmit(true)
            let userData = {
                id: id && id,
                userName: userForm.userName,
                password: userForm.password,
                emailHost: userForm.emailHost,
                emailType: emailType

            }
            await props.addMailAccountData(userData)
            setTimeout(() => {
                setIsSubmitted(false)
                setSubmit(false)
                props.onCancel()
            }, 1000)
            await props.getMailDetails(props.params);
            !userData.id && history.push('/Email/Settings')
        }
    }

    return (
        <div className="modal addTenant small-modal" id="modalId" style={{ display: "block" }}>
            {submit && <LoaderSpinner />}
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"
                            id="exampleModalLabel">{props.mailData ? "Update Mail" : "Add Mail"}</h5>
                        <button type="button" className="close" onClick={props.onCancel}>
                            {/* <h3>Add User</h3> */}
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <h3>Mail Information</h3>
                            <div className="formSection">
                                <div className="row outrForm p-0 m-0">
                                    <div className="col-md-12 FormrightOutr">
                                        <div className="row">

                                            <div className="col-md-12 formInp formSecInp">
                                                <label>Email</label>
                                                <input type="text" className="form-control"
                                                    autoComplete={false}
                                                    placeholder="Enter Email"
                                                    value={userForm.userName}
                                                    name={"userName"} onChange={handleChange} />
                                                {validate(userForm.userName, "Email Address", "required")}
                                                {userForm.userName && validate(userForm.userName, "Email Address", "email")}
                                            </div>

                                            <div className="col-md-12 formInp formSecInp">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    autoComplete={false}
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    value={userForm.password}
                                                    onChange={handleChange}
                                                    name={"password"}
                                                />
                                                {validate(userForm.password, "Password", "required")}

                                            </div>
                                            <div className="col-md-12 formInp formSecInp">
                                                <label>Confirm Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Confirm Password"
                                                    name={"confirmPassword"}
                                                    value={userForm.confirmPassword}
                                                    onChange={handleChange}

                                                />
                                                {validate(userForm.confirmPassword, "Confirm Password", "required")}
                                                {userForm.confirmPassword && userForm.password && validate(userForm.confirmPassword, userForm.password, "password match")}
                                            </div>
                                            {/* <div className="col-md-12 formInp formSecInp">
                                                <label>Property Owner</label>
                                                <div className="selctOtr">
                                                    <select
                                                        className="form-control"
                                                        name="businessEntityId"
                                                        value={userForm.businessEntityId}
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <option value={0}>Select Property Owner</option>
                                                        {
                                                            props.commonReducer.paymentList.payment && props.commonReducer.paymentList.payment.map((pmnt, index) => {
                                                                return <option key={index}
                                                                    value={pmnt._id}>{pmnt.entityName}</option>
                                                            })
                                                        }
                                                    </select>

                                                </div>
                                            </div> */}
                                            <div className="col-md-12 formInp formSecInp">
                                                <label>SMTP Server</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="SMPT Server"
                                                        name={"emailHost"}
                                                        value={userForm.emailHost}
                                                        onChange={e => handleChange(e)}

                                                    />
                                                    {/* <select
                                                        className="form-control"
                                                        name="emailHost"
                                                        value={userForm.emailHost}
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <option value={0}>Select Email Host</option>
                                                        <option value={"imap.gmail.com"}>Gmail</option>
                                                    </select> */}
                                                    {validate(userForm.emailHost, "SMTP Server", "required")}
                                            </div>
                                            <div className="col-md-12 formInp formSecInp">
                                                <label>Email Type</label>
                                                <div className="selctOtr">
                                                    <select
                                                        className="form-control"
                                                        name="emailType"
                                                        value={userForm.emailType}
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <option value={0}>Select Email Type</option>
                                                        <option value={"notification"}>Notification</option>
                                                        <option value={"maintenance"}>Maintenance</option>
                                                    </select>
                                                    {validate(userForm.emailType, "Email Type", "required")}
                                                </div>
                                            </div>

                                            {/* <div className="col-md-12 formInp formSecInp">
                                                <label>Port Address</label>
                                                <input type="text" className="form-control"
                                                    autoComplete={false}
                                                    placeholder="Enter Port Address" value={userForm.emailHost}
                                                    name={"emailHost"} onChange={handleChange} />
                                                {validate(userForm.emailHost, "Email Host", "required")}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit"
                            onClick={addUserDetails}
                        >{props.mailData ? "Update Mail" : "Add Mail"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(userForm) {
    const { settingsReducer, commonReducer, profileReducer } = userForm;
    return {
        commonReducer,
        settingsReducer,
        profileReducer
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction })(
    AddExpense
);
