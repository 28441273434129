import React, {useState, useEffect} from "react";
import action from "../../action";
import {connect} from "react-redux";
import commonAction from '../../../Common/action';
import Modal from '../../../Common/components/Modal';
import invoiceAction from '../../../Invoice/action';
import AddInvoice from '../../../Invoice/components/AddInvoice'
import usePrevious from '../../../Common/components/prevProps';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {numberWithCommas} from "../../../Common/components/utils";
import history from '../../../../config/history'

const Invoices = (props) => {

    const [InvoiceModal, setInvoiceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const [params, setParams] = useState({limit: 5, offset: 0, invoiceTypeId: null, propertyId: null})
    const prevUser = usePrevious(props.invoiceAdd);

    const {tenantById, invoiceAdd} = props;

    useEffect(() => {
        if (isSubmitted && prevUser !== invoiceAdd && !invoiceAdd.success) {
            toast.error(invoiceAdd.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else if (isSubmitted && prevUser !== invoiceAdd && invoiceAdd.success) {
            toast.success("Invoice created Successfully", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [prevUser, invoiceAdd]);

    const addInvoiceDetails = async (invoiceForm) => {
        setIsSubmitted(true)
        await props.addInvoice(invoiceForm)
        await props.reloadTenant()
        setInvoiceModal(false)
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000);
    }

    const openModal = () => {
        setInvoiceModal(true)
    }

    const closeModal = () => {
        setInvoiceModal(false)
    }

    const renderModal = () => {
        const {tenantById: {tenant}} = props;
        return <AddInvoice
            addInvoiceDetails={addInvoiceDetails}
            onCancel={closeModal}
            tenantId={[{value: props.id, label: tenant.firstName + " " + tenant.lastName}]}
        />
    }

    const getPayment = () => {
        history.push('/plaidData')
    }

    return (
        <div className="InvoiceOtr outrTabBg col-md-5 widbx1">
            {InvoiceModal ? <Modal body={renderModal()} onCancel={closeModal}/> : null}
            <div className="brdOut">
                <h3>Invoices / Billing</h3>
                <div className="contentInv text-center">
                    <h4>Total Income</h4>
                    <h2>${tenantById && tenantById.totalAmount && numberWithCommas(tenantById.totalAmount) || 0.00}</h2>
                </div>
                {tenantById && tenantById.tenant && !tenantById.tenant.isClosed ?
                    <div className="col-md-12 otrpaid" onClick={() => history.push(`/Tenants/${props.id}/Invoices`)}>
                        <div className="col-md-6 listPaid">
                            <p className="textPaid">Paid</p>
                            <h4>${tenantById && numberWithCommas(tenantById.Paid) || 0.00}</h4>
                        </div>
                        <div className="col-md-6 listPaid otrdue">
                            <p className="textPaid">Unpaid / Due</p>
                            <h4>${tenantById && numberWithCommas(tenantById.unPaid)}</h4>
                        </div>
                    </div> :
                    <div className="col-md-12 otrpaid">
                        <div className="col-md-6 listPaid">
                            <p className="textPaid">Paid</p>
                            <h4>${tenantById && numberWithCommas(tenantById.Paid) || 0.00}</h4>
                        </div>
                        <div className="col-md-6 listPaid otrdue">
                            <p className="textPaid">Unpaid / Due</p>
                            <h4>${tenantById && numberWithCommas(tenantById.unPaid)}</h4>
                        </div>
                    </div>}

            </div>
            {/* <div className="btnOtrSec text-center">
                <button type="button" className="btn btnCard">
                    <img src="/images/card.png"/>
                    <span className="btnTxt" onClick={()=>getPayment()}>Generate Due Invoice</span>
                </button>
                <button
                    disabled={tenantById && tenantById.tenant.tenantStatus == 0 || tenantById &&
                    tenantById.tenant && tenantById.tenant.isDeleted}
                    type="button" className="btn btnCard btnBlue" onClick={() => openModal()}>
                    <img src="/images/invoice.png"/>
                    <span className="btnTxt">Create Invoice</span>
                </button>
            </div> */}
        </div>
    )
}

const mapStateToProps = state => {
    const {tenantReducer, commonReducer, invoiceReducer} = state;
    return {
        tenantReducer,
        commonReducer,
        invoiceAdd: invoiceReducer.Invoice,
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...invoiceAction
})(Invoices);
