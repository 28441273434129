import React from "react";
import action from "../action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"
import moment from 'moment';
import ContentEditable from "react-contenteditable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MAIN_ROUTE } from "../../../config/constants";
import CommonDatePicker from "../../Common/components/CommonDatePicker";

class CardDetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showEditDescription: false,
            showEditActivity: false,
            showActivityDetails: false,
            cardData: props.cardData,
            uploadAttachmentsHeader: 'Upload',
            uploadAttachment: [],
            attachmentChanged: false,
            showLabelPopup: false,
            showCreateLabelPopup: false,
            showMemberPopup: false,
            showCustomFieldPopup: false,
            showNewCustomField: false,
            showDueDatePopup: false,
            tempLabels: props.cardData.labels || [],
            currentLabelData: {},
            currentCustomFieldData: {},
            currentOptionData: {},
            boardLabelsList: [],
            boardCustomFieldsList: [],
            cardAttachmentList: [],
            tenantList: [],
            tenantListSearchResult: [],
            dueDate: props.cardData.dueDate ? new Date(props.cardData.dueDate) : '',
            dueTime: props.cardData.dueTime ? new Date(props.cardData.dueTime) : '',
            labelColors: ["green", "yellow", "orange", "red", "purple", "blue", "sky", "lime", "pink", "black", "grey"],
            labelPopupType: 'create',
            activityComment: '',
            showOptionColors: false,
            customFieldType: 0,
            customFieldName: '',
            currentCustomFieldOptions: [],
            currentOptionName: '',
            customFieldPopupType: 'create',
            showAddNewTenantForm: false,
            newTenantData: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: ''
            },
            tenantSearchString: '',
            boardTenants: [],
        }
    }

    componentDidMount = async () => {
        await this.props.getBoardLabels();
        await this.props.getTenants();
        await this.props.getCustomFields();
        await this.refreshCardActivity();
        await this.refreshCardAttachments();
        const { boardReducer: { boardLabels, tenantList, boardActivity, customFields }, boardTenants } = this.props;
        let tempTenantList = [];
        tenantList.length && tenantList.map((item, i) => {
            tempTenantList.push({
                _id: item._id,
                firstName: item.firstName,
                lastName: item.lastName,
                name: item.firstName + ' ' + item.lastName,
                email: item.email,
                phoneNumber: item.phoneNumber,
            })
        });
        await this.setState({
            boardLabelsList: boardLabels,
            boardCustomFieldsList: customFields,
            tenantList: [...tempTenantList, ...boardTenants],
            tenantListSearchResult: [...tempTenantList, ...boardTenants],
            boardTenants: boardTenants
        });
        const { cardData } = this.state;
        if (!cardData.customFields || !cardData.customFields.length) {
            await this.setState({
                cardData: {
                    ...cardData,
                    customFields: customFields
                }
            });
        }
    };

    handleAddCardActivity = async (activityData) => {
        await this.props.addCardActivity(activityData);
        await this.refreshCardActivity();
    };

    refreshCardActivity = async () => {
        const { cardData } = this.state;
        await this.props.getCardActivity(cardData.id);
        const { boardReducer: { boardActivity } } = this.props;
        await this.setState({
            cardActivityList: boardActivity
        });
        return true;
    };

    refreshCardAttachments = async () => {
        const { cardData } = this.state;
        await this.props.getCardAttachments(cardData.id);
        const { boardReducer: { cardAttachments } } = this.props;
        await this.setState({
            cardAttachmentList: cardAttachments,
            cardData: {
                ...cardData,
                attachments: cardAttachments && (cardAttachments.attachments || [])
            }
        });
        this.handleCardDataUpdate();
        return true;
    };

    handleShowEditDescription = async () => {
        const { showEditDescription, cardData } = this.state;
        await this.setState({
            showEditDescription: !showEditDescription,
        });
        if (showEditDescription) {
            await this.setState({
                cardData: {
                    ...cardData,
                    description: this.props.cardData.description
                }
            })
        }
        document.getElementById('commentsTextarea') && document.getElementById('commentsTextarea').focus();
    };

    handleShowEditActivity = async () => {
        const { showEditActivity } = this.state;
        await this.setState({
            showEditActivity: !showEditActivity
        });
        document.getElementById('activityTextarea') && document.getElementById('activityTextarea').focus();
    };

    handleShowOptionColor = () => {
        const { showOptionColors } = this.state;
        this.setState({
            showOptionColors: !showOptionColors
        })
    };

    handleShowActivityDetails = () => {
        const { showActivityDetails } = this.state;
        this.setState({
            showActivityDetails: !showActivityDetails
        })
    };

    handleShowLabelPopup = () => {
        const { showLabelPopup } = this.state;
        this.setState({
            showLabelPopup: !showLabelPopup
        })
    };

    handleShowAddNewTenantForm = () => {
        const { showAddNewTenantForm } = this.state;
        this.setState({
            showAddNewTenantForm: !showAddNewTenantForm
        })
    };

    handleShowCreateLabelPopup = (type) => {
        const { showCreateLabelPopup, showLabelPopup } = this.state;
        this.setState({
            showCreateLabelPopup: !showCreateLabelPopup,
            showLabelPopup: type === 'close' ? false : !showLabelPopup,
            labelPopupType: type
        })

    };

    handleShowMemberPopup = () => {
        const { showMemberPopup } = this.state;
        this.setState({
            showMemberPopup: !showMemberPopup
        })
    };

    handleShowCustomFieldPopup = () => {
        const { showCustomFieldPopup } = this.state;
        this.setState({
            showCustomFieldPopup: !showCustomFieldPopup
        })
    };

    handleShowDueDatePopup = () => {
        const { showDueDatePopup } = this.state;
        this.setState({
            showDueDatePopup: !showDueDatePopup
        })
    };

    handleShowNewCustomField = (type) => {
        const { showNewCustomField } = this.state;
        this.setState({
            showNewCustomField: !showNewCustomField,
            customFieldPopupType: type
        })
    };

    isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };

    handleAddAttachment = async (e) => {
        const { cardData } = this.state;
        if (this.isIterable(e.target.files)) {
            this.setState({
                uploadError: ''
            });
            if (e.target.files.length) {
                this.setState({
                    uploadAttachmentsHeader: 'Add'
                })
            }
            await Object.values(e.target.files).map(async (attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const invalidExt = ["exe", "dll", "dmg", "vb", "cmd", "bat", "jar", "vbs", "jse", "pif", "vxd", "jse", "apk", "ins", "sct", "msi"];
                if (!invalidExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        await this.setState({
                            attachmentChanged: true,
                            uploadAttachment: [...this.state.uploadAttachment, ...e.target.files],
                            tempAttachments: [...this.state.uploadAttachment, ...e.target.files],
                        });
                        this.handleAddCardActivity({ cardId: cardData.id, message: `added attachment to card` });
                    } else {
                        this.setState({
                            uploadError: 'File is too big. Files with size greater than 25MB is not allowed.'
                        })
                    }
                } else {
                    this.setState({
                        attachmentChanged: false,
                        uploadError: '* Accept only image / video / emoji / documents !!!'
                    })
                }
            })
        }
        this.manageAttachmentUpload();
        await this.setState({
            uploadAttachment: [],
            tempAttachments: [],
        });
    };

    deleteAttachment = async (index, attachment) => {
        const { cardData } = this.state;
        let fileArray = Object.values(this.state.uploadAttachment)
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            await this.setState({
                uploadAttachmentsHeader: 'Upload',
                attachmentChanged: false,
                uploadAttachment: []
            });
        }
        await this.setState({
            fileChanged: true,
            uploadAttachment: fileArray
        });
        document.getElementById('attachmentFiles').value = null;
        this.manageAttachmentDelete(attachment);
        this.handleAddCardActivity({ cardId: cardData.id, message: `removed attachment from card` });
    };

    manageAttachmentUpload = async () => {
        const { cardData, uploadAttachment } = this.state;
        await this.props.addCardAttachments(cardData.id, uploadAttachment);
        await this.props.getCardAttachments(cardData.id);
        await this.refreshCardAttachments();
        return true
    };

    manageAttachmentDelete = async (attachment) => {
        const { cardData, cardAttachmentList } = this.state;
        await this.props.deleteCardAttachments(cardData.id, attachment._id);
        await this.props.getCardAttachments(cardData.id);
        await this.refreshCardAttachments();
        return true
    };

    handleCardDataUpdate = async () => {
        const { updateBoardDataFromCardModal } = this.props;
        const { cardData } = this.state;
        await updateBoardDataFromCardModal(cardData);
    };

    handleEditCardLabel = async (SelectedCardData) => {
        await this.setState({
            currentLabelData: SelectedCardData,
        });
        this.manageCurrentCardLabels("update");
        this.handleShowCreateLabelPopup("edit");
    }

    handleCreateLabel = async () => {
        const { currentLabelData } = this.state;
        await this.props.addBoardLabels(currentLabelData);
        const { boardReducer: { boardLabels: addedLabelData } } = this.props;
        await this.props.getBoardLabels();
        const { boardReducer: { boardLabels } } = this.props;
        await this.setState({
            boardLabelsList: boardLabels
        });
        this.props.handelUpdateAllCards('label', addedLabelData, 'create')
        this.handleShowCreateLabelPopup();
    }


    handleUpdateLabel = async () => {
        const { currentLabelData } = this.state;
        await this.props.updateBoardLabels(currentLabelData);
        await this.props.getBoardLabels();
        const { boardReducer: { boardLabels } } = this.props;
        await this.setState({
            boardLabelsList: boardLabels
        });
        this.manageCurrentCardLabels(currentLabelData);
        this.props.handelUpdateAllCards('label', currentLabelData, 'update')
        this.handleShowCreateLabelPopup();
    };

    handleDeleteLabel = async () => {
        const { cardData, currentLabelData } = this.state;
        await this.props.deleteBoardLabels(currentLabelData._id);
        await this.props.getBoardLabels();
        const { boardReducer: { boardLabels } } = this.props;
        await this.setState({
            boardLabelsList: boardLabels
        });
        this.props.handelUpdateAllCards('label', currentLabelData._id, 'delete')
        this.manageCurrentCardLabels("delete");
        this.handleAddCardActivity({ cardId: cardData.id, message: `deleted label` })
        this.handleShowCreateLabelPopup();
    };

    manageCurrentCardLabels = async (type) => {
        const { cardData, currentLabelData } = this.state;
        let tempCardLabels = cardData.labels || [];
        if (type === 'delete') {
            tempCardLabels = tempCardLabels.length && tempCardLabels.filter(item => item.id !== currentLabelData._id);
        } else {
            tempCardLabels.length && tempCardLabels.map((item, i) => {
                if (item.id === currentLabelData._id) {
                    item.name = currentLabelData.name;
                    item.color = currentLabelData.color;
                }
            })
        }
        await this.setState({
            cardData: {
                ...cardData,
                labels: tempCardLabels
            },
            tempLabels: tempCardLabels
        });
    };

    updateCurrentCardLabels = async (labelData) => {
        const { cardData } = this.state;
        let tempCardLabels = cardData.labels || [];
        if (!tempCardLabels.some(el => el.id === labelData._id)) {
            tempCardLabels.push({ id: labelData._id, name: labelData.name, color: labelData.color });
            this.handleAddCardActivity({ cardId: cardData.id, message: `added label "${labelData.name}" to card` });
        } else {
            tempCardLabels = tempCardLabels.filter(item => item.id !== labelData._id);
            this.handleAddCardActivity({ cardId: cardData.id, message: `removed label "${labelData.name}" from card` });
        }
        await this.setState({
            cardData: {
                ...cardData,
                labels: tempCardLabels
            },
            tempLabels: tempCardLabels
        });
        this.handleCardDataUpdate()
    };

    updateCurrentCardTenants = async (tenantData) => {
        const { cardData } = this.state;
        let tempCardTenants = cardData.tenants || [];
        if (!tempCardTenants.some(el => el._id === tenantData._id)) {
            tempCardTenants.push(tenantData);
        }
        await this.setState({
            cardData: {
                ...cardData,
                tenants: tempCardTenants
            }
        });
        this.handleAddCardActivity({ cardId: cardData.id, message: `added tenant "${tenantData.name}" to card` })
        this.handleCardDataUpdate()
    };

    removeTenantFromCurrentCard = async (tenantData) => {
        const { cardData } = this.state;
        let tempCardTenants = cardData.tenants || [];
        tempCardTenants = tempCardTenants.filter(item => item._id !== tenantData._id);
        await this.setState({
            cardData: {
                ...cardData,
                tenants: tempCardTenants
            }
        });
        this.handleAddCardActivity({ cardId: cardData.id, message: `removed tenant "${tenantData.name}" from card` })
        this.handleCardDataUpdate()
    };

    handleTenantSearch = async (event) => {
        const { tenantList } = this.state;
        await this.setState({
            tenantSearchString: event.target.value,
        });
        const { tenantSearchString } = this.state
        let tempTenantList = tenantList.filter(item => {
            return item.name.toLowerCase().includes(tenantSearchString.toLowerCase());
        });
        await this.setState({
            tenantListSearchResult: tempTenantList
        });
        const { tenantListSearchResult } = this.state;
        if (tenantListSearchResult.length) {
            this.setState({
                showAddNewTenantForm: false
            })
        }
    };

    handleCardDueDate = async (type) => {
        const { dueDate, dueTime, cardData } = this.state;
        if (type === 'save') {
            await this.setState({
                cardData: {
                    ...cardData,
                    dueDate: dueDate,
                    dueTime: dueTime,
                }
            });
        } else if (type === 'remove') {
            await this.setState({
                cardData: {
                    ...cardData,
                    dueDate: '',
                    dueTime: '',
                },
                dueDate: '',
                dueTime: '',
            });
        }
        this.handleAddCardActivity({ cardId: cardData.id, message: `updated due date` })
        this.handleCardDataUpdate()
    };

    handleCustomFieldTypeOnChange = (event) => {
        const { currentCustomFieldData } = this.state;
        this.setState({
            currentCustomFieldData: {
                ...currentCustomFieldData,
                type: event.target.value,
                options: event.target.value !== 'Dropdown' && []
            },
            currentCustomFieldOptions: currentCustomFieldData.options || [],
        })
    };

    manageCustomFieldOptions = async (event) => {
        event.preventDefault()
        const { currentCustomFieldData, currentCustomFieldOptions } = this.state;
        if (event.target.value.trim().length) {
            currentCustomFieldOptions.push({
                id: moment(new Date()).format('MMDDYYHHMMSSS'),
                value: event.target.value.trim(),
                color: 'grey'
            })
            this.setState({
                currentCustomFieldData: {
                    ...currentCustomFieldData,
                    options: currentCustomFieldOptions || []
                },
                currentOptionData: {
                    name: event.target.value.trim(),
                    color: 'grey'
                }
            })
        }
        await this.setState({ currentOptionName: '' })
    };

    updateOptionColor = () => {
        const { currentCustomFieldData, currentOptionData } = this.state;
        let tempCurrentCustomFieldData = currentCustomFieldData;
        tempCurrentCustomFieldData.options.find(item => item.id === currentOptionData.id).color = currentOptionData.color;
        this.setState({
            currentCustomFieldData: tempCurrentCustomFieldData
        })
    };

    handleCreateCustomField = async (e, type) => {
        e.preventDefault()
        const { currentCustomFieldData, cardData } = this.state;

        if (currentCustomFieldData.type === 'Dropdown' && currentCustomFieldData.options === false) currentCustomFieldData.options = []

        if (type === 'create') {
            await this.props.addCustomField(currentCustomFieldData);
        } else {
            await this.props.updateCustomField(currentCustomFieldData);
        }
        const { boardReducer: { customFields: createdCustomField } } = this.props;
        await this.props.getCustomFields();
        this.handleShowNewCustomField();
        const { boardReducer: { customFields } } = this.props;
        let tempCustomFields = cardData.customFields;

        if (type === 'create') {
            tempCustomFields.push(createdCustomField)
        } else {
            tempCustomFields.map((item) => {
                if (item._id === currentCustomFieldData._id) {
                    item.type = currentCustomFieldData.type;
                    item.name = currentCustomFieldData.name;
                    item.options = currentCustomFieldData.options;
                }
            })
        }

        await this.setState({
            boardCustomFieldsList: tempCustomFields,
            cardData: {
                ...cardData,
                customFields: tempCustomFields
            }
        });
        this.handleCardDataUpdate()
        if (type === 'create') {
            this.props.handelUpdateAllCards('customField', createdCustomField, type)
            this.handleAddCardActivity({ cardId: cardData.id, message: `created custom field` })
            this.props.refreshBoardData()
        } else {
            this.props.handelUpdateAllCards('customField', currentCustomFieldData, type)
            this.handleAddCardActivity({ cardId: cardData.id, message: `updated custom field` })
        }
    };

    handleEditCustomField = async (customFieldData) => {
        await this.setState({
            currentCustomFieldData: customFieldData,
            currentCustomFieldOptions: customFieldData.options,
        });
        this.handleShowNewCustomField('edit');
    };

    handleDeleteCustomField = async (customFieldId) => {
        const { cardData } = this.state;
        await this.props.deleteCustomField(customFieldId);
        await this.props.getCustomFields();
        const { boardReducer: { customFields } } = this.props;

        let tempCustomFields = cardData.customFields.filter((item) => item._id !== customFieldId);
        await this.setState({
            boardCustomFieldsList: customFields,
            cardData: {
                ...cardData,
                customFields: tempCustomFields
            }
        });
        // this.handleCardDataUpdate()
        this.props.handelUpdateAllCards('customField', customFieldId, 'delete')
        this.handleAddCardActivity({ cardId: cardData.id, message: `deleted custom field` })
    }

    renderCustomFieldIcon = (type) => {
        switch (type) {
            case 'Checkbox':
                return <i style={{ width: "20px" }} className="far fa-check-circle" />;
            case 'Date':
                return <i style={{ width: "20px" }} className="far fa-calendar-alt" />;
            case 'Dropdown':
                return <i style={{ width: "20px" }} className="fas fa-list" />;
            case 'Text':
                return <i style={{ width: "20px" }} className="fas fa-text-height" />;
            default:
                return <i style={{ width: "20px" }} className="fas fa-grip-horizontal" />;
        }
    }

    updateCardCustomFieldValues = async (customFieldData, value, color, id) => {
        const { cardData } = this.state;
        if (customFieldData.type === 'Date') {
            customFieldData.value = value ? new Date(value) : null;
        } else {
            customFieldData.value = value;
        }
        if (customFieldData.type === 'Dropdown') {
            customFieldData.backgroundColor = color || '';
            customFieldData.selectedOptionId = id || '';
        }
        let tempCustomFields = []
        cardData.customFields && cardData.customFields.map((customFieldItem, i) => {
            if (customFieldItem._id === customFieldData._id) {
                Object.assign(customFieldItem, customFieldData);
            }
            tempCustomFields.push(customFieldItem)
        });
        await this.setState({
            cardData: {
                ...cardData,
                customFields: tempCustomFields
            }
        });
        this.handleCardDataUpdate()
    }

    deleteCurrentCustomFieldOption = async (optionData) => {
        const { currentCustomFieldData } = this.state;
        let tempOptions = currentCustomFieldData.options.filter((item => item.id !== optionData.id))
        await this.setState({
            currentCustomFieldData: {
                ...currentCustomFieldData,
                options: tempOptions
            }
        });
    }

    renderCustomFieldModalContent = (customFieldData) => {
        //Checking whether a deleted custom field option is there in the card custom field option
        let hasValueInOption = false;
        if (customFieldData.options && customFieldData.options.length) {
            customFieldData.options.map((item) => {
                if (item.value === customFieldData.value) {
                    hasValueInOption = true;
                }
                if (item.id === customFieldData.selectedOptionId) {
                    customFieldData.backgroundColor = item.color
                }
            });
            if (!hasValueInOption) {
                customFieldData.backgroundColor = ''
            }
        }

        switch (customFieldData.type) {
            case 'Checkbox':
                return <div className="form-group">
                    <input
                        id={`custom-check-box_${customFieldData._id}`}
                        type="checkbox"
                        defaultChecked={customFieldData.value}
                        onChange={(event) => this.updateCardCustomFieldValues(customFieldData, event.target.checked)}
                    />
                    <label htmlFor={`custom-check-box_${customFieldData._id}`} />
                </div>

            case 'Date':
                return <CommonDatePicker
                    value={customFieldData.value ? new Date(customFieldData.value) : null}
                    onChange={(date) => this.updateCardCustomFieldValues(customFieldData, date)}
                    tabIndex={1}
                    className="form-control textFiled"
                />

            case 'Dropdown':
                return <div className={`${customFieldData.backgroundColor ? 'hasValue' : ''} selctOtr`}>
                    <select
                        name="cstmDd"
                        className={`labelText colr-${customFieldData.value && customFieldData.backgroundColor} form-control`}
                        value={customFieldData.value}
                        onChange={(event) => {
                            this.updateCardCustomFieldValues(
                                customFieldData,
                                event.target.value,
                                event.target.options[event.target.options.selectedIndex].dataset.color,
                                event.target.options[event.target.options.selectedIndex].dataset.id
                            )
                        }}
                    >
                        <option className={`custom-options colr-white`} value="0">Select</option>
                        {customFieldData.options && customFieldData.options.map((item, i) =>
                            <option data-color={item.color} data-id={item.id} key={i}
                                className={`custom-options colr-${item.color}`}>{item.value}</option>
                        )}
                    </select>
                </div>

            case 'Text':
                return <input
                    type="text"
                    className="form-control textFiled"
                    value={customFieldData.value || ''}
                    disabled={false}
                    onBlur={(event) => this.updateCardCustomFieldValues(customFieldData, event.currentTarget.value)}
                    onChange={(event) => this.updateCardCustomFieldValues(customFieldData, event.currentTarget.value)}
                />;
            default:
                return <i style={{ width: "20px" }} className="fas fa-grip-horizontal" />;
        }
    };

    handleCreateNewTenant = async () => {
        const { newTenantData } = this.state;
        await this.setState({
            newTenantData: {
                ...newTenantData,
                _id: moment(new Date()).format('MMDDYYHHMMSSS'),
                name: newTenantData.firstName + ' ' + newTenantData.lastName
            }
        });
        this.updateCurrentCardTenants(this.state.newTenantData);
        this.handleShowAddNewTenantForm();
        await this.setState({
            tenantList: [this.state.newTenantData, ...this.state.tenantList]
        });
        this.props.addTenantsToBoard(this.state.newTenantData);
    }

    handleDate = (date, name) => {
        this.setState({ [name]: date })
    }

    render() {

        const { onCancel, handleDeleteCard, currentLane } = this.props;
        const {
            cardData,
            showEditDescription,
            showEditActivity,
            showActivityDetails,
            showLabelPopup,
            showMemberPopup,
            showCustomFieldPopup,
            showDueDatePopup,
            showNewCustomField,
            tempLabels,
            showCreateLabelPopup,
            currentLabelData,
            boardLabelsList,
            labelColors,
            labelPopupType,
            tenantListSearchResult,
            dueDate,
            dueTime,
            cardActivityList,
            activityComment,
            showOptionColors,
            currentCustomFieldData,
            currentOptionData,
            cardAttachmentList,
            currentOptionName,
            boardCustomFieldsList,
            customFieldPopupType,
            showAddNewTenantForm,
            newTenantData,
            tenantSearchString,
        } = this.state;

        let cardLabelIds = [];
        tempLabels.length && tempLabels.map((item, i) => {
            cardLabelIds.push(item.id)
        });

        return (
            <>
                <div className="modal unitModal" id="modalId" style={{ display: "block" }}>
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header col-md-12">
                                <h5 className="modalTitle">
                                    <i className="far fa-credit-card" />
                                    <ContentEditable
                                        html={cardData.title}
                                        disabled={false}
                                        onChange={(evt) => {
                                            this.setState({ cardData: { ...cardData, title: evt.currentTarget.innerText } })
                                        }}
                                        style={{ display: "inline-flex" }}
                                        onBlur={() => this.handleCardDataUpdate()}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                this.handleCardDataUpdate()
                                            }
                                        }}
                                    />
                                    <span className="subText">in list {currentLane ? currentLane.title : null}</span>
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={onCancel}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="unitDeailSec">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="unitDetail">
                                                <div className="labelUnit mb-4">
                                                    {(cardData.tenants && cardData.tenants.length) ?
                                                        <div className="labelSec">
                                                            <h3>Tenants</h3>
                                                            <div className="d-flex">
                                                                {cardData.tenants.map((item, i) => <div key={i}
                                                                    title={item.name.trim().length ? item.name : '-'}
                                                                    className="memberIntial">{item.name.trim().length ? item.name.charAt(0).toUpperCase() : '-'}</div>)}
                                                                <div className="addMember addmemberBtn menuDropdown"
                                                                    onClick={() => this.handleShowMemberPopup()}>
                                                                    <i className="fas fa-plus" />
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }

                                                    {(cardData.labels && cardData.labels.length) ?
                                                        <div className="labelSec">
                                                            <h3>Labels</h3>
                                                            <div className="d-flex labels-wrap">
                                                                {cardData.labels.map((item, i) => <span key={i}
                                                                    className={`labelName mr-2 colr-${item.color}`}>{item.name}</span>)}
                                                                <div className="addLabel addlabelBtn menuDropdown"
                                                                    onClick={() => this.handleShowLabelPopup()}>
                                                                    <i className="fas fa-plus" />
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                                <div className="labelUnit mb-4">
                                                    {(cardData.dueDate && cardData.dueTime) ?
                                                        <div className="labelSec">
                                                            <h3>Due Date</h3>
                                                            <div className="d-flex labels-wrap">
                                                                <span className="labelName mr-2 label-grey">
                                                                    {moment(new Date(cardData.dueDate)).format('MMM DD')} at {moment(new Date(cardData.dueTime)).format('hh:mm a')}
                                                                </span>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                                <div className="descSec">
                                                    <div className="moduleTitle">
                                                        <i className="fas fa-align-left" />
                                                        <h3>Description</h3>
                                                    </div>
                                                    <div className="descTextareaSec">
                                                        <div className="col-md-12">
                                                            <div className="textareaBox">
                                                                {showEditDescription ? <>
                                                                    <textarea
                                                                        id="commentsTextarea"
                                                                        className="descTextarea"
                                                                        placeholder="Add a more detailed description"
                                                                        onChange={(evt) => {
                                                                            this.setState({
                                                                                cardData: {
                                                                                    ...cardData,
                                                                                    description: evt.target.value
                                                                                }
                                                                            })
                                                                        }} value={cardData.description} />
                                                                    <div className="textareaAction">
                                                                        <button className="btn saveDesc"
                                                                            onClick={async () => {
                                                                                this.handleCardDataUpdate();
                                                                                this.setState({ showEditDescription: !showEditDescription });
                                                                                this.handleAddCardActivity({
                                                                                    cardId: cardData.id,
                                                                                    message: `updated description`
                                                                                })
                                                                            }}>Save
                                                                        </button>
                                                                        <button type="button" className="close"
                                                                            onClick={() => this.handleShowEditDescription()}>
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                </> : (cardData.description && cardData.description.length)
                                                                        ? <div className="descTextareaBlock mb-3"
                                                                            onClick={() => {
                                                                                this.handleShowEditDescription();
                                                                            }}>{cardData.description}.... <span>Edit</span>
                                                                        </div>
                                                                        : <div className="descTextareaBlock mb-3"
                                                                            onClick={() => this.handleShowEditDescription()}>Add
                                                                        a more detailed description</div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {(cardData.customFields && cardData.customFields.length)
                                                    ? <div className="customSec">
                                                        <div className="moduleTitle pt-4">
                                                            <i className="fas fa-bars" />
                                                            <h3>Custom Fields</h3>
                                                        </div>
                                                        <div className="customFields row p-0">
                                                            {cardData.customFields.map((item, i) => {
                                                                return <div key={i} className="labelSec col-sm-4">
                                                                    <h3>{item.name.toUpperCase()}</h3>
                                                                    {this.renderCustomFieldModalContent(item)}
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>

                                                    : null
                                                }

                                                {(cardAttachmentList && cardAttachmentList.attachments && cardAttachmentList.attachments.length) ?
                                                    <div className="attachmentSec">
                                                        <div className="moduleTitle pt-4">
                                                            <i className="fas fa-paperclip" />
                                                            <h3>Attachments
                                                            </h3>
                                                        </div>
                                                        <div className="attachmentMain">
                                                            {cardAttachmentList.attachments.length
                                                                ? cardAttachmentList.attachments.map((item, i) => <div
                                                                    key={i} className="attachmentList">
                                                                    <div className="attachmentInner">
                                                                        <div className="attachThumbnail">
                                                                            <span
                                                                                className="thumbnailText">{item.title.split('.').pop()}</span>
                                                                        </div>
                                                                        <div className="attachFileName">
                                                                            <a href={MAIN_ROUTE + item.path}
                                                                                target="_blank">{item.title}</a>
                                                                            <span className="attchTime">
                                                                                {/*Added : Mar 06 at 09:28 PM*/}
                                                                                {moment(new Date(item.createdDate)).format('MMM DD')} at {moment(new Date(item.createdDate)).format('hh:mm a')} - <a
                                                                                    onClick={() => this.deleteAttachment(i, item)}
                                                                                    href="#">Delete</a>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="addAttchmentSec col-md-3 p-0">
                                                            <button className="btn adattchBtn cardBtn attchmentBtn"
                                                                onChange={this.handleAddAttachment}><i
                                                                    className="fas fa-paperclip" /> Add an attachment
                                                                <input
                                                                    type="file"
                                                                    className="file-name"
                                                                    multiple={true}
                                                                    id="attachmentFiles"
                                                                />
                                                            </button>
                                                        </div>
                                                        <div className="attachmentOuter">
                                                        </div>
                                                    </div> : null
                                                }
                                                <div className="activityMainSec">
                                                    <div className="activitySec pt-4 pb-3">
                                                        <div className="moduleTitle">
                                                            <i className="fas fa-list" />
                                                            <h3>Activity
                                                            </h3>
                                                        </div>
                                                        <div className="activityButton">
                                                            <button className="btn"
                                                                onClick={() => this.handleShowActivityDetails()}>{showActivityDetails ? 'Hide' : 'Show'} Details
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {showEditActivity
                                                        ?
                                                        <div className="activityDetails inputActivity">
                                                            <div className="memeberName"><i className="fas fa-comment" />
                                                            </div>
                                                            <div className="activityDesc col-md-11 p-0">
                                                                <textarea id="activityTextarea"
                                                                    onChange={(event) => {
                                                                        this.setState({ activityComment: event.target.value })
                                                                    }}
                                                                    className="commentTextarea"
                                                                    placeholder="Write a Comment" />
                                                                <div className="commenttextareaAction">
                                                                    <button className="btn saveDesc"
                                                                        onClick={() => {
                                                                            this.handleShowEditActivity();
                                                                            this.handleAddCardActivity({
                                                                                cardId: cardData.id,
                                                                                message: `added comment "${activityComment}"`
                                                                            })
                                                                        }}>Save
                                                                    </button>
                                                                    <button type="button" className="close">
                                                                        <span aria-hidden="true" onClick={() => {
                                                                            this.handleShowEditActivity();
                                                                        }}>×</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="activityDetails inputActivity"
                                                            onClick={() => this.handleShowEditActivity()}>
                                                            <div className="memeberName"><i className="fas fa-comment" />
                                                            </div>
                                                            <div className="activityDesc col-md-11 p-0">
                                                                <div className="form-control">Write a Comment</div>
                                                            </div>
                                                        </div>

                                                    }
                                                    {(cardActivityList && cardActivityList.length && showActivityDetails) ?
                                                        <div className="activityScroll">
                                                            {cardActivityList.map((item, i) => <div
                                                                className="activityDetails">
                                                                <div
                                                                    className="memeberName">{item.name.charAt(0).toUpperCase()}
                                                                </div>
                                                                <div className="activityDesc">
                                                                    {item.name} {item.message}
                                                                    <span
                                                                        className="dateTime">{moment(new Date(item.createdAt)).format('MMM DD')} at {moment(new Date(item.createdAt)).format('hh:mm a')}</span>
                                                                </div>
                                                            </div>)}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="sidebarActions">
                                                <div className="labelSec">
                                                    <h3>ADD TO CARD
                                                    </h3>
                                                </div>
                                                <div className="sideBtns">

                                                    <div className="memberBoxSec menuDropdown">
                                                        <button className="btn cardBtn memberBtn"
                                                            onClick={() => this.handleShowMemberPopup()}><i
                                                                className="fas fa-user" />Tenants
                                                        </button>
                                                        {showMemberPopup
                                                            ? <div className=" memberBox" style={{ display: "block" }}>
                                                                <div className="dropdownheader">
                                                                    <h3>TENANTS</h3>
                                                                    <div className="dropdownClose"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                tenantSearchString: ''
                                                                            });
                                                                            this.handleShowMemberPopup();
                                                                            this.handleShowAddNewTenantForm();
                                                                        }}>
                                                                        <span aria-hidden="true"
                                                                            className="close-icon">×</span>
                                                                    </div>
                                                                </div>
                                                                <div className="memberOuter">
                                                                    <input onChange={(e) => this.handleTenantSearch(e)}
                                                                        type="text" className="form-control"
                                                                        placeholder="Search Members"
                                                                        value={tenantSearchString} />
                                                                    <div className="boardMember">
                                                                        <h3>SUGGESTIONS</h3>
                                                                        {tenantListSearchResult.length ? tenantListSearchResult.map((item, i) => {
                                                                            if (i < 3) {
                                                                                return <div key={i}
                                                                                    onClick={() => this.updateCurrentCardTenants(item)}
                                                                                    className="memberNameList mb-2">
                                                                                    <div
                                                                                        className="memeberLetter">{item.firstName.charAt(0).toUpperCase()}{item.lastName.charAt(0).toUpperCase()}
                                                                                    </div>
                                                                                    <div
                                                                                        className="memberFullname">{item.name}</div>
                                                                                </div>
                                                                            }
                                                                        }) : <>
                                                                                {!showAddNewTenantForm
                                                                                    ?
                                                                                    <div
                                                                                        className="board-add-tenant-container">
                                                                                        <span>No Tenants Found</span>
                                                                                        <button className="btn"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    newTenantData: {
                                                                                                        firstName: tenantSearchString,
                                                                                                        lastName: '',
                                                                                                        email: '',
                                                                                                        phoneNumber: '',
                                                                                                    }
                                                                                                })
                                                                                                this.handleShowAddNewTenantForm()
                                                                                            }}>Add
                                                                                        Tenant
                                                                                    </button>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="board-add-tenant-form">
                                                                                        <input type="text"
                                                                                            className="form-control"
                                                                                            onChange={
                                                                                                (event) => {
                                                                                                    this.setState({
                                                                                                        newTenantData: {
                                                                                                            ...newTenantData,
                                                                                                            firstName: event.target.value
                                                                                                        }
                                                                                                    })
                                                                                                }}
                                                                                            value={newTenantData.firstName}
                                                                                            placeholder="First Name" />
                                                                                        <input type="text"
                                                                                            className="form-control"
                                                                                            onChange={
                                                                                                (event) => {
                                                                                                    this.setState({
                                                                                                        newTenantData: {
                                                                                                            ...newTenantData,
                                                                                                            lastName: event.target.value
                                                                                                        }
                                                                                                    })
                                                                                                }}
                                                                                            value={newTenantData.lastName}
                                                                                            placeholder="Last Name" />
                                                                                        <input type="text"
                                                                                            className="form-control"
                                                                                            onChange={
                                                                                                (event) => {
                                                                                                    this.setState({
                                                                                                        newTenantData: {
                                                                                                            ...newTenantData,
                                                                                                            email: event.target.value
                                                                                                        }
                                                                                                    })
                                                                                                }}
                                                                                            value={newTenantData.email}
                                                                                            placeholder="Email" />
                                                                                        <input type="text"
                                                                                            className="form-control"
                                                                                            onChange={
                                                                                                (event) => {
                                                                                                    this.setState({
                                                                                                        newTenantData: {
                                                                                                            ...newTenantData,
                                                                                                            phoneNumber: event.target.value
                                                                                                        }
                                                                                                    })
                                                                                                }}
                                                                                            value={newTenantData.phoneNumber}
                                                                                            placeholder="Phone No." />
                                                                                        <div
                                                                                            className="tenant-form-button-container">
                                                                                            <button
                                                                                                onClick={() => this.handleCreateNewTenant()}
                                                                                                className="btn addButton">Create
                                                                                        </button>
                                                                                            <button
                                                                                                className="btn cancelButton"
                                                                                                onClick={() => this.handleShowAddNewTenantForm()}>Cancel
                                                                                        </button>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {(cardData.tenants && cardData.tenants.length) ?
                                                                    <div className="memberOuter pt-0">
                                                                        <div className="boardMember pt-0">
                                                                            <h3>CARD TENANTS</h3>
                                                                            <div className="memberNameList mb-2">
                                                                                {cardData.tenants.map((item, i) => {
                                                                                    return <span key={i}
                                                                                        title={item.name.trim().length ? item.name : '-'}
                                                                                        className="memeberLetter removeTenantFromCard">
                                                                                        <span aria-hidden="true"
                                                                                            className="close-icon"
                                                                                            onClick={() => this.removeTenantFromCurrentCard(item)}>×</span>
                                                                                        {(item.firstName.length || item.firstName.length) ? item.firstName.charAt(0).toUpperCase() + '' + item.lastName.charAt(0).toUpperCase() : '-'}
                                                                                    </span>
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                                }
                                                            </div> : null
                                                        }
                                                    </div>

                                                    <div className="menuDropdown labelBoxSec">
                                                        <button className="btn cardBtn  labelBtn"
                                                            onClick={() => this.handleShowLabelPopup()}><i
                                                                className="fas fa-tag" />
                                                            Labels
                                                        </button>
                                                        {showLabelPopup
                                                            ?
                                                            <div className="labelBox" style={{ display: "block" }}>
                                                                <div className="dropdownheader">
                                                                    <h3>Labels</h3>
                                                                    <div className="dropdownClose"
                                                                        onClick={() => this.handleShowLabelPopup()}>
                                                                        <span aria-hidden="true"
                                                                            className="close-icon">×</span>
                                                                    </div>
                                                                </div>
                                                                <div className="labelOuter">
                                                                    {/*<div className="nameSec mb-2">
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Search Labels"/>
                                                                    </div>*/}
                                                                    <div className="colorSec">
                                                                        <label className="labelName">Labels</label>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                {boardLabelsList.length ? boardLabelsList.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="d-flex">
                                                                                            <div
                                                                                                onClick={() => this.updateCurrentCardLabels(item)}
                                                                                                className={`editlabelColor colr-${item.color}`}>
                                                                                                <span
                                                                                                    className="label-name">{item.name}</span>
                                                                                                {cardLabelIds.includes(item._id)
                                                                                                    ? <span
                                                                                                        className="selectableLabel"><i
                                                                                                            className="fas fa-check" /></span>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            <span
                                                                                                className="labelcoloredit"
                                                                                                onClick={() => this.handleEditCardLabel(item)}><i
                                                                                                    className="fas fa-pen" /></span>
                                                                                        </div>)
                                                                                }) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="colrlabelBtn">
                                                                            <button className="btn"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        currentLabelData: {
                                                                                            color: '',
                                                                                            name: ''
                                                                                        }
                                                                                    })
                                                                                    this.handleShowCreateLabelPopup("create")
                                                                                }}>Create a new label
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    {showCreateLabelPopup ?
                                                        <div className="menuDropdown">
                                                            <div className="creatLabelBox" style={{ display: "block" }}>
                                                                <div className="dropdownheader">
                                                                    <a href="#" className="backArrow"
                                                                        onClick={() => this.handleShowCreateLabelPopup('back')}><i
                                                                            className="fas fa-chevron-left" /></a>
                                                                    <h3>Labels</h3>
                                                                    <div className="dropdownClose"
                                                                        onClick={() => this.handleShowCreateLabelPopup('close')}>
                                                                        <span aria-hidden="true"
                                                                            className="close-icon">×</span>
                                                                    </div>
                                                                </div>
                                                                <div className="labelOuter">
                                                                    <div className="nameSec mb-2">
                                                                        <label className="labelName">Name</label>
                                                                        <input type="text" className="form-control"
                                                                            onChange={(evt) => {
                                                                                this.setState({
                                                                                    currentLabelData: {
                                                                                        ...currentLabelData,
                                                                                        name: evt.target.value
                                                                                    }
                                                                                })
                                                                            }} value={currentLabelData.name}
                                                                            placeholder="Name" />
                                                                    </div>
                                                                    <div className="colorSec">
                                                                        <label className="labelName">Select a
                                                                            color</label>
                                                                        <div className="colorList">
                                                                            {labelColors.map((item, i) =>
                                                                                <span key={i}
                                                                                    className={`labelColor colr-${item}`}
                                                                                    onClick={(evt) => {
                                                                                        this.setState({
                                                                                            currentLabelData: {
                                                                                                ...currentLabelData,
                                                                                                color: item
                                                                                            }
                                                                                        })
                                                                                    }}>
                                                                                    {currentLabelData.color === item
                                                                                        ? <span
                                                                                            className="selectableLabel"><i
                                                                                                className="fas fa-check" /></span>
                                                                                        : null
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {(labelPopupType === 'create') ?
                                                                        <div className="colorCreate">
                                                                            <button className="btn colrcreateBtn"
                                                                                onClick={() => this.handleCreateLabel()}>Create
                                                                            </button>
                                                                        </div> : (labelPopupType === 'edit') ?
                                                                            <div className="colorCreate">
                                                                                <button className="btn colrcreateBtn"
                                                                                    onClick={() => this.handleUpdateLabel()}>Save
                                                                                </button>
                                                                                <button className="btn colrdeleteBtn"
                                                                                    onClick={() => this.handleDeleteLabel()}>Delete
                                                                                </button>
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }

                                                    <div className="attachmentSec">
                                                        <button className="btn cardBtn attchmentBtn"
                                                            onChange={this.handleAddAttachment}><i
                                                                className="fas fa-paperclip" /> Attachment
                                                            <input
                                                                type="file"
                                                                className="file-name"
                                                                multiple={true}
                                                                id="attachmentFiles"
                                                            />
                                                        </button>
                                                    </div>

                                                    <div className="menuDropdown customFieldBoxSec">
                                                        <button className="btn cardBtn filedBtn"
                                                            onClick={() => this.handleShowCustomFieldPopup()}>
                                                            <i className="fas fa-sign-in-alt" />Custom Fields
                                                        </button>
                                                        {showCustomFieldPopup
                                                            ? <div className="filedBox" style={{ display: "block" }}>
                                                                <div className="dropdownheader">
                                                                    <h3>Custom Fields
                                                                    </h3>
                                                                    <div className="dropdownClose"
                                                                        onClick={() => this.handleShowCustomFieldPopup()}>
                                                                        <span aria-hidden="true"
                                                                            className="close-icon">×</span>
                                                                    </div>
                                                                </div>
                                                                <div className="customFieldOuter">
                                                                    <div className="d-flex customList">
                                                                        {boardCustomFieldsList.length
                                                                            ? boardCustomFieldsList.map((item, i) =>
                                                                                <div className="fieldBlock" key={i}>
                                                                                    {this.renderCustomFieldIcon(item.type)}
                                                                                    <span
                                                                                        className="filedName cursorPointer"
                                                                                        onClick={() => this.handleEditCustomField(item)}>{item.name}</span>
                                                                                    <span
                                                                                        className="deleteOptn ml-4"
                                                                                    ><i onClick={() => this.handleDeleteCustomField(item._id)}
                                                                                        className="far fa-trash-alt cursorPointer" /></span>
                                                                                </div>)
                                                                            : <div className="fieldBlock"><span
                                                                                className="filedName">No Custom Fields</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="addNewFiled"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                currentCustomFieldData: {
                                                                                    type: "0",
                                                                                    name: "",
                                                                                    options: [],
                                                                                }
                                                                            });
                                                                            this.handleShowNewCustomField('create')
                                                                        }}>
                                                                        <i className="fas fa-plus" /> New Field
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                        }
                                                    </div>

                                                    {showNewCustomField
                                                        ? <div className="menuDropdown customNewFieldBox">
                                                            <div className="adddnewBox" style={{ display: "block" }}>
                                                                <div className="dropdownheader">
                                                                    <a href="#" className="backArrow"
                                                                        onClick={() => this.handleShowNewCustomField()}><i
                                                                            className="fas fa-chevron-left" /></a>
                                                                    <h3>{customFieldPopupType === 'create' ? 'New Field' : 'Edit Field'}</h3>
                                                                    <div className="dropdownClose"
                                                                        onClick={() => this.handleShowNewCustomField()}>
                                                                        <span aria-hidden="true"
                                                                            className="close-icon">×</span>
                                                                    </div>
                                                                </div>
                                                                <div className="customNewFieldOuter">
                                                                    {customFieldPopupType === 'create'
                                                                        ?
                                                                        <div className="customFieldSelect">
                                                                            <label>Type</label>
                                                                            <div className="selctOtr">
                                                                                <select
                                                                                    onChange={(event) => this.handleCustomFieldTypeOnChange(event)}
                                                                                    value={currentCustomFieldData.type}
                                                                                    className="form-control">
                                                                                    <option value="0">Select</option>
                                                                                    <option value="Checkbox">Checkbox
                                                                                    </option>
                                                                                    <option value="Date">Date</option>
                                                                                    <option value="Dropdown">Dropdown
                                                                                    </option>
                                                                                    <option value="Text">Text</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        : null

                                                                    }
                                                                    <div className="customFiledName mb-2">
                                                                        <label>Name</label>
                                                                        <input type="text"
                                                                            onChange={(event) => {
                                                                                this.setState({
                                                                                    currentCustomFieldData: {
                                                                                        ...currentCustomFieldData,
                                                                                        name: event.target.value
                                                                                    }
                                                                                })
                                                                            }}
                                                                            value={currentCustomFieldData.name}
                                                                            className="form-control"
                                                                            placeholder="Name" />
                                                                    </div>
                                                                    {currentCustomFieldData.type === 'Dropdown' ?
                                                                        <div className="customFiledName mb-3">
                                                                            <label>Options</label>
                                                                            {currentCustomFieldData.options.length
                                                                                ? <>
                                                                                    {currentCustomFieldData.options.map((item, i) =>
                                                                                        <div key={i}
                                                                                            className="customOptionOuter">
                                                                                            <div
                                                                                                className="customOptionList">
                                                                                                <span
                                                                                                    className={`optionBox colr-${item.color}`}
                                                                                                    onClick={() => {
                                                                                                        this.setState({ currentOptionData: item })
                                                                                                        this.handleShowOptionColor()
                                                                                                    }}>&nbsp;</span>
                                                                                                <span
                                                                                                    className="optionName">{item.value}</span>
                                                                                            </div>
                                                                                            <span
                                                                                                onClick={() => this.deleteCurrentCustomFieldOption(item)}
                                                                                                className="deleteOptn"><i
                                                                                                    className="far fa-trash-alt" /></span>
                                                                                        </div>
                                                                                    )}</>
                                                                                : null
                                                                            }
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Add Item"
                                                                                onChange={(event) => {
                                                                                    this.setState({ currentOptionName: event.target.value })
                                                                                }}
                                                                                value={currentOptionName}
                                                                                onKeyPress={(event) => {
                                                                                    if (event.key === "Enter") {
                                                                                        this.manageCustomFieldOptions(event)
                                                                                    }
                                                                                }}
                                                                                onBlur={(event) => {
                                                                                    this.manageCustomFieldOptions(event)
                                                                                }}
                                                                            />

                                                                        </div> : null
                                                                    }
                                                                    <div className="createFiledBtn">
                                                                        <button
                                                                            onClick={(e) => this.handleCreateCustomField(e, customFieldPopupType)}
                                                                            className="btn fieldcreateBtn">
                                                                            {customFieldPopupType === 'create' ? 'Create' : 'Save'}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {showOptionColors ?
                                                                    <div className="creatLabelBox"
                                                                        style={{ "display": "block" }}>
                                                                        <div className="dropdownheader">
                                                                            <a className="backArrow"
                                                                                onClick={() => this.handleShowOptionColor()}><i
                                                                                    className="fas fa-chevron-left" /></a>
                                                                            <h3>Color</h3>
                                                                            <div className="dropdownClose"
                                                                                onClick={() => this.handleShowOptionColor()}>
                                                                                <span aria-hidden="true"
                                                                                    className="close-icon">×</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="labelOuter">
                                                                            <div className="colorSec">
                                                                                <label className="labelName">Select
                                                                                    a color</label>
                                                                                <div className="colorList">
                                                                                    {labelColors.map((item, i) => <span
                                                                                        key={i}
                                                                                        className={`labelColor colr-${item}`}
                                                                                        onClick={async () => {
                                                                                            await this.setState({
                                                                                                currentOptionData: {
                                                                                                    ...currentOptionData,
                                                                                                    color: item
                                                                                                }
                                                                                            })
                                                                                            this.updateOptionColor();
                                                                                            this.handleShowOptionColor();
                                                                                        }}>
                                                                                        {currentOptionData.color === item ?
                                                                                            <span
                                                                                                className="selectableLabel"><i
                                                                                                    className="fas fa-check" /></span> : null
                                                                                        }&nbsp;
                                                                                </span>)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null}
                                                            </div>
                                                        </div> : null
                                                    }

                                                    <div className="menuDropdown  dueDateBoxSec">
                                                        <button className="btn cardBtn dateBtn"
                                                            onClick={() => this.handleShowDueDatePopup()}><i
                                                                className="far fa-clock" /> Due Date
                                                        </button>
                                                        {showDueDatePopup ?
                                                            <div className="dateBox" style={{ display: "block" }}>
                                                                <div className="dropdownheader">
                                                                    <h3>Due Date
                                                                    </h3>
                                                                    <div className="dropdownClose"
                                                                        onClick={() => this.handleShowDueDatePopup()}>
                                                                        <span aria-hidden="true"
                                                                            className="close-icon">×</span>
                                                                    </div>
                                                                </div>
                                                                <div className="dueDateOuter">
                                                                    <div className="d-flex">
                                                                        <div className="dueDate">
                                                                            <label>Date</label>
                                                                            <CommonDatePicker
                                                                                value={dueDate}
                                                                                onChange={(date) => this.handleDate(date, "dueDate")}
                                                                                tabIndex={1}
                                                                                className="form-control"
                                                                                placeholderText={'MM/DD/YYYY'}
                                                                            />
                                                                        </div>
                                                                        <div className="duetime">
                                                                            <label>Time</label>
                                                                            <DatePicker
                                                                                selected={dueTime}
                                                                                onChange={(date) => this.setState({ dueTime: date })}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={5}
                                                                                timeCaption="Time"
                                                                                dateFormat="h:mm aa"
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/*<div className="setReminder">
                                                                        <label>Set Reminder</label>
                                                                        <div className="selctOtr">
                                                                            <select className="form-control">
                                                                                <option>Select</option>
                                                                                <option>test</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>*/}
                                                                    <div
                                                                        className="d-flex justify-content-between dueDateCreate">
                                                                        <button className="btn datecreateBtn"
                                                                            onClick={() => this.handleCardDueDate('save')}>Save
                                                                        </button>
                                                                        <button className="btn datecreateBtn colr-red"
                                                                            onClick={() => this.handleCardDueDate('remove')}>Remove
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div> : null}
                                                    </div>

                                                    {/*<button className="btn cardBtn"><i
                                                        className="fas fa-share-alt"/> Share
                                                    </button>*/}
                                                    <button className="btn cardBtn"
                                                        onClick={() => handleDeleteCard(cardData.id)}><i
                                                            className="far fa-trash-alt" /> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = state => {
    const { boardReducer } = state;
    return { boardReducer }
};

export default withRouter(connect(mapStateToProps, { ...action })(CardDetailModal));
