import React, { useState, useEffect } from "react";
import action from "./action";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toastr from '../Common/components/Toaster'

import MaintenanceList from './components/maintenanceList'
import MaintenanceAdd from './components/maintenanceAdd'
import MaintenanceFilter from './components/maintenanceFilter'
import Modal from '../Common/components/Modal'
import LoaderSpinner from "../Common/components/Loader";
import usePrevious from "../Common/components/prevProps";
import Pagination from '../Common/components/pagination';
import history from '../../config/history'
import InvoiceListing from "../Invoice";
import Confirm from '../Common/components/Confirm';
import commonAction from '../Common/action';



const Maintenance = (props) => {
    const [maintenanceModal, setMaintenanceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCheckedAll, setCheckedAll] = useState(false)
    const [isButtonActive, setButtonActive] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false);
    const [closeArray, setCloseArray] = useState([])
    const [addMaintenanceSet, setAddMaintenance] = useState(false);
    const [messageId, setMessageId] = useState('');



    const [params, setParams] = useState({
        limit: 15,
        offset: 0,
        propertyId: null,
        requestById: null,
        status: null,
        categoryId: null,
        requestByDetails: null,
        propertyDetails: null,
        status: "1,2,3,4,5,6",
        contractorName: null,
        sort: null,
        orderData: 0,
        order: "",
    })
    const [currentPage, setcurrentPage] = useState(1);
    const [setFilter, setIsFilter] = useState(false);
    const [editData, setEditData] = useState({
        propertyId: null,
        maintenanceFile: [],
        maintenanceFileEdit: [],
        description: null,
        categoryId: null,
        requestById: null,
        isEdit: false,
        isView: false,
        maintenancePicture: [],
        status: 1

    })

    const prevLimit = usePrevious(props.commonReducer.limitData);
    const { commonReducer: { emailCheck } } = props;
    const prevEmailCheck = usePrevious(emailCheck);



    useEffect(() => {
        props.getLimitData()
    }, []);

    useEffect(() => {
        if (addMaintenanceSet && !emailCheck.success) {
            toastr.error("Please add a maintenance email!")
        } else if (addMaintenanceSet && emailCheck.success && prevEmailCheck != emailCheck) {
            history.push('/Add-Maintenance')

        }
    }, [prevEmailCheck, emailCheck]);

    useEffect(() => {
        window.scroll(0, 0)

        if (prevLimit != props.commonReducer.limitData) {
            const { limitData } = props.commonReducer
            if (limitData) {
                let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 6)
                let tempParams = params
                tempParams.limit = limitCount ? limitCount.count : 15
                setParams(tempParams)

                async function renderCall() {
                    await props.getMaintenance(tempParams)
                }

                renderCall();
                setTimeout(() => {
                    setIsLoading(false)
                }, 3000)
            }

        }


    }, [prevLimit, props.commonReducer.limitData]);

    const prevMaintenance = usePrevious(props.maintenances);

    useEffect(() => {
        if (isSubmitted && prevMaintenance !== props.maintenances && !props.maintenances.success) {
            toastr.error(props.maintenances.message)
        } else if (isSubmitted && prevMaintenance !== props.maintenances && props.maintenances.success) {
            toastr.success(props.maintenances.message)
        }
    }, [prevMaintenance, props.maintenances]);

    const openModal = () => {
        setMaintenanceModal(true)
    }

    const closeModal = () => {
        setMaintenanceModal(false)
        setEditData({
            _id: null,
            propertyId: null,
            maintenanceFile: [],
            maintenanceFileEdit: [],
            description: null,
            categoryId: null,
            requestById: null,
            isEdit: false,
            isView: false,
            status: 1,
            paymentId: null
        })
    }

    const renderModal = () => {
        return <MaintenanceAdd
            addMaintenanceDetails={addMaintenanceDetails}
            onCancel={closeModal}
            maintenanceDetails={editData}
        />
    }

    const addMaintenanceDetails = async (maintenanceDetails) => {
        setIsSubmitted(true)
        if (params.offset > 0) {
            if (editData.isEdit) {
                await props.updateMaintenance(maintenanceDetails)
                await props.getMaintenance(params)
            } else {

                await props.addMaintenance(maintenanceDetails)
                await props.getMaintenance(params)
            }

        } else {
            let tempParams = { ...params }
            tempParams.offset = 0
            if (editData.isEdit) {
                await props.updateMaintenance(maintenanceDetails)
                await props.getMaintenance(tempParams)
            } else {

                await props.addMaintenance(maintenanceDetails)
                await props.getMaintenance(tempParams)
            }
        }

        setEditData({
            _id: null,
            propertyId: null,
            maintenanceFile: [],
            maintenanceFileEdit: [],
            description: null,
            categoryId: null,
            requestById: null,
            isEdit: false,
            isView: false,
            status: 1,
            paymentId: null
        })
        setMaintenanceModal(false)
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
        history.push('/Maintenance')
    }

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getMaintenance(data);
    }

    const handleFilter = (e) => {
        e.preventDefault()
        setIsFilter(!setFilter)
    }

    const applyFilter = async (data) => {
        setIsLoading(true)
        setParams(params)
        await props.getMaintenance(params);
        setIsFilter(!setFilter)
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }

    const editMaintenance = async (maintenance) => {
        // let editData = {
        //     _id: maintenance._id,
        //     propertyDetail: {
        //         value: maintenance.propertyId && maintenance.propertyId._id,
        //         label: maintenance.propertyId && maintenance.propertyId.propertyName
        //     },
        //     propertyId: maintenance.propertyId && maintenance.propertyId._id,
        //     maintenancePicture: maintenance.maintenancePicture,
        //     maintenanceFile: [],
        //     description: maintenance.description,
        //     categoryId: maintenance.categoryId && maintenance.categoryId._id,
        //     requestById: maintenance.requestById && maintenance.requestById._id,
        //     isEdit: true,
        //     status: JSON.stringify(maintenance.status),
        //     amount: maintenance.amount,
        //     paymentId: maintenance.paymentId && maintenance.paymentId._id,
        //     propertyOwnerName: maintenance.paymentId && maintenance.paymentId.entityName,
        //     tenantName: maintenance.requestById && maintenance.requestById.firstName + " " + maintenance.requestById.lastName,
        //     unitId: maintenance.unitId,
        //     // requestBy: {
        //     //     value: maintenance.requestById && maintenance.requestById._id,
        //     //     label: maintenance.requestById && maintenance.requestById.firstName + " " + maintenance.requestById.lastName
        //     // }
        // }
        // setMaintenanceModal(true)
        // setEditData(editData)
        history.push(`/Add-Maintenance/${maintenance._id}`)
    }

    const viewMaintenance = (maintenance) => {
        let viewData = {
            _id: maintenance._id,
            propertyId: maintenance.propertyId._id,
            maintenanceFile: [],
            maintenancePicture: maintenance.maintenancePicture,
            description: maintenance.description,
            categoryId: maintenance.categoryId._id,
            requestById: maintenance.requestById._id,
            isView: true,
            status: JSON.stringify(maintenance.status),
            amount: maintenance.amount,
            paymentId: maintenance.paymentId && maintenance.paymentId._id,
            propertyOwnerName: maintenance.paymentId && maintenance.paymentId.entityName,
            requestBy: {
                value: maintenance.requestById._id,
                label: maintenance.requestById.firstName + " " + maintenance.requestById.lastName
            }
        }
        setEditData(viewData)
        setMaintenanceModal(true)
    }

    const closeFilter = () => {
        setIsFilter(false)

        setParams({
            ...params,
            propertyId: "",
            requestById: "",
            status: 1,
            categoryId: "",
            requestByDetails: "",
            propertyDetails: ''
        })
    }

    const handleChange = async (e) => {
        const { name, value } = e.target
        if (name === "propertyId") {
            await props.getTenant({ [name]: value, isDeleted: false, isClosed: false })
        }
        setParams({
            ...params,
            [name]: value
        })
    }


    const handleChangeTenant = (value) => {
        setParams({
            ...params,
            requestByDetails: value,
            requestById: value.value
        })
    }


    const clearFilter = async () => {
        setIsLoading(true)
        let tempParams = params
        tempParams.propertyId = ""
        tempParams.requestById = ""
        tempParams.status = "1,2,3,4,5,6"
        tempParams.categoryId = ""
        tempParams.requestByDetails = ""
        tempParams.propertyDetails = ""
        tempParams.contractorName = ""
        await props.getMaintenance(tempParams);
        setTimeout(() => {
            setIsLoading(false)
        }, 2000)
        setIsFilter(false)
        setParams(tempParams)
       
    }

    const { tenantReducer: { tenants } } = props;


    const promiseOptionsProperty = async val => {
        await props.getProperty({ name: val })
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeProperty = async (value) => {
        let tempParams = params
        tempParams.propertyId = value.value
        // props.applyFilter(tempParams)
        setParams({
            ...params,
            propertyId: value.value,
            propertyDetails: value
        })
    }

    const filterData = async (value) => {
        let tempParams = params
        if (value) {
            tempParams.sort = value
            let orderDetails = !params.orderData
            tempParams.order = orderDetails ? "ASC" : "DESC"
            tempParams.orderData = !params.orderData
        }
        setParams(tempParams)
        setIsLoading(true)
        await props.getMaintenance(tempParams)
        setTimeout(() => {
            setIsLoading(false)

        }, 1000)
    }

    const promiseOptionsTenant = async val => {
        await props.getTenant({ name: val, isDeleted: false, isClosed: false })
        const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));
        return tenantList.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleCheck = (e, maintenance) => {
        let tempArray = closeArray
        if (e.target.checked) {
            setButtonActive(true)
            let data = {
                id: maintenance._id,
            }
            tempArray.push(data)
            return setCloseArray(tempArray)
        } else {
            setCheckedAll(false)
            tempArray = tempArray.filter(t => t.id !== maintenance._id)
            if (!tempArray.length) setButtonActive(false)
            return setCloseArray(tempArray)
        }
    }

    const handleCheckAll = (e) => {
        let tempArray = []
        if (e.target.checked) {
            setButtonActive(true)
            setCheckedAll(true)
            return props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList.length ?
                props.maintenanceReducer.maintenanceList.maintenanceList.map((maintenance, key) => {

                    if (maintenance.status) {
                        let data = {
                            id: maintenance._id,
                            status: 1,
                        }
                        tempArray.push(data)
                        setCloseArray(tempArray)
                    }
                })
                : null
        } else {
            setCloseArray([])
            setButtonActive(false)
            setCheckedAll(false)
        }

    }

    const handleDelete = async () => {
        setIsSubmitted(true)
        setDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
        setCloseArray([])
        setCheckedAll(false)
        setIsSubmitted(false)
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Maintenance'
            message='Do you want to delete this maintenance?'
            onCancel={closeDeleteModal}
            onConfirm={deleteMaintenance}
        />
    }

    const deleteMaintenanceById = (maintenance) => {
        let tempArray = []
        let data = {
            id: maintenance._id,
        }
        tempArray.push(data)
        setCloseArray(tempArray)
        setIsSubmitted(true)
        setDeleteModal(true)
    }

    const deleteMaintenance = async () => {
        setIsSubmitted(true)
        await props.deleteMaintenance(closeArray)
        setCloseArray([])
        setCheckedAll(false)
        await props.getMaintenance(params);
        setDeleteModal(false)
        setIsSubmitted(false)
    }

    const closeMaintenanceData = async () => {
        setIsSubmitted(true)
        await props.closeMaintenance(closeArray)
        setCloseArray([])
        setCheckedAll(false)
        await props.getMaintenance(params);
        setIsSubmitted(false)
    }

    const handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = params
        tempParams.limit = value
        tempParams.offset = 0
        setParams(tempParams)
        await props.updateLimitData({ type: 6, count: value });
        await props.getMaintenance(tempParams);
    }

    const addMaintenance = async () => {
        setAddMaintenance(true)
        await props.emailCheck({ emailType: "maintenance" })
        setAddMaintenance(false)
    }


    const renderConfirmModal = () => {
        return <Confirm
            title='Delete Message'
            message='Do you want to delete this message?'
            onCancel={closeModal}
            onConfirm={handleDeleteMessage}
        />
    }

    const handleDeleteMessage = async () => {
        await props.deleteMessage(messageId);
        setConfirmModal(false);
        await props.getMessageByTenantId(props.id, params);

    };



    return (
        <div className="tenants-otr">
            {/* <ToastContainer/> */}
            {confirmModal ? <Modal body={renderConfirmModal()} onCancel={closeModal} /> : null}
            {(isSubmitted || isLoading) && <LoaderSpinner />}
            {maintenanceModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            <div className="innago-table-top main-table-top maint_req maint_req_btm invoiceTable" >
                <div className="lft">
                    <h1>MAINTENANCE REQUESTS</h1>
                </div>
                <div className="rgt">
                    <button className="btn-dvd blue-bg">Total
                        Requests<strong>{props.maintenanceReducer.maintenanceList ? props.maintenanceReducer.maintenanceList.maintenanceListCount : 0}</strong>
                    </button>
                    <button
                        className="btn-dvd orange-bg">Open<strong>{props.maintenanceReducer.maintenanceList ? props.maintenanceReducer.maintenanceList.openCount : 0}</strong>
                    </button>
                    <button
                        className="btn-dvd green-bg">Closed<strong>{props.maintenanceReducer.maintenanceList ? props.maintenanceReducer.maintenanceList.closedCount : 0}</strong>
                    </button>

                    <button className="fltr flrWid " onClick={handleFilter}><i className="fas fa-sliders-h"></i>Filter
                    </button>
                    {setFilter ? <MaintenanceFilter
                        applyFilter={applyFilter}
                        closeFilter={closeFilter}
                        handleChange={handleChange}
                        promiseOptionsProperty={promiseOptionsProperty}
                        handleChangeProperty={handleChangeProperty}
                        handleChangeTenant={handleChangeTenant}
                        clearFilter={clearFilter}
                        promiseOptionsTenant={promiseOptionsTenant}
                        params={params}
                    /> : null}
                    <button className="add" onClick={() => addMaintenance()}>
                        <svg>
                            <use xlinkHref="/images/svg-sprite.svg#ic-add" />
                        </svg>
                        New Maintenance
                    </button>
                </div>
            </div>
            {props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList
                && props.maintenanceReducer.maintenanceList.maintenanceList.length ? <div className="maintenancerightBtn">
                    <div className="formsectionPadding selTenant pr-2">
                        <div className="selctOtr">
                            {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                            <select className="form-control" value={params.limit} name={"limit"}
                                onChange={handleChangeLimit}>
                                <option value={15}>15</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>
                    <button className="btn btnClose" onClick={closeMaintenanceData} disabled={!isButtonActive}>Close
                </button>
                    <button className="btn btnDelete" onClick={handleDelete} disabled={!isButtonActive}>Delete</button>
                </div> : null}
            <div className={`innago-table table-main invoiceTable maintenanceTable ${props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.maintenanceList
                && props.maintenanceReducer.maintenanceList.maintenanceList.length ? "remarw" : "addarw"}`}>
                <MaintenanceList
                    maintenanceList={props.maintenanceReducer.maintenanceList}
                    editMaintenance={editMaintenance}
                    viewMaintenance={viewMaintenance}
                    handleCheck={handleCheck}
                    handleCheckAll={handleCheckAll}
                    closeArray={closeArray}
                    isButtonActive={isButtonActive}
                    isCheckedAll={isCheckedAll}
                    handleDelete={handleDelete}
                    closeMaintenanceData={closeMaintenanceData}
                    deleteMaintenanceById={deleteMaintenanceById}
                    filterData={filterData}
                />
                <div className="paginationSec">
                    <ul className="pagination">
                        {props.maintenanceReducer.maintenanceList && props.maintenanceReducer.maintenanceList.totalCount ?
                            <Pagination
                                totalRecords={props.maintenanceReducer.maintenanceList.totalCount}
                                pageLimit={params.limit}
                                pageNeighbours={1}
                                currentPage={currentPage}
                                onPageChanged={onPageChanged}
                            /> : null}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer, tenantReducer } = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        commonReducer,
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action,
    ...commonAction
})(Maintenance);

