import moment from 'moment';
// encode a Javascript Object into a query-string
export const toQueryParams = (obj) => {
    if (typeof obj !== "object") return 'Not an object';
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const numberWithCommas = (x) => {
    if (x == null || x === undefined) return '0.00'
    let num = x.toString()
    num = parseFloat(num).toFixed(2)
    num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num
}

export const formatDate = (date) => {
    if (!date)
        return 'N/A';
    else
        return moment(date).format('MM/DD/YYYY')
}

export const formatUsPhone = (value) => {
    let cleaned = ('' + value).replace(/\D/g, '')
    // let isCountryCode = cleaned.match(/^(1|)$/)
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    // let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if(match) {
       // let intlCode = (match[1] ? '+1 ' : '')
        let formatted = [ match[1], '-', match[2], '-', match[3]].join('')
        // const formatted = '(' + match[1] + ') ' + match[2] + '-' + match[3]
        // if(!formatted.includes('+')){
        //     console.log('formatted', +formatted)
        //     formatted = '+'+formatted
        // }
        return formatted;
    }
    //  else if (isCountryCode && isCountryCode[1] == 1) {
    //     return '+1 '
    // }
    else{
        return value
    }
}