import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import usePrevious from "../../../Common/components/prevProps";
import toastr from '../../../Common/components/Toaster';
import "react-toastify/dist/ReactToastify.css";
import commonAction from '../../../Common/action';
import * as validation from '../../../Common/components/validation';
import LoaderSpinner from "../../../Common/components/Loader";



const AddFile = (props) => {
    const [fileUploadForm, setFileUploadForm] = useState({
        fileName: null,
        fileData: null,
        id: props.id
    });
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isLoder, setIsLoder] = useState(false)

    const prevFile = usePrevious(props.addFileData);


    useEffect(() => {
        if (isSubmitted && prevFile != props.addFileData) {
            if (!props.addFileData.success)
                toastr.error("Error while addding file")
            else if (props.addFileData.success)
                toastr.success("Successfully added file")
        }

    }, [prevFile, props.addFileData])

    const handleFile = (e) => {
        const { name } = e.target
        setFileUploadForm({ ...fileUploadForm, [name]: e.target.files[0] })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFileUploadForm({ ...fileUploadForm, [name]: value })
    }

    const submitData = async () => {
        setIsSubmitted(true)
        if (fileUploadForm.fileName && fileUploadForm.fileData) {
            setIsLoder(true)
            await props.addFile(fileUploadForm)
            await props.getTenantId(props.id)
            setIsLoder(false)
            await props.onCancel()
            setFileUploadForm(null)
            setIsSubmitted(false)
        }

    }

    const validate = (value, name, type) => {

        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
            }
        } else return null;
    };

    return (
        <div className="modal  addTenant addInvoiceModal addpsw addsign" id="modalId" style={{ display: "block" }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            {isLoder && <LoaderSpinner />}
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add File</h5>
                        <button type="button" className="close" onClick={() => props.onCancel()} aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="clientInfo">
                            <div class="formSection">
                                <div class="outrForm">
                                    <div className="col-md-12 formInp formSecInp">
                                        <label >File Title</label>
                                        <div className="attachRow">
                                            <input type="text" className="form-control"
                                                name={"fileName"}
                                                placeholder={"Enter File Title"}
                                                onChange={(e) => handleChange(e)} />

                                        </div>
                                        {validate(fileUploadForm.fileName, "File Title", "required")}
                                    </div>
                                    <div className="col-md-12 formInp formSecInp">
                                        <label >Attach File</label>
                                        <div className="attachRow">
                                            <input type="file" className="customfile form-control cstmAttach"
                                                name={"fileData"}
                                                placeholder="Insert knowledgebase Link"
                                                onChange={(e) => handleFile(e)} />

                                        </div>
                                        {validate(fileUploadForm.fileData, "File", "required")}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer"><button class="add-btn btn-submit" onClick={() => submitData()}>Submit</button></div>
                </div>
            </div>
        </div>


    )
}

const mapStateToProps = state => {
    const { settingsReducer, commonReducer, tenantReducer } = state;
    return {
        payment: settingsReducer.payment,
        commonReducer,
        addFileData: tenantReducer.addFileData
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(AddFile);
