//add tenant
export const ADD_TENANT_REQUEST = 'ADD_TENANT_REQUEST ';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS ';
export const ADD_TENANT_FAILURE = 'ADD_TENANT_FAILURE ';

//add tenant
export const ADD_RECURED_DATE_REQUEST = ' ADD_RECURED_DATE_REQUEST ';
export const ADD_RECURED_DATE_SUCCESS = ' ADD_RECURED_DATE_SUCCESS ';
export const ADD_RECURED_DATE_FAILURE = ' ADD_RECURED_DATE_FAILURE ';

//get tenant
export const GET_TENANT_REQUEST = 'GET_TENANT_REQUEST ';
export const GET_TENANT_SUCCESS = 'GET_TENANT_SUCCESS ';
export const GET_TENANT_FAILURE = 'GET_TENANT_FAILURE ';

//get tenant by id
export const GET_TENANT_ID_REQUEST = 'GET_TENANT_ID_REQUEST ';
export const GET_TENANT_ID_SUCCESS = 'GET_TENANT_ID_SUCCESS ';
export const GET_TENANT_ID_FAILURE = 'GET_TENANT_ID_FAILURE ';

//get tenant proprty by id
export const GET_TENANT_PROPERTY_REQUEST = 'GET_TENANT_PROPERTY_REQUEST ';
export const GET_TENANT_PROPERTY_SUCCESS = 'GET_TENANT_PROPERTY_SUCCESS ';
export const GET_TENANT_PROPERTY_FAILURE = 'GET_TENANT_PROPERTY_FAILURE ';

//DELETE tenant
export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST ';
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS ';
export const DELETE_TENANT_FAILURE = 'DELETE_TENANT_FAILURE ';

//DELETE tenant permanently
export const DELETE_PERMANENT_TENANT_REQUEST = 'DELETE_PERMANENT_TENANT_REQUEST ';
export const DELETE_PERMANENT_TENANT_SUCCESS = 'DELETE_PERMANENT_TENANT_SUCCESS ';
export const DELETE_PERMANENT_TENANT_FAILURE = 'DELETE_PERMANENT_TENANT_FAILURE ';

//get INVOICE types by id
export const INVOICE_TYPE_REQUEST = 'INVOICE_TYPE_REQUEST ';
export const INVOICE_TYPE_SUCCESS = 'INVOICE_TYPE_SUCCESS ';
export const INVOICE_TYPE_FAILURE = 'INVOICE_TYPE_FAILURE ';

//get INVOICE by tenant id
export const INVOICE_REQUEST = 'INVOICE_REQUEST ';
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS ';
export const INVOICE_FAILURE = 'INVOICE_FAILURE ';

//get EMAILS by tenant id
export const MESSAGE_TENANT_REQUEST = 'MESSAGE_TENANT_REQUEST ';
export const MESSAGE_TENANT_SUCCESS = 'MESSAGE_TENANT_SUCCESS ';
export const MESSAGE_TENANT_FAILURE = 'MESSAGE_TENANT_FAILURE ';

//DELETE tenant
export const DELETE_TENANT_FILE_REQUEST = 'DELETE_TENANT_FILE_REQUEST ';
export const DELETE_TENANT_FILE_SUCCESS = 'DELETE_TENANT_FILE_SUCCESS ';
export const DELETE_TENANT_FILE_FAILURE = 'DELETE_TENANT_FILE_FAILURE ';

//DELETE INVOICE
export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST ';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS ';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE ';

//add LEASE AGREEMENT by tenant id
export const LEASE_TENANT_REQUEST = 'LEASE_TENANT_REQUEST ';
export const LEASE_TENANT_SUCCESS = 'LEASE_TENANT_SUCCESS ';
export const LEASE_TENANT_FAILURE = 'LEASE_TENANT_FAILURE ';

//edit LEASE AGREEMENT by tenant id
export const LEASE_TENANT_EDIT_REQUEST = 'LEASE_TENANT_EDIT_REQUEST ';
export const LEASE_TENANT_EDIT_SUCCESS = 'LEASE_TENANT_EDIT_SUCCESS ';
export const LEASE_TENANT_EDIT_FAILURE = 'LEASE_TENANT_EDIT_FAILURE ';

//view LEASE AGREEMENT by tenant id on add tenant
export const VIEW_LEASE_TENANT_REQUEST = 'VIEW_LEASE_TENANT_REQUEST ';
export const VIEW_LEASE_TENANT_SUCCESS = 'VIEW_LEASE_TENANT_SUCCESS ';
export const VIEW_LEASE_TENANT_FAILURE = 'VIEW_LEASE_TENANT_FAILURE ';

//view LEASE AGREEMENT by tenant id
export const VIEW_LEASE_REQUEST = 'VIEW_LEASE_REQUEST ';
export const VIEW_LEASE_SUCCESS = 'VIEW_LEASE_SUCCESS ';
export const VIEW_LEASE_FAILURE = 'VIEW_LEASE_FAILURE ';

//create password by tenant id
export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST ';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS ';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE ';

//ADD PAYMENT  on sucess of tenant
export const ADD_PAYMENT_TENANT_REQUEST = 'ADD_PAYMENT_TENANT_REQUEST ';
export const ADD_PAYMENT_TENANT_SUCCESS = 'ADD_PAYMENT_TENANT_SUCCESS ';
export const ADD_PAYMENT_TENANT_FAILURE = 'ADD_PAYMENT_TENANT_FAILURE ';

//pay Invoice
export const SEND_LOB_MAIL_REQUEST = "SEND_LOB_MAIL_REQUEST"
export const SEND_LOB_MAIL_SUCCESS = "SEND_LOB_MAIL_SUCCESS"
export const SEND_LOB_MAIL_FAILURE = "SEND_LOB_MAIL_FAILURE"

//edit invoice of a tenant
export const EDIT_INVOICE_TENANT_REQUEST = 'EDIT_INVOICE_TENANT_REQUEST ';
export const EDIT_INVOICE_TENANT_SUCCESS = 'EDIT_INVOICE_TENANT_SUCCESS ';
export const EDIT_INVOICE_TENANT_FAILURE = 'EDIT_INVOICE_TENANT_FAILURE ';

//view html view of notice of a tenant
export const VIEW_NOTICE_REQUEST = 'VIEW_NOTICE_REQUEST ';
export const VIEW_NOTICE_SUCCESS = 'VIEW_NOTICE_SUCCESS ';
export const VIEW_NOTICE_FAILURE = 'VIEW_NOTICE_FAILURE ';

//REOPEN Tenant
export const REOPEN_TENANT_REQUEST = 'REOPEN_TENANT_REQUEST ';
export const REOPEN_TENANT_SUCCESS = 'REOPEN_TENANT_SUCCESS ';
export const REOPEN_TENANT_FAILURE = 'REOPEN_TENANT_FAILURE ';

//VIEW file
export const VIEW_FILE_REQUEST = 'VIEW_FILE_REQUEST ';
export const VIEW_FILE_SUCCESS = 'VIEW_FILE_SUCCESS ';
export const VIEW_FILE_FAILURE = 'VIEW_FILE_FAILURE ';

//Add file
export const ADD_FILE_REQUEST = 'ADD_FILE_REQUEST ';
export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS ';
export const ADD_FILE_FAILURE = 'ADD_FILE_FAILURE ';

//delete file
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST ';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS ';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE ';

//Add tenant email
export const ADD_TENANT_EMAIL_REQUEST = 'ADD_TENANT_EMAIL_REQUEST ';
export const ADD_TENANT_EMAIL_SUCCESS = 'ADD_TENANT_EMAIL_SUCCESS ';
export const ADD_TENANT_EMAIL_FAILURE = 'ADD_TENANT_EMAIL_FAILURE ';


//delete tenant email
export const DELETE_TENANT_EMAIL_REQUEST = 'DELETE_TENANT_EMAIL_REQUEST ';
export const DELETE_TENANT_EMAIL_SUCCESS = 'DELETE_TENANT_EMAIL_SUCCESS ';
export const DELETE_TENANT_EMAIL_FAILURE = 'DELETE_TENANT_EMAIL_FAILURE ';

//get tenant email
export const GET_TENANT_EMAIL_REQUEST = 'GET_TENANT_EMAIL_REQUEST ';
export const GET_TENANT_EMAIL_SUCCESS = 'GET_TENANT_EMAIL_SUCCESS ';
export const GET_TENANT_EMAIL_FAILURE = 'GET_TENANT_EMAIL_FAILURE ';

export const GENERATE_TENANT_INVOICE_REQUEST = 'GENERATE_TENANT_INVOICE_REQUEST ';
export const GENERATE_TENANT_INVOICE_SUCCESS = 'GENERATE_TENANT_INVOICE_SUCCESS ';
export const GENERATE_TENANT_INVOICE_FAILURE = 'GENERATE_TENANT_INVOICE_FAILURE ';