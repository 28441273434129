import React from "react";
import { withRouter } from 'react-router-dom';
import history from "../../../../config/history";
import action from "../../../Profile/action";
import { connect } from "react-redux";
import { MAIN_ROUTE } from "../../../../config/constants";
import Notification from './notification';
import commonAction from '../../action';
import Modal from '../Modal'
import io from 'socket.io-client';
import ChangePassword from "../../../Profile/components/changePassword";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import SearchResult from './searchResult';
import { DebounceInput } from 'react-debounce-input';
import moment from "moment";
import TenantInvoiceNew from "../../../Tenants/components/Invoice/TenantInvoiceNew";
import tenantAction from '../../../Tenants/action';
import invoiceAction from '../../../Invoice/action'
import toastr from "../Toaster";
import LoaderSpinner from "../Loader";

class TopHeader extends React.Component {

    state = {
        isLoading: false,
        toggleNotification: false,
        modal: false,
        name: "",
        searchData: [],
        showSearch: false,
        nodata: true,
        invoiceModal: false,
        editData: {},
        isSubmitted: false,
        smsTenant: false
    }

    componentDidMount = async () => {
        await this.props.getProfile();
        const socket = io(MAIN_ROUTE);
        socket.on("message", async (data) => {
            await this.props.getNotificationDetails()
        });
        socket.on("new_email", async (data) => {
            await this.props.getNotificationDetails()
        });
        await this.props.getNotificationDetails()
        this.setState({
            isLoading: true
        })
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevProps.advanceSearchData != this.props.advanceSearchData) {
            const { advanceSearchData } = this.props
            if (advanceSearchData && advanceSearchData.success) {
                if (!advanceSearchData.property.length && !advanceSearchData.tenant.length
                    && !advanceSearchData.invoice.length && !advanceSearchData.maintenance.length) {
                    this.setState({
                        nodata: false
                    })
                }
            }
        }

        if (this.state.isSubmitted && prevProps.invoicePayment !== this.props.invoicePayment && !this.props.invoicePayment.success) {
            toastr.error(this.props.invoicePayment.message)
        } else if (this.state.isSubmitted && prevProps.invoicePayment !== this.props.invoicePayment && this.props.invoicePayment.success) {
            toastr.success(this.props.invoicePayment.message);
        }
        if (this.state.smsTenant && prevProps.smsInvoice !== this.props.smsInvoice && !this.props.smsInvoice.success) {
            toastr.error(this.props.smsInvoice.message)
        } else if (this.state.smsTenant && prevProps.smsInvoice !== this.props.smsInvoice && this.props.smsInvoice.success) {
            if (this.props.smsInvoice.message.status === 'success') {
                toastr.success("Message sent successfully")
            } else {
                toastr.error(`Message sending failed`)
            }
        }

        const { profileReducer: { profileDetail } } = this.props
        // if (prevProps.profileDetail != profileDetail && !profileDetail.success) {
        //     if (!profileDetail.success) {
        //         await this.props.getProfile();
        //     }
        // }
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutside);
        let serchDiv = document.getElementById("serachableDiv")
        serchDiv && serchDiv.removeEventListener("mousedown", this.handleSearchBar);
    }

    handleClickOutside = async (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.toggleNotification === true) {
                await this.props.getNotificationDetails()
            }
            this.setState({
                toggleNotification: false,
                // showSearch:false

            })


        }
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                toggleNotification: false,
                // showSearch:false

            })
        }
    }

    handleSearchBar = () => {
        if (this.state.showSearch) {
            this.setState({
                showSearch: false,
            })
        }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("email");
        // history.push("/");
        window.location.reload()
    };

    handleProfileView = () => {
        history.push('/Profile')
    }

    renderHome = () => {
        history.push('/home')
    }

    viewNotification = async () => {
        this.setState({
            toggleNotification: !this.state.toggleNotification
        }, () => this.notificationHandle())

        // const socket = io(MAIN_ROUTE);
        // socket.on("message", async (data) => {
        //     await this.props.getNotificationDetails()
        // });
        // socket.on("new_email", async (data) => {
        //     await this.props.getNotificationDetails()
        // });
        // await this.props.getNotificationDetails()
        // this.setState({
        //     isLoading: true
        // })
    }
    notificationHandle = async () => {

        if (this.state.toggleNotification) {
            await this.props.updateNotificationDetails()
        } else {
            await this.props.getNotificationDetails()
        }
    }

    viewDetailNotification = (type) => {
        if (type === "mail") {
            history.push('/Messaging/Email/Inbox')
        } else {
            history.push('/Messaging/Sms')
        }
    }

    openNav = () => {
        var element = document.getElementById('sidebar');
        var element1 = document.getElementById('main');
        element.style.width = '220px';
        element1.style.marginLeft = "220px";
        element.classList.add('expandnavbar');
        element1.classList.add('expandnavbarr');
        // window.location.reload()
    }

    onOpenModal = () => {
        this.setState({
            modal: true
        })
    }

    onCloseModal = () => {
        this.setState({
            modal: false
        })
    }
    renderModal = () => {
        return <ChangePassword
            onCancel={this.onCloseModal}
            userRole={this.props.profileReducer.profileDetail.profileDetail && this.props.profileReducer.profileDetail.profileDetail.admin.role}
            profileDetail={this.props.profileReducer.profileDetail || {}}
        />
    }

    handleChange = async (e) => {
        const { name, value } = e.target
        if (value) {
            this.setState({
                showSearch: true,
                nodata: true
            })
        } else {
            this.setState({
                showSearch: false
            })
        }
        this.setState({
            [name]: value
        })
        await this.props.advancedSerachData({ name: e.target.value })

    }

    renderData = () => {
        let { advanceSearchData } = this.props
        if (advanceSearchData && advanceSearchData.success) {
            advanceSearchData.property1.length && advanceSearchData.property1.map((pro, key) => {
                return <div>{pro.propertyName}</div>
            })
            advanceSearchData.tenant1.length && advanceSearchData.tenant1.map((ten, key) => {
                return <span>{ten.firstName}</span>
            })
            advanceSearchData.settings.length && advanceSearchData.settings.map((set, key) => {
                return <span>{set.name}</span>
            })
            // advanceSearchData.unitList.length && advanceSearchData.unitList.map((uni, key) => {
            //     return <span>{uni.name}</span>
            // })
            advanceSearchData.invoice1.length && advanceSearchData.invoice1.map((inv, key) => {
                return <span>{inv.invoiceNumber}</span>
            })
            advanceSearchData.maintenance1.length && advanceSearchData.maintenance1.map((main, key) => {
                return <span>{main.name}</span>
            })


        }

    }

    renderItems = () => {

    }


    handleSelect = () => {

    }

    promiseOptionsSearch = async val => {
        await this.props.advancedSerachData({ name: val })
        const { advanceSearchData } = this.props
        let tempArray = []
        if (advanceSearchData.property1.length) {
            advanceSearchData.property1.map((pro) => tempArray.push({
                value: pro._id,
                label: pro.propertyName,
                type: "property"
            }))
        }
        if (advanceSearchData.tenant1.length) {
            advanceSearchData.tenant1.map((tenant) => tempArray.push({
                value: tenant._id,
                label: tenant.firstName,
                type: "tenant"
            }))

        }
        if (advanceSearchData.settings.length) {
            advanceSearchData.settings.map((tenant) => tempArray.push({
                value: tenant._id,
                label: tenant.name,
                type: "settings"
            }))

        }
        if (advanceSearchData.settings.length) {
            advanceSearchData.settings.map((tenant) => tempArray.push({
                value: tenant._id,
                label: tenant.name,
                type: "settings"
            }))

        }
        // if (advanceSearchData.unitList.length) {
        //     advanceSearchData.settings.map((tenant) => tempArray.push({
        //         value: tenant._id,
        //         label: tenant.name,
        //         type: "unit"
        //     }))
        //
        // }
        if (advanceSearchData.invoice1.length) {
            advanceSearchData.settings.map((tenant) => tempArray.push({
                value: tenant._id,
                label: tenant.invoiceNumber,
                type: "invoice"
            }))

        }
        if (advanceSearchData.maintenance1.length) {
            advanceSearchData.settings.map((tenant) => tempArray.push({
                value: tenant._id,
                label: tenant.name,
                type: "maintenance"
            }))

        }

        this.setState({
            searchData: tempArray
        })
        return tempArray.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    handleProperty = (id) => {
        this.setState({
            showSearch: false,
            name: ""
        }, () => history.push(`/PropertyDetails/${id}`))

    }

    handleUnit = (id) => {
        this.setState({
            showSearch: false,
            name: ""
        }, () => history.push(`/PropertyDetails/${id}`))
    }

    handleTenant = async (id) => {
        await this.props.getTenantId(id)
        this.setState({
            showSearch: false,
            name: ""
        }, () => history.push(`/Tenants/${id}/Summary`))
    }

    handleMaintenance = (id) => {
        this.setState({
            showSearch: false,
            name: ""
        }, () => history.push(`/Add-Maintenance/${id}`))
    }

    // handleInvoice = (id) => {
    //     this.setState({
    //         showSearch: false,
    //         name: ""
    //     }, () => history.push(`/Invoice/All`))
    // }

    handleSettings = () => {
        this.setState({
            showSearch: false,
            name: ""
        }, () => history.push(`/Settings`))
    }


    handleInvoice = async (invoice) => {
        this.setState({
            showSearch: false
        })
        await this.props.getInvoiceById(invoice._id)
        const { invoiceById } = this.props;
        if (invoiceById.invoice && invoiceById.invoice.length) {
            let invoice = invoiceById.invoice[0]
            let editDataTemp = {
                id: invoice._id,
                invoiceNumber: invoice.invoiceNumber,
                lastCaptureAttempt: invoice.attemptToCaptureDate,
                // balance: invoiceList && invoiceList.data.balance,
                status: invoice.status,
                startDate:invoice.startDate ? moment((invoice.startDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null,
                dueDate:invoice.dueDate ? moment((invoice.dueDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null, 
                tenantId: invoice.tenantId && invoice.tenantId._id,
                tenantName: invoice.tenantId ? (invoice.tenantId.firstName + ' ' + invoice.tenantId.lastName) : '',
                tenantAddress: invoice.tenantId && invoice.tenantId.propertyId && invoice.tenantId.propertyId.propertyName,
                isRecurring: invoice.isRecurring,
                amount: invoice.amount,
                invoiceTypeId: invoice.invoiceTypeId && invoice.invoiceTypeId._id,
                invoiceTypeName: invoice.invoiceTypeId && invoice.invoiceTypeId.name,
                paymentTypeId: invoice.invoiceTypeId && invoice.paymentTypeId._id,
                paymentTypeName: invoice.invoiceTypeId && invoice.paymentTypeId.name,
                description: invoice.description || invoice.title,
                // paidAmount: invoiceList && invoiceList.data.paidAmount,
                // totalDue: invoiceList && invoiceList.data.totalDue,
                leaseStartDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.startDate,
                leaseEndDate: invoice && invoice.tenantId && invoice.tenantId.leaseId && invoice.tenantId.leaseId.endDate,
                cardDetails: invoice.tenantId && invoice.tenantId.cardDetails,
                tenantDetails: {
                    value: invoice.tenantId && invoice.tenantId._id,
                    firstName: invoice.tenantId && invoice.tenantId.firstName,
                    lastName: invoice.tenantId && invoice.tenantId.lastName
                },
                invoices: invoice.charges,
                isEdit: true,
                transactionId: invoice.transactionId,
                paymentData: {
                    invoiceId: invoice._id,
                    businessID: invoice.tenantId && invoice.tenantId.propertyId && invoice.tenantId.propertyId.paymentId,
                    tenantID: invoice.tenantId && invoice.tenantId._id,
                    customerPaymentProfileId: invoice.tenantId && invoice.tenantId.customerPaymentProfileId,
                    customerProfileId: invoice.tenantId && invoice.tenantId.customerProfileId,
                    amount: invoice.amount,
                }
            }
            this.setState({
                editData: editDataTemp,
                invoiceModal: true,
                showSearch: false,
                name: ""
            })
        }

    }


    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = async (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {

            this.setState({
                showSearch: false,
                toggleNotification: false,
                name: ""


            })
            if (this.state.toggleNotification === true) {
                await this.props.getNotificationDetails()
            }
            this.setState({
                toggleNotification: false,
                name: ""
                // showSearch:false

            })

        }
    }

    onCloseModalInvoice = () => {
        this.setState({
            invoiceModal: false
        })
    }

    renderModalInvoice = () => {


        return <TenantInvoiceNew
            editData={this.state.editData}
            addInvoiceDetails={this.addInvoiceDetails}
            onCancel={this.onCloseModalInvoice}
            attemptCapture={this.attemptCapture}
            sendPaymentSms={this.sendPaymentSms}
        />
    }

    sendPaymentSms = async (id, no) => {
        const { editData } = this.state

        if (editData.cardDetails && editData.cardDetails.length) {
            let isDefaultCard = editData.cardDetails.find(tc => tc.defaultCard).cardNumber
            if (isDefaultCard) {
                this.setState({ smsTenant: true })

                await this.props.sendSmsEachInvoice({ receiverId: id, invoiceNumber: no })
                setTimeout(() => this.setState({ smsTenant: false }), 500)
            }
        }
        else {
            toastr.error("Please add a valid card for the selected invoice ")
        }

    }

    addInvoiceDetails = async (invoiceForm) => {
        this.setState({ isSubmitted: true })
        if (this.state.editData.isEdit) {
            await this.props.updateInvoice([invoiceForm]);
        } else {
            await this.props.addInvoice([invoiceForm]);
        }

        this.setState({
            editData: {
                tenantId: null,
                amount: null,
                startDate: null,
                dueDate: null,
                invoiceTypeId: null,
                paymentTypeId: null,
                isRecurring: false,
                status: null,
                isEdit: false,
            },
            invoiceModal: false
        })


    }
    attemptCapture = async () => {
        this.setState({ isSubmitted: true })
        await this.props.payInvoice(this.state.editData.paymentData);
        this.setState({ invoiceModal: false })
        this.setState({ isSubmitted: false, isLoading: false })

    }

    showMenu=()=>{
        if(document.getElementById("sidebar").style.display == 'block'){
            document.getElementById("sidebar").style.display = 'none';
          }else{
            document.getElementById("sidebar").style.display = 'block';
          }
    }





    render() {
        const { profileReducer: { profileDetail }, commonReducer: { notificationDetails } } = this.props;
        let role = profileDetail.profileDetail ? profileDetail.profileDetail.admin.role : null;
        let userId = profileDetail.profileDetail ? role && role == 'tenant' ? profileDetail.profileDetail.admin.tenantId._id : profileDetail.profileDetail.admin._id : null
        const notifications = [
            {
                type: 'mail',
                count: 10,
            },
            {
                type: 'message',
                count: 5,
            },
        ]


        return (
            <div className="col-md-12 navbarOtr">
                {this.state.modal ? <Modal body={this.renderModal()} onCancel={this.onCloseModal} /> : null}
                {this.state.invoiceModal ?
                    <Modal body={this.renderModalInvoice()} onCancel={this.onCloseModalInvoice} /> : null}

                <nav className="navbar navbar-expand-lg col-md-12">
                    <a className="navbar-brand" href="#" onClick={this.renderHome}>
                        <img src="/images/logo.png" />
                    </a>
                    {/* <h2 className"nav-head" onClick={this.renderHome}>Cruz Rentals</h2> */}
                    <div onClick={() => this.openNav()} className="navmenuIcon">
                        <i className="fas fa-bars"></i>
                    </div>
                    <div className="navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto  search-list">
                            <li className="list-li">
                                <div className="d-flex col-md-12 justify-content-center serach-Outrr">
                                    {
                                        role && ['admin', 'adminuser'].includes(role)
                                            ? <>
                                                <div className="serchSec">
                                                    {/*<input type="text" className="form-control" name={"name"}*/}
                                                    {/*       value={this.state.name} onChange={this.handleChange}/>*/}

                                                    <DebounceInput
                                                        type="text"
                                                        name="name"
                                                        value={this.state.name}
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        debounceTimeout={1000}
                                                        onChange={e => this.handleChange(e)}
                                                        autoComplete="off"
                                                    />
                                                    <i className="fa fa-search" aria-hidden="true"></i>

                                                </div>

                                                {(this.state.showSearch) ?
                                                    <div className="col-md-12 serachableDiv" ref={this.setWrapperRef}>
                                                        <SearchResult
                                                            advanceSearchData={this.props.advanceSearchData}
                                                            handleProperty={this.handleProperty}
                                                            handleTenant={this.handleTenant}
                                                            handleInvoice={this.handleInvoice}
                                                            handleMaintenance={this.handleMaintenance}
                                                            handleUnit={this.handleUnit}
                                                            nodata={this.state.nodata}
                                                            handleSettings={this.handleSettings}
                                                        /></div> : null}</> : null}
                                </div>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="d-flex align-items-center imgNoti dropdown">
                                <a className="nav-link dropdown-toggle"
                                    onClick={this.viewNotification}
                                    ref={this.setWrapperRef}>
                                    <img src="/images/bell.jpg" />
                                    <span
                                        className="notifyTxt">{notificationDetails && notificationDetails.unreadNotifications}</span>
                                </a>
                                {this.state.toggleNotification ?
                                    <ul className="dropdown-menu dropdown-menuu" aria-labelledby="navbarDropdown"
                                        style={{ display: 'block' }}
                                    >
                                        <li className="hedlist">
                                            <a className="dropdown-item" href="#">
                                                <img src="/images/bell.jpg" />
                                                <span>Notifications</span>
                                            </a>
                                        </li>
                                        <Notification
                                            notifications={notificationDetails && notificationDetails.notifications}
                                            viewDetailNotification={this.viewDetailNotification}
                                            unreadNotifications={notificationDetails && notificationDetails.unreadNotifications}
                                        />
                                        {/*<li className="botmList">*/}
                                        {/*<span><a href="" className="">View All Notifications <i*/}
                                        {/*className="fas fa-chevron-right"></i></a></span>*/}
                                        {/*</li>*/}
                                    </ul> : null}
                            </li>
                            <li className="nav-item dropdown profileImg">
                                <a
                                    className="nav-link dropdown-toggle d-flex flex-row align-items-center"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {(profileDetail.profileDetail && profileDetail.type && profileDetail.type == 'admin') ?
                                        profileDetail.profileDetail.admin.profilePic.length && profileDetail.profileDetail.admin.profilePic[0].path ?
                                            <>
                                                <div className="imgProf">
                                                    <img
                                                        src={MAIN_ROUTE + profileDetail.profileDetail.admin.profilePic[0].path} />
                                                </div>
                                            </>
                                            :
                                            <div className="profileNameLetter">
                                                {profileDetail.profileDetail.admin ?
                                                    profileDetail.profileDetail.admin.firstName.charAt(0).toUpperCase() + profileDetail.profileDetail.admin.lastName.charAt(0).toUpperCase()
                                                    : ''}
                                            </div>

                                        :
                                        <div className="profileNameLetter">
                                            {profileDetail.profileDetail && profileDetail.profileDetail.tenant && profileDetail.profileDetail.tenant.firstName.charAt(0).toUpperCase() +
                                                profileDetail.profileDetail.tenant.lastName && profileDetail.profileDetail.tenant.lastName.charAt(0).toUpperCase()}
                                        </div>

                                    }
                                    {profileDetail.profileDetail && profileDetail.type && profileDetail.type == 'admin' ?
                                        <span className="profName">
                                            {profileDetail.profileDetail.admin.firstName.charAt(0).toUpperCase() +
                                                profileDetail.profileDetail.admin.firstName.slice(1) + " " +
                                                (profileDetail.profileDetail.admin.lastName && profileDetail.profileDetail.admin.lastName.charAt(0).toUpperCase()) +
                                                (profileDetail.profileDetail.admin.lastName && profileDetail.profileDetail.admin.lastName.slice(1))}
                                        </span> :
                                        <span className="profName">
                                            {profileDetail.profileDetail && profileDetail.profileDetail.tenant && profileDetail.profileDetail.tenant.firstName.charAt(0).toUpperCase() +
                                                profileDetail.profileDetail.tenant.firstName.slice(1) + " " +
                                                (profileDetail.profileDetail.tenant.lastName && profileDetail.profileDetail.tenant.lastName.charAt(0).toUpperCase()) +
                                                (profileDetail.profileDetail.tenant.lastName && profileDetail.profileDetail.tenant.lastName.slice(1))}
                                        </span>
                                    }
                                </a>

                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {/*{*/}
                                    {/*    role && ['tenant', 'adminuser', 'maintenance'].includes(role) ?*/}
                                    {/*        <a className="dropdown-item" href="#"*/}
                                    {/*           onClick={() => this.handleProfileView()}>*/}
                                    {/*            <i className="fas fa-user"></i> Profile*/}
                                    {/*        </a> : null}*/}
                                    {
                                        role && ['tenant'].includes(role)
                                            ? <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={() => this.onOpenModal()}
                                            >
                                                <i className="fas fa-cog"></i> Change Password
                                            </a>
                                            : null
                                    }
                                    {
                                        role && ['admin', 'adminuser'].includes(role)
                                            ? <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={() => this.props.history.push(`/Settings`)}
                                            >
                                                <i className="fas fa-cog"></i> Settings
                                            </a>
                                            : null
                                    }
                                    <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => this.logout()}
                                    >
                                        <i className="fas fa-sign-out-alt"></i> Logout
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item menuItemNav" id="menuNav" onClick={()=>this.showMenu()}>
                                <i className="fas fa-align-justify"></i>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}


const
    mapStateToProps = state => {
        const { profileReducer, commonReducer, invoiceReducer } = state;
        return {
            profileReducer,
            commonReducer,
            advanceSearchData: commonReducer.advanceSearchData,
            InvoiceList: invoiceReducer.InvoiceList,
            invoiceById: invoiceReducer.invoiceById,
            sms: invoiceReducer.sms,
            smsInvoice: invoiceReducer.smsInvoice,
            invoicePayment: invoiceReducer.invoicePayment,
            Invoice: invoiceReducer.Invoice,
        };
    };

export default withRouter(connect

    (
        mapStateToProps
        , {
            ...
            action
            , ...
            commonAction
            , ...
            tenantAction
            , ...
            invoiceAction
        }
    )
    (TopHeader)
)
    ;


