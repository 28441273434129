import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import Modal from '../../../Common/components/Modal';
import AddCreditCard from './AddCreditCard';
import CreatePassword from './createPassword';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastr from "../../../Common/components/Toaster";
import LoaderSpinner from "../../../Common/components/Loader";
import usePrevious from "../../../Common/components/prevProps";
import TenantDetailLease from '../TenantDetailLease';
import AddTenants from '../AddTenants';
import ConfirmLease from '../ConfirmLease';
import { formatUsPhone } from "../../../Common/components/utils";
import { MAIN_ROUTE } from "../../../../config/constants";
import ViewNotice from "../Invoice/viewNotice";
import Confirm from "../../../Common/components/Confirm";
import CardList from '../../../Invoice/TenantComponents/cardList'
import history from '../../../../config/history'
import SettingsAction from '../../../Settings/action'

const ClientInformation = (props) => {

    const [creditModal, setCreditModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [submitLease, setSubmitLease] = useState(false);
    const [viewLeaseAgreement, setViewLeaseAgreement] = useState(false);
    const [tenantModal, setTenantModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [viewNoticeClicked, setViewNoticeClicked] = useState(false)
    const [viewNoticeModal, setViewNoticeModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [sendNotice, setSendNotice] = useState(false);
    const [confirmModal, setConfirm] = useState(false);
    const [isAuto, setIsAuto] = useState(false);
    const [changeAuto, setChangeAuto] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteCard, setDeleteCard] = useState(false);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);


    const { tenantReducer: { lease, sendLobMailResponse } } = props;

    const { tenantById: { tenant } } = props;

    const prevLease = usePrevious(lease);
    const prevSendLobMailResponse = usePrevious(sendLobMailResponse)

    useEffect(() => {
        async function renderCall() {
            if (tenant)
                props.getAutoPay({ tenantId: props.id })
        }
        renderCall();

    }, [tenant])

    useEffect(() => {
        if (submitLease && !lease.success) {
            toastr.error(lease.message)
        } else if (submitLease && lease.success && prevLease != lease) {
            toastr.success(lease.message);
        }
    }, [prevLease, lease]);

    useEffect(() => {
        //For deleting card
        if (deleteSubmitted && !props.deleteCards.success) {
            toastr.error(props.deleteCards.message)
        } else if (deleteSubmitted && props.deleteCards.success) {
            toastr.success("Successfully deleted the card");
        }
    }, [props.deleteCards]);


    useEffect(() => {
        if (sendNotice && prevSendLobMailResponse != sendLobMailResponse && !sendLobMailResponse.success) {
            toastr.error(sendLobMailResponse.message)
        } else if (sendNotice && prevSendLobMailResponse != sendLobMailResponse && !sendLobMailResponse.success) {
            toastr.success(sendLobMailResponse.message);
        }
    }, [prevSendLobMailResponse, sendLobMailResponse]);


    //Edit credit card details
    const onOpenCreditModal = (tenant) => {
        // if(tenant.cardDetails && tenant.cardDetails.length && tenant.cardDetails[0].cardNumber){
        //     if(!tenant.cardDetails[0].defaultCard){
        //
        //     }
        //     else{
        //         toastr.error('Primary card cannot be deleted')
        //     }
        // }
        // else{
        setCreditModal(true);

        // }
    }

    const onCloseCreditModal = () => {
        setCreditModal(false);
    }

    const renderCreditModal = () => {
        return <CardList
            onCancel={onCloseCreditModal}
            id={props.id}
            tenatDetailView={true}
            handleDeleteCard={handleDeleteCard}
        />

    }

    const handleDeleteCard = async (cardDetails) => {
        // if (!cardDetails.defaultCard) {
            setDeleteModal(true)
            setDeleteCard(cardDetails)
        // }
        // else {
        //     toastr.error('Primary card cannot be deleted')
        // }
    }


    const closeDeleteConfirm = () => {
        setDeleteModal(false)
        setDeleteCard(null)
    }

    const renderDeleteConfirm = () => {
        return <Confirm title='Delete Card'
            message='Do you want to delete this card?'
            onCancel={closeDeleteConfirm}
            onConfirm={deleteCardDetails} />
    }

    const deleteCardDetails = async () => {
        setDeleteModal(false)
        setDeleteSubmitted(true)
        await props.deleteCard({ tenantId: props.id, cardId: deleteCard._id })
        await props.getTenantId(props.id)
        setDeleteCard(null)
        setDeleteSubmitted(true)
        setDeleteSubmitted(false)


    }

    //credit card modal ends


    //Create Password modal
    const onOpenCreatePasswordModal = () => {
        setPasswordModal(true);
    }

    const onCloseCreatePasswordModal = () => {
        setPasswordModal(false);
    }

    const renderCreatePasswordModal = () => {
        return <CreatePassword
            onCancel={onCloseCreatePasswordModal}
            id={props.id}
        />
    }
    //Password modal ends

    //edit tenant modal
    const onOpenModal = async () => {
        await props.getTenantId(props.id);
        setTenantModal(true);
        setViewLeaseAgreement(false);
        setEditClicked(false);
    }

    const onCloseModal = () => {
        setTenantModal(false);
    }

    const renderModal = () => {
        return <AddTenants
            onCancel={onCloseModal}
            editId={props.id}
            leaseModal={leaseModal}
            tenantDetail={props.tenantById}
        />
    }
    //edit tenant modal ends


    //lease modal after edit for confirmation
    const leaseModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }

    const renderModalLease = () => {
        return <ConfirmLease
            title='Lease Agreement'
            message='Do you want to send lease agreement?'
            close={closeModal}
            onConfirm={leaseAgreement}
            onView={viewAgreement}
        />
    }

    const leaseAgreement = async () => {
        setViewLeaseAgreement(false);
        setSubmitLease(true);
        setModal(false);
        await props.leaseAgreement({ tenantId: props.id });
        setTimeout(() => {
            setSubmitLease(false);
            // setViewLeaseAgreement(true);
        }, 1000);
    }

    const viewAgreement = async () => {
        setSubmitLease(true);
        // setModal(false);
        await props.viewLeaseAgreement({ tenantId: props.id });
        setTimeout(() => {
            setSubmitLease(false);
            setViewLeaseAgreement(true);
        }, 1000);
    }
    //lease modal after edit for confirmation


    //view lease agreement
    const closeViewLeaseModal = () => {
        setViewLeaseAgreement(false);
    }

    const renderViewLeaseModal = () => {
        return <TenantDetailLease
            tenantId={props.id}
            onCancel={closeViewLeaseModal}
            // lease={props.tenantReducer.lease}
            lease={props.tenantReducer.viewLease}
            leaseAgreement={leaseAgreement}
            closeModal={onCloseModal}
            onOpenModal={onOpenModal}
        />
    }
    //view lease agreement ends

    //view notice before sending
    const sendNoticeHtmlView = async () => {
        setViewNoticeClicked(true);
        await props.viewNotice({ tenantId: props.id })
        setViewNoticeModal(true)
        setTimeout(() =>
            setViewNoticeClicked(false),
            100);
    }

    const closeviewNoticeModal = () => {
        setViewNoticeModal(false)
    }

    const renderviewNoticeModal = () => {
        return <ViewNotice
            sendLobMail={sendLobMail}
            onCancel={closeviewNoticeModal}
            notice={props.tenantReducer.viewNotice.template && props.tenantReducer.viewNotice.template}
        />
    }

    const sendLobMail = async () => {
        setIsLoading(true);
        setSendNotice(true)
        const params = { tenantId: props.id }
        setViewNoticeModal(false)
        await props.sendLobMail(params);
        setSendNotice(false)
        setIsLoading(false);
    }

    const changeAutoPay = async (e) => {
        setChangeAuto(true)
        // setIsLoading(true)

        if (tenant.cardDetails && tenant.cardDetails.length) {
            if (e.target.checked) {
                setIsAuto(true)

            }
            else
                setIsAuto(false)
            await props.autoPay(
                {
                    tenantId: props.id,
                    status: e.target.checked ? 1 : 0
                }
            );
            await props.getAutoPay({ tenantId: props.id })
            // setIsLoading(false)

            await props.reloadTenant()
        }
        else {
            toastr.error("Please add your card details");
        }
    } 

    return (
        <div className="outrTabBg">
            {/* <ToastContainer /> */}
            {editClicked && <LoaderSpinner />}
            {(isLoading) && <LoaderSpinner />}
            {creditModal ? <Modal body={renderCreditModal()} onCancel={onCloseCreditModal} /> : null}
            {passwordModal ? <Modal body={renderCreatePasswordModal()} onCancel={onCloseCreatePasswordModal} /> : null}
            {tenantModal && tenant ? <Modal body={renderModal()} onCancel={onCloseModal} /> : null}
            {modal ? <Modal body={renderModalLease()} onCancel={closeModal} /> : null}
            {viewLeaseAgreement ? <Modal body={renderViewLeaseModal()} onCancel={closeViewLeaseModal} /> : null}
            {deleteCard ? <Modal body={renderDeleteConfirm()} onCancel={closeDeleteConfirm} /> : null}

            {viewNoticeModal ? <Modal body={renderviewNoticeModal()} onCancel={closeviewNoticeModal} /> : null}

            {submitLease && <LoaderSpinner />}

            {tenant &&
                <div className="brdOut">
                    <h3 className="clfinfo">
                        <div>Tenant Information</div>
                        {!tenant.isClosed &&
                            <div className="editInfo"
                                onClick={() => {
                                    setEditClicked(true)
                                    onOpenModal()
                                }}><i className="fas fa-pencil-alt"></i> <span>Edit</span></div>}
                    </h3>
                    <div className="clintOtr">
                        {/* <div className="imgCl">
              <img src="/images/profl.jpg" />
            </div> */}
                        <div className="clntDtl">
                            <ul>
                                <li className="col-xl-3">
                                    <label>First Name</label>
                                    <p className="txtNme">{tenant.firstName}</p>
                                </li>
                                <li className="col-xl-2">
                                    <label>Last Name</label>
                                    <p className="txtNme">{tenant.lastName}</p>
                                </li>
                                <li className="col-xl-4">
                                    <label>Email</label>
                                    <p className="txtNme">{tenant.email}</p>
                                </li>
                                <li className="col-xl-3">
                                    <label>Phone</label>
                                    <p className="txtNme">{formatUsPhone(tenant.phoneNumber)}</p>
                                </li>
                                <li className="col-xl-3 bd-lf" style={{ "cursor": "pointer" }} onClick={() => history.push(`/PropertyDetails/${tenant.propertyId && tenant.propertyId._id}`)}>
                                    <label>Address</label>
                                    <p className="txtNme proAddress" >{tenant.propertyId && tenant.propertyId.propertyName}</p>
                                </li>

                                <li className="col-xl-3">
                                    <label>Name on Card</label>
                                    <p className="txtNme ">{tenant.cardDetails && tenant.cardDetails.length ? tenant.cardDetails.find(tc => tc.defaultCard) &&
                                        tenant.cardDetails.find(tc => tc.defaultCard).cardName : "N/A"}</p>
                                </li>
                                <li className="col-xl-3">
                                    <label>Card Number</label>
                                    <p className="txtNme">{tenant.cardDetails && tenant.cardDetails.length ? tenant.cardDetails.find(tc => tc.defaultCard) &&

                                        "xxxx xxxx xxxx " + tenant.cardDetails.find(tc => tc.defaultCard).cardNumber.substring(12, 16) : "N/A"}</p>
                                </li>
                                <li className="col-xl-3">
                                    <label>Expiry</label>
                                    <p className="txtNme">{tenant.cardDetails && tenant.cardDetails.length ? tenant.cardDetails.find(tc => tc.defaultCard) && tenant.cardDetails.find(tc => tc.defaultCard).expiryDate : "N/A"}</p>
                                </li>
                            </ul>

                            <div className="text-right rightBtn">
                                <div className="priorityOuter">
                                    <div className="auto-pay-wrapper">
                                        <div class="outr-priorty">
                                            <label class="labl-txt">Autoprocessing</label>
                                            <div className="switchToggle">
                                                <input type="checkbox" id="switch"
                                                    onChange={(e) => changeAutoPay(e)}
                                                    checked={changeAuto ? isAuto : props.settingsReducer.autoPay.success} />
                                                <label for="switch">Toggle</label>
                                            </div>
                                        </div>
                                    </div></div>
                                <button type="button" className="btn btnCard"
                                    onClick={() => sendNoticeHtmlView()}
                                    disabled={tenant.isClosed}
                                >
                                    <i className="fas fa-envelope"></i>
                                    <span className="btnTxt">Send Notice</span>
                                </button>
                                <button type="button" className="btn btnCard mr-1"
                                    disabled={!tenant.criminalReportPath || tenant.isClosed} type="button"
                                    onClick={() => tenant.criminalReportPath && window.open(MAIN_ROUTE + tenant.criminalReportPath, '_blank')}>
                                    <img src="/images/card.png" />
                                    <span className="btnTxt">Criminal Case Report</span>
                                </button>
                                <button type="button" className="btn btnCard mr-1"
                                    disabled={!tenant.creditReportPath || tenant.isClosed} type="button"
                                    onClick={() => tenant.creditReportPath && window.open(MAIN_ROUTE + tenant.creditReportPath, '_blank')}>
                                    <img src="/images/card.png" />
                                    <span className="btnTxt">Credit Report</span>
                                </button>
                                <button type="button" className="btn btnCard mr-1"
                                    disabled={tenant.isClosed}
                                    onClick={() => onOpenCreditModal(tenant)}>
                                    <img src="/images/card.png" />
                                    <span className="btnTxt">
                                        {tenant.cardDetails && tenant.cardDetails.length && tenant.cardDetails[0].cardNumber ? "Update Credit Card" : "Add Card Detail"}


                                    </span>
                                </button>
                                <button type="button" className="btn btnCard"
                                    disabled={tenant.isClosed}

                                    onClick={() => onOpenCreatePasswordModal()}>
                                    <img src="/images/lock.png" />
                                    <span className="btnTxt">Create Password</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer, settingsReducer } = state;
    return {
        tenantReducer,
        commonReducer,
        settingsReducer,
        deleteCards: settingsReducer.deleteCard

    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...SettingsAction
})(ClientInformation);
