import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AsyncSelect from 'react-select/async';
import maintenanceAction from '../../Maintenance/action'
import commonAction from '../../Common/action'
import * as validation from '../../Common/components/validation';
import action from '../action';
import LoaderSpinner from "../../Common/components/Loader";
import { MAIN_ROUTE } from "../../../config/constants";
import NumberFormat from 'react-number-format';
import moment from 'moment';


const AddExpense = (props) => {
    const [expenseForm, setexpenseForm] = useState({
        propertyId: null,
        // unitId: null,
        amount: null,
        // businessId: null,
        // merchantId: null,
        maintenanceCategoryId: null,
        expenseFile: [],
        description: null,
        paymentId: null,
        expenseCreatedDate: moment().endOf('day').toISOString(),
    })
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [attachmentChanged, setAttachmentChanged] = useState(false)
    const [uploadError, setError] = useState("")
    const [uploadattachmentHeader, setUploadattachmentHeader] = useState("")


    useEffect(() => {
        setexpenseForm(props.expenseDetails)
        async function renderCall() {
            await props.getUnitsByPropertyId(props.expenseDetails.propertyId)
            await props.getProperty()
            // await props.getExpenseCategory()
            await props.getCategoryList({ level: 1 })
            await props.getBusiness()
            await props.getMerchant()
            await props.getPayment()
        }

        renderCall();
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }, []);

    const handleChange = async (e) => {
        if (e.target.type === "file") {
            const { name, files } = e.target
            setexpenseForm({
                ...expenseForm,
                [name]: files[0]
            })
        } else if (e.target.name === "amount") {
            const { name, value } = e.target
            setexpenseForm({
                ...expenseForm,
                [name]: value.replace("$", "").replace(/,/g, '')
            })
        } else {
            const { name, value } = e.target
            if (name === "propertyId") {
                await props.getUnitsByPropertyId(value)
            }
            setexpenseForm({
                ...expenseForm,
                [name]: value
            })
        }
    }

    const addExpense = async () => {
        setIsSubmitted(true);
        if (expenseForm.propertyId &&
            expenseForm.unitId &&
            expenseForm.amount &&
            expenseForm.paymentId &&
            expenseForm.maintenanceCategoryId &&
            expenseForm.description &&
            validation.NUMBER_ONLY(expenseForm.amount)) {
            let data = expenseForm
            data.expenseCreatedDate = moment().endOf('day').toISOString();
            await props.addExpenseDetails(data)
        }
    }

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        <div className={"errorMsg"}>
                            {!validation.NUMBER_ONLY(value)
                                ? "* Only numbers are allowed"
                                : null}
                        </div>
                    );
            }
        } else return null;
    };

    const isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };

    const handleChangeFile = (e) => {
        if (isIterable(e.target.files)) {
            if (e.target.files.length) {
                setUploadattachmentHeader('Add')
            }
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const acceptableExt = ["pdf", "docx", "doc", "txt", "png", "jpg", "ttf", "avi", "flv", "wmv", "mov", "pdf", "doc", "docx"];
                if (acceptableExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        setAttachmentChanged(true)
                        // console.log("e.target.files",expenseForm.expenseFile)
                        let attachmentTemp = [...expenseForm.expenseFile, ...e.target.files];
                        setexpenseForm({ ...expenseForm, expenseFile: attachmentTemp })
                        // uploadAttachment: [...this.state.uploadAttachment, ...e.target.files]
                    } else {
                        setError('File is too big. Files with size greater than 25MB is not allowed.')
                    }
                } else {
                    setAttachmentChanged(false)
                    setError('* Accept only image / video / emoji / documents !!!')
                }
            })
        }
    }

    const deleteAttachment = (index) => {
        let fileArray = expenseForm.expenseFile
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            setAttachmentChanged(false)
            setexpenseForm({ ...expenseForm, expenseFile: [] })
            setUploadattachmentHeader('Upload')
        }
        setexpenseForm({ ...expenseForm, expenseFile: fileArray })
        // document.getElementById('attachmentFiles').value = null
    };

    const deleteAttachmentEdit = (index, attachment) => {

        let fileArray = expenseForm.attachment
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            setAttachmentChanged(false)
            setexpenseForm({ ...expenseForm, attachment: [] })
            setUploadattachmentHeader('Upload')
        }
        setexpenseForm({ ...expenseForm, attachment: fileArray })
        // document.getElementById('attachmentFiles').value = null
    };

    const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));

    const promiseOptionsProperty = async val => {
        await props.getProperty({ name: val })
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const handleChangeProperty = async (value) => {
        await props.getUnitsByPropertyId(value.value);
        let propertyOwner = props.commonReducer.Property.success ? props.commonReducer.Property.propertyList.find(p => (p._id === value.value)) : null;
        setexpenseForm({
            ...expenseForm,
            propertyId: value.value,
            propertyDetail: value,
            paymentId: propertyOwner && propertyOwner.propertyOwnerName[0] ? propertyOwner.propertyOwnerName[0]._id : " ",
            propertyOwnerName: propertyOwner && propertyOwner.propertyOwnerName[0] ? propertyOwner.propertyOwnerName[0].entityName : " ",
        })
    }

    return (
        <div className="modal addTenant small-modal" id="modalId" style={{ display: "block" }}>
            {isLoading && <LoaderSpinner />}
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {!expenseForm.isView ? expenseForm.isEdit ?
                                "Update Expenses" :
                                "Add Expenses" : "View Expenses"}
                        </h5>
                        <button type="button" className="close" onClick={props.onCancel}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <div className="formSection">
                                <div className="row m-0 p-0">
                                    <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 formInp">
                                        <label>Property</label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions={Properties}
                                            placeholder={"All Properties"}
                                            isDisabled={expenseForm.isView}
                                            loadOptions={promiseOptionsProperty}
                                            name="tenantId"
                                            onChange={handleChangeProperty}
                                            value={expenseForm.propertyDetail}

                                        />
                                        {validate(expenseForm.propertyId, "Property", "required")}
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-md-6 formInp">
                                        <label>Unit</label>
                                        <div className="selctOtr">
                                            <select className="form-control" name={"unitId"}
                                                disabled={expenseForm.isView}
                                                value={expenseForm.unitId ? expenseForm.unitId : null}
                                                onChange={handleChange}>
                                                <option>Select Unit</option>
                                                {props.commonReducer.unitDetails && props.commonReducer.unitDetails.unitList && props.commonReducer.unitDetails.unitList.length && props.commonReducer.unitDetails.unitList.map((unit, key) => {
                                                    return <option value={unit._id}
                                                        key={key}
                                                        selected={expenseForm.unitId}>{unit.unitName}</option>
                                                })}
                                            </select>
                                            {validate(expenseForm.unitId, "Unit", "required")}
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 formInp">
                                        <label>Amount</label>
                                        <NumberFormat
                                            disabled={expenseForm.isView}
                                            prefix={'$'}
                                            className="form-control"
                                            name="amount"
                                            onChange={handleChange}
                                            value={expenseForm.amount}
                                        />
                                        {validate(expenseForm.amount, "Amount", "required")}
                                        {expenseForm.amount && validate(expenseForm.amount, "Amount", "isNumber")}
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 formInp">
                                        <label>Property Owner</label>
                                        <input className="form-control" type="text" placeholder="PropertyOwner"
                                            disabled={true}
                                            name={"paymentId"}
                                            // value={propertyDetails.length ? propertyDetails[0].propertyOwnerName[0].propertyOwnerName:null}
                                            value={expenseForm.propertyOwnerName}
                                        />
                                        {validate(expenseForm.paymentId, "Property owner", "required")}
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 formInp">
                                        <label>Category</label>
                                        <div className="selctOtr">
                                            <select className="form-control" name={"maintenanceCategoryId"}
                                                disabled={expenseForm.isView}
                                                value={expenseForm.maintenanceCategoryId}
                                                onChange={handleChange}>
                                                <option>Select category</option>
                                                {props.categoryMaintenance.category && props.categoryMaintenance.category.length ?
                                                    props.categoryMaintenance.category.map((category, key) => {
                                                        return <option value={category._id}>{category.name}</option>
                                                    }) : null}
                                            </select>
                                            {validate(expenseForm.maintenanceCategoryId, "Category", "required")}
                                        </div>
                                    </div>
                                    <div className="col-xl-12 formInp">
                                        <label>Description</label>
                                        <textarea className="form-control" placeholder="Enter description"
                                            disabled={expenseForm.isView}
                                            id=""
                                            value={expenseForm.description}
                                            name={"description"} cols="30" rows="10"
                                            onChange={handleChange}>{expenseForm.description}</textarea>
                                        {validate(expenseForm.description, "Description", "required")}
                                    </div>
                                    <div className="col-md-12 formInp attachFile">
                                        <div className="col-md-5">
                                            <label className="custom-file-label" htmlFor="inputGroupFile01"><img
                                                src="/images/Attach.png" alt="" /> <span>Attach a file</span>
                                                <input type="file" id="inputGroupFile01" name={"expenseFile"}
                                                    onChange={handleChangeFile}
                                                    multiple={true}
                                                    disabled={expenseForm.isView}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {((expenseForm.attachment && expenseForm.attachment.length) || (expenseForm.expenseFile && expenseForm.expenseFile.length)) ?
                                        <div className="col-md-12 attachfileOuter">
                                            {expenseForm.attachment && expenseForm.attachment.length ? expenseForm.attachment.map((attachment, i) => {
                                                return (
                                                    <div
                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                        key={i}>
                                                        <div className="col-md-12 attachFileList">
                                                            {(attachment.title.split('.').pop() === "jpg" || attachment.title.split('.').pop() === "png")
                                                                ? <img
                                                                    src={MAIN_ROUTE + attachment.path}
                                                                    alt="" />
                                                                : (attachment.title.split('.').pop() === "pdf")
                                                                    ?
                                                                    <i className="far fa-file-pdf"></i>
                                                                    : (attachment.title.split('.').pop() === "doc" || attachment.title.split('.').pop() === "docx")
                                                                        ?
                                                                        <i className="far fa-file-alt"></i>
                                                                        : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.title.split('.').pop())) &&
                                                                        <i className="far fa-file-video"></i>
                                                            }
                                                            {!expenseForm.isView ? <button type="button"
                                                                className="image-close"
                                                                onClick={() => deleteAttachmentEdit(i, attachment)}>
                                                                <span aria-hidden="true">×</span>
                                                            </button> : null}
                                                        </div>
                                                        <p>{attachment.title}</p>
                                                    </div>
                                                )
                                            }) : null}
                                            {expenseForm.expenseFile && expenseForm.expenseFile.length ? expenseForm.expenseFile.map((attachment, i) => {
                                                return (
                                                    <div
                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                        key={i}>
                                                        <div className="col-md-12 attachFileList">
                                                            {(attachment.name.split('.').pop() === "jpg" || attachment.name.split('.').pop() === "png")
                                                                ? <img
                                                                    src={URL.createObjectURL(attachment)}
                                                                    alt="" />
                                                                : (attachment.name.split('.').pop() === "pdf")
                                                                    ?
                                                                    <i className="far fa-file-pdf"></i>
                                                                    : (attachment.name.split('.').pop() === "doc" || attachment.name.split('.').pop() === "docx")
                                                                        ?
                                                                        <i className="far fa-file-alt"></i>
                                                                        : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.name.split('.').pop())) &&
                                                                        <i className="far fa-file-video"></i>
                                                            }
                                                            {!expenseForm.isView ? <button type="button"
                                                                className="image-close"
                                                                onClick={() => deleteAttachment(i)}>
                                                                <span aria-hidden="true">×</span>
                                                            </button> : null}
                                                        </div>
                                                        <p>{attachment.name}</p>
                                                    </div>
                                                )
                                            }) : null
                                            }
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">

                        {!expenseForm.isView ? expenseForm.isEdit ?
                            <button type="button" class="btn btn-primary btnSubmit"
                                onClick={addExpense}>Update</button> :
                            <button type="button" className="btn btn-primary btnSubmit"
                                onClick={addExpense}>Submit</button> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    const { expenseReducer, commonReducer, maintenanceReducer } = state;
    return {
        commonReducer,
        expenseReducer,
        categoryMaintenance: maintenanceReducer.categoryMaintenance,
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction, ...maintenanceAction })(
    AddExpense
);
