//add report
export const ADD_REPORT_REQUEST = 'ADD_REPORT_REQUEST ';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS ';
export const ADD_REPORT_FAILURE = 'ADD_REPORT_FAILURE ';

//get report
export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST ';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS ';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE ';

//DELETE report
export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST ';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS ';
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE ';

//get report
export const INVITE_SCREENING_REQUEST = 'INVITE_SCREENING_REQUEST ';
export const INVITE_SCREENING_SUCCESS = 'INVITE_SCREENING_SUCCESS ';
export const INVITE_SCREENING_FAILURE = 'INVITE_SCREENING_FAILURE ';