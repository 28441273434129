import React, {useState, useEffect} from "react";
import {numberWithCommas} from '../../../Common/components/utils'

const UnitDetails = (props) => {

    const squareFeetFormat = (x) => {
        if (x == null || x === undefined) return '0'
        let num = x.toString()

        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num
    }

    return (
        <div className="unitBox">
            <div className="box">
                <div className="centerContent">
                    <div className="unitImg">
                        <img src="/images/Homee.png"/>
                    </div>
                    <div className="unitcontent">
                        <p>Square Feet</p>
                        <p>{props.unitDetails ? squareFeetFormat(props.unitDetails.squareFeet) : 0}</p>
                    </div>
                </div>
            </div>
            <div className="box">
                <div className="centerContent">
                    <div className="unitImg">
                        <img src="/images/Bed.png"/>
                    </div>
                    <div className="unitcontent">
                        <p>Bed</p>
                        <p className="text-center">{props.unitDetails ? props.unitDetails.bedroomCount : 0}</p>
                    </div>
                </div>
            </div>
            <div className="box">
                <div className="centerContent">
                    <div className="unitImg">
                        <img src="/images/Bath.png"/>
                    </div>
                    <div className="unitcontent">
                        <p>Bath</p>
                        <p className="text-center">{props.unitDetails ? props.unitDetails.bathroomCount : 0}</p>
                    </div>
                </div>
            </div>
            <div className="box">
                <div className="centerContent">
                    <div className="unitImg">
                        <img src="/images/Monthly-Rent.png"/>
                    </div>
                    <div className="unitcontent">
                        <p>Monthly Rent</p>
                        <p>${props.unitDetails ?  numberWithCommas(props.unitDetails.monthlyRent) : 0}</p>
                    </div>
                </div>
            </div>
            <div className="box">
                <div className="centerContent">
                    <div className="unitImg">
                        <img src="/images/Deposit.png"/>
                    </div>
                    <div className="unitcontent">
                        <p>Deposit</p>
                        <p>${props.unitDetails ? numberWithCommas(props.unitDetails.deposit) : 0}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default UnitDetails
