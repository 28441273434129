import * as actionTypes from "./constants";
import * as Service from "./services";
import history from "../../config/history";

const signIn = param => {
    const request = loginAction => ({
        type: actionTypes.LOGIN_REQUEST,
        loginAction
    });
    const success = loginAction => ({
        type: actionTypes.LOGIN_SUCCESS,
        loginAction
    });
    const failure = error => ({
        type: actionTypes.LOGIN_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.SignIn(param);
            if (res.status === 200) {
                const userDetails = res.data;
                if (userDetails.success) {
                    localStorage.setItem("token", userDetails.data.token);
                    localStorage.setItem("userDetails", JSON.stringify(userDetails));
                    await dispatch(success(userDetails));
                    if(userDetails.type==="admin"){
                        history.push("/home");
                    }
                    else {history.push("/home")}
                } else {
                    await dispatch(failure(userDetails));
                }
            } else {
               await dispatch(failure(res.response.data));
            }
        } catch (e) {
           await dispatch(failure(e.response && e.response.data));

        }
    };
};

const passwordSet = (param,email) => {
    const request = loginAction => ({
        type: actionTypes.PASSWORD_CHANGE_REQUEST,
        loginAction
    });
    const success = loginAction => ({
        type: actionTypes.PASSWORD_CHANGE_SUCCESS,
        loginAction
    });
    const failure = error => ({
        type: actionTypes.PASSWORD_CHANGE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.passwordSet(param);
            if (res.status === 200) {
                const userDetails = res.data;
                if (userDetails.success) {
                    // localStorage.setItem("token", userDetails.data.token);
                    // localStorage.setItem("userDetails", JSON.stringify(userDetails.data));
                    await dispatch(success(userDetails));
                    let loginData={
                        password:param.password,
                        email:email
                    }
                    signIn(loginData)
                    // history.push("/");
                } else {
                    await dispatch(failure(userDetails));
                }
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const validateToken = param => {
    const request = passwordAction => ({
        type: actionTypes.VALIDATE_KEY_REQUEST,
        passwordAction
    });
    const success = passwordAction => ({
        type: actionTypes.VALIDATE_KEY_SUCCESS,
        passwordAction
    });
    const failure = error => ({
        type: actionTypes.VALIDATE_KEY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.validateToken(param);
            if (res.status === 200) {
                const userDetails = res.data;
                if (userDetails.success) {
                    await dispatch(success(userDetails));

                } else {
                    await dispatch(failure(userDetails));
                    // history.push("/login");
                }
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

const forgotPassword = value => {
    const request = passwordAction => ({
        type: actionTypes.PASSWORD_FORGOT_REQUEST,
        passwordAction
    });
    const success = passwordAction => ({
        type: actionTypes.PASSWORD_FORGOT_SUCCESS,
        passwordAction
    });
    const failure = error => ({
        type: actionTypes.PASSWORD_FORGOT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.forgotPassword(value);
            if (res.status === 200) {
                const userDetails = res.data;
                if (userDetails.success) {
                    await dispatch(success(userDetails));

                } else {
                    await dispatch(failure(userDetails));
                }
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

export default {
    signIn,
    passwordSet,
    validateToken,
    forgotPassword
};
