//UPDATE BOARD DATA
export const UPDATE_BOARD_DATA_REQUEST = "UPDATE_BOARD_DATA_REQUEST";
export const UPDATE_BOARD_DATA_SUCCESS = "UPDATE_BOARD_DATA_SUCCESS";
export const UPDATE_BOARD_DATA_FAILURE = "UPDATE_BOARD_DATA_FAILURE";

//GET BOARD DATA
export const GET_BOARD_DATA_REQUEST = "GET_BOARD_DATA_REQUEST";
export const GET_BOARD_DATA_SUCCESS = "GET_BOARD_DATA_SUCCESS";
export const GET_BOARD_DATA_FAILURE = "GET_BOARD_DATA_FAILURE";

//GET BOARD DATA
export const GET_BOARD_LABELS_REQUEST = "GET_BOARD_LABELS_REQUEST";
export const GET_BOARD_LABELS_SUCCESS = "GET_BOARD_LABELS_SUCCESS";
export const GET_BOARD_LABELS_FAILURE = "GET_BOARD_LABELS_FAILURE";

//ADD BOARD DATA
export const ADD_BOARD_LABELS_REQUEST = "ADD_BOARD_LABELS_REQUEST";
export const ADD_BOARD_LABELS_SUCCESS = "ADD_BOARD_LABELS_SUCCESS";
export const ADD_BOARD_LABELS_FAILURE = "ADD_BOARD_LABELS_FAILURE";

//UPDATE BOARD DATA
export const UPDATE_BOARD_LABELS_REQUEST = "UPDATE_BOARD_LABELS_REQUEST";
export const UPDATE_BOARD_LABELS_SUCCESS = "UPDATE_BOARD_LABELS_SUCCESS";
export const UPDATE_BOARD_LABELS_FAILURE = "UPDATE_BOARD_LABELS_FAILURE";

//DELETE BOARD DATA
export const DELETE_BOARD_LABELS_REQUEST = "DELETE_BOARD_LABELS_REQUEST";
export const DELETE_BOARD_LABELS_SUCCESS = "DELETE_BOARD_LABELS_SUCCESS";
export const DELETE_BOARD_LABELS_FAILURE = "DELETE_BOARD_LABELS_FAILURE";

//GET TENANTS
export const GET_TENANTS_REQUEST = "GET_TENANTS_REQUEST";
export const GET_TENANTS_SUCCESS = "GET_TENANTS_SUCCESS";
export const GET_TENANTS_FAILURE = "GET_TENANTS_FAILURE";

//GET CARD ACTIVITY
export const GET_CARD_ACTIVITY_REQUEST = "GET_CARD_ACTIVITY_REQUEST";
export const GET_CARD_ACTIVITY_SUCCESS = "GET_CARD_ACTIVITY_SUCCESS";
export const GET_CARD_ACTIVITY_FAILURE = "GET_CARD_ACTIVITY_FAILURE";

//ADD CARD ACTIVITY
export const ADD_CARD_ACTIVITY_REQUEST = "ADD_CARD_ACTIVITY_REQUEST";
export const ADD_CARD_ACTIVITY_SUCCESS = "ADD_CARD_ACTIVITY_SUCCESS";
export const ADD_CARD_ACTIVITY_FAILURE = "ADD_CARD_ACTIVITY_FAILURE";

//GET CARD ATTACHMENTS
export const GET_CARD_ATTACHMENTS_REQUEST = "GET_CARD_ATTACHMENTS_REQUEST";
export const GET_CARD_ATTACHMENTS_SUCCESS = "GET_CARD_ATTACHMENTS_SUCCESS";
export const GET_CARD_ATTACHMENTS_FAILURE = "GET_CARD_ATTACHMENTS_FAILURE";

//ADD CARD ATTACHMENTS
export const ADD_CARD_ATTACHMENTS_REQUEST = "ADD_CARD_ATTACHMENTS_REQUEST";
export const ADD_CARD_ATTACHMENTS_SUCCESS = "ADD_CARD_ATTACHMENTS_SUCCESS";
export const ADD_CARD_ATTACHMENTS_FAILURE = "ADD_CARD_ATTACHMENTS_FAILURE";

//DELETE CARD ATTACHMENTS
export const DELETE_CARD_ATTACHMENTS_REQUEST = "DELETE_CARD_ATTACHMENTS_REQUEST";
export const DELETE_CARD_ATTACHMENTS_SUCCESS = "DELETE_CARD_ATTACHMENTS_SUCCESS";
export const DELETE_CARD_ATTACHMENTS_FAILURE = "DELETE_CARD_ATTACHMENTS_FAILURE";

//ADD CUSTOM FIELDS
export const ADD_CUSTOM_FIELD_REQUEST = "ADD_CUSTOM_FIELD_REQUEST";
export const ADD_CUSTOM_FIELD_SUCCESS = "ADD_CUSTOM_FIELD_SUCCESS";
export const ADD_CUSTOM_FIELD_FAILURE = "ADD_CUSTOM_FIELD_FAILURE";

//GET CUSTOM FIELDS
export const GET_CUSTOM_FIELDS_REQUEST = "GET_CUSTOM_FIELDS_REQUEST";
export const GET_CUSTOM_FIELDS_SUCCESS = "GET_CUSTOM_FIELDS_SUCCESS";
export const GET_CUSTOM_FIELDS_FAILURE = "GET_CUSTOM_FIELDS_FAILURE";

//UPDATE CUSTOM FIELDS
export const UPDATE_CUSTOM_FIELDS_REQUEST = "UPDATE_CUSTOM_FIELDS_REQUEST";
export const UPDATE_CUSTOM_FIELDS_SUCCESS = "UPDATE_CUSTOM_FIELDS_SUCCESS";
export const UPDATE_CUSTOM_FIELDS_FAILURE = "UPDATE_CUSTOM_FIELDS_FAILURE";

//DELETE CUSTOM FIELDS
export const DELETE_CUSTOM_FIELDS_REQUEST = "DELETE_CUSTOM_FIELDS_REQUEST";
export const DELETE_CUSTOM_FIELDS_SUCCESS = "DELETE_CUSTOM_FIELDS_SUCCESS";
export const DELETE_CUSTOM_FIELDS_FAILURE = "DELETE_CUSTOM_FIELDS_FAILURE";
