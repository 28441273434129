import React, { useState, useEffect } from "react";

const ErrorPage = (props) => {
    return (
        <div class="try-again">
            <div class="container otr-dtl-section">
                <div class="img-try">
                    <img src="/images/img-try.png" />
                </div>
                <div class="img-txt">
                    <h3>TRY AGAIN LATER</h3>
                    <h4>
                        Sorry,Something went wrong
                        Please try again later
               </h4>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;