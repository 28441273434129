import * as actionType from "./constants";
import React from "react";

const initialState = {
    Property: {},
    Unit: [],
    propertyTypeList: [],
    amenityList: [],
    stateList: [],
    propertyFeaturelist: [],
    categoryList: [],
    businessList: [],
    merchantList: [],
    InvoiceTypeList: [],
    paymentMethods: [],
    unitDetails: {},
    paymentList: {},
    unitDetailsByUnitId: {},
    revenueDetails: {},
    expenseGraph: {},
    propertyDetails: {},
    messageDetailsByTenant: {},
    propertyDetailsByTenant: {},
    paidInvoiceDetailsByTenant: {},
    unPaidInvoiceDetailsByTenant: {},
    maintenanceDetailsByTenant: {},
    notificationDetails: {},
    emailCheck:{}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_PROPERTY_REQUEST:
            return {
                ...state
            };
        case actionType.GET_PROPERTY_SUCCESS:
            return {
                ...state,
                Property: {
                    success: true,
                    propertyList: action.data.propertyLists,
                    totalPropertyCount: action.data.totalPropertyListCount,
                    totalCount: action.data.totalProperty,
                    activeUnitCount: action.data.activeUnitCount,
                    inactiveUnitCount: action.data.inactiveUnitCount,
                    totalunitListCount: action.data.totalunitListCount
                    // totalPropertyListCount:action.data.propCount
                },
            };
        case actionType.GET_EACH_PROPERTY_FAILURE:
            return {
                ...state,
                Property: { success: false, ...action.error }
            };

        case actionType.GET_EACH_PROPERTY_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EACH_PROPERTY_SUCCESS:
            return {
                ...state,
                propertyDetails: action.data.properties
            };
        case actionType.GET_PROPERTY_FAILURE:
            return {
                ...state,
                propertyDetails: { success: false, ...action.error }
            };

        case actionType.GET_UNIT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_UNIT_SUCCESS:
            return {
                ...state,
                unitDetails: { ...action.data.data }
            };
        case actionType.GET_UNIT_FAILURE:
            return {
                ...state,
                unitDetails: { success: false, ...action.error }
            };

        case actionType.GET_ALL_UNIT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_ALL_UNIT_SUCCESS:
            return {
                ...state,
                unitDetails: { ...action.data.data }
            };
        case actionType.GET_ALL_UNIT_FAILURE:
            return {
                ...state,
                untDetails: { success: false, ...action.error }
            };

        case actionType.GET_TYPES_REQUEST:
            return {
                ...state
            };
        case actionType.GET_TYPES_SUCCESS:
            return {
                ...state,
                propertyTypeList: action.data.data.propertyTypeList,
            };
        case actionType.GET_TYPES_FAILURE:
            return {
                ...state,
                propertyTypeList: { success: false, ...action.error }
            };

        case actionType.GET_FEATHURES_REQUEST:
            return {
                ...state
            };
        case actionType.GET_FEATHURES_SUCCESS:
            return {
                ...state,
                propertyFeaturelist: action.data.data.propertyFeaturelist,
            };
        case actionType.GET_FEATHURES_FAILURE:
            return {
                ...state,
                propertyFeaturelist: { success: false, ...action.error }
            };
        case actionType.GET_AMINITY_REQUEST:
            return {
                ...state
            };
        case actionType.GET_AMINITY_SUCCESS:
            return {
                ...state,
                amenityList: action.data.data.amenityList,
            };
        case actionType.GET_AMINITY_FAILURE:
            return {
                ...state,
                amenityList: { success: false, ...action.error }
            };
        case actionType.GET_STATE_REQUEST:
            return {
                ...state
            };
        case actionType.GET_STATE_SUCCESS:
            return {
                ...state,
                stateList: action.data.data.stateList,
            };
        case actionType.GET_STATE_FAILURE:
            return {
                ...state,
                stateList: { success: false, ...action.error }
            };

        case actionType.GET_EXPENSECATEGORY_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPENSECATEGORY_SUCCESS:
            return {
                ...state,
                categoryList: action.data.data.categoryList,
            };
        case actionType.GET_EXPENSECATEGORY_FAILURE:
            return {
                ...state,
                categoryList: { success: false, ...action.error }
            };

        case actionType.GET_MERCHANT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MERCHANT_SUCCESS:
            return {
                ...state,
                merchantList: action.data.data.merchantList,
            };
        case actionType.GET_MERCHANT_FAILURE:
            return {
                ...state,
                merchantList: { success: false, ...action.error }
            };
        case actionType.GET_BUSINESS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_BUSINESS_SUCCESS:
            return {
                ...state,
                businessList: action.data.data.businessList,
            };
        case actionType.GET_BUSINESS_FAILURE:
            return {
                ...state,
                businessList: { success: false, ...action.error }
            };
        case actionType.GET_INVOICETYPE_REQUEST:
            return {
                ...state
            };
        case actionType.GET_INVOICETYPE_SUCCESS:
            return {
                ...state,
                InvoiceTypeList: action.data.data.InvoiceTypeList,
            };
        case actionType.GET_INVOICETYPE_FAILURE:
            return {
                ...state,
                InvoiceTypeList: { success: false, ...action.error }
            };


        case actionType.GET_UNIT_DETAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_UNIT_DETAILS_SUCCESS:
            return {
                ...state,
                unitDetailsByUnitId: action.data.data.unitlist,
            };
        case actionType.GET_UNIT_DETAILS_FAILURE:
            return {
                ...state,
                unitDetailsByUnitId: { success: false, ...action.error }
            };

        case actionType.GET_PAYMENT_METHOD_REQUEST:
            return {
                ...state
            };
        case actionType.GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: action.data.data.PaymentTypeList,
            };
        case actionType.GET_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                paymentMethods: { success: false, ...action.error }
            };

        case actionType.GET_REVENUE_REQUEST:
            return {
                ...state
            };
        case actionType.GET_REVENUE_SUCCESS:
            return {
                ...state,
                revenueDetails: action.data.data,
            };
        case actionType.GET_REVENUE_FAILURE:
            return {
                ...state,
                revenueDetails: { success: false, ...action.error }
            };

        case actionType.GET_PAYMENT_REQUEST:
            return {
                ...state
            };

        case actionType.GET_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentList: {
                    success: true,
                    payment: action.settingsAction.settings,
                    count: action.settingsAction.total
                }
            };

        case actionType.GET_PAYMENT_FAILURE:
            return {
                ...state,
                paymentList: { success: false, ...action.error }
            };


        case actionType.GET_EXPENSE_GRAPH_DETAILS_REQUEST:
            return {
                ...state
            };

        case actionType.GET_EXPENSE_GRAPH_DETAILS_SUCCESS:

            return {
                ...state,
                expenseGraph: {
                    success: true,
                    graphPlots: action.expenseDetails.graphPlots,
                }
            };

        case actionType.GET_EXPENSE_GRAPH_DETAILS_FAILURE:
            return {
                ...state,
                expenseGraph: { success: false, ...action.error }
            };

        case actionType.GET_PROPERTY_DETAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_PROPERTY_DETAILS_SUCCESS:
            return {
                ...state,
                propertyDetailsByTenant: action.responseDetails,
            };
        case actionType.GET_PROPERTY_DETAILS_FAILURE:
            return {
                ...state,
                propertyDetailsByTenant: { success: false, ...action.error }
            };
        case actionType.GET_PAID_INVOICE_DETAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_PAID_INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                paidInvoiceDetailsByTenant: action.responseDetails,
            };
        case actionType.GET_PAID_INVOICE_DETAILS_FAILURE:
            return {
                ...state,
                paidInvoiceDetailsByTenant: { success: false, ...action.error }
            };


        case actionType.GET_UNPAID_INVOICE_DETAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_UNPAID_INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                unPaidInvoiceDetailsByTenant: action.responseDetails,
            };
        case actionType.GET_UNPAID_INVOICE_DETAILS_FAILURE:
            return {
                ...state,
                unPaidInvoiceDetailsByTenant: { success: false, ...action.error }
            };


        case actionType.GET_MAINTENANCE_TENANT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MAINTENANCE_TENANT_SUCCESS:
            return {
                ...state,
                maintenanceDetailsByTenant: { success: true, ...action.responseDetails },
            };
        case actionType.GET_MAINTENANCE_TENANT_FAILURE:
            return {
                ...state,
                maintenanceDetailsByTenant: { success: false, ...action.error }
            };

        case actionType.GET_MESSAGE_DETAILS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MESSAGE_DETAILS_SUCCESS:
            return {
                ...state,
                messageDetailsByTenant: action.responseDetails,
            };
        case actionType.GET_MESSAGE_DETAILS_FAILURE:
            return {
                ...state,
                messageDetailsByTenant: { success: false, ...action.error }
            };
        case actionType.GET_NOTIFICATION_REQUEST:
            return {
                ...state
            };
        case actionType.GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationDetails: action.responseDetails,
            };
        case actionType.GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                notificationDetails: { success: false, ...action.error }
            };

        case actionType.UPDATE_NOTIFICATION_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                updateNotificationDetails: action.responseDetails,
            };
        case actionType.UPDATE_NOTIFICATION_FAILURE:
            return {
                ...state,
                updateNotificationDetails: { success: false, ...action.error }
            };

        case actionType.GET_ADMIN_REQUEST:
            return {
                ...state
            };
        case actionType.GET_ADMIN_SUCCESS:
            return {
                ...state,
                adminDetails: action.responseDetails,
            };
        case actionType.GET_ADMIN_FAILURE:
            return {
                ...state,
                adminDetails: { success: false, ...action.error }
            };

        case actionType.ADVANCE_SEARCH_REQUEST:
            return {
                ...state,
            };
        case actionType.ADVANCE_SEARCH_SUCCESS:
            return {
                ...state,
                advanceSearchData: action.responseDetails,
            };
        case actionType.ADVANCE_SEARCH_FAILURE:
            return {
                ...state,
                advanceSearchData: { success: false, ...action.error }
            };

        case actionType.GET_LIMIT_REQUEST:
            return {
                ...state,
            };
        case actionType.GET_LIMIT_SUCCESS:
            return {
                ...state,
                limitData: action.responseDetails.data,
            };
        case actionType.GET_LIMIT_FAILURE:
            return {
                ...state,
                limitData: { success: false, ...action.error }
            };
        case actionType.UPDATE_LIMIT_REQUEST:
            return {
                ...state,
            };
        case actionType.UPDATE_LIMIT_SUCCESS:
            return {
                ...state,
                updateLimitData: action.responseDetails.data,
            };
        case actionType.UPDATE_LIMIT_FAILURE:
            return {
                ...state,
                updateLimitData: { success: false, ...action.error }
            };

        case actionType.EMAIL_CHECK_REQUEST:
            return {
                ...state,
            };
        case actionType.EMAIL_CHECK_SUCCESS:
            return {
                ...state,
                emailCheck:{ success: true, ...action.responseDetails},
            };
        case actionType.EMAIL_CHECK_FAILURE:
            return {
                ...state,
                emailCheck: { success: false, ...action.error }
            };




        default:
            return state;
    }
};
