import React from "react";
import {connect} from 'react-redux';
import action from "../action";
import {withRouter} from "react-router-dom"
import SuccessModal from "./successModal"
import LoaderSpinner from "../../Common/components/Loader";
import CreatableSelect from 'react-select/creatable';

class NewMessageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipient: "",
            cc: "",
            subject: "",
            description: "",
            uploadAttachmentsHeader: 'Upload',
            uploadAttachment: [],
            attachmentChanged: false,
            errors: {},
            recipientArray: [],
            ccArray: [],
            showSuccess: false,
            isLoaded: false,
            uploadError:''
        };
    }

    componentDidMount = async () => {
        await this.props.getTenantEmails();
        this.setState({
            isLoaded: true
        });
    };

    checkEmail = (email) => {
        let regExp = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{3})(\.[a-z]{2})*$)/i;
        return regExp.test(email);
    };

    handleNewMessageSubmit = async (e) => {
        e.preventDefault();
        this.setState({errors: {}});
        const {recipient, cc, subject, description, uploadAttachment} = this.state;
        const errors = await this.validate(recipient, cc, subject, description);
        const {recipientArray, ccArray} = this.state;

        if (Object.keys(errors).length) {
            this.setState({errors});
            return false;
        }

        let formValues = {};
        formValues.to = recipientArray;
        formValues.cc = ccArray;
        formValues.subject = subject;
        formValues.message = description;

        this.setState({
            isLoaded: false
        });
        await this.props.sendNewMessage(formValues, uploadAttachment);
        const {messageReducer: {sendMessageRes: {success: status}}} = this.props;
        if (status) {
            this.setState({
                showSuccess: true,
            });
        }
        this.setState({
            isLoaded: true
        });
    };

    hideSuccessModal = () => {
        this.setState({
            showSuccess: false
        });
        this.props.onCancel()
    };

    validate = (recipient, cc, subject) => {
        let errors = {};
        let newRecipientsArray = [];
        let newCcArray = [];

        const {recipientArray, ccArray} = this.state;
        if (recipientArray.length) {
            let tempRecipientsArray = recipientArray;
            for (let i = 0; i <= (tempRecipientsArray.length - 1); i++) {
                if (this.checkEmail(tempRecipientsArray[i].trim())) {
                    newRecipientsArray.push(tempRecipientsArray[i].trim())
                } else {
                    errors.recipient = "Invalid email: " + tempRecipientsArray[i];
                }
            }
            this.setState({
                recipientArray: newRecipientsArray,
            });
        } else {
            errors.recipient = "Please add recipient mails."
        }

        if (ccArray.length) {
            let tempCcArray = ccArray;
            for (let i = 0; i <= (tempCcArray.length - 1); i++) {
                if (this.checkEmail(tempCcArray[i].trim())) {
                    newCcArray.push(tempCcArray[i].trim())
                } else {
                    errors.cc = "Invalid email: " + tempCcArray[i];
                }
            }
            this.setState({
                ccArray: newCcArray,
            });
        }

        if (!subject.length) {
            errors.subject = "Subject can't be empty";
        }

        return errors;
    };

    isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };

    handleAddAttachment = e => {
        if (this.isIterable(e.target.files)) {
            this.setState({
                uploadError: ''
            });
            if (e.target.files.length) {
                this.setState({
                    uploadAttachmentsHeader: 'Add'
                })
            }
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const invalidExt = ["exe", "dll", "dmg", "vb", "cmd", "bat", "jar", "vbs", "jse", "pif", "vxd", "jse", "apk", "ins", "sct", "msi"];
                // const acceptableExt = ["pdf", "docx", "doc", "txt", "png", "jpg", "ttf", "avi", "flv", "wmv", "mov", "mp4", "zip"];
                if (!invalidExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        this.setState({
                            attachmentChanged: true,
                            uploadAttachment: [...this.state.uploadAttachment, ...e.target.files],
                        });
                    } else {
                        this.setState({
                            uploadError: 'File is too big. Files with size greater than 25MB is not allowed.'
                        })
                    }
                } else {
                    this.setState({
                        attachmentChanged: false,
                        uploadError: '* Accept only image / video / emoji / documents !!!'
                    })
                }
            })
        }
    };

    deleteAttachment = (index) => {
        let fileArray = Object.values(this.state.uploadAttachment)
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            this.setState({
                uploadAttachmentsHeader: 'Upload',
                attachmentChanged: false,
                uploadAttachment: []
            });
        }
        this.setState({
            fileChanged: true,
            uploadAttachment: fileArray
        });
        document.getElementById('attachmentFiles').value = null
    };

    handleToSelect = (selectedEmails) => {
        let newRecipientsArray = [];
        (selectedEmails && selectedEmails.length) && selectedEmails.map(item => newRecipientsArray.push(item.value));
        this.setState({
            recipientArray: newRecipientsArray,
        });
    };

    handleCcSelect = (selectedEmails) => {
        let newCcArray = [];
        (selectedEmails && selectedEmails.length) && selectedEmails.map(item => newCcArray.push(item.value));
        this.setState({
            ccArray: newCcArray,
        });
    };

    render() {
        const {onCancel, submitting} = this.props;
        const {uploadAttachment, errors, uploadError, showSuccess, isLoaded} = this.state;
        if (!isLoaded) {
            return <LoaderSpinner/>
        }
        const {messageReducer: {tenantEmails}} = this.props;
        let tempTenantEmails = [];
        (tenantEmails && tenantEmails.length) && tenantEmails.map(item => tempTenantEmails.push({
            label: item.email.trim(),
            value: item.email.trim()
        }));
        return (
            <div className="modal addTenant addMessage" id="modalId" style={{display: 'block'}}>
                {!showSuccess && <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add New Message</h5>
                            <button type="button" className="close" onClick={onCancel}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={this.handleNewMessageSubmit}>
                            <div className="modal-body">
                                <div className="clientInfo">
                                    <div className="formSection">
                                        <div className="row outrForm">
                                            <div className="col-md-12 FormrightOutr">
                                                <div className="row">
                                                    <div className="mailTextboxHeight">
                                                        <div className="col-md-12 formInp">
                                                            <span className="messageLabel">To</span>
                                                            <CreatableSelect
                                                                isMulti
                                                                onChange={this.handleToSelect}
                                                                placeholder=''
                                                                options={tempTenantEmails}
                                                            />
                                                            {errors && errors.recipient &&
                                                            <span
                                                                className="text-danger"><small>{errors.recipient}</small></span>}
                                                        </div>
                                                        <div className="col-md-12 formInp">
                                                            <span className="messageLabel">Cc</span>
                                                            <CreatableSelect
                                                                isMulti
                                                                onChange={this.handleCcSelect}
                                                                placeholder=''
                                                                options={tempTenantEmails}
                                                            />
                                                            {errors && errors.cc &&
                                                            <span
                                                                className="text-danger"><small>{errors.cc}</small></span>}
                                                        </div>
                                                        <div className="col-md-12 formInp">
                                                            <input
                                                                name="subject"
                                                                type="text"
                                                                className="form-control formHeight"
                                                                placeholder="Subject"
                                                                onChange={evt => this.setState({subject: evt.target.value})}
                                                            />
                                                            {errors && errors.subject && <span
                                                                className="text-danger"><small>{errors.subject}</small></span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 formInp">
                                                        <textarea
                                                            className="form-control descriptionHeight"
                                                            rows="10"
                                                            cols="50"
                                                            defaultValue=""
                                                            placeholder="Description"
                                                            onChange={evt => this.setState({description: evt.target.value})}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 formInp attachFile">
                                                        <div className="col-md-5">
                                                            <label className="custom-file-label"><img
                                                                src="/images/Attach.png" alt=""/>
                                                                <span>Attach a file</span>
                                                                <input
                                                                    type="file"
                                                                    name="uploadAttachments"
                                                                    className="file-name"
                                                                    multiple={true}
                                                                    onChange={this.handleAddAttachment}
                                                                    id="attachmentFiles"
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {uploadError &&
                                                    <div className="text-danger text-right"><small>{uploadError}</small>
                                                    </div>}
                                                    {uploadAttachment && uploadAttachment.length
                                                        ?
                                                        <div className="col-md-12 attachfileOuter">
                                                            {Object.values(uploadAttachment).map((attachment, i) => {
                                                                return (
                                                                    <div
                                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                                        key={i}>
                                                                        <div className="col-md-12 attachFileList">
                                                                            {(attachment.name.split('.').pop() === "jpg" || attachment.name.split('.').pop() === "png" || attachment.name.split('.').pop() === "svg")
                                                                                ? <img src={URL.createObjectURL(attachment)} alt=""/>
                                                                                : (attachment.name.split('.').pop() === "pdf")
                                                                                    ? <i className="far fa-file-pdf"/>
                                                                                    : (attachment.name.split('.').pop() === "doc" || attachment.name.split('.').pop() === "docx")
                                                                                        ? <i className="far fa-file-alt"/>
                                                                                        : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.name.split('.').pop()))
                                                                                            ? <i className="far fa-file-video"/>
                                                                                            : (attachment.name.split('.').pop() === 'zip')
                                                                                            && <i className="far fa-file-archive"/>
                                                                            }
                                                                            <button type="button"
                                                                                    className="image-close"
                                                                                    onClick={() => this.deleteAttachment(i)}>
                                                                                <span aria-hidden="true">×</span>
                                                                            </button>
                                                                        </div>
                                                                        <p>{attachment.name}</p>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary btnSubmit" disabled={submitting}>Send</button>
                            </div>
                        </form>
                    </div>
                </div>}

                {showSuccess && <SuccessModal
                    hideSuccessModal={this.hideSuccessModal}
                    modalContents={{title: 'Success !', message: 'Mail sent successfully !!'}}
                />}

            </div>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(NewMessageModal));