import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AsyncSelect from 'react-select/async';
import commonAction from '../../Common/action';
import tenantAction from '../../Tenants/action'
import * as validation from "../../Common/components/validation";
import LoaderSpinner from "../../Common/components/Loader";
import { MAIN_ROUTE } from "../../../config/constants";
import NumberFormat from 'react-number-format';

class MaintenanceAdd extends React.Component {
    state = {
        maintenanceForm: {
            propertyId: null,
            unitId: null,
            maintenanceFile: [],
            description: null,
            categoryId: null,
            requestById: null,
            status: 1,
            amount: null,
            paymentId: null,
            maintenanceFileEdit: [],
            maintenancePicture: [],
            tenantName: ""
        },
        isSubmitted: false,
        isLoading: true,
        attachmentChanged: false,
        uploadAttachmentsHeader: "",
        owner: "",
        Properties: []
    }

    componentDidMount = async () => {
        await this.props.getProperty()
        await this.props.getExpenseCategory()
        await this.props.getPayment();
        await this.props.getAllUnits()
        await this.props.getTenant({ 'property': this.props.maintenanceDetails.propertyId, isDeleted: false });
        if(this.props.maintenanceDetails.propertyId)
        this.setState({
            maintenanceForm: this.props.maintenanceDetails
        })

        const { commonReducer: { Property } } = this.props;
        const Properties = Property.success && Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        setTimeout(() => {
            this.setState({
                isLoading: false,
                Properties
            })
        }, 500)
    }

    validate = (value, name, type) => {
        if (this.state.isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        validation.NUMBER_ONLY(value, name) != true ? <div className={"errorMsg"}>
                            {validation.NUMBER_ONLY(value, name)}
                        </div> : null
                    );
            }
        } else return null;
    };

    handleChangeProperty = async (value) => {
        await this.props.getUnitsByPropertyId(value.value)
        let propertyOwner = this.props.commonReducer.Property.success ? this.props.commonReducer.Property.propertyList.find(p => (p._id === value.value)) : null
        this.setState({
            maintenanceForm: {
                ...this.state.maintenanceForm,
                propertyId: value.value,
                propertyDetail: value,
                paymentId: propertyOwner && propertyOwner.propertyOwnerName[0] ? propertyOwner.propertyOwnerName[0]._id : " ",
                propertyOwnerName: propertyOwner && propertyOwner.propertyOwnerName[0] ? propertyOwner.propertyOwnerName[0].entityName : " ",
                tenantName: "",
                requestById: " ",
                unitId: " "
            }
        })
    }

    handleChange = async (e) => {
        if (e.target.type === "file") {
            const { name, files } = e.target
            this.setState({
                maintenanceForm: {
                    ...this.state.maintenanceForm,
                    [name]: files[0]
                }
            })
        } else if (e.target.name === "amount") {
            const { name, value } = e.target
            this.setState({
                maintenanceForm: {
                    ...this.state.maintenanceForm,
                    [name]: value.replace("$", "").replace(/,/g, ''),
                }
            })
        } else if (e.target.name === "unitId") {
            const { name, value } = e.target
            await this.props.getTenant({ 'unit': value, isDeleted: false })
            const { tenantReducer: { tenants } } = this.props;
            this.setState({
                maintenanceForm: {
                    ...this.state.maintenanceForm,
                    [name]: value,
                    tenantName: tenants.tenantList && tenants.tenantList.length ? tenants.tenantList[0].firstName + " " + tenants.tenantList[0].lastName : "",
                    requestById: tenants.tenantList && tenants.tenantList.length ? tenants.tenantList[0]._id : null
                }
            })
        } else {
            const { name, value } = e.target
            this.setState({
                maintenanceForm: {
                    ...this.state.maintenanceForm,
                    [name]: value,
                }
            })
        }
    }

    handleChangeTenant = (value) => {
        this.setState({
            maintenanceForm: {
                ...this.state.maintenanceForm,
                requestBy: value,
                requestById: value.value
            }
        })
    }

    addMaintenance = async () => {
        this.setState({
            isSubmitted: true
        })
        const { maintenanceForm } = this.state
        if (maintenanceForm.requestById && maintenanceForm.description && maintenanceForm.categoryId && maintenanceForm.propertyId && maintenanceForm.unitId &&
            maintenanceForm.paymentId && maintenanceForm.status) {
            if (maintenanceForm.status == "0") {
                if (maintenanceForm.amount && (validation.NUMBER_ONLY(maintenanceForm.amount) === true)) {
                    await this.props.addMaintenanceDetails(maintenanceForm)
                }
            } else {
                if (maintenanceForm.amount) {
                    validation.NUMBER_ONLY(maintenanceForm.amount) === true && await this.props.addMaintenanceDetails(maintenanceForm)
                } else {
                    await this.props.addMaintenanceDetails(maintenanceForm)
                }
            }
        }
    }

    isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };

    handleChangeFile = (e) => {
        const { maintenanceForm } = this.state
        if (this.isIterable(e.target.files)) {
            if (e.target.files.length) {
                this.setState({
                    uploadAttachmentsHeader: 'Add'
                })
            }
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const acceptableExt = ["pdf", "docx", "doc", "txt", "png", "jpg", "ttf", "avi", "flv", "wmv", "mov", "pdf", "doc", "docx"];
                if (acceptableExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        this.setState({
                            attachmentChanged: true
                        })
                        let attachmentTemp = [...maintenanceForm.maintenanceFile, ...e.target.files];
                        this.setState({
                            maintenanceForm: {
                                ...this.state.maintenanceForm,
                                maintenanceFile: attachmentTemp
                            }
                        })
                    } else {
                        this.setState({
                            uploadError: 'File is too big. Files with size greater than 25MB is not allowed.'
                        })
                    }
                } else {
                    this.setState({
                        attachmentChanged: false
                    })
                    this.setState({
                        uploadError: '* Accept only image / video / emoji / documents !!!'
                    })
                }
            })
        }
    }

    deleteAttachment = (index) => {
        const { maintenanceForm, } = this.state
        let fileArray = maintenanceForm.maintenanceFile
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            this.setState({
                maintenanceForm: {
                    ...this.state.maintenanceForm,
                    maintenanceFile: []

                },
                uploadAttachmentsHeader: 'Upload',
                attachmentChanged: false
            })
        }
        this.setState({
            maintenanceForm: {
                ...this.state.maintenanceForm,
                maintenanceFile: fileArray
            },
        })
    };

    deleteAttachmentEdit = (index, attachment) => {
        const { maintenanceForm, } = this.state
        let fileArray = maintenanceForm.maintenancePicture
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            this.setState({
                maintenanceForm: {
                    ...this.state.maintenanceForm,
                    maintenanceFile: []

                },
                uploadAttachmentsHeader: 'Upload',
                attachmentChanged: false
            })
        }
        this.setState({
            maintenanceForm: {
                ...this.state.maintenanceForm,
                maintenanceFile: fileArray
            },
        })
    };

    promiseOptionsProperty = async val => {
        await this.props.getProperty({ name: val })
        const Properties = this.props.commonReducer.Property.success && this.props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    render() {
        const { maintenanceForm, isLoading } = this.state

        return (
            <div className="modal addTenant small-modal" id="modalId" style={{ display: 'block' }}>
                {isLoading && <LoaderSpinner />}
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {!maintenanceForm.isView ? maintenanceForm.isEdit ?
                                    "Update Maintenance" :
                                    "Add Maintenance" : "View Maintenance"}
                            </h5>
                            <button type="button" className="close" onClick={this.props.onCancel}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="clientInfo">

                                <div className="formSection">
                                    <div className="row m-0 p-0">
                                        <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 formInp">
                                            <label>Property</label>
                                            <AsyncSelect
                                                cacheOptions
                                                defaultOptions={this.state.Properties}
                                                placeholder={"All Properties"}
                                                isDisabled={maintenanceForm.isView || this.props.tenantPage || maintenanceForm.isTenant}
                                                loadOptions={this.promiseOptionsProperty}
                                                name="tenantId"
                                                onChange={(value) => this.handleChangeProperty(value)}
                                                value={maintenanceForm.propertyDetail}

                                            />
                                            {this.validate(maintenanceForm.propertyId, "Property", "required")}
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 formInp">
                                            <label>Unit</label>
                                            <div className="selctOtr">
                                                <select className="form-control" name={"unitId"}
                                                    value={maintenanceForm.unitId}
                                                    disabled={maintenanceForm.isView || this.props.tenantPage || maintenanceForm.isTenant}
                                                    onChange={this.handleChange}>
                                                    <option>Select Unit</option>
                                                    {this.props.commonReducer.unitDetails && this.props.commonReducer.unitDetails.unitList
                                                        && this.props.commonReducer.unitDetails.unitList.length ? this.props.commonReducer.unitDetails.unitList.map((units, key) => {
                                                            return <option value={units._id}>{units.unitName}</option>
                                                        }) : null}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 formInp">
                                            <label>Description</label>
                                            <textarea class="form-control" placeholder="Enter description" name="" id=""
                                                cols="30" rows="10" name={"description"}
                                                value={maintenanceForm.description}
                                                disabled={maintenanceForm.isView}
                                                onChange={this.handleChange}></textarea>
                                            {this.validate(maintenanceForm.description, "Description", "required")}

                                        </div>

                                        <div className="col-xl-6 col-lg-6 col-md-6 formInp">
                                            <label>Category</label>
                                            <div className="selctOtr">
                                                <select className="form-control" name={"categoryId"}
                                                    value={maintenanceForm.categoryId}
                                                    disabled={maintenanceForm.isView}
                                                    onChange={this.handleChange}>
                                                    <option>Select category</option>
                                                    {this.props.commonReducer.categoryList.length ? this.props.commonReducer.categoryList.map((category, key) => {
                                                        return <option value={category._id}>{category.name}</option>
                                                    }) : null}
                                                </select>
                                            </div>
                                            {this.validate(maintenanceForm.categoryId, "Category", "required")}
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 formInp">
                                            <label>Tenant Name</label>
                                            <input className="form-control" type="text" placeholder="Tenant Name"
                                                disabled={true}
                                                name={"requestById"}
                                                // onChange={handleChange}
                                                // value={propertyDetails.length ? propertyDetails[0].propertyOwnerName[0].propertyOwnerName:null}
                                                value={maintenanceForm.tenantName}
                                            />
                                            {this.validate(maintenanceForm.requestById, "Reported By ", "required")}
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-4 formInp">
                                            <label>Property Owner</label>
                                            <input className="form-control" type="text" placeholder="PropertyOwner"
                                                disabled={true}
                                                name={"paymentId"}
                                                onChange={this.handleChange}
                                                // value={propertyDetails.length ? propertyDetails[0].propertyOwnerName[0].propertyOwnerName:null}
                                                value={maintenanceForm.propertyOwnerName}
                                            />
                                            {this.validate(maintenanceForm.paymentId, "Property Owner", "required")}

                                        </div>{!maintenanceForm.isTenant &&
                                            <>
                                        <div className="col-xl-4 col-lg-4 col-md-4 formInp">
                                            <label>Status</label>
                                            <div className="selctOtr">
                                                <select className="form-control" name={"status"}
                                                    value={maintenanceForm.status}
                                                    disabled={maintenanceForm.isView}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value={1}>Open</option>
                                                    <option value={2}>OnHold</option>
                                                    <option value={3}>In progress</option>
                                                    <option value={4}>Answered</option>
                                                    <option value={0}>Closed</option>
                                                </select>
                                            </div>
                                            {this.validate(maintenanceForm.status, "Status", "required")}
                                        </div>

                                        <div className="col-md-4 col-lg-4 col-md-4 formInp">
                                            <label>Amount</label>

                                            <NumberFormat
                                                thousandSeparator={true}
                                                disabled={maintenanceForm.isView}
                                                prefix={'$'}
                                                className="form-control"
                                                name="amount"
                                                onChange={this.handleChange}
                                                value={maintenanceForm.amount}
                                            />
                                            {maintenanceForm.status == "0" && this.validate(maintenanceForm.amount, "Amount", "required")}
                                            {maintenanceForm.amount && this.validate(maintenanceForm.amount, "Amount", "isNumber")}
                                        </div>
                                    </>}
                                        <div className="col-md-12 formInp attachFile">
                                            <div className="col-md-5">
                                                <label className="custom-file-label" htmlFor="inputGroupFile01">
                                                    {!maintenanceForm.isView && <><img
                                                        src="/images/Attach.png" alt="" />
                                                        <span>Attach a file</span>
                                                        <input type="file" id="inputGroupFile01"
                                                            name={"maintenanceFile"}
                                                            onChange={this.handleChangeFile}
                                                            multiple={true}
                                                            disabled={maintenanceForm.isView}
                                                        /></>}
                                                </label>
                                            </div>
                                        </div>
                                        {((maintenanceForm.maintenancePicture && maintenanceForm.maintenancePicture.length) || (maintenanceForm.maintenanceFile && maintenanceForm.maintenanceFile.length)) ?
                                            <div className="col-md-12 attachfileOuter">
                                                {maintenanceForm.maintenancePicture && maintenanceForm.maintenancePicture.length ? maintenanceForm.maintenancePicture.map((attachment, i) => {
                                                    return (
                                                        <div
                                                            className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                            key={i}>
                                                            <div className="col-md-12 attachFileList">
                                                                {(attachment.title.split('.').pop() === "jpg" || attachment.title.split('.').pop() === "png")
                                                                    ? <img
                                                                        src={MAIN_ROUTE + attachment.path}
                                                                        alt="" />
                                                                    : (attachment.title.split('.').pop() === "pdf")
                                                                        ?
                                                                        <i className="far fa-file-pdf"></i>
                                                                        : (attachment.title.split('.').pop() === "doc" || attachment.title.split('.').pop() === "docx")
                                                                            ?
                                                                            <i className="far fa-file-alt"></i>
                                                                            : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.title.split('.').pop())) &&
                                                                            <i className="far fa-file-video"></i>
                                                                }
                                                                {!maintenanceForm.isView && <button type="button"
                                                                    className="image-close"
                                                                    onClick={() => this.deleteAttachmentEdit(i, attachment)}>
                                                                    <span aria-hidden="true">×</span>
                                                                </button>}
                                                            </div>
                                                            <p>{attachment.title}</p>
                                                        </div>
                                                    )
                                                }) : null}
                                                {maintenanceForm.maintenanceFile && maintenanceForm.maintenanceFile.length ? maintenanceForm.maintenanceFile.map((attachment, i) => {
                                                    return (
                                                        <div
                                                            className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                            key={i}>
                                                            <div className="col-md-12 attachFileList">
                                                                {(attachment.name.split('.').pop() === "jpg" || attachment.name.split('.').pop() === "png")
                                                                    ? <img
                                                                        src={URL.createObjectURL(attachment)}
                                                                        alt="" />
                                                                    : (attachment.name.split('.').pop() === "pdf")
                                                                        ?
                                                                        <i className="far fa-file-pdf"></i>
                                                                        : (attachment.name.split('.').pop() === "doc" || attachment.name.split('.').pop() === "docx")
                                                                            ?
                                                                            <i className="far fa-file-alt"></i>
                                                                            : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.name.split('.').pop())) &&
                                                                            <i className="far fa-file-video"></i>
                                                                }
                                                                <button type="button"
                                                                    className="image-close"
                                                                    onClick={() => this.deleteAttachment(i)}>
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <p>{attachment.name}</p>
                                                        </div>
                                                    )
                                                }) : null
                                                }
                                            </div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {!maintenanceForm.isView ? maintenanceForm.isEdit ?
                                <button type="button" class="btn btn-primary btnSubmit"
                                    onClick={this.addMaintenance}>Update</button> :
                                <button type="button" className="btn btn-primary btnSubmit"
                                    onClick={this.addMaintenance}>Submit</button> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { commonReducer, tenantReducer } = state;
    return {
        commonReducer,
        tenantReducer
    };
}

export default connect(mapStateToProps, { ...commonAction, ...tenantAction })(
    MaintenanceAdd
);

