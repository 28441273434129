import React, {useState, useEffect} from "react";
import action from "../../action";
import {connect} from "react-redux";
import commonAction from '../../../Common/action';
import usePrevious from "../../../Common/components/prevProps";
import toastr from '../../../Common/components/Toaster';
import LoaderSpinner from "../../../Common/components/Loader";
import * as validation from '../../../Common/components/validation';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CreditCardInput from 'react-credit-card-input';
import settingsAction from '../../../Settings/action'

const AddCredit = (props) => {

    const [cardDetails, setCardDetails] = useState({
        expiryDate: '',
        cardName: '',
        cardNumber: '',
        cvv: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect(() => {
        const {cardDetails} = props;
        if (cardDetails && cardDetails.length)
            setCardDetails({
                expiryDate: cardDetails[0].expiryDate && cardDetails[0].expiryDate,
                cardName: cardDetails[0].cardName && cardDetails[0].cardName,
                cardNumber: cardDetails[0].cardNumber && cardDetails[0].cardNumber,
                cvv: cardDetails[0].cvv && cardDetails[0].cvv
            })
    }, []);

    const {tenantReducer: {tenant}} = props;

    const prevTenant = usePrevious(tenant);

    useEffect(() => {
        //For updating card
        if (isSubmitted && !tenant.success && tenant != prevTenant) {
            toastr.error(tenant.message)
        } else if (isSubmitted && tenant.success && tenant.tenant != prevTenant) {
            toastr.success("Card details updated successfully");
        }
    }, [prevTenant, tenant]);

    const { card } = props;

    useEffect(() => {
        //For updating card
        if (submitted && !card.success) {

            toastr.error(card.message)
        } else if (submitted && card.success ) {
            toastr.success("Card details added successfully");
        }
    }, [card]);

    //For deleting card
    useEffect(() => {
        if (isDeleted && !tenant.success && tenant != prevTenant) {
            toastr.error(tenant.message)
        } else if (isDeleted && tenant.success && tenant.tenant != prevTenant) {
            toastr.success("Card details deleted successfully");
        }
    }, [prevTenant, tenant]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCardDetails({
            ...cardDetails,
            [name]: value
        });
    }

    const onChangeDate = value => {
        setCardDetails({
            ...cardDetails,
            expiryDate: moment(new Date(value)).format('MM/YY')
        });
    }

    const validate = (value, name, type) => {
        if (submitted) {
            switch (type) {
                case "required":
                    return validation.REQUIRED(value, name) != true &&
                        <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
                case "number":
                    return validation.NUMBER_ONLY(value, name) != true &&
                        <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
                case "letter":
                    return validation.NUMBER_ONLY(value, name) != true &&
                        <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "cardNumber":
                    return validation.NUMBER_ONLY(value, name) != true &&
                        <div className={"errorMsg"}>{validation.CARD_NUMBER(value, name)}</div>
            }
        } else return null
    }

    const handleCardNumberChange = e => {
        setCardDetails({
            ...cardDetails,
            cardNumber: e.target.value
        });
    }

    const handleCardExpiryChange = (e) => {
        setCardDetails({
            ...cardDetails,
            expiryDate: e.target.value
        });
    }

    const handleCvvChange = (e) => {
        setCardDetails({
            ...cardDetails,
            cvv: e.target.value
        });
    }

    const onSubmit = async () => {
        setSubmitted(true)
        const {expiryDate, cardName, cardNumber, cvv} = cardDetails;
        const req = {
            tenantId:props.id,
            cardName:cardName,
            cardNumber:cardNumber.replace(/ +/g, ""),
            cvv:cvv,
            expiryDate : expiryDate,
            defaultCard: 1
        }
        if (expiryDate && cardName && cardNumber && cvv) {
            if (validation.CARD_NUMBER(cardNumber, 'cardNumber') == true) {
                await props.addCardDetails(req);
                props.reloadTenant();
                props.onCancel();
                setCardDetails({})
                setTimeout(() => {
                    setIsSubmitted(false)
                }, 1000)
            }
        }
    }

    const onDelete = async () => {
        const req = {
            id: props.id,
            cardDetails: {
                expiryDate: '',
                cardName: '',
                cardNumber: '',
                cvv: ''
            },
            profilePic: []
        }

        await props.addTenant(req);
        setIsDeleted(true);
        props.reloadTenant();
        props.onCancel();
        setCardDetails({})
        setTimeout(() => {
            setIsDeleted(false)
        }, 1000);
    }


    const {expiryDate, cardName, cardNumber, cvv} = cardDetails;

    return (
        <div className="modal addTenant addcardDtl" id="modalId" style={{display: "block"}} role="dialog"
             aria-hidden="true">
            {/* <ToastContainer /> */}
            {isSubmitted && <LoaderSpinner/>}
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Credit Card Information</h5>
                        <button type="button" className="close" onClick={() => props.onCancel()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <div className="formSection">
                                <div className="row outrForm m-0 p-0">
                                    <div className="col-md-12 formInp">
                                        <label>Name on Card</label>
                                        <input
                                            type="text"
                                            name="cardName"
                                            className="form-control"
                                            placeholder="Name on Card"
                                            value={cardName}
                                            onChange={handleChange}
                                        />
                                        {validate(cardName, "Name", "required")}
                                        {cardName && validate(cardName, "Name", "letter")}
                                    </div>
                                    <div className="col-md-12 formInp">
                                        <label>Card Number</label>
                                        <div className="creditcardOtr">
                                            <CreditCardInput
                                              customTextLabels={{
                                                invalidCvc:'  ',
                                                cardNumberPlaceholder: 'Card Number',
                                                expiryPlaceholder: 'MM/YY',
                                                cvcPlaceholder: 'CVV'
                                              }}
                                                cardNumberInputProps={{
                                                    value: cardNumber,
                                                    onChange: handleCardNumberChange
                                                }}
                                                cardExpiryInputProps={{
                                                    value: expiryDate,
                                                    onChange: handleCardExpiryChange
                                                }}
                                                cardCVCInputProps={{value: cvv, onChange: handleCvvChange}}
                                                // fieldClassName="form-control"
                                                dangerTextClassName={"errorMsg"}
                                                invalidClassName={null}
                                            />
                                        </div>
                                        {/* <input
                                            type="number"
                                            name="cardNumber"
                                            className="form-control"
                                            placeholder="Card Number"
                                            value={cardNumber}
                                            onChange={handleChange}
                                            maxlength='16'
                                        />
                                        {validate(cardNumber, "Card Number", "required")}
                                        {cardNumber && validate(cardNumber, "Card Number", "cardNumber")} */}
                                    </div>
                                    {/* <div className="col-md-6 formInp">
                                        <label>Expiry Date</label>
                                        <DatePicker
                                            name="expiryDate"
                                            placeholder="Expiry Date"
                                            disableClock={true}
                                            format="mm/yy"
                                            className="form-control"
                                            onChange={onChangeDate}
                                            value={expiryDate}
                                            minDate={new Date()}
                                        />
                                        {validate(expiryDate, "Expiry Date", "required")}
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit" onClick={() => onSubmit()}>Submit
                        </button>
                        {props.cardDetails && props.cardDetails[0] && props.cardDetails[0].cardNumber ?
                            <button type="button" className="btn btn-primary btnSubmit"
                                    onClick={() => onDelete()}>Delete</button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const {tenantReducer, commonReducer,settingsReducer} = state;
    return {
        tenantReducer,
        commonReducer,
        card:settingsReducer.card,
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction,...settingsAction
})(AddCredit);

