//SEND MESSAGE
export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

//GET MESSAGES
export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";

//GET MESSAGE DETAILS
export const GET_MESSAGES_DETAILS_REQUEST = "GET_MESSAGES_DETAILS_REQUEST";
export const GET_MESSAGES_DETAILS_SUCCESS = "GET_MESSAGES_DETAILS_SUCCESS";
export const GET_MESSAGES_DETAILS_FAILURE = "GET_MESSAGES_DETAILS_FAILURE";

//GET TENANT EMAILS
export const GET_TENANT_EMAILS_REQUEST = "GET_TENANT_EMAILS_REQUEST";
export const GET_TENANT_EMAILS_SUCCESS = "GET_TENANT_EMAILS_SUCCESS";
export const GET_TENANT_EMAILS_FAILURE = "GET_TENANT_EMAILS_FAILURE";

//DELETE INBOX MESSAGE
export const DELETE_INBOX_MESSAGE_REQUEST = "DELETE_INBOX_MESSAGE_REQUEST";
export const DELETE_INBOX_MESSAGE_SUCCESS = "DELETE_INBOX_MESSAGE_SUCCESS";
export const DELETE_INBOX_MESSAGE_FAILURE = "DELETE_INBOX_MESSAGE_FAILURE";

//GET TENANT PHONE NUMBERS
export const GET_TENANT_PHONE_NUMBERS_REQUEST = "GET_TENANT_PHONE_NUMBERS_REQUEST";
export const GET_TENANT_PHONE_NUMBERS_SUCCESS = "GET_TENANT_PHONE_NUMBERS_SUCCESS";
export const GET_TENANT_PHONE_NUMBERS_FAILURE = "GET_TENANT_PHONE_NUMBERS_FAILURE";

//SEND CHAT MESSAGE
export const SEND_CHAT_MESSAGE_REQUEST = "SEND_CHAT_MESSAGE_REQUEST";
export const SEND_CHAT_MESSAGE_SUCCESS = "SEND_CHAT_MESSAGE_SUCCESS";
export const SEND_CHAT_MESSAGE_FAILURE = "SEND_CHAT_MESSAGE_FAILURE";

//SEND CHAT MESSAGE
export const GET_CHAT_MESSAGE_LIST_REQUEST = "GET_CHAT_MESSAGE_LIST_REQUEST";
export const GET_CHAT_MESSAGE_LIST_SUCCESS = "GET_CHAT_MESSAGE_LIST_SUCCESS";
export const GET_CHAT_MESSAGE_LIST_FAILURE = "GET_CHAT_MESSAGE_LIST_FAILURE";

//GET CHAT DETAILS
export const GET_CHAT_DETAILS_REQUEST = "GET_CHAT_DETAILS_REQUEST";
export const GET_CHAT_DETAILS_SUCCESS = "GET_CHAT_DETAILS_SUCCESS";
export const GET_CHAT_DETAILS_FAILURE = "GET_CHAT_DETAILS_FAILURE";

//GET CHAT DETAILS
export const DELETE_INBOX_THREAD_REQUEST = "DELETE_INBOX_THREAD_REQUEST";
export const DELETE_INBOX_THREAD_SUCCESS = "DELETE_INBOX_THREAD_SUCCESS";
export const DELETE_INBOX_THREAD_FAILURE = "DELETE_INBOX_THREAD_FAILURE";

//DELETE  MAIL DETAILS
export const DELETE_INBOX_MAIL_REQUEST = "DELETE_INBOX_MAIL_REQUEST";
export const DELETE_INBOX_MAIL_SUCCESS = "DELETE_INBOX_MAIL_SUCCESS";
export const DELETE_INBOX_MAIL_FAILURE = "DELETE_INBOX_MAIL_FAILURE";

//DELETE  MESSAGE DETAILS
export const DELETE_MESSAGE_REQUEST = "DELETE_MESSAGE_REQUEST";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAILURE = "DELETE_MESSAGE_FAILURE";

//GET CHAT DETAILS
export const REFRESH_REQUEST = "REFRESH_REQUEST";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAILURE = "REFRESH_FAILURE";