import React, { useState, useEffect } from "react";
import moment from 'moment'
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";

import { numberWithCommas } from "../../Common/components/utils";
import history from "../../../config/history";
import PopOverItem from "./popOverItem"

// const PopoverExampleMulti = props => {
//     return (
//         <>
//             {[
//                 {
//                     placement: "top",
//                     text: "Top"
//                 },
//                 {
//                     placement: "bottom",
//                     text: "Bottom"
//                 },
//                 {
//                     placement: "left",
//                     text: "Left"
//                 },
//                 {
//                     placement: "right",
//                     text: "Right"
//                 }
//             ].map((popover, i) => {
//                 return <PopOverItem key={i} item={popover} id={i} amount={amount}/>;
//             })}
//         </>
//     );
// };


const InvoiceListing = (props) => {
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false)
    const { id, item } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverKey, setPopoverKey] = useState(null);

    const toggle = () => setPopoverOpen(!popoverOpen);

    useEffect(() => {

        if (props.InvoiceList) {
            if (!props.InvoiceList.invoice) {
                setIsCheckboxDisabled(true)
            } else {
                const { invoice } = props.InvoiceList
                let unpaidArray = invoice.filter(item => item.status == 0)
                setIsCheckboxDisabled(unpaidArray.length == 0)
            }
        } else {
            setIsCheckboxDisabled(true)
        }
    }, [props.InvoiceList])



    return (
        <>
            {!props.isTenantView && <div className={"invoiceOtr"}> {props.count ? <>
                {/* // <span className={"txtInvoicePaid "}>
                //     {props.count} Records Found </span> */}
                <span className={"txtInvoicePaid paddingInvoiceText"}>
                    {props.count} Records Found </span>
                <div className="vl"></div>
                {!props.isTenantViewDetail ? <span className={"txtInvoicePaid paddingInvoiceText"}>
                    Total Amount : ${props.InvoiceList && props.InvoiceList.totalAmountBasedFilter ? numberWithCommas(props.InvoiceList.totalAmountBasedFilter) : "0.00"}</span> : null}
                {/* <span className={"txtInvoicePaid pr-2"}>Total Paid : {props.InvoiceList.invoiceList.data.paidCount}</span> */}
            </> : null}<div className="markpaidBtn d-flex">

                    <button className="markPaid mr-1" onClick={props.handleAttemptCapture} disabled={!props.isButtonActive}>
                        Attempt Capture
                </button>
                    <button className="markPaid mr-1" onClick={props.handleSendSms} disabled={!props.isButtonActive}>Send
                    SMS
                </button>
                    <button className="markPaid mr-1" onClick={props.handleDeleteChecked}
                        disabled={!props.isButtonActive}>Delete
                </button>
                    <button className="markPaid mr-1" onClick={props.handleMerge}
                        disabled={!props.isButtonActive}>Merge
                </button>
                    <button className="markPaid mr-1" onClick={props.handleUpdate} disabled={!props.isButtonActive}>Mark as
                    Paid
                </button>
                    <button className="markPaid" onClick={props.handleUnpaid}
                        disabled={!props.isButtonActive}>Mark as Unpaid
                </button>
                    <div className="formSection status">
                        <select className="form-control" title="Status" value={props.status} name={"status"} onChange={props.handleChangeStatus}>
                            <option value="">All</option>
                            <option value={"0"}>Unpaid</option>
                            <option value={"1"}>Paid</option>
                        </select>
                    </div>
                    <div className="formSection">
                        {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                        <select className="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}>
                            <option value={15}>15</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div></div>}
            <div >
                <table className="table-gap table-td">
                    <thead>
                        <tr className="table-head">
                            {!props.isTenantView && <th className="checkboxWidth">
                                <label className="invoiceCheckbox"
                                    style={{ cursor: isCheckboxDisabled ? 'no-drop' : '' }}
                                >
                                    <input
                                        type="checkbox"
                                        disabled={isCheckboxDisabled}
                                        name="isArchived"
                                        onClick={props.handleCheckAll}
                                        checked={props.isCheckedAll}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </th>}
                            <th onClick={() => props.filterData("invoiceName")}>
                                <div className="thTab">
                                    <div className="txtTabl">Invoice No</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" /></div>
                                </div>
                            </th>
                            <th onClick={() => props.filterData("firstName")}>
                                <div className="thTab">
                                    <div className="txtTabl">Tenant Name</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" /></div>
                                </div>
                            </th>

                            <th className="date-column-width" onClick={() => props.filterData("startDate")}>
                                <div className="thTab">
                                    <div className="txtTabl">Invoice date</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" /></div>
                                </div>
                            </th>
                            <th className="date-column-width" onClick={() => props.filterData("dueDate")}>
                                <div className="thTab">
                                    <div className="txtTabl">Due date</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" /></div>
                                </div>
                            </th>
                            <th className="date-column-width" onClick={() => props.filterData("attemptToCaptureDate")}>
                                <div className="thTab">
                                    <div className="txtTabl">{props.status == 1 ? "Paid Date" : "Last attempt"}</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" /></div>
                                </div>
                            </th>
                            <th onClick={() => props.filterData("amount")}>
                                <div className="thTab">
                                    <div className="txtTabl">Total</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" /></div>
                                </div>
                            </th>
                            <th onClick={() => props.filterData("name")}>
                                <div className="thTab">
                                    <div className="txtTabl">Payment method</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" /></div>
                                </div>
                            </th>
                            <th
                                onClick={() => props.filterData("status")}
                            >
                                <div className="thTab">
                                    <div className="txtTabl">Status</div>
                                    <div className="TxtImg">
                                        <img src="/images/doublearrow.png" className="imgArw" />
                                    </div>
                                </div>
                            </th>
                            {<th className="actionSec">Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {props.InvoiceList && props.InvoiceList.invoice && props.InvoiceList.invoice.length ? props.InvoiceList.invoice.map((inv, key) => {
                            let isChecked = props.paidArray && props.paidArray.length ? props.paidArray.find(p => p.id === inv._id) : false
                            return <tr key={key}>
                                {!props.isTenantView &&
                                    <td className="checkboxPadding">
                                        <label
                                            // style={{cursor: inv.status ? 'no-drop' : ''}}
                                            className="invoiceCheckbox">
                                            <input
                                                type="checkbox"
                                                name="isArchived"
                                                // disabled={inv.status}
                                                checked={isChecked}

                                                onChange={(e) => props.handleCheck(e, inv)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>}
                                <td onClick={() => props.editInvoice(inv)}>#{inv.invoiceNumber}</td>
                                {!props.tenantView ? <td onClick={() => window.open(`/Tenants/${inv.tenantId._id}/Summary`, "_blank")}>{props.InvoiceList.tenantId ?
                                    (props.InvoiceList.tenantId.firstName).charAt(0).toUpperCase() + (props.InvoiceList.tenantId.firstName).slice(1) + " " +
                                    (props.InvoiceList.tenantId.lastName).charAt(0).toUpperCase() + (props.InvoiceList.tenantId.lastName).slice(1)
                                    :
                                    (inv.tenantId.firstName).charAt(0).toUpperCase() + (inv.tenantId.firstName).slice(1) + " " +
                                    (inv.tenantId.lastName).charAt(0).toUpperCase() + (inv.tenantId.lastName).slice(1)
                                }
                                </td> : <td>{inv.invoiceTypeId ? inv.invoiceTypeId.name : null}</td>}
                                <td>{inv.startDate ? moment((inv.startDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null}</td>
                                <td>{inv.dueDate ? moment((inv.dueDate).replace(/-/g, '/').replace(/T.+/, '')).format('MM/DD/YYYY') : null}</td>
                                <td>{inv.attemptToCaptureDate ? moment(inv.attemptToCaptureDate).format('MM/DD/YYYY') : "N/A"}</td>
                                {/* <td id={"Popover-" + key }
                                    onMouseEnter={()=>setPopoverOpen(true)}
                                    onMouseLeave={()=>setPopoverOpen(false)}
                                    type="td">
                                    ${inv.amount ? numberWithCommas(inv.amount) || 0 : 0}
                                    {console.log("popover","Popover-" + key)}
                                    <Popover
                                        // placement={"top"}
                                        isOpen={popoverOpen}
                                        target={"Popover-" + key}
                                        toggle={toggle}
                                    >
                                        <PopoverHeader>Popover Title</PopoverHeader>
                                        <PopoverBody>
                                            Sed posuere consectetur est at lobortis. Aenean eu leo quam.
                                            Pellentesque ornare sem lacinia quam venenatis vestibulum.
            `                       </PopoverBody>
                                    </Popover>
                                </td> */}
                                <PopOverItem key={key} item={"top"} id={key} amount={inv.amount} invoice={inv} />
                                <td>{inv.paymentTypeId ? inv.paymentTypeId.name : null}</td>
                                <td>
                                    <div className="statusSec">
                                        <div className={inv.status == "1" ? "status-bg " : "status-bg process-bg"}>
                                        </div>
                                        <div
                                            className={inv.status == "1" ? "price unpaide" : inv.status == "0" ? "price " : ""}>{inv.status == "1" ? "Paid" : inv.status == "2" ? "Cancelled" : "Unpaid"}</div>
                                    </div>
                                </td>
                                {
                                    <td>
                                        <div className="dropdown moveDrop editDetlPrp drop-arw">
                                            <i
                                                className="fas fa-pencil-alt mr-1"
                                                aria-hidden="true"
                                                onClick={() => props.editInvoice(inv)}
                                            ></i>
                                            <i
                                                disabled="true"
                                                className="fa fa-trash"
                                                onClick={() => props.handleDelete(inv._id)}
                                            ></i>
                                        </div>
                                    </td>
                                }
                            </tr>
                        }) : <tr>
                                <td className="text-center no_value_table" colSpan={10}><i className="far fa-thumbs-down"></i>No
                                    Data
                                    Found!!
                        </td>
                            </tr>
                        }

                    </tbody>

                </table>
            </div>
        </>

    )
}

export default InvoiceListing;
