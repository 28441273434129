import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import action from '../../Settings/action';
import tenantAction from '../../Tenants/action'
import LoaderSpinner from "../../Common/components/Loader";
import AddCreditCard from '../../Settings/components/addCreditCard';

import { payInvoice } from "../service";
import toastr from "../../Common/components/Toaster";
import Modal from "../../Common/components/Modal";


const CardSelect = (props) => {

    const [isSubmitted, setIsSubmitted] = useState(true);
    const [id, setId] = useState('');
    const [cardSet, setPrimary] = useState(false)
    const [cardModal, setCardModal] = useState(false);


    useEffect(() => {
        async function renderCall() {
            const { profileDetail: { profileDetail } } = props;
            if (profileDetail) {
                await props.getTenantId(props.id)
                setId(props.id)
            }
        }

        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }, [props.profileDetail.profileDetail]);

    const { card } = props;

    useEffect(() => {
        //For setting primary card
        if (cardSet && !card.success) {
            toastr.error(card.message)
        } else if (cardSet && card.success) {
            toastr.success("Successfully set as primary");
        }
    }, [card]);

    const { tenantReducer: { tenantsById: { tenant } } } = props;

    const handleCard = async (cardDetails, e) => {
        setIsSubmitted(true)
        setPrimary(true)
        if (e.target.checked) {
            const req = {
                tenantId: tenant.tenant._id,
                cardId: cardDetails._id,
                defaultCard: "1"
            }
            await props.addCardDetails(req);
            await props.getTenantId(id)
            setIsSubmitted(false)
            setPrimary(false)
        }


    }

    // const setPrimary = async (cardId) => {
    //     setSubmit(true);
    //     const req = {
    //         tenantId: id,
    //         cardId: cardId,
    //         defaultCard: "1"
    //     }
    //     await props.addCardDetails(req);
    //     await props.getTenantId(id)
    //     setTimeout(() => {
    //         setSubmit(false);
    //     }, 1000)
    // }

    const openModalCard = () => {
        setCardModal(true);

    }

    const closeModalCard = () => {
        if (props.tenatDetailView) {
            setCardModal(false);
            props.onCancel()
        } else setCardModal(false)
    }

    const renderModalCard = () => {
        return <AddCreditCard
            onCancel={closeModalCard}
            id={id}
            tenantView={props.tenatDetailView}
        />
    };

    return (
        <div className="modal  addTenant addInvoiceModal makepayment" id="modalId" style={{ display: 'block' }}>
            {cardModal ? <Modal body={renderModalCard()} onCancel={closeModalCard} /> : null}
            <div className="modal-dialog modal-xs" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {props.tenatDetailView ? "Card Details" : "Make Payment"}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true" onClick={props.onCancel}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="cardMakepay">
                            <div className="cardOtrSection">
                                <ul>
                                    {tenant && tenant.tenant.cardDetails && tenant.tenant.cardDetails.length ? tenant.tenant.cardDetails.map((card, i) => {
                                        return <li>
                                            <div className="customRadioBtn">
                                                <label className="radioBtn">
                                                    <input type="checkbox"
                                                        name={"cardDetails"}
                                                        onChange={(e) => handleCard(card, e)}
                                                        checked={card.defaultCard}
                                                    />
                                                    <span className="radiocheckmark"></span>
                                                </label>
                                            </div>
                                            <span>{card.cardNumber.substring(0, 3)}-xxxx-xxxx-{card.cardNumber.substring(12, 16)}</span>
                                            {props.tenatDetailView ? <i class="fa fa-trash pull-right" onClick={()=>props.handleDeleteCard(card)}></i> : null}
                                        </li>
                                    }) : <li>No Card Details Added!!</li>}

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit newcrd" onClick={openModalCard}>
                            Add New Card
                        </button>
                        {!props.tenatDetailView ? <button type="button" className="btn btn-primary btnSubmit"

                            disabled={tenant && tenant.tenant.cardDetails && !tenant.tenant.cardDetails.length}
                            onClick={() => props.payInvoiceData()}>
                            Pay Now
                        </button> : null}


                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer.User,
        profileDetail: state.profileReducer.profileDetail,
        tenantReducer: state.tenantReducer,
        card: state.settingsReducer.card,

    };
};

export default connect(mapStateToProps, {
    ...action, ...tenantAction
})(CardSelect);
