import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import action from "../../action";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from "moment";
import messageAction from '../../../Messaging/action'
import renderHTML from 'react-render-html';
import { numberWithCommas } from "../../../Common/components/utils";
import Confirm from "../../../Common/components/Confirm";
import Modal from "../../../Common/components/Modal";
import LoaderSpinner from "../../../Common/components/Loader";
import Pagination from "../../../Common/components/pagination";
import parser from 'html-react-parser';
import settingsAction from "../../../Settings/action";
import usePrevious from "../../../Common/components/prevProps";
import { MAIN_ROUTE } from "../../../../config/constants";



const SupportTicket = (props) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [loader, setLoder] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [adminEmail, setEmail] = useState(null);
    const [params, setParams] = useState({
        limit: 5,
        offset: 0,
        id: null
    })


    const deleteNoteDetails = async (id) => {
        setDeleteModal(true)
        setDeleteId(id)
    }

    useEffect(() => {
        let tempParams = params
        tempParams.id = props.maintenanceId
        setParams(tempParams)
        props.viewMaintenanceById(tempParams)
        props.getMailDetails();
    }, [])
    const { settingsReducer: { getMailDetails } } = props;
    const prevMail = usePrevious(getMailDetails);

    // useEffect(() => {
    //     if (!adminEmail && prevMail != getMailDetails) {
    //         if (getMailDetails.settings && getMailDetails.settings.length) {
    //             let maintenanceEmail = null
    //             maintenanceEmail = getMailDetails.settings.find(s => s.emailType == "maintenance")
    //             console.log("maintenanceEmail", maintenanceEmail)
    //             if (maintenanceEmail) setEmail(maintenanceEmail.userName)
    //         }
    //     }
    // }, [prevMail, getMailDetails])

    const renderConfirmModal = () => {
        return <Confirm
            title='Delete '
            message='Do you want to delete this content?'
            onCancel={closeDeleteModal}
            onConfirm={deleteNoteData}
        />
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
    }

    const deleteNoteData = async () => {
        setLoder(true)
        setDeleteModal(false)
        await props.deleteMessage(deleteId)
        await props.viewMaintenanceById(params)
        setLoder(false)
    }

    const renderStatus = (status) => {
        switch (status) {
            case 0:
                return <span className="statusclose">Closed</span>
            case 1:
                return <span className="answered">Open</span>
            case 2:
                return <span className="answered">On Hold</span>
            case 3:
                return <span className="answered">In Progress</span>
            case 4:
                return <span className="inProgress">Answered</span>

        }
    }

    const onPageChanged = async page => {

        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setCurrentPage(page)
        await props.viewMaintenanceById(data);
    }
    let classWrappr = "commentWrapper cmnt"

    const renderStyle = (maintenance) => {
        if (maintenance.type === "note") return classWrappr = "commentWrapper cmnt noteCstm"
        else {
            if (getMailDetails.settings && getMailDetails.settings.length) {
                let maintenanceEmail = null
                maintenanceEmail = getMailDetails.settings.filter(s => s.emailType == "maintenance")
                let isAdmin = maintenanceEmail.length && maintenanceEmail.find(m => m.userName == maintenance.from)
                if (isAdmin) return classWrappr = "commentWrapper cmnt adminRply"
                else return classWrappr = "commentWrapper cmnt commentWrapperYellow"

            }
        }
    }

    return (
        <div className="commenttbleOtr">
            {loader ? <LoaderSpinner /> : null}

            {deleteModal ? <Modal body={renderConfirmModal()} onCancel={closeDeleteModal} /> : null}
            <div className="innago-table table-main p-0 mb-3 ticketTable">

                <div className="table-responsive">
                    <table className="table-gap table-td ">
                        <tbody>
                            <tr className="table-head">
                                <th>Product / Service</th>
                                <th>Amount</th>
                                {/* <th>Billing Cycle</th> */}
                                <th>Created Date</th>
                                {/* <th>Next Due Date</th> */}
                                <th>Status</th>
                            </tr>
                            {/* 
                            <tr>
                                <td >
                                    {props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets && props.maintenanceDetails.maintenanceTickets.title}
                                </td> */}
                            {/* <td>
                                    ${props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets && props.maintenanceDetails.maintenanceTickets.amount ? `${numberWithCommas(props.maintenanceDetails.maintenanceTickets.amount)}` : 0.00}
                                </td> */}
                            {/* <td>
                                    Monthly
                            </td> */}
                            {/* <td>{moment(props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets &&
                                    props.maintenanceDetails.maintenanceTickets.createdAt).format('MM/DD/YYYY')}</td> */}
                            {/* <td>{props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets &&
                                    props.maintenanceDetails.maintenanceTickets.requestDueDate ?

                                    moment(props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets &&
                                        props.maintenanceDetails.maintenanceTickets.requestDueDate).format('MM/DD/YYYY') : 'N/A'}</td> */}
                            {/* <td>{renderStatus(props.maintenanceDetails && props.maintenanceDetails.maintenanceTickets && props.maintenanceDetails.maintenanceTickets.status)}</td>
                            </tr> */}
                            {props.maintenanceDetails && props.maintenanceDetails.invoice &&
                                props.maintenanceDetails.invoice.length ?
                                props.maintenanceDetails.invoice.map((inv, key) => {
                                    if (inv.invoiceTypeId.name != "Security Deposit") {
                                        return <tr key={key}>
                                            <td>{inv.invoiceTypeId ? inv.invoiceTypeId.name : "N/A"}</td>
                                            <td>${inv.amount ? `${numberWithCommas(inv.amount)}` : 0.00}</td>
                                            <td>{inv.startDate ? moment(inv.startDate).format('MM/DD/YYYY') : null}</td>
                                            <td>{inv.status ? "Paid" : "Unpaid"}</td>
                                        </tr>
                                    }
                                }) : <tr>
                                    <td colSpan={4} className={"text-center no_value_table"}><i className="far fa-thumbs-down"></i>No Data Found!!!</td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="commentOtr">
                <div className="table-responsive ">
                    <table className="table msgBox commentWrapper cmnt">
                        <tbody>
                            {props.viewMaintenance.data && props.viewMaintenance.data.messages && props.viewMaintenance.data.messages.length ?
                                props.viewMaintenance.data.messages.map((maintenance, key) => {
                                    renderStyle(maintenance)
                                    let maintenanceEmail = null
                                    maintenanceEmail = getMailDetails && getMailDetails.settings && getMailDetails.settings.length && getMailDetails.settings.filter(s => s.emailType == "maintenance")
                                    let isAdmin = maintenanceEmail && maintenanceEmail.length && maintenanceEmail.find(m => m.userName == maintenance.from)
                                    return (
                                        <tr className={`commentHead ${classWrappr}`} key={key}>

                                            <td className={"shrink-0"}> <div className="commentPerson ">
                                                <span className="letrtxt">{maintenance.from ?
                                                    maintenance.from.charAt(0).toUpperCase()
                                                    : ''}</span>
                                                <div className="personDetail">
                                                    {!isAdmin ? <>{maintenance.tenantId ? <><p>{maintenance.tenantId.firstName + " " + maintenance.tenantId.lastName}</p>
                                                        <span>{maintenance.from}</span></> : <span>{maintenance.from}</span>}</> :
                                                        <> <p>{isAdmin ? "Admin" : null}</p>
                                                            <span>{maintenance.type === "note" ? "Note" : maintenance.from}</span>
                                                        </>}
                                                    <div className={"commentAction"}>
                                                        <div className=" p-0 mt-2 text-right">

                                                            <button className="btn btnEdit "
                                                                onClick={(e) => props.handleEdit(e, maintenance)}>Edit
</button>
                                                            <button className="btn btnDelete"
                                                                onClick={() => deleteNoteDetails(maintenance._id)}>Delete
</button>

                                                        </div>
                                                    </div>

                                                    {/* <span>{props.profileDetail.profileDetail ? props.profileDetail.profileDetail.admin.role : null}</span> */}
                                                </div>
                                            </div></td>
                                            <td className={"mailContent"}>
                                                <div className="commentbody">
                                                    <div className="commentAction">
                                                        <p className="col-md-5 pl-0 mt-2 text-muted">Posted
                                                on {moment(maintenance.createdAt).format('MMM Do YYYY, h:mm A')}
                                                        </p>

                                                    </div>
                                                    <p>{maintenance.message}</p>
                                                    {maintenance.attachments && maintenance.attachments.length ? <div className="row">
                                                        <div className="col-md-12 formInp formSecInp">

                                                            <div className="col-md-12 attachfileOuter msgAttachment">
                                                                {maintenance.attachments.map((attachment, i) => {
                                                                    return (
                                                                        <div
                                                                            className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => window.open(`${MAIN_ROUTE + attachment.path}`, '_blank')}
                                                                            key={i}>
                                                                            <div className="col-md-12 attachFileList">
                                                                                {(attachment.title.split('.').pop() === "jpg" || attachment.title.split('.').pop() === "png" || attachment.title.split('.').pop() === "jpeg")
                                                                                    ? <img
                                                                                        src={MAIN_ROUTE + attachment.path}
                                                                                        alt="" />
                                                                                    : (attachment.title.split('.').pop() === "pdf")
                                                                                        ?
                                                                                        <i className="far fa-file-pdf"></i>
                                                                                        : (attachment.title.split('.').pop() === "doc" || attachment.title.split('.').pop() === "docx")
                                                                                            ?
                                                                                            <i className="far fa-file-alt"></i>
                                                                                            : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.title.split('.').pop())) &&
                                                                                            <i className="far fa-file-video"></i>
                                                                                }
                                                                                {/* {!expenseForm.isView ? <button type="button"
                                                                className="image-close"
                                                                onClick={() => deleteAttachmentEdit(i, attachment)}>
                                                                <span aria-hidden="true">×</span>
                                                            </button> : null} */}
                                                                            </div>
                                                                            <p>{attachment.title}</p>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                        : null}


                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : null}
                        </tbody>
                    </table>
                </div>

                <div className="paginationSec">
                    <ul className="pagination">
                        {props.viewMaintenance.data && props.viewMaintenance.data.messageListCount ?

                            <Pagination
                                totalRecords={props.viewMaintenance.data.messageListCount}
                                pageLimit={params.limit}
                                pageNeighbours={1}
                                currentPage={currentPage}
                                onPageChanged={onPageChanged}
                            />
                            : null}
                    </ul>
                </div>
            </div>

        </div>
    )


}

const mapStateToProps = state => {
    const { maintenanceReducer, profileReducer, settingsReducer } = state;
    return {
        maintenanceReducer,
        profileDetail: profileReducer.profileDetail,
        viewMaintenance: maintenanceReducer.viewMaintenance,
        settingsReducer

    };
};

export default connect(mapStateToProps, {
    ...action, ...messageAction, ...settingsAction
})(SupportTicket);
