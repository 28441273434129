import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { savePDF } from '@progress/kendo-react-pdf';
import parser from 'html-react-parser';



import LeaseFilter from "./components/leaseFilter";
import Pagination from "../Common/components/pagination";
import LeaseList from '../Leases&Files/components/leaseListing'
import action from "./action";
import TenantAction from '../Tenants/action'
import LoaderSpinner from "../Common/components/Loader";
import toastr from '../Common/components/Toaster';
import { MAIN_ROUTE } from "../../config/constants";
import { toast } from "react-toastify";
import commonAction from '../Common/action'
import Modal from '../Common/components/Modal'
import AddTenants from "../Tenants/components/AddTenants";
import LeaseModal from "./components/leaseModal"


class Leases extends React.Component {

    state = {
        setFilter: false,
        params: {
            limit: 15,
            offset: 0,
            property: null,
            unit: null,
            from: null,
            to: null,
            propertyDetails: "",
            status: null,
            orderData: 0,
            order: "",
            sort: null
        },
        currentPage: 1,
        isSubmitted: true,
        submitLease: false,
        isViewModal: false,
        htmlData: null,


    }

    componentDidMount = async () => {
        window.scroll(0, 0)
        await this.props.getLimitData()
        const { limitData } = this.props.commonReducer
        let limitCount = limitData && limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 5)
        let tempParams = this.state.params
        tempParams.limit = limitCount ? limitCount.count : 15
        this.setState({
            params: tempParams
        })
        await this.props.getLease(tempParams)
        setTimeout(() => {
            this.setState({
                isSubmitted: false
            })
        }, 100)
    }

    handleFilter = (e) => {
        e.preventDefault()
        this.setState({
            setFilter: true
        })
    }


    onPageChanged = async page => {
        let data = { ...this.state.params };
        data.offset = ((page - 1) * this.state.params.limit) || 0;
        this.setState({
            ...this.state.params,
            params: data,
            currentPage: page
        })
        await this.props.getLease(data);
    }

    filterData = async (value) => {
        let paramsTemp = { ...value, ...this.state.params }
        this.setState({
            ...this.state.params,
            params: paramsTemp,
        })
        await this.props.getLease(paramsTemp)
        this.setState({
            setFilter: false
        })
    }

    closeFilter = () => {
        this.setState({
            setFilter: false
        })
    }

    viewLease = async (id) => {
        //  console.log("leaseData-->:htmlview",leaseData)


        // let htmlData = leaseData.documentData
        // {console.log("props.tenantReducer-->viewLease", htmlData)}


        // this.setState({
        //     isViewModal:true,
        //     htmlData: htmlData
        // })


        await this.props.getTenantId(id)
        this.setState({
            isSubmitted: true
        })
        await this.props.getTenantProperty(id)

        const { tenantReducer: { tenantsPropertyById: { tenant } } } = this.props;
        if (tenant && tenant.agreementSent) {
            this.setState({
                submitLease: true
            });
            await this.props.viewAgreement({ tenantId: id });
            const { tenantReducer } = this.props;
            if (tenantReducer && tenantReducer.viewLeasePath.lease) {
                window.open(MAIN_ROUTE + tenantReducer.viewLeasePath.lease, '_blank');
                this.setState({
                    submitLease: false
                });
            }
            this.setState({
                isSubmitted: false
            })
        } else {
            this.setState({
                isSubmitted: false
            })
            toastr.error("You have to send the lease agreement first!", {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    renderViewModal = () => {

        return <LeaseModal
            onCancel={this.closeViewModal}
            htmlData={this.state.htmlData}

        />
    }
    closeViewModal = () => {
        this.setState({
            isViewModal: false
        })
    }

    sendLeaseAgreement = async (id) => {
        this.setState({
            isSubmitted: true
        })
        await this.props.leaseAgreement({ tenantId: id });
        const { tenantReducer: { lease } } = this.props;
        await this.props.getLease(this.state.params)
        this.setState({
            isSubmitted: false
        })
        if (lease && lease.success) {
            toastr.success(lease.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            toastr.error(lease.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    handleChange = async (e) => {
        const { name, value } = e.target
        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                [name]: value
            }
        })
    }

    handleChangeDate = (value, name) => {
        let date = moment(value).format("MM/DD/YYYY")
        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                [name]: date
            }
        })
    }

    clearFilterData = async () => {
        this.setState({
            ...this.state,
            params: {
                property: "",
                unit: "",
                from: "",
                to: "",
                status: '',
                propertyDetails: ""
            }

        }, () => this.props.getLease(this.state.params))
    }

    promiseOptionsProperty = async val => {
        await this.props.getProperty({ name: val })
        const Properties = this.props.commonReducer.Property.success && this.props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    handleChangeProperty = async (value) => {
        let tempParams = this.state.params
        tempParams.property = value.value
        // props.applyFilter(tempParams)
        await this.props.getUnitsByPropertyId(value.value)
        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                property: value.value,
                propertyDetails: value
            }
        })
    }

    editLeaseAgreement = () => {

    }

    handleChangeLimit = async (e) => {
        const { name, value } = e.target
        let tempParams = this.state.params
        tempParams.limit = value
        tempParams.offset = 0
        this.setState({
            params: tempParams
        })
        await this.props.updateLimitData({ type: 5, count: value });
        await this.props.getLease(this.state.params);
    }


    filterData = async (value) => {
        let tempParams = this.state.params
        if (value) {
            tempParams.sort = value
            let orderDetails = !this.state.params.orderData
            tempParams.order = orderDetails ? "ASC" : "DESC"
            tempParams.orderData = !this.state.params.orderData
        }
        this.setState({
            params: tempParams
        })
        await this.props.getLease(tempParams)
        // setTimeout(() => {
        //     setIsLoading(false)

        // }, 1000)
    }



    render() {
        return (
            <div className="tenants-otr leasesfilesOuter">
                {this.state.isSubmitted && <LoaderSpinner />}
                {this.state.isViewModal ? <Modal
                    body={this.renderViewModal()}
                    onCancel={this.onCancel} /> : null}
                <div className="innago-table-top main-table-top maint_req">
                    <div className="lft">
                        <h1>LEASES & FILES</h1>
                    </div>
                    <div className="rgt">
                        <button className="btn-dvd red-bg">Unsent Leases<strong>
                            {this.props.leaseReducer.leaseList.leases && this.props.leaseReducer.leaseList.leases.notSentCount ? this.props.leaseReducer.leaseList.leases.notSentCount : 0}
                        </strong></button>
                        <button className="btn-dvd green-bg">Active
                            Leases<strong>
                                {this.props.leaseReducer.leaseList.leases && this.props.leaseReducer.leaseList.leases.isActiveCount ? this.props.leaseReducer.leaseList.leases.isActiveCount : 0}</strong>

                        </button>
                        <button className="btn-dvd orange-bg">Pending
                            Leases<strong>{this.props.leaseReducer.leaseList.leases && this.props.leaseReducer.leaseList.leases.inprocessCount ? this.props.leaseReducer.leaseList.leases.inprocessCount : 0}</strong>
                        </button>

                        <div className="formsectionPadding selTenant pr-2">
                            <div className="selctOtr">
                                {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                                <select className="form-control" value={this.state.params.limit} name={"limit"}
                                    onChange={this.handleChangeLimit}>
                                    <option value={15}>15</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>

                        {/*<button className="export"><img src="/images/export.png"/> Export</button>*/}
                        {/*<button className="add">*/}
                        {/*<i className="fas fa-plus"/> Add New Lease*/}
                        {/*</button>*/}
                        <button className="fltr flrWid" onClick={this.handleFilter}><i className="fas fa-sliders-h" />Filter
                        </button>
                        {this.state.setFilter ?
                            <LeaseFilter filterLease={this.filterData}
                                closeFilter={this.closeFilter}
                                params={this.state.params}
                                handleChange={this.handleChange}
                                handleChangeDate={this.handleChangeDate}
                                promiseOptionsProperty={this.promiseOptionsProperty}
                                handleChangeProperty={this.handleChangeProperty}
                                clearFilterData={this.clearFilterData}

                            /> : null}
                    </div>
                </div>
                <div className="innago-table table-main leasestable invoiceTable">
                    <LeaseList leaseList={this.props.leaseReducer.leaseList} onPageChanged={this.onPageChanged}
                        closeFilter={this.closeFilter}
                        viewLease={this.viewLease}
                        sendLeaseAgreement={this.sendLeaseAgreement}
                        params={this.state.params}
                        filterData={this.filterData}
                        currentPage={this.currentPage} />
                    <div className="paginationSec">
                        {this.props.leaseReducer.leaseList.leases && this.props.leaseReducer.leaseList.leases.leaseCount
                            ? <Pagination
                                totalRecords={this.props.leaseReducer.leaseList.leases.leaseCount}
                                pageLimit={this.state.params.limit}
                                pageNeighbours={1}
                                currentPage={this.state.currentPage}
                                onPageChanged={this.onPageChanged}
                            /> : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { leaseReducer, tenantReducer, commonReducer } = state;
    return {
        leaseReducer,
        tenantReducer,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action,
    ...TenantAction,
    ...commonAction
})(Leases);
