import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format'

const SplitUp = props => {
    const { selectedInvoice, splitupIndex, handleChange, deleteInvoice, splitupError, isView } = props



    return (
        <tr>
            <td>
                <textarea
                    className="descriptionsec"
                    maxLength={100}
                    name='description'
                    onChange={e => handleChange(e, splitupIndex)}
                    // value={parseDescription(desc, splitupIndex)}
                    value={selectedInvoice.description}
                    disabled={isView}
                />
                <span className="errorText">{splitupError[splitupIndex].description}</span>
            </td>
            <td>
                <NumberFormat
                    thousandSeparator={true}
                    prefix={'$'}
                    maxLength={15}
                    value={selectedInvoice.amount}
                    name='amount'
                    disabled={isView}
                    onChange={e => handleChange(e, splitupIndex)}
                    className="amountInput"
                />
                <span className="errorText">{splitupError[splitupIndex].amount}</span>
            </td>
            {!isView ? <td>
                <div
                    onClick={() => {
                        if ((props.spiltupLength != 1)) {
                            deleteInvoice(selectedInvoice, splitupIndex)
                        }
                    }}
                    className="deleteround text-center"
                >
                    <i
                        className="fas fa-minus disableMinusIcon"
                        style={props.spiltupLength == 1 ? { opacity: '0.7', 'cursor': 'no-drop' } : {}}
                    ></i>
                </div>
            </td> : null}
        </tr>
    )
}
function mapStateToProps(state) {
    const { commonReducer, tenantReducer, invoiceReducer, messageReducer } = state;
    return {
        messageReducer,
        invoiceAdd: invoiceReducer.Invoice,
        commonReducer,
        invoices: tenantReducer.invoiceList,
        invoicesTypes: tenantReducer.invoicesTypes,
        invoicePayment: invoiceReducer.invoicePayment
    };
}
export default connect(mapStateToProps, {})(SplitUp)
