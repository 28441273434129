
//get Zillow Data
export const GET_CARD_POSITIONS_REQUEST = "GET_CARD_POSITIONS_REQUEST"
export const GET_CARD_POSITIONS_SUCCESS = "GET_CARD_POSITIONS_SUCCESS"
export const GET_CARD_POSITIONS_FAILURE = "GET_CARD_POSITIONS_FAILURE"

export const UPDATE_CARD_POSITIONS_REQUEST = "UPDATE_CARD_POSITIONS_REQUEST"
export const UPDATE_CARD_POSITIONS_SUCCESS = "UPDATE_CARD_POSITIONS_SUCCESS"
export const UPDATE_CARD_POSITIONS_FAILURE = "UPDATE_CARD_POSITIONS_FAILURE"

export const MAKE_PAYMENT_REQUEST = "MAKE_PAYMENT_REQUEST"
export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS"
export const MAKE_PAYMENT_FAILURE = "MAKE_PAYMENT_FAILURE"