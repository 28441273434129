import React, {useState, useEffect} from "react";
import CreditCardInput from 'react-credit-card-input';
import LoaderSpinner from "../../../";

const DocumentUpload = props => {
    const {state, validate, handleChange, onChangeIncomeStartdate, handleCardNumberChange, handleCardExpiryChange, handleCvvChange, onOpenConfirmation} = props;


    return (
        <>

            <div className="clientInfo">
                {/* {modal ? <Modal body={renderModalConfirm()} onCancel={closeConfirmModal} /> : null} */}
                <h3>Upload the following documents</h3>
                <div className="form-section-dtl">
                    <div className="docOuter">
                        <p>
                            You can upload a photo of the document taken with a camera
                            or a PDF or scanned image.
                        </p>
                        <div className="col-md-12 p-0 mb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="box">
                                        <ul className="mainList">
                                            <li>
                                                Government issued photo-id (e.g. driver's license,
                                                passport. Take pictures with your phone! military
                                                ID, etc.)
                                            </li>
                                            <li>
                                                Proof of income / employment (e.g. paystub, bank
                                                receive an email on your phone. Open statement,
                                                social security, etc.)
                                            </li>
                                            <li>
                                                Any other documents requested by the landlord
                                                phone.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="box d-flex">
                                        <div className="pictureBox">
                                            <h6>Take Pictures with your phone</h6>
                                            <p>
                                                "Upload Documents Later" and receive an email on
                                                your phone. Open the email and click to upload any
                                                photos of documents saved on your phone.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row m-0">
                            <div className="col-md-12 p-0">
                                <div className="documentWrapper">
                                    <label className="uploadDesc" for="inputGroupFile01">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                        <p>Click to Upload Documents</p>
                                    </label>
                                    <input
                                        name="reportDocuments"
                                        onChange={(e) => props.changeDocument(e)}
                                        type="file"
                                        multiple
                                        className="dropzone"
                                        id="inputGroupFile01"
                                    />
                                </div>
                                {state.reportDocuments && state.reportDocuments.map((e, i) => {
                                    return <div className="docView" key={i}>
                                        <img src="/images/unsignedlease.png"/>
                                        <span className="docName">{e.name}</span>
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        <div className="uploadCheck mt-3">
                            <label className="documentCheck"
                            >Upload Documents Later
                                <input type="checkbox"/>
                                <span className="checkmark"></span>
                            </label>

                        </div>
                        <div className="row reminderLine">
                            <div className="col-md-7">
                                <p className="reminderText">
                                    We will send you a reminder email to updated your
                                    documents. Most landlords will not review your
                                    application unit your documents are added
                                </p>
                            </div>
                        </div>
                        <div className="text-center centerText">
                            <p>
                                A secure popup will appear to collect your non-refundable
                                application fee.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentUpload;
