import React from "react";
import action from "../action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"
import LoaderSpinner from "../../Common/components/Loader";
import moment from 'moment';
import ReactBoard from 'react-trello'
import CardDetailModal from "./CardDetailModal"
import Modal from '../../Common/components/Modal';


class BoardContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            showCardModal: false,
            currentCardData: null,
            boardData: {},
            currentLane:{}
        };
    }

    componentDidMount = async () => {
        await this.props.getBoardData()
        await this.setState({
            boardData: this.props.boardReducer.boardData,
            isLoaded: true,
        })
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    componentWillUnmount = () => {
        let modatData = document.getElementById("unitDetailModal")
        modatData && modatData.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = async (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showCardModal: false

            })
        }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }




    onCardClick = (cardData) => {
        const { boardData } = this.state;
        let currentLane = null
        if (boardData && boardData.lanes && boardData.lanes.length) {
            currentLane = boardData.lanes.find(bd => bd.id === cardData.laneId)
        }
        this.setState({
            showCardModal: true,
            currentCardData: cardData,
            currentLane:currentLane
        });
    };

    onCloseCardDetailModal = () => {
        this.setState({
            showCardModal: false
        });
    };

    handleCardDetailsModal = () => {
        const { boardData ,currentLane} = this.state;
      

        return <Modal
            onCancel={this.onCloseCardDetailModal}
            body={
                <CardDetailModal
                    onCancel={this.onCloseCardDetailModal}
                    handleAddCardActivity={this.handleAddCardActivity}
                    cardData={this.state.currentCardData}
                    handelUpdateAllCards={this.handelUpdateAllCards}
                    updateBoardDataFromCardModal={this.updateBoardDataFromCardModal}
                    handleDeleteCard={this.handleDeleteCard}
                    refreshBoardData={this.refreshBoardData}
                    addTenantsToBoard={this.addTenantsToBoard}
                    boardTenants={boardData.boardTenants ? boardData.boardTenants : []}
                    setWrapperRef={this.setWrapperRef}
                    currentLane={currentLane}
                />
            }
        />
    };

    onDataChange = async (data) => {
        await this.setState({
            boardData: {
                lanes: data.lanes,
                boardTenants: this.state.boardData.boardTenants ? this.state.boardData.boardTenants : []
            }
        });
        await this.props.updateBoardData(this.state.boardData)
        return true
    };

    onLaneUpdate = (laneId, laneVal) => {
        const { boardData } = this.state;
        let tempBoardData = [];
        boardData.lanes.map((item, i) => {
            if (item.id === laneId) {
                item.title = laneVal.title;
                item.id = laneVal.title
            }
            tempBoardData.push(item)
        });
        this.setState({
            boardData: {
                ...boardData,
                lanes: tempBoardData
            }
        });
        this.onDataChange(this.state.boardData);
    };

    updateBoardDataFromCardModal = (updatedCardData) => {
        const { boardData } = this.state;
        let tempBoardData = [];
        boardData.lanes.map((laneItem, i) => {
            laneItem.cards.map((cardItem, i) => {
                if (cardItem.id === updatedCardData.id) {
                    Object.assign(cardItem, updatedCardData);
                }
            });
            tempBoardData.push(laneItem)
        });
        this.setState({
            boardData: {
                ...boardData,
                lanes: tempBoardData
            }
        });
        this.onDataChange(this.state.boardData);
    };

    handleAddCardActivity = async (activityData) => {
        await this.props.addCardActivity(activityData);
        await this.props.getCardActivity(activityData.cardId);
    };

    onCardAdd = (cardData, lane) => {
        this.handleAddCardActivity({ cardId: cardData.id, message: `added this card to ${lane}` })
    };
    /*onLaneAdd = (data, val) => {
    };*/
    onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
        this.handleAddCardActivity({ cardId, message: `moved this card from "${fromLaneId}" to "${toLaneId}"` })
    };

    handelUpdateAllCards = async (type, data, processType) => {
        const { boardData } = this.state;
        let tempBoardData = [];
        if (type === 'customField') {
            boardData.lanes.map((laneItem, i) => {
                laneItem.cards.map((cardItem, i) => {
                    if (processType !== 'delete') {
                        if (cardItem.customFields && cardItem.customFields.length) {
                            cardItem.customFields.map(item => {
                                if (item._id === data._id) {
                                    item.name = data.name
                                    item.type = data.type
                                    item.options = data.options
                                }
                            });
                            if (!cardItem.customFields.find(item => item._id === data._id)) {
                                cardItem.customFields.push(data)
                            }
                        } else {
                            cardItem.customFields = [];
                            cardItem.customFields.push(data)
                        }
                    } else {
                        if (cardItem.customFields && cardItem.customFields.length) {
                            var removeIndex = cardItem.customFields.map(function (item) {
                                return item.id;
                            }).indexOf(data);
                            cardItem.customFields.splice(removeIndex, 1);
                        }
                    }
                });
                tempBoardData.push(laneItem)
            });
        }
        if (type === 'label') {
            boardData.lanes.map((laneItem, i) => {
                laneItem.cards.map((cardItem, i) => {
                    if (processType !== 'delete') {
                        if (cardItem.labels && cardItem.labels.length) {
                            cardItem.labels.map(item => {
                                if (item.id === data._id) {
                                    item.name = data.name
                                    item.color = data.color
                                }
                            });
                        }
                    } else {
                        if (cardItem.labels && cardItem.labels.length) {
                            var removeIndex = cardItem.labels.map(function (item) {
                                return item.id;
                            }).indexOf(data);
                            cardItem.labels.splice(removeIndex, 1);
                        }
                    }
                });
                tempBoardData.push(laneItem)
            });
        }
        this.setState({
            boardData: {
                ...boardData,
                lanes: tempBoardData
            }
        });
        this.onDataChange(this.state.boardData);
    };

    refreshBoardData = async () => {
        await this.props.getBoardData()
        await this.setState({
            boardData: this.props.boardReducer.boardData,
            isLoaded: true,
        })
    }

    handleDeleteCard = async (cardId) => {
        const { boardData } = this.state;
        let tempBoardData = boardData;
        tempBoardData.lanes.map((laneItem, i) => {
            laneItem.cards = laneItem.cards.filter((cardItem) => cardItem.id !== cardId)
        });
        await this.setState({
            boardData: tempBoardData
        });
        this.handleAddCardActivity({ cardId, message: `deleted this card` })
        this.onDataChange(this.state.boardData);
    };

    CustomCard = (cardData) => {
        return (
            <li className="board-card" id={"cardData"} onClick={() => this.onCardClick(cardData)}>

                <div className="listCardDetails">
                    <div className="cardlabelwrap">
                        {cardData.labels && cardData.labels.length ?
                            <>
                                {cardData.labels.map((item, i) => <div key={i}
                                    className={`cardLabel colr-${item.color}`} />)}
                            </> : null
                        }
                    </div>
                    <div className="cardId">{cardData.title}</div>
                </div>
                <div className="listCardDetails">
                    <div className="cardIcon">
                        {cardData.tenants && cardData.tenants.length ?
                            <>
                                <div className="comment">
                                    <span className="desc">TENANT NAME :&nbsp;
                                    </span>
                                </div>
                                {cardData.tenants && cardData.tenants.map((item, i) =>
                                    <div className="notificationSec">
                                        <div className='labelMsg tenant-card-list colr-grey'>
                                            <span className="tenant-card-list-item">{item.name.trim().length ? item.name.trim() : '-'}</span>
                                        </div>
                                    </div>
                                )}
                            </> : null
                        }
                        {cardData.comments && cardData.comments.length ?
                            <div className="comment">
                                <span className="icon"><i className="far fa-comment" /></span>
                                <span className="count">{cardData.comments.length}</span>
                            </div> : null
                        }
                        {cardData.attachments && cardData.attachments.length ?
                            <div className="comment">
                                <span className="icon"><i className="fas fa-paperclip" /></span>
                                <span className="count">{cardData.attachments.length}</span>
                            </div> : null
                        }
                        {cardData.dueDate && cardData.dueTime ?
                            <div className="comment">
                                <span className="dueDate"><i
                                    className="far fa-clock" /> {moment(new Date(cardData.dueDate)).format('MMM DD')} at {moment(new Date(cardData.dueTime)).format('hh:mm a')}</span>
                            </div> : null
                        }
                        {cardData.customFields && cardData.customFields.length ?
                            <>
                                {cardData.customFields.map((item, i) => {
                                    return <React.Fragment key={i}>
                                        {item.value ?
                                            item.type === 'Dropdown' ?
                                                <>{item.value !== "0" ? <div className="notificationSec">
                                                    <div className={`labelMsg colr-${item.backgroundColor || 'grey'}`}>
                                                        <span className="">{item.name} : {item.value || ''}</span>
                                                    </div>
                                                </div> : null}</>
                                                : item.type === 'Checkbox'
                                                    ?
                                                    <div className="comment">
                                                        <div className="timeSec ml-0">
                                                            <span><i className="far fa-check-circle" />&nbsp;</span>
                                                            <span className="cfpText">{item.name}</span>
                                                        </div>
                                                    </div>
                                                    : item.type === 'Date'
                                                        ? <div className="comment">
                                                            <span
                                                                className="desc">{item.name} : {moment(new Date(item.value)).format('MMM DD, YYYY')}</span>
                                                        </div>
                                                        : <div className="comment">
                                                            <span className="desc">{item.name} : {item.value}</span>
                                                        </div>
                                            : null
                                        }
                                    </React.Fragment>
                                })}
                            </> : null
                        }
                    </div>
                    {/*{cardData.tenants && cardData.tenants.length ?
                        <div className="d-flex cardFlex">
                            <div className="memberSec">
                                {cardData.tenants && cardData.tenants.map((item, i) => <span key={i}
                                                                                             className="greyBg">{item.name.trim().length ? item.name.charAt(0).toUpperCase() : '-' }</span>)}
                            </div>
                        </div> : null
                    }*/}
                </div>
            </li>
        )
    };

    addTenantsToBoard = async (newTenantData) => {
        const { boardData } = this.state;
        let tempBoardTenants = boardData.boardTenants ? [newTenantData, ...boardData.boardTenants] : [newTenantData];
        this.setState({
            boardData: {
                ...boardData,
                boardTenants: tempBoardTenants
            }
        });
        await this.onDataChange(this.state.boardData);
    };

    handleModalClose = (e) => {
    }

    render() {
        const { showCardModal, isLoaded } = this.state;
        if (!isLoaded) {
            // return <LoaderSpinner />
            return null
        }
        const { boardData } = this.state;
        return (
            <>
                <div className="boardWrapper" id={"boardWrapper"} onClick={(e) => this.handleModalClose(e)}>
                    <div className="boardCanvas boardOuter">
                        <ReactBoard
                            data={boardData || { lanes: [] }}
                            draggable
                            onDataChange={this.onDataChange}
                            onCardDelete={this.onCardDelete}
                            onCardAdd={this.onCardAdd}
                            onCardClick={this.onCardClick}
                            editable
                            canAddLanes
                            onLaneAdd={this.onLaneAdd}
                            onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
                            editLaneTitle
                            onLaneUpdate={this.onLaneUpdate}
                            tagStyle={{ fontSize: '80%', padding: "5px 15px" }}
                            cardDragClass="draggingCard"
                            laneDragClass="draggingLane"
                            components={{ Card: this.CustomCard }}
                        />
                    </div>
                </div>
                {showCardModal && this.handleCardDetailsModal()}
            </>
        );
    }
}


const mapStateToProps = state => {
    const { boardReducer } = state;
    return { boardReducer }
};

export default withRouter(connect(mapStateToProps, { ...action })(BoardContainer));