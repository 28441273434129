import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import action from "../../action";
import commonAction from "../../../Common/action";
import tenantAction from "../../../Tenants/action";

const AdvancedSearch= (props) => {
    return(
        <div className="row">
            <div className="col-md-12 categ-sub-one p-0">
                <div className="boxSec">
                    <div className="row">
                        <div className="col-md-12 listing issue-section-outer">
                            <div className="listing-issue-outer maintenance-search">
                                <div className="formSection">
                                    <div className="formInp">
                                        <label>Find The Category</label>
                                        <input type="text" className="form-control" placeholder="type the category name here.." />
                                    </div>
                                </div>
                                <div className="col-md-12 fail-box">
                                    <img src="/images/toolbox.png" />
                                    <h4>OTHER</h4>
                                    <p>Unfortunately, maintenance categories are not custom.
                                        <br/>Contact customer support to suggest a category. Thank you :)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer, tenantReducer } = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        categoryMaintenance: maintenanceReducer.categoryMaintenance,
        add: maintenanceReducer.maintenanceAdd,
        commonReducer,
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...tenantAction
})(AdvancedSearch);