import React from "react";
import {connect} from "react-redux";

import AsyncSelect from 'react-select/async';
import tenantAction from '../../Tenants/action';
import commonAction from '../../Common/action'


const InvoiceFilter = (props) => {

    const {tenants} = props.tenants;

    const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
        value: e._id,
        label: e.firstName + " " + e.lastName
    }));

    const promiseOptionsTenant = async val => {
        await props.getTenant({name: val, isDeleted: false,isClosed:false})
        const tenantList = tenants.tenantList && tenants.tenantList.length && tenants.tenantList.map(e => ({
            value: e._id,
            label: e.firstName + " " + e.lastName
        }));
        return tenantList.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }

    const Properties = props.Property.success && props.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));

    const promiseOptionsProperty = async val => {
        await props.getProperty({name: val})
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }


    return (
        <>
            <div className="innago-table-top main-table-top">
                <div className="lft">
                    <h1>INVOICE DETAILS</h1>
                </div>
            </div>
            <div className="formSection">
                <div className="row outrForm m-0 p-0">
                    <div className="col-md-12 p-0">
                        <div className="d-flex formInp custon-flex">
                            <div className="col-md-3 formsectionPadding">
                                {/*<div className="selctOtr">*/}
                                {/*<select className="form-control" name={"propertyId"}*/}
                                {/*value={props.params.propertyId}*/}
                                {/*onChange={props.handleChange}>*/}
                                {/*<option>Select Property</option>*/}
                                {/*/!*{Properties && Properties.length ? Properties.map((property, key) => {*!/*/}
                                {/*/!*return <option value={property.value}*!/*/}
                                {/*/!*key={key}>{property.label}</option>*!/*/}
                                {/*/!*}) : null}*!/*/}
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions={Properties}
                                    loadOptions={promiseOptionsProperty}
                                    name="tenantId"
                                    value={props.params.propertyDetails}
                                    onChange={props.handleChangeProperty}
                                    placeholder={"Search Property"}
                                />
                                {/*</select>*/}
                                {/*</div>*/}
                            </div>
                            <div className="col-md-3 formsectionPadding">
                                <div className="selctOtr">
                                    <select className="form-control" name={"unit"}
                                            value={props.params.unit}
                                            onChange={props.handleChange}>
                                        <option>Select Unit</option>
                                        {props.unit && props.unit.unitList && props.unit.unitList.length ? props.unit.unitList.map((units, key) => {
                                            return <option value={units._id}>{units.unitName}</option>
                                        }) : null}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3 formsectionPadding">

                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions={tenantList}
                                    loadOptions={promiseOptionsTenant}
                                    name="tenantId"
                                    onChange={props.handleChangeTenant}
                                    value={props.params.tenantDetails}
                                    placeholder={"Search Tenants"}

                                />
                            </div>
                            <div className="col-md-3 formsectionPadding btn-form-padding">
                                <button className="submit-btn"
                                        onClick={props.filterData}
                                        disabled={!props.params.tenantDetails && !props.params.unit && !props.params.propertyDetails}
                                > Submit
                                </button>
                                <button className="submit-btn clr" onClick={props.filterData}
                                        disabled={!props.params.tenantDetails && !props.params.unit && !props.params.propertyDetails}
                                        onClick={props.clearFilter}> Clear
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    const {tenantReducer, commonReducer} = state;
    return {
        tenantReducer,
        commonReducer
    };
}

export default connect(mapStateToProps, {...tenantAction, ...commonAction})(InvoiceFilter);

