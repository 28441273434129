import React, {useState, useEffect} from "react";
import action from "../action";
import {connect} from "react-redux";
import commonAction from '../../Common/action';
import TenantTopSection from './TenantTopSection1';
import TenantDetailListing from './TenantDetailListing';
import history from '../../../config/history';
import Summary from "./Summary/Summary";
import Invoice from "./Invoice";
import Emails from './Email';
import ViewNotice from "./Invoice/viewNotice";
import toastr from '../../Common/components/Toaster'
import LoaderSpinner from "../../Common/components/Loader";
import Modal from '../../Common/components/Modal';
import Contacts from './Contacts/index'

const TenantDetail = (props) => {
    const [tenantId, setTenantId] = useState(null)
    const [viewNoticeClicked, setViewNoticeClicked] = useState(false)
    const [viewNoticeModal, setViewNoticeModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    const {match: {params: {id, section}}} = props;

    useEffect(() => {
        setTenantId(id);
    }, []);

    useEffect(() => {
        handleTabs(section);
        setTenantId(id);
        props.getTenantId(id)
        setIsLoading(false)

        renderComponent()
    }, [id]);

    useEffect(() => {
        if (viewNoticeClicked && props.tenantReducer.viewNotice.success) {
            // setViewNoticeModal(true);
        }
    }, [viewNoticeClicked])

    useEffect(() => {

        const {tenantReducer: {sendLobMailResponse}} = props;
        if (isLoading && sendLobMailResponse.success) {
            toastr.success(sendLobMailResponse.message)
            setIsLoading(false)

        }
    }, [props.tenantReducer.sendLobMailResponse])

    const handleTabs = (tab) => {
        if (!id) return false;
        switch (tab) {
            case 'Summary':
                history.push(`/Tenants/${id}/Summary`);
                break;
            case 'Profile':
                history.push(`/Tenants/${id}/Profile`);
                break;
            case 'Invoices':
                history.push(`/Tenants/${id}/Invoices`);
                break;
            case 'Email':
                history.push(`/Tenants/${id}/Email`);
                break;
            case 'Contacts':
                history.push(`/Tenants/${id}/Contacts`);
                break;
        }
    }

    const {tenantReducer: {tenantsPropertyById: {tenant}, tenantsById: {tenant: tenantById, lease}}} = props;

    const renderComponent = () => {
        if (!tenantId) return false;
        switch (section) {
            case 'Summary':
                return <div className="tab-content" id="pills-tabContent"><Summary id={tenantId}   
                                                                                   onChangePerson={onChangePerson}/>
                </div>
                break;
            case 'Invoices':
                return <div className="tab-content" id="pills-tabContent"><Invoice id={tenantId} {...props} tenantView={true}/></div>
                break;
            case 'Email':
                return <div className="tab-content" id="pills-tabContent"><Emails id={tenantId}/></div>
                break;
            case 'Contacts':
                return <div className="tab-content" id="pills-tabContent"><Contacts id={tenantId}/></div>
                break;
        }
    }

    const onChangePerson = (id) => {
        setTenantId(id);
    }

    const sendNoticeHtmlView = async () => {
        setViewNoticeClicked(true);
        await props.viewNotice({tenantId: id})
        setViewNoticeModal(true)
        setTimeout(() =>

                setViewNoticeClicked(false),
            100);
    }

    const closeviewNoticeModal = () => {
        setViewNoticeModal(false)
    }

    const renderviewNoticeModal = () => {
        return <ViewNotice
            sendLobMail={sendLobMail}
            onCancel={closeviewNoticeModal}
            notice={props.tenantReducer.viewNotice.template && props.tenantReducer.viewNotice.template}
        />
    }

    const sendLobMail = async () => {
        setIsLoading(true);
        const params = {tenantId: id}
        props.sendLobMail(params);
        setViewNoticeModal(false)
    }

    return (
        <>
            <div className="tenants-otr">
                {(isLoading) && <LoaderSpinner/>}
                {viewNoticeModal ? <Modal body={renderviewNoticeModal()} onCancel={closeviewNoticeModal}/> : null}
                <TenantTopSection tenantDetail={true} tenantId={tenantId} onChangePerson={onChangePerson} {...props}/>
                <div className="tenantDtlOutr">
                    <div className="col-md-12 tenantDtl p-0">
                        {/*<TenantDetailListing id={id} section={section} onChangePerson={onChangePerson} />*/}

                        <div className="tenantDtlright">
                            <div className="nav nav-pills" id="pills-tab" role="tablist">
                                <a
                                    className={section === "Summary" ? "nav-item nav-link active" : "nav-item nav-link"}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => handleTabs('Summary')}
                                    aria-selected="true">
                                    Summary
                                </a>
                                {tenantById && tenantById.tenant && !tenantById.tenant.isClosed ?
                                    <a
                                        className={section === "Invoices" ? "nav-item nav-link active" : "nav-item nav-link"}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => handleTabs('Invoices')}
                                        aria-selected="true">
                                        Invoices
                                    </a> : null}
                                {tenantById && tenantById.tenant && !tenantById.tenant.isClosed ?
                                    <a
                                        className={section === "Email" ? "nav-item nav-link active" : "nav-item nav-link"}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => handleTabs('Email')}
                                        aria-selected="true">
                                        Support Ticket
                                    </a> : null}
                                    {tenantById && tenantById.tenant && !tenantById.tenant.isClosed ?
                                    <a
                                        className={section === "Contacts" ? "nav-item nav-link active" : "nav-item nav-link"}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => handleTabs('Contacts')}
                                        aria-selected="true">
                                        Contacts
                                    </a> : null}
                                {/*<a
                                    className={section === "Log" ? "nav-item nav-link active" : "nav-item nav-link"}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleTabs('Log')}
                                    aria-selected="true">
                                    Log
                                </a>*/}
                                {/* {
                                <div className="sendNoticeTenant">
                                    <button className="btn noticeSend" onClick={()=>sendNoticeHtmlView()}>
                                        <i className="fas fa-envelope"></i>&nbsp;Send Notice
                                    </button>
                                </div>} */}
                            </div>

                            {renderComponent()}
                            {
                                !tenantId &&
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-summary" role="tabpanel">
                                            <div className="brdOut">
                                                <div className="clientNovalue">
                                                    <div className="outerTenant no_value"><i
                                                        className="far fa-thumbs-down"></i>
                                                        There is no data to show you right now!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = state => {
    const {tenantReducer, commonReducer} = state;
    return {
        tenantReducer,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(TenantDetail);