import React, {useState, useEffect} from "react";
import {withRouter} from 'react-router-dom';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';

import usePrevious from "../../../Common/components/prevProps";
import {connect} from "react-redux";
import commonAction from "../../../Common/action";
import action from "../../../Properties/action";
import {MAIN_ROUTE} from "../../../../config/constants";
import {numberWithCommas} from "../../../Common/components/utils";
import history from "../../../../config/history";


const Property = props => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const prevUser = usePrevious(props.profileReducer.profileDetail);

    useEffect(() => {
        props.getTenantPropertyDetails()
    }, [])

    const {propertyDetailsByTenant} = props;

    const squareFeetFormat = (x) => {
        if (x == null || x === undefined) return '0'
        let num = x.toString()

        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num
    }

    const getDefaultImage = (property) => {
        // src={(property.propertyImage[0]) ? parseBackgoundImage(property.propertyImage) : "/images/def_property.png"}
        let defImg = [];
        if (property &&
            property.propertyImage && property.propertyImage.length &&
            property.propertyImage.filter(img => img.isDefault) &&
            property.propertyImage.filter(img => img.isDefault).length
        ) {
            defImg = property.propertyImage.filter(img => img.isDefault);
        } else if (property.propertyImage[0]) {
            defImg = property.propertyImage[0]
        }
        if (!Array.isArray(defImg)) {
            defImg = [defImg]
        }
        if (defImg && defImg[0]) {
            return parseBackgoundImage(defImg)
        } else {
            return "/images/def_property.png"
        }
    }

    const parseBackgoundImage = (image) => {
        if (image.length) {
            if (image[0] && image[0].type && image[0].type == "zillo") {
                return image[0].path
            } else if (image[0]) {
                return MAIN_ROUTE + image[0].path
            } else {
                return '/images/def_property.png'
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }

    const handleViewMore = () => {
        history.push('/Properties/All')
    }

    return (
        <div draggable={props.draggable}
             onDragStart={props.onDragStart()}
             onDragOver={props.onDragOver()}
             onDrop={props.onDrop()}
             id={props.idx}
             className="drpbl col-lg-7 col-md-6 col-sm-12 tableProperty mb-3">
            <div className="tableOtr TenantDashOtr">
                <div className="headerSection d-flex">
                    <h3>Property</h3>
                    <div className="viewMore" onClick={()=>handleViewMore()}>
                        <img src="/images/more.png"/><span>View More</span>
                    </div>
                </div>
                <div className="propDtlSec">
                    <div className="col-md-3 propImg">
                        <img src={
                            propertyDetailsByTenant &&
                            propertyDetailsByTenant.property &&
                            propertyDetailsByTenant.property.propertyId
                                ? getDefaultImage(propertyDetailsByTenant.property.propertyId)
                                : null
                        }/>
                    </div>
                    <div className="col-md-9 propDetails">
                        <div className="headerProp">
                            <h3>
                                {propertyDetailsByTenant && propertyDetailsByTenant.property &&
                                propertyDetailsByTenant.property.propertyId ? propertyDetailsByTenant.property.propertyId.address : null}
                            </h3>
                            {/*<h4>*/}
                            {/*1312 Dock Street*/}
                            {/*</h4>*/}
                        </div>
                        <div className="col-md-12 PropdtlCont">
                            <div className="col-md-4 contentOtr">
                                <div className="contentNum col-md-12">
                                    <div className="col-md-3 imgPropDtl">
                                        <img src="/images/Homee.png"/>
                                    </div>
                                    <div className="col-md-9 contPropDtl">
                                        <h4>Square Feet </h4>
                                        <p className="totdtl"> {propertyDetailsByTenant && propertyDetailsByTenant.property &&
                                        propertyDetailsByTenant.property.propertyId ? squareFeetFormat(propertyDetailsByTenant.property.unitId.squareFeet) : null}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 contentOtr">
                                <div className="contentNum col-md-12">
                                    <div className="col-md-3 imgPropDtl">
                                        <img src="/images/calender.png"/>
                                    </div>
                                    <div className="col-md-9 contPropDtl">
                                        <h4>Lease Start Date </h4>
                                        <p className="totdtl">{propertyDetailsByTenant && propertyDetailsByTenant.property &&
                                        propertyDetailsByTenant.property.propertyId ? moment(propertyDetailsByTenant.property.leaseId.startDate).format('MM/DD/YYYY') : null}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 contentOtr">
                                <div className="contentNum col-md-12">
                                    <div className="col-md-3 imgPropDtl">
                                        <img src="/images/calender.png"/>
                                    </div>
                                    <div className="col-md-9 contPropDtl">
                                        <h4>Lease End Date</h4>
                                        <p className="totdtl">{propertyDetailsByTenant && propertyDetailsByTenant.property &&
                                        propertyDetailsByTenant.property.propertyId ? moment(propertyDetailsByTenant.property.leaseId.endDate).format('MM/DD/YYYY') : null}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 contentOtr">
                                <div className="contentNum col-md-12">
                                    <div className="col-md-2 imgPropDtl depo">
                                        <img src="/images/Deposit.png"/>
                                    </div>
                                    <div className="col-md-10 contPropDtl">
                                        <h4>Deposit</h4>
                                        <p className="totdtl">${propertyDetailsByTenant && propertyDetailsByTenant.property &&
                                        propertyDetailsByTenant.property.propertyId ? numberWithCommas(propertyDetailsByTenant.property.unitId.deposit) : null}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 contentOtr monthRent">
                                <div className="contentNum col-md-12">
                                    <div className="monthImg">
                                        <img src="/images/Monthly-Rentt.png"/>
                                    </div>
                                    <div className="contPropDtl">
                                        <h4>Monthly Rent</h4>
                                        <p className="totdtl">${propertyDetailsByTenant && propertyDetailsByTenant.property &&
                                        propertyDetailsByTenant.property.propertyId ? numberWithCommas(propertyDetailsByTenant.property.unitId.monthlyRent) : null}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {commonReducer, profileReducer} = state;
    return {
        propertyDetailsByTenant: commonReducer.propertyDetailsByTenant,
        profileReducer
    };
}

export default withRouter(connect(mapStateToProps, {...commonAction, ...action})(Property));
