import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import AsyncSelect from 'react-select/async';

const FilterTenant = (props) => {

    return (
        <div className="filterSide" style={{ display: 'block' }}>
            <button type="button" class="close" onClick={props.handleFilter}><span aria-hidden="true">×</span></button>
            <div className="formSection outerBg">
                <div className="col-md-12 row m-0 p-0 fltrOtr">
                    <div className="col-md-4 formInp">
                        <label>Name</label>
                        <input  
                            type="text"
                            className="form-control"
                            placeholder="Tenant Name"
                            name={props.tenantName}
                            onChange={props.handleChange}
                            value={props.tenantName}
                        />
                    </div>
                    <div className="col-md-5 formInp">
                        <label>Property</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={props.Properties}
                            placeholder={"Search Property"}
                            loadOptions={props.promiseOptionsProperty}
                            name="propertyId"
                            onChange={props.onChangeProperty}
                            value={props.propertyId && props.propertyId}

                        />
                    </div>
                    <div className="col-md-3 formInp">

                        <label>Unit</label>

                        <div className="selOtr">
                            {/* {props.InvoiceTypeList && unitDetails.unitList ? */}
                            <select className="form-control"
                                name="unitId"
                                onChange={(e) => props.onChange(e)}
                                value={props.unitId}
                            >
                                <option value={""}>Select Unit</option>
                                {
                                    props.Units && props.Units.map((e, i) => {
                                        return <option value={e.value} key={i}>{e.label}</option>
                                    })
                                }
                            </select>
                            {/* :
                                <i className="fa fa-spinner fa-spin"></i>
                            } */}
                        </div>

                    </div>
                </div>
                <div className="col-md-12 otrTenantCheck d-flex">
                    <div className="col-md-6 tenantStatus d-flex pl-0 ml-0">
                        <div className="formInp selOtr">
                            <label>Invoice</label>
                            <div className="selOtr">
                                <select className="form-control"
                                    name="invoiceTypeId"
                                    onChange={(e) => props.onChange(e)}
                                    value={props.invoiceTypeId}
                                >
                                    <option value={""}>Select Invoice Types</option>
                                    {
                                        props.InvoiceTypeList && props.InvoiceTypeList.map((e, i) => {
                                            return <option value={e._id} key={i}>{e.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 tenantStatus accStatus d-flex">
                        <h3>Account Status</h3>
                        <ul className="tenantstOtr">
                            <li className="formInp">
                                <label className="containerCheck">Verified
                                    <input type="checkbox" value={1}
                                        onChange={(e) => onAccountStatusChange(e)} />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                            <li className="formInp">
                                <label className="containerCheck">Unverified
                                    <input type="checkbox" value={0}
                                        onChange={(e) => onAccountStatusChange(e)} />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                            <li className="formInp">
                                <label className="containerCheck">Show Pending Lease Tenants
                                    <input type="checkbox" value={2}
                                        onChange={(e) => onAccountStatusChange(e)} />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div> */}
                </div>
                <div className="btnOtr text-right">
                    <button type="button" className="btn btn-primary btnClear" onClick={() => props.clearFilter()}>Clear</button>
                    <button type="button" className="btn btn-primary btnApply" onClick={() => props.applyFilter()}>Apply</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer } = state;
    return {
        tenantReducer,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(FilterTenant);
