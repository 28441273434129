import React, { useState, useEffect } from "react";
import Modal from '../../Common/components/Modal';
import history from "../../../config/history";
import AddTenants from './AddTenants';
import Confirm from '../../Common/components/Confirm';
import settingsAction from '../../Settings/action'
import action from "../action";
import { connect } from "react-redux";
import toastr from '../../Common/components/Toaster'
import LoaderSpinner from "../../Common/components/Loader";
import usePrevious from "../../Common/components/prevProps";
import TenantDetailLease from './TenantDetailLease';
import { numberWithCommas, formatUsPhone } from "../../Common/components/utils";
import ConfirmLease from './ConfirmLease';
import TemplateListing from './templateListModal';
import LeaseEdit from './leaseEditModal';
import SignLease from "../../Leases&Files/components/signModal";
import commonAction from "../../Common/action"


const TenantListing = (props) => {

    const { tenants } = props;
    const [tenantModal, setTenantModal] = useState(false);
    const [id, setId] = useState('');
    const [modal, setModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [tenantId, setTenantId] = useState('');
    const [submitLease, setSubmitLease] = useState(false);
    const [viewLeaseAgreement, setViewLeaseAgreement] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [deleteSubmitted, setDeleteSubmitted] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [templateData, setTemplateData] = useState(null)
    const [oldId, setOldId] = useState(null)
    const [isButtonDisabled, setIsButton] = useState(false);
    const [isLeaseEdit, setIsLeaseEdit] = useState(false);
    const [isSign, setIsSign] = useState(false);
    const [leaseSign, setLeaseSign] = useState(null);
    const [leaseSignModal, setLeaseSignModal] = useState(null);
    const [message, setMessage] = useState(null);
    const [addTenant, setAddTennat] = useState(false);




    const { tenantReducer: { tenants: tenant, lease } } = props;


    const prevUser = usePrevious(tenant);
    const prevLease = usePrevious(lease);
    const prevTemplateEdit = usePrevious(props.tenantReducer.leaseEdit);
    const { commonReducer: { emailCheck } } = props;
    const prevEmailCheck = usePrevious(emailCheck);


    useEffect(() => {
        props.getTemplates()
    }, [])

    useEffect(() => {
        if (deleteSubmitted && !tenant.success) {
            toastr.error(tenant.message)
        } else if (deleteSubmitted && tenant.success && (prevUser.tenantList && prevUser.tenantList) != (tenant.tenantList && tenant.tenantList)) {
            toastr.success("Successfully deleted");
        }
    }, [prevUser, tenant]);

    useEffect(() => {
        if (submitLease && !lease.success) {
            toastr.error(lease.message)
        } else if (submitLease && lease.success && prevLease != lease) {
            toastr.success(lease.message);
            setIsSign(true)
            setLeaseSign(lease.lease)

        }
    }, [prevLease, lease]);

    useEffect(() => {
        console.log("addTenant".addTenant)

        if (addTenant && !emailCheck.success) {
            toastr.error("Please add a notification email!")
        } else if (addTenant && emailCheck.success && prevEmailCheck != emailCheck) {
            setModal(false);
            setSubmitLease(true);
            const { settingsReducer: { templateDetails } } = props;
            if (templateDetails && templateDetails.length === 1) {
                setTemplateData(templateDetails[0])
                setTemplateId(templateDetails[0]._id)
                setIsSubmitted(true)
                props.editLeaseAgreement({ tenantId: tenantId, templateId: templateDetails[0]._id });
                setSubmitLease(false);
                setSubmitLease(true)
            }
            else {
                setTemplateModal(true)
                setIsSubmitted(false)
                setSubmitLease(false);
                setAddTennat(false)
            }

        }
    }, [prevEmailCheck, emailCheck]);

    useEffect(() => {
        if (isSubmitted && prevTemplateEdit != props.tenantReducer.leaseEdit) {
            if (props.tenantReducer.leaseEdit.success) {
                setIsLeaseEdit(true)
            }
            else {
                toastr.error("Error while adding lease")
            }
        }
    }, [prevTemplateEdit, props.tenantReducer.leaseEdit])

    const tenantStatus = (status) => {
        switch (status) {
            case 0:
                return 'Archieved'//Archieved
                break;
            case 1:
                return 'Active'
                break;
            case 2:
                return 'Inactive'//Future
                break;
            case 3:
                return 'Inactive'//Past
                break;
        }
    }

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Tenant'
            message='Do you want to delete this tenant account ?'
            onCancel={closeDeleteModal}
            onConfirm={onDelete}
        />
    }

    const onDelete = async () => {
        setDeleteSubmitted(true);
        closeDeleteModal();
        await props.deleteTenant(deleteId);
        setTimeout(() => {
            setDeleteSubmitted(false);
            props.getTenant(props.params);
        }, 500);
    }


    const onOpenModal = async (id) => {
        setId(id);
        await props.getTenantId(id);
        setViewLeaseAgreement(false);
        setTenantModal(true);
        setId(id);
        setEditClicked(false);
    }


    const onCloseModal = () => {
        setTenantModal(false);
    }

    const renderModal = () => {
        if (tenantDetail)
            return <AddTenants
                onCancel={onCloseModal}
                editId={id}
                leaseModal={leaseModal}
                tenantDetail={tenantDetail && tenantDetail}
                params={props.params}
            />
    }

    const leaseModal = (id, tenant) => {
        const { settingsReducer: { templateDetails } } = props;
        if (templateDetails && templateDetails.length) {
            if (tenant.templateId) {
                setOldId(tenant.templateId)
                setTemplateId(tenant.templateId)
                setModal(true);
                setTenantId(id);
            }
            else {
                setIsButton(true)
                if (templateDetails.length === 1) {
                    setModal(true);
                    setTemplateData(templateDetails[0])
                    setTemplateId(templateDetails[0]._id)
                }
                else {
                    setModal(true);
                }
                setTenantId(id);
            }

        }
        else {
            toastr.error("Please add a template!!")
        }

    }

    const closeModalTemplate = () => {
        setTemplateModal(false)
        setIsButton(false)
        setIsSubmitted(false)
        setIsSubmitted(false)
        setAddTennat(false)
        // setTemplateId(null)
    }

    const renderTemplateList = () => {
        const { settingsReducer: { templateDetails } } = props;
        return <TemplateListing
            onCancel={closeModalTemplate}
            templateDetails={templateDetails}
            submitTemplate={submitTemplate}
            handleChangeTemplate={handleChangeTemplate}
            templateId={templateId}

        />
    };

    const submitTemplate = async () => {
        setAddTennat(false)
        if (templateId) {
            setModal(false)
            setTemplateModal(false)
            setAddTennat(false)
            setSubmitLease(true)
            setIsSubmitted(true)
            await props.editLeaseAgreement({ tenantId: tenantId, templateId: templateId });
            setIsSubmitted(false)
            await props.getTenant(props.params);
            setTimeout(() => {
                setViewLeaseAgreement(false);
                setSubmitLease(false);
                // setTemplateId(null)
                setIsButton(false)
            }, 100);
        }
        else {
            toastr.error("Please choose a template")
        }
    }

    const handleChangeTemplate = (e, details) => {

        if (e.target.checked) {
            setTemplateId(details._id)
            setTemplateData(details)
        }
        else {
            setTemplateId(null)
            setTemplateData(null)
        }
    }

    const closeModalLeaseEdit = () => {
        setIsLeaseEdit(false);
        setIsButton(false)

    }

    const renderModalLeaseEdit = () => {

        return <LeaseEdit
            templateData={props.tenantReducer.leaseEdit}
            onCancel={closeModalLeaseEdit}
            addEditedLeaseData={addEditedLeaseData}
            editId={props.tenantReducer.leaseEdit._id}
        />
    }


    const addEditedLeaseData = async (data) => {
        setSubmitLease(true);
        setIsLeaseEdit(false);
        await props.sendLeaseAgreement(data, { templateId, tenantId })
        await props.getTenant(props.params);
        setSubmitLease(false);
        setIsSubmitted(false);

    }

    const closeModal = () => {
        setModal(false);
        setIsButton(false)

    }

    const renderModalLease = () => {
        return <ConfirmLease
            title='Lease Agreement'
            message='Do you want to send lease agreement?'
            isButtonDisabled={isButtonDisabled}
            close={closeModal}
            onConfirm={leaseAgreement}
            onView={viewAgreement}
            edit={true}
        />
    }

    const leaseAgreement = async () => {
        setAddTennat(true)
        await props.emailCheck({ emailType: "notification" })



    }

    const viewAgreement = async () => {
        setSubmitLease(true);
        setModal(false);
        await props.viewLeaseAgreement({ tenantId: tenantId, templateId: oldId });
        setTimeout(() => {
            setSubmitLease(false);
            setViewLeaseAgreement(true);
        }, 100);
    }

    const closeViewLeaseModal = () => {
        setViewLeaseAgreement(false);
    }

    const renderViewLeaseModal = () => {

        return <TenantDetailLease
            tenantId={tenantId}
            onCancel={closeViewLeaseModal}
            // lease={props.tenantReducer.lease}
            lease={props.tenantReducer.viewLease}
            leaseAgreement={leaseAgreement}
            closeModal={closeModal}
            onOpenModal={onOpenModal}
        />
    }



    const renderSignConfirm = () => {
        return <Confirm
            title='Sign Lease'
            message='Do you want to sign now?'
            onCancel={closeSignConfirm}
            onConfirm={signLease}
        />
    }

    const closeSignConfirm = () => {
        setIsSign(false)
    }

    const signLease = () => {
        setIsSign(false)
        setLeaseSignModal(true)
    }

    const closeSignLease = async () => {
        setLeaseSignModal(false)
        await props.getTenant(props.params);
    }

    const renderSignLease = () => {
        return <SignLease
            onCancel={closeSignLease}
            lease={leaseSign.documentData}
            leaseId={leaseSign._id}
        />
    }





    const { tenantReducer: { tenantsById: { tenant: tenantDetail } } } = props;


    return (
        <div className={`table-responsive tenantsTable 
        ${ tenants && Object.values(tenants).length ? " " : "tab-arw"}`}>
            {/* <ToastContainer /> */}
            {(editClicked || deleteSubmitted) && <LoaderSpinner />}
            {isSubmitted && <LoaderSpinner />}
            {submitLease && <LoaderSpinner />}
            {tenantModal && tenantDetail ? <Modal body={renderModal()} onCancel={onCloseModal} /> : null}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {modal ? <Modal body={renderModalLease()} onCancel={closeModal} /> : null}
            {viewLeaseAgreement ? <Modal body={renderViewLeaseModal()} onCancel={closeViewLeaseModal} /> : null}
            {templateModal ? <Modal body={renderTemplateList()} onCancel={closeModalTemplate} /> : null}
            {isLeaseEdit ? <Modal body={renderModalLeaseEdit()} onCancel={closeModalLeaseEdit} /> : null}
            {leaseSignModal ? <Modal body={renderSignLease()} onCancel={closeSignLease} /> : null}
            {isSign ? <Modal body={renderSignConfirm()} onCancel={closeSignConfirm} /> : null}
            {tenants && Object.values(tenants).length ?
                <table className="table-gap table-td table-hover">

                    <tbody>
                        <>
                            <tr className="table-head">
                                {/* <th className="wdt-01"></th> */}
                                <th className="checkboxWidth">
                                    <label className="invoiceCheckbox"
                                    // style={{ cursor: isCheckboxDisabled ? 'no-drop' : '' }}
                                    >
                                        <input
                                            type="checkbox"
                                            // disabled={isCheckboxDisabled}
                                            name="isArchived"
                                            checked={props.isCheckedAll}
                                            onClick={props.handleCheckAll}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                                <th className="tenantName">Name</th>
                                <th className="wdt-03">Address</th>
                                <th className="wdt-02">Unit Name</th>
                                <th>Email</th>
                                <th className="phoneNo">Phone</th>
                                <th>Rent</th>
                                {/* <th>Status</th> */}
                                {props.role == "maintenance" ? null : <th>Action</th>}
                            </tr>

                            {Object.values(tenants).map((tenant, i) => {
                                let RentData = tenant && tenant.Invoices.length && tenant.Invoices.find(data => data.defaultInvoice == 2);
                                let isChecked = props.paidArray && props.paidArray.length ? props.paidArray.find(p => p.id === tenant._id) : false

                                return <tr key={i}>
                                    <td className="checkboxPadding">
                                        <label
                                            // style={{cursor: inv.status ? 'no-drop' : ''}}
                                            className="invoiceCheckbox">
                                            <input
                                                type="checkbox"
                                                name="isArchived"
                                                // disabled={inv.status}
                                                checked={isChecked}
                                                onChange={(e) => props.handleCheck(e, tenant)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>                                    <td>
                                        <div className="c-td cursorPointer"
                                            onClick={() => props.role == "maintenance" ? null : window.open(`/Tenants/${tenant._id}/Summary`, "_blank")}>
                                            <div className="img-name">
                                                <div className="txtNameLet">
                                                    {tenant.firstName.charAt(0).toUpperCase() +
                                                        tenant.lastName.charAt(0).toUpperCase()}
                                                </div>
                                                <div className="name">
                                                    {tenant.firstName.charAt(0).toUpperCase() + tenant.firstName.slice(1) + " " +
                                                        tenant.lastName.charAt(0).toUpperCase() + tenant.lastName.slice(1)}
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="c-td">
                                            <p> {tenant.propertyId && tenant.propertyId.propertyName}</p>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="c-td">{tenant.unitId && tenant.unitId.unitName}</div>
                                    </td>
                                    <td>
                                        <div className="c-td">{tenant.email}</div>
                                    </td>
                                    <td>
                                        <div className="c-td">{formatUsPhone(tenant.phoneNumber)}</div>
                                    </td>
                                    <td>
                                        <div className="c-td">
                                            ${tenant.rent ? tenant.rent[0] && numberWithCommas(tenant.rent[0].amount && tenant.rent[0].amount) || 0.00 :
                                                tenant && tenant.unitId && numberWithCommas(tenant.unitId.monthlyRent) || 0.00}
                                        </div>
                                    </td>
                                    {/* <td>
                                        <div className="c-td">{tenantStatus(tenant.tenantStatus)}</div>
                                    </td> */}
                                    {props.role == "maintenance" ? null : <td>
                                        {!tenant.isDeleted ?
                                            <div className="c-td  justify-content-center editDetlPrp">
                                                {tenant.isClosed
                                                    ?
                                                    <>
                                                        <i className="far fa-eye mr-1" style={{ "cursor": "not-allowed" }} />
                                                        <i className="fas fa-pencil-alt mr-1" style={{ "cursor": "not-allowed" }} />
                                                    </>
                                                    :
                                                    <>
                                                        <i className="far fa-eye mr-1" title="lease agreement"
                                                            onClick={() => leaseModal(tenant._id, tenant)}></i>
                                                        <i className="fas fa-pencil-alt mr-1" title="edit" onClick={() => {
                                                            setEditClicked(true)
                                                            onOpenModal(tenant._id)
                                                        }}></i>
                                                    </>
                                                }


                                                <i className="fa fa-trash" aria-hidden="true" title="delete"
                                                    onClick={() => openDeleteModal(tenant._id)}></i>
                                            </div>
                                            :
                                            <div className="c-td  justify-content-center editDetlPrp">
                                                <div className="c-td  justify-content-center disable-div" disabled={true}>
                                                    <i className="fas fa-pencil-alt mr-1"></i>
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        }
                                    </td>}
                                </tr>
                            })}</>
                        {/* :
                        <tr>
                            <td className="text-center no_value_table" colSpan={7}><i
                                className="far fa-thumbs-down"></i>There is no data to show you right now!
                            </td>
                        </tr>  */}

                    </tbody>
                </table>
                :
                <div className="no_value"><i className="far fa-thumbs-down"></i> Add Your First Tenant To Get Started!!!</div>
            }
        </div >
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer, settingsReducer } = state;
    return {
        tenantReducer,
        settingsReducer,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...settingsAction, ...commonAction
})(TenantListing);
