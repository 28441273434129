import * as actionTypes from "./constants";
import * as Service from "./services";


//get all the property details
const getProperty = (params) => {
    const request = data => ({
        type: actionTypes.GET_PROPERTY_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_PROPERTY_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_PROPERTY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getProperty(params);
            if (res.status === 200) {
                const userDetails = res.data;
                await dispatch(success(userDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get property by id
const getPropertyById = (id) => {
    const request = data => ({
        type: actionTypes.GET_EACH_PROPERTY_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_EACH_PROPERTY_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_EACH_PROPERTY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPropertyById(id);
            if (res.status === 200) {
                const userDetails = res.data;
                await dispatch(success(userDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};


//get unit by property id
const getUnitsByPropertyId = (id) => {
    const request = data => ({
        type: actionTypes.GET_UNIT_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_UNIT_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_UNIT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getUnitsByPropertyId(id);
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get individual unit details
const getUnitDetails = (id) => {
    const request = data => ({
        type: actionTypes.GET_UNIT_DETAILS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_UNIT_DETAILS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_UNIT_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getUnitDetails(id);
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get state
const getState = () => {
    const request = data => ({
        type: actionTypes.GET_STATE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_STATE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_STATE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getState();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get aminety
const getAminities = () => {
    const request = data => ({
        type: actionTypes.GET_AMINITY_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_AMINITY_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_FEATHURES_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getAmminities();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get property types
const getPropertyType = () => {
    const request = data => ({
        type: actionTypes.GET_TYPES_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_TYPES_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_TYPES_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPropertyType();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get property feathures
const getPropertyFeathures = () => {
    const request = data => ({
        type: actionTypes.GET_FEATHURES_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_FEATHURES_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_FEATHURES_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPropertyFeature();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get expense category
const getExpenseCategory = () => {
    const request = data => ({
        type: actionTypes.GET_EXPENSECATEGORY_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_EXPENSECATEGORY_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_EXPENSECATEGORY_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getExpenseCategory();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get business category
const getBusiness = () => {
    const request = data => ({
        type: actionTypes.GET_BUSINESS_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_BUSINESS_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_BUSINESS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getBusiness();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get business category
const getMerchant = () => {
    const request = data => ({
        type: actionTypes.GET_MERCHANT_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_MERCHANT_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_MERCHANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getMerchant();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get invoice type
const getInvoiceType = () => {
    const request = data => ({
        type: actionTypes.GET_INVOICETYPE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_INVOICETYPE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_INVOICETYPE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getInvoiceType();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get payment method details
const getPaymentDetails = () => {
    const request = data => ({
        type: actionTypes.GET_PAYMENT_METHOD_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_PAYMENT_METHOD_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_PAYMENT_METHOD_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPaymentDetails();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get all units
const getAllUnits = () => {
    const request = data => ({
        type: actionTypes.GET_ALL_UNIT_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_ALL_UNIT_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_ALL_UNIT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getAllUnits();
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get all revenue
const getRevenue = (req) => {
    const request = data => ({
        type: actionTypes.GET_REVENUE_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.GET_REVENUE_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.GET_REVENUE_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getRevenue(req);
            if (res.status === 200) {
                const Details = res.data;
                await dispatch(success(Details));
            } else {
                await dispatch(failure(res.message));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

//get all property owner details
const getPayment = (params) => {
    const request = () => ({ type: actionTypes.GET_PAYMENT_REQUEST });
    const success = settingsAction => ({
        type: actionTypes.GET_PAYMENT_SUCCESS,
        settingsAction
    });
    const failure = error => ({
        type: actionTypes.GET_PAYMENT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getPayment(params);
            if (res.status === 200) {
                const tenantDetails = res.data;
                if (tenantDetails.success) {
                    dispatch(success(tenantDetails.data));
                } else {
                    dispatch(failure(tenantDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Expense graph details
const getExpenseGraphDetails = (params) => {
    const request = () => ({ type: actionTypes.GET_EXPENSE_GRAPH_DETAILS_REQUEST });
    const success = expenseDetails => ({
        type: actionTypes.GET_EXPENSE_GRAPH_DETAILS_SUCCESS,
        expenseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_EXPENSE_GRAPH_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getExpenseGraphDetails(params);
            if (res.status === 200) {
                const expenseDetails = res.data;
                if (expenseDetails.success) {
                    dispatch(success(expenseDetails.data));
                } else {
                    dispatch(failure(expenseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Expense graph details
const getExpenseDetails = (params) => {
    const request = () => ({ type: actionTypes.GET_EXPENSE_GRAPH_DETAILS_REQUEST });
    const success = expenseDetails => ({
        type: actionTypes.GET_EXPENSE_GRAPH_DETAILS_SUCCESS,
        expenseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_EXPENSE_GRAPH_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getExpenseDetails(params);
            if (res.status === 200) {
                const expenseDetails = res.data;
                if (expenseDetails.success) {
                    dispatch(success(expenseDetails.data));
                } else {
                    dispatch(failure(expenseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Tenant property details
const getTenantPropertyDetails = (params) => {
    const request = () => ({ type: actionTypes.GET_PROPERTY_DETAILS_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_PROPERTY_DETAILS_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_PROPERTY_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantProperty(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Tenant paid invoice details
const getTenantPaidInvoice = (params) => {
    const request = () => ({ type: actionTypes.GET_PAID_INVOICE_DETAILS_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_PAID_INVOICE_DETAILS_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_PAID_INVOICE_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantPaidInvoice(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Tenant unpaid invoice details
const getTenantUnPaidInvoice = (params) => {
    const request = () => ({ type: actionTypes.GET_UNPAID_INVOICE_DETAILS_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_UNPAID_INVOICE_DETAILS_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_UNPAID_INVOICE_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantUnPaidInvoice(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Tenant Message  details
const getTenantMessage = (params) => {
    const request = () => ({ type: actionTypes.GET_MESSAGE_DETAILS_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_MESSAGE_DETAILS_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_MESSAGE_DETAILS_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantMessage(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Tenant maintenance   details
const getTenantMaintenance = (params) => {
    const request = () => ({ type: actionTypes.GET_MAINTENANCE_TENANT_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_MAINTENANCE_TENANT_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_MAINTENANCE_TENANT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getTenantMaintenance(params);

            if (res.status === 200) {
                const responseDetails = res.data;

                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get Notification  details
const getNotificationDetails = (params) => {
    const request = () => ({ type: actionTypes.GET_NOTIFICATION_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_NOTIFICATION_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_NOTIFICATION_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getNotificationDetails(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}



//update Notification  details
const updateNotificationDetails = (params) => {
    const request = () => ({ type: actionTypes.UPDATE_NOTIFICATION_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.UPDATE_NOTIFICATION_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.UPDATE_NOTIFICATION_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateNotificationDetails(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//update Notification  details
const getAllAdminUsers = (params) => {
    const request = () => ({ type: actionTypes.GET_ADMIN_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_ADMIN_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_ADMIN_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getAllAdminUsers(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails.data));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//update Notification  details
const advancedSerachData = (params) => {
    const request = () => ({ type: actionTypes.ADVANCE_SEARCH_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.ADVANCE_SEARCH_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.ADVANCE_SEARCH_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.advancedSerachData(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//get limit details
const getLimitData = (params) => {
    const request = () => ({ type: actionTypes.GET_LIMIT_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.GET_LIMIT_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.GET_LIMIT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.getLimitData(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            // if(navigator.onLine){
            //     getLimitData(params)
            // }
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//update limit details
const updateLimitData = (params) => {
    const request = () => ({ type: actionTypes.UPDATE_LIMIT_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.UPDATE_LIMIT_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.UPDATE_LIMIT_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.updateLimitData(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}

//email check
const emailCheck = (params) => {
    const request = () => ({ type: actionTypes.EMAIL_CHECK_REQUEST });
    const success = responseDetails => ({
        type: actionTypes.EMAIL_CHECK_SUCCESS,
        responseDetails
    });
    const failure = error => ({
        type: actionTypes.EMAIL_CHECK_FAILURE,
        error
    });
    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.emailCheck(params);
            if (res.status === 200) {
                const responseDetails = res.data;
                if (responseDetails.success) {
                    dispatch(success(responseDetails));
                } else {
                    dispatch(failure(responseDetails));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    };
}


export default {
    getProperty,
    getUnitsByPropertyId,
    getState,
    getAminities,
    getPropertyType,
    getPropertyFeathures,
    getExpenseCategory,
    getBusiness,
    getMerchant,
    getInvoiceType,
    getPaymentDetails,
    getAllUnits,
    getPropertyById,
    getUnitDetails,
    getRevenue,
    getPayment,
    getExpenseGraphDetails,
    getExpenseDetails,
    getTenantPropertyDetails,
    getTenantPaidInvoice,
    getTenantUnPaidInvoice,
    getTenantMessage,
    getTenantMaintenance,
    getNotificationDetails,
    updateNotificationDetails,
    getAllAdminUsers,
    advancedSerachData,
    getLimitData,
    updateLimitData,
    emailCheck
};
