import React from "react";
import { connect } from 'react-redux';
import action from "../action";
import { withRouter } from "react-router-dom"
import PageTitle from "./pageTitle";
import MessagingForm from "./messagingForm"
import history from "../../../config/history";
import queryString from 'query-string';
import LoaderSpinner from "../../Common/components/Loader";
import moment from 'moment';
import { MAIN_ROUTE } from "../../../config/constants";
import SuccessModal from "./successModal"
import parser from 'html-react-parser';


class NewMessageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasReplyForm: false,
            hasForwardForm: false,
            messageType: '',
            encryptedSubject: null,
            detailsParams: {
                limit: 10,
                offset: 0,
            },
            mailSource: '',
            isLoaded: false,
            callBackPage: '',
            messageParams: {
                to: null,
                thread: null,
                subject: null,
            },
            showSuccess: false,
        };
    }

    handleReplyMessage = async (threadId, threadSubject, replyTo) => {
        const { messageParams } = this.state;
        await this.setState({
            messageType: 'reply',
            hasReplyForm: true,
            messageParams: {
                ...messageParams,
                to: replyTo,
                thread: threadId,
                subject: threadSubject,
            }
        })
    };

    handleForwardMessage = async (threadId, threadSubject) => {
        const { messageParams } = this.state;
        await this.setState({
            messageType: 'forward',
            hasForwardForm: true,
            messageParams: {
                ...messageParams,
                to: '',
                thread: threadId,
                subject: threadSubject,
            }
        })
    };

    handleDiscard = async (status) => {
        this.setState({
            isLoaded: false
        });
        await this.setState({
            hasForwardForm: false,
            hasReplyForm: false,
            messageType: '',
        });
        if (status && status !== 'discard') {
            await this.props.getMailDetails(this.state.detailsParams, this.state.encryptedSubject);
            this.setState({
                isLoaded: true,
                showSuccess: true,
            });
        }
        await this.setState({
            isLoaded: true,
        });
    };

    hideSuccessModal = () => {
        this.setState({
            showSuccess: false,
        });
    }

    componentDidMount = async () => {
        const { location: { pathname, search } } = this.props;
        const urlDataQuery = queryString.parse(search);
        await this.setState({
            encryptedSubject: pathname.split("/").pop(),
            mailSource: urlDataQuery.fSrs === 'sent' ? 'Sent' : 'Inbox',
            callBackPage: urlDataQuery.fSrs === 'sent' ? 'Sent' : 'Inbox'
        });
        await this.props.getMailDetails(this.state.detailsParams, this.state.encryptedSubject);
        await this.props.getTenantEmails();
        this.setState({
            isLoaded: true
        });
    };


    deleteMessage = async (id) => {
         await this.props.deleteThread(id);
        const { location: { pathname, search } } = this.props;
        const urlDataQuery = queryString.parse(search);
        history.push(`/Tenants/${urlDataQuery.tenantId}/Email`)
        let listParams = {
            limit: 10,
            offset: 1, type: 'email'
        }
        
        await this.props.getMailList(listParams)
       
    }


    render() {
        const { onCancel, submitting } = this.props;
        const { hasReplyForm, hasForwardForm, messageType, mailSource, isLoaded, callBackPage, showSuccess } = this.state;
        if (!isLoaded) {
            return <LoaderSpinner />;
        }
        const { messageReducer: { messageDetails, tenantEmails } } = this.props;
        let tempTenantEmails = [];
        (tenantEmails && tenantEmails.length) && tenantEmails.map(item => tempTenantEmails.push({
            label: item.email.trim(),
            value: item.email.trim()
        }));
        if (!messageDetails.length) {
            return (
                <div className="tenants-otr">
                    <PageTitle />
                    <div className="messageOuter">
                        <div className="emailOuter">
                            <div className="no_value"><i className="far fa-thumbs-down" /> No Data Found!!!</div>
                        </div>
                    </div>
                </div>
            )
        }

        let replyTo = messageDetails[0].from.trim() === 'admin' ? messageDetails[0].to.trim() : messageDetails[0].from.trim(); // For Inbox
        if (callBackPage === 'Sent') {
            replyTo = messageDetails[0].to.trim(); // For Send Emails
        }


        return (
            <>
                <div className="tenants-otr">
                    <PageTitle />
                    <div className="messageOuter">
                        <div className="emailOuter">
                            <div className="inboxInner inboxdetailInner">

                                <div className="email-img">
                                    <img src="/images/inbox.png" alt="" />
                                    <span>{mailSource}</span>
                                </div>
                                {/*<button className={"dltMsg"}  onClick={this.deleteMessage} >Delete</button>*/}
                            </div>

                            <div className="inboxcontentWrapper">
                                {/* <div className="back-arrow-sec" onClick={() => {
                                    history.push(`/Messaging/Email/${callBackPage}`)
                                }}>
                                    <i className="fas fa-chevron-left" />
                                    <span>Back to {mailSource}</span>
                                </div> */}
                                <div className="mailContent">
                                    <h2 className="d-flex justify-content-between" ><span>{messageDetails[0].subject}</span><i className="delete-all-messages fa fa-trash cursorPointer" onClick={() => this.deleteMessage(messageDetails[0]._id)} /></h2>
                                    {messageDetails.map((item, i) => {
                                        let messageDetailsData = item.message
                                        messageDetailsData = messageDetailsData ? messageDetailsData.replace(/(?:\\[rn]|[\r\n])/g, `<br/>`) : null
                                        return <div className="mailWrapper" key={i}>
                                            <div className="maildetailSec col-md-12">
                                                <div className="userImage">
                                                    <img src="/images/profile-image.png" alt="" />
                                                </div>
                                                <div className="userDetailPadding col-md-11">
                                                    <div className="userDetails">
                                                        <h4>{item.from === 'admin' ? <><small>To
                                                            : </small>{item.to}</> : <><small>From
                                                            : </small>{item.from}</>}</h4>
                                                        <p className="c-td date">{moment(item.createdAt).format('MMM DD YYYY, h:mm A')} ({moment(item.createdAt).fromNow()})</p>
                                                    </div>
                                                    <div className="mailDescription">
                                                        {/*<div contentEditable="true" className="message-content-area" >{item.message}</div>*/}
                                                        {/*<textarea className="message-content-area" readOnly={true} value={item.message}/>*/}
                                                        <pre className="message-content-area"
                                                            readOnly={true}>{item.message}</pre>
                                                    </div>
                                                    {item.attachments && item.attachments.length ?
                                                        <div className="attachedImages">
                                                            <h3>Attachments</h3>
                                                            <div className="col-md-12 attachfileOuter">

                                                                {item.attachments.map((attachment, j) => {
                                                                    return (attachment.filename.split('.').pop() === "jpg" || attachment.filename.split('.').pop() === "png")
                                                                        ? <div key={j}
                                                                            className="col-xl-2 col-md-2 col-sm-2 outAttachImg">
                                                                            <div className="col-md-12 attachFileList">
                                                                                <img
                                                                                    src={MAIN_ROUTE + attachment.path}
                                                                                    alt="" />
                                                                                <a href={MAIN_ROUTE + attachment.path}
                                                                                    target="_blank"
                                                                                    className="image-close">
                                                                                    <i className="fas fa-arrow-down" />
                                                                                </a>
                                                                            </div>
                                                                            <p>{attachment.filename}</p>
                                                                        </div>
                                                                        : (attachment.filename.split('.').pop() === "pdf")
                                                                            ? <div key={j}
                                                                                className="col-xl-2 col-md-2 col-sm-2 outAttachImg">
                                                                                <div
                                                                                    className="col-md-12 attachFileList">
                                                                                    <i className="far fa-file-pdf" />
                                                                                    <a href={MAIN_ROUTE + attachment.path}
                                                                                        target="_blank"
                                                                                        className="image-close">
                                                                                        <i className="fas fa-arrow-down" />
                                                                                    </a>
                                                                                </div>
                                                                                <p>{attachment.filename}</p>
                                                                            </div>

                                                                            : (attachment.filename.split('.').pop() === "doc" || attachment.filename.split('.').pop() === "docx")
                                                                                ?
                                                                                <div key={j}
                                                                                    className="col-xl-2 col-md-2 col-sm-2 outAttachImg">
                                                                                    <div
                                                                                        className="col-md-12 attachFileList">
                                                                                        <i className="far fa-file-alt" />
                                                                                        <a href={MAIN_ROUTE + attachment.path}
                                                                                            target="_blank"
                                                                                            className="image-close">
                                                                                            <i className="fas fa-arrow-down" />
                                                                                        </a>
                                                                                    </div>
                                                                                    <p>{attachment.filename}</p>
                                                                                </div>
                                                                                : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.filename.split('.').pop())) ?
                                                                                    <div key={j}
                                                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg">
                                                                                        <div
                                                                                            className="col-md-12 attachFileList">
                                                                                            <i className="far fa-file-video" />
                                                                                            <a href={MAIN_ROUTE + attachment.path}
                                                                                                target="_blank"
                                                                                                className="image-close">
                                                                                                <i className="fas fa-arrow-down" />
                                                                                            </a>
                                                                                        </div>
                                                                                        <p>{attachment.filename}</p>
                                                                                    </div>
                                                                                    : (attachment.filename.split('.').pop() === 'zip') &&
                                                                                    <div key={j}
                                                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg">
                                                                                        <div
                                                                                            className="col-md-12 attachFileList">
                                                                                            <i className="far fa-file-archive" />
                                                                                            <a href={MAIN_ROUTE + attachment.path}
                                                                                                target="_blank"
                                                                                                className="image-close">
                                                                                                <i className="fas fa-arrow-down" />
                                                                                            </a>
                                                                                        </div>
                                                                                        <p>{attachment.filename}</p>
                                                                                    </div>
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    })}
                                </div>
                                {!(hasReplyForm || hasForwardForm)
                                    ? <div className="mailFooter">
                                        <button type="button" className="footer-btn mr-1"
                                            onClick={() => this.handleReplyMessage(messageDetails[0].thread, messageDetails[0].subject, replyTo)}>
                                            <img src="/images/Replay.png" alt="" /> Reply
                                        </button>
                                        <button type="button" className="footer-btn"
                                            onClick={() => this.handleForwardMessage(messageDetails[0].thread, messageDetails[0].subject)}>
                                            <img src="/images/Forward.png" alt="" /> Forward
                                        </button>
                                    </div>
                                    : null
                                }

                                {(hasReplyForm || hasForwardForm) &&
                                    <MessagingForm
                                        handleDiscard={this.handleDiscard}
                                        messageType={messageType}
                                        messageParams={this.state.messageParams}
                                        tempTenantEmails={tempTenantEmails}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {showSuccess ?
                    <div className="modal addTenant addMessage" id="modalId" style={{ display: 'block' }}>
                        <SuccessModal hideSuccessModal={this.hideSuccessModal}
                            modalContents={{ title: 'Success !', message: 'Mail sent successfully !!' }} />
                    </div> : null
                }

            </>
        )
    }
}

const mapStateToProps = state => {
    return { ...state }
};

export default withRouter(connect(mapStateToProps, { ...action })(NewMessageModal));


