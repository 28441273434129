import React, { useState, useEffect } from "react";
import moment from 'moment'



const MaintenanceDetails = (props) => {

    const renderStatus = (status) => {
        switch (status) {
            case 0:
                return "Closed"
            case 1:
                return "Open"
            case 2:
                return "Answered"
            case 3:
                return "Customer-Replay"
            case 4:
                return "On Hold"
            case 5:
                return "In Progress "
            case 6:
                return 'Scheduled'

        }
    }


    return (
        <div className="innago-table">
            <h3>Maintenance Request</h3>
            <div className="table-responsive">
                <table className="table-gap table-td">
                    <tbody>
                        <tr className="table-head">
                            {/*<th className="wdt-01"></th>*/}
                            <th className="wdt-03">Issue</th>
                            <th>Status</th>
                            <th>Reported On</th>
                            <th className="reportedBy">Reported By</th>
                        </tr>
                        {props.maintenanceList && props.maintenanceList.maintenanceList &&
                            props.maintenanceList.maintenanceList.length ? props.maintenanceList.maintenanceList.map((maintenance, key) => {
                                return <tr className="detailRow">
                                    {/*<td className="text-center">*/}
                                    {/*<i*/}
                                    {/*><img src="/images/icon-dots.png" alt=""*/}
                                    {/*/></i>*/}
                                    {/*</td>*/}
                                    <td>
                                        <div className="c-td">
                                            {maintenance.title}
                                        </div>
                                    </td>

                                    <td>
                                        <div className="c-td">
                                            <p>
                                                {renderStatus(maintenance.status)}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="c-td"> {moment(maintenance.reportedOn).format('DD MMM YYYY')}</div>
                                    </td>
                                    <td>
                                        <div className="c-td">
                                            {maintenance.requestById ? <div className="img-name">
                                                <i>
                                                    <div className="txtNameLet">
                                                        {maintenance.requestById ? maintenance.requestById.firstName.charAt(0).toUpperCase() +
                                                            maintenance.requestById.lastName.charAt(0).toUpperCase() : null}
                                                    </div>
                                                </i>
                                                <div className="name">
                                                    <p>{maintenance.requestById ? maintenance.requestById.firstName + " " + maintenance.requestById.lastName : null}</p>
                                                    <span className="subText"
                                                    >{maintenance.requestById ? maintenance.address : null}</span
                                                    >
                                                </div>
                                            </div> : "N/A"}
                                        </div>
                                    </td>
                                </tr>
                            }) : <tr >
                                <td colSpan={4} className="text-center no_value_table"><i className="far fa-thumbs-down"></i>No details Found!!!</td>
                            </tr>}

                    </tbody>
                </table>
            </div>
        </div>


    )
}
export default MaintenanceDetails
