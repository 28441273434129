import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import messageAction from '../../../Messaging/action';
import moment from 'moment';
import history from "../../../../config/history";
import base64url from "base64url";

const Messages = props => {

    const [params, setParams] = useState({
        limit: 10,
        offset: 1,
        type: 'inbox'
    })

    useEffect(() => {

        async function renderCall() {
            await props.getMailList(params);
            await props.getChatMessageList();
        }

        renderCall();
    }, []);

    const { messageReducer: { messageList, chatMessageList: {
        chats: chatList
    } } } = props;



    return (
        <div
            // ref={props.reference.ref}
            // {...props.dragHandleProps}
            // {...props.draggableProps}
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-6 col-sm-12 occupancy maintenance mb-3 messageOtr">
            <div className="occupancyOtr">

                <div className="headerSection d-flex">
                    <h3>Messages</h3>
                    <div className="viewMore" onClick={() => history.push(`/Messaging/Sms`)}>
                        <img src="images/more.png" /><span>View More</span>
                    </div>
                </div>
                {(chatList && chatList.length)
                    ? <ul className="maintul">
                        {chatList.slice(0, 3).map((chatContents, i) => {
                            // const encryptedSubject = message.thread ? base64url(message.thread) : '';
                            return <li className="d-flex" key={i}
                                // onClick={() => history.push(`/Messaging/Details/${encryptedSubject}?fSrs=inbox`)}
                                onClick={() => history.push('/Messaging/Sms')}

                                style={{ cursor: 'Pointer' }}>
                                <div className="imgSec">
                                    <img src="/images/message.png" />
                                </div>
                                <div className="maintCont mesgCont">
                                    <h4>{chatContents.name || chatContents._id}</h4>
                                    <p className="contDtl">
                                        {chatContents.message}
                                    </p>
                                    <p className="textTime">
                                        {moment(chatContents.createDate).startOf('hour').fromNow()}
                                    </p>
                                </div>
                            </li>
                        })

                        }
                    </ul> :
                    <div className="outerTenant no_value"><i className="far fa-thumbs-down"></i>No data found!</div>}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { messageReducer } = state;
    return {
        messageReducer
    };
};

export default connect(mapStateToProps, {
    ...messageAction
})(Messages);

