import * as actionType from "./constant";

const initialState = {
    Invoice: {},
    InvoiceList: { invoice: [] },
    InvoiceTenantList: { invoice: [], invoiceCount: '', paidAmount: '', unpaidAmount: '' },
    invoiceTenantTypes: { invoice: [] },
    invoicePayment: {},
    sendLobMailResponse: {},
    sms: {},
    invoiceDelete: {},
    smsInvoice: {}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case actionType.ADD_INVOICE_SUCCESS:
            return {
                ...state,
                Invoice: { success: true, ...action.data },
            };
        case actionType.ADD_INVOICE_FAILURE:
            return {
                ...state,
                Invoice: { success: false, ...action.error }
            };

        case actionType.GET_INVOICE_SUCCESS:
            return {
                ...state,
                InvoiceList: {
                    success: true,
                    invoiceList: action.data,
                    invoice: action.data.data.invoices,
                    invoiceCount: action.data.data.invoiceCount,
                    unpaidAmount: action.data.data.unpaidAmount,
                    paidAmount: action.data.data.paidAmount,
                    overdueAmount: action.data.data.overdueAmount,
                    totalAmountBasedFilter: action.data.data.totalAmountBasedFilter

                },
            };
        case actionType.GET_INVOICE_FAILURE:
            return {
                ...state,
                InvoiceList: { success: false, ...action.error }
            };

        //Invoice list of logined tenant
        case actionType.GET_INVOICE_TENANT_SUCCESS:
            return {
                ...state,
                InvoiceTenantList: {
                    success: true,
                    tenant: action.data.data.tenant,
                    invoice: action.data.data.Invoices,
                    invoiceCount: action.data.data.invoiceCount,
                    unpaidAmount: action.data.data.unPaid,
                    paidAmount: action.data.data.Paid,
                    overdueAmount: action.data.data.totalDue,
                    unPaidInvoiceCount: action.data.data.unPaidInvoiceCount,
                    totalAmountBasedFilter: action.data.data.totalAmountBasedFilter

                }
            };

        case actionType.GET_INVOICE_TENANT_FAILURE:
            return {
                ...state,
                InvoiceTenantList: { success: false, ...action.error }
            };

        //Invoice types of logined tenant
        case actionType.GET_INVOICE_TENANT_TYPE_SUCCESS:
            return {
                ...state,
                invoiceTenantTypes: {
                    success: true,
                    invoice: action.data.data.invoice
                },
            };

        case actionType.GET_INVOICE_TENANT_TYPE_FAILURE:
            return {
                ...state,
                invoiceTenantTypes: { success: false, ...action.error }
            };

        case actionType.GET_INVOICE_TENANT_FAILURE:
            return {
                ...state,
                InvoiceTenantList: { success: false, ...action.error }
            };

        //Pay Invoices
        case actionType.PAY_INVOICE_SUCCESS:
            return {
                ...state,
                invoicePayment: {
                    ...action.data
                },
            };

        case actionType.PAY_INVOICE_FAILURE:
            return {
                ...state,
                invoicePayment: { success: false, ...action.error }
            };

        //Pay Invoices
        case actionType.DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                invoiceDelete: action.data

            };

        case actionType.DELETE_INVOICE_FAILURE:
            return {
                ...state,
                invoiceDelete: { success: false, ...action.error }
            };

        case actionType.SEND_SMS_REQUEST:
            return {
                ...state,
                sms: { loading: true }
            }

        //Pay Invoices
        case actionType.SEND_SMS_SUCCESS:
            return {
                ...state,
                sms: {
                    success: true, loading: false, message: action.data.status
                },
            };

        case actionType.SEND_SMS_FAILURE:
            return {
                ...state,
                sms: { success: false, loading: false, ...action.error }
            };

        case actionType.SEND_SMS_INVOICE_REQUEST:
            return {
                ...state,
                smsInvoice: { loading: true }
            }

        //Pay Invoices
        case actionType.SEND_SMS_INVOICE_SUCCESS:
            return {
                ...state,
                smsInvoice: {
                    success: true, loading: false, message: action.data.chat
                },
            };

        case actionType.SEND_SMS_INVOICE_FAILURE:
            return {
                ...state,
                smsInvoice: { success: false, loading: false, ...action.error }
            };

        case actionType.MERGE_INVOICE_REQUEST:
            return {
                ...state,
                mergeInvoice: { loading: true }
            }

        //Merge Invoices
        case actionType.MERGE_INVOICE_SUCCESS:
            return {
                ...state,
                mergeInvoice: {
                    success: true, loading: false, mergeRequest: action.data
                },
            };

        case actionType.MERGE_INVOICE_FAILURE:
            return {
                ...state,
                mergeInvoice: { success: false, loading: false, ...action.error }
            };

        case actionType.GET_INVOICE_ID_SUCCESS:
            return {
                ...state,
                invoiceById: action.data,
            };

        case actionType.GET_INVOICE_ID__FAILURE:
            return {
                ...state,
                invoiceById: { success: false, loading: false, ...action.error }
            };



        default:
            return state;
    }
};
