import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import commonAction from '../../../Common/action'
import { numberWithCommas } from "../../../Common/components/utils";
import CommonDatePicker from '../../../Common/components/CommonDatePicker'

class CustomInput extends React.Component {
    render() {
        return (
            <>
                <span className="input-group-addon">
                    <i className="far fa-calendar" onClick={this.props.onClick}></i>
                </span>
                <input onClick={this.props.onClick} onChange={this.props.onChange}
                    autoComplete={false} placeholder={this.props.placeholder}
                    selected={this.props.selected}
                    value={this.props.value}
                    className={this.props.className}
                    type="text" />
            </>
        )
    }
}


export const popperPlacementPositions = [
    'auto',
    'auto-left',
    'auto-right',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start'
]

const Revenue = props => {

    const [startDate, setStartDate] = useState(moment(new Date()).startOf('month').toISOString());
    const [endDate, setEndDate] = useState(moment(new Date()).endOf('month').toISOString());
    const [changedFilter, setChangedFilter] = useState(false);

    useEffect(() => {
        async function renderCall() {
            await props.getRevenue({ startDate: (moment(new Date()).startOf('day').toISOString()), endDate: moment(new Date()).endOf('day').toISOString() });
        }

        renderCall();
    }, []);

    const onChangeStartDate = async (value) => {
        setChangedFilter(true)
        setStartDate(moment(new Date(value)).startOf('day').toISOString());
        await props.getRevenue({ startDate: moment(new Date(value)).startOf('day').toISOString(), endDate: endDate && endDate });
    }

    const onChangeEndDate = async (value) => {
        setChangedFilter(true)
        setEndDate(moment(new Date(value)).endOf('day').toISOString());
        await props.getRevenue({
            startDate: startDate && startDate,
            endDate: moment(new Date(value)).endOf('day').toISOString(),
        });
    }

    const clearDates = async () => {
        setChangedFilter(false)
        setStartDate('');
        setEndDate('');
        await props.getRevenue();
    }

    return (
        <div
            // ref={props.reference.ref}
            // {...props.dragHandleProps}
            // {...props.draggableProps}
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-6 col-sm-12 occupancy revenue mb-3">
            <div className="occupancyOtr">
                <div className="headerSection d-flex">
                    <h3>Revenue</h3>
                    <form autoComplete="off">
                        <div className="viewMore d-flex align-items-center">
                            <div className="dateSec d-flex">
                                <div className="datePic1">
                                    {/*<span className="input-group-addon" style={{cursor: "none"}}>*/}
                                    {/*    <span className="far fa-calendar"></span>*/}
                                    {/*</span>*/}
                                    {/* <input data-date-format="mm/dd/yyyy" id="datepicker" placeholder="Start-date"
                                       className="date1"/> */}
                                    <CommonDatePicker
                                        name="expiryDate"
                                        placeholderText="Start Date"
                                        disableClock={true}
                                        format="mm/yy"
                                        className="date1"
                                        onChange={onChangeStartDate}
                                        // customInput={<CustomInput/>}
                                        value={startDate && new Date(startDate)}
                                    //value={startDate&&moment(startDate).format('MM/DD/YYYY')}
                                    //  maxDate={new Date()}
                                    />
                                </div>
                                <div className="datePic2">
                                    {/*<span className="input-group-addon">*/}
                                    {/*    <span className="far fa-calendar"></span>*/}
                                    {/*</span>*/}
                                    <CommonDatePicker
                                        name="expiryDate"
                                        placeholderText="End Date"
                                        // customInput={<CustomInput/>}
                                        disableClock={true}
                                        format="mm/yy"
                                        className="date2"
                                        onChange={onChangeEndDate}
                                        value={endDate && new Date(endDate)}
                                        // value={endDate && moment(endDate).format('MM/DD/YYYY')}
                                        popperPlacement="top-end"
                                        popperModifiers={{
                                            offset: {
                                                enabled: true,
                                                offset: '5px, 10px'
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="refreshBg" onClick={() => clearDates()}>
                                <i className="fas fa-redo"></i>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="chartLine d-flex">
                    <div className="col-md-12 revText text-center">
                        <h4>{!changedFilter ? "Today's Revenue" : "Revenue Amount"} </h4>
                        <h3>{props.commonReducer.revenueDetails ? props.commonReducer.revenueDetails.totalInvoiceAmountBasedFilter > 0
                            ? '$' + numberWithCommas(props.commonReducer.revenueDetails.totalInvoiceAmountBasedFilter) : "$" + numberWithCommas(props.commonReducer.revenueDetails.totalInvoiceAmountBasedFilter) : "$" + 0.00}

                        </h3>

                    </div>
                    {/* <div className="col-md-6">
                        <canvas id="myChart" width="100" height="70" className="mychart"></canvas>
                    </div> */}
                </div>
                <div className="revenueSec">
                    <div className="reveDtlOutr">
                        <div className="reveDtl col-md-4">
                            <div className="col-md-12 revBg">
                                <h3>This Month </h3>
                                <p>{props.commonReducer.revenueDetails ? props.commonReducer.revenueDetails.totalMonthlyAmount > 0
                                    ? '$' + numberWithCommas(props.commonReducer.revenueDetails.totalMonthlyAmount) : "$" + numberWithCommas(props.commonReducer.revenueDetails.totalMonthlyAmount) : "$" + 0.00}

                                </p>
                            </div>
                        </div>
                        <div className="reveDtl col-md-4">
                            <div className="col-md-12 revBg">
                                <h3>This Year</h3>
                                <p> {props.commonReducer.revenueDetails ? props.commonReducer.revenueDetails.totalYearRevenue > 0
                                    ? '$' + numberWithCommas(props.commonReducer.revenueDetails.totalYearRevenue) : "$" + numberWithCommas(props.commonReducer.revenueDetails.totalYearRevenue) : "$" + 0.00}


                                </p>
                            </div>
                        </div>
                        <div className="reveDtl col-md-4">
                            <div className="col-md-12 revBg">
                                <h3> All Time </h3>
                                <p>{props.commonReducer.revenueDetails ? props.commonReducer.revenueDetails.alltimeRevenue > 0 ? '$'
                                    + numberWithCommas(props.commonReducer.revenueDetails.alltimeRevenue) : '$' + numberWithCommas(props.commonReducer.revenueDetails.alltimeRevenue) : 0}


                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { commonReducer } = state;
    return {
        commonReducer
    };
}

export default connect(mapStateToProps, { ...commonAction })(Revenue);
