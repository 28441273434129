import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//add property details
export const addProperty = (value) =>
    innagoGateWay.post(serviceEndpoints.propertyEndpoints.property, value);
export const getZillowData = (params) => {
    return innagoGateWay.get(`${serviceEndpoints.zillowEndPoint.zillow}${params}`);
}

export const updatePropertyImage = (params) => {
    return innagoGateWay.put(serviceEndpoints.zillowEndPoint.zillow, params);
}

export const updateProperty = (params) => {
    return innagoGateWay.put(serviceEndpoints.propertyEndpoints.property, params);
}

export const deleteProperty = (params) => {
    return innagoGateWay.delete(`${serviceEndpoints.propertyEndpoints.property}/${params.id}`);
}

export const makeImageDefault = (params, propertyId, imageid) => {
    return innagoGateWay.put(`${serviceEndpoints.propertyEndpoints.makeDefault}?id=${propertyId}${imageid ? `&imageid=${imageid}`:''}`, params);
}

export const deletePropertyImage = (params) => {
    return innagoGateWay.delete(`${serviceEndpoints.propertyEndpoints.deleteImage}?id=${params.id}&imageid=${params.imageid}`);
}

export const deletePropertyUnit = (params) => {
    return innagoGateWay.delete(`${serviceEndpoints.propertyEndpoints.unit}/${params}`);
}

