import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import commonAction from '../../Common/action';
import "react-toastify/dist/ReactToastify.css";
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import Modal from '../../Common/components/Modal';
import AddBusiness from './AddBusiness';
import Confirm from '../../Common/components/Confirm';
import toastr from '../../Common/components/Toaster';

const BusinessEntity = (props) => {

    const [isSubmitted, setIsSubmitted] = useState(true);
    const [params, setParams] = useState({ limit: 10, offset: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [businessEntity, setBusinessEntity] = useState('');
    const [businessModal, setBusinessModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(false);

    useEffect(() => {
        async function renderCall() {
            await props.getPayment(params);
        }
        renderCall();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000)
    }, []);

    const { settingsReducer: { deleteBusiness } } = props;

    useEffect(() => {
        if (isSubmitted && !deleteBusiness.success) {
            toastr.error(deleteBusiness.message)
        } else if (isSubmitted && deleteBusiness.success) {
            toastr.success("Successfully deleted");
        }
    }, [deleteBusiness]);

    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setCurrentPage(page)
        await props.getPayment(data);
    }

    const openModalBusiness = (e) => {
        setBusinessModal(true);
        setBusinessEntity(e);
    }

    const closeModalBusiness = () => {
        setBusinessModal(false)
    }

    const renderModalBusiness = () => {
        return <AddBusiness
            onCancel={closeModalBusiness}
            businessEntity={businessEntity}
        />
    };

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Business Entity'
            message='Do you want to delete this business entity?'
            onCancel={closeDeleteModal}
            onConfirm={deleteBusinessEntity}
        />
    }

    const deleteBusinessEntity = async () => {
        setIsSubmitted(true);
        setDeleteModal(false);
        await props.deleteBusiness(deleteId);
        setTimeout(() => {
            setIsSubmitted(false);
            props.getPayment({ limit: 10, offset: 0 });
        }, 500);
    }

    const { commonReducer: { paymentList } } = props;

    return (
        <>
            {isSubmitted && <LoaderSpinner />}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {businessModal ? <Modal body={renderModalBusiness()} onCancel={closeModalBusiness} /> : null}
            <div className="table-responsive">
                <table className="table-gap table-td">
                    <tbody>
                        <tr className="table-head">
                            <th>Entity Name</th>
                            <th>Owner Name</th>
                            <th>Address</th>
                            <th>Email</th>
                            <th>Active Payment Gateway</th>
                            <th>API ID</th>
                            <th>Transaction Key</th>
                            <th>Stripe Secret Key</th>
                            <th>Stripe Public Key</th>
                            <th>Action</th>
                        </tr>
                        {paymentList.payment && paymentList.payment.length ? paymentList.payment.map((e, i) => {
                            return <tr key={i}>
                                <td>
                                    <p>{e.entityName}</p>
                                </td>
                                <td>
                                    <p>{e.propertyOwnerName}</p>
                                </td>
                                <td>
                                    <p>{e.address}</p>
                                </td>
                                <td>
                                    <p>{e.email && e.email}</p>
                                </td>
                                <td>
                                    <p>{e.paymentMethod ? e.paymentMethod : "N/A"}</p>
                                </td>
                                <td>
                                    <p>{e.apiId ? e.apiId : "N/A"}</p>
                                </td>
                                <td>
                                    <p>{e.transactionKey ? e.transactionKey : "N/A"}</p>
                                </td>
                                <td>
                                    <p>{e.stripeSecretKey ? e.stripeSecretKey : "N/A"}</p>
                                </td>
                                <td>
                                    <p>{e.stripePublicKey ? e.stripePublicKey : "N/A"}</p>
                                </td>

                                <td>
                                    <div class="dropdown moveDrop editDetlPrp drop-arw">
                                        <i className="fas fa-pencil-alt mr-2" onClick={() => openModalBusiness(e)}></i>
                                        <i className="fa fa-trash" aria-hidden="true" onClick={() => openDeleteModal(e._id)}></i>
                                    </div>
                                </td>
                            </tr>
                        }) : <tr>
                                <td colSpan={10} className="text-center no_value_table"><i
                                    className="far fa-thumbs-down"></i> No Data Found!!!
                        </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="paginationSec">
                {paymentList.count && paymentList.count ? <Pagination
                    totalRecords={paymentList.count}
                    pageLimit={params.limit}
                    pageNeighbours={1}
                    currentPage={currentPage}
                    onPageChanged={onPageChanged}
                /> : null}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { settingsReducer, commonReducer } = state;
    return {
        settingsReducer, commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(BusinessEntity);
