import React, { useState, useEffect } from "react";
import parser from 'html-react-parser';
import toastr from '../../Common/components/Toaster';
import $ from "jquery"


class TemplateDetail extends React.Component {
  state = {
    templateName: null,
    templateContent: null
  }

  componentDidMount = async () => {
    const { templateData } = this.props;

    if (templateData && templateData) {
      var inputs = document.getElementsByTagName("p");
      for (var i = 0; i < inputs.length; i++) {
          inputs[i].innerHTML = inputs[i].innerHTML.trim();
      }
      let selectBox = document.getElementsByClassName("choosevalue");

      Object.values(selectBox).map((sl, key) => {
        // sl.id = `Select${key}`
        let classValue = "choosevalue form-control w-25"
        sl.style.display = "inline"
        sl.addEventListener("change", this.handleSelect);
        sl.setAttribute("class", classValue)

      })
      if (this.props.templateTitle) {
        this.setState({
          templateName: this.props.templateTitle
        })
      }
      if (this.props.isEdit && this.props.isTemplateView) {
        let replaceText = document.getElementsByClassName("spanClass");
        Object.values(replaceText).map((replace, key) => {
          let tag = document.createElement('li')
          tag.setAttribute("class", "fas fa-sync-alt")
          tag.setAttribute("title", "Change")
          replace.appendChild(tag);
          tag.addEventListener("click", this.handleRedo);
        })
      }
      
    }
  }

  handleSelect = (event) => {
    var currentEventTarget = event.currentTarget;
    let span = document.createElement('span')
    span.setAttribute("class", "text-center spanClass")
    span.style.display = "inline-block"
    span.style.minWidth = "230px"
    span.style.borderBottom = "1px"
    span.style.borderBottomStyle = "dotted"
    let tag = document.createElement('li')
    tag.setAttribute("class", "fas fa-sync-alt")
    tag.setAttribute("title", "Change")
    var new_content = document.createTextNode(event.target.value);
    span.appendChild(new_content);
    span.appendChild(tag);
    currentEventTarget.replaceWith(span);
    tag.addEventListener("click", this.handleRedo);
  }

  handleRedo = (event) => {
    let div = document.createElement('span')
    let selectDropdown = `<select class="choosevalue form-control w-25" 
  style="display: inline;"><option value="">select</option>
    <option value=" ">Leave blank</option>
    <option value="**landlordname**">Landlord name</option>
    <option value="**entityName**">Entity name</option>
    <option value="**landLordEmail**">LandLord email</option>
    <option value="**tenantEmail**">Tenant email</option>
    <option value="**tenantName**">Tenant name</option>
    <option value="**propertyAddress**">Property address</option>
    <option value="**deposit**">Deposit</option>
    <option value="**rent**">Rent</option>
    <option value="**fromDate**">From date</option>
    <option value="**endLeaseDay**">End lease day</option>
    <option value="**endLeaseMonth**">End lease month</option>
    <option value="**endLeaseYear**">End lease year</option>
    <option value="**toDate**">To date</option>
    <option value="**currentDate**">Current date</option>
    <option value="**signature_1**">Owner signature</option>
    <option value="**signature_2**">Tenant signature</option>
  </select>`
    div.innerHTML = selectDropdown
    var currentEventTarget = event.currentTarget.parentNode;



    // let selectBox = document.getElementsByClassName("choosevalue");
    // Object.values(selectBox).map((sl, key) => {
    //   selectDropdown = sl
    // })
    currentEventTarget.replaceWith(div);
    div.addEventListener("change", this.handleSelect);

  }

  handleChangeTitle = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  handleSave = () => {
    // console.log("templateDate-->selectBox:submit", templateData)
    // let elements=getElementsByClassName("fas fa-sync-alt")
    Array.from(document.getElementsByClassName("fas fa-sync-alt"))
      .forEach(element => element.remove());
    let selectBox = document.getElementsByClassName("choosevalue");
    let data = document.getElementById("modalBody").innerHTML
    if (!Object.keys(selectBox).length) {
      if (this.state.templateName) {
        this.props.addEditedTemplateData({
          templateName: this.state.templateName,
          templateContent: data,
          id: this.props.editId
        })
      }
      else {
        toastr.error("Please fill all the title!!")
      }
    }
    else {
      toastr.error("Please fill all the fields!!")
    }
  }



  render() {
    const { templateData } = this.props;
    return (
      <div className="modal addTenant leaseAgreement" id="modalId" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">

          <div className="modal-content">
            <div className="modal-header">
              <div className="customtest">
                <h5 className="modal-title" id="exampleModalLabel">{!this.props.isEdit ?
                  "Template View" :
                  "Edit Template"}</h5>
                {this.props.isEdit ? <div className="leaseDiv">
                  <div className="pr-2 formInp">
                    <input type="text" className="form-control" value={this.state.templateName} placeholder="Lease Title" name="templateName"
                      onChange={this.handleChangeTitle} />
                  </div>
                  <button className="btn sendbutton" style={{ padding: '3px 20px' }} onClick={this.handleSave}>
                    Save
                 </button>
                </div> : null}
              </div>

              <button type="button" className="close" onClick={() => this.props.onCancel()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" id={"modalBody"}>
              {templateData && parser(templateData)}
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default TemplateDetail;