import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { MAIN_ROUTE } from "../../../../config/constants";
import history from '../../../../config/history'
import toastr from "../../../Common/components/Toaster";
import LoaderSpinner from "../../../Common/components/Loader";
import leaseAction from '../../../Leases&Files/action'
import TenantAction from '../../../Tenants/action';

class Leases extends React.Component {
    state = {
        isLoading: false,
        unPaidLease: []
    }


    componentDidMount = async () => {
        window.scroll(0, 0)
        await this.props.getLease()
        if(this.props.leaseList && this.props.leaseList.leases && this.props.leaseList.leases.LeaseList){
            const { leaseList: { leases: { LeaseList } } } = this.props
            let unPaidLease = LeaseList && LeaseList.length ? LeaseList.filter(lease => lease.status == 1) : null
            this.setState({
                unPaidLease
            })
        }
       
    }


    handleViewmore = () => {
        history.push('/Leases')
    }

    viewLease = async (id) => {
        // await this.props.getTenantId(id)

        this.setState({
            isLoading: true
        })
        await this.props.getTenantProperty(id)

        const { tenantReducer: { tenantsPropertyById: { tenant } } } = this.props;
        if (tenant && tenant.agreementSent) {
            this.setState({
                submitLease: true
            });
            await this.props.viewAgreement({ tenantId: id });
            const { tenantReducer } = this.props;
            if (tenantReducer && tenantReducer.viewLeasePath.lease) {
                window.open(MAIN_ROUTE + tenantReducer.viewLeasePath.lease, '_blank');
                this.setState({
                    submitLease: false
                });
            }
            this.setState({
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
            toastr.error("Please send the lease agreement", {
                position: toast.POSITION.TOP_RIGHT
            })
        }

    }

    render() {
        const { unPaidLease } = this.state
        return (
            <div
                // ref={props.reference.ref}
                // {...props.dragHandleProps}
                // {...props.draggableProps}
                draggable={this.props.draggable}
                onDragStart={this.props.onDragStart()}
                onDragOver={this.props.onDragOver()}
                onDrop={this.props.onDrop()}
                id={this.props.idx}
                className="drpbl col-lg-4 col-md-6 col-sm-12 occupancy maintenance mb-3 unsignOtr">
                {this.state.isLoading ? <LoaderSpinner /> : null}



                <div className="occupancyOtr">
                    <div className="headerSection d-flex">
                        <h3>Unsigned Lease</h3>
                        <div className="viewMore" onClick={this.handleViewmore}>
                            <img src="images/more.png" /><span>View More</span>
                        </div>
                    </div>
                    {unPaidLease && unPaidLease.length ?
                        <ul className="maintul">
                            {unPaidLease.slice(0, 5).map((lease, key) => {
                                return <li className="d-flex" key={key}
                                    style={{ cursor: 'Pointer' }}
                                    onClick={() => this.viewLease(lease.tenantId && lease.tenantId._id)}>
                                    <div className="imgSec">
                                        <img src="images/unsignedlease.png" />
                                    </div>
                                    <div className="maintCont mesgCont">
                                        <h4 className="msgSec">{lease.leaseDocuments[0] && lease.leaseDocuments[0].title}</h4>
                                        <p className="contDtl">
                                            {lease.tenantId && (lease.tenantId.firstName + " " + lease.tenantId.lastName)},&nbsp;
                                            {lease.propertyId && lease.propertyId.propertyName}
                                        </p>
                                        <p className="btnCont">
                                            <a href={lease.leaseDocuments[0] && (MAIN_ROUTE + lease.leaseDocuments[0].path)}
                                                target="_blank">
                                                <span className="view"
                                                    onClick={() => this.viewLease(lease.tenantId && lease.tenantId._id)}
                                                >View</span>
                                            </a>
                                        </p>
                                    </div>
                                </li>
                            })}
                        </ul>
                        : <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { leaseReducer, tenantReducer } = state;
    return {
        leaseList: leaseReducer.leaseList,
        tenantReducer,
        leaseReducer
    };
};

export default connect(mapStateToProps, {
    ...TenantAction,
    ...leaseAction
})(Leases);
