import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from 'moment'
import action from "../action";
import Pagination from '../../Common/components/pagination';
import LoaderSpinner from "../../Common/components/Loader";
import AsyncSelect from 'react-select/async';

import usePrevious from "../../Common/components/prevProps";
import toastr from "../../Common/components/Toaster";
import Confirm from '../../Common/components/Confirm';
import Modal from '../../Common/components/Modal'
import PlaidLinkButton from "react-plaid-link";
import ProfileAction from '../../Profile/action';
import { PLAID_KEY, PLAID_ENV } from "../../../config/constants";
import commonAction from '../../Common/action'
import { render } from "react-dom";

class DataSource extends React.Component {

    state = {
        params: {
            limit: 25, offset: 0
        },
        currentPage: 1,
        accountDetails: null,
        isSubmitted: false,
        isConfirm: false,
        bankArray: []
    }

    componentDidMount = async () => {
        await this.props.getLimitData()
        await this.props.getPropertyBankDetails()
    }

    componentDidUpdate = async (preProps, preState) => {
        if (preProps.commonReducer.limitData != this.props.commonReducer.limitData) {
            const { limitData } = this.props.commonReducer
            if (limitData) {
                let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 7)
                let tempParams = this.state.params
                tempParams.limit = limitCount ? limitCount.count : 25
                this.setState({
                    params: tempParams
                })
                await this.props.getDataSource(tempParams)
                await this.props.getPropertyBankDetails()
                const { bankDetails } = this.props;
                let bankDetailArray = []
                bankDetailArray.push({ value: "exclude", label: "Exclude from Cruz Control" })
                bankDetails && bankDetails.length && bankDetails.map(e => (bankDetailArray.push({
                    value: e._id,
                    label: e.propertyName
                })));
                bankDetailArray.push({ value: "stop", label: "Stop Importing" })
                this.setState({
                    bankArray: bankDetailArray
                })
            }
        }
        if (this.state.isSubmitted && preProps.updateImportDetails !== this.props.updateImportDetails
            && !this.props.updateImportDetails.success) {
            toastr.success(this.props.updateImportDetails.message)
        } else if (this.state.isSubmitted && preProps.updateImportDetails !== this.props.updateImportDetails
            && this.props.updateImportDetails.success) {
            toastr.success(this.props.updateImportDetails.message);
        }
    }




    // useEffect(() => {
    //     if (prevLimit != props.commonReducer.limitData) {
    //         const { limitData } = props.commonReducer
    //         if (limitData) {
    //             let limitCount = limitData.countInPage && limitData.countInPage.length && limitData.countInPage.find(l => l.type === 7)
    //             console.log("limitData", limitCount)
    //             let tempParams = params
    //             tempParams.limit = limitCount ? limitCount.count : 25
    //             setParams(tempParams)
    //             props.getDataSource(tempParams)
    //             props.getPropertyBankDetails()
    //         }
    //     }

    // }, [prevLimit, props.commonReducer.limitData]);


    // this.setState(bankDetailArray);



    // useEffect(() => {
    //     if (isSubmitted && prevImport !== props.updateImportDetails && !props.updateImportDetails.success) {
    //         toastr.success(props.updateImportDetails.message)
    //     } else if (isSubmitted && prevImport !== props.updateImportDetails && props.updateImportDetails.success) {
    //         toastr.success(props.updateImportDetails.message);
    //     }
    // }, [prevImport, props.updateImportDetails]);

    onPageChanged = async page => {
        let data = this.state.params
        let offset = ((page - 1) * this.state.params.limit) || 0;
        data.offset = offset;

        this.setState({
            params: data,
            currentPage: page
        })
        await this.props.getDataSource(data);
    }

    handleChangeBankDetails = async (details, value) => {
        if (value) {
            let data = {
                status: "0",
                inAccountId: value._id,
            }

            if (details.value == 'stop') {
                data = {
                    ...data,
                    status: "2",

                }
            } else if (details.value !== 'exclude') {
                data = {
                    ...data,
                    propertyId: details.value,
                    status: "1",
                }
            }
            this.setState({
                accountDetails: data.value
            })
            await this.props.updateBankDetails(data)
            await this.props.getPropertyBankDetails()
            await this.props.getDataSource(this.state.params)

            const { bankDetails } = this.props;
            let bankDetailArray = []
            bankDetailArray.push({ value: "exclude", label: "Exclude from Cruz Control" })
            bankDetails && bankDetails.length && bankDetails.map(e => (bankDetailArray.push({
                value: e._id,
                label: e.propertyName
            })));
            bankDetailArray.push({ value: "stop", label: "Stop Importing" })
            this.setState({
                bankArray: bankDetailArray
            })
        }
    }

    handleImport = async () => {
        this.setState({
            isSubmitted: true
        })
        await this.props.importData()
        this.setState({
            isSubmitted: false
        })
    }

    openConfirm = () => {
        this.setState({
            isConfirm: true
        })
    }

    closeConfirm = () => {
        this.setState({
            isConfirm: false
        })
    }

    renderConfirm = () => {
        return <Confirm
            title={"Change Account"}
            onCancel={this.closeConfirm}
            message={'All your data will lost by changing!!!  Are you sure you want to continue?'}
            plaidConfirm={true}
            handleOnExit={this.handleOnExit}
            handleOnSuccess={this.handleOnSuccess}
        />
    }

    handleOnSuccess = async (public_token, metadata) => {
        //send token to client server
        // axios.post("/auth/public_token", {
        //     public_token: public_token
        // });
        const { params } = this.state
        this.setState({
            isConfirm: false,
            isSubmitted: true
        })

        await this.props.postPlaidRequest({ publicToken: public_token })
        this.setState({
            isSubmitted: false
        })
        await this.props.getProfile();
        await this.props.getDataSource(params)
        await this.props.getTransaction(params)
    }

    handleOnExit = () => {
        this.setState({
            isSubmitted: false
        })
        // handle the case when your user exits Link
        // For the sake of tutorial, we're not going to be doing anything here.
    }


    handleChangeLimit = async (e) => {
        const { params } = this.state
        const { name, value } = e.target
        let tempParams = params
        tempParams.limit = value
        tempParams.offset = 0
        this.setState({
            params: tempParams
        })
        await this.props.updateLimitData({ type: 7, count: value });
        await this.props.getDataSource(tempParams)
        await this.props.getTransaction(tempParams)
    }

    promiseOptionsDataSource = async val => {

        await this.props.getPropertyBankDetails({ propertyName: val })
        const { bankDetails } = this.props;
        let bankDetailArray = []
        bankDetailArray.push({ value: "exclude", label: "Exclude from Cruz Control" })
        bankDetails && bankDetails.length && bankDetails.map(e => (bankDetailArray.push({
            value: e._id,
            label: e.propertyName
        })));
        bankDetailArray.push({ value: "stop", label: "Stop Importing" })
        this.setState({
            bankArray: bankDetailArray
        })


        return bankDetailArray.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }
    render() {
        const { params, bankArray, isSubmitted, isConfirm, currentPage } = this.state
        return (
            <div className="tenants-otr">
                {isSubmitted && <LoaderSpinner />}
                {isConfirm && <Modal body={this.renderConfirm()} onCancel={this.closeConfirm} />}

                <div className="innago-table-top">
                    <div className="lft">
                        <h1>Data Source</h1>
                    </div>

                    <div className="rgt addTransctnbtn">

                        {this.props.profileDetail.profileDetail && this.props.profileDetail.profileDetail.admin.plaidAccessToken ?
                            <button className="add" onClick={this.openConfirm}>
                                <i className="fas fa-plus"></i> Change Account
                        </button>
                            : <PlaidLinkButton
                                clientName="React Plaid Setup"
                                env={PLAID_ENV}
                                product={["auth", "transactions"]}
                                publicKey={PLAID_KEY}
                                onExit={this.handleOnExit}
                                onSuccess={this.handleOnSuccess}
                                className="add plaidbtn"
                            >
                                <button className="add">
                                    <i className="fas fa-plus"></i> Add Account
                            </button>
                            </PlaidLinkButton>}

                        <button onClick={this.handleImport} className="add mr-1"
                            disabled={this.props.profileDetail.profileDetail &&
                                !this.props.profileDetail.profileDetail.admin.plaidAccessToken}>Update Transactions</button>
                        <div className="col-md-1 formsectionPadding pr-1">
                            {/* <div className="selctOtr"> */}
                            {/*<input type={"text"} class="form-control" value={props.limit} name={"limit"} onChange={props.handleChange}/>*/}
                            <select className="form-control dts-sel" value={params.limit} name={"limit"}
                                onChange={this.handleChangeLimit}>
                                <option value={15}>15</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                <div className={"dataSourcesOuter"}>
                    <div className="innago-table table-main dataSourcesTable ">
                        <div >
                            <table className="table-gap table-td">
                                <tr className="table-head">
                                    <th className="">Account</th>
                                    <th className="">Account Number</th>
                                    <th className="">Actions</th>
                                </tr>
                                {this.props.dataSources && this.props.dataSources.accounts &&
                                    this.props.dataSources.accounts.length ? this.props.dataSources.accounts.map((source, key) => {
                                        return (<tr key={key}>
                                            <td>
                                                <p>
                                                    {source.name ? source.name : null}
                                                </p>
                                            </td>
                                            <td>
                                                <div className="transactionDetails">
                                                    {source.accountId ? source.accountId : null}
                                                    <p className="transactionTime">{source.createdAt ? moment(source.createdAt).fromNow() : null}</p>
                                                </div>
                                            </td>
                                            <td className="th-dts-srch">
                                                {/* <select className="form-control"
                                                onChange={(e) => handleChangeBankDetails(e, source)}
                                                value={source.property ? source.property._id : source.status === 0 ? "exclude" : source.status === 2 ? "stop" : null}>

                                                <option value={null}>Select</option>
                                                {source.property ? <option
                                                    value={source.property._id}>{source.property.propertyName}</option> : null}
                                                <option value={"exclude"}>Exclude from Cruz Control</option>
                                                {props.bankDetails && props.bankDetails.length ? props.bankDetails.map((bd, key) => {
                                                    return <option value={bd._id}>{bd.propertyName}</option>
                                                }) : null}
                                                <option value={"stop"}>Stop Importing</option>

                                            </select> */}
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions={bankArray}
                                                    placeholder={"Search"}
                                                    loadOptions={this.promiseOptionsDataSource}
                                                    onChange={(data) => this.handleChangeBankDetails(data, source)}
                                                    value={source.property ? { value: source.property._id, label: source.property.propertyName }
                                                        : source.status === 0 ? { value: "exclude", label: "Exclude from Cruz Control" }
                                                            : source.status === 2 ? { value: "stop", label: "Stop Importing" } : null}
                                                // menuIsOpen={true}
                                                />
                                            </td>
                                        </tr>

                                        )
                                    }) : <td colSpan={4} className={"text-center no_value_table"}><i
                                        className="far fa-thumbs-down"></i>No
                                                                                                                            Data Found!!!
                            </td>}

                            </table>
                        </div>

                        <div className="paginationSec">

                            {this.props.dataSources && this.props.dataSources.totalAccounts ? <Pagination
                                totalRecords={this.props.dataSources.totalAccounts}
                                pageLimit={params.limit}
                                pageNeighbours={1}
                                currentPage={currentPage}
                                onPageChanged={this.onPageChanged}
                            /> : null}

                        </div>

                    </div>
                </div>
            </div>

        )
    }
}



const mapStateToProps = state => {
    const { plaidReducer, profileReducer, commonReducer } = state;
    return {
        dataSources: plaidReducer.dataSources,
        bankDetails: plaidReducer.bankDetails,
        profileDetail: profileReducer.profileDetail,
        updateImportDetails: plaidReducer.updateImportDetails,
        commonReducer


    };
};

export default connect(mapStateToProps, {
    ...action, ...ProfileAction, ...commonAction
})(DataSource);

