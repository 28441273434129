import React from "react";
import action from "../action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"
import ChatItem from "./ChatItem";
import IncomingMessage from "./IncomingMessage";
import OutgoingMessage from "./OutgoingMessage";

class SmsContainer extends React.Component {
    render() {
        const {
            chatList,
            handleChatSelect,
            chatReceiverId,
            currentRecName,
            query,
            handleRemoveNewChat,
            renderNewChat,
            tempTenantPhoneNumbers,
            chatMessageDetails,
            chatMessage,
            keyPressed,
            handleSendMessage,
            manageMessageOnChange,
            hasNotRecSmsResp,
            tempMessageContent,
            handleCheck,
            checkedArray,
            openDeleteMessageConfirm,
            handleCheckAllMessage,
            deleteSelectedMessage,
            chatReceiverPhoneNo
        } = this.props;

        return (
            <div className="messageOuter">
                <div className="sendemailOuter chatOuter">
                    <div className="searchOtr">
                        <input className="form-control" placeholder="Search" type="text" />
                        <img src="/images/search.png" className="searchIcn" alt="" />
                    </div>
                    <div className="inboxInner">
                        {/* <div className="checkboxPadding "><label className="invoiceCheckbox "
                            style={{ marginLeft: "9px" }}>
                            <input
                                type="checkbox"
                                name="isArchived"
                                // disabled={props.status}
                                defaultChecked={this.props.isCheckAll ? true : false}
                                onChange={(e) => handleCheckAllMessage(e)}
                            />
                            <span className="checkmark "></span>
                        </label></div> */}

                        {/* <button className={"btn  btn-add"} >Delete</button> */}
                    </div>
                    <div className="chatList">
                        {chatList && chatList.length ? <><div class="col-md-12 dlt-icn">
                            <label class="transactionCheckbox">
                                <input type="checkbox"
                                    name="isArchived"
                                    // disabled={props.status}
                                    defaultChecked={this.props.isCheckAll ? true : false}
                                    onChange={(e) => handleCheckAllMessage(e)}
                                />
                                <span class="checkmark"></span>
                            </label>
                            <div onClick={openDeleteMessageConfirm}>
                                <i class="fas fa-trash" >
                                </i> Delete</div>
                        </div></> : null}

                        {(chatList && chatList.length)
                            ? chatList.map((item, i) => {
                                let isChecked = this.props.checkedArray && this.props.checkedArray.length ?
                                    this.props.checkedArray.find(da => da.id == item._id) : false

                                return <ChatItem
                                    handleChatSelect={handleChatSelect}
                                    chatContents={item}
                                    key={i}
                                    chatReceiverId={chatReceiverId}
                                    handleCheck={handleCheck}
                                    checkedArray={checkedArray}
                                    isChecked={isChecked}
                                    deleteSelectedMessage={deleteSelectedMessage}
                                    chatReceiverPhoneNo={chatReceiverPhoneNo}



                                />
                            })
                            : <div className="no_value"><i className="far fa-thumbs-down" /> No Data Found!
                            </div>
                        }
                    </div>
                </div>
                <div className="emailOuter chatSec">
                    <div className="inboxInner">
                        <div className="email-img">
                            {query.newChat ? <span>New Conversation</span> :
                                <span>{currentRecName}</span>}
                        </div>
                        <div className="chatDelete">
                            <a>
                                {query.newChat
                                    ?
                                    <i className="fas fa-trash" onClick={() => handleRemoveNewChat()} />
                                    : null
                                }
                            </a>
                        </div>
                    </div>
                    {renderNewChat(tempTenantPhoneNumbers)}
                    <div className="inboxWrapper">
                        <div className="inboxMail">
                            <div className="messagesSec">
                                <div className="messageHistory" id="messageHistory">
                                    {!query.newChat
                                        ? <>
                                            {(chatMessageDetails && chatMessageDetails.length)
                                                ? chatMessageDetails.map((item, i) => {
                                                    return item.senderRef === 'AdminUser'
                                                        ? <OutgoingMessage
                                                            messageDetails={item}
                                                            key={i}
                                                        />
                                                        : <IncomingMessage
                                                            messageDetails={item} key={i}
                                                        />
                                                })
                                                : <div className="no_value"><i className="far fa-thumbs-down" /> No Data
                                                    Found!
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {hasNotRecSmsResp
                                        ?
                                        <OutgoingMessage
                                            hasNotRecSmsResp={hasNotRecSmsResp}
                                            tempMessageContent={tempMessageContent}
                                        />
                                        : null
                                    }
                                </div>
                                <div className="submitMsg">
                                    <div className="inputMsgSec">
                                        <input
                                            type="text"
                                            className="inputMsg"
                                            placeholder="Type a message"
                                            value={chatMessage}
                                            onKeyPress={keyPressed}
                                            // onChange={evt => this.setState({chatMessage: evt.target.value})}
                                            onChange={(evt) => manageMessageOnChange(evt.target.value)}
                                        />
                                        <button className="msgSendBtn" type="button"
                                            onClick={handleSendMessage}><i
                                                className="fas fa-paper-plane" /></button>
                                    </div>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    const { messageReducer } = state;
    return { messageReducer }
};

export default withRouter(connect(mapStateToProps, { ...action })(SmsContainer));