import React, { useState, useEffect } from "react";
import LoaderSpinner from "../../../Common/components/Loader";
// import {ToastContainer, toast} from "react-toastify";
import toastr from '../../../Common/components/Toaster'
import tenantAction from "../../../Tenants/action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import AddTenants from "../../../Tenants/components/AddTenants";
import Modal from '../../../Common/components/Modal';
import history from "../../../../config/history";
import TenantDetailLease from '../../../Tenants/components/TenantDetailLease';
import usePrevious from "../../../Common/components/prevProps";
import ConfirmLease from '../../../Tenants/components/ConfirmLease';
import TemplateListing from '../../../Tenants/components/templateListModal'
import settingsAction from '../../../Settings/action';
import LeaseEdit from '../../../Tenants/components/leaseEditModal';
import SignLease from "../../../Leases&Files/components/signModal";
import Confirm from '../../../Common/components/Confirm';



const Tenants = props => {

    const [tenantModal, setTenantModal] = useState(false);
    const [editId, setEditId] = useState(false);
    const [modal, setModal] = useState(false);
    const [tenantId, setTenantId] = useState('');
    const [submitted, setSubmitted] = useState(true);
    const [submitLease, setSubmitLease] = useState(false);
    const [viewLeaseAgreement, setViewLeaseAgreement] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [templateData, setTemplateData] = useState(null)
    const [isButtonDisabled, setIsButton] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLeaseEdit, setIsLeaseEdit] = useState(false);
    const [isSign, setIsSign] = useState(false);
    const [leaseSign, setLeaseSign] = useState(null);
    const [leaseSignModal, setLeaseSignModal] = useState(null);
    const [addTenant, setAddTennat] = useState(false);


    const prevTemplateEdit = usePrevious(props.tenantReducer.leaseEdit);
    const { commonReducer: { emailCheck } } = props;
    const prevEmailCheck = usePrevious(emailCheck);


    useEffect(() => {
        async function renderCall() {
            await props.getTenant({ limit: 10, isDeleted: false, isClosed: false })
            await props.getTemplates()

            setSubmitted(false)
        }
        renderCall();
    }, []);

    useEffect(() => {
        if (addTenant && !emailCheck.success) {
            toastr.error("Please add a notification email!")
        } else if (addTenant && emailCheck.success && prevEmailCheck != emailCheck) {
            props.getTenantId(editId);
            setViewLeaseAgreement(false);
            setTenantModal(true);
            setEditClicked(false);
        }
    }, [prevEmailCheck, emailCheck]);

    useEffect(() => {
        if (isSubmitted && prevTemplateEdit != props.tenantReducer.leaseEdit) {
            if (props.tenantReducer.leaseEdit.success) {
                setIsLeaseEdit(true)
            }
            else {
                toastr.error("Error while adding lease")
            }
        }
    }, [prevTemplateEdit, props.tenantReducer.leaseEdit])

    const { tenantReducer: { lease } } = props;
    const prevLease = usePrevious(lease);

    useEffect(() => {
        if (submitLease && !lease.success) {
            toastr.error(lease.message)

        } else if (submitLease && lease.success && prevLease != lease) {
            toastr.success(lease.message);
            setIsSign(true)
            setLeaseSign(lease.lease)
        }
    }, [prevLease, lease]);

    const onOpenModal = async (id) => {
        setEditId(id);
        setAddTennat(true)
        await props.emailCheck({ emailType: "notification" })

    }

    const onCloseModal = async () => {
        setTenantModal(false);
        await props.getProperty({ limit: 10 })
    }

    const renderModal = () => {
        return <AddTenants
            onCancel={onCloseModal}
            editId={editId}
            leaseModal={leaseModal}
            tenantDetail={tenantDetail && tenantDetail}
        />
    }

    const leaseModal = (id) => {
        const { settingsReducer: { templateDetails } } = props;
        if (templateDetails && templateDetails.length) {
            setIsButton(true)
            if (templateDetails.length === 1) {
                setModal(true);
                setTemplateData(templateDetails[0])
                setTemplateId(templateDetails[0]._id)
            }
            else {
                setModal(true);
            }
            setTenantId(id);

        }
        else {
            toastr.error("Please add a template to send Lease!!")
        }

    }

    const closeModalTemplate = () => {
        setTemplateModal(false)
        setIsButton(false)
        setIsSubmitted(false)
        setIsSubmitted(false)
        setTemplateId(null)
    }

    const renderTemplateList = () => {
        const { settingsReducer: { templateDetails } } = props;
        return <TemplateListing
            onCancel={closeModalTemplate}
            templateDetails={templateDetails}
            submitTemplate={submitTemplate}
            handleChangeTemplate={handleChangeTemplate}
            templateId={templateId}

        />
    };

    const submitTemplate = async () => {
        setTemplateModal(false)
        setModal(false)
        setIsSubmitted(true)
        setSubmitLease(true)
        // await props.leaseAgreement({ tenantId: tenantId, templateId: templateId });
        await props.editLeaseAgreement({ tenantId: tenantId, templateId: templateId });
        setTimeout(() => {
            setIsSubmitted(false)
            setSubmitLease(false)
        }, 1000);

    }

    const handleChangeTemplate = (e, details) => {

        if (e.target.checked) {
            setTemplateId(details._id)
            setTemplateData(details)
        }
        else {
            setTemplateId(null)
            setTemplateData(null)
        }
    }


    const closeModal = () => {
        setModal(false);

    }

    const renderModalLease = () => {
        return <ConfirmLease
            title='Lease Agreement'
            message='Do you want to send lease agreement?'
            close={closeModal}
            onConfirm={leaseAgreement}
            onView={viewAgreement}
            isButtonDisabled={true}
        />
    }

    const leaseAgreement = async () => {
        setTemplateModal(true)
        setViewLeaseAgreement(false);
        setModal(false);
        // setSubmitLease(true);
        // await props.leaseAgreement({ tenantId: tenantId, templateId: templateId });
        // setTimeout(() => {
        //     setViewLeaseAgreement(false);
        //     setSubmitLease(false);
        //     setTemplateId(null)
        // }, 100);
    }

    //View lease agreement modal
    const viewAgreement = async () => {
        setSubmitLease(true);
        setTemplateModal(true)
        // setModal(false);
        // await props.viewLeaseAgreement({ tenantId: tenantId });
        setTimeout(() => {
            setSubmitLease(false);
            setViewLeaseAgreement(true);
        }, 1000);
    }

    const closeViewLeaseModal = () => {
        setViewLeaseAgreement(false);
    }

    const renderViewLeaseModal = () => {
        return <TenantDetailLease
            tenantId={tenantId}
            onCancel={closeViewLeaseModal}
            // lease={props.tenantReducer.lease}
            lease={templateData.templateContent}
            leaseAgreement={leaseAgreement}
            closeModal={onCloseModal}
            onOpenModal={onOpenModal}
        />
    }

    const closeModalLeaseEdit = () => {
        setIsLeaseEdit(false);
        setIsButton(false)

    }

    const renderModalLeaseEdit = () => {

        return <LeaseEdit
            templateData={props.tenantReducer.leaseEdit}
            onCancel={closeModalLeaseEdit}
            addEditedLeaseData={addEditedLeaseData}
            editId={props.tenantReducer.leaseEdit._id}
        />
    }

    const addEditedLeaseData = async (data) => {
        setSubmitLease(true);
        setIsLeaseEdit(false);
        await props.sendLeaseAgreement(data, { templateId, tenantId })

        await props.getTenant({ limit: 10, offSet: 0, isDeleted: false, isClosed: false });
        setSubmitLease(false);
        setIsSubmitted(false);

    }


    const renderSignConfirm = () => {
        return <Confirm
            title='Sign Lease'
            message='Do you want to sign now?'
            onCancel={closeSignConfirm}
            onConfirm={signLease}
        />
    }

    const closeSignConfirm = () => {
        setIsSign(false)
    }

    const signLease = () => {
        setIsSign(false)
        setLeaseSignModal(true)
    }

    const closeSignLease = async () => {
        setLeaseSignModal(false)
        await props.getTenant({ limit: 10, isDeleted: false, isClosed: false })
    }

    const renderSignLease = () => {
        return <SignLease
            onCancel={closeSignLease}
            lease={leaseSign.documentData}
            leaseId={leaseSign._id}
        />
    }



    const { tenantReducer: { tenants, tenantsById: { tenant: tenantDetail } } } = props;

    return (
        <div
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-6 col-sm-12 occupancy tenant mb-3">
            {/* <ToastContainer/> */}
            {editClicked && <LoaderSpinner />}
            {submitLease && <LoaderSpinner />}
            {submitted && <LoaderSpinner />}
            {tenantModal ? <Modal body={renderModal()} onCancel={onCloseModal} /> : null}
            {templateModal ? <Modal body={renderTemplateList()} onCancel={closeViewLeaseModal} /> : null}
            {modal ? <Modal body={renderModalLease()} onCancel={closeModal} /> : null}
            {isLeaseEdit ? <Modal body={renderModalLeaseEdit()} onCancel={closeModalLeaseEdit} /> : null}
            {leaseSignModal ? <Modal body={renderSignLease()} onCancel={closeSignLease} /> : null}
            {isSign ? <Modal body={renderSignConfirm()} onCancel={closeSignConfirm} /> : null}
            {viewLeaseAgreement ? <Modal body={renderViewLeaseModal()} onCancel={closeViewLeaseModal} /> : null}

            <div className="occupancyOtr">
                <div className="headerSection d-flex">
                    <h3>Tenants
                         {/* <span>({tenants.count ? tenants.count : 0})</span> */}
                    </h3>
                    <div className="viewMore" onClick={() => history.push('/Tenants')}>
                        <img src="images/more.png" /><span>View More</span>
                    </div>
                </div>
                <div className="outerTenant ">
                    {tenants.tenantList && Object.values(tenants.tenantList).length ?
                        Object.values(tenants.tenantList).slice(0, 3).map((tenant, i) => {
                            return <div className="listSec">
                                <div className="txtNameLet" onClick={() => history.push(`/Tenants/${tenant._id}/Summary`)}>
                                    {tenant.firstName.charAt(0).toUpperCase() +
                                        tenant.lastName.charAt(0).toUpperCase()}
                                </div>
                                <div className="ContDtl" onClick={() => history.push(`/Tenants/${tenant._id}/Summary`)}>
                                    <h3> {tenant.firstName.charAt(0).toUpperCase() + tenant.firstName.slice(1) + " " +
                                        tenant.lastName.charAt(0).toUpperCase() + tenant.lastName.slice(1)}</h3>
                                    <h5>
                                        <img src="images/email.png" />{tenant.email}
                                    </h5>
                                    <h5 className="phone"><img src="images/Phone.png" /> {tenant.phoneNumber}</h5>

                                </div>
                                <div className="iconSec">
                                    <div className="dropdown moveDrop">
                                        <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                            data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i className="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink"
                                            style={{ cursor: "pointer" }}>
                                            <a className="dropdown-item"
                                                onClick={() => history.push(`/Tenants/${tenant._id}/Summary`)}><i
                                                    className="far fa-eye"></i> View</a>
                                            <a className="dropdown-item" onClick={() => {
                                                setEditClicked(true)
                                                onOpenModal(tenant._id)
                                            }}><i
                                                className="far fa-edit"></i> Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                        :
                        <div className="outerTenant no_value"><i className="far fa-thumbs-down"></i>No data found!</div>
                    }
                </div>
                < button
                    className="btnadd"
                    onClick={() => onOpenModal()}>
                    <i className="fas fa-plus">
                    </i> Add Tenant
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer, settingsReducer } = state;
    return {
        tenantReducer,
        commonReducer,
        settingsReducer
    };
};

export default connect(mapStateToProps, {
    ...tenantAction, ...commonAction, ...settingsAction
})(Tenants);
