import React from "react";
import action from "../action";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import CreditCardInput from 'react-credit-card-input';
import * as validation from '../../Common/components/validation';
import toastr from '../../Common/components/Toaster';
import PersonalInformation from './personalInformation';
import IncomeDetails from './incomeDetails';
import DocumentUpload from './documentUpload';
import isImage from "is-image";
import {formatUsPhone} from '../../Common/components/utils';
import Confirm from "../../Common/components/Confirm";
import Modal from "../../Common/components/Modal";


class Report extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        stateCode: '',
        postalCode: '',
        dob: '',
        address: '',
        city: '',
        socialSecurityNumber: '',
        currentEmployer: '',
        incomeStartdate: '',
        occupation: '',
        monthlyIncome: '',
        contactName: '',
        companyNumber: '',
        incomeAmount1: '',
        incomeAmount2: '',
        description1: '',
        description2: '',
        name: '',
        expiry: '',
        cvv: '',
        number: '',
        submitted: '',
        proprtyId: {},
        moveInDate: '',
        Properties: [],
        propertyId: this.props.match.params.id,
        submitSuccess: false,
        currentPage: 1,
        bankName: '',
        isSubmitted: false,
        reportDocuments: [],
        addedCharacter: false,
        disableSubmit: true,
        isConfirmModal: false,
        property: '',
        Units: [],
        unitId: ''

    }

    componentDidMount = async () => {
        const {
            match: {
                params: {email}
            }
        } = this.props;
        this.setState({
            email: email
        })
        await this.props.getVacantProperties({isAvailable:1});
        this.setState({
            moveInDate: new Date()
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {screeningReducer: {screening}} = this.props;
        const {isSubmitted} = this.state;
        const {screeningReducer: {screening: prevScreening}} = prevProps;
        if (isSubmitted && prevScreening !== screening && !screening.success) {

            toastr.error(screening.message);
        } else if (isSubmitted && prevScreening != screening && screening.success) {

            toastr.success("Successfully added the data, we will contact you.");
        }
    }

    viewNextPage = () => {
        const {
            moveInDate, propertyId, firstName, lastName, email, phoneNumber, stateCode, address, city,
            dob, socialSecurityNumber, postalCode, currentPage
        } = this.state;
        this.setState({
            //isSubmitted: true,
            currentPage: currentPage + 1
        })
        // if(this.state.currentPage == 1 && moveInDate && propertyId && firstName && lastName && email && phoneNumber && stateCode && address
        //     && city && dob && socialSecurityNumber && postalCode){
        //         this.setState({
        //             //isSubmitted: true,
        //             currentPage: currentPage + 1
        //         })
        //     }
    }

    viewPreviousPage = () => {
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    promiseOptionsProperty = async val => {
        await this.props.getProperties({name: val, isIncluded: 1, status: 1})
        const {screeningReducer: {getProperty}} = this.props;
        const Properties = getProperty.success && getProperty.property.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        this.setState({Properties});
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }


    onChangeProperty = async (e) => {
        this.setState({propertyId: e});
    }

    onChangeIncomeStartdate = value => {
        this.setState({
            incomeStartdate: value
        })
    }

    validate = (value, name, type) => {
        const {isSubmitted} = this.state;
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div>
                case "number":
                    return <div className={"errorMsg"}>{validation.NUMBER_ONLY(value, name)}</div>
                case "email":
                    return <div className={"errorMsg"}>{validation.EMAIL(value, name)}</div>
                case "letter":
                    return <div className={"errorMsg"}>{validation.LETTER_ONLY(value, name)}</div>
                case "cardNumber":
                    return <div className={"errorMsg"}>{validation.CARD_NUMBER(value, name)}</div>
                case "code":
                    return <div className={"errorMsg"}>{validation.CODE(value, name)}</div>
                case "ssn":
                    return <div className={"errorMsg"}>{validation.SSN(value, name)}</div>
                case "zip":
                    return <div className={"errorMsg"}>{validation.ZIP_CODE(value, name)}</div>
                case "phone":
                    return <div className={"errorMsg"}>{validation.PHONE_NO(value.replace(/\D/g, ''), name)}</div>
            }
        } else return null
    }

    handleChange = async (e) => {
        const {name, value} = e.target
        if (name == 'phoneNumber' || name == 'companyNumber') {
            if (isNaN(value)) {
                this.setState({
                    [name]: formatUsPhone(value),
                    addedCharacter: true
                });
            } else {
                this.setState({
                    [name]: formatUsPhone(value)
                });
            }

        } else {
            this.setState({
                [name]: value
            });
        }
    }

    onChangeDob = value => {
        this.setState({
            dob: value
        })
    }

    onChangeMoveInDate = value => {
        this.setState({
            moveInDate: value
        })
    }

    handleCardNumberChange = e => {
        this.setState({
            number: e.target.value
        })
    }

    handleCardExpiryChange = (e) => {
        this.setState({
            expiry: e.target.value
        })
    }

    handleCvvChange = (e) => {
        this.setState({
            cvv: e.target.value
        })
    }

    changeDocument = (e) => {
        const {files} = e.target;
        this.setState({
            reportDocuments: [
                ...this.state.reportDocuments,
                ...files
            ]
        })
    }

    handleChangeDate = (e) => {
        e.preventDefault();

    }

    handleSubmit = async () => {

        this.setState({isSubmitted: true});

        const {
            moveInDate, propertyId, firstName, lastName, email, phoneNumber, expiry, name, number, cvv, bankName, stateCode, address, city,
            dob, socialSecurityNumber, postalCode, currentEmployer, occupation, monthlyIncome, contactName, companyNumber, incomeAmount1,
            incomeAmount2, description1, description2, reportDocuments, incomeStartdate
        } = this.state;

        //data to send for adding report
        const req = {
            _id: this.props.reports && this.props.reports._id,
            moveInDate:moment(new Date(moveInDate)).endOf('day').toISOString(),
            propertyId: propertyId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phoneNumber ? phoneNumber.replace(/\D/g, '') : '',
            stateCode: stateCode,
            postalCode: postalCode,
            dob: moment(new Date(dob)).endOf('day').toISOString(),
            address: address,
            city: city,
            socialSecurityNumber: socialSecurityNumber,
            cardDetails: {
                expiry: expiry,
                name: name,
                number: number,
                bankName: bankName,
                cvv: cvv
            },
            currentEmployer: currentEmployer,
            occupation: occupation,
            monthlyIncome: monthlyIncome && monthlyIncome.toString().replace("$", "").replace(/,/g, ''),
            incomeStartdate: moment(new Date(incomeStartdate)).format('YYYY-MM-DD'),
            contactName: contactName,
            companyNumber: companyNumber ? companyNumber.replace(/\D/g, '') : '',
            otherIncome: [
                {
                    incomeAmount: incomeAmount1 && incomeAmount1.toString().replace("$", "").replace(/,/g, ''),
                    description: description1
                },
                {
                    incomeAmount: incomeAmount2 && incomeAmount2.toString().replace("$", "").replace(/,/g, ''),
                    description: description2
                }
            ],
            reportDocuments: reportDocuments
        }

        if (firstName && lastName && email && phoneNumber && stateCode && postalCode && dob && city && socialSecurityNumber && propertyId &&
            moveInDate && expiry && name  && number && validation.EMAIL(email, 'email') == true &&
            validation.LETTER_ONLY(firstName, 'firstName') == true &&
            validation.LETTER_ONLY(lastName, 'lastName') == true &&
            validation.LETTER_ONLY(name, 'cardName') == true &&
            validation.SSN(socialSecurityNumber, 'socialSecurityNumber') == true &&
            validation.ZIP_CODE(postalCode, 'postalCode') == true &&
            validation.CODE(stateCode, 'stateCode') == true &&
            validation.PHONE_NO(phoneNumber.replace(/\D/g, ''), 'phoneNumber') == true
        ) {

            await this.props.addScreening(req);

            if (this.props.screeningReducer.screening.success) {
                this.setState({
                    isSubmitted: false,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    stateCode: '',
                    postalCode: '',
                    dob: '',
                    address: '',
                    city: '',
                    socialSecurityNumber: '',
                    name: '',
                    expiry: '',
                    cvv: '',
                    number: '',
                    moveInDate: '',
                    propertyId: '',
                    disableSubmit: true,
                    currentEmployer: '',
                    incomeStartdate: '',
                    occupation: '',
                    monthlyIncome: '',
                    contactName: '',
                    companyNumber: '',
                    incomeAmount1: '',
                    incomeAmount2: '',
                    description1: '',
                    description2: '',
                    reportDocuments: []
                })
            }
        }
    }
    onOpenConfirmation = () => {
        const {name, expiry, cvv, number} = this.state
        if (name && expiry  && number) {
            this.setState({
                isConfirmModal: true
            })
        } else {
            toastr.error('Please Enter Card Details!!')
        }
    }

    closeConfirmModal = () => {
        this.setState({
            isConfirmModal: false
        })
    }

    renderModalConfirm = () => {
        return <Confirm
            title='Pay Application Fee'
            message='Are you sure you want to pay from your credit card?'
            onCancel={this.closeConfirmModal}
            onConfirm={this.payFee}
        />
    }


    payFee = async () => {
        const {name, expiry, cvv, number} = this.state
        if (name && expiry  && number) {
            let paymentData = {
                cardName: name,
                cardNumber: number,
                cvv: cvv,
                expiryDate: expiry
            }
            await this.props.makePayMent(paymentData)
            if (this.props.screeningPayment.success) {
                toastr.success(this.props.screeningPayment.message)
                this.setState({
                    disableSubmit: false,
                })
            } else {
                toastr.error(this.props.screeningPayment.message)
            }
        }
        this.setState({
            isConfirmModal: false
        })
    }


    promiseOptionsProperty = async val => {
        await this.props.getProperties({name: val, isIncluded: 1, status: 1})
        const {screeningReducer: {propertyDetails}} = this.props;
        const Properties = propertyDetails.success && propertyDetails.property.map(e => ({
            value: e._id,
            label: e.propertyName
        }));
        this.setState({Properties});
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }


    onChangeProperty = async (e) => {
        if (e) {
            await this.props.getAllUnitProperties(e.value)
            const {screeningReducer: {unitDetails:{unitList}}} = this.props;
            let units=unitList
            this.setState({propertyId: e.value, property: e, Units: units});
        }
    }

    render() {

        const {screeningReducer: {getProperty, propertyDetails}} = this.props;

        const {
            match: {
                params: {id}
            }
        } = this.props;

        const Properties = propertyDetails && propertyDetails.success &&
            propertyDetails.propertyLists && propertyDetails.propertyLists.length && propertyDetails.propertyLists.map(e => ({
                value: e._id,
                label: e.propertyName
            }));


        return (
            <React.Fragment>
                {/* {this.state.submitSuccess && <LoaderSpinner />} */}
                <nav className="navbar navbar-expand-lg col-md-12">
                    {this.state.isConfirmModal ?
                        <Modal body={this.renderModalConfirm()} onCancel={this.closeConfirmModal}/> : null}
                    <div className="container">
                        <a className="navbar-brand logo " href="#"><img src="/images/logo.png"/></a>
                    </div>
                </nav>
                <div className="otrprop">
                    <div className="col-md-12 blue-navsection">
                        <div className="container">
                            <div className="outer-detail d-flex ">
                                <div className="cruzhed">
                                    <h3>Cruz Properties</h3>
                                </div>
                                <div className="cruzesub">
                                    <h3>Rental Applications</h3>
                                    <p>Powered by CruzRentals</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 property-image-sec">
                        <div className="container">
                            <div className="property-otr d-flex  col-md-10 m-auto p-0">
                                <div className="col-md-7 img-prop">
                                    <img src="/images/image-property.jpg"/>
                                </div>
                                <div className="col-md-5 prop-detail-content">
                                    <h3>{getProperty.property && getProperty.property.propertyName}</h3>
                                    <h4>{getProperty.property && getProperty.property.address}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-outer">
                        <div className="container">
                            <div className="col-md-10 m-auto p-0">
                                <div className="rental-application">
                                    <h3>Please fill the details below</h3>
                                    {this.state.currentPage === 1 ?
                                        <PersonalInformation
                                            status={this.props.status}
                                            onChangeDob={this.onChangeDob}
                                            onChangeMoveInDate={this.onChangeMoveInDate}
                                            state={this.state}
                                            validate={this.validate}
                                            handleChange={this.handleChange}
                                            handleChangeDate={this.handleChangeDate}
                                            isEmailSection={true}
                                            promiseOptionsProperty={this.promiseOptionsProperty}
                                            onChangeProperty={this.onChangeProperty}
                                            Properties={Properties}
                                            Units={this.state.Units}

                                        />
                                        : this.state.currentPage === 2 ?
                                            <IncomeDetails
                                                state={this.state}
                                                validate={this.validate}
                                                handleChange={this.handleChange}
                                                onChangeIncomeStartdate={this.onChangeIncomeStartdate}
                                                handleCardNumberChange={this.handleCardNumberChange}
                                                handleCardExpiryChange={this.handleCardExpiryChange}
                                                handleCvvChange={this.handleCvvChange}
                                            />
                                            :
                                            this.state.currentPage === 3 ?
                                                <DocumentUpload
                                                    state={this.state}
                                                    validate={this.validate}
                                                    handleChange={this.handleChange}
                                                    changeDocument={this.changeDocument}
                                                    onOpenConfirmation={this.onOpenConfirmation}
                                                    handleCardNumberChange={this.handleCardNumberChange}
                                                    handleCardExpiryChange={this.handleCardExpiryChange}
                                                    handleCvvChange={this.handleCvvChange}
                                                />
                                                : null}
                                    <div className="submit-btn">

                                        {this.state.currentPage != 1 ?
                                            <button type="button" className="btn btn-submit mr-2"
                                                    onClick={this.viewPreviousPage}>Previous</button> : null
                                        }
                                        {
                                            this.state.currentPage == 3 ?
                                                <button type="button" className="btn btn-submit"
                                                        onClick={this.handleSubmit}
                                                        disabled={this.state.disableSubmit}>Submit
                                                </button> : null
                                        }
                                        {this.state.currentPage != 3 ?
                                            <button type="button" className="btn btn-submit"
                                                    onClick={this.viewNextPage}>Next
                                            </button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const {screeningReducer} = state;
    return {
        screeningReducer,
        screeningPayment: screeningReducer.screeningPayment
    };
};

export default connect(mapStateToProps, {...action})(Report);

