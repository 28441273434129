import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="col-md-12 text-center ftSec">
                    ©  2020 cruzcontrol. All Rights Reserved.
                </div>
            </footer>
        )
    }
}

export default Footer;
