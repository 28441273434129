/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import toastr from '../../Common/components/Toaster'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import GoogleMapReact from 'google-map-react';

import commonAction from "../../Common/action";
import action from "../action";
import * as validation from '../../../components/Common/components/validation';
import ReactStreetview from 'react-streetview';
import { StreetView } from 'react-google-map-street-view';

import OwlCarousel from 'react-owl-carousel2';
import LoaderSpinner from "../../Common/components/Loader";
import PropertyUnit from "./PropertyUnit";
import { MAIN_ROUTE } from "../../../config/constants";
import PropertyImageModal from "./PropertyImageModal";
import Modal from "../../Common/components/Modal";
import Confirm from "../../Common/components/Confirm";
import MessageBox from "../../Common/components/MessageBox";
import $ from "jquery";
import usePrevious from "../../Common/components/prevProps";

const options = {
    items: 4,
    nav: false,
    rewind: true,
    autoplay: false,
};

let localeData = {};
const LocaleMarker = () => <span> <img src="/images/Map_Marker.png" alt="marker" className="img-map-marker" /> </span>

const DefaultImage = (props) => {
    const [defImg, setDefImg] = useState('/images/def_property.png')
    useEffect(() => {
        let tempImg = props.parseBackgoundImage()
        setDefImg(tempImg);
    }, [props.imagesList, (props.imagesList && props.imagesList.length)])
    return (
        <div
            className="col-md-12 upldFile p-0 bg-prop-def-img"
            // style={{ backgroundImage: `url('${props.parseBackgoundImage()}')` }}
            style={{ backgroundImage: `url('${defImg}')` }}
        >
            <div className="upld-otr d-flex">
            </div>
        </div>
    )
}

const AddPropertyModal = props => {
    // states in the component
    const [property, setProperty] = useState({
        profilePic: "",
        propertyName: "",
        propertyOwner: "",
        status: 1,
        propertyType: '',
        address: "",
        latitude: '',
        longitude: '',
        unit: [],
        unitError: {}
    });
    const [unitDetails, setUnitDetails] = useState({
        unitName: "",
        bedRoom: "",
        bathroom: "",
        squareFeet: "",
        deposit: "",
        monthly: "",
    });
    const [fieldErrors, setFieldErrors] = useState({ imageUpload: '' })
    const [unitValidations, setUnitValidations] = useState([])
    const [isMapUpdated, setIsMapUpdated] = useState(false);
    const [zillowPropImages, setZillowPropImages] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [showPropImage, setShowPropImage] = useState(false);
    const [zillowQueryString, setZillowQueryString] = useState('');
    const [address, setAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [zillowPlaceData, setZillowPlaceData] = useState({});
    const [showZipModal, setShowZipModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const [deletedImages, setDeletedImages] = useState([]);
    const [message, setMessage] = useState(null);
    const [unitErrorAvailable, setunitErrorAvailable] = useState(false);


    const myInput = useRef(null);
    // when component loads
    useEffect(() => {
        async function renderFuntion() {
            await props.getState();
            await props.getAminities();
            await props.getPropertyType();
            await props.getPropertyFeathures();
            await props.getPayment()
        }

        renderFuntion();
    }, []);

    const clickedArrow = (e) => {
        if (e.keyCode == 37) {
            myInput.current && myInput.current.prev();
        }
        if (e.keyCode == 39) {
            myInput.current && myInput.current.next();
        }
    }

    // useEffect(() => {
    //     if(showPropImage){
    //         // myInput.click();
    //         document.getElementById('test').click()
    //     }
    // },[showPropImage])

    // Edit property
    useEffect(() => {
        if (props.propertyDetails) {
            setIsEdit(true)
            const { propertyDetails } = props
            let tempUnitArray = [];
            propertyDetails.Units && propertyDetails.Units.map((unit, index) => {
                let tempUnit = {
                    id: unit._id,
                    unitName: unit.unitName,
                    bedRoom: unit.bedroomCount,
                    bathroom: unit.bathroomCount,
                    squareFeet: unit.squareFeet,
                    deposit: unit.deposit,
                    monthly: unit.monthlyRent,
                    isAvailable: unit.isAvailable
                }
                tempUnitArray.push(tempUnit)
            })
            setProperty({
                ...property,
                profilePic: propertyDetails.propertyImage.length && propertyDetails.propertyImage[0] && (MAIN_ROUTE + propertyDetails.propertyImage[0].path),
                propertyName: propertyDetails.propertyName,
                propertyOwner: propertyDetails.paymentId,
                status: propertyDetails.status,
                propertyType: propertyDetails.propertyTypeId,
                propertyImageId: propertyDetails.propertyImageId[0] ? propertyDetails.propertyImageId[0]._id : '',
                address: propertyDetails.address,
                latitude: propertyDetails.latitude ? parseFloat(propertyDetails.latitude) : 38.9071923,
                longitude: propertyDetails.longitude ? parseFloat(propertyDetails.longitude) : -77.03687070000001,
                unit: tempUnitArray,
            })
            let tempImgArray = []
            propertyDetails.propertyImage.length && propertyDetails.propertyImage.map((image, index) => {
                let tempImg = image && image.type == 'zillo' ? image : image && {
                    ...image,
                    path: (MAIN_ROUTE + image.path)
                }
                tempImgArray.push(tempImg)
            })
            let defImg = tempImgArray.filter(img => img && img.isDefault)
            let filteredArray = tempImgArray.filter(img => img && !img.isDefault)
            filteredArray.unshift(defImg[0])
            setZillowPropImages({
                ...zillowPropImages,
                propImages: filteredArray.filter(img => img && !img.isDeleted),
                propertyImageId: propertyDetails.propertyImageId ? propertyDetails.propertyImageId._id : '',
            })
        }
    }, [props.propertyDetails])

    useEffect(() => {
        setIsMapUpdated(false)
        renderGoogleMaps();
        renderStreetView();
    }, [property.latitude, property.longitude, isMapUpdated])

    // Handling zillow api response
    useEffect(() => {

        if (address && props.propertyReducer.zillloData && !props.propertyReducer.zillloData.data) {
            let propertyAddress = address.split(',')
            // notify("error", 'Property not found in Zillow!');
            setShowZipModal(true)
            let imageData = {
                propImages: [],
                count: 0,
                propertyId: '',
                propertyImageId: ''
            }
            setProperty({
                ...property,
                propertyName: propertyAddress[0]
            })
            setZillowPropImages(imageData)

        }
        if (props.propertyReducer.zillloData && props.propertyReducer.zillloData.data && address) {
            let zillowresopnse = props.propertyReducer.zillloData.data['SearchResults:searchresults']
            let propertyImageId = props.propertyReducer.zillloData.data.propertyImageId
            let propImages = props.propertyReducer.zillloData.data.images
            let propertyAddress = address.split(',')
            if (zillowresopnse.message.code != 0) {
                setShowZipModal(true)
                let imageData = {
                    propImages: [],
                    count: 0,
                    propertyId: '',
                    propertyImageId: ''
                }
                setProperty({
                    ...property,
                    propertyName: propertyAddress[0]
                })
                setZillowPropImages(imageData)
            } else {
                const { response: { results: { result } } } = zillowresopnse
                const zillowData = Array.isArray(result) ? result[0] : result;
                const { commonReducer: { propertyTypeList } } = props;
                const propertyTypeName = propertyTypeList.length && propertyTypeList.find((e) => e.name === "House");
                if (zillowData && zillowData.address) {
                    // handling units
                    let tempSingleUnit = {
                        bathroom: zillowData.bathrooms ? Number(zillowData.bathrooms) : '',
                        bedRoom: zillowData.bedrooms ? Number(zillowData.bedrooms) : '',
                        squareFeet: Number(zillowData.finishedSqFt) || Number(zillowData.lotSizeSqFt) || '',
                        // unitName: property.propertyTypeId  ?  propertyTypeName.length ? property.propertyTypeId == propertyTypeName[0]._id ? 'Single Family':
                        //     `${zillowData.address.street}, ${zillowData.address.city}, ${zillowData.address.zipcode}`:
                        //     `${zillowData.address.street}, ${zillowData.address.city}, ${zillowData.address.zipcode}` :
                        //     `${zillowData.address.street}, ${zillowData.address.city}, ${zillowData.address.zipcode}`,
                        unitName: zillowData.useCode.includes('Family') ? 'Single Family' :
                            `${zillowData.address.street}, ${zillowData.address.city}, ${zillowData.address.zipcode}`,
                        deposit: "",
                        monthly: "",
                    }

                    let data = zillowData.useCode.includes("Multi") ? [{ ...tempSingleUnit }, { ...tempSingleUnit }] : [tempSingleUnit]


                    let tempUnitValidations = [];
                    if (data.length) {
                        for (let i = 0; i < property.unit.length; i++) {
                            tempUnitValidations.push({
                                bathroom: '',
                                bedRoom: '',
                                squareFeet: '',
                                unitName: '',
                                deposit: '',
                                monthly: '',
                            })
                        }
                    }
                    setUnitValidations(tempUnitValidations)

                    // handling field errors
                    setFieldErrors({
                        ...fieldErrors,
                        propertyName: '',
                        propertyType: ''
                    })

                    // setting zillow images
                    let imageData = {
                        propImages,
                        count: propImages.length,
                        propertyId: zillowData.zpid,
                        propertyImageId
                    }
                    setZillowPropImages({ ...imageData })


                    // handling property fields
                    setPropertyData(zillowData, data)

                    if (!props.commonReducer.propertyTypeList) return false
                    if (!props.commonReducer.propertyTypeList.length) return false
                }
            }
        }
    }, [props.propertyReducer.zillloData])

    useEffect(() => {
        if (props.propertyReducer.makeDefaultImageResponse) {
            if (props.propertyReducer.makeDefaultImageResponse.data) {
                setIsLoading(true)
                let newDefault = props.propertyReducer.makeDefaultImageResponse.data.imageid
                let temp = zillowPropImages.propImages && zillowPropImages.propImages.filter(img => img._id == newDefault)
                let newImageArray = zillowPropImages.propImages && zillowPropImages.propImages.filter(img => img._id != newDefault)
                temp && temp[0] && newImageArray.unshift(temp[0])
                if (newImageArray) {
                    setZillowPropImages({
                        propImages: newImageArray.filter(img => !img.isDeleted)
                    })
                }
                // setShowPropImage(null)
                setIsLoading(false)
            }
        }
    }, [props.propertyReducer.makeDefaultImageResponse])

    useEffect(() => {
        if (props.propertyReducer.deletePropertyImgResponse) {
            setIsLoading(true)
            if (props.propertyReducer.propertyImage) {
                // setZillowPropImages({
                //     propImages: props.propertyReducer.propertyImage.filter(img => img.isDeleted)
                // })
            }
            setShowPropImage(null)
            setIsLoading(false)
        }
    }, [props.propertyReducer.deletePropertyImgResponse])

    const setPropertyData = (result, newUnitData) => {
        let val;
        if (result.useCode.includes('Family')) {
            val = props.commonReducer.propertyTypeList.filter((propertyType) => propertyType.name == "House")[0]._id;
            setProperty({
                ...property,
                propertyType: val,
                propertyName: `${result.address.street}`,
                latitude: result.address.latitude,
                longitude: result.address.longitude,
                useCode: result.useCode,
                unit: newUnitData
            });
        }
        // switch (result.useCode) {
        //     case 'SingleFamily':
        //         val = props.commonReducer.propertyTypeList.filter((propertyType) => propertyType.name == "House")[0]._id;
        //         setProperty({
        //             ...property,
        //             propertyType: val,
        //             propertyName: `${result.address.street}, ${result.address.city}, ${result.address.zipcode}`,
        //             latitude: result.address.latitude,
        //             longitude: result.address.longitude,
        //             useCode: result.useCode,
        //             unit: newUnitData
        //         });
        //         break;
        //     case 'MultiFamily2To4':
        //         val = props.commonReducer.propertyTypeList.filter((propertyType) => propertyType.name == "Apartment")[0]._id;
        //         setProperty({
        //             ...property,
        //             propertyType: val,
        //             propertyName: `${result.address.street}, ${result.address.city}, ${result.address.zipcode}`,
        //             latitude: result.address.latitude,
        //             longitude: result.address.longitude,
        //             useCode: result.useCode,
        //             unit: newUnitData
        //         });
        //         break;
        //     case 'MultiFamily5Plus':
        //         val = props.commonReducer.propertyTypeList.filter((propertyType) => propertyType.name == "Apartment")[0]._id;
        //         setProperty({
        //             ...property,
        //             propertyType: val,
        //             propertyName: `${result.address.street}, ${result.address.city}, ${result.address.zipcode}`,
        //             latitude: result.address.latitude,
        //             longitude: result.address.longitude,
        //             useCode: result.useCode,
        //             unit: newUnitData
        //         });
        //         break;
        //     default:
        //         return ''
        // }
    }

    const renderGoogleMaps = () => {
        return (<div style={{ height: '270px', width: '100%' }} className="map-innr-ht">
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyAZYX-O-1_ROqoz6oyzxHvKYsW0cEw7eG8' }}
                defaultCenter={{
                    lat: parseFloat(property.latitude) || 38.9071923,
                    lng: parseFloat(property.longitude) || -77.03687070000001
                }}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals={true}
            >
                <LocaleMarker />
            </GoogleMapReact>
        </div>)
    }

    const renderStreetView = () => {
        if (property.latitude && property.longitude) {
            return (<div style={{
                width: '100%',
                height: '270px',
                backgroundColor: '#eeeeee'
            }}>
                {
                    <ReactStreetview
                        apiKey='AIzaSyAZYX-O-1_ROqoz6oyzxHvKYsW0cEw7eG8'
                        streetViewPanoramaOptions={{
                            position: {
                                lat: parseFloat(property.latitude) || 38.9071923,
                                lng: parseFloat(property.longitude) || -77.03687070000001
                            },
                            pov: { heading: 180, pitch: 1 },
                            zoom: 1
                        }}
                    />
                }
            </div>)
        } else {
            return (<div style={{
                width: '100%',
                height: '270px',
                backgroundColor: '#eeeeee'
            }}>
                <img src="/images/defaultStreetView2.png" style={{
                    width: '100%',
                    height: '100%'
                }} />
            </div>)
        }

    }

    const notify = (type, message) => {
        switch (type) {
            case "error":
                toastr.error(message);
                break;
            default:
                break;
        }
    }

    const events = {
        onDragged: function (event) {
            // if (event.page)
            // setSelectedImage(zillowPropImages.propImages[event.page.index])
        },
        onChange: function (event) {
            // if (event.page)
            // setSelectedImage(zillowPropImages.propImages[event.page.index])
        }
    };


    // handling changes in the form fields
    const handleChange = (e, type) => {
        const { name, value } = e.target;
        const validateStatus = validate(name, value);
        setFieldErrors({
            ...fieldErrors,
            [name]: validateStatus
        })
        const { commonReducer: { propertyTypeList } } = props;
        const propertyTypeName = propertyTypeList.length && propertyTypeList.find((e) => e.name === "House");

        setProperty({
            ...property,
            [name]: value
        })
        if (name == 'propertyType') {
            if (propertyTypeName._id == value) {
                let tempUnit = {}
                let tempArray = []
                if (property.unit.length) {
                    Array.isArray(property.unit) && property.propertyType && property.unit.map((unitData, index) => {
                        tempUnit = {
                            ...unitData,
                            unitName: 'Single Family'
                        }
                        tempArray.push(tempUnit)
                    })
                    setProperty({
                        ...property,
                        [name]: value,
                        unit: tempArray
                    })
                }
                else {
                    tempUnit = {
                        bathroom: '',
                        bedRoom: '',
                        squareFeet: '',
                        unitName: 'Single Family',
                        deposit: '',
                        monthly: '',
                    }
                    tempArray.push(tempUnit)
                    setProperty({
                        ...property,
                        [name]: value,
                        unit: tempArray
                    })

                }

            } else {
                let tempUnit = {}
                let tempArray = []
                if (property.propertyName) {
                    if (Array.isArray(property.unit) && property.unit.length) {
                        property.propertyType && property.unit.map((unitData, index) => {
                            tempUnit = {
                                ...unitData,
                                unitName: property.propertyName
                            }
                            tempArray.push(tempUnit)
                        })
                    }
                    else {
                        tempUnit = {
                            bathroom: '',
                            bedRoom: '',
                            squareFeet: '',
                            unitName: property.propertyName,
                            deposit: '',
                            monthly: '',
                        }
                        tempArray.push(tempUnit)
                    }
                    setProperty({
                        ...property,
                        [name]: value,
                        unit: tempArray
                    })
                }

                else {
                    tempUnit = {
                        bathroom: '',
                        bedRoom: '',
                        squareFeet: '',
                        unitName: '',
                        deposit: '',
                        monthly: '',
                    }
                    tempArray.push(tempUnit)
                    setProperty({
                        ...property,
                        [name]: value,
                        unit: tempArray
                    })

                }

            }

        }
        // }
    }
    const handleEditUnits = (unitId, field, event) => {
        const { value } = event.target
        // setting validations
        let tempUnitValidations = unitValidations;
        if (!tempUnitValidations.length) {
            tempUnitValidations = [];
            for (let i = 0; i < property.unit.length; i++) {
                tempUnitValidations.push({
                    bathroom: '',
                    bedRoom: '',
                    squareFeet: '',
                    unitName: '',
                    deposit: '',
                    monthly: '',
                })
            }
        }
        let isValid = validate(field, value);
        if (tempUnitValidations[unitId])
            tempUnitValidations[unitId][field] = isValid
        setUnitValidations(tempUnitValidations)
        // setting value
        let tempPropertyUnit = property.unit;
        tempPropertyUnit[unitId][field] = value;
        setProperty({
            ...property,
            unit: tempPropertyUnit,
        });
    }

    // Validation Conditions
    const validate = (name, value) => {
        let parsedValue = '';
        switch (name) {
            case 'address':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Address is required.'
                } else {
                    return false;
                }
            case 'propertyName':
                if (value === undefined || value == null || value == '' || value <= 0 || !value) {
                    return 'Property name is required.';
                } else {
                    return false;
                }
            case 'propertyType':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Property type is required.'
                } else {
                    return false;
                }
            case 'propertyOwner':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Property owner is required.'
                } else {
                    return false;
                }
            case 'status':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Status is required.'
                } else {
                    return false;
                }

            case 'unitName':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Unit name is required'
                } else {
                    return false;
                }
            case 'bedRoom':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Bedroom count is required.'
                } else if (validation.NUMBER_ONLY(value) !== true) {
                    return 'Only number is allowed'
                } else {
                    return false;
                }
            case 'bathroom':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Bathroom count is required.'
                } else if (validation.NUMBER_ONLY(value) !== true) {
                    return 'Only number is allowed'
                } else {
                    return false;
                }
            case 'squareFeet':
                if (value === undefined || value == null || value == '' || value <= 0) {
                    return 'Square feet is required.'
                } else if (validation.NUMBER_ONLY(value) !== true) {
                    return 'Only number is allowed'
                } else {
                    return false;
                }
            case 'deposit':
                parsedValue = value.replace("$", "").replace(/,/g, '')
                if (parsedValue === undefined || parsedValue == null || parsedValue == '' || parsedValue <= 0) {
                    return 'Deposit is required.'
                } else if (validation.NUMBER_ONLY(parsedValue) !== true) {
                    return 'Only number is allowed'
                } else {
                    return false;
                }
            case 'monthly':
                parsedValue = value.replace("$", "").replace(/,/g, '')
                if (parsedValue === undefined || parsedValue == null || parsedValue == '' || parsedValue <= 0) {
                    return 'Monthly rent is required.'
                } else if (validation.NUMBER_ONLY(parsedValue) !== true) {
                    return 'Only number is allowed'
                } else {
                    return false;
                }

            default:
                return false;
        }
    }

    const handleImageUpload = async (e) => {
        const { files } = e.target;
        setFieldErrors({
            ...fieldErrors,
            imageUpload: ''
        })
        if (files && files.length) {
            if (!files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                setFieldErrors({
                    ...fieldErrors,
                    imageUpload: 'Only images are allowed'
                })
                return false
            }
            let tempImgArray = zillowPropImages.propImages || [];
            tempImgArray.push({ image: files, isNewImg: true })
            setZillowPropImages({
                ...zillowPropImages,
                propImages: tempImgArray
            })

            // if (isEdit && props.propertyDetails) {
            //     let imgArray = []
            //     tempImgArray && tempImgArray.length && tempImgArray.map(img => {
            //         if (img.isNewImg) {
            //             imgArray.push(img);
            //         }
            //     })
            //     const param = {
            //         data: { id: props.propertyDetails._id },
            //         images: imgArray,
            //     }
            //     console.log("param", param)
            //     await props.addPropertyDetails(param);
            //     await props.getPropertyById(props.propertyDetails._id)
            // }

        }
    }

    const handleAddressSearch = address => {
        if (address) {
            setProperty({
                ...property,
                address,
                // propertyName: '',
                // propertyType: ''
            })
            setFieldErrors({
                ...fieldErrors,
                address: ''
            })
            setUnitDetails({
                ...unitDetails,
                bathroom: '',
                bedRoom: '',
                squareFeet: '',
                deposit: '',
                monthly: ''
            })
        } else {
            setProperty({
                ...property,
                address: ''
            })
            setFieldErrors({
                ...fieldErrors,
                address: 'Address is required'
            })
        }
    }

    const addNewUnitForm = () => {
        const { commonReducer: { propertyTypeList } } = props;
        const propertyTypeName = propertyTypeList.length && propertyTypeList.find((e) => e.name === "House");
        let name = ""
        if (property && property.propertyType === propertyTypeName._id) {
            name = "Single Family"
        }
        if (property.propertyType) {
            setProperty({
                ...property,
                unit: [
                    ...property.unit,
                    {
                        isNewUnit: true,
                        unitName: name,
                        bedRoom: "",
                        bathroom: "",
                        squareFeet: "",
                        deposit: "",
                        monthly: "",
                        isAvailable: 1
                    }
                ]
            })
        }
    }

    const removeUnit = (index, unit) => {
        if (unit.isAvailable) {
            let tempArray = property.unit;
            tempArray.splice(index, 1)
            setProperty({
                ...property,
                unit: tempArray
            })
            setMessage(null)
        }
        else {
            setMessage('A tenant is associated with this unit. Remove tenant to delete this unit!!')
        }

    }

    const handleSelect = address => {
        setAddress(address)
        setIsLoading(true)
        geocodeByAddress(address)
            .then(async results => {
                if (results && results.length) {
                    results && results.length && results[0].address_components.map(comp => {
                        if (!localeData.postal_code) {
                            localeData.postal_code = comp.types.includes('postal_code') ? comp.long_name : null;
                            localeData.locality = comp.types.includes('locality') ? comp.long_name : null;
                        }
                    })
                    setZillowPlaceData({
                        formatted_address: results[0].formatted_address,
                        zillowPlaceData: null,
                        postal_code: localeData.postal_code
                    })
                    getZillowDetails(results[0].formatted_address, null)
                }
                return getLatLng(results[0])
            })
            .then(latLng => {
                setIsLoading(false)
                setProperty({
                    ...property,
                    address: address,
                    latitude: latLng.lat.toString(),
                    longitude: latLng.lng.toString(),
                })
                setIsMapUpdated(true)
            })
            .catch(error => {
                setIsLoading(false)
                console.error('Error', error)
            });
    };

    const getZillowDetails = async (formatted_address, zip) => {
        // let queryData = '?zwsid=zws-id=X1-ZWz1hjzg7x72ff_3qukz&types=address&address=';
        let queryData = '?zwsid=zws-id=X1-ZWz1hjzg7x72ff_3qukz&address=';
        // formatting formattedAddress into query params ??????
        let tempArray = formatted_address.split(',');
        let city = tempArray.splice(tempArray.length - 2, 2);
        let cityData = city && city.map(x => x.trim()).join('+')
        let part1 = tempArray.map(x => {
            x.trim()
            return x.replace(',', '+')
        }).join('%2C')

        queryData = queryData + part1 + '&' + 'citystatezip='
        queryData = zip ? queryData + zip : queryData + cityData;
        // queryData = zip ? queryData + zip : queryData + localeData.postal_code;
        // 1
        // let queryData = "?zwsid=zws-id=X1-ZWz1hjzg7x72ff_3qukz&address=2114+Bigelow+Ave&citystatezip=Seattle%2C+WA"
        // 2
        // let queryData = "?zwsid=zws-id=X1-ZWz1hjzg7x72ff_3qukz&address=3708+Antelope+Trail&citystatezip=28409"
        setZillowQueryString(queryData);
        setIsLoading(true)
        await props.getZillowData(queryData);
        if (zip) {
            zip && setShowZipModal(false)
        }
        setIsLoading(false)
    }

    const addProperty = () => {
        let unitError = false

        setFieldErrors({
            ...fieldErrors,
            address: validate('address', property['address']),
            propertyName: validate('propertyName', property['propertyName']),
            propertyType: validate('propertyType', property['propertyType']),
            propertyOwner: validate('propertyOwner', property['propertyOwner']),
            status: validate('status', property['status'])
        })
        // Validating units
        if (!property.unit.length) {
            notify("error", 'One unit is required!');
            return false;
        }
        if (!property.unit.length || property.unit.filter(unit => Object.values(unit).includes('')).length) {
            // let tempUnitValidations = new Array(property.unit.length).fill({})
            let tempUnitValidations = [];
            for (let i = 0; i < property.unit.length; i++) {
                tempUnitValidations.push({
                    bathroom: '',
                    bedRoom: '',
                    squareFeet: '',
                    unitName: '',
                    deposit: '',
                    monthly: '',
                })
            }
            property.unit.map((unit, index) => {
                Object.keys(unit).map((field) => {
                    tempUnitValidations[index][field] = validate(field, unit[field])
                })
            })
            setUnitValidations(tempUnitValidations)
            return false;
        }
        if (property['address'] &&
            property['propertyName'] &&
            property['propertyType'] &&
            property['propertyOwner'] &&
            property['status'] &&
            property.unit.length
        ) {
            let newArray = [];
            property.unit.map(unitData => {
                let dataVal = {
                    id: unitData.id,
                    unitName: unitData.unitName,
                    bedroomCount: unitData.bedRoom,
                    bathroomCount: unitData.bathroom,
                    squareFeet: unitData.squareFeet,
                    deposit: unitData.deposit && unitData.deposit.toString().replace("$", "").replace(/,/g, ''),
                    monthlyRent: unitData.monthly && unitData.monthly.toString().replace("$", "").replace(/,/g, ''),
                    propertyFeatures: unitData.features,
                    Ameneties: unitData.amenities
                };
                newArray.push(dataVal);
            });
            //add isDEleted to removed unit
            const { propertyDetails } = props
            let tempUnitArray = [];
            let unitArrayValue = newArray
            propertyDetails && propertyDetails.Units && propertyDetails.Units.map((unit, index) => {
                let tempUnit = {
                    id: unit._id,
                    unitName: unit.unitName,
                    bedRoom: unit.bedroomCount,
                    bathroom: unit.bathroomCount,
                    squareFeet: unit.squareFeet,
                    deposit: unit.deposit,
                    monthly: unit.monthlyRent,
                    isAvailable: unit.isAvailable
                }
                tempUnitArray.push(tempUnit)
            })


            tempUnitArray && tempUnitArray.length && tempUnitArray.map(unit => {
                let deletedValue = newArray.find(na => na.id === unit.id)

                if (!deletedValue) {
                    if (unit.isAvailable) {
                        let temp = {
                            id: unit.id,
                            unitName: unit.unitName,
                            bedRoom: unit.bedRoom,
                            bathroom: unit.bathroom,
                            squareFeet: unit.squareFeet,
                            deposit: unit.deposit,
                            monthly: unit.monthly,
                            isDeleted: "true"
                        }
                        unitArrayValue.push(temp)
                    }
                    else {
                        setunitErrorAvailable(true)
                        unitError = true
                        setMessage('A tenant is associated the Replaced unit. Remove tenant to Edit property!!')
                    }

                }

            })




            let param = {};
            if (isEdit) {
                let imgArray = [];
                zillowPropImages.propImages.map(img => {
                    if (img.isNewImg && !img.isDefault) {
                        imgArray.push(img);
                    }
                })
                param = {
                    images: imgArray,
                    data: {
                        defaultImageTitle: zillowPropImages.propImages ? zillowPropImages.propImages && zillowPropImages.propImages.length && zillowPropImages.propImages[0].title : '',
                        deleteImageTitle: deletedImages,
                        file: property.file,
                        paymentId: property.propertyOwner,
                        status: property.status,
                        propertyName: property.propertyName,
                        id: props.propertyDetails._id,
                        propertyImageId: property.propertyImageId,
                        propertyTypeId: property.propertyType,
                        address: property.address,
                        unit: unitArrayValue
                    }
                }
            } else {
                let imgArray = [];
                zillowPropImages.propImages && zillowPropImages.propImages.length && zillowPropImages.propImages.map(img => {
                    if (img.isNewImg) {
                        imgArray.push(img);
                    }
                })
                param = {
                    images: imgArray,
                    data: {
                        defaultImageTitle: zillowPropImages.propImages ? zillowPropImages.propImages.length && zillowPropImages.propImages[0].title : '',
                        deleteImageTitle: deletedImages,
                        propertyImageId: zillowPropImages.propertyImageId,
                        paymentId: property.propertyOwner,
                        status: property.status,
                        propertyName: property.propertyName,
                        propertyTypeId: property.propertyType,
                        address: property.address,
                        latitude: property.latitude,
                        longitude: property.longitude,
                        unit: unitArrayValue
                    }
                }
            }

            if (!unitError) {
                console.log("param:submit", param)
                props.addPropertyDetails(param);

            }
            // props.addPropertyDetails(param);
        }
    }

    const makeImageDefault = async (image, index) => {
       
        let tempImgArray = zillowPropImages.propImages || [];
        tempImgArray.splice(index, 1)
        tempImgArray = tempImgArray.map(img => {
            return { ...img, isDefault: false }
        })
        tempImgArray.unshift({ ...image, isDefault: true })
        setZillowPropImages({
            ...zillowPropImages,
            propImages: tempImgArray
        })
        if (isEdit && props.propertyDetails ) {
            const params = {
                id: props.propertyDetails._id,
                imageid: image._id,
            }
            if (image.isNewImg) {
                delete params.imageid;
                params.defaultImageTitle = image && image.image[0].name
                params.image = image.image[0]
            }
            await props.makeImageDefault(params)
            props.getPropertyById && await props.getPropertyById(props.propertyDetails._id)
            props.getPropertyList && await props.getPropertyList()
        }
    }

    const deleteImage = async (image, index) => {
        let tempImgArray = zillowPropImages.propImages || [];
        tempImgArray.splice(index, 1)
        setDeletedImages([
            ...deletedImages,
            image.title
        ])
        setZillowPropImages({
            ...zillowPropImages,
            propImages: tempImgArray
        })
        if (isEdit && props.propertyDetails) {
            const params = {
                id: props.propertyDetails._id,
                imageid: image._id
            }
            await props.deletePropertyImage(params)
            await props.getPropertyById(props.propertyDetails._id)
            await props.getPropertyList && await props.getPropertyList()

        }
    }

    const parseImage = (image) => {
        if (image.path) {

            if (image.isNewImg) {
                // if (image.image) {
                //     return URL.createObjectURL(image.image[0])
                // }
                if (image.image && image.image.length) {
                    return URL.createObjectURL(image.image[0])
                } else if (image.image && image.image) {
                    return URL.createObjectURL(image.image)
                }
            } else if (image.type == "zillo") {
                return image.path
            } else if (image.type == "innago") {
                return image.path
            } else {
                if (image.image) {
                    return URL.createObjectURL(image.image[0])
                }
            }
        } else {
            if (image.image && image.image.length) {
                return URL.createObjectURL(image.image[0])
            } else if (image.image && image.image.length) {
                return URL.createObjectURL(image.image)
            }
        }
    }

    const parseBackgoundImage = (image) => {
        if (zillowPropImages.propImages && zillowPropImages.propImages.length) {
            const image = zillowPropImages.propImages[0] && zillowPropImages.propImages[0];

            if (image.type == "zillo") {
                return image.path
            } else if (image.type == "innago") {
                return image.path
            } else if (image && image.image) {
                return URL.createObjectURL(image.image[0])
            } else {
                return '/images/def_property.png'
            }
        } else if (!image) {
            return ('/images/def_property.png')
        }
    }

    return (
        <div className="modal addTenant propSec" id="modalId" style={{ display: "block" }}>
            {/* <ToastContainer /> */}
            {message ? <Modal body={<MessageBox
                onCancel={() => setMessage(null)}
                message={message}
                title={<i style={{ 'color': 'indianred', 'fontSize': '30px' }} className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
            />
            }
                onCancel={() => setMessage({ text: null })}
            /> : null}
            {(isLoading || props.renderLoader) && <LoaderSpinner />}
            {imageModal &&
                <PropertyImageModal
                    onClose={() => setImageModal(false)}
                    handleImageUpload={handleImageUpload}
                    deleteImage={deleteImage}
                    makeImageDefault={makeImageDefault}
                    zillowPropImages={zillowPropImages && zillowPropImages}
                    parseImage={parseImage}
                    fieldErrors={fieldErrors}
                />
            }
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isEdit ? 'Update Property' : 'Add New Property'}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => props.onCancel()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <h3>Property Details</h3>
                            <div className="formSection">
                                <div className="row outrForm"
                                // onClick={()=>setShowPropImage(!showPropImage)}
                                >
                                    <div className="col-md-3 FormleftOutr owlIMgLEFT">
                                        <div
                                            style={{ 'cursor': 'pointer' }}
                                            className="btnAddCam"
                                            onClick={() => setImageModal(true)}
                                        >
                                            <i className="fas fa-camera"></i>
                                        </div>
                                        {/* <div
                                            className="col-md-12 upldFile p-0 bg-prop-def-img"
                                            style={{ backgroundImage: `url('${parseBackgoundImage()}')` }}
                                        >
                                            <div className="upld-otr d-flex">
                                            </div>
                                        </div> */}
                                        <DefaultImage parseBackgoundImage={parseBackgoundImage}
                                            imagesList={zillowPropImages.propImages} />
                                        <div className="carousel-otr" >
                                            {zillowPropImages.propImages &&
                                                <OwlCarousel options={options} events={events}>
                                                    {
                                                        zillowPropImages.propImages && zillowPropImages.propImages.map((img, id) => {
                                                            return (
                                                                <div key={id} className="item">
                                                                    <h1>
                                                                        <div onClick={() => {
                                                                            setSelectedImage(img)
                                                                            setShowPropImage(img.path)
                                                                            makeImageDefault(img, id)
                                                                        }} className="lightbox"
                                                                        >
                                                                            {img && <img src={parseImage(img)} />}
                                                                        </div>
                                                                    </h1>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </OwlCarousel>}
                                        </div>
                                    </div>
                                    {/* Image Modal */}
                                    {
                                        showPropImage &&
                                        <div
                                            className="modal addTenant lightBoxModal"
                                            id="LightBoxModal"
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                            onKeyDown={(e) => clickedArrow(e)}
                                            style={{ display: showPropImage ? 'block' : 'none' }}
                                        >
                                            <div className="modal-dialog modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header light-box-modal-header">
                                                        <div className="closeBtn">
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                                onClick={() => setShowPropImage(null)}
                                                            >
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/* <div className="modal-body LighboxImg"> */}
                                                    <OwlCarousel ref={myInput}
                                                        options={{
                                                            ...options,
                                                            items: 1,
                                                            nav: true,
                                                            loop: true,
                                                            navText: [
                                                                '<i class="fas fa-chevron-left"></i>',
                                                                '<i class="fas fa-chevron-right"></i>'
                                                            ]
                                                        }}
                                                        events={events}
                                                    >
                                                        {
                                                            zillowPropImages.propImages && zillowPropImages.propImages.map((img, id) => {
                                                                return (
                                                                    <div key={id} className="modal-body LighboxImg"
                                                                        tabIndex="0">
                                                                        <div className="owlIMgOuterSEc col-md-12">
                                                                            <img src={parseImage(img)} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </OwlCarousel>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-9 FormrightOutr">
                                        <div className="col-md-12 row m-0 p-0">
                                            <div className="col-xl-6 col-lg-6 col-md-6 formInp">
                                                <label>Address</label>
                                                <PlacesAutocomplete
                                                    value={property.address}
                                                    onChange={handleAddressSearch}
                                                    onSelect={handleSelect}
                                                // searchOptions={
                                                //     { types: 'address'}
                                                // }
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div className="autocompleteOuter">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Address',
                                                                    className: 'location-search-input form-control',
                                                                })}
                                                            />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && <div className="loader">Loading...</div>}
                                                                {suggestions.map((suggestion, suggKey) => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                    return (
                                                                        // eslint-disable-next-line react/jsx-key
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className
                                                                            })}
                                                                        >
                                                                            <span
                                                                                key={suggKey}>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                                {fieldErrors.address &&
                                                    <span className={"errorMsg"}>{fieldErrors.address}</span>}
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 formInp">
                                                <label>Property Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Property  Name"
                                                    name="propertyName"
                                                    value={property.propertyName}
                                                    onChange={e => handleChange(e)}
                                                />
                                                {fieldErrors.propertyName &&
                                                    <span className={"errorMsg"}>{fieldErrors.propertyName}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12 row m-0 p-0">
                                            <div className="col-xl-4 col-lg-4 col-md-4 formInp">
                                                <label>Property Type</label>
                                                <div className="selctOtr">
                                                    <select
                                                        className="form-control"
                                                        name="propertyType"
                                                        onChange={e => handleChange(e)}
                                                        value={property.propertyType}
                                                    >
                                                        <option value={''}>Select Property Type</option>
                                                        {props.commonReducer.propertyTypeList.length &&
                                                            props.commonReducer.propertyTypeList.map(
                                                                (propertyType, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={propertyType._id}
                                                                        >
                                                                            {propertyType.name}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    {fieldErrors.propertyType &&
                                                        <span className={"errorMsg"}>{fieldErrors.propertyType}</span>}
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 formInp">
                                                <label>Property Owner</label>
                                                <div className="selctOtr">
                                                    <select
                                                        className="form-control"
                                                        name="propertyOwner"
                                                        value={property.propertyOwner}
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <option value={0}>Select Property Owner</option>
                                                        {
                                                            props.commonReducer.paymentList.payment && props.commonReducer.paymentList.payment.map((pmnt, index) => {
                                                                return <option key={index}
                                                                    value={pmnt._id}>{pmnt.entityName}</option>
                                                            })
                                                        }
                                                        {/* <option value='aptoProperties'>Apto Properties</option> */}
                                                    </select>
                                                    {fieldErrors.propertyOwner &&
                                                        <span className={"errorMsg"}>{fieldErrors.propertyOwner}</span>}
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 formInp">
                                                <label>Property Status</label>
                                                {(props.propertyDetails && props.propertyDetails.tenantCount != 0) ?
                                                    <input type={"text"} disabled={true} className="form-control"
                                                        value={"Occupied"} /> : <div className="selctOtr">
                                                        <select
                                                            className="form-control"
                                                            name="status"
                                                            value={property.status}
                                                            onChange={e => handleChange(e)}
                                                        >
                                                            <option value='1'>Move-In Ready</option>
                                                            <option value='2'>Under construction</option>
                                                        </select>
                                                    </div>}
                                                {fieldErrors.status &&
                                                    <span className={"errorMsg"}>{fieldErrors.status}</span>}

                                            </div>
                                        </div>
                                    </div>
                                    {/* Maps */}
                                    <div className="col-md-12 row m-0 mapOuterProp">
                                        <div className="col-md-6 formInp">
                                            {!isMapUpdated && renderGoogleMaps()}
                                        </div>
                                        <div className="col-md-6 formInp">
                                            {!isMapUpdated && renderStreetView()}
                                        </div>
                                        <span className="zillowPw">Powered by Zillow</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            property.propertyType
                                ? <div className="invoice text-right">
                                    <span className="anothrUnit" onClick={() => addNewUnitForm()}>
                                        <i className="fas fa-plus"></i> Add Unit
                                </span>
                                </div>
                                : null
                        }
                        {Array.isArray(property.unit) && property.propertyType && property.unit.map((unitData, index) => {
                            return (
                                <PropertyUnit
                                    key={index}
                                    idx={index}
                                    unitData={unitData}
                                    fieldErrors={fieldErrors}
                                    handleEditUnits={handleEditUnits}
                                    unitValidations={unitValidations}
                                    removeUnit={removeUnit}
                                />
                            );
                        })}
                    </div>
                    {/* Submit */}
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary btnSubmit"
                            onClick={() => addProperty()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    const { propertyReducer, commonReducer } = state;
    return {
        propertyReducer,
        commonReducer
    };
}

export default connect(mapStateToProps, { ...action, ...commonAction })(
    AddPropertyModal
);
