import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { MAIN_ROUTE } from "../../../../config/constants";
import CommonDatePicker from "../../../Common/components/CommonDatePicker";
import LoaderSpinner from "../../../Common/components/Loader";


const MaintenanceForm3 = (props) => {

    const [btnDisable, setBtnDisable] = useState(false);

    const {
        maintenance: {
            title, description, assignee, requestInitiatedDate, completedWorkDate, isChangedImage, originalEstimateMin, originalEstimateHr, requestDueDate,
            startedWorkdate, labourTimeHr, labourTimeMin, maintenancePicture, maintenanceVideo
        },
        changeRadio, onChangeDate, maintenance, isEdit, tenantLogin, validate, handleChange
    } = props;

    const submitMaintenance = async (e) => {
        setBtnDisable(true)
        await props.handleSubmit(e)
        setBtnDisable(false)

    }
    return (
        <div className="col-md-12 categ-sub-one p-0">
            {btnDisable && <LoaderSpinner/>}
            <div className="boxSec">
                <div className="row">
                    <div className="col-md-12 listing issue-section-outer">
                        <div className="hed">
                            <h3>Request Details</h3>
                            <h4>Add any additional request details.</h4>
                        </div>
                        <div className="listing-issue-outer">
                            <div className="formSection">
                                <div className="formInp">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Title"
                                        name="title"
                                        onChange={(e) => props.handleChange(e)}
                                        value={title}
                                    />
                                    <span className="red-dots"></span>
                                    {props.validate(title, "Title", "required")}
                                </div>
                                <div className="formInp">
                                    <textarea
                                        className="form-control"
                                        placeholder="Details"
                                        name="description"
                                        onChange={(e) => props.handleChange(e)}
                                        value={description}
                                        maxlength='2000'
                                    ></textarea>
                                    <span className="limit-txt">Character Limit : {description.length}/2000</span>
                                    {props.validate(description, "Description", "required")}
                                </div>
                            </div>
                        </div>
                        <div className="hed">
                            <h3>Attachments</h3>
                            <h4>Attach the files of the issue to help narrowing down the issue.</h4>
                        </div>
                        <div className="file-upld-otr d-flex">
                            <div className="col-md-6 upl-outr pl-0">

                                <div className="col-md-12 uplod-1">
                                    <input
                                        type="file"
                                        onChange={(e) => props.handleAddImage(e)}
                                        name="maintenancePicture"
                                        multiple
                                        accept="image/*"
                                        className="upload-file"
                                    />
                                    {!maintenancePicture.length && !isChangedImage ?
                                        <>
                                            <div className="svgimg">
                                                <img src="/images/Icon_1.svg" className="svgIcn" />
                                            </div>
                                            <h3>Upload</h3>
                                            <h4>Take the photo of the problem</h4>
                                        </>
                                        :
                                        <div className="preview-img">

                                            {isEdit && !isChangedImage && maintenancePicture.length ?
                                                <img src={MAIN_ROUTE + maintenancePicture[0].path} />
                                                : maintenancePicture ? <img
                                                    src={maintenancePicture && URL.createObjectURL(maintenancePicture)} /> : null
                                            }


                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 upl-outr pr-0">
                                <div className="col-md-12 uplod-1 uplod-2">
                                    <input
                                        type="file"
                                        onChange={(e) => props.handleAddImage(e)}
                                        name="maintenanceVideo"
                                        className="upload-file"
                                    />
                                    <div className="svgimg">
                                        <img src="/images/Icon_2.svg" className="svgIcn1" />
                                    </div>

                                    {maintenanceVideo ?
                                        <h3>Video Uploaded</h3>
                                        :
                                        <> <h3>Upload</h3>
                                            <h4>Take a 15 sec video of the problem(mp4)</h4>
                                        </>}
                                </div>
                                <div className={'errorMsg'}>{props.videoError && props.videoError}</div>
                            </div>
                        </div>
                        {
                            !tenantLogin &&
                            <>
                                <div className="hed">
                                    <h3>Assignee Information</h3>
                                    <h4>Assign yourself or select the Service Professional from the list. Connect and
                                        post the order to ServicePro Portal. Communicate, add materials, and create
                                        transactions within the
                                        order.</h4>
                                </div>
                                <div className="assigne-otr">
                                    <div className="d-flex customRadioBtn">
                                        <label className="radioBtn">Unassigned
                                            <input
                                                type="radio"
                                                name='assignee'
                                                checked={assignee == 1}
                                                onChange={(e) => changeRadio(e)}
                                                value='1'
                                            />
                                            <span className="radiocheckmark"></span>
                                        </label>
                                        <label className="radioBtn">Do It myself
                                            <input
                                                type="radio"
                                                name='assignee'
                                                checked={assignee == 2}
                                                onChange={(e) => changeRadio(e)}
                                                value='2'
                                            />
                                            <span className="radiocheckmark"></span>
                                        </label>
                                        <label className="radioBtn">Assign to my pro
                                            <input
                                                type="radio"
                                                name='assignee'
                                                checked={assignee == 3}
                                                onChange={(e) => changeRadio(e)}
                                                value='3'
                                            />
                                            <span className="radiocheckmark"></span>
                                        </label>
                                        {assignee == 3 ?
                                            <div className="formInp col-md-5 pl-0">
                                                <input name={"contractorName"}
                                                    onChange={handleChange}
                                                    placeholder={"Contractor Name"}
                                                    value={maintenance.contractorName}
                                                    className={"form-control"} /> </div> : null}

                                    </div>
                                    {assignee == 3 && props.validate(maintenance.contractorName, "Contractor Name", "required")}


                                </div>
                                <div className="hed labTop">
                                    <h3>Dates & Labor</h3>
                                    <h4>Capture work and day hours for each request to keep track of labor time.</h4>
                                </div>
                                <div className="labr-otr">
                                    <div className="formSection d-flex">
                                        <div className="formInp col-md-3 pl-0">
                                            <label>Request initiated date</label>
                                            <CommonDatePicker
                                                name="requestInitiatedDate"
                                                placeholderText={"MM/DD/YYYY"}
                                                disableClock={true}
                                                className="form-control"
                                                value={requestInitiatedDate && moment(new Date(requestInitiatedDate)).format('MM/DD/YYYY')}
                                                onChange={(e) => onChangeDate(e, 'requestInitiatedDate')}
                                                showYearDropdown='true'
                                            />
                                        </div>
                                        <div className="formInp col-md-3 pl-0">
                                            <label>Request due date</label>
                                            <CommonDatePicker
                                                name="requestDueDate"
                                                placeholderText={"MM/DD/YYYY"}
                                                disableClock={true}
                                                className="form-control"
                                                value={requestDueDate && moment(new Date(requestDueDate)).format('MM/DD/YYYY')}
                                                onChange={(e) => onChangeDate(e, 'requestDueDate')}
                                                showYearDropdown='true'
                                            />
                                        </div>
                                        <div className="formInp col-md-3 pl-0">
                                            <label>Start to work here</label>
                                            <CommonDatePicker
                                                name="startedWorkdate"
                                                placeholderText={"MM/DD/YYYY"}
                                                disableClock={true}
                                                className="form-control"
                                                value={startedWorkdate && moment(new Date(startedWorkdate)).format('MM/DD/YYYY')}
                                                onChange={(e) => onChangeDate(e, 'startedWorkdate')}
                                                showYearDropdown='true'
                                            />
                                        </div>
                                        <div className="formInp col-md-3 pl-0">
                                            <label>Completed work here</label>
                                            <CommonDatePicker
                                                name="completedWorkDate"
                                                placeholderText={"MM/DD/YYYY"}
                                                disableClock={true}
                                                className="form-control"
                                                value={completedWorkDate && moment(new Date(completedWorkDate)).format('MM/DD/YYYY')}
                                                onChange={(e) => onChangeDate(e, 'completedWorkDate')}
                                                showYearDropdown='true'
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 d-flex otr-est p-0 labr-brd-otrr">
                                        <div className="col-md-6 otr-brd-sec">
                                            <div className="brd-otrr">
                                                <div className="hed">
                                                    <h3>Original Estimate</h3>
                                                </div>
                                                <div className="labr-otr">
                                                    <div className="formSection d-flex">
                                                        <div className="formInp col-md-6 pl-0">
                                                            <label>Estimate Hours</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="originalEstimateHr"
                                                                placeholder="00"
                                                                onChange={(e) => props.handleChange(e)}
                                                                maxLength={2}
                                                                max={12}
                                                                min={0}
                                                                value={originalEstimateHr} />
                                                            {originalEstimateHr && props.validate(originalEstimateHr, "OriginalEstimateHr", "number")}
                                                        </div>

                                                        <div className="formInp col-md-6 pl-0">
                                                            <label>Estimate Minutes</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="originalEstimateMin"
                                                                placeholder="00"
                                                                onChange={(e) => props.handleChange(e)}
                                                                maxLength={2}
                                                                value={originalEstimateMin}
                                                                max={60}
                                                                min={0}
                                                            />
                                                            {originalEstimateMin && props.validate(originalEstimateMin, "OriginalEstimateMin", "number")}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 otr-brd-sec">
                                            <div className="brd-otrr">
                                                <div className="hed">
                                                    <h3>Labour Time</h3>
                                                </div>
                                                <div className="labr-otr">
                                                    <div className="formSection d-flex">
                                                        <div className="formInp col-md-6 pl-0">
                                                            <label>Hours</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="labourTimeHr"
                                                                placeholder="00"
                                                                onChange={(e) => props.handleChange(e)}
                                                                value={labourTimeHr}
                                                                max={12}
                                                                min={0}

                                                            />
                                                            {labourTimeHr && props.validate(labourTimeHr, "Labour Time Hr", "number")}
                                                        </div>


                                                        <div className="formInp col-md-6 pl-0">
                                                            <label>Minutes</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="labourTimeMin"
                                                                placeholder="00"
                                                                onChange={(e) => props.handleChange(e)}
                                                                value={labourTimeMin}
                                                                maxLength={2}
                                                                max={60}
                                                                min={0}

                                                            />
                                                            {labourTimeMin && props.validate(labourTimeMin, "Labour Time Min", "number")}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {/* <div className="hed labTopr">
                                <h3>Supplies & Materials</h3>
                                <h4>You and your Service Pro can add supplies/materials, quantity and the cost amount of every item.</h4>
                            </div>
                            <div className="outerfr outerDivTog col-md-12 d-flex p-0 materialOtr" >
                                <div className="switchToggle">
                                    <input type="checkbox" id="switch2" />
                                    <label for="switch2">Toggle</label>
                                </div>
                                <div className="txtfld">
                                    Add Materials
                              </div>
                            </div> */}
                        <div className="buton-otr d-flex">
                            <button className="btn-create">
                                Cancel
                            </button>
                            <button className="btn-create create" disabled={btnDisable} onClick={(e) => submitMaintenance(e)}>
                                {props.isEdit ? "Update" : "Create"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { maintenanceReducer, commonReducer, tenantReducer } = state;
    return {
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
        commonReducer,
        tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action
})(MaintenanceForm3);


