import React from "react";
import moment from "moment";

class OutgoingMessage extends React.Component {

    render() {
        const {messageDetails, hasNotRecSmsResp, tempMessageContent} = this.props;
        return (
            <>
                <div className="outgoingMsg">
                    {!hasNotRecSmsResp
                        ?
                        <div className="outgoingOuter">
                            {messageDetails.status !== 'success'?
                            <div className="resendIcon">
                                <i className="fas fa-exclamation-circle" title="Message not delivered" />
                            </div>:null
                            }
                            <div className="sentMsg">
                                <p>{messageDetails.message}</p>
                                <span className="time">{moment(messageDetails.createdAt).format('hh:mm A')}</span>
                            </div>
                            <div className="outgoingMsgName">
                                <i className="fas fa-user"/>
                            </div>
                        </div>
                        :
                        <div className="outgoingOuter">
                            {/*<div className="resendIcon">
                                <i className="fas fa-exclamation-circle"/>
                            </div>*/}
                            <div className="sentMsg">
                                <p>{tempMessageContent} <span className="loadingIcon"><i
                                    className="fas fa-circle-notch fa-spin"/></span>
                                </p>
                                <span className="time">{moment(new Date()).format('hh:mm A')}</span>
                            </div>
                            <div className="outgoingMsgName">
                                <i className="fas fa-user"/>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default OutgoingMessage;