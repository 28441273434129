import React from 'react'

const PropertyImageModal = (props) => {
    const { onClose, handleImageUpload, zillowPropImages, deleteImage, makeImageDefault, parseImage } = props;

    return (
        <div
            className="modal addTenant addMessage maintain-property-image"
            id="modalId"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ 'display': 'block', 'background': '#0a0a0a82' }}
        >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Manage Property Images</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true" onClick={() => onClose()}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clientInfo">
                            <div className="formSection">
                                <div className="row outrForm">
                                    <div className="col-md-12 FormrightOutr">
                                        <div className="row">
                                            <div
                                                className="col-md-12 property-image-ourter"
                                                style={{
                                                    'justifyContent': zillowPropImages &&
                                                        zillowPropImages.propImages &&
                                                        zillowPropImages.propImages.length ? '' : 'center'
                                                }}
                                            >
                                                {
                                                    zillowPropImages &&
                                                        zillowPropImages.propImages &&
                                                        zillowPropImages.propImages.length
                                                        ? zillowPropImages.propImages.map((img, index) => {
                                                            return (
                                                                <>
                                                                    <div key={index} className="col-xl-3 col-md-3 col-sm-3 col-6 outAttachImg">
                                                                        <div className="col-md-12 attachFileList">
                                                                            <img src={parseImage(img)} />
                                                                            <button
                                                                                type="button"
                                                                                className="image-close"
                                                                                onClick={() => deleteImage(img, index)}
                                                                            >
                                                                                <span aria-hidden="true">×</span>
                                                                            </button>
                                                                        </div>
                                                                        <label className="containerCheck" title="Make Primary" >
                                                                            <input
                                                                                type="radio"
                                                                                name="active"
                                                                                onClick={() => makeImageDefault(img, index)}
                                                                                value="1"
                                                                                readOnly
                                                                                // checked={img.isDefault}
                                                                                checked={index == 0}
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                        : <span style={{ 'fontSize': '13px' }}>No Images Found!</span>
                                                }
                                            </div>
                                            <div className="col-md-12 formInp attachFile">
                                                {
                                                    props.fieldErrors.imageUpload
                                                        ? <span className="errorMsg errorImage">{props.fieldErrors.imageUpload}</span>
                                                        : null
                                                }
                                                <div className="col-md-5">
                                                    <label className="custom-file-label">
                                                        <span><i className="fas fa-plus"></i> Add Images</span>
                                                        <input 
                                                            onChange={e => handleImageUpload(e)}
                                                            type="file"  
                                                            id="inputGroupFile01"
                                                            accept='image/*'
                                                            className="file-name"
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit messageSend">
                            Update
                </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default PropertyImageModal;