import React from "react";
import action from "./action";
import {connect} from "react-redux";
import BoardContainer from "./components/BoardContainer";
import {withRouter} from "react-router-dom"
import CommonDatePicker from '../Common/components/CommonDatePicker'

class Board extends React.Component {

    render() {
        const {location: {pathname}} = this.props;
        return (
            <>
                <BoardContainer/>
            </>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(Board));