import innagoGateWay from "../../services/authorizationService";
import serviceEndpoints from "../../config/serviceEndpoints";

//send new message
export const sendMessage = (value) =>innagoGateWay.post(serviceEndpoints.messageEndpoints.sendMessage,value);
export const getMailList = (params) =>innagoGateWay.get(serviceEndpoints.messageEndpoints.getMailList, {params});
export const getMailDetails = (params, key) =>innagoGateWay.get(`${serviceEndpoints.messageEndpoints.getMailDetails}/${key}`, {params});
export const getTenantEmails = () =>innagoGateWay.get(serviceEndpoints.messageEndpoints.getTenantEmails);
export const deleteMessage = (messageId) =>innagoGateWay.delete(`${serviceEndpoints.messageEndpoints.deleteMessage}/${messageId}`);
export const getPhoneNumbers = () =>innagoGateWay.get(serviceEndpoints.messageEndpoints.getPhoneNumbers);
export const sendChatMessage = (chatData) =>innagoGateWay.post(serviceEndpoints.messageEndpoints.sendChatMessage,chatData);
export const getChatMessageList = () =>innagoGateWay.get(serviceEndpoints.messageEndpoints.getChatMessageList);
export const getChatDetails = (id)  =>innagoGateWay.get(`${serviceEndpoints.messageEndpoints.getChatDetails}/${id}`);
export const deleteThread = (messageId) =>innagoGateWay.delete(`${serviceEndpoints.messageEndpoints.deleteThread}/${messageId}`);
export const refreshMail = () =>innagoGateWay.get(serviceEndpoints.messageEndpoints.refreshMail);
export const deleteMailChecked = (data) =>innagoGateWay.delete(serviceEndpoints.messageEndpoints.deleteMailCheck,{data});
export const deleteMessageChecked = (data) =>innagoGateWay.delete(serviceEndpoints.messageEndpoints.deleteMessageChecked,{data});
