import React from "react";
import action from "../action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"

class MessagingSideBar extends React.Component {
    render() {
        const {history, pathname} = this.props;
        return (
            <div className="sendemailOuter">
                <div
                    className={`sendemailInner ${(pathname === '/Messaging/Email/Inbox' || pathname === '/Messaging') ? 'active' : ''}`}
                    onClick={() => history.push('/Messaging/Email/Inbox')}>
                    <div className="email-img">
                        <img src="/images/inbox.png" alt=""/>
                        <span> Inbox</span>
                    </div>
                </div>
                <div className={`sendemailInner ${pathname === '/Messaging/Email/Sent' ? 'active' : ''}`}
                     onClick={() => history.push('/Messaging/Email/Sent')}>
                    <div className="email-img">
                        <img src="/images/email-new.png" alt=""/>
                        <span> Sent</span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(MessagingSideBar));