//get all Property
export const GET_PROPERTY_REQUEST = "GET_PROPERTY_REQUEST"
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS"
export const GET_PROPERTY_FAILURE = "GET_PROPERTY_FAILURE"

//get one Property by id
export const GET_EACH_PROPERTY_REQUEST = "GET_EACH_PROPERTY_REQUEST"
export const GET_EACH_PROPERTY_SUCCESS = "GET_EACH_PROPERTY_SUCCESS"
export const GET_EACH_PROPERTY_FAILURE = "GET_EACH_PROPERTY_FAILURE"

//Get Units by Property id
export const GET_UNIT_REQUEST = "GET_UNIT_REQUEST"
export const GET_UNIT_SUCCESS = "GET_UNIT_SUCCESS"
export const GET_UNIT_FAILURE = "GET_UNIT_FAILURE"

//Get All Units by Property id
export const GET_ALL_UNIT_REQUEST = "GET_ALL_UNIT_REQUEST"
export const GET_ALL_UNIT_SUCCESS = "GET_ALL_UNIT_SUCCESS"
export const GET_ALL_UNIT_FAILURE = "GET_ALL_UNIT_FAILURE"

//Get Individual Units
export const GET_UNIT_DETAILS_REQUEST = "GET_UNIT_DETAILS_REQUEST"
export const GET_UNIT_DETAILS_SUCCESS = "GET_UNIT_DETAILS_SUCCESS"
export const GET_UNIT_DETAILS_FAILURE = "GET_UNIT_DETAILS_FAILURE"

//get state
export const GET_STATE_REQUEST = "GET_STATE_REQUEST"
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS"
export const GET_STATE_FAILURE = "GET_STATE_FAILURE"


//get aminety
export const GET_AMINITY_REQUEST = "GET_AMINITY_REQUEST"
export const GET_AMINITY_SUCCESS = "GET_AMINITY_SUCCESS"
export const GET_AMINITY_FAILURE = "GET_AMINITY_FAILURE"

//get property feathures
export const GET_FEATHURES_REQUEST = "GET_FEATHURES_REQUEST"
export const GET_FEATHURES_SUCCESS = "GET_FEATHURES_SUCCESS"
export const GET_FEATHURES_FAILURE = "GET_FEATHURES_FAILURE"

//get property types
export const GET_TYPES_REQUEST = "GET_TYPES_REQUEST"
export const GET_TYPES_SUCCESS = "GET_TYPES_SUCCESS"
export const GET_TYPES_FAILURE = "GET_TYPES_FAILURE"

//get expense category
export const GET_EXPENSECATEGORY_REQUEST = "GET_EXPENSECATEGORY_REQUEST"
export const GET_EXPENSECATEGORY_SUCCESS = "GET_EXPENSECATEGORY_SUCCESS"
export const GET_EXPENSECATEGORY_FAILURE = "GET_EXPENSECATEGORY_FAILURE"

//get business category
export const GET_BUSINESS_REQUEST = "GET_BUSINESS_REQUEST"
export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS"
export const GET_BUSINESS_FAILURE = "GET_BUSINESS_FAILURE"

//get merchant category
export const GET_MERCHANT_REQUEST = "GET_MERCHANT_REQUEST"
export const GET_MERCHANT_SUCCESS = "GET_MERCHANT_SUCCESS"
export const GET_MERCHANT_FAILURE = "GET_MERCHANT_FAILURE"

//get invoice type
export const GET_INVOICETYPE_REQUEST = "GET_INVOICETYPE_REQUEST"
export const GET_INVOICETYPE_SUCCESS = "GET_INVOICETYPE_SUCCESS"
export const GET_INVOICETYPE_FAILURE = "GET_INVOICETYPE_FAILURE"

//get payment method details
export const GET_PAYMENT_METHOD_REQUEST = "GET_PAYMENT_METHOD_REQUEST"
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS"
export const GET_PAYMENT_METHOD_FAILURE = "GET_INVOICETYPE_FAILURE"

//get revenue
export const GET_REVENUE_REQUEST = "GET_REVENUE_REQUEST"
export const GET_REVENUE_SUCCESS = "GET_REVENUE_SUCCESS"
export const GET_REVENUE_FAILURE = "GET_REVENUE_FAILURE"

//get expense
export const GET_EXPENSE_DETAILS_REQUEST = "GET_EXPENSE_DETAILS_REQUEST"
export const GET_EXPENSE_DETAILS_SUCCESS = "GET_EXPENSE_DETAILS_SUCCESS"
export const GET_EXPENSE_DETAILS_FAILURE = "GET_EXPENSE_DETAILS_FAILURE"

//get payment
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST ';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS ';
export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE ';

//get expense
export const GET_EXPENSE_GRAPH_DETAILS_REQUEST = "GET_EXPENSE_GRAPH_DETAILS_REQUEST"
export const GET_EXPENSE_GRAPH_DETAILS_SUCCESS = "GET_EXPENSE_GRAPH_DETAILS_SUCCESS"
export const GET_EXPENSE_GRAPH_DETAILS_FAILURE = "GET_EXPENSE_GRAPH_DETAILS_FAILURE"

//get property by tenant
export const GET_PROPERTY_DETAILS_REQUEST = 'GET_PROPERTY_DETAILS_REQUEST ';
export const GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS ';
export const GET_PROPERTY_DETAILS_FAILURE = 'GET_PROPERTY_DETAILS_FAILURE ';

//get paid invoice by tenant
export const GET_PAID_INVOICE_DETAILS_REQUEST = 'GET_PAID_INVOICE_DETAILS_REQUEST ';
export const GET_PAID_INVOICE_DETAILS_SUCCESS = 'GET_PAID_INVOICE_DETAILS_SUCCESS ';
export const GET_PAID_INVOICE_DETAILS_FAILURE = 'GET_PAID_INVOICE_DETAILS_FAILURE ';

//get invoice by tenant
export const GET_UNPAID_INVOICE_DETAILS_REQUEST = 'GET_UNPAID_INVOICE_DETAILS_REQUEST ';
export const GET_UNPAID_INVOICE_DETAILS_SUCCESS = 'GET_UNPAID_INVOICE_DETAILS_SUCCESS ';
export const GET_UNPAID_INVOICE_DETAILS_FAILURE = 'GET_UNPAID_INVOICE_DETAILS_FAILURE ';


//get maintenance by tenant
export const GET_MAINTENANCE_TENANT_REQUEST = 'GET_MAINTENANCE_TENANT_REQUEST ';
export const GET_MAINTENANCE_TENANT_SUCCESS = 'GET_MAINTENANCE_TENANT_SUCCESS ';
export const GET_MAINTENANCE_TENANT_FAILURE = 'GET_MAINTENANCE_TENANT_FAILURE ';

//get message by tenant
export const GET_MESSAGE_DETAILS_REQUEST = 'GET_MESSAGE_DETAILS_REQUEST ';
export const GET_MESSAGE_DETAILS_SUCCESS = 'GET_MESSAGE_DETAILS_SUCCESS ';
export const GET_MESSAGE_DETAILS_FAILURE = 'GET_MESSAGE_DETAILS_FAILURE ';

//get Notification
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST ';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS ';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE ';


//update Notification
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST ';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS ';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE ';

//get admin users
export const GET_ADMIN_REQUEST = 'GET_ADMIN_REQUEST ';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS ';
export const GET_ADMIN_FAILURE = 'GET_ADMIN_FAILURE ';

//advanced search
export const ADVANCE_SEARCH_REQUEST = 'ADVANCE_SEARCH_REQUEST ';
export const ADVANCE_SEARCH_SUCCESS = 'ADVANCE_SEARCH_SUCCESS ';
export const ADVANCE_SEARCH_FAILURE = 'ADVANCE_SEARCH_FAILURE ';

//get limit
export const GET_LIMIT_REQUEST = 'GET_LIMIT_REQUEST ';
export const GET_LIMIT_SUCCESS = 'GET_LIMIT_SUCCESS ';
export const GET_LIMIT_FAILURE = 'GET_LIMIT_FAILURE ';

//update limit
export const UPDATE_LIMIT_REQUEST = 'UPDATE_LIMIT_REQUEST ';
export const UPDATE_LIMIT_SUCCESS = 'UPDATE_LIMIT_SUCCESS ';
export const UPDATE_LIMIT_FAILURE = 'UPDATE_LIMIT_FAILURE ';

//email check
export const EMAIL_CHECK_REQUEST = ' EMAIL_CHECK_REQUEST ';
export const EMAIL_CHECK_SUCCESS = ' EMAIL_CHECK_SUCCESS ';
export const EMAIL_CHECK_FAILURE = ' EMAIL_CHECK_FAILURE ';