export const SET_PROFILE = 'SET-PROFILE ';

//add Invoice
export const ADD_INVOICE_REQUEST = "ADD_INVOICE_REQUEST"
export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS"
export const ADD_INVOICE_FAILURE = "ADD_INVOICE_FAILURE"

//get Invoice
export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST"
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE"

//get all TENANT Invoices
export const GET_INVOICE_TENANT_REQUEST = "GET_INVOICE_TENANT_REQUEST"
export const GET_INVOICE_TENANT_SUCCESS = "GET_INVOICE_TENANT_SUCCESS"
export const GET_INVOICE_TENANT_FAILURE = "GET_INVOICE_TENANT_FAILURE"

//get all TENANT Invoices
export const GET_INVOICE_TENANT_TYPE_REQUEST = "GET_INVOICE_TENANT_TYPE_REQUEST"
export const GET_INVOICE_TENANT_TYPE_SUCCESS = "GET_INVOICE_TENANT_TYPE_SUCCESS"
export const GET_INVOICE_TENANT_TYPE_FAILURE = "GET_INVOICE_TENANT_TYPE_FAILURE"

//pay Invoice
export const PAY_INVOICE_REQUEST = "PAY_INVOICE_REQUEST"
export const PAY_INVOICE_SUCCESS = "PAY_INVOICE_SUCCESS"
export const PAY_INVOICE_FAILURE = "PAY_INVOICE_FAILURE"

//pay Invoice
export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST"
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS"
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE"

//SEND SMS
export const SEND_SMS_REQUEST = "SEND_SMS_REQUEST"
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS"
export const SEND_SMS_FAILURE = "SEND_SMS_FAILURE"

//SEND SMS for each invoice
export const SEND_SMS_INVOICE_REQUEST = "SEND_SMS_INVOICE_REQUEST"
export const SEND_SMS_INVOICE_SUCCESS = "SEND_SMS_INVOICE_SUCCESS"
export const SEND_SMS_INVOICE_FAILURE = "SEND_SMS_INVOICE_FAILURE"

//Merge Invoice Tickets
export const MERGE_INVOICE_REQUEST = "MERGE_INVOICE_REQUEST"
export const MERGE_INVOICE_SUCCESS = "MERGE_INVOICE_SUCCESS"
export const MERGE_INVOICE_FAILURE = "MERGE_INVOICE_FAILURE"



//GET INVOICE BY ID
export const GET_INVOICE_ID_REQUEST = "GET_INVOICE_ID_REQUEST"
export const GET_INVOICE_ID_SUCCESS = "GET_INVOICE_ID_SUCCESS"
export const GET_INVOICE_ID__FAILURE = "GET_INVOICE_ID_FAILURE"