import * as actions from "./constant";

const initialState = {
  report: {},
  getReport: {report:[], count:''},
  deleteReport: {}
};

export default function tenantsReducer(state = initialState, action) {
  switch (action.type) {

    case actions.ADD_REPORT_REQUEST:
      return {
        ...state
      };

    case actions.ADD_REPORT_SUCCESS:
      return {
        ...state,
        report: { success: true, ...action.reportAction, message: action.reportAction.message }
      };

    case actions.ADD_REPORT_FAILURE:
      return {
        ...state,
        report: { success: false, ...action.error }
      };

    case actions.GET_REPORT_REQUEST:
      return {
        ...state
      };

    case actions.GET_REPORT_SUCCESS:
      return {
        ...state,
        getReport: {
          success: true,
          report: action.reportAction.reports,
          count: action.reportAction.totalReports
        }
      };

    case actions.GET_REPORT_FAILURE:
      return {
        ...state,
        getReport: { success: false, ...action.error }
      };

      case actions.DELETE_REPORT_REQUEST:
          return {
            ...state
          };

      case actions.DELETE_REPORT_SUCCESS:
        return {
          ...state,
          deleteReport: {
            success: true,
            //report: state.getReport.report.filter((tnt) => tnt._id != action.id),
          },
          getReport: {
            report: state.getReport.report.filter((tnt) => tnt._id != action.id),
            count: state.getReport.count
          }
        };

      case actions.DELETE_REPORT_FAILURE:
        return {
          ...state,
          deleteReport: { success: false, ...action.error }
        };
      case actions.INVITE_SCREENING_REQUEST:
        return {
          ...state
        };

    case actions.INVITE_SCREENING_SUCCESS:
      return {
        ...state,
        invite: action.reportAction
      };

    case actions.INVITE_SCREENING_FAILURE:
      return {
        ...state,
        invite: { success: false, ...action.error }
      };

    default:
      return state;
  }
}

