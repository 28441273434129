import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaintenanceList from './maintenanceTenantList'
import LoaderSpinner from "../../Common/components/Loader";
import Pagination from '../../Common/components/pagination';
import history from '../../../config/history';
import commonAction from '../../Common/action';

const Maintenance = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [params, setParams] = useState({ limit: 10, offset: 0 })
    const [currentPage, setcurrentPage] = useState(1);

    useEffect(() => {
        async function renderCall() {
            await props.getTenantMaintenance(params)
        }
        renderCall();
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }, []);


    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getTenantMaintenance(data);
    };


    const changeStatus = async (e) => {
        if (e.target.value != 'null')
            await props.getTenantMaintenance({ offset: 0, limit: 5, status: e.target.value })
    }

    const refresh = async () => {
        await props.getTenantMaintenance({ offset: 0, limit: 5 })
    }

    return (

        <div className="tenants-otr">
            {isLoading && <LoaderSpinner />}
            <div className="innago-table-top main-table-top maint_req">
                <div className="lft">
                    <h1>MAINTENANCE REQUESTS</h1>
                </div>

                <div className="rgt">
                    <button className="btn-dvd blue-bg">Total
                        Requests<strong>
                            {props.maintenanceDetailsByTenant.maintenanceListCount ? props.maintenanceDetailsByTenant.maintenanceListCount : 0}
                        </strong>
                    </button>
                    <button
                        className="btn-dvd orange-bg">Open<strong>
                            {props.maintenanceDetailsByTenant.openCount ? props.maintenanceDetailsByTenant.openCount : 0}
                        </strong>
                    </button>
                    <button
                        className="btn-dvd green-bg">Closed<strong>
                            {props.maintenanceDetailsByTenant.closedCount ? props.maintenanceDetailsByTenant.closedCount : 0}
                        </strong>
                    </button>
                    <button className="add" onClick={() => history.push('/Add-Maintenance-Tenant')} >
                        <svg>
                            <use xlinkHref="/images/svg-sprite.svg#ic-add" />
                        </svg>
                        New Maintenance
                    </button>
                    {/* <div className="formInp col-md-3 pr-0 pl-1">
                        <div className="selctOtr">
                            <select className="form-control" onChange={(e) => changeStatus(e)}>
                                <option value='null'>Status</option>
                                <option value="1">Open</option>
                                <option value="0">Closed</option>
                                <option value="2">Answered</option>
                                <option value="3">On-Hold</option>
                                <option value="4">In Progress</option>
                                <option value="5">Scheduled</option>
                            </select>
                        </div>
                    </div>
                    <div className="bgr-maint" onClick={() => refresh()}>
                        <i className="fas fa-redo"></i>
                    </div> */}
                </div>
            </div>
            <div className={`innago-table table-main maintenanceTable ${props.maintenanceDetailsByTenant.maintenanceList
                && props.maintenanceDetailsByTenant.maintenanceList.length ? "remarw" : "addarw"}`}>

                <MaintenanceList
                    maintenanceList={props.maintenanceDetailsByTenant.maintenanceList}
                />
                {props.maintenanceDetailsByTenant.maintenanceListCount ?
                    <div className="paginationSec">
                        <Pagination
                            totalRecords={props.maintenanceDetailsByTenant.maintenanceListCount}
                            pageLimit={params.limit}
                            pageNeighbours={2}
                            currentPage={currentPage}
                            onPageChanged={onPageChanged}
                        />
                    </div> : null}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { commonReducer } = state;
    return {
        maintenanceDetailsByTenant: commonReducer.maintenanceDetailsByTenant
    };
};

export default connect(mapStateToProps, {
    ...commonAction
})(Maintenance);

