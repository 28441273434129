import React, { useState, useEffect } from "react";
import parser from 'html-react-parser';
import { MAIN_ROUTE } from "../../../../config/constants";

class LobDataModal extends React.Component {

  // state = {
  //   url: ''
  // }

  // componentDidMount() {
  //   this.setState({
  //     url: this.props.docUrl
  //   })
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if(prevProps.docUrl != this.props.docUrl){
  //     this.setState({
  //       url: this.props.docUrl
  //     })
  //   }
  // }
  render() {
    return (

      <div className="modal addTenant leaseAgreement" id="modalId" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="customtest">
                <h5 className="modal-title" id="exampleModalLabel">Invoice Notice</h5>
              </div>

              <button type="button" className="close" onClick={() => this.props.onCancel()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={MAIN_ROUTE + this.props.docUrl} alt="*Image not loaded" />
              {/* <img src={require(`${MAIN_ROUTE + this.props.docUrl}` )}/> */}
              {/* <img src="https://cruzdevapi.spericorn.com/uploads/lob/5e4bc2dfe15151243c5bd19f_invoice_lob.png" alt="bad"/> */}
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default LobDataModal;