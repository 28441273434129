//Login
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

//Reset Password
export const PASSWORD_CHANGE_REQUEST = "PASSWORD_CHANGE_REQUEST"
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS"
export const PASSWORD_CHANGE_FAILURE = "PASSWORD_CHANGE_FAILURE"


//Validate Key
export const VALIDATE_KEY_REQUEST = "VALIDATE_KEY_REQUEST"
export const VALIDATE_KEY_SUCCESS = "VALIDATE_KEY_SUCCESS"
export const VALIDATE_KEY_FAILURE = "VALIDATE_KEY_FAILURE"

//Forgot Password
export const PASSWORD_FORGOT_REQUEST = "PASSWORD_FORGOT_REQUEST"
export const PASSWORD_FORGOT_SUCCESS = "PASSWORD_FORGOT_SUCCESS"
export const PASSWORD_FORGOT_FAILURE = "PASSWORD_FORGOT_FAILURE"

