export const SET_PROFILE = 'SET-PROFILE ';

//add Expense
export const ADD_EXPENSE_REQUEST = "ADD_EXPENSE_REQUEST"
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS"
export const ADD_EXPENSE_FAILURE = "ADD_EXPENSE_FAILURE"

//get Expense
export const GET_EXPENSE_REQUEST = "GET_EXPENSE_REQUEST"
export const GET_EXPENSE_SUCCESS = "GET_EXPENSE_SUCCESS"
export const GET_EXPENSE_FAILURE = "GET_EXPENSE_FAILURE"

//get invoice transaction
export const GET_INVOICE_TRANSACTION_REQUEST = "GET_INVOICE_TRANSACTION_REQUEST"
export const GET_INVOICE_TRANSACTION_SUCCESS = "GET_INVOICE_TRANSACTION_SUCCESS"
export const GET_INVOICE_TRANSACTION_FAILURE = "GET_INVOICE_TRANSACTION_FAILURE"
