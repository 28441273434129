import React, { useState, useEffect } from "react";
import { MAIN_ROUTE } from "../../../config/constants";
import moment from 'moment';
import messageAction from '../../Messaging/action'
import { connect } from "react-redux";
import toastr from '../../Common/components/Toaster'

const MaintenanceView = (props) => {

    const [showReply, setShowReply] = useState(false);
    const [description, setDescription] = useState('');
    const [uploadAttachment, setUploadAttachment] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const [uploadAttachmentsHeader, setUploadAttachmentsHeader] = useState('Upload');
    const [attachmentChanged, setAttachmentChanged] = useState(false);
    const [fileChanged, setFileChanged] = useState(false);

    const onChange = (e) => {
        const {target:{value}} = e;
        setDescription(value);
    }

    const isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };

    const handleAddAttachment = e => {
        if (isIterable(e.target.files)) {
            setUploadError('')
            if (e.target.files.length) {
                setUploadAttachmentsHeader('Add')
            }
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const invalidExt = ["exe", "dll", "dmg", "vb", "cmd", "bat", "jar", "vbs", "jse", "pif", "vxd", "jse", "apk", "ins", "sct", "msi"];
                // const acceptableExt = ["pdf", "docx", "doc", "txt", "png", "jpg", "ttf", "avi", "flv", "wmv", "mov", "mp4", "zip"];
                if (!invalidExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        setAttachmentChanged(true);
                        setUploadAttachment([...uploadAttachment, ...e.target.files])
                    } else {
                        setUploadError('File is too big. Files with size greater than 25MB is not allowed.');
                    }
                } else {
                    setAttachmentChanged(false);
                    setUploadError('* Accept only image / video / emoji / documents !!!');
                }
            })
        }
    };

    const deleteAttachment = (index) => {
        let fileArray = Object.values(uploadAttachment)
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            setUploadAttachmentsHeader('Upload');
            setAttachmentChanged(false);
            setUploadAttachment([]);
        }
        setFileChanged(true);
        setUploadAttachment(fileArray)
        document.getElementById('inputGroupFile01').value = null
    };


    const handleMessageSubmit = async (e) => {
        e.preventDefault();

        let formValues = {};
        formValues.to = [props.email];
        formValues.subject = props.maintenances.messages.length && props.maintenances.messages[0].thread;
        formValues.message = description;
        if(description){
            await props.sendNewMessage(formValues, uploadAttachment);
            const {messageReducer: {sendMessageRes: {success: status}}} = props;
            // if(status){
                toastr.success('Message sent successfully')
                props.reloadData();
                setDescription('');
                setUploadAttachment([])
           // }
        }
    };


    return (
        <div
            className="modal maintenanceMessaging addTenant small-modal" id="modalId"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ 'display': 'block' }}
            id="maintenanceMessaging"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Maintenance Messaging
              </h5>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={() => props.onCancel()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="messagingForm">
                            <div className="formSection">
                                <div className="row m-0">
                                    <div className="col-xl-12 formInp p-0">
                                        <div className="mailReplySec">
                                            <label>Description</label>
                                        </div>
                                        <textarea
                                            className="form-control"
                                            placeholder="Enter description"
                                            onChange={(e)=>onChange(e)}
                                            value={description}
                                            name="descrption"
                                            cols="30"
                                            rows="10"
                                        ></textarea>
                                    </div>

                                    <div className="col-md-12 formInp attachFile">
                                        <div className="col-md-5">
                                            <label className="custom-file-label" htmlFor="inputGroupFile01"
                                            ><img src="images/Attach.png" alt="" />
                                                <span>Attach a file</span>
                                                {/* <input
                                                    type="file"
                                                    id="inputGroupFile01"
                                                    className="file-name"
                                                /> */}
                                                 <input
                                                    type="file"
                                                    id="inputGroupFile01"
                                                    name="uploadAttachments"
                                                    className="file-name"
                                                    multiple={true}
                                                    onChange={(e)=> handleAddAttachment(e)}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                        {uploadAttachment && uploadAttachment.length
                                            ?
                                            <div className="col-md-12 attachfileOuter">
                                                {Object.values(uploadAttachment).map((attachment, i) => {
                                                    return (

                                                        <div className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                             key={i}>
                                                            <div className="col-md-12 attachFileList">
                                                                {(attachment.name.split('.').pop() === "jpg" || attachment.name.split('.').pop() === "png" || attachment.name.split('.').pop() === "svg")
                                                                    ?
                                                                    <img src={URL.createObjectURL(attachment)} alt=""/>
                                                                    : (attachment.name.split('.').pop() === "pdf")
                                                                        ? <i className="far fa-file-pdf"/>
                                                                        : (attachment.name.split('.').pop() === "doc" || attachment.name.split('.').pop() === "docx")
                                                                            ?
                                                                            <i className="far fa-file-alt"/>
                                                                            : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.name.split('.').pop()))
                                                                                ? <i className="far fa-file-video"/>
                                                                                : (attachment.name.split('.').pop() === 'zip')
                                                                                && <i className="far fa-file-archive"/>
                                                                }
                                                                <button type="button" className="image-close"
                                                                        onClick={() => deleteAttachment(i)}>
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <p>{attachment.name}</p>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            : null
                                        }
                                </div>
                            </div>
                            <div className="messageFormSubmit">
                                <button type="button" className="btn btn-primary btnSubmit" onClick={(e)=>handleMessageSubmit(e)}>
                                    Submit
                                </button>
                            </div>

                        </div>

                        <div className="mailListBox mt-2">
                            {props.maintenances.messages && props.maintenances.messages.map((maintenance, key) => {

                                return <div className="clientInfo clientInfoGrey mb-3" key={key}>
                                    <div className="messagingOuter">
                                        <div className="d-flex col-md-12 profileSection p-0">
                                            <div className="MessageWrapper">
                                                <div className="mailHeader">
                                                    <div className="profileDetails">
                                                        <h4>{maintenance.from}</h4>
                                                        <p>{moment(maintenance.createdAt).format('MMMM Do YYYY, h:mm A')}</p>
                                                    </div>
                                                </div>
                                                <div className="mailDescription">
                                                    <pre className="mailpreContent" readOnly="">
                                                        {maintenance.message}
                                                    </pre>
                                                </div>
                                                <div class="col-md-12 attachimgOtr p-0">
                                                    {maintenance.attachments && maintenance.attachments.map((e,i)=> {
                                                        return <div class="col-xl-2 col-md-2 col-sm-2 attachimgpadng" key={i}>
                                                        <div class="col-md-12 attachList">
                                                            <img src={MAIN_ROUTE + e.path} />
                                                        </div>
                                                        <p>{e.filename}</p>
                                                    </div>
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                            }
                        </div>
                    </div>
                    {/* <div className="modal-footer">
              <button type="button" className="btn btn-primary btnSubmit">
                Submit
              </button>
            </div> */}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    const {maintenanceReducer, commonReducer, tenantReducer, messageReducer} = state;
    return {
        viewMaintenance: maintenanceReducer.viewMaintenance,
        commonReducer,
        tenantReducer,
        messageReducer
    };
};

export default connect(mapStateToProps, {
     ...messageAction
})(MaintenanceView);
