import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import history from '../../../../config/history';
import toastr from '../../../Common/components/Toaster';
import LoaderSpinner from "../../../Common/components/Loader";
import usePrevious from "../../../Common/components/prevProps";
import moment from 'moment';
import Confirm from '../../../Common/components/Confirm';
import Modal from '../../../Common/components/Modal';
import RecentEmails from './RecentEmails';
import MaintenanceAdd from '../../../Maintenance/components/maintenanceAdd';
import maintenanceAction from '../../../Maintenance/action';

const Others = (props) => {

    const [isClosed, setIsClosed] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [reOpenTenantModal, setReOpenTenantModal] = useState(false);
    const [maintenanceModal, setMaintenanceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isReopen, setIsReopen] = useState(false);
    const [addMaintenanceSet, setAddMaintenance] = useState(false);


    const { tenantById: { tenant: tenantsById } } = props;
    const { commonReducer: { emailCheck } } = props;
    const prevEmailCheck = usePrevious(emailCheck);

    const [editData, setEditData] = useState({
        propertyId: tenantsById && tenantsById.propertyId._id,
        propertyDetail: {
            value: tenantsById && tenantsById.propertyId._id,
            label: tenantsById && tenantsById.propertyId.propertyName
        },
        unitId: tenantsById && tenantsById.unitId,
        paymentId: tenantsById && tenantsById.propertyId.paymentId,
        propertyOwnerName: tenantsById && tenantsById.propertyId.paymentId && tenantsById.propertyId.paymentId.entityName && tenantsById.propertyId.paymentId.entityName,
        tenantName: tenantsById && (tenantsById.firstName + " " + tenantsById.lastName),
        maintenanceFile: [],
        maintenanceFileEdit: [],
        description: null,
        categoryId: null,
        requestById: tenantsById && tenantsById._id,
        isEdit: false,
        isView: false,
        maintenancePicture: [],
        status: 1
    })


    const prevMaintenance = usePrevious(props.maintenances);
    //message showing after maintenance add
    useEffect(() => {
        if (isSubmitted && prevMaintenance !== props.maintenances && !props.maintenances.success) {
            toastr.error(props.maintenances.message)
        } else if (isSubmitted && prevMaintenance !== props.maintenances && props.maintenances.success) {
            toastr.success(props.maintenances.message)
        }
    }, [prevMaintenance, props.maintenances]);

    useEffect(() => {
        if (addMaintenanceSet && !emailCheck.success) {
            toastr.error("Please add a maintenance email!")
        } else if (addMaintenanceSet && emailCheck.success && prevEmailCheck != emailCheck) {
            history.push(`/Add-Maintenance?tenanatId=${tenantById.tenant._id}`)

        }
    }, [prevEmailCheck, emailCheck]);

    const addMaintenance = async () => {
        setAddMaintenance(true)
        await props.emailCheck({ emailType: "maintenance" })
        setAddMaintenance(false)
    }


    const { tenantReducer: { permanentDeleteTenant, closeTenant, tenant } } = props;

    //message showing after reopening tenant account
    useEffect(() => {
        if (isReopen && !tenant.success) {
            toastr.error(tenant.message)
        } else if (isReopen && tenant.success) {
            toastr.success("Tenant account reopened successfully");
        }
    }, [tenant]);

    //message showing after closing tenant account
    useEffect(() => {
        if (isClosed && !closeTenant.success) {
            toastr.error(closeTenant.message)
        } else if (isClosed && closeTenant.success) {
            toastr.success("Tenant moved to archieved");
        }
    }, [closeTenant]);

    //message showing after deleting tenant permanently
    useEffect(() => {
        if (isDeleted && !permanentDeleteTenant.success) {
            toastr.error(permanentDeleteTenant.message)
        } else if (isDeleted && permanentDeleteTenant.success) {
            toastr.success(permanentDeleteTenant.message);
        }
    }, [permanentDeleteTenant]);

    //REOPEN  tenant account
    const onReOpenTenantModal = () => {
        setReOpenTenantModal(true)
    }

    const closeReOpenTenantModal = () => {
        setReOpenTenantModal(false);
    }

    const renderReOpenTenantModal = () => {
        return <Confirm
            title='Reopen Tenant Account'
            message='Do you want to reopen this tenant account?'
            onCancel={closeReOpenTenantModal}
            onConfirm={reOpenAccount}
        />
    }

    const reOpenAccount = async () => {
        setIsReopen(true);
        await props.reopenTenant(props.id)
        setReOpenTenantModal(false);
        props.reloadTenant();
        setTimeout(() => {
            setIsReopen(false)
        }, 500)
    }

    //close or move the tenant account to archived
    const openConfirmModal = () => {
        setConfirmModal(true)
    }

    const closeModal = () => {
        setConfirmModal(false);
        setDeleteModal(false);
    }

    const renderConfirmModal = () => {
        return <Confirm
            title='Close Tenant Account'
            message='Do you want to move this tenant account to archieved?'
            onCancel={closeModal}
            onConfirm={onCloseAccount}
        />
    }

    const onCloseAccount = async () => {
        setIsClosed(true);
        closeModal();
        await props.permanentDelete(props.id);
        props.reloadTenant();
        setTimeout(() => {
            setIsClosed(false)
            history.push('/Tenants')
        }, 1000)
    }


    //Delete tenant account permanently
    const openDeleteModal = () => {
        setDeleteModal(true)
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Tenant'
            message='Do you want to delete this tenant account permanently ?'
            onCancel={closeModal}
            onConfirm={onDelete}
        />
    }

    const onDelete = async () => {
        setIsDeleted(true);
        closeModal();

        await props.deleteTenant(props.id);
        setTimeout(() => {
            setIsDeleted(false);
            history.push('/Tenants')
        }, 1000);

        const { tenantReducer: { tenant, tenants } } = props;
        if (tenant.success && tenants.tenantList.length) {
            props.onChangePerson(tenants.tenantList[0] && tenants.tenantList[0]._id)
        }
    }

    const openMaintenanceModal = () => {
        setMaintenanceModal(true)
    }

    const closeMaintenanceModal = async () => {
        setMaintenanceModal(false);
        setEditData({
            ...editData,
            maintenanceFile: [],
            maintenanceFileEdit: [],
            description: null,
            categoryId: null,
        });
        await props.getTenant({ offset: 0, limit: 15, isDeleted: false });
    }

    const renderMaintenanceModal = () => {
        return <MaintenanceAdd
            addMaintenanceDetails={addMaintenanceDetails}
            onCancel={closeMaintenanceModal}
            maintenanceDetails={editData}
            tenantPage={true}
        />
    }

    const addMaintenanceDetails = async (maintenanceDetails) => {
        setIsSubmitted(true)
        await props.addMaintenance(maintenanceDetails);
        closeMaintenanceModal();
        setTimeout(() => {
            setIsSubmitted(false)
        }, 1000);
    }

    const { tenantById } = props;

    return (
        <div className="col-md-12 otherSection outrTabBg row m-0">
            {confirmModal ? <Modal body={renderConfirmModal()} onCancel={closeModal} /> : null}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeModal} /> : null}
            {reOpenTenantModal ? <Modal body={renderReOpenTenantModal()} onCancel={closeReOpenTenantModal} /> : null}

            {maintenanceModal ? <Modal body={renderMaintenanceModal()} onCancel={closeMaintenanceModal} /> : null}

            {isClosed && <LoaderSpinner />}
            {isReopen && <LoaderSpinner />}
            <div className="col-md-4 otherOtr">
                <div className="col-md-12 otherDtl">
                    <h3>Other Actions</h3>
                    <ul>

                        {/*<li onClick={() => (tenantById && tenantById.tenant && (!tenantById.tenant.isClosed)) ?*/}
                        {/*    history.push(`/Add-Maintenance?tenanatId=${tenantById.tenant._id}`) : ''}>*/}
                        {/*    <span className="imgSpan"><img src="/images/ticket.png"/></span>*/}
                        {/*    <span className="spnTxt">Open New Support Ticket </span>*/}
                        {/*</li>*/}

                        {(tenantById && tenantById.tenant && !tenantById.tenant.isClosed) ?
                            <li onClick={() =>addMaintenance()}>
                                <span className="imgSpan"><img src="/images/ticket.png" /></span>
                        <span className="spnTxt">Open New Support Ticket </span>
                            </li>
                    :

                            <li style={{ cursor: 'no-drop' }}>
                        <span className="imgSpan"><img src="/images/ticket.png" /></span>
                        <span className="spnTxt ">Open New Support Ticket </span>
                    </li>

                    }

                        {(tenantById && tenantById.tenant && !tenantById.tenant.isClosed) ?
                        <li onClick={() => openConfirmModal()} style={{ "cursor": "pointer" }}>
                            <span className="imgSpan"><img src="/images/close.png" /></span>
                            <span className="spnTxt">Close Tenant Account</span>
                        </li>
                        :
                        <li onClick={() => onReOpenTenantModal()} >
                            <span className="imgSpan"><img src="/images/close.png" /></span>
                            <span className="spnTxt clntClosed">Reopen Tenant Account</span>
                        </li>
                    }
                    {(tenantById && tenantById.tenant && tenantById.tenant.isDeleted) ?
                        <li>
                            <span className="imgSpan"><img src="/images/delete.png" className="imgDelt" /></span>
                            <span className="spnTxt clntClosed"> Tenant Account Deleted</span>
                        </li>
                        :
                        <li onClick={() => openDeleteModal()} style={{ "cursor": "pointer" }}>
                            <span className="imgSpan"><img src="/images/delete.png" className="imgDelt" /></span>
                            <span className="spnTxt"> Delete Tenant Account</span>
                        </li>
                    }
                    </ul>
            </div>
        </div>
        <div className="col-md-4 otherOtr">
            <RecentEmails tenantById={tenantById} />
        </div>
        <div className="col-md-4 otherOtr tableSecOther p-0">
            <div className="col-md-12 otherDtl cnfirm">
                <h3>Other Confirmation</h3>
                <div className="col-md-12 table-responsive tableTenantDtl">
                    <table className="table-gap table-td table">
                        <tbody>
                            {/* <tr>
                                <td>Client Group</td>
                                <td>Trillium</td>
                            </tr> */}
                            <tr>
                                <td>Client Creation Date</td>
                                <td>{tenantById.tenant && moment(new Date(tenantById.tenant.createdAt)).format('MM/DD/YYYY')}</td>
                            </tr>
                            <tr>
                                <td>Last Login</td>
                                <td>{tenantById.tenant && tenantById.tenant.lastLoginDate ? moment(new Date(tenantById.tenant.lastLoginDate)).format('MM/DD/YYYY') : 'Not logged in yet'}</td>
                            </tr>
                            <tr>
                                <td>Lease Start Date</td>
                                <td>{tenantById.lease && tenantById.lease.startDate ? tenantById.lease && moment(new Date(tenantById.lease.startDate)).format('MM/DD/YYYY') : "N/A"}</td>
                            </tr>
                            <tr>
                                <td>Lease End Date</td>
                                <td>{tenantById.lease && tenantById.lease.endDate ? tenantById.lease && moment(new Date(tenantById.lease.endDate)).format('MM/DD/YYYY') : "N/A"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </div >
    )
}

const mapStateToProps = state => {
    const { tenantReducer, commonReducer, maintenanceReducer } = state;
    return {
        tenantReducer,
        commonReducer,
        maintenanceReducer,
        maintenances: maintenanceReducer.maintenances,
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction, ...maintenanceAction
})(Others);
