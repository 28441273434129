import React, { useState, useEffect } from "react";
import Modal from '../../Common/components/Modal';
import history from "../../../config/history";
import action from "../action";
import { connect } from "react-redux";
import toastr from '../../Common/components/Toaster';
import LoaderSpinner from "../../Common/components/Loader";
import usePrevious from "../../Common/components/prevProps";
import { numberWithCommas, formatUsPhone } from "../../Common/components/utils";
import AddReport from './AddReport';
import AddTenant from '../../Tenants/components/AddTenants';
import { MAIN_ROUTE } from "../../../config/constants";
import TenantDetailLease from '../../Tenants/components/TenantDetailLease';
import ConfirmLease from '../../Tenants/components/ConfirmLease';
import tenantAction from '../../Tenants/action';
import Confirm from '../../Common/components/Confirm';

const ReportListing = (props) => {

    const [reportModal, setReportModal] = useState(false);
    const [tenantModal, setTenantModal] = useState(false);
    const [reports, setReports] = useState(false);
    const [reportTenant, setReportTenant] = useState(false);
    const [status, setStatus] = useState(false);
    const [confirmLeaseModal, setConfirmLeaseModal] = useState(false);
    const [viewLeaseModal, setViewLeaseModal] = useState(false);
    const [submitLease, setSubmitLease] = useState(false);
    const [tenantId, setTenantId] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { tenantReducer: { lease } } = props;
    const { reportReducer: { getReport: { report: reportList }, deleteReport } } = props;

    const prevUser = usePrevious(reportList);
    const prevLease = usePrevious(lease);

    //to show toster on deleting report
    useEffect(() => {
        if (isSubmitted && !deleteReport.success) {
            toastr.error(deleteReport.message)
        } else if (isSubmitted && deleteReport.success && prevUser != reportList) {
            toastr.success("Successfully deleted");
        }
    }, [prevUser, reportList]);

    //to show toster on sending lease
    useEffect(() => {
        if (submitLease && !lease.success) {
            toastr.error(lease.message)
        } else if (submitLease && lease.success && prevLease != lease) {
            toastr.success(lease.message);
        }
    }, [prevLease, lease]);

    //Add Report
    const onOpenModal = (e) => {
        setReportModal(true);
        setReports(e);
    }

    const onCloseModal = () => {
        setReportModal(false);
    }

    const renderReportModal = () => {
        if (reports)
            return <AddReport
                onCancel={onCloseModal}
                reports={reports}
                status={status}
            />
    }

    //Add tenant
    const onOpenTenantModal = (e) => {
        setTenantModal(true);
        setReportTenant(e);
    }

    const onCloseTenantModal = () => {
        setTenantModal(false);
    }

    const renderTenantModal = () => {
        if (reportTenant)
            return <AddTenant
                onCancel={onCloseTenantModal}
                reports={reportTenant}
                leaseModal={leaseModal}
            />
    }

    //Confirm lease modal
    const leaseModal = (id) => {
        setTenantModal(false);
        setConfirmLeaseModal(true);
        setTenantId(id)
    }

    const closeLeaseModal = () => {
        setConfirmLeaseModal(false);
    }

    const renderModalLease = () => {
        return <ConfirmLease
            title='Lease Agreement'
            message='Do you want to send lease agreement?'
            close={closeLeaseModal}
            onConfirm={leaseAgreement}
            onView={viewAgreement}

        />
    }

    const leaseAgreement = async () => {
        setConfirmLeaseModal(false);
        setTenantModal(false);
        setSubmitLease(true);
        await props.leaseAgreement({ tenantId: tenantId });
        setTimeout(() => {
            setSubmitLease(false);
        }, 1000);
    }

    const viewAgreement = async () => {
        setSubmitLease(true);
        await props.viewLeaseAgreement({ tenantId: tenantId });
        setTimeout(() => {
            setSubmitLease(false);
            setViewLeaseModal(true)
        }, 1000);
    }

    const closeViewLeaseModal = () => {
        setViewLeaseModal(false);
    }

    const renderViewLeaseModal = () => {
        return <TenantDetailLease
            tenantId={tenantId}
            onCancel={closeViewLeaseModal}
            lease={props.tenantReducer.viewLease}
            leaseAgreement={leaseAgreement}
            closeModal={onCloseModal}
            onOpenModal={onOpenModal}
        />
    }

    const openDeleteModal = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const renderDeleteModal = () => {
        return <Confirm
            title='Delete Report'
            message='Do you want to delete this report?'
            onCancel={closeDeleteModal}
            onConfirm={onDelete}
        />
    }

    const onDelete = async () => {
        setIsSubmitted(true);
        closeDeleteModal();
        await props.deleteReport(deleteId);
        setTimeout(async () => {
            setIsSubmitted(false);
        }, 1000);
    }

    const { report } = props;

    return (
        <div className={`table-responsive reportTable 
        ${ report && Object.values(report).length ? " " : "tab-arw"}`}>
            {reportModal ? <Modal body={renderReportModal()} onCancel={onCloseModal} /> : null}
            {tenantModal ? <Modal body={renderTenantModal()} onCancel={onCloseTenantModal} /> : null}
            {confirmLeaseModal ? <Modal body={renderModalLease()} onCancel={closeLeaseModal} /> : null}
            {viewLeaseModal ? <Modal body={renderViewLeaseModal()} onCancel={closeViewLeaseModal} /> : null}
            {deleteModal ? <Modal body={renderDeleteModal()} onCancel={closeDeleteModal} /> : null}
            {submitLease && <LoaderSpinner />}
            {isSubmitted && <LoaderSpinner />}
            {report && Object.values(report).length ?

                <table className="table-gap table-td table-hover">
                    <tbody>
                        <tr className="table-head">
                            <th className="tenantName">Name</th>
                            <th className="wdt-03">Address</th>
                            <th>Email</th>
                            <th className="phoneNo">Phone</th>
                            <th>Criminal Status</th>
                            <th>Credit Status</th>
                            <th className="actionWidth">Action</th>
                        </tr>

                        {Object.values(report).map((e, i) => {
                            return <tr key={i} >
                                <td>
                                    <div className="c-td cursorPointer">
                                        <div className="img-name">
                                            <div className="txtNameLet">
                                                {e.firstName.charAt(0).toUpperCase() +
                                                    e.lastName.charAt(0).toUpperCase()}
                                            </div>
                                            <div className="name">
                                                {e.firstName.charAt(0).toUpperCase() + e.firstName.slice(1) + " " +
                                                    e.lastName.charAt(0).toUpperCase() + e.lastName.slice(1)}
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className="c-td">
                                        <p> {e.address}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="c-td">{e.email}</div>
                                </td>
                                <td>
                                    <div className="c-td">{formatUsPhone(e.phone)}</div>
                                </td>
                                <td>
                                    {e.criminalReport ?
                                        <div className="c-td" onClick={() => window.open(MAIN_ROUTE + e.criminalReport, '_blank')} style={{ cursor: "pointer" }}>
                                            View
                                        </div>
                                        :
                                        <div className="c-td">
                                            Pending
                                        </div>
                                    }
                                </td>
                                <td>
                                    {e.creditReport ?
                                        <div className="c-td" onClick={() => window.open(MAIN_ROUTE + e.creditReport, '_blank')} style={{ cursor: "pointer" }}>
                                            View
                                        </div>
                                        :
                                        <div className="c-td">
                                            Pending
                                        </div>
                                    }
                                </td>
                                <td>
                                    <div className="c-td  justify-content-center editDetlPrp">
                                        <i className="far fa-eye  mr-1" title="View" onClick={() => { setStatus('view'); onOpenModal(e) }}></i>

                                        <i className="fas fa-pencil-alt mr-1" title="Edit" onClick={() => { setStatus('edit'); onOpenModal(e) }}></i>

                                        <i className="fa fa-trash  mr-1" aria-hidden="true" title="Delete" onClick={() => openDeleteModal(e._id)}></i>

                                        <i class="fas fa-plus" onClick={() => onOpenTenantModal(e)} title="Add as Tenant"></i>
                                    </div>
                                </td>
                            </tr>
                        })

                        }
                    </tbody>
                </table>
                :
                <div className="no_value">Add Your First Report To Get Started!!! 


                </div>}
        </div>
    )
}

const mapStateToProps = state => {
    const { reportReducer, tenantReducer } = state;
    return {
        reportReducer, tenantReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...tenantAction
})(ReportListing);
