import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import commonAction from "../../../Common/action";
import action from "../../../Maintenance/action";
import tenantAction from '../../../Tenants/action'
import moment from 'moment';
import history from '../../../../config/history';
import settingsAction from '../../../Settings/action';

const AutoPay = props => {
      
    useEffect(() => {
        props.getTenantMessage()
    }, [])

    const { profileDetail: { profileDetail } } = props;
  useEffect(() => {
    async function renderCall() {
      if (profileDetail)
        await props.getAutoPay({ tenantId: profileDetail.admin.tenantId._id })
        await props.getTenantId(profileDetail.admin.tenantId._id)
    }
    renderCall();
    }, [props.profileDetail])
  
    return (
        <div className="autoplay-header mb-3">
            <div className="autoplay-setup">
                <img src="/images/Auto-Play.png" />
                <h4>SET UP AUTO PAY</h4>
                {/* <p>and never miss a rental payment again.</p> */}
            </div>
            <div className="auto-ply-btn" onClick={() => history.push('Auto-Pay')}>
                <button className="btn">
                    {!props.settingsReducer.autoPay.success ? 'Enable Auto Pay': 'Disable Auto Pay'}
                </button>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { commonReducer, profileReducer, maintenanceReducer, settingsReducer } = state;
    return {
        messageDetailsByTenant: commonReducer.messageDetailsByTenant,
        profileReducer,
        maintenanceReducer,
        profileDetail: profileReducer.profileDetail,
        settingsReducer

    };
}

export default withRouter(connect(mapStateToProps, { ...commonAction, ...action, ...settingsAction, ...tenantAction })(AutoPay));
