import React, {useState, useEffect} from "react";
import {withRouter} from 'react-router-dom';
import commonAction from '../../../Common/action';
import {connect} from "react-redux";
import action from "../../../Properties/action";
import {MAIN_ROUTE} from "../../../../config/constants";
import history from '../../../../config/history'
// import { ToastContainer, toast } from "react-toastify";
import toastr from '../../../Common/components/Toaster'
import "react-toastify/dist/ReactToastify.css";
// import moment from "moment";

import AddPropertyModal from '../../../Properties/components/AddPropertyModal';
import Modal from '../../../Common/components/Modal'
import usePrevious from "../../../Common/components/prevProps";
import {numberWithCommas} from "../../../Common/components/utils";
import LoaderSpinner from "../../../Common/components/Loader";

const Property = props => {
    const [propertyModal, setPropertyModal] = useState(false);
    const [editPropertyModal, setEditPropertyModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState({limit: 15, offset: 0})
    const [unitDetails, setUnitDetails] = useState({
        unitName: "",
        bedRoom: "",
        bathroom: "",
        squareFeet: "",
        features: [],
        amenities: [],
        deposit: "",
        monthlyRent: ""
    });
    const prevUser = usePrevious(props.property);


    useEffect(() => {

        async function renderCall() {
            await props.getProperty(params);
        }

        renderCall();
    }, []);


    useEffect(() => {
        if (isSubmitted && prevUser !== props.property && !props.property.success) {
            toastr.error(props.property.message);
        } else if (
            isSubmitted &&
            prevUser !== props.property &&
            props.property.success
        ) {

            toastr.success("Property created successfully");
        }
    }, [prevUser, props.property]);

    useEffect(() => {
        if (isSubmitted && props.propertyReducer.updatePropertyResponse && props.propertyReducer.updatePropertyResponse.success) {
            toastr.success("Property updated successfully");
        } else if (props.propertyReducer.updatePropertyResponse && props.propertyReducer.updatePropertyResponse.success == false) {
            toastr.error('Failed to udpate property!');
        }
    }, [props.propertyReducer.updatePropertyResponse]);
    const openAddProperty = () => {
        setPropertyModal(true);
    }

    const parseBackgoundImage = (image) => {
        if (image.length) {
            if (image[0].type && image[0].type == "zillo") {
                return image[0].path
            } else {
                return MAIN_ROUTE + image[0].path
            }
        } else if (!image[0]) {
            return '/images/def_property.png'
        }
    }

    const openModal = () => {
        setPropertyModal(true);
    };

    const closeModal = () => {
        setPropertyModal(false);
    };

    const addProperty = async propertyDetails => {
        closeModal();
        setIsSubmitted(true);
        setIsLoading(true)
        await props.addProperty(propertyDetails);
        await props.getProperty(params);
        setIsSubmitted(false);
        setIsLoading(false)
    };

    const renderModal = () => {
        return (
            // <AddProperty
            <AddPropertyModal
                addPropertyDetails={addProperty}
                onCancel={closeModal}
                setIsSubmitted={setIsSubmitted}
                setUnitDetails={setUnitDetails}
                unitDetails={unitDetails}
                renderLoader={isLoading}
            />
        );
    };

    const renderPropertyPage = () => {
        history.push('/Properties/All')
    }

    const updateProperty = async (params) => {
        let propertyDetails = new FormData();
        params.images.map(img => {
            propertyDetails.append("file", img.image[0]);
        })
        propertyDetails.append("data", JSON.stringify(params.data));
        setIsLoading(true)
        setIsSubmitted(true);
        await props.updateProperty(propertyDetails)
        await props.getPropertyById(editPropertyModal._id);
        setIsLoading(false)
        setIsSubmitted(false);
        setEditPropertyModal(false)
    }


    const renderEditPropertyModal = () => {
        return (
            <AddPropertyModal
                onCancel={() => setEditPropertyModal(false)}
                propertyDetails={editPropertyModal}
                addPropertyDetails={updateProperty}
                renderLoader={isLoading}
            />
        );
    }

    return (
        <div
            draggable={props.draggable}
            onDragStart={props.onDragStart()}
            onDragOver={props.onDragOver()}
            onDrop={props.onDrop()}
            id={props.idx}
            className="drpbl col-lg-4 col-md-6 col-sm-12 tableProperty mb-3"
        >
            {isLoading ? <LoaderSpinner/> : null
            }
            {propertyModal ? <Modal body={renderModal()} onCancel={closeModal}/> : null}
            {editPropertyModal ?
                <Modal body={renderEditPropertyModal()} onCancel={() => setEditPropertyModal(false)}/> : null}
            <div className="tableOtr">
                <div className="headerSection d-flex">
                    <h3>
                        Recent Properties
                        {/* <span>({props.commonReducer.Property.totalPropertyCount ? props.commonReducer.Property.totalPropertyCount : 0})</span> */}
                    </h3>
                    <div className="viewMore" onClick={() => renderPropertyPage()}>
                        <img src="/images/more.png"/>
                        <span>View More</span>
                    </div>
                </div>
                <div
                    className={props.commonReducer.Property && props.commonReducer.Property.propertyList && props.commonReducer.Property.propertyList.length ? "table-responsive " : "table-responsive no_value"}>
                    {props.commonReducer.Property && props.commonReducer.Property.propertyList && props.commonReducer.Property.propertyList.length ?
                        <table className="table propTableDtl">
                            <tbody>
                            {props.commonReducer.Property.propertyList.slice(0, 3).map((property, key) => {
                                return <tr key={key}>
                                    <td className={"width-001"} style={{cursor: "pointer"}}
                                        onClick={() => props.history.push(`/PropertyDetails/${property._id}`)}>
                                        <div className="d-flex propDtl align-items-center justify-content-center">
                                            <div className="imgProp">
                                                <img
                                                    src={(property.propertyImage[0]) ? parseBackgoundImage(property.propertyImage) : "/images/def_property.png"}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="width-002"
                                        onClick={() => props.history.push(`/PropertyDetails/${property._id}`)}
                                        style={{cursor: "pointer"}}>
                                        <div className="propDtl">
                                            <div className="textProp">
                                                <h4>{property.propertyName}</h4>
                                                {/*<h5>{property.address}</h5>*/}
                                                {/*<p className="slno">SLNO:190086</p>*/}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="rent">
                                        <h4>Total Rent</h4>
                                        <p>${numberWithCommas(property.totalMonthlyRent)}</p>
                                    </td>
                                    <td className="unit">
                                        <h4>Units</h4>
                                        <p>{property.unitCount}</p>
                                    </td>
                                    <td className="unit">
                                        <h4>Availability</h4>
                                        <p>{property.availableunitCount}</p>
                                    </td>
                                    <td className="icon">
                                        <div className="dropdown moveDrop">
                                            <a
                                                className="dropdown-toggle"
                                                href="#"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-toggle="dropdown"
                                                data-boundary="window"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i className="fas fa-ellipsis-v"></i>
                                            </a>
                                            <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                            >
                                                <a
                                                    onClick={() => props.history.push(`/PropertyDetails/${property._id}`)}
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="far fa-eye"></i> View
                                                </a>
                                                <a
                                                    onClick={() => setEditPropertyModal(property)}
                                                    className="dropdown-item"
                                                    href="#"
                                                >
                                                    <i className="far fa-edit"></i> Edit
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            })}

                            </tbody>
                        </table>
                        :
                        <div className="no_value"><i className="far fa-thumbs-down"></i>No data found!</div>
                    }
                </div>
                <button className="btnadd" onClick={() => openAddProperty()}>
                    <i className="fas fa-plus"></i> Add Property
                </button>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {commonReducer, propertyReducer} = state;
    return {
        commonReducer,
        propertyReducer,
        property: propertyReducer.Property,
    };
}

export default withRouter(connect(mapStateToProps, {...commonAction, ...action})(Property));
