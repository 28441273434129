import React, { useState, useEffect } from "react";
import parser from 'html-react-parser';

const TenantDetailLease = (props) => {

  const { lease: { lease } } = props;

  return (

    <div className="modal addTenant leaseAgreement" id="modalId" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="customtest">
              <h5 className="modal-title" id="exampleModalLabel">Lease Agreement</h5>
              <div className="leaseDiv">
                <button className="btn sendbutton"
                  onClick={() => {
                    props.leaseAgreement()
                  }}>
                  Send
            </button>
                {!props.files &&
                  <button className="btn cancelbtn"
                    onClick={() => {
                      props.onOpenModal(props.tenantId)
                    }}>
                    Edit
            </button>}
              </div>
            </div>

            <button type="button" className="close" onClick={() => props.onCancel()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.leaseViewDetails ? (props.leaseData && parser(lease)) : (lease && lease.documentData && parser(JSON.parse(lease.documentData)))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TenantDetailLease;
