import * as actionType from "./constants";

const initialState = {
    dashboardCardList: {},
    updatedCardList: {},
    payment: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_CARD_POSITIONS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CARD_POSITIONS_SUCCESS:
            return {
                ...state,
                dashboardCardList: { success: true, ...action.response }
            };
        case actionType.GET_CARD_POSITIONS_FAILURE:
            return {
                ...state,
                dashboardCardList: { success: false, ...action.error }
            };

        case actionType.UPDATE_CARD_POSITIONS_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_CARD_POSITIONS_SUCCESS:
            return {
                ...state,
                updatedCardList: { success: true, ...action.response }
            };
        case actionType.UPDATE_CARD_POSITIONS_FAILURE:
            return {
                ...state,
                updatedCardList: { success: false, ...action.error }
            };

        case actionType.MAKE_PAYMENT_REQUEST:
            return {
                ...state
                
            };
        case actionType.MAKE_PAYMENT_SUCCESS:
            return {
                ...state,
                payment: { success: true, ...action.data }
            };
        case actionType.MAKE_PAYMENT_FAILURE:
            return {
                ...state,
                payment: { success: false, ...action.error }
            };
        default:
            return state;
    }
};
