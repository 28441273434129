import React, { useState, useEffect } from "react";
import moment from 'moment'
import history from "../../../config/history";

const MaintenanceList = (props) => {

    const renderStatus = (status) => {
        switch (status) {
            case 1:
                return "Open"
            case 0:
                return "Closed"
            case 2:
                return "Answered"
            case 3:
                return "On-Hold"
            case 4:
                return "In-Progress"
            case 5:
                return "Scheduled"
        }
    }

    const renderTimeSlot = (status) => {
        switch (status) {
            case '1':
                return "Any time"
            case '2':
                return "8am - 12pm"
            case '3':
                return "12pm - 4pm"
            case '4':
                return "4pm - 8pm"
        }
    }

    const handleMaintenance = (id) => {
        history.push(`/Add-Maintenance-Tenant/${id}`)
    }

    return (
        <div className={`table-responsive  ${props.maintenanceList  && props.maintenanceList.length ? " " :"tab-arw"}`}>
            {props.maintenanceList && props.maintenanceList.length ?
                <table className="table-gap table-td">
                    <tr className="table-head">
                        <th className="wdt-03">Subject</th>
                        {/* <th className="wdt-03">Category</th> */}
                        <th className="date-column-width">Reported on</th>
                        <th className="date-column-width">Available Time</th>
                        <th className="date-column-width">Priority</th>
                        <th className="date-column-width">Last Reply</th>
                        <th className="date-column-width">Status</th>
                        {/*<th className="actionSec">Action</th>*/}
                    </tr>
                    {props.maintenanceList.map((maintenance, key) => {
                        return <tr key={key} onClick={() => handleMaintenance(maintenance._id)}>
                            <td>
                                <p>{maintenance.title}</p>
                            </td>
                            {/* <td>
                                <p>
                                    {maintenance.maintenanceCategoryId.name}
                                </p>
                            </td> */}
                            <td>
                                <p>
                                    {moment(maintenance.reportedOn).format('MM/DD/YYYY')}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {maintenance.availableTime ? renderTimeSlot(maintenance.availableTime) : 'N/A'}
                                </p>
                            </td>
                            <td><p>{maintenance.priority ? maintenance.priority : null}</p></td>
                            <td><p>{maintenance.lastMessageCreatedAt && moment(maintenance.lastMessageCreatedAt).format('MM/DD/YYYY')}</p></td>

                            <td>{renderStatus(maintenance.status)}</td>

                        </tr>
                    })}

                </table>: <div className="no_value"><i className="far fa-thumbs-down"></i> Click here to add your maintenance request!!!</div>}
        </div>
    )
}
export default MaintenanceList
