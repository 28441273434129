import React, { useEffect, useState } from "react";
import Pdf from "react-to-pdf";
import { connect } from "react-redux";

import ExpenseFilter from './components/expenseFilter';
import ExpenseReport from './components/expenseReport';
import ExpenseList from './components/expenseList';
import Modal from '../Common/components/Modal'
import AddExpense from './components/addExpense'
import commonAction from '../Common/action';
import action from './action';
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toastr from '../Common/components/Toaster'
import LoaderSpinner from "../Common/components/Loader";
import usePrevious from "../Common/components/prevProps";
import Pagination from '../Common/components/pagination'
import history from "../../config/history";
import Transaction from '../Plaid/components/Transactions';
import PlaidLinkButton from "react-plaid-link";
import axios from "axios";
import PlaidAction from '../Plaid/action';
import InvoiceTransaction from './components/invoiceTransaction'


const Expense = (props) => {
    const [expenseModal, setExpenseModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [params, setParams] = useState({ limit: 5, offset: 0 })
    const [currentPage, setcurrentPage] = useState(1);
    const [setFilter, setIsFilter] = useState(false);
    const [tab, setTab] = useState(false);
    const [editData, setEditData] = useState({
        propertyId: null,
        unitId: null,
        amount: null,
        businessId: null,
        merchantId: null,
        maintenanceCategoryId: null,
        description: "",
        isEdit: false,
        isView: false,
        expenseFile: []
    })


    const prevUser = usePrevious(props.Expense);

    useEffect(() => {
        window.scroll(0, 0)

        async function renderCall() {
            await props.getExpense(params)
            await props.getExpenseGraphDetails()
        }

        renderCall();
        setTimeout(() => {
            setIsLoading(false)

        }, 100)
    }, []);

    useEffect(() => {
        if (isSubmitted && prevUser !== props.Expense && !props.Expense.success) {
            toastr.error(props.Expense.message)
        } else if (isSubmitted && prevUser !== props.Expense && props.Expense.success) {
            toastr.success(props.Expense.message);
        }
    }, [prevUser, props.Expense]);


    const openModal = () => {
        setExpenseModal(true)
    }

    const closeModal = () => {
        setExpenseModal(false)
        setEditData({
            propertyId: null,
            unitId: null,
            amount: null,
            businessId: null,
            merchantId: null,
            maintenanceCategoryId: null,
            expenseFile: [],
            description: "",
            paymentType: "",
            isEdit: false,
            isView: false
        })
    }

    const addExpense = async (expenseForm) => {
        setIsSubmitted(true)
        if (editData.isEdit) {
            await props.updateExpense(expenseForm)
        } else {
            await props.addExpense(expenseForm)
        }
        setEditData({
            propertyId: null,
            unitId: null,
            amount: null,
            businessId: null,
            merchantId: null,
            maintenanceCategoryId: null,
            expenseFile: [],
            description: "",
            paymentType: "",
            isEdit: false,
            isView: false
        })
        setExpenseModal(false)
        await props.getExpense()
        await props.getExpenseGraphDetails()
        setTimeout(() => {
            setIsSubmitted(false)

        }, 1000)
    }

    const renderModal = () => {
        return <AddExpense
            addExpenseDetails={addExpense}
            onCancel={closeModal}
            expenseDetails={editData}
        />
    }

    const ref = React.createRef();


    const onPageChanged = async page => {
        let data = { ...params }
        let offset = ((page - 1) * params.limit) || 0;
        data.offset = offset;
        setParams(data)
        setcurrentPage(page)
        await props.getExpense(data);
    }

    const handleFilter = (e) => {
        e.preventDefault()
        setIsFilter(!setFilter)
    }

    const filterExpense = async (data) => {
        let paramsTemp = { ...params, ...data }

        await props.getExpense(paramsTemp);
        setIsFilter(!setFilter)
    }

    const editExpense = async (expense) => {
        let editData = {
            _id: expense._id,
            propertyId: expense.propertyId._id,
            unitId: expense.unitId ? expense.unitId._id : null,
            amount: expense.amount,
            // businessId: expense.businessId._id,
            // merchantId: expense.merchantId._id,
            maintenanceCategoryId: expense.maintenanceCategoryId._id,
            attachment: expense.attachment,
            expenseFile: [],
            description: expense.description,
            paymentId: expense.paymentId && expense.paymentId._id,
            isEdit: true,
        }
        setExpenseModal(true)
        setEditData(editData)
    }

    const viewExpense = (expense) => {
        let viewData = {
            _id: expense._id,
            propertyId: expense.propertyId._id,
            unitId: expense.unitId ? expense.unitId._id : null,
            amount: expense.amount,
            // businessId: expense.businessId._id,
            // merchantId: expense.merchantId._id,
            maintenanceCategoryId: expense.maintenanceCategoryId._id,
            expenseFile: [],
            attachment: expense.attachment,

            description: expense.description,
            paymentId: expense.paymentId && expense.paymentId._id,
            isView: true,

        }

        setEditData(viewData)
        setExpenseModal(true)
    }

    const closeFilter = () => {
        setIsFilter(false)
    }

    const handleTab = async (tab) => {
        if (tab === "expense") {
            // this.setState({
            //     isActiveDataSource: true,
            //     isActiveTransaction: false
            // }, () => history.push('/DataSource'))
            setTab("expense")
            history.push('/Expense')
        } else if (tab === "invoiceTransaction") {
            setTab("invoiceTransaction")
            history.push('/InvoiceTransaction')
        }
        else {
            setTab("transaction")
            history.push('/Transaction')
            // this.setState({
            //     isActiveTransaction: true,
            //     isActiveDataSource: false
            // }, () => history.push('/Transaction'))
        }

    }

    const handleOnSuccess = async (public_token, metadata) => {
        //send token to client server
        // axios.post("/auth/public_token", {
        //     public_token: public_token
        // });
        setIsSubmitted(true)
        // this.setState({isSubmitted: true})
        await props.postPlaidRequest({ publicToken: public_token })
        setIsSubmitted(false)

        await props.getDataSource({ limit: 5, offset: 0 })
        await props.getTransaction({ limit: 5, offset: 0 })
    }

    const handleOnExit = () => {
        // handle the case when your user exits Link
        // For the sake of this tutorial, we're not going to be doing anything here.
    }

    const { location: { pathname } } = props;

    return (
        <div className="tenants-otr">
            {/* <ToastContainer/> */}
            {(isSubmitted || isLoading) && <LoaderSpinner />}
            {expenseModal ? <Modal body={renderModal()} onCancel={closeModal} /> : null}
            <div className="innago-table-top main-table-top">
                <div className="lft">
                    <h1>{pathname == "/Expense" ? "EXPENSES" : pathname == "/Transaction" ? "TRANSACTION" : "INVOICE TRANSACTION"}</h1>
                </div>

            </div>
            <div className="expenseTab">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a
                            className={`nav-link  ${pathname == "/InvoiceTransaction" ? "active" : null}`}
                            id="pills-transaction-tab"
                            data-toggle="pill"
                            href="#pills-transaction"
                            role="tab"
                            aria-selected="false"
                            onClick={() => handleTab("invoiceTransaction")}
                        >Invoice Transactions</a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link  ${pathname == "/Expense" ? "active" : null}`}
                            // id="pills-expense-tab"
                            data-toggle="pill"
                            href="#pills-expense"
                            role="tab"
                            aria-selected="true"
                            onClick={() => handleTab("expense")}
                        >Expenses</a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link  ${pathname == "/Transaction" ? "active" : null}`}
                            id="pills-transaction-tab"
                            data-toggle="pill"
                            href="#pills-transaction"
                            role="tab"
                            aria-selected="false"
                            onClick={() => handleTab("transaction")}
                        >Transaction</a>
                    </li>

                </ul>
            </div>
            <div className=" transaction-tab ">
                {pathname == "/Expense" ? <><ExpenseReport ExpenseList={props.ExpenseList}
                    expenseGraph={props.commonReducer.expenseGraph}
                    setFilter={setFilter}
                    handleFilter={handleFilter}
                    filterExpense={filterExpense}
                    closeFilter={closeFilter}
                    openModal={openModal}
                />
                    <div className="innago-table table-main expenses-table" ref={ref}>
                        <ExpenseList ExpenseList={props.ExpenseList}
                            editExpense={editExpense}
                            viewExpense={viewExpense} />
                        <div className="paginationSec">
                            {props.ExpenseList.expensesCount ? <Pagination
                                totalRecords={props.ExpenseList.expensesCount}
                                pageLimit={params.limit}
                                pageNeighbours={1}
                                currentPage={currentPage}
                                onPageChanged={onPageChanged}
                            /> : null}

                        </div>
                    </div>
                </> : pathname == "/InvoiceTransaction" ? <InvoiceTransaction /> : <Transaction />}
            </div>


        </div>
    )
}

function mapStateToProps(state) {
    const { expenseReducer, commonReducer, plaidReducer } = state;
    return {
        Expense: expenseReducer.Expense,
        ExpenseList: expenseReducer.ExpenseList,
        commonReducer,
        plaidReducer
    };
}

export default connect(mapStateToProps, { ...commonAction, ...action, ...PlaidAction })(Expense);
