import React from "react";
import action from "./action";
import {connect} from "react-redux";
import MessageContainer from "./components/MessageContainer";
import {withRouter} from "react-router-dom"

class Messaging extends React.Component {

    render() {
        // const {location: {pathname}} = this.props;
        return (
            <MessageContainer/>
        )
    }
}

const mapStateToProps = state => {
    const {messageReducer} = state;
    return {messageReducer}
};

export default withRouter(connect(mapStateToProps, {...action})(Messaging));