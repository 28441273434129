import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from 'moment'
import commonAction from '../../../Common/action'
import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import 'rc-menu/assets/index.css';
import AsyncSelect from 'react-select/async';

import action from "../../action";
import TransactionEdit from './editTransaction'
import Modal from '../../../Common/components/Modal';
import Confirm from '../../../Common/components/Confirm'
import { CalendarContainer } from "react-datepicker";
import LoaderSpinner from "../../../Common/components/Loader";

import usePrevious from "../../../Common/components/prevProps";
import toastr from "../../../Common/components/Toaster";
import { numberWithCommas } from "../../../Common/components/utils";

import Select from 'react-select-nested';
import DropdownTreeSelect from "react-dropdown-tree-select";
import ContentEditable from 'react-contenteditable'


const TransactionList = (props) => {

    const prevTransaction = usePrevious(props.deleteTransactionData);
    const [temp, setTemp] = useState("");
    const [isCategory, setCategory] = useState(null)
    const [name, setName] = useState(null)
    const [category, setCategoryId] = useState({ categoryId: null, categoryName: null })
    const [editModal, isEditModal] = useState(false)
    const [deleteModal, isDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isMenu, setMenu] = useState(false)
    const [categoryName, setCategoryName] = useState()

    // const [isTextArea, setTextArea] = useState(null)
    // const [handleCategoryId, setHandleCategory] = useState(null)


    useEffect(() => {
        props.getProperty()
        props.getCategories()
        props.getTransaction(props.params)

    }, [])


    useEffect(() => {
        if (isSubmitted && prevTransaction !== props.deleteTransactionData && !props.deleteTransactionData.success) {
            toastr.error(props.deleteTransactionData.message)
        } else if (isSubmitted && prevTransaction !== props.updateTransaction && props.deleteTransactionData.success) {
            toastr.success(props.deleteTransactionData.message);
        }
    }, [prevTransaction, props.deleteTransactionData]);

    const renderSubmenu = (category) => {
        return category.subCategories.map(sub => {
            return <MenuItem value={sub._id}>{sub.title}</MenuItem>
        })
    }
    const [editData, setEditData] = useState({
        name: null, date: null, isMoneyIn: false,
        isMoneyOut: false, amount: null, category: null, property: null, description: null, files: []
    })

    const handleCategory = (key) => {
        if (key == isCategory) {
            setCategory(null)
        } else {
            setCategory(key)
        }

    }

    const handleOptionCategory = (info) => {
        setCategory(null)
        props.updateTransaction({ categoryId: info.item.props.value })
        setCategoryId({ categoryId: info.item.props.value, categoryName: info.item.props.children })
    }

    const editTransaction = (transaction) => {
        let editData = {
            transactionId: transaction._id,
            name: transaction.name,
        date: moment(new Date(transaction.date)).endOf('day').toISOString,
            amount: transaction.amount,
            property: transaction.property ? transaction.property._id : null,
            propertDetails: transaction.property ? {
                value: transaction.property._id,
                label: transaction.property.propertyName
            } : null,
            files: [],
            moneyStatus: transaction.moneyStatus,
            attachment: transaction.attachment,
            details: transaction.details,
            categoryName: transaction.transactionSubCategory ? transaction.transactionSubCategory.title : transaction.transactionCategory ? transaction.transactionCategory.title : null,
            categoryId: transaction.transactionCategory && transaction.transactionCategory._id,
            subCategory: transaction.transactionSubCategory && transaction.transactionSubCategory._id


        }
        setEditData(editData)
        isEditModal(true)
    }

    const closeEditModal = () => {
        isEditModal(false)
    }

    const renderModal = () => {
        return <TransactionEdit onCancel={closeEditModal} editData={editData} handleUpdateData={handleUpdateData} />
    }

    const deleteTransaction = (id) => {
        setDeleteId(id)
        isDeleteModal(true)
    }

    const handleUpdateData = () => {
        props.getTransaction(props.params)
        props.setTextArea(null)
        props.setHandleCategory(null)
    }

    const closeTransaction = () => {
        isDeleteModal(false)
    }

    const renderDelete = () => {
        return <Confirm
            title='Delete Transaction'
            message='Do you want to delete this transaction?'
            onCancel={closeTransaction}
            onConfirm={deleteTransactionData}
        />
    }


    const deleteTransactionData = async () => {
        isDeleteModal(false)
        setIsSubmitted(true)
        await props.deleteTransaction({ transactionId: deleteId })
        await props.getTransaction({ limit: 5, offset: 0 })
        setIsSubmitted(false)
        setDeleteId(null)
    }

    const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));


    const promiseOptionsProperty = async val => {
        await props.getProperty({ name: val })
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName,

        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }


    const handleChangeProperty = async (value, transaction) => {
        let data = {
            property: value.value,
            transactionId: transaction._id
        }
        // setIsSubmitted(true)
        await props.updateTransaction(data)
        await props.getTransaction(props.params)
    }

    const renderCategory = (transaction) => {
        return props.categoryData && props.categoryData.length && props.categoryData.map((category => ({
            val: category._id,
            transactionId: transaction._id,
            label: category.title,
            children: category.subCategories ? category.subCategories.map(sub => ({
                parentVal: category._id,
                transactionId: transaction._id,
                val: sub._id,
                label: sub.title
            })) : null
        })))
    }


    const handleCategoryDetails = async (currentNode, selectedNodes) => {
        setTemp(currentNode.val)
        let data = {
            transactionId: currentNode.transactionId,
            category: currentNode.parentVal ? currentNode.parentVal : currentNode.val,
            subCategory: currentNode.parentVal ? currentNode.val : null
        }
        updateCategory(data)

        setTemp(currentNode.val)
    }


    const handleOption = async (info, transaction) => {
        setCategoryName(info.item.props.children)


        setTemp(info.item.props.value)
        let data = {
            transactionId: transaction._id,
            category: info.item.props.parentMenu.props.value ? info.item.props.parentMenu.props.value : info.item.props.value,
            subCategory: info.item.props.parentMenu.props.value ? info.item.props.value : null
        }
        setIsSubmitted(true)
        updateCategory(data)
        setTemp(info.item.props.value)


    }

    const updateCategory = async (data) => {
        props.setHandleCategory(null)
        await props.updateTransaction(data)
        window.location.reload()
        await props.getTransaction(props.params)
        setIsSubmitted(false)
        setMenu(false)

    }

    const handleName = async (event, transaction) => {
        setName(event.target.value)
        let data = {
            transactionId: transaction._id,
            name: event.target.value

        }
        await props.updateTransaction(data)
        await props.getTransaction(props.params)
    }

    const triggerText = (key) => {
        props.setTextArea(key)
    }

    const handleSearch = async (e) => {
        await props.getCategories({ title: e.target.value })
    }


    return (
        <div className="table-responsive transactionResponsive">
            {isSubmitted && <LoaderSpinner></LoaderSpinner>}
            {editModal ? <Modal body={renderModal()} onCancel={closeEditModal} /> : null}
            {deleteModal ? <Modal body={renderDelete()} onCancel={closeTransaction} /> : null}
            <table className="table-gap table-td transactionTbl">
                <tr className="table-head">
                    <th className="checkbxwidth">
                        <label className="transactionCheckbox"
                        ><input type="checkbox" name="isArchived"
                            checked={props.checkedAll ? true : false}
                            onClick={(e) => props.handleCheckAll(e)}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </th>

                    <th className="width-01">Date</th>
                    <th className="width-02">Description</th>
                    <th className="width-03">Category</th>
                    <th className="width-04">Property</th>
                    <th className="width-05">Amount</th>
                    <th className="width-06">Account</th>
                    <th className="width-07">Action</th>
                </tr>
                {props.transactionData && props.transactionData.transactions && props.transactionData.transactions.length ? props.transactionData.transactions.map((transaction, key) => {
                    let categoryId = isCategory + key
                    let isChecked = props.checkedArray && props.checkedArray.length
                        ? props.checkedArray.find(p => p.transactionId === transaction._id) : false

                    return (
                        <tr key={key}>
                            <td className="transactionChkbxPadng">
                                <label className="transactionCheckbox"
                                ><input type="checkbox" name="isArchived"
                                    checked={isChecked ? true : false}
                                    onClick={(e) => props.handleCheck(e, transaction)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </td>
                            <td>
                                <div className="transactionDate  formInp">
                                    {moment(transaction.date).format('MM/DD/YYYY')}
                                </div>

                            </td>
                            <td>
                                <div className="descHead cursorPointer">
                                    {/*<ContentEditable onChange={(e) => handleName(e, transaction)}*/}
                                    {/*className={"form-control transaction-input"}*/}
                                    {/*html={transaction.name} />*/}
                                    {props.isTextArea != key ?
                                        <span onClick={() => triggerText(key)}>{transaction.name}</span> : null}
                                    {props.isTextArea == key ? <textarea onChange={(e) => handleName(e, transaction)}
                                        className={"form-control transaction-input"}>
                                        {transaction.name}
                                    </textarea> : null}
                                    {/*{transaction.name}*/}
                                    <p className="descSub">{transaction.amount >= 0 ? "Credit" : "Debit"}</p>
                                    {/*<p className="descSub">{transaction.inAccountId.type}</p>*/}
                                </div>
                            </td>
                            <td className={`${props.handleCategoryId == key ? "category-select-plaid" : transaction.transactionSubCategory ? transaction.transactionSubCategory.title : transaction.transactionCategory ? transaction.transactionCategory.title : "not-available"}`}>
                                <div className="categorySec cursorPointer" onClick={() => props.setHandleCategory(key)}>
                                    {props.handleCategoryId != key
                                        ? transaction.transactionSubCategory ?
                                            <>{transaction.transactionCategory.title}<i className="fas fa-long-arrow-alt-right"></i>{transaction.transactionSubCategory.title}</>
                                            : transaction.transactionCategory ? transaction.transactionCategory.title : "N/A"
                                        : null
                                    }


                                    {/* {props.handleCategoryId == key ? <DropdownTreeSelect
                                        data={renderCategory(transaction)}
                                        mode='radioSelect'
                                        texts={{ placeholder: 'Select Category...' }}
                                        onChange={handleCategoryDetails}
                                        className={'mdl-demo drpdwn'}

                                    /> : null} */}
                                    {props.handleCategoryId == key ? <> <div className="selctOtr" >
                                        <input type={"text"} placeholder={"All Categories"} className="form-control"
                                            value={categoryName}
                                            readOnly={true}
                                            onClick={() => setMenu(!isMenu)} />
                                    </div>

                                        {isMenu ?
                                            <>
                                                <input type={"text"} placeholder={"Search"} className="form-control"
                                                    onChange={(e) => handleSearch(e)}
                                                    style={{ 'position': 'absolute', 'top': '0' }}
                                                />
                                                {props.categoryData && props.categoryData.length ?
                                                    <Menu onSelect={(info) => handleOption(info, transaction)}> {props.categoryData.map((category, key) => {
                                                        {
                                                            return !category.subCategories.length ?
                                                                <MenuItem value={category._id}>{category.title}</MenuItem>
                                                                : <SubMenu
                                                                    title={category.title}
                                                                    value={category._id}> {renderSubmenu(category)}</SubMenu>
                                                        }
                                                    })}</Menu> : null}</> : null}</> : null}



                                </div>
                            </td>
                            <td className={`${transaction.property ? "property-select-plaid" : "not-available"}`}>
                                <div className="propertySec">
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions={Properties}
                                        placeholder={"All Properties"}
                                        loadOptions={promiseOptionsProperty}
                                        name="tenantId"
                                        onChange={(value) => handleChangeProperty(value, transaction)}
                                        value={transaction.property ? {
                                            value: transaction._id,
                                            label: transaction.property.propertyName
                                        } : "N/A"}
                                    />
                                    {/*<p>{transaction.property ? transaction.property.propertyName : "N/A"}</p>*/}
                                </div>
                            </td>
                            <td>
                                <div className="amountSec">
                                    <p className="transacionAmount">${transaction.amount ? numberWithCommas(transaction.amount) : 0.00}</p>
                                </div>
                            </td>
                            <td>
                                <div className="accountSec">
                                    {transaction.inAccountId && transaction.inAccountId.officialName}
                                    <p className="descSub"> {transaction.inAccountId && transaction.inAccountId.name}</p>
                                </div>
                            </td>
                            <td>
                                <div className="editDetlPrp text-center">
                                    <i className="fa fa-trash mr-1"
                                        onClick={() => deleteTransaction(transaction._id)}></i>
                                    <i
                                        className="fas fa-pencil-alt"
                                        aria-hidden="true"
                                        data-toggle="modal"
                                        data-target="#editTransactionModal"
                                        onClick={() => editTransaction(transaction)}
                                    ></i>
                                </div>
                            </td>
                        </tr>

                    )
                }) : <tr>
                        <td colSpan={8} className={"text-center no_value_table"}><i className="far fa-thumbs-down"></i>No
                            Data Found!!!
                    </td>
                    </tr>}
            </table>
        </div>

    )
}

const mapStateToProps = state => {
    const { plaidReducer, commonReducer } = state;
    return {
        dataSources: plaidReducer.dataSources,
        plaidReducer,
        deleteTransactionData: plaidReducer.deleteTransactionData,
        commonReducer,
        categoryData: plaidReducer.categoryData
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(TransactionList);

