import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import action from "../action";
import TenantAction from "../../Tenants/action";
import commonAction from "../../Common/action";
import OwlCarousel from "react-owl-carousel2";
import * as utils from "../../Common/components/utils";
import Pagination from '../../Common/components/pagination';
import { MAIN_ROUTE } from "../../../config/constants";
import toastr from "../../Common/components/Toaster";
import { toast } from "react-toastify";

const options = {
    items: 1,
    nav: true,
    rewind: true,
    loop: true,
    autoplay: false,
    navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>'
    ]
};

class LeasesTenant extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: {
                limit: 5,
                offset: 0
            },
            currentPage: 1
        }
    }

    componentDidMount() {
        const { params } = this.state

        this.props.getTenantPropertyDetails(params)
    }

    onPageChanged = async page => {
        const { params } = this.state
        let data = { ...params };
        data.offset = ((page - 1) * params.limit) || 0;
        this.setState({
            params: data,
            currentPage: page
        })
        await this.props.getTenantPropertyDetails(params);
    }

    parsePropImage = (image) => {
        if (!image) {
            return '/images/def_property.png'
        }
        if (image.type == 'innago') {
            return MAIN_ROUTE + image.path
        } else if (image.type == 'zillo') {
            return image.path
        } else {
            return '/images/def_property.png'
        }
    }

    viewLease = async () => {
        // await this.props.getTenantId(id)
        let id = this.props.profileDetail.profileDetail.admin.tenantId._id
        this.setState({
            isSubmitted: true
        })
        await this.props.getTenantProperty(id)

        const { tenantReducer: { tenantsPropertyById: { tenant } } } = this.props;
        if (tenant && tenant.agreementSent) {
            this.setState({
                submitLease: true
            });
            await this.props.viewAgreement({ tenantId: id });
            const { tenantReducer } = this.props;
            if (tenantReducer && tenantReducer.viewLeasePath.lease) {
                window.open(MAIN_ROUTE + tenantReducer.viewLeasePath.lease, '_blank');
                this.setState({
                    submitLease: false
                });
            }
            this.setState({
                isSubmitted: false
            })
        } else {
            this.setState({
                isSubmitted: false
            })
            toastr.error("No lease agreement, Please contact the property manager", {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    render() {
        const { commonReducer: { propertyDetailsByTenant } } = this.props;
        const { currentPage } = this.state;
        const { property, maintenance } = propertyDetailsByTenant || {}
        // console.log("property:profile==>",this.props.profileDetail.profileDetail.tenantId_id)
        return (
            <div className="tenants-otr">
                <div className="innago-table-top tenantDtlTop">
                    <div className="lft">
                        <h1>LEASES & FILES</h1>
                    </div>
                    <div className="rgt">
                        <button className="export tenantViewBut" id="viewAllTenant">
                            <i className="far fa-eye" />
                            View All Tenants
                        </button>
                    </div>
                </div>
                <div className="tenantDtlOutr propertyOuter leaseStatusOuter tenantpropOtr">
                    <div className="col-md-12 tenantDtl p-0">
                        <div className="col-md-3 propLeftImg">
                            {
                                property &&
                                    property.propertyId.propertyImage &&
                                    property.propertyId.propertyImage.length
                                    ? <OwlCarousel options={options}>
                                        {
                                            property.propertyId.propertyImage.map((img, id) => {
                                                return (
                                                    <div key={id} className="propImg">
                                                        <img src={this.parsePropImage(img)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                    : <div className="propImg">
                                        <img src='/images/def_property.png' />
                                    </div>
                            }
                            <div className="propCont">
                                <h3>
                                    {property ? property.propertyId.propertyName : ''}
                                </h3>
                                <p className="col-md-11 p-0 contadr">
                                    {property ? property.propertyId.address : ''}
                                </p>
                            </div>
                        </div>
                        <div className="propertyDtlRight col-md-9">
                            <div className="rightBg">
                                <div className="tab-content">
                                    <div id="unit1" className="unitDetl">

                                        <div className="innago-table leaseOtr">
                                            <h3>Lease Details</h3>
                                            <div className="col-md-12 leaseDtlOtr d-flex">
                                                <div className="col-md-6 d-flex leaseDate bdtr">
                                                    <div className=" leaseImg">
                                                        <img src="/images/calender.png" />
                                                    </div>
                                                    <div className="leaseCont">
                                                        <h4>
                                                            Lease Start Date
                                                        </h4>
                                                        <p className="dateSection">
                                                            {property ? utils.formatDate(property.leaseId ? property.leaseId.startDate : 'N/A') : 'N/A'}
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 d-flex leaseDate">
                                                    <div className=" leaseImg">
                                                        <img src="/images/calender.png" />
                                                    </div>
                                                    <div className="leaseCont">
                                                        <h4>
                                                            Lease End Date
                                                        </h4>
                                                        <p className="dateSection">
                                                            {property ? utils.formatDate(property.leaseId ? property.leaseId.endDate : 'N/A') : 'N/A'}
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="innago-table">
                                            <h3>Lease Status</h3>
                                            <div className="col-md-12 d-flex status-outerbar">
                                                <div className="col-md-6 d-flex status-innerbar">
                                                    <div className="imgSecbr bdr">
                                                        <img src="images/icon-dots.png" alt="" />
                                                    </div>
                                                    <div className="txtlse">
                                                        <h4>Lease Status</h4>
                                                        <p>{property && property.leaseId ? property.leaseId.status === 0 ? 'Not Sent' : (property.leaseId.status === 1 ? "Pending" :
                                                            (property.leaseId.status === 2 ? "Active" : " N/A")) : " N/A"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 d-flex status-innerbar">
                                                    <div className="imgSecbr">
                                                        <img src="images/icon-dots.png" alt="" />
                                                    </div>
                                                    <div className="txtlse">
                                                        <h4>Lease Agreement</h4>
                                                        <a href="#" onClick={this.viewLease}>
                                                            <p className="linkr">View Agreement</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>


                                            {/*<h3>Lease Status</h3>*/}
                                            {/*<div className="table-responsive">*/}
                                            {/*<table className="table-gap table-td">*/}
                                            {/*<tbody>*/}
                                            {/**/}

                                            {/*<tr className="detailRow">*/}
                                            {/*<td className="text-center">*/}
                                            {/*<i><img src="/images/icon-dots.png" alt=""/></i>*/}
                                            {/*</td>*/}
                                            {/*<td>*/}
                                            {/*<div className="c-td">*/}
                                            {/*Lease Status*/}

                                            {/*</div>*/}
                                            {/*<span> {property && property.leaseId ? property.leaseId.status === 0 ? 'Not Sent' : (property.leaseId.status === 1 ? "Pending" :*/}
                                            {/*(property.leaseId.status === 2 ? "Active" : null)) : null}</span>*/}
                                            {/*</td>*/}

                                            {/*</tr>*/}
                                            {/*<tr className="detailRow">*/}
                                            {/*<td className="text-center">*/}
                                            {/*<i><img src="/images/icon-dots.png" alt=""/></i>*/}
                                            {/*</td>*/}
                                            {/*<td>*/}
                                            {/*<div className="c-td">*/}
                                            {/*Lease Agreement*/}

                                            {/*</div>*/}
                                            {/*<span onClick={this.viewLease}> View Lease</span>*/}
                                            {/*</td>*/}

                                            {/*</tr>*/}


                                            {/*</tbody>*/}
                                            {/*</table>*/}
                                            {/*</div>*/}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}


const mapStateToProps = state => {
    const { leaseReducer, tenantReducer, commonReducer, profileReducer } = state;
    return {
        leaseReducer,
        tenantReducer,
        commonReducer,
        profileDetail: profileReducer.profileDetail
    };
};

export default connect(mapStateToProps, {
    ...action,
    ...TenantAction,
    ...commonAction
})(LeasesTenant)

