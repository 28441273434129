import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import commonAction from '../../../Common/action';
import action from '../../action'
import AsyncSelect from 'react-select/async';
import moment from "moment"
import * as validation from '../../../Common/components/validation';
import NumberFormat from 'react-number-format';
import toastr from "../../../Common/components/Toaster";
import usePrevious from "../../../Common/components/prevProps";
import { MAIN_ROUTE } from "../../../../config/constants";
import DropdownTreeSelect from "react-dropdown-tree-select";
import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import 'rc-menu/assets/index.css';
import CommonDatePicker from "../../../Common/components/CommonDatePicker";


const EditTransaction = (props) => {
    const prevTransaction = usePrevious(props.updateTransactionData);

    const [transaction, setTransactionForm] = useState({
        name: null, date: null, isMoneyIn: false,
        isMoneyOut: false, amount: null, category: null, property: null, description: null, files: [],
        propertDetails: null, moneyStatus: null,
        details: null,
        categoryName: null
    })
    const [attachmentChanged, setAttachmentChanged] = useState(false)
    const [uploadError, setError] = useState("")
    const [uploadattachmentHeader, setUploadattachmentHeader] = useState("")
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isMenu, setMenu] = useState(false)

    useEffect(() => {
        props.getProperty()
        props.getCategories()
        setTransactionForm(props.editData)
    }, []);

    useEffect(() => {
        if (isSubmitted && prevTransaction !== props.updateTransactionData && !props.updateTransactionData.success) {
            toastr.error(props.updateTransactionData.message)
        } else if (isSubmitted && prevTransaction !== props.updateTransactionData && props.updateTransactionData.success) {
            toastr.success(props.updateTransactionData.message);
        }
    }, [prevTransaction, props.updateTransactionData]);

    const validate = (value, name, type) => {
        if (isSubmitted) {
            switch (type) {
                case "required":
                    return (
                        validation.REQUIRED(value, name) != true ?
                            <div className={"errorMsg"}>{validation.REQUIRED(value, name)}</div> : null
                    );
                case "isNumber":
                    return (
                        !validation.NUMBER_ONLY(value)
                            ? <div className={"errorMsg"}>
                                * Only numbers are allowed

                            </div> : null
                    );
            }
        } else return null;
    };

    const handleSubmit = async () => {

        setIsSubmitted(true)
        if (transaction.amount &&
            transaction.date &&
            transaction.name &&
            transaction.moneyStatus &&
            validation.NUMBER_ONLY(transaction.amount)
            // transaction.categoryId &&
            // transaction.description &&
            // transaction.property
            // transaction.files
        ) {
            await props.updateTransaction(transaction)
            await props.getTransaction({ limit: 5, offset: 0 })
            props.onCancel()
            setIsSubmitted(false)
            props.handleUpdateData()
        }
    }


    const handleChange = (e) => {
        if (e.target.name == "moneyStatus") {
            const { name, value } = e.target
            if (e.target.checked) {
                setTransactionForm({ ...transaction, moneyStatus: value })
            } else {
                setTransactionForm({ ...transaction, moneyStatus: null })
            }
        } else if (e.target.name === "amount") {
            const { name, value } = e.target
            setTransactionForm({
                ...transaction,
                [name]: value.replace("$", "").replace(/,/g, '')
            })
        } else {
            const { name, value } = e.target
            setTransactionForm({ ...transaction, [name]: value })
        }

    }

    const onChangeDate = (date) => {
        setTransactionForm({ ...transaction, date: moment(new Date(date)).endOf('day').toISOString()})

    }

    const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
        value: e._id,
        label: e.propertyName
    }));
    //
    // let Properties = [{
    //     label: 'sd',
    //     options: [
    //         {
    //             label: "sadasd",
    //             options: [{label: "scscxsza", value: 2}]
    //         }
    //     ],
    // }]

    const promiseOptionsProperty = async val => {
        await props.getProperty({ name: val })
        const Properties = props.commonReducer.Property.success && props.commonReducer.Property.propertyList.map(e => ({
            value: e._id,
            label: e.propertyName,

        }));
        return Properties.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
    }


    const handleChangeProperty = async (value) => {
        setTransactionForm({ ...transaction, property: value.value, propertDetails: value })
    }

    const isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    };


    const handleChangeFile = (e) => {
        if (isIterable(e.target.files)) {
            if (e.target.files.length) {
                setUploadattachmentHeader('Add')
            }
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split('.').pop();
                const acceptableExt = ["pdf", "docx", "doc", "txt", "png", "jpg", "ttf", "avi", "flv", "wmv", "mov", "pdf", "doc", "docx"];
                if (acceptableExt.includes(ext)) {
                    if (attachment.size < 25000000) {
                        setAttachmentChanged(true)
                        // console.log("e.target.files",transaction.files)
                        let attachmentTemp = [...transaction.files, ...e.target.files];
                        setTransactionForm({ ...transaction, files: attachmentTemp })
                        // uploadAttachment: [...this.state.uploadAttachment, ...e.target.files]
                    } else {
                        setError('File is too big. Files with size greater than 25MB is not allowed.')
                    }
                } else {
                    setAttachmentChanged(false)
                    setError('* Accept only image / video / emoji / documents !!!')
                }
            })
        }
    }

    const deleteAttachment = (index) => {
        let fileArray = transaction.files
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            setAttachmentChanged(false)
            setTransactionForm({ ...transaction, files: [] })
            setUploadattachmentHeader('Upload')
        }
        setTransactionForm({ ...transaction, files: fileArray })
        // document.getElementById('attachmentFiles').value = null
    };

    const deleteAttachmentEdit = (index, attachment) => {

        let fileArray = transaction.attachment
        fileArray.splice(index, 1);
        if (!fileArray.length) {
            setAttachmentChanged(false)
            setTransactionForm({ ...transaction, attachment: [] })
            setUploadattachmentHeader('Upload')
        }
        setTransactionForm({ ...transaction, attachment: fileArray })
        // document.getElementById('attachmentFiles').value = null
    };

    const renderCategory = () => {
        return props.categoryData && props.categoryData.length && props.categoryData.map((category => ({
            val: category._id,
            label: category.title,
            children: category.subCategories ? category.subCategories.map(sub => ({
                parentVal: category._id,

                val: sub._id,
                label: sub.title
            })) : null
        })))
    }


    const renderSubmenu = (category) => {
        return category.subCategories.map(sub => {
            return <MenuItem value={sub._id} parentId={category._id}>{sub.title}</MenuItem>
        })
    }
    const Categories = props.categoryData && props.categoryData.length && props.categoryData.map((category => ({
        val: category._id,
        label: category.title,
        children: category.subCategories ? category.subCategories.map(sub => ({
            parentVal: category._id,
            val: sub._id,
            label: sub.title
        })) : null
    })))

    const handleCategoryDetails = async (currentNode, selectedNodes) => {
        setTransactionForm({
            ...transaction, category: currentNode.parentVal ? currentNode.parentVal : currentNode.val,
            subCategory: currentNode.parentVal ? currentNode.val : null,
        })


    }

    const handleOption = (info) => {
        setMenu(false)
        setTransactionForm({
            ...transaction,
            category: info.item.props.parentMenu.props.value ? info.item.props.parentMenu.props.value : info.item.props.value,
            subCategory: info.item.props.parentMenu.props.value ? info.item.props.value : null,
            categoryName: info.item.props.children


        })
    }

    const handleSearch = async (e) => {
        await props.getCategories({ title: e.target.value })
    }

    return (
        <div
            className="modal addTenant small-modal editTransactionModal"
            id="modalId"
            style={{ display: 'block' }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit Transaction</h5>
                        <button
                            type="button"
                            className="close"
                            onClick={props.onCancel}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="clientInfo">
                            <div className="formSection">
                                <div className="row m-0 p-0">
                                    <div className="col-xl-7 col-lg-7 col-md-7">
                                        <div className="row align-items-center">
                                            <div className="col-md-2 custmpadding">
                                                <label>Name</label>
                                            </div>
                                            <div className="col-md-10 formInp">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Name"
                                                    name={"name"}
                                                    onChange={handleChange}
                                                    value={transaction.name}
                                                />
                                                {validate(transaction.name, "Name", "required")}
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2 custmpadding">
                                                <label>Date</label>
                                            </div>
                                            <div className="col-md-10 formInp">
                                                <CommonDatePicker
                                                    name="date"
                                                    placeholder="Date"
                                                    disableClock={true}
                                                    className="form-control"
                                                    value={transaction.date && moment(new Date(transaction.date)).format('MM/DD/YYYY')}
                                                    onChange={onChangeDate}
                                                    // maxDate={new Date()}
                                                    showYearDropdown='true'
                                                />
                                                {validate(transaction.date, "Date", "required")}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label>Amount</label>
                                            </div>
                                            <div className="col-md-10 formInp">
                                                <div className="d-flex customRadioBtn">
                                                    <label className="radioBtn"
                                                    >Money In
                                                        <input type="checkbox" name={"moneyStatus"} value={'1'}
                                                            onChange={handleChange}
                                                            checked={transaction.moneyStatus == "1" ? true : false} />
                                                        <span className="radiocheckmark"></span>
                                                    </label>
                                                    <label className="radioBtn"
                                                    >Money Out
                                                        <input type="checkbox" name={"moneyStatus"} value={'2'}
                                                            checked={transaction.moneyStatus == "2" ? true : false}
                                                            onChange={handleChange} />
                                                        <span className="radiocheckmark"></span>
                                                    </label>
                                                </div>
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    className="form-control"
                                                    name="amount"
                                                    onChange={handleChange}
                                                    value={transaction.amount}
                                                />
                                                {validate(transaction.amount, "Amount", "required")}
                                                {transaction.amount && validate(transaction.amount, "Amount", "isNumber")}
                                            </div>
                                            {validate(transaction.moneyStatus, "Money Status", "required")}
                                        </div>

                                        <div className="row align-items-center">
                                            <div className="col-md-2 custmpadding">
                                                {/* <div className="col-xl-6 col-lg-6 col-md-6 formInp formsectionPadding"> */}
                                                <label>Category</label>
                                            </div>
                                            <div className="col-md-10 formInp srch-frm">
                                                <div className="selctOtr" contentEditable={true}>
                                                    <input type={"text"} placeholder={"All Categories"}
                                                        className="form-control"
                                                        value={transaction.categoryName}
                                                        onChange={(e) => handleSearch(e)}
                                                        onClick={() => setMenu(!isMenu)} />

                                                    {/*{validate(transaction.categoryId, "Category", "required")}*/}
                                                </div>

                                                {isMenu ?
                                                    <>
                                                        <input type={"text"} placeholder={"Search"} className="form-control form-srch"
                                                            onChange={(e) => handleSearch(e)}
                                                            // onClick={() => setMenu(!isMenu)} 
                                                            style={{ 'position': 'absolute', 'top': '0' }}
                                                        />
                                                        {props.categoryData && props.categoryData.length ?
                                                            <Menu
                                                                onSelect={handleOption}> {props.categoryData.map((category, key) => {
                                                                    {
                                                                        return !category.subCategories.length ?
                                                                            <MenuItem
                                                                                value={category._id}>{category.title}</MenuItem>
                                                                            : <SubMenu
                                                                                title={category.title}
                                                                                value={category._id}> {renderSubmenu(category)}</SubMenu>
                                                                    }
                                                                })}</Menu> : null}</> : null}

                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2">
                                                <label>Property</label>
                                            </div>
                                            <div className="col-md-10 formInp">
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions={Properties}
                                                    placeholder={"All Properties"}
                                                    loadOptions={promiseOptionsProperty}
                                                    name="tenantId"
                                                    onChange={handleChangeProperty}
                                                    value={transaction.propertDetails}
                                                />
                                                {/*{validate(transaction.property, "Property", "required")}*/}
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2 custmpadding">
                                                <label>Details</label>
                                            </div>
                                            <div className="col-md-10 formInp">
                                                <textarea
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Details"
                                                    name={"details"}
                                                    onChange={handleChange}
                                                    value={transaction.details}
                                                ></textarea>
                                                {/*{validate(transaction.details, "Description", "required")}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5">
                                        <div className="col-md-12 formInp attachFile">
                                            <div className="col-md-12 p-0">
                                                <div className="dropzoneWrapper">
                                                    <label className="dropzoneDesc" htmlFor="inputGroupFile01">
                                                        <i className="fas fa-cloud-upload-alt"></i>
                                                        <p>Drag & Drop or Choose File</p>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="dropzone"
                                                        id="inputGroupFile01"
                                                        onChange={handleChangeFile}
                                                        name={'files'}
                                                        multiple={true}
                                                    />

                                                </div>
                                                {validate(transaction.files, "File", "required")}
                                            </div>
                                        </div>
                                    </div>

                                    {((transaction.files && transaction.files.length) || (transaction.attachment && transaction.attachment.length)) ?
                                        <div
                                            className="col-md-12 attachfileOuter">
                                            {transaction.attachment && transaction.attachment.length ? transaction.attachment.map((attachment, i) => {
                                                return (
                                                    <div
                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                        key={i}>
                                                        <div className="col-md-12 attachFileList">
                                                            {(attachment.title.split('.').pop() === "jpg" || attachment.title.split('.').pop() === "png")
                                                                ? <img
                                                                    src={MAIN_ROUTE + attachment.path}
                                                                    alt="" />
                                                                : (attachment.title.split('.').pop() === "pdf")
                                                                    ?
                                                                    <i className="far fa-file-pdf"></i>
                                                                    : (attachment.title.split('.').pop() === "doc" || attachment.title.split('.').pop() === "docx")
                                                                        ?
                                                                        <i className="far fa-file-alt"></i>
                                                                        : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.title.split('.').pop())) &&
                                                                        <i className="far fa-file-video"></i>
                                                            }
                                                            <button type="button"
                                                                className="image-close"
                                                                onClick={() => deleteAttachmentEdit(i, attachment)}>
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>
                                                        <p>{attachment.title}</p>
                                                    </div>
                                                )
                                            }) : null}
                                            {transaction.files && transaction.files.length ? transaction.files.map((attachment, i) => {
                                                return (
                                                    <div
                                                        className="col-xl-2 col-md-2 col-sm-2 outAttachImg"
                                                        key={i}>
                                                        <div className="col-md-12 attachFileList">
                                                            {(attachment.name.split('.').pop() === "jpg" || attachment.name.split('.').pop() === "png")
                                                                ? <img
                                                                    src={URL.createObjectURL(attachment)}
                                                                    alt="" />
                                                                : (attachment.name.split('.').pop() === "pdf")
                                                                    ?
                                                                    <i className="far fa-file-pdf"></i>
                                                                    : (attachment.name.split('.').pop() === "doc" || attachment.name.split('.').pop() === "docx")
                                                                        ?
                                                                        <i className="far fa-file-alt"></i>
                                                                        : (['avi', 'flv', 'wmv', 'mov', 'mp4'].includes(attachment.name.split('.').pop())) &&
                                                                        <i className="far fa-file-video"></i>
                                                            }
                                                            <button type="button"
                                                                className="image-close"
                                                                onClick={() => deleteAttachment(i)}>
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>
                                                        <p>{attachment.name}</p>
                                                    </div>
                                                )
                                            }) : null
                                            }
                                        </div> : null}


                                </div>
                            </div>
                        </div>

                        {/*<div className="modal-footer">*/}
                        {/*<button type="button" className="btn btn-primary btnSubmit" onClick={handleSubmit}>*/}
                        {/*Update*/}
                        {/*</button>*/}
                        {/*</div>*/}

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btnSubmit" onClick={handleSubmit}>
                            Save
                        </button>
                        {/*  <button type="button" className="btn btn-primary btnSubmit btnCancel">
                            Cancel
                        </button>*/}
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        plaidReducer: state.plaidReducer,
        commonReducer: state.commonReducer,
        updateTransactionData: state.plaidReducer.updateTransactionData,
        categoryData: state.plaidReducer.categoryData


    };
};

export default connect(mapStateToProps, { ...commonAction, ...action })(EditTransaction);
