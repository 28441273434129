import * as actionTypes from "./constants";
import * as Service from "./services";

const getCardPositions = () => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CARD_POSITIONS_REQUEST });
            const res = await Service.getCardPositions();
            if (res.status === 200) {
                const response = res.data;
                await dispatch({ type: actionTypes.GET_CARD_POSITIONS_SUCCESS, response });
            } else {
                await dispatch({ type: actionTypes.GET_CARD_POSITIONS_FAILURE, error: res.response && res.response.data });
            }
        } catch (e) {
            await dispatch({ type: actionTypes.GET_CARD_POSITIONS_FAILURE, error: e.response && e.response.data });
        }
    };
};

const updateCardPositions = (data) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPDATE_CARD_POSITIONS_REQUEST });
            const res = await Service.updateCardPositions(data);
            if (res.status === 200) {
                const response = res.data;
                await dispatch({ type: actionTypes.UPDATE_CARD_POSITIONS_SUCCESS, response });
            } else {
                await dispatch({ type: actionTypes.UPDATE_CARD_POSITIONS_FAILURE, error: res.response.data });
            }
        } catch (e) {
            await dispatch({ type: actionTypes.UPDATE_CARD_POSITIONS_FAILURE, error: e.response });
        }
    };
};

const makeMultiplePayment =(value)  => {

    const request = data => ({
        type: actionTypes.MAKE_PAYMENT_REQUEST,
        data
    });
    const success = data => ({
        type: actionTypes.MAKE_PAYMENT_SUCCESS,
        data
    });
    const failure = error => ({
        type: actionTypes.MAKE_PAYMENT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await Service.makeMultiplePayment(value);
            if (res.status === 200) {
                const invoiceDetails = res.data;
                await dispatch(success(invoiceDetails));
            } else {
                await dispatch(failure(res.response.data));
            }
        } catch (e) {
            await dispatch(failure(e.response && e.response.data));

        }
    };
};

export default {
    getCardPositions,
    updateCardPositions,
    makeMultiplePayment
};
