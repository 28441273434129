export const PHONE_LENGTH = 10;
export const MESSAGE_LENGTH = 25;

export const MISSING_NUMBER_CHARACTER = (password) => /^(?=.*\d)/i.test(password) ? true : 'Password must contain at least one number.';

export const PASSWORD_LENGTH = (password) => /^(?=.*[0-9])/i.test(password) ? true : 'Password minimum length should be  8 .';

export const PASS_ONLY_NUMBER_CHARACTER = (password) => /^(?=.*\d)/i.test(password) ? true : 'Password should only contain numbers.';

export const MISSING_LOWERCASE_CHARACTER = (password) => /^(?=.*[a-z])/i.test(password) ? true : 'Password must contain at least one lowercase character.';

export const ONLY_NUMBER = resource => `${resource} should only contain numbers.`;

export const MISSING_UPPERCASE_CHARACTER = (password) => /^(?=.*[A-Z])/i.test(password) ? true : 'Password must contain at least one uppercase character.';

export const MISSING_SPECIAL_CHARACTER = (password) => /^(?=.*\d)/i.test(password) ? true : 'Password must contain at least one special character.';

export const DEFAULT_OFFSET_VALUE = 0;

export const DEFAULT_LIMIT_VALUE = 20;

export const APP_MAIN_ROUTE = '/home';

export const LOGIN_ROUTE = '/login';

export const FORBIDDEN = 403;

export const NOT_FOUND = 404;

export const UNAUTHORIZED = 401;

export const VALIDATION_ERROR = 422;

export const EMAIL_VALIDATED = 'Email has been validated successfully.';

export const CHANGED_PASSWORD = 'Your password was changed successfully.';

export const INVALID_PHONE_NUMBER = 'Phone number must begin with your country code. For example: +3534325551212.';

export const REQUIRED = (resource, name) => (!resource) ? `*${name} is required.` : true;

export const INVALID = resource => `${resource} is invalid.`;

export const UNMATCHED_PASSWORDS = (password, confirmPassword) => password === confirmPassword ? true : '*The passwords you have entered do not match.';

export const LENGTH_REQUIRED = (resource, options) => {
    const { min, max } = options;
    if (min && max) {
        return `${resource} should be of length ${min} characters .`;
    }
    if (min) {
        return `${resource} must be more than ${min} characters.`;
    }
    return `${resource} must have  ${max} characters.`;
};

export const LETTER_ONLY = (value, name) => /^[a-zA-Z\s]*$/i.test(value) ? true : `Invalid ${name}`

export const ISNAME = value => /^[A-Z ]+$/i.test(value) ? true : false

export const NUMBER_ONLY = (value, name) => /^[0-9]+$/i.test(value) ? true : `Invalid ${name}`

export const EMAIL = (value, name) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(value) ? true : `Invalid ${name}`

export const CARD_NUMBER = (value, name) => value.length == 19 ? true : `Invalid ${name}`

export const TRANSACTION_KEY = (value, name) => /^[0-9a-zA-Z]+$/i.test(value) && value.length == 16 ? true : `Invalid ${name}`

export const API_ID = (value, name) => /^[0-9a-zA-Z]+$/i.test(value) ? true : `Invalid ${name}`

export const CODE = (value, name) => /^[0-9a-zA-Z]+$/i.test(value) ? true : `Invalid ${name}`

export const PASSWORD = (value) => /^(?=.*?[A-Z]).{5,}$/.test(value) ? true :
    `*Password must contain at least one upper case character with minimum five characters`

export const SSN = (value, name) => /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/i.test(value) ? true : `Invalid ${name}`;

export const ZIP_CODE = (value, name) => /^\d{5}$|^\d{5}-\d{4}$/i.test(value) ? true : `Invalid ${name}`;

export const PHONE_NO = (value, name) => /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/i.test(value) ? true : `Invalid ${name}`;

