import React, { useState, useEffect } from "react";
import action from "../../action";
import { connect } from "react-redux";
import commonAction from '../../../Common/action';
import moment from 'moment';
import base64url from "base64url";
import history from '../../../../config/history';

const RecentEmail = (props) => {

  const {tenantById} = props;

  return (
    <div className="col-md-12 otherDtl">
    <h3>Recent Emails</h3>

    {tenantById && tenantById.message.length ?
    <ul className="emlOtr">
       { tenantById && tenantById.message.map((data,i)=> {
           return  <li className="d-flex contentEml" key={i}>
           <div className="imgSecEml"><img src="/images/message-bx.png" /></div>
           <div className="dtlSecEml">
             <div className="col-md-12 dtlPaye"  onClick={() => history.push(`/Messaging/Details/${data.thread && base64url(data.thread)}?fSrs=Inbox`)}>
               <p className="payCont">{data.subject}</p>
               <p className="payTime">
                 <span className="dateImg"><img src="/images/datee.png" />{moment(new Date(data.createdAt)).format('DD MMM YYYY')}</span>
                 <span className="timeImg"><img src="/images/timer.png" /> {moment(new Date(data.createdAt)).format(' h:mm a')}</span>
               </p>
             </div>

           </div>
         </li>
       })
     }
    </ul>:
    <div className="no_value"><i className="far fa-thumbs-down"></i>There is no data to show you right now!</div>
}
  </div>
  )
}

const mapStateToProps = state => {
  const { tenantReducer, commonReducer } = state;
  return {
    tenantReducer,
    commonReducer
  };
};

export default connect(mapStateToProps, {
  ...action, ...commonAction
})(RecentEmail);
