import React, { useState, useEffect } from "react";
import action from "../action";
import { connect } from "react-redux";
import * as validation from '../../Common/components/validation';
import usePrevious from "../../Common/components/prevProps";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastr from '../../Common/components/Toaster';
import LoaderSpinner from "../../Common/components/Loader";
import commonAction from '../../Common/action';

const AddTemplate = (props) => {
    return (
        <div className="modal  addTenant addInvoiceModal addpsw addsign" id="modalId" style={{ display: "block" }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Lease Template</h5>
                        <button type="button" className="close" onClick={() => props.onCancel()} aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="clientInfo">
                            <div class="formSection">
                                <div class="outrForm">
                                    {/* <div class="col-md-12 formInp">
                                        <label class="custom-file-label" for="inputGroupFile01" 
                                        onChange={(e)=>props.handlePdfdata(e)}>
                                            <img src="/images/Attach.png" alt="" />
                                            <span>Attach Template</span>
                                            <input type="file" id="inputGroupFile01" name="expenseFile" /></label>
                                    </div> */}
                                    <div className="col-md-12 formInp formSecInp">
                                        <label >Attach Template</label>
                                        <div className="attachRow">
                                            <input type="file" className="customfile form-control cstmAttach"
                                                placeholder="Insert knowledgebase Link"
                                                onChange={(e) => props.handlePdfdata(e)} />

                                        </div>
                                    </div>
                                    {/* <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="inputGroupFile01"
                                                aria-describedby="inputGroupFileAddon01" />
                                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer"><button class="add-btn btn-submit" onClick={props.submitData}>Submit</button></div>
                </div>
            </div>
        </div>


    )
}

const mapStateToProps = state => {
    const { settingsReducer, commonReducer } = state;
    return {
        payment: settingsReducer.payment,
        commonReducer
    };
};

export default connect(mapStateToProps, {
    ...action, ...commonAction
})(AddTemplate);
