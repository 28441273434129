import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from 'moment'
import $ from 'jquery'

import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

const date = '2015-06-26' // or Date or Moment.js

const CommonDatePicker = (props) => {
    const [dateError, setDateError] = useState(false)
    const [dateValue, setDateValue] = useState(null)
    const onChange = (jsDate, dateString) => {
        // console.log("Summary format:",jsDate)
        if (_.isDate(jsDate)) {
            setDateError(false)
            props.onChange((jsDate))   
        }
        else {
            let tempDate = dateString
            // let date = tempDate.getDate() + "-" + (tempDate.getMonth() + 1) + "-" + tempDate.getFullYear()
            setDateError(true)
        }
    };

    let anyReactInputProps = props

    let errorClass = props.className + " eror-brdr"
    return (
        <>
            <DatePickerInput
                id="date"
                name={props.name}
                onChange={onChange}
                value={props.value}
                displayFormat='MM/DD/YYYY'
                className={!dateError ? props.className : errorClass}
                placeHolder={props.placeholderText}
                locale='en'
                // validationFormat={moment(props.value).format('MM/DD/YYYY')}
                format={"MM/DD/YYYY"}
                disabled={props.disabled}
                // selected={input.value ? moment(input.value) : null}
                minDate={props.minDate}
                maxDate={props.maxDate}
                popperModifiers={{
                    offset: {
                        enabled: true,
                        offset: '5px, 10px'
                    },
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                        boundariesElement: 'viewport'
                    }
                }}
            // startMode={"MM/DD/YYYY"}
            //    {...anyReactInputProps}
            />
            {/* {dateError ? <div className="errorMsg">*Invalid Date</div>:null} */}
            {/*<DatePicker
                onChange={onChange}
                value={date}
                locale='en'
            />*/}
        </>
    )
}

export default CommonDatePicker;